
<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-street-view"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Eagle View') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
             <!--  <div class="col-sm-2 offset-sm-4">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Delivery Zones') }} :</label>
                    <select class="custom-select">
                      <option value="status">{{ trans.get('__JSON__.Default') }}</option>
                      <option value="created_date">{{ trans.get('__JSON__.Date Added') }}</option>
                      <option value="order_count">{{ trans.get('__JSON__.Most Orders') }}</option>
                      <option value="name">{{ trans.get('__JSON__.Alphabetically') }}</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-2 offset-sm-4">
                <div class="form-group">
                  <div class="date-range-list">
                   <!--  <label>{{ trans.get('__JSON__.Status') }} :</label> -->
                   <label> Near By </label>
                    <select class="custom-select" v-model="type" @change="getDriverList">
                      <option value="pickup">Pickup Address</option>
                      <option value="delivery">Delivery Address</option>
                    </select>
                   
                  </div>
                </div>
              </div>

              <div class="col-sm-2">
                <div class="form-group">
                   <label> Select Radius (In KM) </label>
                    <select class="custom-select" v-model="km" @change="getDriverList">
                      <option value="0">Select KM</option>
                      <option :value="k" v-for="k in 10"> {{ k}} </option>
                     <!--  <option value="10000000"> Testing </option> -->
                    </select>
                </div>
              </div>
               <div class="col-sm-3">
                <div class="row">
                  <div class="col-sm-10">
                        <div class="form-group">
                      <div class="date-range-list">
                        <label>Enter OrderID</label>
                        <input type="text" v-model="orderId" class="form-control" placeholder="Default : All Orders" >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 m-eagle-view">
                    <div class="form-group">
                  <div class="text-sm-right">
                   <button  class="btn btn-primary searchBtn" @click="getDriverList" >Search</button>
                  </div>
                </div>
                  </div>
                </div>
                
              </div>
              <!-- <div class="col-sm-2">
                <div class="form-group">
                  <div class="text-sm-right">
                   <button  class="btn btn-primary searchBtn" @click="getDriverList" >Search</button>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row" v-if="statusMsg">
              <div class="col-8 offset-sm-4">
                <div class="alert alert-primary" role="alert">
                    {{ statusMsg }}
                </div>
              </div>
            </div>
             <div class="row">
              <div class="col-12 table-main-list">
              <!-- basic map -->

                   <!--  <div class="text-center text-danger my-2" v-if="loading">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div> -->
                   
                    <GmapMap 
                     :center="{lat: parseFloat($auth.setting.system_latitude), lng: parseFloat($auth.setting.system_longitude) }" 
                     :zoom="13"
                      style="height: 500px"
                      map-type-id="roadmap"
                      ref="map"
                      
                     >

                     <gmapInfoWindow
                            :options="infoWindowOptions"
                            :position="infoWindowPosition"
                            :opened="infoWindowOpened"
                            @closeclick="handleInfoWindowClose"
                          
                        >
                            <div class="info-window"  v-if="activeDriver">
                                <h5> Name: {{ capitalize(activeDriver.firstname + ' ' + activeDriver.lastname) }}</h5>
                                <p class="driver-status">Status: {{ (activeDriver.ongoing_requests && activeDriver.ongoing_requests.length ) ? 'Busy': 'Free' }}</p>
                                <p> Active orders: {{ activeDriver.orderIds }}</p>
                                <!-- <p> <b>Active orders:</b> #1235454,#4567878</p> -->
                            </div>
                        </gmapInfoWindow>

                      <gmap-custom-marker :marker="{lat:parseFloat(driver.latitude),lng:parseFloat(driver.longitude)}" 
                       v-for="(driver,index) in driverList"
                       v-if="driverList && driverList.length"
                       :key="index"
                       >
                    
                     <div class="custom-google-map-marker"   :key="driver.driverid">
                       <div class="custom-google-map-marker-img" :class="{ 'make-green-img' :  driver.ongoing_requests && driver.ongoing_requests.length ? false : true }">
                         <img :src="driver.profile_path"  @click="handleMarkerClicked(driver)" width="40" height="40" @error="replaceByDefault" />
                       </div>
                         <h6 class="driver-name" :class="{ 'make-green-text' :  driver.ongoing_requests && driver.ongoing_requests.length ? false : true }">{{ capitalize(driver.firstname) }}</h6>
                     </div>
                    </gmap-custom-marker>

                    <gmap-custom-marker v-if="restOrCus" :marker="{lat:parseFloat(restOrCus.latitude),lng:parseFloat(restOrCus.longitude)}" 
                       >
                      <img :src="restOrCus.icon_url" alt="icon image">
                    </gmap-custom-marker>

                    <!-- <gmap-custom-marker v-if="Object.keys(restOrCus).len" :marker="{lat:parseFloat(restOrCus.latitude),lng:parseFloat(restOrCus.longitude)}" 
                       >
                     <h2>testing</h2>
                    </gmap-custom-marker> -->



                     <!-- <GmapMarker
                        :key="driver.driverid"
                        v-for="driver in driverList"
                        :position="{lat:parseFloat(driver.latitude),lng:parseFloat(driver.longitude)}"
                        :clickable="true"
                        :draggable="false"
                         @click="handleMarkerClicked(driver)"
                        :icon="{ url: driver.profile_path }",
                        :label="driver.firstname",
                      />
                      -->
                    </GmapMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { driverService} from '../../services';
import {gmapApi} from 'vue2-google-maps'
import _ from 'lodash';
import GmapCustomMarker from 'vue2-gmap-custom-marker';

var no_image = "https://storage.googleapis.com/deonde-prod/media/images/no-img.png";

export default {
  components: { Layout,'gmap-custom-marker': GmapCustomMarker },
  data() {
    return {
      type: 'pickup',
      loading:false,
      orderId:'',
      km:5,
      driverList: [],
      restOrCus: null,
      infoWindowOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
      infoWindowOpened: false,
      activeDriver: {},
      statusMsg: '',
    };
  },
  watch: {
    driverList(drivers) {
      if (drivers && drivers.length > 2) {
        const bounds = new google.maps.LatLngBounds()
        for (let d of drivers) {
          bounds.extend({lat:parseFloat(d.latitude),lng:parseFloat(d.longitude)})
        }
        this.$refs.map.fitBounds(bounds)
      }
    },
    // orderId(order_id)
    // {
    //    if(!order_id)
    //    {
    //       this.getDriverList();
    //    }
    // } 
  },
  computed: {
    infoWindowPosition() {
            return {
                lat: parseFloat(this.activeDriver.latitude),
                lng: parseFloat(this.activeDriver.longitude)
            };
        },
    google: gmapApi,
    defaultLatLng() {
      return {lat:  23.0225, lng: 72.5714 }
      // return {
      //   lat: parseFloat(this.$auth.setting.system_latitude ? $auth.setting.system_latitude : 10 ), lng: parseFloat(this.$auth.setting.system_longitude ? $auth.setting.system_longitude: 10 ) }
    }
  },
  methods: {
     getDriverList() {
        //if(this.orderId == '') return;
        this.handleInfoWindowClose();
        this.loading = true;
        this.statusMsg = '';
        let data = { km : this.km , "order_id" : this.orderId,type: this.type};
        driverService.eagleViewDriverList(data).then((response) => {
          if(response.data.code == 200)
          {
              //this.driverList = [];
              //this.restOrCus = null;
             this.driverList = response.data.result.drivers;
             this.restOrCus = response.data.result.rest_or_cus;
          }
          else {
            this.statusMsg = response.data.message;
            this.driverList = [];
            this.restOrCus = null;
            setTimeout(() => {
                this.statusMsg = '';
            },2000);
          }
          this.loading = false;
        });
     },
     handleMarkerClicked(r) {
          let orderIds = '';
          this.activeDriver = r;
          if(this.activeDriver.ongoing_requests && this.activeDriver.ongoing_requests.length)
          {
            orderIds = this.activeDriver.ongoing_requests.map(function(v) {
             return '#'+v.orderid;
             });
            orderIds = orderIds.join(',');
          }
          
          this.activeDriver.orderIds = orderIds;
          this.infoWindowOpened = true;
      },
    handleInfoWindowClose() {
        this.activeDriver = {};
        this.infoWindowOpened = false;
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    replaceByDefault(e) {
      e.target.src = no_image;
    }
  },
  mounted() {
     this.getDriverList();
  }
};
</script>
<style>
  .searchBtn {
    margin-top: 26px;
  }
  .custom-google-map-marker
  {
     
  }
  .custom-google-map-marker img
  {
     object-fit: fill;
  }
  .custom-google-map-marker-img
  {
     display: flex;
     justify-content: center;
     align-items: center;
     border: 2px solid red;
     border-radius: 50%;
     overflow: hidden;
     width: 40px;
     height: 40px;
    }
  .custom-google-map-marker .driver-name
  {
    margin-top: -1px;
    text-align: center;
    color: #fff;
    background: red;
    padding: 5px 10px;
    border-radius: 4px;
    position: relative;
    font-size: 10px;
  }

 .make-green-img
 {
    border: 2px solid green;
 }
 .custom-google-map-marker .make-green-text
 {
    background-color :green;
 }
 .driver-status {
   margin-bottom: 6px;
 }

</style>