
<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { bannerService } from "../../services";
import { required, requiredIf } from "vuelidate/lib/validators";
import config from "../../config";
import { error_message, replaceByDefault } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import DropzoneSingle from "../../components/widgets/dropzone-single";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { routes } from "../../config/api_routes";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect,
    DropzoneSingle,
    DropzonePopup,
  },
  data() {
    return {
      value: null,
      tmpBannerImage: "",
      url: null,
      config: config,
      search: "",
      isLoading: false,
      resturant: [],
      restaurants: [],
      banner: {
        id: "",
        name: "",
        image: "",
        restaurants: "",
        status: "",
        sort: ''
      },
      banner_image: "",
      banner_image_path: "",
      banner_image_delete_url: routes.bannerImageDeleteUrl,
      id: "",
    };
  },
  validations: {
    banner: {
      name: {
        required,
      },
      restaurants: {
        required,
      },
      image: {
        required:requiredIf(function (nestedModel) {
          return !(!!this.banner_image || !!this.banner.image)
        })
      },
      sort: {
        required
      }
    },
  },
  mounted() {
    this.getBannerById();
  },
  methods: {
    onBannerImagedeleted(value) {
      this.banner_image = "";
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.banner.image = file;
    },
    asyncFind(query) {
      this.isLoading = true;
      bannerService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.restaurants = [];
    },
    getBannerById() {
      bannerService
        .getBannerById({ id: this.$route.params.id })
        .then((response) => {
          const { id, name, restaurants, status, sort } = response.data.result;
          this.banner_image_path = response.data.result.banner_image_path;
          this.banner_image = response.data.result.image;
          this.id = response.data.result.id;
          this.banner = {
            id,
            name,
            restaurants,
            status,
            sort
          };
        });
    },
    bannerInIt() {
      this.$v.banner.$touch();
      if (this.$v.banner.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("id", this.banner.id);
        if(!!this.banner.image){
          fd.append("image", this.banner.image);
        }
        fd.append("name", this.banner.name);
        fd.append("status", this.banner.status);
        fd.append("sort", this.banner.sort);
        this.banner.restaurants
          .map((item) => item.restaurant_id)
          .map((item, index) => {
            fd.append("restaurants[" + index + "]", item);
          });
        bannerService.updateBannerById(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            // this.$router.push({ name: "banner-edit" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <b-form @submit.prevent="bannerInIt">
    <!-- Banner Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Banner Info') }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="form-group">
            <input
              id="id"
              name="id"
              type="hidden"
              @id="id"
              v-model="banner.id"
              class="form-control"
            />
          </div>
          <div class="col-sm-6">
            <b-form-group id="input-group-1" :label="trans.get('__JSON__.Name')" label-for="name">
              <b-form-input
                id="name"
                name="name"
                v-model="$v.banner.name.$model"
                type="text"
                :placeholder="trans.get('__JSON__.Banner Name Placeholder')"
                :class="{ 'is-invalid': $v.banner.name.$error }"
              ></b-form-input>
              <div v-if="$v.banner.name.$error" class="invalid-feedback">
                <span v-if="!$v.banner.name.required">{{ trans.get('__JSON__.Banner Name Error') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Vendors',{ vendor: $auth.setting.restaurant_name })"
              label-for="banner_name"
              for="ajax"
            >
              <multiselect
                v-model="$v.banner.restaurants.$model"
                id="ajax"
                label="name"
                track-by="name"
                :placeholder="trans.trans('__JSON__.Banner Vendor Search Placeholder',{ vendor: $auth.setting.restaurant_name })"
                open-direction="bottom"
                :options="resturant"
                :multiple="true"
                :searchable="true"
                :loading="isLoading"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="false"
                :options-limit="5"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFind"
                :class="{ 'is-invalid': $v.banner.restaurants.$error }"
              >
                
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag">
                    <span>{{ option.name }}</span>
                    <span class="custom__remove" @click="remove(option)">❌</span>
                  </span>
                </template>
                <template slot="clear" slot-scope="props">
                  <div
                    class="multiselect__clear"
                    v-if="restaurants.length"
                    @mousedown.prevent.stop="clearAll(props.search)"
                  ></div>
                </template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </multiselect>
              <div v-if=" $v.banner.restaurants.$error" class="invalid-feedback">
                <span
                  v-if="!$v.banner.restaurants.required"
                >{{ trans.trans('__JSON__.Vendor Select Error',{ vendor: $auth.setting.restaurant_name }) }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 vendor-status">
            <b-form-group
              id="input-group-1"
              :label="trans.get('__JSON__.Status')"
              label-for="status"
            >
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  name="status"
                  v-model="banner.status"
                  class="switch-on"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group id="input-group-1" :label="trans.get('__JSON__.Sort Order')" label-for="name">
              <b-form-input
                id="name"
                name="name"
                v-model="$v.banner.sort.$model"
                type="number"
                :placeholder="trans.get('__JSON__.Enter field',{name: 'sort order'})"
                :class="{ 'is-invalid': $v.banner.sort.$error }"
              ></b-form-input>
              <div v-if="$v.banner.sort.$error" class="invalid-feedback">
                <span v-if="!$v.banner.sort.required">{{ trans.get('__JSON__.Please enter field',{name: 'sort order'}) }}.</span>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Info End -->

    <!-- Banner Image Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Banner Image') }}</h4>
        <p class="card-title-desc"></p>

        <div class="row">
          <div class="col-sm-3">
            <dropzone-popup
              v-model="banner.image"
              id="banner_image"
              acceptedFiles=".jpeg,.jpg,.png"
              :editable-images="banner_image"
              :media-path="banner_image_path+'/original'"
              :delete-path="banner_image_delete_url"
              @image-deleted="this.onBannerImagedeleted"
              :class="{ 'is-invalid': $v.banner.image.$error }"
            />
            <div v-if="$v.banner.image.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.banner.image.required"
              >{{ trans.get('__JSON__.Banner Image Error') }}.</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update Banner') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Image End -->
  </b-form>
</template>

<style>
</style>