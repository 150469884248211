<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Multiselect from "vue-multiselect";
import config from "../../config";
import { error_message, success_message, get_user } from "../../Helper/helper";
import { routes } from "../../config/api_routes";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
  required,
  email,
  minValue,
  maxValue,
  maxLength,
  minlength,
  numeric,
  helpers,
  requiredIf,
} from "vuelidate/lib/validators";
import {
  vendorService,
  deliveryZoneService,
  settingService,
  userService,
} from "../../services";
const floating = helpers.regex("floating", /^(?:\d*\.\d{1,2}|\d+)$/);
const phone = helpers.regex("phone", /^([+]{1})?([0-9]*)$/);

/**
 * update-profile component
 */
export default {
  components: { Layout, PageHeader, Multiselect, DropzonePopup, VueGoogleAutocomplete },
  data() {
    return {
      title: this.trans.get("__JSON__.Profile"),
      config: config,
      filename: "",
      restaurant_id: "",
      file1: null,
      url: null,
      search: "",
      isLoading: false,
      value: null,
      value1: null,
      rescategories: [],
      countries: [],
      states: [],
      cities: [],
      cuisine: [],
      cuisines: [],
      center: { lat: 0.0, lng: 0.0 },
      markers: [],
      vendor_currency: "",
      country: "",
      state: "",
      city: "",
      country_count: "",
      state_count: "",
      city_count: "",
      disable: false,
      restaurant: {
        no_of_tables: 0,
        name: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        address: "",
        location_address: "",
        // minimum_order_value: "",
        two_person_price: "",
        delivery_time: "",
        admin_commision: "",
        fssai_licence: "",
        delivery_type_time_slots: "",
        restaurant_type: "",
        time_slots: "",
        currency: "",
        admin_commision_type: "",
        category_id: "",
        restaurant_tax: "",
        restaurant_item_layout: "",
        key: "",
        cuisines: "",
        image: "",
        pet_pooja_restaurant_id: "",
        is_schedule_order: "",
        dunzo_enable: "0",
        pidge_enable: "0",
        chowman_pay_status: "0",
        default_pay_cashback: "",
        seo_title: "",
        seo_meta: "",
        seo_keyword: "",
      },
      icon_image: "",
      icon_image_path: "",
      restaurant_media_path: "",
      icon_image_delete_url: routes.vendorImageDeleteUrl,
      restaurant_id: "",
      weather_text: "",
    };
  },
  validations: {
    restaurant: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        phone,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      address: {
        required,
      },
      location_address: {
        required,
      },
      // minimum_order_value: {
      //   required,
      //   floating
      // },
      time_slots: {
        required,
      },
      delivery_time: {
        required,
        numeric,
      },
      admin_commision: {
        required,
        floating,
      },
      image: {
        required: requiredIf(function (nestedModel) {
          return !(!!this.icon_image || !!this.restaurant.image);
        }),
      },
      pet_pooja_restaurant_id: {
        required,
      },
      is_schedule_order: {
        required,
      },
      no_of_tables: {
        required,
      },
      default_pay_cashback: {
        required: requiredIf(function () {
          return this.restaurant.chowman_pay_status == "1";
        }),
        maxValue: maxValue(100),
        minValue: minValue(0),
      },
    },
  },
  watch: {
    "restaurant.dunzo_enable": function (newValue) {
      if (newValue == 1) {
        this.restaurant.pidge_enable = 0;
      }
      // if (newValue == 0) {
      //   this.restaurant.pidge_enable = 1;
      // }
    },
    "restaurant.pidge_enable": function (newValue) {
      if (newValue == 1) {
        this.restaurant.dunzo_enable = 0;
      }
      // if (newValue == 0) {
      //   this.restaurant.dunzo_enable = 1;
      // }
    },
  },
  mounted() {
    this.initMap();
    this.getVendorCountry();
    this.getAllState();
    this.getAllCity();
    this.getResCategory();
    this.getRestaurantById();
    this.$refs.address.focus();
    this.getSetting();
    this.asyncFind();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onIconImagedeleted(value) {
      this.icon_image = "";
    },
    asyncFind(query) {
      this.isLoading = true;
      vendorService.getVendorCuisine(query).then((response) => {
        this.cuisine = response.data.result;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.cuisines = [];
    },
    userCheckEmail(e) {
      userService
        .userCheckEmail({
          email: this.restaurant.email,
          id: this.restaurant.user_id,
          table: "User",
          column: "user_id",
          vendor_id: this.vendor_id,
          user_role: "2",
        })
        .then((response) => {
          if (response.data.code === 101) {
            this.disable = true;
            error_message(response.data.message);
          } else {
            this.disable = false;
          }
        });
    },
    getAddressData: function (addressData, placeResultData, id) {
      console.log(addressData);
      this.address = addressData;
      this.restaurant.address = placeResultData.formatted_address;
      this.restaurant.location_address = this.$refs.address.autocompleteText;
      this.restaurant.latitude = addressData.latitude;
      this.restaurant.longitude = addressData.longitude;
      if (this.marker) {
        this.marker.setMap(null);
      }
      const marker = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      var geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(addressData.latitude, addressData.longitude);
      this.marker = new google.maps.Marker({
        position: latlng,
        title: "Restaurant",
        draggable: true,
      });
      this.marker.setMap(this.map);
      this.map.setCenter(latlng);
      this.center = marker;
      this.map.setZoom(14);

      this.marker.addListener("dragend", (e) => {
        const latLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
        this.restaurant.latitude = e.latLng.lat();
        this.restaurant.longitude = e.latLng.lng();
        let self = this;
        geocoder.geocode({ latLng: latLng }, function (results, status) {
          if (status === "OK") {
            const address = $("#address").val(results[0].formatted_address);
            self.restaurant.address = results[0].formatted_address;
          }
        });
      });
    },
    /* getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
      this.restaurant.address = placeResultData.formatted_address;
      this.restaurant.latitude = addressData.latitude;
      this.restaurant.longitude = addressData.longitude;
      const marker = {
        lat: addressData.latitude,
        lng: addressData.longitude
      };
      this.markers.push({ position: marker });
      this.center = marker;
    }, */
    getVendorCountry() {
      deliveryZoneService.getVendorCountry().then((response) => {
        this.country_count = response.data.result.length;
        this.countries = response.data.result;
        if (this.country_count == 1) {
          this.restaurant.country = this.countries[0].id;
          this.getVendorState();
        }
      });
    },
    getVendorState(country) {
      deliveryZoneService
        .getVendorState({
          country_id: this.restaurant.country,
        })
        .then((response) => {
          this.state_count = response.data.result.length;
          this.states = response.data.result;
          console.log(this.country);
          if (this.state_count == 1) {
            this.restaurant.state = this.states[0].id;
            this.getVendorCity();
          } else {
            if (this.restaurant.country == "") {
              this.getAllState();
              this.getAllCity();
            }
            if (this.country != this.restaurant.country) {
              this.restaurant.city = "";
              this.getVendorCity();
            }
            if (this.country == this.restaurant.country) {
              this.restaurant.state = this.state;
              this.restaurant.city = this.city;
            }
          }
        });
    },
    getVendorCity(state) {
      deliveryZoneService
        .getVendorCity({
          state_id: this.restaurant.state,
        })
        .then((response) => {
          this.city_count = response.data.result.length;
          this.cities = response.data.result;
          if (this.city_count == 1) {
            this.restaurant.city = this.cities[0].cityid;
          } else {
            if (this.restaurant.state == "") {
              this.restaurant.city = "";
              this.getAllCity();
            }
          }
        });
    },
    getAllState() {
      deliveryZoneService.getAllState().then((response) => {
        this.states = response.data.result;
        this.state_count = response.data.result.length;
      });
    },
    getAllCity() {
      deliveryZoneService.getAllCity().then((response) => {
        this.cities = response.data.result;
        this.city_count = response.data.result.length;
      });
    },
    getResCategory() {
      vendorService.getResCategory().then((response) => {
        this.rescategories = response.data.result;
      });
    },
    getSetting() {
      settingService.getSetting().then((response) => {
        this.vendor_currency = response.data.result.currency;
      });
    },
    getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            name_thai,
            email,
            phone,
            category_id,
            country,
            state,
            city,
            cuisines,
            address,
            location_address,
            address_thai,
            minimum_order_value,
            two_person_price,
            delivery_time,
            fssai_licence,
            admin_commision_type,
            admin_commision,
            restaurant_type,
            time_slots,
            delivery_type_time_slots,
            restaurant_tax,
            restaurant_item_layout,
            latitude,
            longitude,
            restaurant_id,
            user_id,
            pet_pooja_restaurant_id,
            is_schedule_order,
            no_of_tables,
            dunzo_enable,
            pidge_enable,
            chowman_pay_status,
            default_pay_cashback,
            seo_title,
            seo_meta,
            seo_keyword,
          } = response.data.result;
          this.restaurant_media_path = response.data.result.restaurant_media_path;
          this.icon_image_path = response.data.result.icon_image_path;
          this.icon_image = response.data.result.icon_image;
          this.restaurant_id = response.data.result.restaurant_id;
          this.user_id = response.data.result.user_id;
          this.weather_text =
            response.data.result.weather_text == "null"
              ? ""
              : response.data.result.weather_text;
          this.restaurant = {
            name,
            name_thai,
            email,
            phone,
            category_id,
            country,
            state,
            city,
            cuisines,
            address,
            location_address,
            address_thai,
            minimum_order_value,
            two_person_price,
            delivery_time,
            fssai_licence,
            admin_commision_type,
            admin_commision,
            restaurant_type,
            time_slots,
            delivery_type_time_slots,
            restaurant_tax,
            restaurant_item_layout,
            latitude,
            longitude,
            restaurant_id,
            user_id,
            pet_pooja_restaurant_id,
            is_schedule_order,
            no_of_tables,
            dunzo_enable,
            pidge_enable,
            chowman_pay_status,
            default_pay_cashback,
            seo_title,
            seo_meta,
            seo_keyword,
          };
          const marker = {
            lat: parseFloat(response.data.result.latitude),
            lng: parseFloat(response.data.result.longitude),
          };
          this.country = response.data.result.country;
          this.state = response.data.result.state;
          this.city = response.data.result.city;
          this.markers.push({ position: marker });
          var geocoder = new google.maps.Geocoder();
          const latlng = new google.maps.LatLng(
            parseFloat(response.data.result.latitude),
            parseFloat(response.data.result.longitude)
          );
          this.marker = new google.maps.Marker({
            position: latlng,
            title: "Restaurant",
            draggable: true,
          });
          this.marker.setMap(this.map);
          this.map.setCenter(latlng);
          this.center = marker;
          this.map.setZoom(14);

          this.marker.addListener("dragend", (e) => {
            const latLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
            this.restaurant.latitude = e.latLng.lat();
            this.restaurant.longitude = e.latLng.lng();
            let self = this;
            geocoder.geocode({ latLng: latLng }, function (results, status) {
              if (status === "OK") {
                const address = $("#address").val(results[0].formatted_address);
                self.restaurant.address = results[0].formatted_address;
              }
            });
          });
          this.center = marker;
          this.getVendorState();
          this.getVendorCity();
        });
    },
    vendorUpdate() {
      if (this.$auth.partner_login) {
        return false;
      }
      this.$v.restaurant.$touch();
      if (this.$v.restaurant.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("restaurant_id", this.restaurant.restaurant_id);
        fd.append("user_id", this.restaurant.user_id);
        fd.append("name", this.restaurant.name);
        fd.append("email", this.restaurant.email);
        fd.append("phone", this.restaurant.phone);
        fd.append("country", this.restaurant.country);
        fd.append("state", this.restaurant.state);
        fd.append("city", this.restaurant.city);
        fd.append("address", this.restaurant.address);
        fd.append(
          "location_address",
          this.$refs.address.autocompleteText || this.restaurant.location_address
        );
        fd.append("minimum_order_value", this.restaurant.minimum_order_value || 0);
        fd.append("two_person_price", this.restaurant.two_person_price);
        fd.append("delivery_time", this.restaurant.delivery_time || 0);
        fd.append("restaurant_type", this.restaurant.restaurant_type);
        fd.append("time_slots", this.restaurant.time_slots);
        fd.append("currency", this.restaurant.currency);
        fd.append("admin_commision_type", this.restaurant.admin_commision_type);
        fd.append("admin_commision", this.restaurant.admin_commision);
        fd.append("category_id", this.restaurant.category_id);
        fd.append("restaurant_tax", this.restaurant.restaurant_tax);
        fd.append("default_pay_cashback", this.restaurant.default_pay_cashback);
        fd.append("restaurant_item_layout", this.restaurant.restaurant_item_layout);
        fd.append("latitude", this.restaurant.latitude);
        fd.append("longitude", this.restaurant.longitude);
        fd.append("name_thai", this.restaurant.name_thai);
        fd.append("address_thai", this.restaurant.address_thai);
        fd.append("fssai_licence", this.restaurant.fssai_licence);
        fd.append("delivery_type_time_slots", this.restaurant.delivery_type_time_slots);
        fd.append("pet_pooja_restaurant_id", this.restaurant.pet_pooja_restaurant_id);
        fd.append("key", this.restaurant.key);
        fd.append("is_schedule_order", this.restaurant.is_schedule_order);
        fd.append("no_of_tables", this.restaurant.no_of_tables);
        fd.append("dunzo_enable", this.restaurant.dunzo_enable);
        fd.append("pidge_enable", this.restaurant.pidge_enable);
        fd.append("chowman_pay_status", this.restaurant.chowman_pay_status);
        fd.append("weather_text", this.weather_text);
        fd.append("seo_title", this.restaurant.seo_title);
        fd.append("seo_meta", this.restaurant.seo_meta);
        fd.append("seo_keyword", this.restaurant.seo_keyword);
        if (!!this.restaurant.image) {
          fd.append("icon_image", this.restaurant.image);
        }
        this.restaurant.cuisines
          .map((item) => item.cuisine_id)
          .map((item, index) => {
            fd.append("cuisines[" + index + "]", item);
          });
        vendorService.updateVendorInfo(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            if (!this.$auth.partner_login) {
              // this.$router.push({ name: "vendor-detail" });
            }
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div v-if="$auth.partner_login">
    <Layout>
      <PageHeader :title="title" />
      <div class="row">
        <div class="col-12">
          <form @submit.prevent="vendorUpdate">
            <!-- Vendor Info Start -->
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">
                  {{
                    trans.trans("__JSON__.Vendor Info", {
                      vendor: $auth.setting.restaurant_name,
                    })
                  }}
                </h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" v-if="$auth.setting">
                      <label>{{
                        trans.trans("__JSON__.Vendor Name", {
                          vendor: $auth.setting.restaurant_name,
                          lang: $auth.langauges[0].name,
                        })
                      }}</label>
                      <input
                        id="name"
                        v-model="$v.restaurant.name.$model"
                        type="text"
                        class="form-control"
                        :placeholder="
                          trans.trans('__JSON__.Enter Vendor Name langauge', {
                            vendor: $auth.setting.restaurant_name,
                            lang: $auth.langauges[0].name,
                          })
                        "
                        name="name"
                        :class="{ 'is-invalid': $v.restaurant.name.$error }"
                      />
                      <div v-if="$v.restaurant.name.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.name.required">{{
                          trans.trans("__JSON__.Please enter vendor name", {
                            vendor: $auth.setting.restaurant_name,
                          })
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-sm-6 d-none"
                    v-if="$auth.langauges[1].name === 'No Secondary Language'"
                  ></div>
                  <div class="col-sm-6" v-else>
                    <div class="form-group">
                      <label>{{
                        trans.trans("__JSON__.Vendor Name", {
                          vendor: $auth.setting.restaurant_name_sec_lang,
                          lang: $auth.langauges[1].name,
                        })
                      }}</label>
                      <input
                        maxlength="100"
                        id="name_thai"
                        name="name_thai"
                        type="text"
                        v-model="restaurant.name_thai"
                        :placeholder="
                          trans.trans('__JSON__.Enter Vendor Name langauge', {
                            vendor: $auth.setting.restaurant_name_sec_lang,
                            lang: $auth.langauges[1].name,
                          })
                        "
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.get("__JSON__.E-Mail") }}</label>
                      <input
                        id="email"
                        v-model="$v.restaurant.email.$model"
                        type="email"
                        name="email"
                        class="form-control"
                        @blur="userCheckEmail"
                        :class="{ 'is-invalid': $v.restaurant.email.$error }"
                        placeholder="Enter a valid e-mail"
                      />
                      <div v-if="$v.restaurant.email.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.email.required">{{
                          trans.get("__JSON__.Please enter email")
                        }}</span>
                        <span v-if="!$v.restaurant.email.email">{{
                          trans.get("__JSON__.This value should be a valid email")
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.get("__JSON__.Contact Number") }}</label>
                      <input
                        maxlength="13"
                        minlength="10"
                        id="phone"
                        v-model="$v.restaurant.phone.$model"
                        type="tel"
                        name="phone"
                        class="form-control"
                        :class="{ 'is-invalid': $v.restaurant.phone.$error }"
                        :placeholder="trans.get('__JSON__.Enter Contact Number')"
                      />
                      <div v-if="$v.restaurant.phone.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.phone.required">{{
                          trans.get("__JSON__.Please enter contact number")
                        }}</span>
                        <span v-if="!$v.restaurant.phone.phone">{{
                          trans.get("__JSON__.This value should be a valid number")
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.get("__JSON__.Select Category")
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="restaurant.category_id"
                        id="category_id"
                        name="category_id"
                      >
                        <option value="0" selected>
                          {{ trans.get("__JSON__.None") }}
                        </option>
                        <option
                          v-for="res in rescategories"
                          :value="res.category_id"
                          :key="res.category_id"
                        >
                          {{ res.category_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.get(
                          "__JSON__.Select Tags (For Restaurants it will be cuisines)"
                        )
                      }}</label>
                      <multiselect
                        v-model="restaurant.cuisines"
                        id="ajax"
                        label="cuisine_name"
                        track-by="cuisine_id"
                        :placeholder="trans.get('__JSON__.Search...')"
                        open-direction="bottom"
                        :options="cuisine"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoading"
                        :internal-search="true"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :options-limit="5"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                      >
                        <!-- @search-change="asyncFind" -->
                        <template slot="tag" slot-scope="{ option, remove }">
                          <span class="custom__tag">
                            <span>{{ option.cuisine_name }}</span>
                            <span class="custom__remove" @click="remove(option)">❌</span>
                          </span>
                        </template>
                        <template slot="clear" slot-scope="props">
                          <div
                            class="multiselect__clear"
                            v-if="cuisine.length"
                            @mousedown.prevent.stop="clearAll(props.search)"
                          ></div>
                        </template>
                        <span slot="noResult">{{
                          trans.get(
                            "__JSON__Oops! No elements found. Consider changing the search query"
                          )
                        }}</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.trans("__JSON__.Pet Pooja Restaurant ID") }}</label>
                      <input
                        maxlength="100"
                        id="pet_pooja_restaurant_id"
                        name="pet_pooja_restaurant_id"
                        type="text"
                        v-model="$v.restaurant.pet_pooja_restaurant_id.$model"
                        :placeholder="
                          trans.trans('__JSON__.Enter Pet Pooja Restaurant ID')
                        "
                        class="form-control"
                        :class="{
                          'is-invalid': $v.restaurant.pet_pooja_restaurant_id.$error,
                        }"
                      />
                      <div
                        v-if="$v.restaurant.pet_pooja_restaurant_id.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.restaurant.pet_pooja_restaurant_id.required">{{
                          trans.get("__JSON__.Please enter pet pooja restaurant ID")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">{{ trans.get('__JSON__.Status') }}</label> 
                        <select class="custom-select" v-bind:value="restaurant.is_confirm" v-model="restaurant.is_confirm"  id="is_confirm" name="is_confirm"> 
                            <option value="0">Inactive</option>
                            <option value="1">Active</option>
                            <option value="2">Waiting For Approval</option>
                        </select>
                      </div>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- Vendor Info End -->

            <!-- Location Section Start -->
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">{{ trans.get("__JSON__.Location") }}</h4>
                <p class="card-title-desc"></p>

                <div class="row">
                  <div class="col-sm-6">
                    <div v-if="country_count > 1" class="form-group">
                      <label class="control-label">{{
                        trans.get("__JSON__.Country")
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.restaurant.country.$model"
                        @change="getVendorState"
                        id="country"
                        name="country"
                        :class="{ 'is-invalid': $v.restaurant.country.$error }"
                      >
                        <option value selected>
                          {{ trans.get("__JSON__.Select Country") }}
                        </option>
                        <option v-for="con in countries" :value="con.id" :key="con.id">
                          {{ con.country_name }}
                        </option>
                      </select>
                      <div v-if="$v.restaurant.country.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.country.required">{{
                          trans.get("__JSON__.Please select country")
                        }}</span>
                      </div>
                    </div>

                    <div v-if="state_count >= 1 && city_count > 1" class="form-group">
                      <label class="control-label">{{
                        trans.get("__JSON__.City")
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.restaurant.city.$model"
                        id="city"
                        name="city"
                        :class="{ 'is-invalid': $v.restaurant.city.$error }"
                      >
                        <option value selected>
                          {{ trans.get("__JSON__.Select City") }}
                        </option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >
                          {{ cit.cityname }}
                        </option>
                      </select>
                      <div v-if="$v.restaurant.city.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.city.required">{{
                          trans.get("__JSON__.Please select city")
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="control-label">{{
                        trans.get("__JSON__.Location based on Latitude/Longitude")
                      }}</label>
                      <vue-google-autocomplete
                        ref="address"
                        id="address"
                        name="address"
                        type="text"
                        class="form-control"
                        :placeholder="
                          trans.get('__JSON__.Enter location based on Latitude/Longitude')
                        "
                        v-model="$v.restaurant.location_address.$model"
                        v-on:placechanged="getAddressData"
                        :class="{ 'is-invalid': $v.restaurant.location_address.$error }"
                        :fields="['formatted_address', 'geometry', 'name']"
                        types=""
                      >
                      </vue-google-autocomplete>

                      <div
                        v-if="$v.restaurant.location_address.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.restaurant.location_address.required">{{
                          trans.get("__JSON__.Please enter address")
                        }}</span>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.Vendor Full address", {
                          vendor: $auth.setting.restaurant_name,
                        })
                      }}</label>
                      <input
                        id="location_address"
                        name="location_address"
                        type="text"
                        v-model="$v.restaurant.address.$model"
                        class="form-control"
                        :placeholder="
                          trans.trans('__JSON__.Enter Vendor full address', {
                            vendor: $auth.setting.restaurant_name,
                          })
                        "
                        :class="{ 'is-invalid': $v.restaurant.address.$error }"
                      />
                      <div v-if="$v.restaurant.address.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.address.required">{{
                          trans.trans("__JSON__.Please enter Vendor full address", {
                            vendor: $auth.setting.restaurant_name,
                          })
                        }}</span>
                      </div>
                    </div>

                    <div
                      v-if="$auth.langauges[1].name == 'No Secondary Language'"
                      class="form-group d-none"
                    ></div>
                    <div class="form-group" v-else>
                      <label class="control-label">{{
                        trans.trans(
                          "__JSON__.Vendor Full address Secondary Language (Optional)",
                          {
                            vendor: $auth.setting.restaurant_name,
                            lang: $auth.langauges[1].name,
                          }
                        )
                      }}</label>
                      <input
                        id="address_thai"
                        name="address_thai"
                        v-model="restaurant.address_thai"
                        type="text"
                        class="form-control"
                        :placeholder="
                          trans.trans(
                            '__JSON__.Enter Vendor full address Secondary language',
                            {
                              vendor: $auth.setting.restaurant_name,
                              lang: $auth.langauges[1].name,
                            }
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div v-if="state_count > 1" class="form-group">
                      <label class="control-label">{{
                        trans.get("__JSON__.State")
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.restaurant.state.$model"
                        @change="getVendorCity"
                        id="state"
                        name="state"
                        :class="{ 'is-invalid': $v.restaurant.state.$error }"
                      >
                        <option value selected>
                          {{ trans.get("__JSON__.Select State") }}
                        </option>
                        <option v-for="sat in states" :value="sat.id" :key="sat.id">
                          {{ sat.state_name }}
                        </option>
                      </select>
                      <div v-if="$v.restaurant.state.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.state.required">{{
                          trans.get("__JSON__.Please select state")
                        }}</span>
                      </div>
                    </div>

                    <div class="map1" id="map" style="height: 300px">
                      <!-- <gmap-map :center="center" :zoom="7" style="height: 300px">
                        <gmap-marker
                          v-for="(m, index) in markers"
                          :key="index"
                          :position="m.position"
                          :clickable="true"
                          :draggable="true"
                          @click="center = m.position"
                        ></gmap-marker>
                      </gmap-map> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Location Section End -->

            <!-- Other Info Start -->
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">{{ trans.get("__JSON__.Other Info") }}</h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <!-- <div class="col-sm-6">
                    <div class="form-group">
                      <label>
                        {{ trans.get('__JSON__.Minimum Order Amount') }} ( {{ vendor_currency}} )
                      </label>
                      <input
                        id="minimum_order_value"
                        name="minimum_order_value"
                        type="number"
                        v-model="$v.restaurant.minimum_order_value.$model"
                        class="form-control"
                        :placeholder="trans.get('__JSON__.Minimum Order Amount')"
                        :class="{ 'is-invalid':$v.restaurant.minimum_order_value.$error }"
                      />
                      <div v-if="$v.restaurant.minimum_order_value.$error" class="invalid-feedback">
                        <span v-if="!$v.restaurant.minimum_order_value.required">{{ trans.get('__JSON__.Please enter amount') }}</span>
                        <span
                              v-if="!$v.restaurant.minimum_order_value.floating"
                            >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>
                        {{ trans.get("__JSON__.Cost for two person") }} (
                        {{ vendor_currency }} ) ({{
                          trans.get("__JSON__.Applicable for Restaurants")
                        }})
                      </label>
                      <input
                        id="two_person_price"
                        name="two_person_price"
                        type="text"
                        v-model="restaurant.two_person_price"
                        class="form-control"
                        :placeholder="trans.get('__JSON__.Cost for two person')"
                        v-on:keypress="isNumber($event)"
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{
                        trans.get("__JSON__.Avg. Processing Time (Min)")
                      }}</label>
                      <input
                        maxlength="6"
                        id="delivery_time"
                        name="delivery_time"
                        type="number"
                        v-model="$v.restaurant.delivery_time.$model"
                        class="form-control"
                        :placeholder="trans.get('__JSON__.Avg. Processing Time (Min)')"
                        :class="{ 'is-invalid': $v.restaurant.delivery_time.$error }"
                      />
                      <div
                        v-if="$v.restaurant.delivery_time.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.restaurant.delivery_time.required">{{
                          trans.get("__JSON__.Please enter avg delivery time")
                        }}</span>
                        <span v-if="!$v.restaurant.delivery_time.numeric">{{
                          trans.get("__JSON__.Please enter avg delivery time")
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.get("__JSON__.FSSAI licence number") }}</label>
                      <input
                        id="fssai_licence"
                        name="fssai_licence"
                        type="text"
                        v-model="restaurant.fssai_licence"
                        class="form-control"
                        :placeholder="trans.get('__JSON__.FSSAI licence number')"
                        v-on:keypress="isLetterOrNumber($event)"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.get("__JSON__.Admin Commission Type")
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="restaurant.admin_commision_type"
                        id="admin_commision_type"
                        name="admin_commision_type"
                      >
                        <option value="">
                          {{ trans.get("__JSON__.Select Admin Commission") }}
                        </option>
                        <option value="commission_in_INR">
                          {{ trans.get("__JSON__.Fixed") }} ( {{ vendor_currency }} )
                        </option>
                        <option value="commission_in_percentage">
                          {{ trans.get("__JSON__.Percentage") }} (%)
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.get("__JSON__.Admin Commission Value") }}</label>
                      <input
                        id="admin_commision"
                        name="admin_commision"
                        type="number"
                        disabled
                        v-model="$v.restaurant.admin_commision.$model"
                        class="form-control"
                        :placeholder="trans.get('__JSON__.Admin Commission Value')"
                        :class="{ 'is-invalid': $v.restaurant.admin_commision.$error }"
                      />
                      <div
                        v-if="$v.restaurant.admin_commision.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.restaurant.admin_commision.required">{{
                          trans.get("__JSON__.Please enter admin commission")
                        }}</span>
                        <span v-if="!$v.restaurant.admin_commision.floating"
                          >{{ trans.get("__JSON__.This value should be digits") }}.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.Vendor Type", {
                          vendor: $auth.setting.restaurant_name,
                        })
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="restaurant.restaurant_type"
                        id="restaurant_type"
                        name="restaurant_type"
                      >
                        <option value="">
                          {{
                            trans.get("__JSON__.Select Vendor Type", {
                              vendor: $auth.setting.restaurant_name,
                            })
                          }}
                        </option>
                        <option v-bind:value="0" selected>
                          {{ trans.get("__JSON__.All") }}
                        </option>
                        <option v-bind:value="1">
                          {{ trans.get("__JSON__.Pure Veg") }}
                        </option>
                        <option v-bind:value="2">
                          {{ trans.get("__JSON__.Non Veg") }}
                        </option>
                        <option v-bind:value="3">
                          {{ trans.get("__JSON__.Not Applicable") }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label"
                        >{{ trans.get("__JSON__.Time Slot") }}ad sad</label
                      >
                      <select
                        name="time_slots"
                        class="custom-select"
                        id="time_slots"
                        v-model="$v.restaurant.time_slots.$model"
                        :class="{ 'is-invalid': $v.restaurant.time_slots.$error }"
                      >
                        <option value="">
                          {{ trans.get("__JSON__.Select Time Slot") }}
                        </option>
                        <option value="15">15 {{ trans.get("__JSON__.Mins") }}</option>
                        <option value="30">30 {{ trans.get("__JSON__.Mins") }}</option>
                        <option value="45">45 {{ trans.get("__JSON__.Mins") }}</option>
                        <option value="60">1 {{ trans.get("__JSON__.Hour") }}</option>
                        <option value="120">2 {{ trans.get("__JSON__.Hours") }}</option>
                        <option value="180">3 {{ trans.get("__JSON__.Hours") }}</option>
                        <option value="240">4 {{ trans.get("__JSON__.Hours") }}</option>
                        <option value="480">8 {{ trans.get("__JSON__.Hours") }}</option>
                        <option value="540">9 {{ trans.get("__JSON__.Hours") }}</option>
                      </select>
                      <div
                        v-if="$v.restaurant.time_slots.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.restaurant.time_slots.required"
                          >Please enter time slots</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6" v-if="$auth.setting.is_pickup_enable == true">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.get("__JSON__.Delivery Type & Time Slots")
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="restaurant.delivery_type_time_slots"
                        id="delivery_type_time_slots"
                        name="delivery_type_time_slots"
                      >
                        <option value="">
                          {{ trans.get("__JSON__.Select Delivery Type") }}
                        </option>
                        <option v-bind:value="0">
                          {{ trans.get("__JSON__.Pickup") }}
                        </option>
                        <option v-bind:value="1">
                          {{ trans.get("__JSON__.Delivery") }}
                        </option>
                        <option v-bind:value="2" selected>
                          {{ trans.get("__JSON__.Both") }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label"
                        >{{
                          trans.get("__JSON__.GST", { gst: $auth.getTaxName() })
                        }}
                        (%)</label
                      >
                      <input
                        id="restaurant_tax"
                        name="restaurant_tax"
                        type="text"
                        class="form-control"
                        :placeholder="
                          trans.get('__JSON__.Vendor GST', { gst: $auth.getTaxName() })
                        "
                        v-model="restaurant.restaurant_tax"
                        v-on:keypress="isNumber($event)"
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.Vendor Details Page Layout", {
                          vendor: $auth.setting.restaurant_name,
                        })
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="restaurant.restaurant_item_layout"
                        id="restaurant_item_layout"
                        name="restaurant_item_layout"
                      >
                        <option value="">
                          {{ trans.get("__JSON__.Select Page Layout") }}
                        </option>
                        <option v-bind:value="1" selected>
                          {{ trans.get("__JSON__.Layout") }} 1
                        </option>
                        <option v-bind:value="2">
                          {{ trans.get("__JSON__.Layout") }} 2
                        </option>
                        <option v-bind:value="3">
                          {{ trans.get("__JSON__.Layout") }} 3
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.Scheduling Order")
                      }}</label>
                      <select
                        class="custom-select"
                        v-model="restaurant.is_schedule_order"
                        id="is_schedule_order"
                        name="is_schedule_order"
                      >
                        <option value="">{{ trans.get("__JSON__.Select One") }}</option>
                        <option v-bind:value="'0'">
                          {{ trans.get("__JSON__.Off") }}
                        </option>
                        <option v-bind:value="'1'">{{ trans.get("__JSON__.On") }}</option>
                      </select>
                    </div>
                    <div
                      v-if="$v.restaurant.is_schedule_order.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span v-if="!$v.restaurant.is_schedule_order.required">{{
                        trans.trans("__JSON__.required", {
                          attribute: trans.trans("__JSON__.Scheduling Order"),
                        })
                      }}</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.No. Of Table")
                      }}</label>
                      <input
                        id="restaurant_tax"
                        name="restaurant_tax"
                        type="text"
                        class="form-control"
                        :placeholder="trans.get('__JSON__.No. Of Table')"
                        v-model="$v.restaurant.no_of_tables.$model"
                        v-on:keypress="isNumber($event)"
                      />
                    </div>
                    <div
                      v-if="$v.restaurant.no_of_tables.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span v-if="!$v.restaurant.no_of_tables.required">{{
                        trans.trans("__JSON__.required", {
                          attribute: trans.trans("__JSON__.No. Of Table"),
                        })
                      }}</span>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.Dunzo Enabled")
                      }}</label
                      ><br />
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="dunzo_enable"
                          v-model="restaurant.dunzo_enable"
                          class="switch-on"
                          true-value="1"
                          false-value="0"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get("__JSON__.Yes") }}</span>
                          <span class="off">{{ trans.get("__JSON__.No") }}</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.Pidge Enabled")
                      }}</label
                      ><br />
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="pidge_enable"
                          v-model="restaurant.pidge_enable"
                          class="switch-on"
                          true-value="1"
                          false-value="0"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get("__JSON__.Yes") }}</span>
                          <span class="off">{{ trans.get("__JSON__.No") }}</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{
                        trans.trans("__JSON__.Chowman Pay")
                      }}</label
                      ><br />
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="chowman_pay_status"
                          v-model="restaurant.chowman_pay_status"
                          class="switch-on"
                          true-value="1"
                          false-value="0"
                        />
                        <div class="slider round">
                          <span class="on">{{ trans.get("__JSON__.Yes") }}</span>
                          <span class="off">{{ trans.get("__JSON__.No") }}</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-6" v-if="restaurant.chowman_pay_status == '1'">
                    <div class="form-group">
                      <label class="control-label"
                        >{{ trans.get("__JSON__.Default Pay Cashback") }} (%)</label
                      >
                      <input
                        id="default_pay_cashback"
                        name="default_pay_cashback"
                        type="text"
                        class="form-control"
                        :placeholder="trans.get('__JSON__.Default Pay Cashback')"
                        v-model="$v.restaurant.default_pay_cashback.$model"
                      />
                    </div>
                    <div
                      v-if="$v.restaurant.default_pay_cashback.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span v-if="!$v.restaurant.default_pay_cashback.required">
                        {{
                          trans.get("__JSON__.required", {
                            attribute: trans.trans("__JSON__.Default Pay Cashback"),
                          })
                        }}
                      </span>
                      <span v-else-if="!$v.restaurant.default_pay_cashback.maxValue">
                        {{
                          trans.get("__JSON__.maxValue", {
                            attribute: trans.trans("__JSON__.Default Pay Cashback"),
                          })
                        }}
                      </span>
                      <span v-else-if="!$v.restaurant.default_pay_cashback.minValue">
                        {{
                          trans.get("__JSON__.minValue", {
                            attribute: trans.trans("__JSON__.Default Pay Cashback"),
                          })
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Other Info End -->
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">{{ trans.trans("__JSON__.Weather") }}</h4>
                <p class="card-title-desc"></p>
                <label for="weather_msg">Weather Message</label>
                <textarea
                  name="weather_msg"
                  class="form-control"
                  id=""
                  cols="30"
                  rows="3"
                  v-model="weather_text"
                ></textarea>
              </div>
            </div>
            <!-- SEO start -->
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">SEO</h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <label for="seo_title">Page Title</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="restaurant.seo_title"
                  />
                </div>
                <div class="row">
                  <div class="col">
                    <label for="seo_meta">Meta Description For SEO</label>
                    <textarea
                      name="seo_meta"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="3"
                      v-model="restaurant.seo_meta"
                    ></textarea>
                  </div>
                  <div class="col">
                    <label for="seo_keyword">Keywords For SEO</label>
                    <textarea
                      name="seo_keyword"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="3"
                      v-model="restaurant.seo_keyword"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <!-- SEO end -->
            <!-- Vendor Image Start -->
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">
                  {{
                    trans.trans("__JSON__.Vendor Image", {
                      vendor: $auth.setting.restaurant_name,
                    })
                  }}
                </h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <div class="col-sm-3 imgUp">
                    <dropzone-popup
                      v-model="restaurant.image"
                      id="icon_image"
                      acceptedFiles=".jpeg,.jpg,.png"
                      :editable-images="icon_image"
                      :media-path="restaurant_media_path + '/original'"
                      :delete-path="icon_image_delete_url"
                      :custom-params="{ driverid: $route.params.driver_id }"
                      @image-deleted="this.onIconImagedeleted"
                      :class="{ 'is-invalid': $v.restaurant.image.$error }"
                    />
                    <div
                      v-if="$v.restaurant.image.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span v-if="!$v.restaurant.image.required">{{
                        trans.get("__JSON__.Please select image")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <button
                      type="submit"
                      v-if="
                        $auth.hasPermission(config.permissions.merchant_write) &&
                        !$auth.partner_login
                      "
                      class="btn btn-primary mr-1 mt-3"
                      :disabled="disable"
                    >
                      {{
                        trans.trans("__JSON__.Update Vendor", {
                          vendor: $auth.setting.restaurant_name,
                        })
                      }}
                    </button>
                    <b-button
                      @click="$router.back()"
                      type="button"
                      variant="secondary"
                      class="mt-3"
                      >{{ trans.get("__JSON__.Cancel") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Vendor Image End -->
          </form>
        </div>
      </div>
    </Layout>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <form @submit.prevent="vendorUpdate">
          <!-- Vendor Info Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                {{
                  trans.trans("__JSON__.Vendor Info", {
                    vendor: $auth.setting.restaurant_name,
                  })
                }}
              </h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group" v-if="$auth.setting">
                    <label>{{
                      trans.trans("__JSON__.Vendor Name", {
                        vendor: $auth.setting.restaurant_name,
                        lang: $auth.langauges[0].name,
                      })
                    }}</label>
                    <input
                      id="name"
                      v-model="$v.restaurant.name.$model"
                      type="text"
                      class="form-control"
                      :placeholder="
                        trans.trans('__JSON__.Enter Vendor Name langauge', {
                          vendor: $auth.setting.restaurant_name,
                          lang: $auth.langauges[0].name,
                        })
                      "
                      name="name"
                      :class="{ 'is-invalid': $v.restaurant.name.$error }"
                    />
                    <div v-if="$v.restaurant.name.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.name.required">{{
                        trans.trans("__JSON__.Please enter vendor name", {
                          vendor: $auth.setting.restaurant_name,
                        })
                      }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-sm-6 d-none"
                  v-if="$auth.langauges[1].name === 'No Secondary Language'"
                ></div>
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label>{{
                      trans.trans("__JSON__.Vendor Name", {
                        vendor: $auth.setting.restaurant_name_sec_lang,
                        lang: $auth.langauges[1].name,
                      })
                    }}</label>
                    <input
                      maxlength="100"
                      id="name_thai"
                      name="name_thai"
                      type="text"
                      v-model="restaurant.name_thai"
                      :placeholder="
                        trans.trans('__JSON__.Enter Vendor Name langauge', {
                          vendor: $auth.setting.restaurant_name_sec_lang,
                          lang: $auth.langauges[1].name,
                        })
                      "
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get("__JSON__.E-Mail") }}</label>
                    <input
                      id="email"
                      v-model="$v.restaurant.email.$model"
                      type="email"
                      name="email"
                      class="form-control"
                      @blur="userCheckEmail"
                      :class="{ 'is-invalid': $v.restaurant.email.$error }"
                      placeholder="Enter a valid e-mail"
                    />
                    <div v-if="$v.restaurant.email.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.email.required">{{
                        trans.get("__JSON__.Please enter email")
                      }}</span>
                      <span v-if="!$v.restaurant.email.email">{{
                        trans.get("__JSON__.This value should be a valid email")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get("__JSON__.Contact Number") }}</label>
                    <input
                      maxlength="12"
                      minlength="10"
                      id="phone"
                      v-model="$v.restaurant.phone.$model"
                      type="tel"
                      name="phone"
                      class="form-control"
                      :class="{ 'is-invalid': $v.restaurant.phone.$error }"
                      :placeholder="trans.get('__JSON__.Enter Contact Number')"
                    />
                    <div v-if="$v.restaurant.phone.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.phone.required">{{
                        trans.get("__JSON__.Please enter contact number")
                      }}</span>
                      <span v-if="!$v.restaurant.phone.numeric">{{
                        trans.get("__JSON__.This value should be a valid number")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.get("__JSON__.Select Category")
                    }}</label>
                    <select
                      class="custom-select"
                      v-model="restaurant.category_id"
                      id="category_id"
                      name="category_id"
                    >
                      <option value="0" selected>{{ trans.get("__JSON__.None") }}</option>
                      <option
                        v-for="res in rescategories"
                        :value="res.category_id"
                        :key="res.category_id"
                      >
                        {{ res.category_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.get(
                        "__JSON__.Select Tags (For Restaurants it will be cuisines)"
                      )
                    }}</label>
                    <multiselect
                      v-model="restaurant.cuisines"
                      id="ajax"
                      label="cuisine_name"
                      track-by="cuisine_id"
                      :placeholder="trans.get('__JSON__.Search...')"
                      open-direction="bottom"
                      :options="cuisine"
                      :multiple="true"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :options-limit="5"
                      :limit="3"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                    >
                      <!-- @search-change="asyncFind" -->
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.cuisine_name }}</span>
                          <span class="custom__remove" @click="remove(option)">❌</span>
                        </span>
                      </template>
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="cuisine.length"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div>
                      </template>
                      <span slot="noResult">{{
                        trans.get(
                          "__JSON__Oops! No elements found. Consider changing the search query"
                        )
                      }}</span>
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.trans("__JSON__.Pet Pooja Restaurant ID") }}</label>
                    <input
                      maxlength="100"
                      id="pet_pooja_restaurant_id"
                      name="pet_pooja_restaurant_id"
                      type="text"
                      v-model="$v.restaurant.pet_pooja_restaurant_id.$model"
                      :placeholder="trans.trans('__JSON__.Enter Pet Pooja Restaurant ID')"
                      class="form-control"
                      :class="{
                        'is-invalid': $v.restaurant.pet_pooja_restaurant_id.$error,
                      }"
                    />
                    <div
                      v-if="$v.restaurant.pet_pooja_restaurant_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurant.pet_pooja_restaurant_id.required">{{
                        trans.get("__JSON__.Please enter pet pooja restaurant ID")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Status') }}</label> 
                      <select class="custom-select" v-bind:value="restaurant.is_confirm" v-model="restaurant.is_confirm"  id="is_confirm" name="is_confirm"> 
                          <option value="0">Inactive</option>
                          <option value="1">Active</option>
                          <option value="2">Waiting For Approval</option>
                      </select>
                    </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- Vendor Info End -->

          <!-- Location Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get("__JSON__.Location") }}</h4>
              <p class="card-title-desc"></p>

              <div class="row">
                <div class="col-sm-6">
                  <div v-if="country_count > 1" class="form-group">
                    <label class="control-label">{{
                      trans.get("__JSON__.Country")
                    }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.restaurant.country.$model"
                      @change="getVendorState"
                      id="country"
                      name="country"
                      :class="{ 'is-invalid': $v.restaurant.country.$error }"
                    >
                      <option value selected>
                        {{ trans.get("__JSON__.Select Country") }}
                      </option>
                      <option v-for="con in countries" :value="con.id" :key="con.id">
                        {{ con.country_name }}
                      </option>
                    </select>
                    <div v-if="$v.restaurant.country.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.country.required">{{
                        trans.get("__JSON__.Please select country")
                      }}</span>
                    </div>
                  </div>

                  <div v-if="state_count >= 1 && city_count > 1" class="form-group">
                    <label class="control-label">{{ trans.get("__JSON__.City") }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.restaurant.city.$model"
                      id="city"
                      name="city"
                      :class="{ 'is-invalid': $v.restaurant.city.$error }"
                    >
                      <option value selected>
                        {{ trans.get("__JSON__.Select City") }}
                      </option>
                      <option v-for="cit in cities" :value="cit.cityid" :key="cit.cityid">
                        {{ cit.cityname }}
                      </option>
                    </select>
                    <div v-if="$v.restaurant.city.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.city.required">{{
                        trans.get("__JSON__.Please select city")
                      }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="control-label">{{
                      trans.get("__JSON__.Location based on Latitude/Longitude")
                    }}</label>
                    <vue-google-autocomplete
                      ref="address"
                      id="address"
                      name="address"
                      type="text"
                      class="form-control"
                      :placeholder="
                        trans.get('__JSON__.Enter location based on Latitude/Longitude')
                      "
                      v-model="$v.restaurant.location_address.$model"
                      v-on:placechanged="getAddressData"
                      :class="{ 'is-invalid': $v.restaurant.location_address.$error }"
                      types=""
                    >
                    </vue-google-autocomplete>

                    <div
                      v-if="$v.restaurant.location_address.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurant.location_address.required">{{
                        trans.get("__JSON__.Please enter address")
                      }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.Vendor Full address", {
                        vendor: $auth.setting.restaurant_name,
                      })
                    }}</label>
                    <input
                      id="location_address"
                      name="location_address"
                      type="text"
                      v-model="$v.restaurant.address.$model"
                      class="form-control"
                      :placeholder="
                        trans.trans('__JSON__.Enter Vendor full address', {
                          vendor: $auth.setting.restaurant_name,
                        })
                      "
                      :class="{ 'is-invalid': $v.restaurant.address.$error }"
                    />
                    <div v-if="$v.restaurant.address.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.address.required">{{
                        trans.trans("__JSON__.Please enter Vendor full address", {
                          vendor: $auth.setting.restaurant_name,
                        })
                      }}</span>
                    </div>
                  </div>

                  <div
                    v-if="$auth.langauges[1].name == 'No Secondary Language'"
                    class="form-group d-none"
                  ></div>
                  <div class="form-group" v-else>
                    <label class="control-label">{{
                      trans.trans(
                        "__JSON__.Vendor Full address Secondary Language (Optional)",
                        {
                          vendor: $auth.setting.restaurant_name,
                          lang: $auth.langauges[1].name,
                        }
                      )
                    }}</label>
                    <input
                      id="address_thai"
                      name="address_thai"
                      v-model="restaurant.address_thai"
                      type="text"
                      class="form-control"
                      :placeholder="
                        trans.trans(
                          '__JSON__.Enter Vendor full address Secondary language',
                          {
                            vendor: $auth.setting.restaurant_name,
                            lang: $auth.langauges[1].name,
                          }
                        )
                      "
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div v-if="state_count > 1" class="form-group">
                    <label class="control-label">{{ trans.get("__JSON__.State") }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.restaurant.state.$model"
                      @change="getVendorCity"
                      id="state"
                      name="state"
                      :class="{ 'is-invalid': $v.restaurant.state.$error }"
                    >
                      <option value selected>
                        {{ trans.get("__JSON__.Select State") }}
                      </option>
                      <option v-for="sat in states" :value="sat.id" :key="sat.id">
                        {{ sat.state_name }}
                      </option>
                    </select>
                    <div v-if="$v.restaurant.state.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.state.required">{{
                        trans.get("__JSON__.Please select state")
                      }}</span>
                    </div>
                  </div>

                  <div class="map1" id="map" style="height: 300px">
                    <!-- <gmap-map :center="center" :zoom="7" style="height: 300px">
                      <gmap-marker
                        v-for="(m, index) in markers"
                        :key="index"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @click="center = m.position"
                      ></gmap-marker>
                    </gmap-map> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Location Section End -->

          <!-- Other Info Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get("__JSON__.Other Info") }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      {{ trans.get('__JSON__.Minimum Order Amount') }} ( {{ vendor_currency}} )
                    </label>
                    <input
                      id="minimum_order_value"
                      name="minimum_order_value"
                      type="number"
                      v-model="$v.restaurant.minimum_order_value.$model"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.Minimum Order Amount')"
                      :class="{ 'is-invalid':$v.restaurant.minimum_order_value.$error }"
                    />
                    <div v-if="$v.restaurant.minimum_order_value.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.minimum_order_value.required">{{ trans.get('__JSON__.Please enter amount') }}</span>
                      <span
                            v-if="!$v.restaurant.minimum_order_value.floating"
                          >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                    </div>
                  </div>
                </div> -->

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      {{ trans.get("__JSON__.Cost for two person") }} (
                      {{ vendor_currency }} ) ({{
                        trans.get("__JSON__.Applicable for Restaurants")
                      }})
                    </label>
                    <input
                      id="two_person_price"
                      name="two_person_price"
                      type="text"
                      v-model="restaurant.two_person_price"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.Cost for two person')"
                      v-on:keypress="isNumber($event)"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get("__JSON__.Avg. Processing Time (Min)") }}</label>
                    <input
                      maxlength="6"
                      id="delivery_time"
                      name="delivery_time"
                      type="number"
                      v-model="$v.restaurant.delivery_time.$model"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.Avg. Processing Time (Min)')"
                      :class="{ 'is-invalid': $v.restaurant.delivery_time.$error }"
                    />
                    <div
                      v-if="$v.restaurant.delivery_time.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurant.delivery_time.required">{{
                        trans.get("__JSON__.Please enter avg delivery time")
                      }}</span>
                      <span v-if="!$v.restaurant.delivery_time.numeric">{{
                        trans.get("__JSON__.Please enter avg delivery time")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get("__JSON__.FSSAI licence number") }}</label>
                    <input
                      id="fssai_licence"
                      name="fssai_licence"
                      type="text"
                      v-model="restaurant.fssai_licence"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.FSSAI licence number')"
                      v-on:keypress="isLetterOrNumber($event)"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.get("__JSON__.Admin Commission Type")
                    }}</label>
                    <select
                      class="custom-select"
                      v-model="restaurant.admin_commision_type"
                      id="admin_commision_type"
                      name="admin_commision_type"
                    >
                      <option value="">
                        {{ trans.get("__JSON__.Select Admin Commission") }}
                      </option>
                      <option value="commission_in_INR">
                        {{ trans.get("__JSON__.Fixed") }} ( {{ vendor_currency }} )
                      </option>
                      <option value="commission_in_percentage">
                        {{ trans.get("__JSON__.Percentage") }} (%)
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get("__JSON__.Admin Commission Value") }}</label>
                    <input
                      id="admin_commision"
                      name="admin_commision"
                      type="number"
                      v-model="$v.restaurant.admin_commision.$model"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.Admin Commission Value')"
                      :class="{ 'is-invalid': $v.restaurant.admin_commision.$error }"
                    />
                    <div
                      v-if="$v.restaurant.admin_commision.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurant.admin_commision.required">{{
                        trans.get("__JSON__.Please enter admin commission")
                      }}</span>
                      <span v-if="!$v.restaurant.admin_commision.floating"
                        >{{ trans.get("__JSON__.This value should be digits") }}.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.Vendor Type", {
                        vendor: $auth.setting.restaurant_name,
                      })
                    }}</label>
                    <select
                      class="custom-select"
                      v-model="restaurant.restaurant_type"
                      id="restaurant_type"
                      name="restaurant_type"
                    >
                      <option value="">
                        {{
                          trans.get("__JSON__.Select Vendor Type", {
                            vendor: $auth.setting.restaurant_name,
                          })
                        }}
                      </option>
                      <option v-bind:value="0" selected>
                        {{ trans.get("__JSON__.All") }}
                      </option>
                      <option v-bind:value="1">
                        {{ trans.get("__JSON__.Pure Veg") }}
                      </option>
                      <option v-bind:value="2">
                        {{ trans.get("__JSON__.Non Veg") }}
                      </option>
                      <option v-bind:value="3">
                        {{ trans.get("__JSON__.Not Applicable") }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.get("__JSON__.Time Slot")
                    }}</label>
                    <select
                      name="time_slots"
                      class="custom-select"
                      id="time_slots"
                      v-model="$v.restaurant.time_slots.$model"
                      :class="{ 'is-invalid': $v.restaurant.time_slots.$error }"
                    >
                      <option value="">
                        {{ trans.get("__JSON__.Select Time Slot") }}
                      </option>
                      <option value="15" selected>
                        15 {{ trans.get("__JSON__.Mins") }}
                      </option>
                      <option value="30">30 {{ trans.get("__JSON__.Mins") }}</option>
                      <option value="45">45 {{ trans.get("__JSON__.Mins") }}</option>
                      <option value="60">1 {{ trans.get("__JSON__.Hour") }}</option>
                      <option value="120">2 {{ trans.get("__JSON__.Hours") }}</option>
                      <option value="180">3 {{ trans.get("__JSON__.Hours") }}</option>
                      <option value="240">4 {{ trans.get("__JSON__.Hours") }}</option>
                      <option value="480">8 {{ trans.get("__JSON__.Hours") }}</option>
                      <option value="540">9 {{ trans.get("__JSON__.Hours") }}</option>
                    </select>
                    <div v-if="$v.restaurant.time_slots.$error" class="invalid-feedback">
                      <span v-if="!$v.restaurant.time_slots.required"
                        >Please enter time slots</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-sm-6" v-if="$auth.setting.is_pickup_enable == true">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.get("__JSON__.Delivery Type & Time Slots")
                    }}</label>
                    <select
                      class="custom-select"
                      v-model="restaurant.delivery_type_time_slots"
                      id="delivery_type_time_slots"
                      name="delivery_type_time_slots"
                    >
                      <option value="">
                        {{ trans.get("__JSON__.Select Delivery Type") }}
                      </option>
                      <option v-bind:value="0">{{ trans.get("__JSON__.Pickup") }}</option>
                      <option v-bind:value="1">
                        {{ trans.get("__JSON__.Delivery") }}
                      </option>
                      <option v-bind:value="2" selected>
                        {{ trans.get("__JSON__.Both") }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label"
                      >{{
                        trans.get("__JSON__.GST", { gst: $auth.getTaxName() })
                      }}
                      (%)</label
                    >
                    <input
                      id="restaurant_tax"
                      name="restaurant_tax"
                      type="text"
                      class="form-control"
                      :placeholder="
                        trans.get('__JSON__.Vendor GST', { gst: $auth.getTaxName() })
                      "
                      v-model="restaurant.restaurant_tax"
                      v-on:keypress="isNumber($event)"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.Vendor Details Page Layout", {
                        vendor: $auth.setting.restaurant_name,
                      })
                    }}</label>
                    <select
                      class="custom-select"
                      v-model="restaurant.restaurant_item_layout"
                      id="restaurant_item_layout"
                      name="restaurant_item_layout"
                    >
                      <option value="">
                        {{ trans.get("__JSON__.Select Page Layout") }}
                      </option>
                      <option v-bind:value="1" selected>
                        {{ trans.get("__JSON__.Layout") }} 1
                      </option>
                      <option v-bind:value="2">
                        {{ trans.get("__JSON__.Layout") }} 2
                      </option>
                      <option v-bind:value="3">
                        {{ trans.get("__JSON__.Layout") }} 3
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.Scheduling Order")
                    }}</label>
                    <select
                      class="custom-select"
                      v-model="restaurant.is_schedule_order"
                      id="is_schedule_order"
                      name="is_schedule_order"
                    >
                      <option value="">{{ trans.get("__JSON__.Select One") }}</option>
                      <option v-bind:value="'0'">{{ trans.get("__JSON__.Off") }}</option>
                      <option v-bind:value="'1'">{{ trans.get("__JSON__.On") }}</option>
                    </select>
                  </div>
                  <div
                    v-if="$v.restaurant.is_schedule_order.$error"
                    class="invalid-feedback cuisine-img"
                  >
                    <span v-if="!$v.restaurant.is_schedule_order.required">{{
                      trans.trans("__JSON__.required", {
                        attribute: trans.trans("__JSON__.Scheduling Order"),
                      })
                    }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.No. Of Table")
                    }}</label>
                    <input
                      id="restaurant_tax"
                      name="restaurant_tax"
                      type="text"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.No. Of Table')"
                      v-model="$v.restaurant.no_of_tables.$model"
                      v-on:keypress="isNumber($event)"
                    />
                  </div>
                  <div
                    v-if="$v.restaurant.no_of_tables.$error"
                    class="invalid-feedback cuisine-img"
                  >
                    <span v-if="!$v.restaurant.no_of_tables.required">{{
                      trans.trans("__JSON__.required", {
                        attribute: trans.trans("__JSON__.No. Of Table"),
                      })
                    }}</span>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.Dunzo Enabled")
                    }}</label
                    ><br />
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="dunzo_enable"
                        v-model="restaurant.dunzo_enable"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get("__JSON__.Yes") }}</span>
                        <span class="off">{{ trans.get("__JSON__.No") }}</span>
                      </div>
                    </label>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.Pidge Enabled")
                    }}</label
                    ><br />
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="pidge_enable"
                        v-model="restaurant.pidge_enable"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get("__JSON__.Yes") }}</span>
                        <span class="off">{{ trans.get("__JSON__.No") }}</span>
                      </div>
                    </label>
                  </div>
                </div>

                <!-- chowman pay -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{
                      trans.trans("__JSON__.Chowman Pay")
                    }}</label
                    ><br />
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="chowman_pay_status"
                        v-model="restaurant.chowman_pay_status"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get("__JSON__.Yes") }}</span>
                        <span class="off">{{ trans.get("__JSON__.No") }}</span>
                      </div>
                    </label>
                  </div>
                </div>

                <!-- Default Pay Cashback -->
                <div class="col-sm-6" v-if="restaurant.chowman_pay_status == '1'">
                  <div class="form-group">
                    <label class="control-label"
                      >{{ trans.get("__JSON__.Default Pay Cashback") }} (%)</label
                    >
                    <input
                      id="default_pay_cashback"
                      name="default_pay_cashback"
                      type="text"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.Default Pay Cashback')"
                      v-model="$v.restaurant.default_pay_cashback.$model"
                    />
                  </div>
                  <div
                    v-if="$v.restaurant.default_pay_cashback.$error"
                    class="invalid-feedback cuisine-img"
                  >
                    <span v-if="!$v.restaurant.default_pay_cashback.required">
                      {{
                        trans.get("__JSON__.required", {
                          attribute: trans.trans("__JSON__.Default Pay Cashback"),
                        })
                      }}
                    </span>
                    <span v-else-if="!$v.restaurant.default_pay_cashback.maxValue">
                      {{
                        trans.get("__JSON__.maxValue", {
                          attribute: trans.trans("__JSON__.Default Pay Cashback"),
                        })
                      }}
                    </span>
                    <span v-else-if="!$v.restaurant.default_pay_cashback.minValue">
                      {{
                        trans.get("__JSON__.minValue", {
                          attribute: trans.trans("__JSON__.Default Pay Cashback"),
                        })
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Other Info End -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.trans("__JSON__.Weather") }}</h4>
              <p class="card-title-desc"></p>
              <label for="weather_msg">Weather Message</label>
              <textarea
                name="weather_msg"
                class="form-control"
                id="weather_msg"
                cols="30"
                rows="3"
                v-model="weather_text"
              ></textarea>
            </div>
          </div>
          <!-- SEO start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">SEO</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col">
                  <label for="seo_title">Page Title</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="restaurant.seo_title"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="seo_meta">Meta Description For SEO</label>
                  <textarea
                    name="seo_meta"
                    class="form-control"
                    id=""
                    cols="30"
                    rows="3"
                    v-model="restaurant.seo_meta"
                  ></textarea>
                </div>
                <div class="col">
                  <label for="seo_keyword">Keywords For SEO</label>
                  <textarea
                    name="seo_keyword"
                    class="form-control"
                    id=""
                    cols="30"
                    rows="3"
                    v-model="restaurant.seo_keyword"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- SEO end -->
          <!-- Vendor Image Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                {{
                  trans.trans("__JSON__.Vendor Image", {
                    vendor: $auth.setting.restaurant_name,
                  })
                }}
              </h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-3 imgUp">
                  <dropzone-popup
                    v-model="restaurant.image"
                    id="icon_image"
                    acceptedFiles=".jpeg,.jpg,.png"
                    :editable-images="icon_image"
                    :media-path="restaurant_media_path + '/original'"
                    :delete-path="icon_image_delete_url"
                    :custom-params="{ driverid: $route.params.driver_id }"
                    @image-deleted="this.onIconImagedeleted"
                    :class="{ 'is-invalid': $v.restaurant.image.$error }"
                  />
                  <div
                    v-if="$v.restaurant.image.$error"
                    class="invalid-feedback cuisine-img"
                  >
                    <span v-if="!$v.restaurant.image.required">{{
                      trans.get("__JSON__.Please select image")
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <button
                    type="submit"
                    v-if="
                      $auth.hasPermission(config.permissions.merchant_write) &&
                      !$auth.partner_login
                    "
                    class="btn btn-primary mr-1 mt-3"
                    :disabled="disable"
                  >
                    {{
                      trans.trans("__JSON__.Update Vendor", {
                        vendor: $auth.setting.restaurant_name,
                      })
                    }}
                  </button>
                  <b-button
                    @click="$router.back()"
                    type="button"
                    variant="secondary"
                    class="mt-3"
                    >{{ trans.get("__JSON__.Cancel") }}</b-button
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- Vendor Image End -->
        </form>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
