<script>
import { get_currency, get_decimal } from '../../../Helper/helper'
import { bookingsService } from '../../../services'

export default{
  props:['booking_id'],
  data() {
    return {
      currency: get_currency(),
      decimal: get_decimal(),
      showModal: false,
      loading: false,
      booking: null
    }
  },
  mounted(){
        this.viewDetails()
  },
  methods : {
    viewDetails(){
      this.showModal = true;
      this.loading = true;
      bookingsService.getBooking(this.booking_id)
        .then(res => {
          this.booking = res.data.result;
          this.loading = false;
        })
    },
    onModalHide(){
      this.showModal = false;
      this.$emit('hidden');
    },
    btoa(str){
      return btoa(str);
    }
  }
}

</script>

<template>
<b-modal
    v-model="showModal"
    size="lg"
    scrollable
    title-class="font-18"
    hide-footer
    @hidden="onModalHide"
  >
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <h5>
        {{trans.get('__JSON__.Booking No')+' '+(booking && booking.booking_id || '000')}}
      </h5>
      <div>
      <button @click="close()" class="close">
        <i class="fas fa-times"></i>
      </button>
      </div>
    </template>
    <div class="row mt-3 settings-main">
      <div class="col-12">
        <div class="text-center text-danger my-2" v-if="loading">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{trans.get('__JSON__.Loading...')}}</strong>
        </div>
      </div>
      <div class="col-md-12" v-if="!loading">
        <div class="row">
          <div class="col-md-6">
            <h6>{{trans.get("__JSON__.Vendor's Name",{ vendor: $auth.getRestaurantName() })}}</h6>
            <p><b-link :to="{ name: 'vendor-detail', params:{restaurant_id: btoa(booking.restaurant_id) }}">{{ booking.vendor_name }}</b-link></p>
          </div>
          <div class="col-md-6">
            <h6>{{trans.get("__JSON__.Customer's Name")}}</h6>
            <p><b-link :to="{ name: 'customer-detail', params:{user_id: btoa(booking.user_id) }}">{{ booking.user_name }}</b-link></p>
          </div>
          <div class="col-md-6">
          </div>
        </div>
      </div>

      <div class="col-md-12" v-if="!loading">
        <div class="row sections-main">
          <div class="col-md-6">
            <h6>{{trans.get("__JSON__.Merchant Phone",{vendor: $auth.getRestaurantName()})}}</h6>
            <p>{{ '-' }}</p>
          </div>
          <div class="col-md-6">
            <h6>{{trans.get("__JSON__.Customer's Phone")}}</h6>
            <p>{{ booking.user_mobile_number }}</p>
          </div>
        </div>
      </div>


      <div class="col-md-12 mt-3" v-if="!loading">
        <div class="row sections-main">
          <div class="col-md-6" v-if="booking.created_at">
            <h6>{{trans.get('__JSON__.Placement time')}}</h6>
            <p v-if="booking.created_at != '0000-00-00 00:00:00'">{{ moment(booking.created_at).format('MMM DD[,] YYYY hh:mm A') }}</p>
          </div>
          <div class="col-md-6"  v-if="booking.future_delivery_date != '0000-00-00 00:00:00'">
            <h6>{{trans.get('__JSON__.Schedule Time')}}</h6>
            <div class="align-items-center d-flex">
              <span>
                {{ moment(booking.future_delivery_date).format('MMM DD[,] YYYY hh:mm A') }}
              </span>
            </div>
          </div>
          <div class="col-md-6" v-if="booking.confirmed_at && booking.confirmed_at != '0000-00-00 00:00:00'">
            <h6 >{{trans.get('__JSON__.Accepted Time')}}</h6>
            <p v-if="booking.confirmed_at && booking.confirmed_at != '0000-00-00 00:00:00'">{{ moment(booking.confirmed_at).format('MMM DD[,] YYYY hh:mm A') }}</p>
          </div>
          
          <div class="col-md-6" v-if="booking.completed_at && booking.status == 'Completed'">
            <h6>{{trans.get('__JSON__.Delivery Time')}}</h6>
            <p>{{ moment(booking.completed_at).format('MMM DD[,] YYYY hh:mm A') }}</p>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-3" v-if="!loading">
        <div class="row sections-main">
          <div class="col-md-6">
            <h6>{{trans.get('__JSON__.Number of guest')}}</h6>
            <p>{{ booking.guests_count }}</p>
          </div>
          <div class="col-md-6">
            <h6>{{trans.get('__JSON__.Notes')}}</h6>
            <p>{{ booking.notes || '-' }}</p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
