<template>
    <b-modal
        id="modal-sm"
        size="lg"
        title-class="font-18"
        @hidden="resetModal"
        @ok="rejectBooking"
        :ok-title="trans.get('__JSON__.Reject Booking')"
        ok-only
        ok-variant="danger"
        ref="reject-modal"
        lazy
        >
        <template #modal-header={close}>
            <div class="d-flex justify-content-between w-100">
                <h4 class="mb-0">{{trans.get('__JSON__.Reject Booking')}}</h4>
                <h4 class="mb-0">#{{"0000".substring(bookingId.toString().length)+bookingId}}</h4>
            </div>
            <button type="button" aria-label="Close" class="close" @click="close()">&times;</button>
        </template>

        <div class="row">
            <div class="col-12">
                <b-form role="form">
                    <div class="row">
                        <div class="col" v-for="(reason, index) in cancel_reasons" :key="index">
                            <a href="#" @click="onReasonSelect(reason)">
                            <b-card header-class="bg-transparent border-primary" class="border border-primary"
                                :bg-variant="other_selected && reason == 'Other' || reason == cancelled_desc ? 'primary' : 'default'"
                                 body-class="d-flex align-items-center justify-content-center height-100">
                                <h5 class="card-title mt-0" :class="{'text-white': (other_selected && reason == 'Other' || reason == cancelled_desc)}">{{reason}}</h5>
                            </b-card>
                            </a>
                        </div>
                    </div>
                    <b-form-group label="Reject Description" v-if="other_selected">
                         <b-form-textarea
                            id="textarea"
                            v-model="cancelled_desc"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                            ></b-form-textarea>
                    </b-form-group>
                </b-form>
            </div>
        </div>
    </b-modal>
</template>
<script>
import {booking_statuses, success_message} from './../../../Helper/helper'
import config from '../../../config';
import { bookingsService, orderService } from '../../../services';
import Swal from 'sweetalert2';

export default {
    props :['booking-id'],
    data(){
        return {
            config: config,
            showModal: false,
            statuses: booking_statuses,
            cancelled_desc: '',
            is_refundable: false,
            showPopup: false,
            order_id: null,
            cancel_reasons:[
                'Restaurant might be Full',
                'Restaurant may be closed',
                'Not taking bookings currently',
                'Other'
            ],
            other_selected: false,
        }
    },
    mounted(){
        this.rejectBookingPopup()
    },
     methods:{
        resetModal(){
            // this.order_id = null;
            this.is_refundable = false;
            this.$emit('hidden')
        },
        rejectBookingPopup(id,status){
            this.$refs['reject-modal'].show();
            // this.order_id = id;
        },
        rejectBooking(bvModalEvt){
            bvModalEvt.preventDefault()
            if(this.cancelled_desc == ''){
                this.$toasted.info('Please select reject reason.');
                return false;
            }
            const data = {booking_id: this.bookingId, status: booking_statuses.rejected,reject_note:this.cancelled_desc};
            bookingsService.changeBookingsStatusApi(data)
                .then(response => {
                    if(response.data.code == 200){
                        Swal.fire("Rejected!", "#"+ ("0000".substring(this.bookingId.length)+this.bookingId) +" booking has been rejected.", "success");
                    }else{
                        Swal.fire("Failed!", "#"+ ("0000".substring(this.bookingId.length)+this.bookingId) +" booking is not rejected.", "danger");
                    }
                    this.$refs['reject-modal'].hide();
                    this.$emit('actionChanged');
                })
        },
        onReasonSelect(reason){
            if(reason !== 'Other'){
                this.cancelled_desc = reason;
                this.other_selected = false;
            }else{
                this.other_selected = true;
                this.cancelled_desc = '';
            }
        }
     }
}
</script>
<style  scoped>
.height-100{
    height: 100px;
}
</style>