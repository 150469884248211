<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import ChartData from "../views/data-saas";
import config from "../config";
import { dashboardService, orderService, userService } from "../services";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  get_currency,
  replaceByDefault
} from "../Helper/helper";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      ChartData,
      currency: get_currency(),
      config,
      dashboard: [],
      stats: null,
      selling_count: "",
      item_count: "",
      thirty_vendor: "",
      thirty_driver: "",
      ninty_customer: "",
      replaceByDefault:replaceByDefault
    };
  },
  mounted() {
    history.pushState(null,null,location.href);
    window.onpopstate = function () {
        history.go(1);
    },
    this.getDashboardData();
    this.getDashboardStats();
    this.getOrdersCount();
  },
  methods: {
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getOrdersCount(){
      orderService
        .getNewOrders()
        .then((response) => {
          this.$auth.updateOrders(response.data.result)
        })
        .catch(function (error) {
        });
    },
    getDashboardData() {
      dashboardService.getDashboard().then((response) => {
        if (this.$auth.partner_login) {
          this.selling_count = response.data.result.items.length;
          this.item_count = response.data.result.items_menu.length;
        } else {
          this.thirty_vendor = response.data.result.thirty_vendor.length;
          this.thirty_driver = response.data.result.thirty_driver.length;
          this.ninty_customer = response.data.result.ninty_customer.length;
        }
        this.dashboard = response.data.result;
      });
    },
    getDashboardStats(){
      dashboardService.getStatistics().then((response) => {
        if (this.$auth.partner_login) {
        
        } else {
          this.stats = response.data.result;
        }
      });
    }
  },
};
</script>
<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="mdi mdi-speedometer"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Dashboard') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="media">
                  <div class="mr-3" v-if="$auth.setting">
                    <div v-if="$auth.partner_login">
                      <img
                        :src="config.restauranticon+$auth.user.restaurant.icon_image"
                        @error="replaceByDefault"
                        alt
                        class="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div v-else>
                      <div v-if="$auth.setting">
                        <img
                          :src="config.foodicon+$auth.setting.flat_icon"
                          @error="replaceByDefault"
                          alt
                          class="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="media-body align-self-center" v-if="$auth.setting">
                    <div class="text-muted">
                      <p class="mb-2">Welcome to {{$auth.setting.app_name}} dashboard</p>
                      <div v-if="$auth.partner_login">
                        <h5 class="mb-1">{{$auth.user.user_name}}</h5>
                      </div>
                      <div v-else>
                        <h5 class="mb-1">{{$auth.user.vendor_name}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 align-self-center" v-if="$auth.setting">
                <!-- <div >
                  <div class="text-lg-center mt-6 mt-lg-0">
                    <div class="row">
                      <div class="col-4">
                        <div>
                          <p
                            class="text-muted text-truncate mb-2"
                          >{{ trans.get('__JSON__.Orders Delivered') }}</p>
                          <h5 class="mb-0">{{ dashboard.total_order_delivered }}</h5>
                        </div>
                      </div>
                      <div class="col-4">
                        <div>
                          <p
                            class="text-muted text-truncate mb-2"
                          >{{ trans.get('__JSON__.Orders Pending') }}</p>
                          <h5 class="mb-0">{{ dashboard.total_order_pending }}</h5>
                        </div>
                      </div>
                      <div class="col-4">
                        <div>
                          <p
                            class="text-muted text-truncate mb-2"
                          >{{ trans.get('__JSON__.Earning This Month') }}</p>
                          <h5 class="mb-0">
                            <i class="bx bx-rupee"></i>
                            {{ dashboard.total_earning }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div v-if="!$auth.partner_login">
                  <div class="text-lg-center mt-6 mt-lg-0" v-if="$auth.setting">
                    <div class="row m-dashboard">
                      <div class="col-3">
                        <div>
                          <p
                            class="text-muted text-truncate mb-2"
                          >{{ $auth.setting.restaurant_name }}</p>
                          <h5 class="mb-0">
                            <router-link :to="{name: 'vendor-list'}">{{ dashboard.total_vendor }}</router-link>
                          </h5>
                        </div>
                      </div>
                      <div class="col-3">
                        <div>
                          <p class="text-muted text-truncate mb-2">{{ $auth.setting.driver_name }}</p>
                          <h5 class="mb-0">
                            <router-link :to="{name: 'driver-list'}">{{ dashboard.total_delivery_boy }}</router-link>

                            </h5>
                        </div>
                      </div>
                      <div class="col-3">
                        <div>
                          <p
                            class="text-muted text-truncate mb-2"
                          >{{ trans.get('__JSON__.Customers') }}</p>
                          <h5 class="mb-0">
                            <router-link :to="{name: 'customer-list'}">{{ dashboard.total_customer }}</router-link>
                          </h5>
                          
                        </div>
                      </div>
                      <div class="col-3">
                        <div>
                          <p
                            class="text-muted text-truncate mb-2"
                          >{{ trans.get('__JSON__.Delivery Zones') }}</p>
                          <h5 class="mb-0"><router-link :to="{name: 'list'}">{{ dashboard.total_zone }}</router-link></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row" v-if="$auth.partner">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-copy-alt"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Orders')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>{{dashboard.total_order_delivered || 0}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-archive-in"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Order Values')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4><span v-html="currency"></span> {{ dashboard.total_order_amount || 0 }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                      v-html="currency"></span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Earning')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4><span v-html="currency"></span> {{dashboard.total_earning}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs mr-3">
                    <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="bx bx-purchase-tag-alt"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Average Order Price')}}</h5>
                </div>
                <div class="text-muted mt-4" v-if="$auth.setting">
                  <h4><span v-html="currency"></span> {{dashboard.average_order_price}}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>

    <div class="row d-none">
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-right">
                <div class="input-group input-group-sm">
                  <select class="custom-select custom-select-sm">
                    <option selected>Jan</option>
                    <option value="1">Dec</option>
                    <option value="2">Nov</option>
                    <option value="3">Oct</option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text">Month</label>
                  </div>
                </div>
              </div>
              <h4 class="card-title mb-4">Earning</h4>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="text-muted">
                  <div class="mb-4" v-if="$auth.setting">
                    <p>This month</p>
                    <h4>{{$auth.setting.currency}} 2453.35</h4>
                    <div>
                      <span class="badge badge-soft-success font-size-12 mr-1">+ 0.2%</span> From previous period
                    </div>
                  </div>

                  <div>
                    <a href="#" class="btn btn-primary waves-effect waves-light btn-sm">
                      View Details
                      <i class="mdi mdi-chevron-right ml-1"></i>
                    </a>
                  </div>

                  <div class="mt-4" v-if="$auth.setting">
                    <p class="mb-2">Last month</p>
                    <h5>{{$auth.setting.currency}} 2281.04</h5>
                  </div>
                </div>
              </div>

              <div class="col-lg-8">
                <apexchart
                  class="apex-charts"
                  :options="ChartData.LineChart.chartOptions"
                  :series="ChartData.LineChart.series"
                  dir="ltr"
                  height="380"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Sales Analytics</h4>

            <div>
              <apexchart
                class="apex-charts"
                :options="ChartData.DonutChart.chartOptions"
                :series="ChartData.DonutChart.series"
                dir="ltr"
                height="300"
              />
            </div>

            <div class="text-center text-muted">
              <div class="row">
                <div class="col-4">
                  <div class="mt-4">
                    <p class="mb-2 text-truncate" v-if="$auth.setting">
                      <i class="mdi mdi-circle text-primary mr-1"></i>COD
                    </p>
                    <h5>2,132</h5>
                  </div>
                </div>
                <div class="col-4 offset-4">
                  <div class="mt-4">
                    <p class="mb-2 text-truncate" v-if="$auth.setting">
                      <i class="mdi mdi-circle text-success mr-1"></i>Online
                    </p>
                    <h5>1,763</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div v-if="$auth.partner_login">
      <div class="row list-table-main">
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right">
                  <div class="input-group input-group-sm">
                    <h4 class="card-title mb-4">(30 Days)</h4>
                  </div>
                </div>
                <h4 class="card-title mb-4">Top Selling Items</h4>
              </div>

              <div v-if="selling_count > 0">
                <div class="text-muted text-center">
                  <!-- <p class="mb-2">{{ dashboard.items[0].item_name }}</p> -->
                </div>

                <div class="table-responsive mb-0 item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="item in dashboard.items"
                    :key="item.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="config.restaurantmenuitemicon+$auth.user.vendor_id+'/'+$auth.setting.restaurant_id+'/'+item.image"
                            @error="$event.target.src=config.basepath+'no-img.png'"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1">{{ item.item_name }}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Selling Item Found') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right"></div>
                <h4 class="card-title mb-4">Items Low In Stock</h4>
              </div>

              <div v-if="item_count > 0">
                <div class="text-muted text-center">
                  <!-- <p class="mb-2">{{ dashboard.items_menu[0].submenu }}</p> -->
                </div>

                <div class="table-responsive mt-4 mb-0 item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="item_menu in dashboard.items_menu"
                    :key="item_menu.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="item_menu.image"
                            @error="$event.target.src=config.basepath+'no-img.png'"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1">{{ item_menu.submenu }}</h5>
                          <div v-if="item_menu.count > 0">
                            <p class="text-muted mb-0">{{ item_menu.count }} Available</p>
                          </div>
                          <div v-else>
                            <p class="text-muted mb-0">{{ item_menu.count }} Not Available</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Item Found') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-3 col-sm-6">
           <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <h5 class="card-title mb-4">This Month SignUps </h5>
                <h3 class="text-primary" >{{stats?stats.total_this_month_customer:'-'}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <h4 class="card-title mb-4">This Month SignUps <small>(With Order)</small></h4>
                <h3 class="text-primary" >{{stats?stats.total_this_month_with_order_customer:'-'}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <h4 class="card-title mb-4">Last Month SignUps</h4>
                <h3 class="text-primary" >{{stats?stats.total_last_month_customer:'-'}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <h4 class="card-title mb-4">Last Month SignUps <small> (With Order)</small></h4>
                <h3 class="text-primary" >{{stats?stats.total_last_month_with_order_customer:'-'}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row list-table-main">
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right">
                  <div class="input-group input-group-sm">
                    <h4 class="card-title mb-4">(30 Days)</h4>
                  </div>
                </div>
                <h4 class="card-title mb-4">Top {{ $auth.setting.restaurant_name }}</h4>
              </div>

              <div v-if="thirty_vendor > 0">
                <div class="table-responsive mt-4 mb-0 item-menu m-item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="vendors in dashboard.thirty_vendor"
                    :key="vendors.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="config.restauranticon+vendors.icon_image"
                            @error="replaceByDefault"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1 dashboard-thirty">
                            <router-link
                              :to="{ name: 'vendor-detail',params: { restaurant_id: base64(vendors.restaurant_id) }}"
                            >{{ vendors.name }}</router-link>
                          </h5>
                          <p class="text-muted mb-0">{{ vendors.vendor_order_count }} Orders</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Vendor Found') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right">
                  <div class="input-group input-group-sm">
                    <h4 class="card-title mb-4">(30 Days)</h4>
                  </div>
                </div>
                <h4 class="card-title mb-4">Top {{ $auth.setting.driver_name }}</h4>
              </div>

              <div v-if="thirty_driver > 0">
                <div class="table-responsive mt-4 mb-0 item-menu m-item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="drivers in dashboard.thirty_driver"
                    :key="drivers.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="config.driver+drivers.profileimage"
                            @error="replaceByDefault"
                            alt
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1 dashboard-thirty">
                            <router-link
                              :to="{name: 'driver-details',params: { driver_id: base64(drivers.driverid) }}"
                            >{{ drivers.firstname }} {{ drivers.lastname }}</router-link>
                          </h5>
                          <p class="text-muted mb-0">{{ drivers.driver_order_count }} Orders</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Driver Found') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-right">
                  <div class="input-group input-group-sm">
                    <h4 class="card-title mb-4">(90 Days)</h4>
                  </div>
                </div>
                <h4 class="card-title mb-4">Top Customer</h4>
              </div>

              <div v-if="ninty_customer > 0">
                <div class="table-responsive mt-4 mb-0 item-menu m-item-menu">
                  <table
                    class="table table-centered mb-0"
                    v-for="customers in dashboard.ninty_customer"
                    :key="customers.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <img
                            :src="config.basepath+'businessman.png'"
                            alt
                            @error="replaceByDefault"
                            class="avatar-md rounded-circle img-thumbnail"
                          />
                        </td>
                        <td>
                          <h5 class="font-size-14 mb-1 dashboard-thirty">
                            <router-link
                              :to="{name: 'customer-detail',params: { user_id: base64(customers.user_id)}}"
                            >{{ customers.user_name }} {{ customers.last_name }}</router-link>
                          </h5>
                          <p class="text-muted mb-0">{{ customers.customer_order_count }} Orders</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p class="text-center">{{ trans.get('__JSON__.No Customer') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
