<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { settingService } from '../../../services';
import DropzonePopup from './../../../components/widgets/dropzone-popup.vue'
import config from "../../../config";
import { success_message, error_message } from '../../../Helper/helper';
import { routes } from '../../../config/api_routes';

/**
 * Starter component
 */
export default {
  components: {
    DropzonePopup
  },
  data() {
    return {
      config:config,
      setting: [],
      setting: {
        login_image: '',
        login_text: ''
      },
      editableImage: '',
      icon_image_delete_url: routes.imageDeleteApi,
      loginImagePath: '',
    };
  },
  validations: {
    setting: {
      login_image: {
        required:requiredIf(function () {
          return (this.editableImage == '')
        }) 
      },
      login_text: {
        required
      }
    },
  },
  mounted() {
    this.getAppSetting();
    this.loginImagePath = this.config.loginimage //config.media_path + 'media/login_page/original';
  },
  methods: {
    getAppSetting(){
      settingService.getAppSettingApi().then((response) => {
            this.editableImage = response.data.result.settings.login_image;
            this.setting = {
                ...this.setting,
                ...response.data.result.settings,
                login_image: '',
            };
      })
    },
    onIconImageDeleted(ime){
      console.log(ime)
      this.editableImage = ''
    },
    generalIt(){
      this.$v.setting.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("login_image", this.setting.login_image);
        fd.append('login_text',this.setting.login_text);
        fd.append('type',6);
        settingService.updateAppSetting(fd).then((response) => {
          if (response.data.code === 200) {
              success_message(response.data.message);
            } else {
              error_message(response.data.message);
            }
        });
      }
    },
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <b-form-group id="login-1" label="Login Title" label-for="login-2">
                    <b-form-input
                      id="login-2"
                      v-model="$v.setting.login_text.$model"
                      placeholder="Enter login title"
                      required
                      :class="{ 'is-invalid': $v.setting.login_text.$error }"
                    ></b-form-input>
                    <div v-if="$v.setting.login_text.$error" class="invalid-feedback cuisine-img">
                      <span
                        v-if="!$v.setting.login_text.required"
                      >{{ trans.get('__JSON__.Please select field', {field: 'Login Title'}) }}</span>
                    </div>
                </b-form-group>
                <b-form-group
                  label="Login Image"
                  label-for="login-image"
                >
                <dropzone-popup
                  v-model="setting.login_image"
                  id="login-image"
                  name="Login Image"
                  acceptedFiles=".jpeg,.jpg,.png"
                  :editable-images="editableImage"
                  :media-path="loginImagePath"
                  :delete-path="icon_image_delete_url"
                  @image-deleted="onIconImageDeleted"
                  :class="{ 'is-invalid': $v.setting.login_image.$error }"
                />
                <div v-if="$v.setting.login_image.$error" class="invalid-feedback cuisine-img">
                  <span
                    v-if="!$v.setting.login_image.required"
                  >{{ trans.get('__JSON__.Please select image') }}</span>
                </div>
                </b-form-group>
              </div>
              <div class="col-md-6 offset-sm-3">
               
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  v-if="$auth.hasPermission(config.permissions.layout_write)"
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>