
<script>
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "../../orders/order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { orderService } from '../../../services';
import { date_ranges, debounce, order_statuses } from '../../../Helper/helper';
import config from '../../../config';

export default {
  components: { DateRangePicker, OrderList,pagination },
  props:['driver_id','status','customer_id'],
  data() {
    let startDate = this.moment().subtract(1, 'months').startOf('day').toDate();
    let endDate = this.moment().endOf('day').toDate();
    return {
      config:config,
      dateRange: { startDate, endDate },
      statuses: order_statuses,
      orders: null,
      ordersCount: null,
      loading: false,
      limit: 1,
      filters: {
        status: this.status,
        search: '',
        start_date: this.moment().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: this.moment().endOf('day').format('YYYY-MM-DD'),
        ...(this.driver_id?{driver_id: atob(this.driver_id)}:{}),
        ...(this.customer_id?{user_id: atob(this.customer_id)}:{})
      },
      scheduleColumns: [
        { key: "future_delivery_date", label: this.trans.get("__JSON__.Schedule Time"), sortable: false }
      ],
      cancelledColumns: [
        { key: "cancelled_date", label: this.trans.get("__JSON__.Cancel Time"), sortable: false }
      ],
      debounce,
      date_ranges:date_ranges,
      searchChanged:debounce(()=>{
          this.getOrders()
          this.getOrdersCount();
      },500)
    };
  },
  mounted() {
    this.getOrdersCount();
    this.getOrders();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.getOrders();
      this.getOrdersCount();
    },
    getOrders(page = 1) {
      this.loading = true;
      orderService
        .getOrders({
          ...this.filters
        })
        .then((response) => {
          this.loading = false;
          this.orders = response.data.result;
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    getOrdersCount(){
      this.loading = true;
      orderService
        .getOrdersCount({
          ...this.filters,
          
          start_date :  null,
          end_date :  null 
        })
        .then((response) => {
          this.loading = false;
          this.ordersCount = response.data.result.orders_count;
        })
        .catch(function (error) {
          this.loading = false;
          // console.log(error);
        });
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="col-12">
        <div class="justify-content-between row">
          <div class="mt-3">
            <span v-if="filters.status == statuses.delivered" class="font-weight-bold font-size-16">{{trans.get('__JSON__.Orders')}} : {{ordersCount && ordersCount.delivered_orders || 0}}</span>
            <span v-if="filters.status == statuses.cancelled" class="font-weight-bold font-size-16">{{trans.get('__JSON__.Orders')}} : {{ordersCount && ordersCount.cancelled_orders || 0}}</span>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="date-range-list mr-2">
                <label>{{trans.get('__JSON__.Date')}}:</label>
                <date-range-picker
                  ref="picker"
                  :locale-data="{
                    firstDay: 1,
                    format: 'dd-mm-yyyy',
                  }"
                  :showDropdowns="true"
                  :showWeekNumbers="true"
                  opens="left"
                  v-model="dateRange"
                  :ranges="date_ranges()"
                  @update="updateValues"
                >
                  <template v-slot:input="picker" style="min-width: 350px">
                    {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                    {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="">
              <div class="search-box mr-3 mb-0 d-inline-block form-group">
                <div class="date-range-list">
                  <label>{{trans.get('__JSON__.Search')}} :</label>
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control"
                      v-model="filters.search"
                      @keyup="searchChanged"
                      :placeholder="trans.get('__JSON__.Search...')"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderList :orders="orders" :loading="loading" />
    </div>
  </div>
</template>

<style scoped>
</style>