<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Multiselect from "vue-multiselect";
import config from "../../config";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService, bannerService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker,Multiselect },
  data() {
    let startDate = this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      totalSale: {},
      config:config,
      restaurant: [],
      totalSaleReport: [],
      restaurant_count: "",
      restaurants_id: "",
      sortBy: "id",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      countries: [],
      country: "0",
      states: [],
      state: "0",
      cities: [],
      isLoading : true,
      resturant:null,
      selectedRestaurant:[],
      city: "0",
      country_count: "",
      state_count: "",
      city_count: "",
      loading: true,
      exporting: false,
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(6,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      date_ranges: date_ranges,
      fields: [
        { key: "rating_id", label: "#", sortable: false },
        { key: "restaurant", label: (this.$auth.getRestaurantName()+" Name"), sortable: true },
        { key: "customer", label: "Customer Name", sortable: false },
        { key: "order_id", label: "Order Id", sortable: false },
        { key: "rating", label: "Rating", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "created_at", label: "Date", sortable: true },
      ],
    };
  },
  mounted() {
    this.getVendorRatingReport()
    this.asyncFind()
  },
  methods: {
    asyncFind(query) {
      this.isLoading =true;
      bannerService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading =false;
      });
    },
    clearAll() {
      this.selectedRestaurant = [];
    },
    downloadsCSV: function () {
      let totalSaleReport =
        '\ufeff' +
        '#,'+this.$auth.getRestaurantName()+' Name,Customer Name,Order ID,Rating,Description,Date \n'
      this.totalSaleReport.forEach((el, index) => {
        var line =
          (index+1) +
          "," +
          (el["restaurant"]['name']) +
          "," +
          (el["customer"]['user_name']+' '+el['customer']['last_name']) +
          "," +
          el["order_id"] +
          "," +
          el["rating"] +
          "," +
          (el["description"] || '-').replaceAll(',',';').replaceAll('\n',' ') +
          "," +
          this.moment(el["created_at"]).format('MMMM Do YYYY [-] h:mm a') +
          "\n"
        totalSaleReport += line;
      });

      var blob = new Blob([totalSaleReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "vendor_feedback_rating_report_" + date + ".csv";
      link.click();
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getVendorRatingReport();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getVendorRatingReport(page) {
      this.loading = true;
      reportService
        .getVendorRatingReport({
          ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page ? page : 1,
          restaurant: this.selectedRestaurant && this.selectedRestaurant.length > 0 ? this.selectedRestaurant.map(r=>r.restaurant_id) : null
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.totalSale = response.data.result;
        });
    },
    getVendorRatingReportExport() {
      this.exporting = true;
      reportService
        .getVendorRatingReportExport({ 
           ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          restaurant: this.selectedRestaurant && this.selectedRestaurant.length > 0 ? this.selectedRestaurant.map(r=>r.restaurant_id) : null
        })
        .then((response) => {
          this.exporting = false;
          this.totalSaleReport = response.data.result;
          this.downloadsCSV()
        });
    },
    searchData() {
      this.getVendorRatingReport();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendorRatingReport();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Vendor - Feedback and rating report',{ vendor : $auth.setting.restaurant_name }) }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Vendor - Feedback and rating report',{ vendor : $auth.setting.restaurant_name }) }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="getVendorRatingReportExport" v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)" class="btn btn-primary btn-rounded" :disabled="exporting">{{ trans.get(!exporting?'__JSON__.Export To CSV':'__JSON__.Exporting') }}</button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 col-xs-12 offset-sm-4">
                  <div class="form-group">
                    <label>{{trans.get('__JSON__.Search...')}}:</label>
                    <multiselect
                      v-model="selectedRestaurant"
                      id="ajax"
                      label="name"
                      track-by="restaurant_id"
                      :placeholder="trans.trans('__JSON__.Search...')"
                      open-direction="bottom"
                      :options="resturant"
                      :multiple="true"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :options-limit="5"
                      :limit="3"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                      @input="searchData"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.name }}</span>
                          <span class="custom__remove" @click="remove(option)">❌</span>
                        </span>
                      </template>
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="selectedRestaurant.length"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div>
                      </template>
                      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-4 col-xs-12 ">
                  <div class="form-group total-sales-earning">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                         <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(rating_id)="data">
                      <div>{{ (totalSale.per_page * (totalSale.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(restaurant)="data">
                      <span>{{ data.value.name  }}</span>
                    </template>

                    <template v-slot:cell(customer)="data">
                      <span>{{ data.value.user_name }} {{data.value.last_name}}</span>
                    </template>

                    <template v-slot:cell(order_id)="data">
                      #{{data.value }}
                    </template>

                    <template v-slot:cell(rating)="data">
                      {{data.value }}
                    </template>

                    <template v-slot:cell(description)="data">
                      <div style="width: 200px">{{data.value || '-' }}</div>
                    </template>
                    <template v-slot:cell(created_at)="data">
                      {{moment(data.value).format('lll') }}
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Sales Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                       <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getVendorRatingReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}
</style>