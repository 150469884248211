var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$auth.hasPermission(_vm.config.permissions.order_write))?_c('b-dropdown',{staticClass:"m-md-2",attrs:{"lazy":"","id":"dropdown-1","variant":"link","right":"","role":"navigation"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"bx bx-dots-vertical-rounded"})]},proxy:true}],null,false,2127224613)},[_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.viewDetails(_vm.order.base_id)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.View")))]),_vm._v(" "),(_vm.order.order_status == _vm.statuses.placed && !_vm.$auth.partner_login)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatus(_vm.order.base_id, _vm.statuses.inkitchen)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Confirm Order")))]):_vm._e(),_vm._v(" "),(_vm.order.order_status == _vm.statuses.placed && _vm.order.petpooja_orderid == null)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.petpoojaSync(_vm.order.id)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Petpooja Sync")))]):_vm._e(),_vm._v(" "),(_vm.order.order_status == _vm.statuses.inkitchen)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatus(_vm.order.base_id, _vm.statuses.readytoserve)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Ready To Delivery Order")))]):_vm._e(),_vm._v(" "),(
        [_vm.statuses.readytoserve, _vm.statuses.inkitchen].includes(_vm.order.order_status) &&
        _vm.order.pidge_enable === '1' &&
        _vm.order.pidge_driver == '0' &&
        _vm.order.driver.length == 0
      )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.sendToPidge(_vm.order.base_id)}}},[_c('span',{staticClass:"text-info"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Send To Pidge")))])]):_vm._e(),_vm._v(" "),(
        [_vm.statuses.readytoserve, _vm.statuses.inkitchen].includes(_vm.order.order_status) &&
        _vm.order.dunzo_enable === '1' &&
        _vm.order.dunzo_driver == '0' &&
        _vm.order.driver.length == 0
      )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.sendToDunzo(_vm.order.base_id)}}},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Send To Dunzo")))])]):_vm._e(),_vm._v(" "),(
        [_vm.statuses.readytoserve, _vm.statuses.inkitchen].includes(_vm.order.order_status) &&
        _vm.order.dunzo_enable === '1' &&
        _vm.order.dunzo_driver == '0' &&
        _vm.order.driver.length == 0
      )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.scheduleToDunzo(_vm.order.base_id, _vm.statuses.readytoserve)}}},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Schedule To Dunzo")))])]):_vm._e(),_vm._v(" "),(
        [
          _vm.statuses.placed,
          _vm.statuses.inkitchen,
          _vm.statuses.readytoserve,
          _vm.statuses.ontheway,
          _vm.statuses.scheduled,
          _vm.statuses.delivered ].includes(_vm.order.order_status)
      )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.addNotePopup(_vm.order.base_id)}}},[_c('span',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Add Note")))])]):_vm._e(),_vm._v(" "),(!_vm.$auth.partner_login)?[(
          [
            _vm.statuses.inkitchen,
            _vm.statuses.readytoserve,
            _vm.statuses.ontheway,
            _vm.statuses.scheduled,
            _vm.statuses.arrived,
            _vm.statuses.delivered,
            _vm.statuses.cancelled ].includes(_vm.order.order_status) &&
          _vm.order.is_no_response_email == 0 &&
          _vm.not_response_btn == true
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.notRespondingCustomer(_vm.order.base_id)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Not Responding Customer")))]):_vm._e(),_vm._v(" "),(
          [
            _vm.statuses.inkitchen,
            _vm.statuses.readytoserve,
            _vm.statuses.ontheway,
            _vm.statuses.scheduled,
            _vm.statuses.arrived,
            _vm.statuses.delivered,
            _vm.statuses.cancelled ].includes(_vm.order.order_status) &&
          _vm.order.is_delay_email == 0 &&
          _vm.delay_order_btn == true
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.delayOrderCustomer(_vm.order.base_id)}}},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Delay/Complain Email To Customer")))]):_vm._e(),_vm._v(" "),(
          [
            _vm.statuses.placed,
            _vm.statuses.inkitchen,
            _vm.statuses.readytoserve,
            _vm.statuses.ontheway,
            _vm.statuses.scheduled,
            _vm.statuses.delivered ].includes(_vm.order.order_status)
        )?_c('b-dropdown-divider'):_vm._e(),_vm._v(" "),(
          [
            _vm.statuses.placed,
            _vm.statuses.inkitchen,
            _vm.statuses.readytoserve,
            _vm.statuses.ontheway,
            _vm.statuses.scheduled,
            _vm.statuses.delivered ].includes(_vm.order.order_status)
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.cancelOrderPopup(_vm.order.base_id, _vm.statuses.cancelled)}}},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Cancel Order")))])]):_vm._e(),_vm._v(" "),(
          [_vm.statuses.readytoserve, _vm.statuses.ontheway, _vm.statuses.arrived].includes(
            _vm.order.order_status
          )
        )?_c('b-dropdown-divider'):_vm._e(),_vm._v(" "),(
          [_vm.statuses.readytoserve, _vm.statuses.ontheway, _vm.statuses.arrived].includes(
            _vm.order.order_status
          )
        )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatusDelivered(_vm.order.base_id, _vm.statuses.delivered)}}},[_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.Complete Order")))])]):_vm._e()]:_vm._e()],2):_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Need Permission'),expression:"'Need Permission'"}],staticClass:"mdi mdi-information h4 text-danger"}),_vm._v(" "),(_vm.showModal)?_c('OrderShow',{attrs:{"order-id":_vm.order.base_id,"thirdPartyOrder":_vm.thirdPartyOrder},on:{"hidden":function($event){_vm.showModal = false}}}):_vm._e(),_vm._v(" "),(_vm.delayOrderShow)?_c('DelayOrderShow',{attrs:{"order_id":_vm.order.id},on:{"hidden":function($event){_vm.delayOrderShow = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }