<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import pagination from "laravel-vue-pagination";
import OrderShow from '../orders/order_tabs/order-show'
import config from "../../config";
import {
  get_decimal,
  get_currency,
  date_ranges,
  debounce
} from "../../Helper/helper";
import moment from "moment";
import { reportService } from "../../services";
import { routes } from '../../config/api_routes';
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, DateRangePicker, pagination, OrderShow },
  data() {
    let startDate = this.moment().startOf("day").subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      config:config,
      sortBy: "created_at",
      sortDesc: true,
      cashbacks:null,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      loading: true,
      date_ranges: date_ranges,
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(29,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
        search: ''
      },
      order_id: null,
      showModal: false,
      fields: [
        { key: "id", label: "Customer ID", sortable: false },
        { key: "customer", label: "Customer Name", sortable: false },
        { key: "phone_number", label: "Customer Number", sortable: false },
        { key: "created_date", label: "Sign Up Date", sortable: false },
        { key: "order_id", label: "Order ID", sortable: false },
        { key: "restaurant", label: "Outlet Name", sortable: false },
        { key: "original_price", label: "Net Total", sortable: false },
        { key: "payment_method", label: "Payment Type", sortable: false },
        { key: "coupon_code", label: "Coupon Code", sortable: false },
        { key: "discount_price", label: "Payable Amount", sortable: false },
        { key: "discount_amount", label: "Discount Amount", sortable: false },
        { key: "cashback", label: "Cashback", sortable: false },
        { key: "wallet_balance", label: "Current Wallet Balance", sortable: false },
      ],
      debounce,
      searchChanged:debounce(()=>{
        this.$router.push({query: this.filters});
        this.getCashbackReport()
      },500),
    };
  },
  mounted() {
    if(Object.keys(this.$route.query).length == 0){
      this.$router.push({query: this.filters});
    }
    this.filters = this.$route.query;
    this.dateRange = {
      startDate : this.moment(this.filters.start_date).toDate(), 
      endDate : this.moment(this.filters.end_date).toDate()
    }
    // this.$data.fields[0].label = this.$auth.getRestaurantName() +' Name';
    this.getCashbackReport();
  },
  methods: {
    getCashbackReport(page = 1){
      reportService.getCashbackReport({...this.filters,page})
        .then(response => {
          this.cashbacks = response.data.result;
          this.loading = false; 
          
        })
    },
    downloadsCSV: function (exportData) {
      let params = new URLSearchParams({
        ...this.filters,
        vendor_id: this.$auth.getVendorId(),
        type: this.$auth.user.vendor_role ?'vendor':'partner'
      });

      window.open(routes.getCashbackReportExportApi+'?'+params);

      // let data =
      //   '\ufeff' +
      //   'Customer ID,Customer Name,Customer Number,Sign Up Date,Order ID,Outlet Name,Net Total,Payment Type,Coupon Code,Discount Amount,Cashback,Current Wallet Balance\n'
      // exportData.forEach((el) => {
      //   var line =
      //     el.customer.unique_data +
      //     "," +
      //     el.customer.full_name +
      //     "," +
      //     el.customer.mobile_number +
      //     "," +
      //     el.customer.created_date +
      //     ",#" +
      //     el.order_id +
      //     "," +
      //     el.order.restaurant.name +
      //     "," +
      //     parseFloat(el.original_price || 0).toFixed(this.decimal) +
      //     "," +
      //     el.order.payment_method +
      //     "," +
      //     el.coupon.coupon_code +
      //     "," +
      //     parseFloat(el.discount_price || 0).toFixed(this.decimal) +
      //     "," +
      //     parseFloat(el.cashback || 0).toFixed(this.decimal) +
      //     "," +
      //     parseFloat(el.customer.wallet?el.customer.wallet.wallet_balance:0).toFixed(this.decimal) +
      //     "\n"
      //   data += line;
      // });

      // var blob = new Blob([data], { type: "csv/plain" });
      // var date = new Date().toLocaleString();
      // let link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // link.download = "cashback_report_" + date + ".csv";
      // link.click();
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.$router.push({query: this.filters}).catch(err => {});
      this.getCashbackReport();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    exportToCsv()
    {
        reportService.getCashbackReportExport({
            ...this.filters
        }).
        then((response) => {
            this.downloadsCSV(response.data.result);
        });
    },
    searchData() {
      this.getCashbackReport();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCashbackReport();
    },
    btoa(str){
      return btoa(str);
    },
    viewOrderDetails(order_id){
      this.order_id = order_id;
      this.showModal = true;
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
     <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Cashback Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.trans('__JSON__.Cashback Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="downloadsCSV" v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)" class="btn btn-primary btn-rounded">{{ trans.get('__JSON__.Export To CSV') }}</button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5 col-xs-12 offset-sm-7 d-flex justify-content-end">
                  <div class="ml-2">
                    <div class="search-box mr-2 mb-0 d-inline-block form-group">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Search')}} :</label>
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control"
                            v-model="filters.search"
                            @keyup="searchChanged"
                            :placeholder="trans.get('__JSON__.Search...')"
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list table-responsive" v-if="cashbacks">
                  <b-table
                    :items="cashbacks.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template #cell(id)="data">
                        <span>{{ data.item.customer.unique_data  }}</span>
                    </template>
                    <template #cell(customer)="data">
                        <span><b-link :to="{ name: 'customer-detail', params:{user_id: btoa(data.value.user_id) }}">{{ data.value.full_name  }}</b-link></span>
                    </template>
                    <template #cell(phone_number)="data">
                        <span>{{ data.item.customer.mobile_number  }}</span>
                    </template>
                    <template #cell(created_date)="data">
                        <span>{{ moment(data.item.customer.created_date).format('MMM DD[,] YYYY hh:mm A')  }}</span>
                    </template>
                    <template #cell(order_id)="data">
                        <span>
                          <a href="javascript:void(0)" @click="viewOrderDetails(btoa(data.value))">{{ '#'+data.value  }}</a>
                        </span>
                    </template>
                    <template #cell(restaurant)="data">
                        <span>{{ data.item.order.restaurant.name  }}</span>
                    </template>
                    <template #cell(original_price)="data">
                        <span><span v-html="currency"></span> {{ parseFloat(data.value||0).toFixed(2)  }}</span>
                    </template>
                    <template #cell(payment_method)="data">
                        <span>{{ data.item.order.payment_method  }}</span>
                    </template>
                    <template #cell(coupon_code)="data">
                        <span>{{ data.item.coupon.coupon_code  }}</span>
                    </template>
                    <template #cell(discount_price)="data">
                        <span><span v-html="currency"></span> {{ parseFloat(data.value || 0).toFixed(2)  }}</span>
                    </template>
                    <template #cell(discount_amount)="data">
                        <span><span v-html="currency"></span> {{ parseFloat((data.item.original_price - data.item.discount_price) || 0).toFixed(2)  }}</span>
                    </template>
                    <template #cell(cashback)="data">
                        <span><span v-html="currency"></span> {{ parseFloat(data.value || 0).toFixed(2) }}</span>
                    </template>
                    <template #cell(wallet_balance)="data">
                        <span><span v-html="currency"></span> {{ parseFloat(data.item.customer.wallet?data.item.customer.wallet.wallet_balance:0).toFixed(2)  }}</span>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.Cashback Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0" v-if="cashbacks">
                        <!-- pagination -->
                        <pagination
                          :data="cashbacks"
                          :limit="limit"
                          @pagination-change-page="getCashbackReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <order-show v-if="showModal"  :order-id="order_id" @hidden="showModal = false" />
  </Layout>
</template>