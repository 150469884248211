
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { locationService } from "../../services";

import { required } from "vuelidate/lib/validators";

export default {
  components: { pagination, locationService },
  data() {
    return {
      config: config,
      addModal: false,
      editModal: false,
      states: [],
      sortBy: "state_name",
      stateCount: '',
      sortDesc: true,
      paginations: {},
      limit: 1,
      search: "",
      status: 1,
      error: "",
      getStateById: {
        id: "",
        state_name: "",
        country_id: "",
        status: "",
      },
      loading:true,
      fields: [
        { key: "id", label: this.trans.get("__JSON__.Id"), sortable: true },
        {
          key: "state_name",
          label: this.trans.get("__JSON__.State Name"),
          sortable: true,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
      typeform: {
        state_name: "",
        country_id: this.$route.params.country_id,
        status: "1",
      },
    };
  },
  validations: {
    typeform: {
      state_name: {
        required,
      },
    },
    getStateById: {
      state_name: {
        required,
      },
    },
  },
  mounted() {
    this.getState();
  },
  methods: {
    getState(page) {
      this.loading = true;
      locationService
        .getState({
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page ? page : 1,
          country_id: this.$route.params.country_id
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.data;
          this.states = response.data.result.data.data;
          this.stateCount = response.data.result.state_count;
        });
    },
    searchData() {
      this.getState();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getState();
    },
    addState() {
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        locationService.addState(this.typeform).then((response) => {
           this.typeform = {
                state_name: "",
                country_id: this.$route.params.country_id,
                status: "1",
          }
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            this.$toasted.success(response.data.message);
            this.addModal = false;
            this.getState();
          } else {
            this.$toasted.error(response.data.message);
          }
        });
      }
    },
    stateUpdate() {
      this.$v.getStateById.$touch();
      if (this.$v.getStateById.$invalid) {
        return;
      } else {
        locationService.updateStateById(this.getStateById).then((response) => {
          if (response.data.code === 200) {
            this.$toasted.success(response.data.message);
            this.editModal = false;
            this.getState();
          } else {
            this.$toasted.error(response.data.message);
          }
        });
      }
    },
    editState(id) {
      this.fetchState(id);
    },
    fetchState(id) {
      return locationService.getStateById({ id: id }).then((response) => {
        if (response.data.code === 200) {
          this.getStateById = response.data.result;
          this.editModal = true;
        } else {
          this.$toasted.error(response.data.message);
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-map"></i>
              </span>
            </div>
            <div class="report-tag">
             <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.State') }}({{stateCount}})</h4>
             <p><router-link :to="{ name: 'country-list'}">{{ trans.get('__JSON__.Country') }}</router-link> > {{ trans.get('__JSON__.State') }}</p>
          </div>
          </div>
          <div class="page-title-right">
            <button type="button" v-if="$auth.hasPermission(config.permissions.location_write)" class="btn btn-success btn-rounded mr-2" @click="addModal = true">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add State') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value selected>{{ trans.get('__JSON__.All') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="search-box mr-2 mb-2 d-inline-block">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Search') }} :</label>
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        @keyup="searchData()"
                        v-model="search"
                        :placeholder="trans.get('__JSON__.Search Placeholder')"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list state-table table-responsive">
                <b-table
                  :items="states"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >

                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>
                  
                  <template v-slot:cell(id)="data">{{ data.index + 1 }}</template>

                  <template v-slot:cell(state_name)="data">
                    <h5 class="font-size-14">{{data.item.state_name}}</h5>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status == '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <button
                        type="button"
                        @click="editState(data.item.id)"
                        class="btn btn-primary btn-sm btn-rounded"
                        v-if="$auth.hasPermission(config.permissions.location_write)"
                      >{{ trans.get('__JSON__.Edit') }}</button>
                      <router-link
                        class="btn btn-success btn-sm btn-rounded ml-1"
                        :to="{ name: 'city-list', params: { state_id: base64(data.item.id) }}"
                      >{{ trans.get('__JSON__.View') }}</router-link>
                    </div>
                  </template>
                  <template #empty>
                     <p class="text-center">{{ trans.get('__JSON__.No State') }}</p>
                   </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getState"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add State Modal Start -->
      <b-modal
        id="modal-scoped"
        v-model="addModal"
        :title="trans.get('__JSON__.Add State')"
        centered
      >
        <b-form @submit.prevent="addState">
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.State Name')"
                label-for="state_name"
              >
                <b-form-input
                  id="state_name"
                  name="state_name"
                  v-model="$v.typeform.state_name.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.State Name Placeholder')"
                  :class="{ 'is-invalid': $v.typeform.state_name.$error }"
                ></b-form-input>
                <div v-if="$v.typeform.state_name.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.state_name.required"
                  >{{ trans.get('__JSON__.Please enter state name') }}</span>
                </div>
              </b-form-group>
            </div>
            <div class="form-group">
              <input
                id="country_id"
                name="country_id"
                type="hidden"
                v-model="typeform.country_id"
                class="form-control"
              />
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="typeform.status"
                      name="status"
                      value="1"
                      checked
                    >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                  </div>
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="typeform.status"
                      name="status"
                      value="0"
                    >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="addState"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="addModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Add State Modal End -->

      <!-- Edit State Modal Start -->
      <b-modal
        id="modal-scoped"
        v-model="editModal"
        :title="trans.get('__JSON__.Edit State')"
        centered
        v-if="$auth.hasPermission(config.permissions.location_write)"
      >
        <b-form @submit.prevent="stateUpdate">
          <div class="row">
            <div class="form-group">
              <input id="id" name="id" type="hidden" v-model="getStateById.id" class="form-control" />
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.State Name')"
                label-for="state_name"
              >
                <b-form-input
                  id="state_name"
                  name="state_name"
                  v-model="$v.getStateById.state_name.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.State Name Placeholder')"
                  :class="{ 'is-invalid': $v.getStateById.state_name.$error }"
                ></b-form-input>
                <div v-if="$v.getStateById.state_name.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.getStateById.state_name.required"
                  >{{ trans.get('__JSON__.State Name Error') }}</span>
                </div>
              </b-form-group>
            </div>
            <div class="form-group">
              <input
                id="country_id"
                name="country_id"
                type="hidden"
                v-model="getStateById.country_id"
                class="form-control"
              />
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="getStateById.status"
                      name="status"
                      value="1"
                      checked
                    >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                  </div>
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="getStateById.status"
                      name="status"
                      value="0"
                    >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="stateUpdate"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="editModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Edit State Modal End -->
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.state-table .table thead th:nth-child(4){
  pointer-events: none;
}
</style>