
// Setting services rest api calling

import { routes } from '../config/api_routes'

export const settingService = {
    // get banner 
    // info: sortDesc : Boolean

    getSetting: () => window.axios.get(routes.getSettingApi),

    // update Settings
    updateSetting: (formData) => window.axios.post(routes.updateSettingApi,formData),

     // get App Setting Api
     getAppSettingApi: () => window.axios.get(routes.getAppLayoutSettingApi),

     // Update App Setting
     updateAppSetting: (formData) => window.axios.post(routes.updateAppLayoutApi,formData),

     // Update General terminology Setting
     updateGeneralTerminology: (formData) => window.axios.post(routes.updateGeneralTerminologyApi,formData),

     // get Notification 
     getNotificationText: (formData) => window.axios.post(routes.getOrderNotificationTextApi,formData),
     // get SMS 
     getSmsText: (formData) => window.axios.post(routes.getSmsTextApi,formData),
     
     // update SMS 
     updateSMStext: (formData) => window.axios.post(routes.updateSmsTextApi,formData),

    //  // update Notification
     updateNotificationtext: (formData) => window.axios.post(routes.updateOrderTextApi,formData),

     // update payment Gateway
     updatepaymentgateway: (formData) => window.axios.post(routes.updatepaymentgatewayApi,formData),
    // update referral Gateway
    updateReferral: (formData) => window.axios.post(routes.updateReferralApi,formData)


}