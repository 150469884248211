
// Report services rest api calling

import { routes } from '../config/api_routes'

export const reportService = {
    // get sales report 

    getChowmanPayReport: (formData) => {    
        return window.axios.post(routes.getChowmanPayReportApi,formData)
    }, 


    exportChowmanPayReport: (data) => window.axios.get(routes.exportChowmanPayReportApi,{params:data,responseType: 'blob'}),
     // get chowman Pay Report

     getSalesReport: (formData) => {    
        return window.axios.post(routes.getSalesReportApi,formData)
    }, 

    // sales report export to csv
    exportToCsv: (formData) => {
        return window.axios.post(routes.saleReportExportToCsvApi,formData)
    },

    // get item report 

    getItemReport: (formData) => {    
        return window.axios.post(routes.getItemReportApi,formData)
    }, 

    // get Customer Report

    getCustomerReport: (formData) => {
        return window.axios.post(routes.getCustomerReportApi,formData)
    },

    // Customer Export To CSV

    customerExportToCSV: (formData) => {
        return window.axios.post(routes.reportcustomerExportToCsvApi,formData)
    },

    // get Total Sale report
    gettotalSaleReport: (formData) => {
        return window.axios.post(routes.totalSaleReportApi,formData)
    },

    // Total Sale Export To CSV
    totalSalesExportToCSV: (formData) => {
        return window.axios.post(routes.totalSaleReportExportToCsvApi,formData)
    },

    // get All Restaurants
    getAllRestaurantName: (formData) => {
        return window.axios.post(routes.getRestaurantsApi, formData)
    },

    // get All Restaurants Confirm
    getRestaurantsConfirm: (formData) => {
        return window.axios.post(routes.getRestaurantsConfirmApi,formData)
    },  

    // get GST Admin commission Report
    getTaxReport: (formData) => {
        return window.axios.post(routes.taxReportReportApi,formData)
    },

    // Gst Admin Export To CSV
    taxReportExportToCsv: (formData) => {
        return window.axios.post(routes.taxReportExportToCsvApi,formData)
    },

    // get All User
    getAllUser: () => {
        return window.axios.get(routes.getAllUserApi)
    },

    // get DriverReportListing
    getDriverReportListing: (formData) => {
        return window.axios.post(routes.getDriverReportApi,formData)
    },
    
    exportDriverReportApi: (formData) => {
        return window.axios.post(routes.exportDriverReportApi,formData)
    },

    // get InactiveCustomerReport
    getInactiveCustomerReport: (formData) => {
        return window.axios.post(routes.getInactiveCustomerReportApi,formData)
    },

    // Export InactiveCustomerReport
    exportInactiveCustomer: (formData) => {
        return window.axios.post(routes.exportInactiveCustomerApi,formData)
    },

    // get CODOnlineorderReport
    getCODOnlineOrderReport: (formData) => {
        return window.axios.post(routes.getCODOnlineOrderReportApi,formData)
    },

    // export To Csv CodOnlineReport
    exportOnlineReport: (formData) => {
        return window.axios.post(routes.exportCODOnlineReportApi,formData)
    },

    // get Vendor Rating Report
    getVendorAvgRatingReport: (formData) => {
        return window.axios.post(routes.getVendorAvgRatingReportApi,formData)
    },

    // Vendor Avg Export To Csv
    exportVendorAvgRating: (formData) => {
        return window.axios.post(routes.exportVendorAvgRatingApi,formData)
    },
    // Cashback Report
    getCashbackReport: (formData) => window.axios.post(routes.getCashbackReportApi,formData),
    getCashbackReportExport: (formData) => window.axios.post(routes.getCashbackReportExportApi,formData),

    // get GST Delivery Charge commission Report
    getGstDeliveryChargeReport: (formData) => {
        return window.axios.post(routes.getGSTDeliveryChargeReportApi,formData)
    },

    // Export To Csv GST delivery Charge
    exportGSTDeliveryChargeReport: (formData) => {
        return window.axios.post(routes.exportGSTDelivertReportApi,formData)
    },

    getPromoCodeReport: (formData) => {
        return window.axios.post(routes.getPromoCodereportApi,formData)
    },

    // Get Sales Vendor Report
    getVendorSalesReport: (formData) => {
        return window.axios.post(routes.getVendorSalesReportApi,formData)
    },

    getDriverOrderReport: (formData) => window.axios.post(routes.getDriverOrderReportApi,formData),

    exportDriverOrderReport: (formData) => window.axios.post(routes.exportDriverOrderReportApi,formData),

    getVendorRatingReport:  (formData) => window.axios.post(routes.getVendorRatingReportApi,formData),
    getVendorDineRatingReport:  (formData) => window.axios.post(routes.getVendorDineRatingReportApi,formData),
    getVendorRatingReportExport:  (formData) => window.axios.post(routes.getVendorRatingReportExportApi,formData),
    getVendorDineRatingReportExport:  (formData) => window.axios.post(routes.getVendorDineRatingReportExportApi,formData),
    getFeedReadyMarkReports: (formData) => {    
        return window.axios.post(routes.getFoodReadyMarkReportApi,formData)
    }, 
     // sales report export to csv
     exportFoodReadyMarkcsv: (formData) => {
        return window.axios.post(routes.exportFoodReadyMarkcsvApi,formData)
    },
    getReferEarnReports: (formData) => {    
        return window.axios.post(routes.getReferEarnReportsApi,formData)
    }, 
    referEarnExportCsv: (formData) => {
        return window.axios.post(routes.exportReferEarncsvApi,formData)
    },
    getNewSignupReport: (formData)  => window.axios.post(routes.getNewSignupReportApi, formData),
    getNewSignupReportExport: (formData)  => window.axios.post(routes.getNewSignupReportExportApi, formData),

    // zomato report
    getZomatoReport : (formData) => window.axios.post(routes.getZomatoApi,formData),
    exportZomatoToCsv: (formData) => window.axios.post(routes.zomatoReportExportToCsvApi,formData),
}