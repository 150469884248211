
<script>
import DateRangePicker from "vue2-daterange-picker";
import BookingList from "./tabs/booking-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { bookingsService } from '../../services';
import { date_ranges, debounce, booking_statuses } from '../../Helper/helper';
export default {
  components: { DateRangePicker, BookingList,pagination },
  props:['filterChange'],
  data() {
    let startDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().startOf('day').toDate();
    let endDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().endOf('day').toDate();
    return {
      dateRange: { startDate, endDate },
      statuses: booking_statuses,
      bookings: null,
      bookingsCount: null,
      loading: false,
      limit: 1,
      filters: {
        status: 'Pending',
        search: '',
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD')
      },
      debounce,
      searchChanged:debounce(()=>{
          this.getBookings()
        //   this.getBookingsCount();
      },500),
      date_ranges: date_ranges
    };
  },

  watch:{
    "$auth.booking_count": function(){
      this.getData();
    },
  },
 /*  watch:{
    "$auth.bookings": function(){
      this.getData();
    },
    "$route.query": function(){
      this.dateRange =this.changeSettingRange();
      this.filters = {
        ...this.filters,
        status: this.$route.query.status?this.$route.query.status:order_statuses.placed,
        start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }
      
      this.getBookings();
      this.getBookingsCount();
    }
  }, */
  mounted() {
    this.dateRange =this.changeSettingRange();
    this.filters = {
      ...this.filters,
      status: this.$route.query.status?this.$route.query.status:booking_statuses.pending,
      start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
    }
    this.getData();
    // this.getBookingsCount();
  },
  computed:{
    getSelected:function(){
      const { start_date, end_date } = this.filters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.getData()
    },
    getData(){
      this.getNewBookings()
      this.getBookings();
      this.getBookingsCount();
    },
    getNewBookings(){
      bookingsService
        .getNewBookings()
        .then((response) => {
          if(typeof response.data.result == 'object'){
            if(response.data.result.length !== this.$auth.bookings.length){
              this.$auth.updateBookings(response.data.result)
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
    },
    getBookings(page = 1) {
      this.loading = true;
      bookingsService
        .getBookings({
          ...this.filters,
          page,
        })
        .then((response) => {
          this.bookings = response.data.result;
           setTimeout(() => {
            this.loading = false;
          }, 500);
          this.$emit('filterChange',this.filters);
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    onTabChanged(tabIndex){
      const { pending, upcoming, cancelled, completed, expired, rejected } = booking_statuses;
      var statuses = [pending, upcoming, completed, rejected, cancelled, expired];
      this.filters.status = statuses[tabIndex]
      this.getData();
      this.getNewBookings();
    },
    getBookingsCount(){
      this.loading = true;
      bookingsService
        .getBookingsCount({
          ...this.filters
        })
        .then((response) => {
          this.loading = false;
          this.bookingsCount = response.data.result;
        })
        .catch(function (error) {
          this.loading = false;
          // console.log(error);
        });
    }, 
    changeSettingRange(){
      var now = this.moment();
      var then = this.moment();
      if(this.$auth && this.$auth.setting){
        var range = this.$auth.setting.order_dashboard_default_time;
        var date_ranges = this.date_ranges();
        var keys = Object.keys(date_ranges)
        switch(range){
          case "0":
            return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
          case "1":
            return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
          case "6":
            return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
          case "29":
            return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
          case "365":
            return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
          case "101":
            return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
          default:
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        }
      }
      return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="justify-content-end row m-order-list">
                <div class="">
                  <div class="form-group ml-2">
                       <!-- <b-form-group id="example-date-time" label-cols-sm="2" label-cols-lg="2" label="Date and time" label-for="date-time">
                                    <b-form-input id="date-time" value="2019-08-19T13:45:00" type="datetime-local"></b-form-input>
                                </b-form-group> -->
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}} {{(getSelected!=''?('('+getSelected+')'):'')}} :</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        class="w-100"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
                <div class="ml-2">
                  <div class="search-box mr-2 mb-0 d-inline-block form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Search')}} :</label>
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          v-model="filters.search"
                          @keyup="searchChanged"
                          :placeholder="trans.get('__JSON__.Search...')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-tabs
                justified
                nav-class="nav-tabs-custom mt-0 order-tabs"
                content-class="p-3 text-muted"
                lazy
                @activate-tab="onTabChanged"
              >
                <b-tab :button-id="statuses.pending" >
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-plus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.New Requests") 
                    }}</span>
                    <span v-if="bookingsCount && bookingsCount.pending_orders && bookingsCount.pending_orders !== '0'" class="badge badge-success font-size-12">
                      {{ bookingsCount && bookingsCount.pending_orders || 0 }}
                    </span>
                  </template>
                  <BookingList :bookings="bookings" :loading="loading" @refreshData="getData" />
                </b-tab>
                <b-tab :button-id="statuses.upcoming">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Upcoming")
                    }}</span>
                    <span v-if="bookingsCount && bookingsCount.upcoming_orders && bookingsCount.upcoming_orders !== '0'" class="badge badge-primary font-size-12">
                      {{ bookingsCount && bookingsCount.upcoming_orders || 0 }}
                    </span>
                  </template>
                  <BookingList :bookings="bookings" :loading="loading" @refreshData="getData"/>
                </b-tab>
                <b-tab :button-id="statuses.completed">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Completed")
                    }}</span>
                    <span v-if="bookingsCount && bookingsCount.completed_orders && bookingsCount.completed_orders !== '0'" class="badge badge-primary font-size-12">
                      {{ bookingsCount && bookingsCount.completed_orders || 0 }}
                    </span>
                  </template>
                  <BookingList :bookings="bookings" :loading="loading" @refreshData="getData"/>
                </b-tab>
                <b-tab :button-id="statuses.rejected">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Rejected")
                    }}</span>
                    <span v-if="bookingsCount && bookingsCount.rejected_orders && bookingsCount.rejected_orders !== '0'" class="badge badge-primary font-size-12">
                      {{ bookingsCount && bookingsCount.rejected_orders || 0 }}
                    </span>
                  </template>
                  <BookingList :bookings="bookings" :loading="loading" @refreshData="getData"/>
                </b-tab>
                <b-tab :button-id="statuses.cancelled">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Cancelled")
                    }}</span>
                    <span v-if="bookingsCount && bookingsCount.cancelled_orders && bookingsCount.cancelled_orders !== '0'" class="badge badge-primary font-size-12">
                      {{ bookingsCount && bookingsCount.cancelled_orders || 0 }}
                    </span>
                  </template>
                  <BookingList :bookings="bookings" :loading="loading" @refreshData="getData"/>
                </b-tab>
                <b-tab :button-id="statuses.expired">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Expired")
                    }}</span>
                    <span v-if="bookingsCount && bookingsCount.expired_orders && bookingsCount.expired_orders !== '0'" class="badge badge-primary font-size-12">
                      {{ bookingsCount && bookingsCount.expired_orders || 0 }}
                    </span>
                  </template>
                  <BookingList :bookings="bookings" :loading="loading" @refreshData="getData"/>
                </b-tab>
              </b-tabs>
            </div>
            <div class="col-12">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0" v-if="bookings">
                  <pagination
                      :data="bookings"
                      :limit="limit"
                      @pagination-change-page="getBookings"
                  ></pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>