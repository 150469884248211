<script>
import { required, numeric, email,requiredIf } from "vuelidate/lib/validators";
import { error_message, get_currency, get_decimal } from "../../Helper/helper";
import { callCenterDashboardService } from "../../services";
import AddAddress from './address-add.vue';
import OrderList from './../orders/order_tabs/order-list.vue'
import moment from 'moment'
import _ from 'lodash';
export default {
  components: {AddAddress, OrderList},
  data() {
    return {
      mobile_number: "",
      restaurant_count: "",
      restaurant:[],
      customerAddress: [],
      checksNumber: null,
      user_details: { 
        user_id : null, 
        email : '', 
        user_name : '', 
        last_name : '', 
        mobile_number : '',
        address_id: '',
        restaurant_id: '',
        delivery_type: 'Delivery'
      },
      addNewAddress:false,
      customerOrders: null,
      loading: false,
      driverColumns:[
        { key: "driver", label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}), sortable: false,formatter: value => (value.length > 0 ?`${value[0].firstname+' '+value[0].lastname}`:'-')}
      ],
      moment:moment,
      currency:get_currency(),
      decimal:get_decimal(),
      _:_
    };
  },
  validations: {
    user_details:{
      mobile_number: {
        required,
        numeric,
      },
      user_name:{
        required
      },
      email:{
        email,
        async isUnique(value){
          if (value === '') return true
          const response = await callCenterDashboardService.checkEmail({email: value, user_id: this.user_details.user_id})
          return Boolean(response.data.result.valid);
        }
      },
      address_id:{
        required: requiredIf((form) => (form.delivery_type == 'Delivery'))
      },
      restaurant_id:{
        required
      }
    }
  },
  methods: {
    validate(){
      this.$v.user_details.$touch();
      var isValid = !this.$v.user_details.$invalid
      this.$emit('on-validate', this.user_details, isValid)
      this.$emit('onGetUser',this.user_details)
      return isValid
    },
    checkNumber() {
      this.customerOrders = null;
      this.user_details = { user_id : null, email : '', user_name : '', last_name : '', mobile_number :  this.user_details.mobile_number,address_id: '',restaurant_id: '', delivery_type: this.user_details.delivery_type}
      this.customerAddress = []
      if(this.user_details.delivery_type == 'Delivery'){
        this.restaurant = []
      }
      this.$v.user_details.mobile_number.$touch();
      if (this.$v.user_details.mobile_number.$invalid) {
        return;
      } else {
        callCenterDashboardService
          .checkMobileNumber({
            mobile_number: this.user_details.mobile_number,
          })
          .then((response) => {
            this.checksNumber = response.data;            
            if (response.data.code == 200) {
              this.name =
                response.data.result[0].user_name +
                " " +
                response.data.result[0].last_name;
              this.emails = response.data.result[0].email;
              this.user_id = response.data.result[0].user_id;
              const { user_id, email, user_name, last_name,mobile_number } = response.data.result[0]
              this.user_details = { user_id, email, user_name, last_name,mobile_number, address_id: '',restaurant_id: '', delivery_type: this.user_details.delivery_type};
              this.$v.user_details.$touch()
              this.getCustomerOrder();
            } else {
              error_message(response.data.message);
              this.user_details = { user_id : null, email : '', user_name : '', last_name : '', mobile_number :  this.user_details.mobile_number,address_id: '',restaurant_id: '', delivery_type: this.user_details.delivery_type}
            }
            this.$emit('onGetUser',this.user_details)
            this.getCustomerAddress();
          });
      }
    },
    getCustomerAddress(id) {
      if(!this.user_details.user_id){
        this.user_details = {
          ...this.user_details,
          address_id: id || this.customerAddress[0].id
        }
        this.$emit('onRestaurantSelect','');
        this.getRestaurants();
          return false;
      }
      callCenterDashboardService
        .getCustomerAddress({
          user_id: this.user_details.user_id,
        })
        .then((response) => {
          this.customerAddress = response.data.result;
          if(!id){
            this.user_details.address_id = this.customerAddress.find(
              (a) => a.is_primary_address == "1"
            )?.id;
          }else{
            this.user_details = {
              ...this.user_details,
              address_id: id
            }
          }
          this.$emit('onRestaurantSelect','');
          this.getRestaurants();
        });
    },
    getRestaurants(){
        let address = this.customerAddress.find(add => add.id == this.user_details.address_id);
        this.$emit('addressChanged',address)
        if(this.user_details.delivery_type == 'Delivery'){
          this.restaurant = [];
        }
        callCenterDashboardService.getAllRestaurantName({
          lat: address && address.latitude || '',
          lng: address && address.longitude || '',
          delivery_type: this.user_details.delivery_type
        }).then((response) => {
          this.restaurant = response.data.result;
          this.$emit('onGetRestaurant',response.data.result);
          this.$emit('onGetUser',this.user_details)
        });
    },
    onAddressAdded(address){
      this.$bvModal.hide('addNewAddress');

      this.customerAddress = [...this.customerAddress,address];
      this.getCustomerAddress(address.id) 
    },
    clearData(){
      this.user_details = { user_id : null, email : '', user_name : '', last_name : '', mobile_number : '', delivery_type:this.user_details.delivery_type }
      this.$v.user_details.$reset()
      this.customerAddress = []
      if(this.user_details.delivery_type == 'Delivery'){
        this.restaurant = []
      }
      this.$emit('onRestaurantSelect','');  
    },
    clearSave(){
      this.user_details.user_id = null;
      this.customerAddress = [];
      this.user_details.address_id='';
      if(this.user_details.delivery_type == 'Delivery'){
        this.restaurant = [];
      }
    },
    getFilteredRestaurants(){
      return this.restaurant.filter(rest => [this.user_details.delivery_type == 'Delivery'?'1':'0','2'].includes(rest.delivery_type_time_slots));
    },
    resetRestaurant(){
      this.getRestaurants()
      this.user_details = {
        ...this.user_details,
        restaurant_id:''
      }
    },
    changeRestaurant(){
      this.$v.user_details.restaurant_id.$touch();
      this.getCustomerOrder();
      this.$emit('onRestaurantSelect',this.user_details.restaurant_id)
    },
    getCustomerOrder(){
      this.loading = true;
      const rest = this.restaurant.find(r => r.restaurant_id === this.user_details.restaurant_id)
      callCenterDashboardService.getCustomerLastOrders({
        customer_id: this.user_details.user_id,
        limit: 5,
        restaurant_user_id: rest?rest.user_id:0
      }).then(response => {
        this.loading = false;
        this.customerOrders = response.data.result;
      })
    },
    getSelections(items,price){
      return items.length > 0 ? items.reduce((accu, item) => {
        return accu.concat(item.selection_name +' x '+ parseFloat(item.selection_price || price || 0).toFixed(this.decimal));
      },[]).join(', '): '';
    },
    getSelectionPrices(items){
      return parseFloat(items.length > 0 ? items.reduce(function(accu, item){
        return accu + parseFloat(item.selection_price);
      },0): 0);
    },
    mrpPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_mrp || 0)))
    },
    totalPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_price || 0)))
    },
    getOrderItem(order){
      return order.delivery_pickup_types != 'Dine In' ? order.order_item : order.order_item_dine;
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="mobile_number">{{
          trans.get("__JSON__.Phone Number")
        }}</label>
        <div class="col-md-9">
          <b-input-group :class="{ 'is-invalid': $v.user_details.mobile_number.$error }">
            <b-form-input
              type="tel"
              v-model="$v.user_details.mobile_number.$model"
              @change="clearSave"
              name="mobile_number"
              :placeholder="trans.get('__JSON__.Enter Phone Number')"
              @keyup.enter="checkNumber"
              @focusout="checkNumber" >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="outline-danger" @click="clearData" ><i class="fas fa-times"></i></b-button>
              <b-button variant="outline-primary" @click="checkNumber" >Get Info</b-button>
            </b-input-group-append>
          </b-input-group>
          <div v-if="$v.user_details.mobile_number.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.mobile_number.required">{{
              trans.get("__JSON__.Please enter contact number")
            }}</span>
            <span v-if="!$v.user_details.mobile_number.numeric">{{
              trans.get("__JSON__.This value should be a valid number")
            }}</span>
          </div>
          <!-- <input
            type="tel"
            v-model="$v.user_details.mobile_number.$model"
            @change="clearSave"
            name="mobile_number"
            class="form-control"
            :placeholder="trans.get('__JSON__.Enter Phone Number')"
            :class="{ 'is-invalid': $v.user_details.mobile_number.$error }"
            @keyup.enter="checkNumber"
          />
          <div v-if="$v.user_details.mobile_number.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.mobile_number.required">{{
              trans.get("__JSON__.Please enter contact number")
            }}</span>
            <span v-if="!$v.user_details.mobile_number.numeric">{{
              trans.get("__JSON__.This value should be a valid number")
            }}</span>
          </div>
          <button type="button" class="btn btn-danger" @click="clearData" style="
                right: 85px;
                padding-top: 6px;
            ">
          <i class="fas fa-times"></i>
        </button>
          <button type="button" class="btn-btn primary" @click="checkNumber">
            {{ trans.get("__JSON__.Get Info") }}
          </button> -->
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="name">Name</label>
        <div class="col-md-9">
          <input
            type="text"
            name="name"
            v-model="$v.user_details.user_name.$model"
            :disabled="user_details.user_id"
            class="form-control"
            :placeholder="trans.get('__JSON__.Enter Name')"
            :class="{ 'is-invalid': $v.user_details.user_name.$error }"
          />
          <div v-if="$v.user_details.user_name.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.user_name.required">{{
              trans.get("__JSON__.Please enter user name")
            }}</span>
          </div>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="email">Email</label>
        <div class="col-md-9">
          <input
            type="email"
            name="email"
            v-model="$v.user_details.email.$model"
            :disabled="user_details.user_id"
            :class="{ 'is-invalid': $v.user_details.email.$error }"
            class="form-control"
            :placeholder="trans.get('__JSON__.Enter Email')"
          />
          <div v-if="$v.user_details.email.$error" class="invalid-feedback">
            <!-- <span v-if="!$v.user_details.email.required">{{
              trans.get("__JSON__.Please enter email")
            }}</span> -->
            <span v-if="!$v.user_details.email.email">{{
              trans.get("__JSON__.Please enter valid email")
            }}</span>
            <span v-if="!$v.user_details.email.isUnique">{{
              trans.get("__JSON__.Email already exists")
            }}</span>
          </div>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="addreses">Address</label>
        <div class="col-md-9">
           <b-input-group :class="{ 'is-invalid': $v.user_details.address_id.$error }">
             <select
              class="custom-select"
              v-model="$v.user_details.address_id.$model"
              @change="getRestaurants"
              
            >
              <option key="a" value="">Select Address</option>
              <option
                v-for="adres in customerAddress"
                :key="adres.id"
                :value="adres.id"
              >
                {{ adres.address }}
              </option>
            </select>
            <b-input-group-append>
              <b-button v-b-modal.addNewAddress variant="outline-primary" @click="addNewAddress = true">{{ trans.get("__JSON__.Add Address") }}</b-button>
            </b-input-group-append>
          </b-input-group>         
          <div v-if="$v.user_details.address_id.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.address_id.required">{{
              trans.get("__JSON__.Please select address")
            }}</span>
          </div>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label class="col-md-3 col-form-label" for="restaurant">{{
          trans.trans("__JSON__.Vendor", {
            vendor: $auth.setting.restaurant_name,
          })
        }}</label>
        <div class="col-md-9">
          <b-input-group :class="{ 'is-invalid': $v.user_details.restaurant_id.$error }">
          <select
            class="custom-select"
            v-model="$v.user_details.restaurant_id.$model"
            v-on:change="changeRestaurant"
          >
            <option value="">
              {{
                trans.trans("__JSON__.All Vendor", {
                  vendor: $auth.setting.restaurant_name,
                })
              }}
            </option>
            <option
              v-for="res in getFilteredRestaurants()"
              :value="res.restaurant_id"
              :key="res.restaurant_id"
              :service_fee="res.service_fee"
            >
              {{ res.name }} {{ user_details.delivery_type =='Delivery'? ' - '+res.distance:'' }}
            </option>
          </select>
          <b-input-group-append is-text>
            <b-form-radio class="mr-n2 mb-n1" v-model="user_details.delivery_type" @input="resetRestaurant()" name="delivery_type" value="Delivery">
              <span class="font-weight-lighter pr-1">Delivery</span>
            </b-form-radio>
          </b-input-group-append>
          <b-input-group-append is-text>
            <b-form-radio class="mr-n2 mb-n1" v-model="user_details.delivery_type" @input="resetRestaurant()" name="delivery_type" value="Pickup">
              <span class="font-weight-lighter pr-1">TakeAway</span>
            </b-form-radio>
          </b-input-group-append>
          </b-input-group>
          <div v-if="$v.user_details.restaurant_id.$error" class="invalid-feedback">
            <span v-if="!$v.user_details.restaurant_id.required">{{
              trans.get("__JSON__.Please select restaurant")
            }}</span>
          </div>
        </div>
        <div class="col-md-12">
          <!-- <OrderList :orders="customerOrders" :loading="loading" :extra-columns="driverColumns" @refreshData="getCustomerOrder" /> -->
          <div class="row mt-4" v-if="customerOrders && customerOrders.total > 0">
            <div class="col">
              <h3>Orders</h3>
            </div>
          </div>
          <template v-if="!loading && customerOrders && customerOrders.total > 0">
          <div class="row" v-for="(orders, index) in $data._.chunk(customerOrders.data, 3)" :key="index">
            <div class="col-sm-4" v-for="order in orders" :key="order.id">
              <b-card class="shadow-sm">
                <b-card-title>
                  #{{order.id}} - 
                  <span class="badge badge-pill badge-soft-primary font-size-12" v-if="order && order.delivery_pickup_types == 'Delivery'">
                    {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
                  </span>
                  <span class="badge badge-pill badge-soft-danger font-size-12" v-if="order && order.delivery_pickup_types == 'Takeaway'">
                    {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
                  </span>
                  <span class="badge badge-pill badge-soft-danger font-size-12" v-if="order && order.delivery_pickup_types == 'Pickup'">
                    {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
                  </span>
                  <span class="badge badge-pill badge-soft-pink font-size-12" v-if="order && order.delivery_pickup_types == 'Dine In'">
                    {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
                  </span>
                  <span class="badge badge-pill badge-soft-secondary font-size-12" v-if="order && order.access">
                    <i class="bx bx-phone-call"></i> {{trans.get('__JSON__.'+order && order.access && order.access.vendor_name)}}
                  </span>
                  <!-- <b-badge pill variant="primary">{{order.delivery_pickup_types}}</b-badge> -->
                </b-card-title>
                <b-card-sub-title>
                  <small>{{moment(order.created_at).format('DD MMM, hh:ss A')}}</small>
                </b-card-sub-title>
                <b-card-text class="h5 mb-0 pt-3 text-secondary">
                  {{order.vendor_name}}
                </b-card-text>
                <strong><span v-html="currency"></span> {{order.total_amount}}</strong>
                <span class="badge badge-pill font-size-12" :class="{'badge-soft-success': order && (order.payment_method == 'Cash On Delivery'),'badge-soft-warning':order && (order.payment_method != 'Cash On Delivery')}">
                  {{order && (order.payment_method == 'Cash On Delivery'?'COD':order.payment_method)}}
                </span>
                <br>
                <span>----------------------</span> 
                <table>

                  <tr v-for="item in getOrderItem(order)" :key="item.id">
                      <td>{{item.menu_item && item.menu_item.item_name}} {{getSelections(item.menu_item_selections,item.item_mrp)}}</td>
                      <td>&nbsp;&nbsp; x &nbsp;&nbsp;</td>
                      <td align="right">
                        <span> {{item.quantity}}</span>
                      </td>
                    </tr>
                  </table>
                
              </b-card>
            </div>
          </div>
          </template>
          <div class="row" v-if="loading">
            <div class="col text-center">
              <b-spinner class="m-2" variant="danger" role="status"></b-spinner>
            </div>
          </div>

        </div>
      </div>
    </div>
    <b-modal id="addNewAddress" tabindex="-1" :title="trans.get('__JSON__.Add Address')" centered hide-footer>
      <add-address :user_id="user_details.user_id" @address-added="onAddressAdded" @onCancel="$bvModal.hide('addNewAddress')" />
    </b-modal>
  </div>
</template>
<style>
</style>