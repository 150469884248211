<script>
import { get_currency, get_decimal } from "../../../Helper/helper";

export default {
  props: {
    order: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    thirdPartyOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currency: get_currency(),
      decimal: get_decimal(),
    };
  },
  methods: {
    btoa(str) {
      return btoa(str);
    },
    dunzoHtml() {
      let $data = "";
      if (this.order.dunzo_task) {
        $data =
          "<span>Task Id: " +
          this.order.dunzo_task.task_id +
          "</span><br><span>" +
          this.$auth.getDriverName() +
          ": " +
          ((this.order.dunzo_task.runner && this.order.dunzo_task.runner.name) ||
            "Not Assigned") +
          "</span><br><span>" +
          this.$auth.getDriverName() +
          " Phone: " +
          ((this.order.dunzo_task.runner && this.order.dunzo_task.runner.phone_number) ||
            "") +
          "</span><br><span> Status: " +
          this.order.dunzo_task.status +
          "";
        this.order.dunzo_task.locations_order &&
          this.order.dunzo_task.locations_order.forEach((o) => {
            $data +=
              (o.type == "pick" ? "<br>Pickup OTP: " : "<br>Dropoff OTP: ") +
              "" +
              (o.otp || "-");
          });
      }
      return $data;
    },
    pidgeHtml() {
      let $data = "";
      if (this.order.pidge_task) {
        $data =
          "<span>Task Id: " +
          this.order.pidge_task.task_id +
          "</span><br><span>" +
          this.$auth.getDriverName() +
          ": " +
          ((this.order.pidge_task.runner && this.order.pidge_task.runner.name) ||
            "Not Assigned") +
          "</span><br><span>" +
          this.$auth.getDriverName() +
          " Phone: " +
          ((this.order.pidge_task.runner && this.order.pidge_task.runner.mobile) || "") +
          "</span><br><span> Status: " +
          this.order.pidge_task.status +
          "";
        this.order.pidge_task.locations_order &&
          this.order.pidge_task.locations_order.forEach((o) => {
            $data +=
              (o.type == "pick" ? "<br>Pickup OTP: " : "<br>Dropoff OTP: ") +
              "" +
              (o.otp || "-");
          });
      }
      return $data;
    },
  },
};
</script>

<template>
  <!-- View Detail Main Start -->
  <div class="row mt-3 settings-main">
    <div class="col-12">
      <div class="text-center text-danger my-2" v-if="loading">
        <b-spinner class="align-middle"></b-spinner>
        <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <div class="row">
        <div class="col-md-4">
          <h6>
            {{
              trans.get("__JSON__.Vendor's Name", { vendor: $auth.getRestaurantName() })
            }}
          </h6>

          <template v-if="thirdPartyOrder">
            <p>{{ (order.fa_order_address && order.fa_order_address.restaurant_name) ? order.fa_order_address.restaurant_name : '' }}</p>
          </template>
          <template v-else>
            <p>
              <b-link
                :to="{
                  name: 'vendor-detail',
                  params: {
                    restaurant_id: btoa(
                      (order && order.restaurant && order.restaurant.restaurant_id) || 0
                    ),
                  },
                }"
                >{{ order.restaurant.name }}</b-link
              >
            </p>
          </template>
        </div>
        <div class="col-md-4">
          <h6>{{ trans.get("__JSON__.Customer's Name") }}</h6>
          <template v-if="thirdPartyOrder">
            <p>{{ (order.fa_order_address && order.fa_order_address.customer_name) ? order.fa_order_address.customer_name : '' }}</p>
          </template>
          <template v-else>
            <p>
              <b-link
                :to="{
                  name: 'customer-detail',
                  params: { user_id: btoa(order.user.user_id) },
                }"
                >{{ order.user.user_name + " " + (order.user.last_name || "") }}</b-link
              >
            </p>
          </template>
        </div>
        <div class="col-md-4">
          <h6>
            {{ trans.get("__JSON__.Driver Name", { driver: $auth.getDriverName() }) }}
          </h6>
          <p
            v-if="
              order.driver.length > 0 &&
              order.dunzo_driver == '0' &&
              order.pidge_driver == '0'
            "
          >
            <b-link
              :to="{
                name: 'driver-details',
                params: { driver_id: btoa(order.driver[0].driverid) },
              }"
            >
              {{
                order.driver.length > 0
                  ? order.driver[0].firstname + " " + order.driver[0].lastname
                  : "-"
              }}</b-link
            >
          </p>
          <p
            v-else-if="
              order.dunzo_task && order.dunzo_driver == '1' && order.driver.length == '0'
            "
          >
            {{ order.dunzo_task.runner ? order.dunzo_task.runner.name : "-" }}
            <span class="badge badge-pill font-size-12 badge-soft-success"
              >DUNZO DRIVER</span
            >
            <span
              v-b-popover.html="dunzoHtml()"
              :title="trans.get('__JSON__.Task Details')"
              ><i class="bx bx-info-circle"></i
            ></span>
          </p>
          <p
            v-else-if="
              order.pidge_task && order.pidge_driver == '1' && order.driver.length == '0'
            "
          >
            {{ order.pidge_task.runner ? order.pidge_task.runner.name : "-" }}
            <span class="badge badge-pill font-size-12 badge-soft-success"
              >PIDGE DRIVER</span
            >
            <span
              v-b-popover.html="pidgeHtml()"
              :title="trans.get('__JSON__.Task Details')"
              ><i class="bx bx-info-circle"></i
            ></span>
          </p>
          <!-- <template v-if="thirdPartyOrder">
              <p>----</p>
            </template>
            <template v-else>
              <p v-if="order.driver.length > 0 && order.dunzo_driver == '0'"><b-link :to="{ name: 'driver-details', params:{driver_id: btoa(order.driver[0].driverid) }}"> {{ order.driver.length > 0 ? order.driver[0].firstname+' '+order.driver[0].lastname:'-' }}</b-link></p>
                <p v-else-if="order.dunzo_task && order.dunzo_driver == '1' && order.driver.length == '0'">{{ order.dunzo_task.runner ? order.dunzo_task.runner.name:'-' }} <span class="badge badge-pill font-size-12 badge-soft-success">DUNZO DRIVER</span>
                <span v-b-popover.html="dunzoHtml()" :title="trans.get('__JSON__.Task Details')"><i class="bx bx-info-circle"></i></span>
              </p>
            </template> -->
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <div class="row sections-main">
        <div class="col-md-4">
          <h6>
            {{
              trans.get("__JSON__.Merchant Phone", { vendor: $auth.getRestaurantName() })
            }}
          </h6>
          <template v-if="thirdPartyOrder">
            <p>{{ (order.fa_order_address && order.fa_order_address.restaurant_contact_number) ? order.fa_order_address.restaurant_contact_number : '' }}</p>
          </template>
          <template v-else>
            <p>{{ order.restaurant.phone }}</p>
          </template>
        </div>
        <div class="col-md-4">
          <h6>{{ trans.get("__JSON__.Customer's Phone") }}</h6>
          <template v-if="thirdPartyOrder">
            <p>{{ (order.fa_order_address && order.fa_order_address.customer_contact_number) ? order.fa_order_address.customer_contact_number : '' }}</p>
          </template>
          <template v-else>
            <p>{{ order.user.country_code + " " + order.user.mobile_number }}</p>
          </template>
        </div>
        <div class="col-md-4">
          <h6>
            {{ trans.get("__JSON__.Driver Phone", { driver: $auth.getDriverName() }) }}
          </h6>
          <p v-if="order.dunzo_driver == '0' && order.pidge_driver == '0'">
            {{
              order.driver.length > 0
                ? order.driver[0].country_code + " " + order.driver[0].contactno
                : "-"
            }}
          </p>
          <p v-if="order.dunzo_task && order.dunzo_driver == '1'">
            {{ order.dunzo_task.runner ? order.dunzo_task.runner.phone_number : "-" }}
          </p>
          <p v-if="order.pidge_task && order.pidge_driver == '1'">
            {{ order.pidge_task.runner ? order.pidge_task.runner.mobile : "-" }}
          </p>
          <!-- <template v-if="thirdPartyOrder">
              <p>-</p>
            </template>
            <template v-else>
              <p v-if="order.dunzo_driver == '0'"> {{ ((order.driver.length > 0) ? (order.driver[0].country_code+' '+order.driver[0].contactno) : '-') }}</p>
              <p v-if="order.dunzo_task && order.dunzo_driver == '1'">{{ order.dunzo_task.runner ? order.dunzo_task.runner.phone_number:'-' }}</p>
            </template> -->
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-3" v-if="!loading">
      <div class="row sections-main">
        <div class="col-md-4">
          <h6>
            {{ trans.get("__JSON__.Pickup Location") }}
            <i class="bx bx-map bx-border"></i>
          </h6>
          <template v-if="thirdPartyOrder">
            <p>{{ (order.fa_order_address && order.fa_order_address.restaurant_address) ? order.fa_order_address.restaurant_address : '' }}</p>
          </template>
          <template v-else>
            <p>{{ order.restaurant.address }}</p>
          </template>
        </div>
        <div class="col-md-4">
          <h6>
            {{ trans.get("__JSON__.Drop Location") }} <i class="bx bx-map bx-border"></i>
          </h6>
          <template v-if="thirdPartyOrder">
            <p>{{ (order.fa_order_address && order.fa_order_address.customer_address) ? order.fa_order_address.customer_address : '' }}</p>
          </template>
          <template v-else>
            <!-- <p><span class="font-weight-bold">{{ trans.get('__JSON__.Area') }}</span>: {{order.area}}</p> -->
            <p>
              <span class="font-weight-bold">{{ trans.get("__JSON__.Address") }}</span
              >: {{ order.area }}, {{ order.shipping_address }}
            </p>
            <p>
              <span class="font-weight-bold">{{ trans.get("__JSON__.Locality") }}</span
              >: {{ order.locality }}
            </p>
          </template>
        </div>
        <div class="col-md-4">
          <h6>{{ trans.get("__JSON__.Payment Details") }}</h6>
          <p>
            <span
              class="text-success"
              v-if="order.wallet_amount != 0"
              v-html="currency"
            ></span>
            <span v-if="order.wallet_amount != 0" class="text-success"
              >{{ parseFloat(order.wallet_amount || 0).toFixed(2) }}/{{
                order.payment_method
              }}</span
            >
            <br v-if="order.amount != 0 || order.reddemed_coupon_order" />
            <span class="text-success" v-if="order.amount != 0" v-html="currency"></span>
            <span v-if="order.amount != 0" class="text-success"
              >{{ (order.amount || 0).toFixed(2) }}/{{
                order.payment_method == "Wallet" ? "Online" : order.payment_method
              }}</span
            >
            <template v-if="order.reddemed_coupon_order">
              <br />
              <span class="text-danger" v-html="currency"></span>
              <span class="text-danger"
                >{{
                  (
                    order.reddemed_coupon_order.original_price -
                      order.reddemed_coupon_order.discount_price || 0
                  ).toFixed(2)
                }}/{{ trans.get("__JSON__.Coupon Discount") }}</span
              >
            </template>
            <template
              v-if="
                order.reddemed_coupon_order && order.reddemed_coupon_order.cashback !== 0
              "
            >
              <br />
              <span class="text-danger" v-html="currency"></span>
              <span class="text-danger"
                >{{ (order.reddemed_coupon_order.cashback || 0).toFixed(2) }}/{{
                  trans.get("__JSON__.Cashback")
                }}</span
              >
            </template>
            <span>{{ order.transaction_id ? order.transaction_id : "" }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-3" v-if="!loading">
      <div class="row sections-main">
        <div class="col-md-4" v-if="order.created_date != '0000-00-00 00:00:00'">
          <h6>{{ trans.get("__JSON__.Placement time") }}</h6>
          <p v-if="order.created_date != '0000-00-00 00:00:00'">
            {{ order.created_date }}
          </p>
        </div>
        <div
          class="col-md-4"
          v-if="order.future_delivery_date != '0000-00-00 00:00:00' && !thirdPartyOrder"
        >
          <h6>{{ trans.get("__JSON__.Schedule Time") }}</h6>
          <div class="align-items-center d-flex">
            <span>
              {{ moment(order.future_delivery_date).format("MMM DD[,] YYYY hh:mm A") }}
            </span>
          </div>
        </div>
        <div
          class="col-md-4"
          v-if="order.confirm_date && order.confirm_date != '0000-00-00 00:00:00'"
        >
          <h6>{{ trans.get("__JSON__.Accepted Time") }}</h6>
          <p v-if="order.confirm_date != '0000-00-00 00:00:00'">{{ order.confirm_at }}</p>
        </div>
        <div
          class="col-md-4"
          v-if="
            order.readytoserve_date && order.readytoserve_date != '0000-00-00 00:00:00'
          "
        >
          <h6>{{ trans.get("__JSON__.Ready To Serve time") }}</h6>
          <p v-if="order.readytoserve_date != '0000-00-00 00:00:00'">
            {{ order.readytoserve_at }}
          </p>
        </div>
        <div
          class="col-md-4"
          v-if="order.ontheway_date && order.ontheway_date != '0000-00-00 00:00:00'"
        >
          <h6>{{ trans.get("__JSON__.Picked up Time") }}</h6>
          <p>{{ order.ontheway_at }}</p>
        </div>
        <div
          class="col-md-4"
          v-if="order.delivered_date && order.delivered_date != '0000-00-00 00:00:00'"
        >
          <h6>{{ trans.get("__JSON__.Delivered time") }}</h6>
          <p>{{ order.delivered_at }}</p>
        </div>
        <div class="col-md-4" v-if="order.delivery_pickup_types != 'Pickup'">
          <h6>{{ trans.get("__JSON__.Delivery Time") }}</h6>
          <div v-if="order.eta && order.eta !== ''" class="align-items-center d-flex">
            <span>{{ moment(order.eta).format("MMM DD[,] YYYY hh:mm A") }}</span>
            <span
              v-if="
                order.future_delivery_date != '0000-00-00 00:00:00' && !thirdPartyOrder
              "
              class="mdi mdi-calendar text-primary font-size-18 pl-1"
            ></span>
          </div>
          <div v-else>
            <span v-if="order.future_delivery_date != '0000-00-00 00:00:00'">
              {{ moment(order.future_delivery_date).format("MMM DD[,] YYYY hh:mm A") }}
            </span>
            <span v-else>
              {{ moment(order.created_at).add(30, "minutes").format("HH:mm:ss") }}
            </span>
          </div>
        </div>
        <div class="col-md-4" v-if="order.order_status == 'Cancelled'">
          <h6>{{ trans.get("__JSON__.Cancelled time") }}</h6>
          <p>{{ order.cancelled_date }}</p>
          <!-- <span v-b-popover.hover.top="order.cancelled_desc" :title="trans.get('__JSON__.Cancel Description')" class="font-size-15"><i class="bx bx-info-circle"></i></span> -->
        </div>
        <div class="col-md-4" v-if="order.order_status == 'Cancelled'">
          <h6>{{ trans.get("__JSON__.Cancel Description") }}</h6>
          <p>{{ order.cancelled_desc }}</p>
        </div>
      </div>
    </div>

    <!-- <div class="col-md-12" v-if="!loading">
        <div class="row sections-main">
        </div>
      </div> -->

    <div class="col-md-12 mt-3" v-if="!loading">
      <div class="row sections-main">
        <div class="col-md-4" v-if="order.distance_km && order.distance_km != ''">
          <h6>{{ trans.get("__JSON__.Distance In KM") }}</h6>
          <p>{{ order.distance_km }}</p>
        </div>
        <!-- <div class="col-md-4" v-if="order.distance_mintue && order.distance_mintue != ''">
            <h6>{{trans.get('__JSON__.Distance In Minute')}}</h6>
            <p>{{ order.distance_mintue }}</p>
          </div> -->
        <div class="col-md-4">
          <h6>{{ trans.get("__JSON__.Last Mile Earning") }}</h6>
          <p>
            <span v-html="currency"></span>
            {{ parseFloat(order.driver_earning || 0).toFixed(decimal) }}
          </p>
        </div>
        <div class="col-md-4">
          <h6>{{ trans.get("__JSON__.Admin Earning") }}</h6>
          <p>
            <span v-html="currency"></span>
            {{ parseFloat(order.admin_commision || 0).toFixed(decimal) }}
          </p>
        </div>
        <div class="col-md-4">
          <h6>{{ trans.get("__JSON__.Delivery Charge") }}</h6>
          <p>
            <span v-html="currency"></span>
            {{ parseFloat(order.delivery_fee || 0).toFixed(decimal) }}
          </p>
        </div>
        <div class="col-md-4">
          <h6>{{ trans.get("__JSON__.Notes") }}</h6>
          <p>{{ order.notes }}</p>
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-3" v-if="!loading">
      <div class="row sections-main">
        <div class="col-md-4" v-if="!thirdPartyOrder && order.device_type && order.device_type != ''">
          <template v-if="!thirdPartyOrder">
            <h6>{{ trans.get("__JSON__.Device Type") }}</h6>
            <p v-if="order.device_type == 'Web'">{{ order.device_type }}</p>
            <p v-else>{{ order.device_type + " " + order.app_version_code }}</p>
          </template>
        </div>
        <div class="col-md-4" v-if="order.cancelled_from && order.cancelled_from != ''">
          <h6>{{ trans.get("__JSON__.Cancelled By") }}</h6>
          <p>{{ order.cancelled_from }}</p>
        </div>
      </div>
    </div>

    <!-- View Detail Main End -->
  </div>
</template>
