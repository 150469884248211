<script>
import config from "../../config";
import DateRangePicker from "vue2-daterange-picker";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import Layout from "../../layouts/main";
import { giftVoucherService } from "../../services";
import { debounce, date_ranges, error_message, success_message,replaceByDefault,get_currency } from "../../Helper/helper";


export default {
  components: {DateRangePicker, PageHeader, pagination, moment, Layout},
  data() {
    const { payment_status, orderBy, sortBy, search } = this.$route.query;
    let startDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().startOf('day').toDate();
    let endDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().endOf('day').toDate();
    return {
      dateRange: { startDate, endDate },
      get_currency,
      replaceByDefault,
      sortBy: sortBy || "created_at",
      sortDesc: orderBy || true,
      giftVouchers: null,
      giftVouchersData: [],
      giftVouchersCount: "",
      paginations: null,
      limit: 2,
      config: config,
      moment: moment,
      loading: true,
      init: true,
      searchData: debounce(() => {
        this.getPurchasedGiftVouchers();
        // this.giftVoucherExportCsv();
        this.init = false;
      }, 500),
      fields: [
        { key: "id", label: "Id", sortable: false },
        { key: "full_image", label: this.trans.get("__JSON__.Image"), sortable: false },
        { key: "title", label: this.trans.get("__JSON__.Title"), sortable: false },
        { key: "user_name", label: this.trans.get("__JSON__.Purchased By"), sortable: false },
        { key: "email", label: this.trans.get("__JSON__.Email"), sortable: false },
        { key: "redeemed_by", label: this.trans.get("__JSON__.Redeemed By"), sortable: false },
        { key: "gift_code", label: this.trans.get("__JSON__.Redeem Code"), sortable: false },
        { key: "code", label: this.trans.get("__JSON__.Ref. Code"), sortable: false },
        { key: "amount", label: this.trans.get("__JSON__.Amount"), sortable: false },
        { key: "payment_status", label: this.trans.get("__JSON__.Status"), sortable: false },
        { key: "created_at", label: this.trans.get("__JSON__.Created At"), sortable: false },
        { key: "transaction_id", label: this.trans.get("__JSON__.Payment ID"), sortable: false },
        { key: "wallet_pay_id", label: this.trans.get("__JSON__.Wallet Pay ID"), sortable: false },
        // { key: "created_at", label: this.trans.get("__JSON__.Created At"), sortable: true, },
      ],
      date_ranges: date_ranges,
      filters: {
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
        search: search || "",
        payment_status: payment_status || "",
        sortBy: sortBy,
        orderBy: orderBy ? "desc" : "asc",
      }
    };
  },
  mounted() {
    this.getPurchasedGiftVouchers(0);
  },
  computed:{
    getSelected:function(){
      const { start_date, end_date } = this.filters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  methods: {
    exportData(){
      
      giftVoucherService.exportGiftVouchers(this.filters).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],{ type:"application/xlsx" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll("\"","")); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    onRowClicked(item) {
      
    },
    getPurchasedGiftVouchers(page = 0) {
      if (!this.init) {
        const { payment_status, orderBy, sortBy, search } = this.filters;
        this.$router.replace({
          name: "gift-voucher-purchased-list",
          query: { payment_status, orderBy, sortBy,search },
        });
      }
      this.loading = true;
      var filters = {
        ...this.filters,
        page: page && page > 0 ? page : 1,
      };
      giftVoucherService.getPurchasedGiftVoucherservice(filters).then((response) => {
        this.loading = false;
        this.paginations = response.data.result;
        this.giftVouchers = response.data.result.data;
        this.giftVouchersCount = response.data.result.total;
      });
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.getPurchasedGiftVouchers()
      
    },
    sortingChanged(ctx) {
      this.filters.sortBy = ctx.sortBy;
      this.filters.sortDesc = ctx.sortDesc;
      this.getPurchasedGiftVouchers();
    },
    changeStatus(status, id){
      giftVoucherService.changeGiftVoucherStatus({id: this.base64(id),status})
        .then(res => {
          if(res.data.code == 200){
            success_message(res.data.message)
            return true;
          }
          error_message(res.data.message)
        })
    }
  },
};
</script>

<template>
<Layout>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-card"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Purchased Gift Vouchers', 'Purchased Gift Vouchers')
            }}({{ giftVouchersCount }})</h4>
          </div>
          <div class="page-title-right d-flex">
            <button @click="exportData" 
            v-if="$auth.hasSubAdminPermission(config.permissions.gift_voucher_export)"
            class="btn btn-primary btn-rounded export-btn">{{
                trans.get('__JSON__.Export To CSV')
            }}</button>
            <div class="d-flex m-vendor-btn">

              <button  class="btn btn-primary btn-rounded m-export-btn">
                <i class="bx bx-archive-in"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3 offset-sm-3">
                <div class="date-range-list">
                  <label>{{trans.get('__JSON__.Date')}} {{(getSelected!=''?('('+getSelected+')'):'')}} :</label>
                  <date-range-picker
                    ref="picker"
                    :locale-data="{
                      firstDay: 1,
                      format: 'dd-mm-yyyy',
                    }"
                    :showDropdowns="true"
                    :showWeekNumbers="true"
                    opens="right"
                    v-model="dateRange"
                    class="w-100"
                    :ranges="date_ranges()"
                    @update="updateValues"
                  >
                    <template
                      v-slot:input="picker"
                      style="min-width: 350px"
                    >
                      {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                      {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                    </template>
                  </date-range-picker>
                </div>
              </div>
              <div class="col-sm-3 ">
                <div>
                  <b-form-group 
                    id="status-1"
                    :label="trans.get('__JSON__.Status')"
                    label-for="status"
                  >
                    <b-form-select v-model="filters.payment_status" :options="[
                      {value: '', text: 'All'},
                      {value: 'completed', text: 'Completed'},
                      {value: 'failed', text: 'Failed'},
                      {value: 'pending', text: 'Pending'}
                    ]" @input="getPurchasedGiftVouchers">
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Search') }}</label>
                  <div class="search-box mr-2">
                    <div class="position-relative">
                      <input type="text" class="form-control" id="search" @input="searchData()" v-model="filters.search"
                        :placeholder="this.trans.get('__JSON__.Search')" />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list  table-responsive">
                <b-table :items="giftVouchers && giftVouchers || []" :fields="fields" :sort-by.sync="sortBy"
                  :no-local-sorting="true" :sort-desc.sync="sortDesc" @sort-changed="sortingChanged" :busy="loading"
                  show-empty @row-clicked="onRowClicked">
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
                    </div>
                  </template>
                  <template v-slot:cell(full_image)="data">
                    <img :src="data.item.gift_voucher.full_image" 
                    @error="replaceByDefault" alt class="rounded avatar-md" />
                  </template>
                  <template v-slot:cell(email)="data">
                    <strong>{{data.item.name}}</strong>
                    <small>{{data.value}}</small>
                  </template>
                  <template v-slot:cell(title)="data">
                    {{data.item.gift_voucher.title}}
                  </template>
                  <template v-slot:cell(user_name)="data">
                    {{data.item.customer.user_name +(data.item.customer.last_name?' '+data.item.customer.last_name:'')}}
                  </template>
                  <template v-slot:cell(redeemed_by)="data">
                    {{ data.item.redeemed_by?data.item.redeemed_by.user_name +(data.item.redeemed_by.last_name?' '+data.item.redeemed_by.last_name:'-'):'-'}}
                  </template>
                  <template v-slot:cell(formatted_gift_code)="data">
                    <code class="text-nowrap">{{ data.value }}</code>
                  </template>
                  <template v-slot:cell(amount)="data">
                     {{get_currency()}} {{data.value}}
                  </template>
                  <template v-slot:cell(wallet_amount)="data">
                     {{get_currency()}} {{data.value}}
                  </template>
                  <template v-slot:cell(online_amount)="data">
                     {{get_currency()}} {{data.value}}
                  </template>
                  <template v-slot:cell(created_at)="data">
                     {{moment(data.value).format('DD MMM YYYY, hh:mm A')}}
                  </template>
                  <template v-slot:cell(payment_status)="data">
                    <span class="badge badge-pill font-size-12" :class="{
                      'badge-soft-success': (data.value == 'completed'),
                      'badge-soft-warning':(data.value == 'pending'),
                      'badge-soft-danger':(data.value == 'failed')
                    }">{{data.value.toUpperCase()}}</span>
                  </template>
                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Data Found',{field:trans.get('__JSON__.Gift Vouchers') }) }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0" v-if="paginations">
                      <!-- pagination -->
                      <pagination :data="paginations" :limit="limit" @pagination-change-page="getPurchasedGiftVouchers">
                      </pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </Layout>
</template>