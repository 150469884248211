
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { required } from 'vuelidate/lib/validators'
import DropzonePopup from '../../components/widgets/dropzone-popup';
import { giftVoucherService } from '../../services';
import { error_message, success_message } from '../../Helper/helper';
export default {
  components: { Layout, PageHeader, DropzonePopup},
  data(){
    return {
      form: {
        title: '',
        description: '',
        image: ''
      },
      loading: false
    }
  },
  validations: {
    form:{
      title: {
        required
      },
      description: {
        required
      },
      image: {
        required
      }
    }
  },

  methods:{
    createGiftVoucher(){
      this.$v.$touch();
      if(!this.$v.$invalid){
        this.loading = true;
        giftVoucherService.addGiftVoucher(this.form)
          .then(response => {
            if(response.data.code == 200){
              success_message(response.data.message)
              this.$router.push({'name':'gift-voucher-list'})
              return success;
            }
            error_message(response.data.message)
            this.loading = false;
          })
      }
    }
  }
}
</script>
<template>  
  <Layout>
    <page-header title="Gift Voucher Add">
      <template #icon>
        <i class="bx bx-card"></i>
      </template>
      <div>
        <h4 class="mb-0 font-size-18">Add Gift Voucher</h4>
        <p class="mb-0"><router-link :to="{ name: 'gift-voucher-list'}">{{ trans.trans('__JSON__.Gift Vouchers') }}</router-link> > Add Gift Voucher</p>
      </div>
    </page-header>
    <div class="row">
      <div class="col-12">
         <form class="form" role="form" @submit.prevent="createGiftVoucher">
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Title"
              label-for="Title"
            >
              <b-form-input for="title" v-model="$v.form.title.$model"
                :class="{ 'is-invalid': $v.form.title.$error }"
                ></b-form-input>  
              <div v-if="$v.form.title.$error" class="invalid-feedback cuisine-img">
                <span
                  v-if="!$v.form.title.required"
                >{{ trans.get('__JSON__.Please enter field',{name: trans.get('__JSON__.Title')}) }}</span>
              </div>
            </b-form-group>
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Description"
              label-for="Description"
            >
              <b-form-textarea for="Description" v-model="form.description"
                ></b-form-textarea>
            </b-form-group>
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Image"
              label-for="Image"
            >
            <dropzone-popup
              v-model="form.image"
              type="file"
              id="Image"
              name="Image"
              acceptedFiles=".jpeg,.jpg,.png"
              :class="{ 'is-invalid': $v.form.image.$error }"
            />
            <div v-if="$v.form.image.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.form.image.required"
              >{{ trans.get('__JSON__.Please select image') }}</span>
            </div>
            </b-form-group>
            <button :disabled="loading" type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.trans('__JSON__.Submit') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
         </form>
      </div>
    </div>
  </Layout>
</template>
<style>

</style>