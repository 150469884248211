
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { required, requiredIf } from 'vuelidate/lib/validators'
import DropzonePopup from '../../components/widgets/dropzone-popup';
import { giftVoucherService } from '../../services';
import { error_message, success_message } from '../../Helper/helper';
import { routes } from '../../config/api_routes';
export default {
  components: { Layout, PageHeader, DropzonePopup},
  data(){
    return {
      giftVoucherMediaPath: '',
      form: {
        title: '',
        description: '',
        image: ''
      },
      loading: false,

      editableImage: '',
      icon_image_delete_url: routes.deleteGiftVoucherImageApi
    }
  },
  validations: {
    form:{
      title: {
        required
      },
      image: {
        required: requiredIf(function (){
          return (this.editableImage == '')
        })
      }
    },
  },
  mounted(){
    this.getGiftVoucher();
  },
  methods:{
    onIconImagedeleted(){
      this.editableImage = ''
    },
    getGiftVoucher(){
      giftVoucherService.getGiftVoucher(this.$route.params.voucher_id)
        .then(res => {
          if(res.data.code == 200){
            const { title, description, image, full_image } = res.data.result;
            this.form = {title, description, image: ''}
            this.giftVoucherMediaPath = full_image.replace('/'+image,'')
            this.editableImage = image;
          }
        });
    },

    updateGiftVoucher(){
      this.$v.$touch();
      if(!this.$v.$invalid){
        this.loading = true;
        const {title, description, image} = this.form;
        let form = {title, description, image}
        if(this.form.image == ''){
            form = {title, description}
        }
        giftVoucherService.updateGiftVoucher({...form, id: this.$route.params.voucher_id})
          .then(res => {
            if(res.data.code == 200){
              success_message(res.data.message)
              this.$router.push({'name':'gift-voucher-list'})
              return true;
            }
            error_message(res.data.message)
            this.loading = false;
          })
      }
    }
  }
}
</script>
<template>  
  <Layout>
    <page-header title="Gift Voucher Add">
      <template #icon>
        <i class="bx bx-card"></i>
      </template>
      <div>
        <h4 class="mb-0 font-size-18">Edit Gift Voucher</h4>
        <p class="mb-0"><router-link :to="{ name: 'gift-voucher-list'}">{{ trans.trans('__JSON__.Gift Vouchers') }}</router-link> > Add Gift Voucher</p>
      </div>
    </page-header>
    <div class="row">
      <div class="col-12">
         <form class="form" role="form" @submit.prevent="updateGiftVoucher">
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Title"
              label-for="Title"
            >
              <b-form-input for="title" v-model="$v.form.title.$model"
                :class="{ 'is-invalid': $v.form.title.$error }"
                ></b-form-input>
              <div v-if="$v.form.title.$error" class="invalid-feedback cuisine-img">
                <span
                  v-if="!$v.form.title.required"
                >{{ trans.get('__JSON__.Please enter field',{name: trans.get('__JSON__.Title')}) }}</span>
              </div>
            </b-form-group>
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Description"
              label-for="Description"
            >
              <b-form-textarea for="Description" v-model="form.description"
                ></b-form-textarea>
            </b-form-group>
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Image"
              label-for="Image"
            >
              <dropzone-popup
                v-model="form.image"
                id="icon_image"
                acceptedFiles=".jpeg,.jpg,.png"
                :editable-images="editableImage"
                :media-path="giftVoucherMediaPath"
                :delete-path="icon_image_delete_url"
                :custom-params="{id: $route.params.voucher_id}"
                @image-deleted="this.onIconImagedeleted"
                :class="{ 'is-invalid': $v.form.image.$error }"
              />
              <div v-if="$v.form.image.$error" class="invalid-feedback cuisine-img">
                <span
                  v-if="!$v.form.image.required"
                >{{ trans.get('__JSON__.Please select image') }}</span>
              </div>
            </b-form-group>
            <button :disabled="loading" type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.trans('__JSON__.Submit') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
         </form>
      </div>
    </div>
  </Layout>
</template>
<style>

</style>