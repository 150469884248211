<script>
import Layout from '../../layouts/main';
import PageHeader from '../../components/page-header';
import config from "../../config";
import BookingList from './list';
import { bookingsService } from '../../services';
import { get_decimal } from '../../Helper/helper';
/**
 * Products-booking component
 */
export default {
  components: { Layout, PageHeader, BookingList },
  data() {
    return {
      config:config,
        filters: null,
        bookings: null,
        decimal: get_decimal()
    }
  },
  methods:{
    exportBookings() {
    //   console.log('hi');
    // this.loading = true;
      bookingsService
        .getBookings({
          ...this.filters,
        })
        .then((response) => {
          // this.loading = false;
          this.bookings = response.data.result;
          this.downloadCSV()
        })
        .catch(function (error) {
          // this.loading = false;
          console.log(error);
        });
    },
    getSelectionPrices(items){
      return parseFloat(items.length > 0 ? items.reduce(function(accu, item){
        return accu + parseFloat(item.selection_price);
      },0): 0);
    },
    getPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_price || 0))).toFixed(this.decimal)
    },
    downloadCSV: function(){
      let bookings = '\ufeff' + 'SR,Order ID,Customer Name,Customer Number,Customer Email,Order Date,'+this.$auth.getRestaurantName()+' Name,'+this.$auth.getRestaurantName()+' Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Special Instruction,Item Name,Item Price,Item Quantity,Item Customization\n'
      this.bookings.forEach((el,ind) => {
        var line = (ind+1)+ ',' + el['id'] + ',' + el['customer']['customer_name'] + ',' 
          + el['customer']['mobile_number'] + ',' 
          + el['customer']['email'] + ',' + this.moment(el['created_at']).format('DD-MM-YYYY hh:mm A') + ',' 
          + (el['restaurant']?el['restaurant']['name']:'-') +',' 
          + (el['restaurant']?el['restaurant']['phone']:'-') +',' 
          + el['payment_method'] +',' 
          + el['order_status'] +',' 
          + (el['reddemed_coupon_order']?el['reddemed_coupon_order']['coupon']['coupon_code']:'--') +',' 
          + el['sub_total'] +',' 
          + el['delivery_fee'] +',' 
          + (el['reddemed_coupon_order']?parseFloat(el['reddemed_coupon_order']['original_price'] - el['reddemed_coupon_order']['discount_price']):'--') +',' 
          + (el['service_fee']?el['service_fee']:'--') +',' 
          + el['total_amount'] +','
          + (el['suggestion']?el['suggestion']:'--' )+',';
        var i = 0;
        do{
          line += (el['order_item'] && el['order_item'][i] && el['order_item'][i]['menu_item'] && el['order_item'][i]['menu_item']['item_name'].replaceAll(',','') || '') +',' 
                + (el['order_item'] && el['order_item'][i] && this.getPrice(el['order_item'][i]) || '0') +',' 
                + (el['order_item'] && el['order_item'][i] && el['order_item'][i]['quantity'] || '') +',' 
                + (el['order_item'] && el['order_item'][i] && el['order_item'][i]['menu_item_selections'].length > 0?el['order_item'][i]['menu_item_selections'].map((i) => i.selection_name).join('|'):'--')
                +''
                +'\n'
              
          i++;
          bookings += line  
          line = ',,,,,,,,,,,,,,,,,';
        } while(el['order_item'].length > i);
        // bookings += line
      })
      var blob = new Blob([ bookings ], { "type" : "csv/plain" });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'bookings-' + new Date().getTime() +'.csv'
      link.click()
    },
    updateFilters(filters){
      this.filters = filters;
    }
  }
}
</script>

<template>
 <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-copy-alt"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Bookings') }}</h4>
          </div>
          <div class="page-title-right">
            <!-- <b-spinner v-if="loading" class="m-2" variant="primary" role="status"></b-spinner> -->
            <button
              @click="exportBookings()" v-if="$auth.hasSubAdminPermission(config.permissions.booking_export)"
              class="btn btn-primary btn-rounded export-btn"
            >{{trans.get('__JSON__.Export To CSV')}}</button>
            <button
              @click="exportBookings()" v-if="$auth.hasSubAdminPermission(config.permissions.booking_export)"
              class="btn btn-primary btn-rounded m-export-btn"
            ><i class="bx bx-archive-in"></i></button>
          </div>
        </div>
      </div>
    </div>
    <BookingList @filterChange="updateFilters" />
</Layout>
</template>
