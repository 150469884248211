<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import config from "../../config";
import { vendorFinanceService } from '../../services';
import { get_decimal, toKebabCase } from '../../Helper/helper';

/**
 * Contacts-list component
 */ 
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      decimal:get_decimal(),
      config:config,
      vendorFinance: {}
    };
  },
  mounted(){
    this.getMonthFinanceData(this.$route.params.month);
  },
  methods:{
    getMonthFinanceData(month){
      vendorFinanceService.getVendorFinanceMonth(month)
        .then((res) => {this.vendorFinance = res.data.result });
    },
    downloadsCSV: function(){
      let vendorFinance = '\ufeff' + this.trans.trans('__JSON__.Vendor',{ vendor: this.$auth.getRestaurantName() })+','
        +this.trans.get('__JSON__.Item Price')+','
        +this.trans.get('__JSON__.Delivery Charge')+','
        +this.$auth.getTaxName()+','
        +this.trans.get('__JSON__.Coupon Discount')+','
        +this.trans.get('__JSON__.Total Amount')+','
        +this.trans.get('__JSON__.Admin Commission')+','
        +this.trans.trans('__JSON__.Vendor Earning',{ vendor: this.$auth.getRestaurantName() })+','
        +this.trans.get('__JSON__.Admin Earning')+'\n'
      this.vendorFinance.monthWiseFinance.map(el=> {
        var line = el['restaurant_name'] + ',' + el['item_total'] + ',' + el['delivery_fee'] + ',' + el['service_fee']+ ',' + el['discount'] + ',' + el['amount'] + ',' + el['admin_commision'] + ',' + el['chefEarning'] + ',' + el['adminEarning'] +'\n'
        vendorFinance +=line;
      })
      vendorFinance+= 'Total,' + this.getTotal('item_total')  + ',' + this.getTotal('delivery_fee') + ',' + this.getTotal('discount') + ',' + this.getTotal('amount') + ',' + this.getTotal('admin_commision') + ',' + this.getTotal('chefEarning') + ',' + this.getTotal('adminEarning') +'\n'
      vendorFinance+= ',' + ',' + ',' + ',' + ',' + this.trans.get('__JSON__.Total Paid by customer Σ(D)') + ',' + this.getTotal('withcard') +'('+this.trans.get('__JSON__.Online')+')' + ',' + this.getTotal('cod') +'('+this.trans.get('__JSON__.COD') +'),'+ (parseFloat(this.getTotal('cod')) + parseFloat(this.getTotal('withcard'))) + '('+this.trans.get('__JSON__.Total')+')' + '\n'
      vendorFinance+= ',' + ',' + ',' + ',' + ',' + ',' + ',' + this.trans.trans('__JSON__.Vendor Earning',{ vendor: this.$auth.getRestaurantName() }) +' Σ(F),'+ this.getTotal('chefEarning') + '\n'
      vendorFinance+= ',' + ',' + ',' + ',' + ',' + ',' + ',' + this.trans.get('__JSON__.Admin Earning') +'Σ(G),'+ this.getTotal('adminEarning') + '\n'

      var blob = new Blob([ vendorFinance ], { "type" : "csv/plain" });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = toKebabCase(this.$auth.getRestaurantName())+'-finance-detail.csv'
      link.click()
    },
    getTotal(key){
      return Object.keys(this.vendorFinance && this.vendorFinance.monthWiseFinance || {})
        .reduce((acc, index) => {
          return acc + parseFloat(this.vendorFinance.monthWiseFinance[index][key]?this.vendorFinance.monthWiseFinance[index][key].toString().replaceAll(',',''):0);
        },0).toFixed(2);
    }
  }
};
</script>

<template>
  <Layout>
   <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                   <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ this.$route.params.month }}</h4>
                     <p><router-link :to="{ name: 'vendor'}">{{ trans.trans('__JSON__.Vendor Finance',{ vendor: $auth.getRestaurantName() }) }}</router-link> > {{ this.$route.params.month }}</p>
                </div>
              </div>
              <div class="page-title-right">
                  <button @click="downloadsCSV" 
                  v-if="$auth.hasSubAdminPermission(config.permissions.vendor_finance_export)" 
                  class="btn btn-primary btn-rounded">
                    {{trans.get('__JSON__.Export To CSV')}}
                  </button>
              </div>
              </div>
          </div>
      </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive table-border">
              <table class="table table-centered table-nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.getRestaurantName() }) }}</th>
                    <th scope="col">{{ trans.get('__JSON__.Item Price') }}</th>
                    <th scope="col">{{ trans.get('__JSON__.Delivery Charge') }}</th>
                    <th scope="col">{{ $auth.getTaxName() }}</th>
                    <th scope="col">{{ trans.get('__JSON__.Coupon Discount') }}</th>
                    <th scope="col">{{ trans.get('__JSON__.Total Amount') }}</th>
                    <th scope="col">{{ trans.get('__JSON__.Admin Commission') }}</th>
                    <th scope="col">{{ trans.trans('__JSON__.Vendor Earning',{ vendor: $auth.getRestaurantName() }) }}</th>
                    <th scope="col">{{ trans.get('__JSON__.Admin Earning') }}</th>
                    <th scope="col">{{ trans.get('__JSON__.Action') }}</th>
                  </tr>
                </thead>
                <tbody class="bold-text">
                  <tr v-for="index in Object.keys(vendorFinance && vendorFinance.monthWiseFinance || [])" :key="index">
                    <td>{{ vendorFinance.monthWiseFinance[index].restaurant_name }}</td>
                    <td><span v-html="vendorFinance.currency"></span> {{ vendorFinance.monthWiseFinance[index].item_total.toFixed(decimal) }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ parseFloat(vendorFinance.monthWiseFinance[index].delivery_fee || "0.00").toFixed(decimal) }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ parseFloat(vendorFinance.monthWiseFinance[index].service_fee || "0.00").toFixed(decimal) }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ parseFloat(vendorFinance.monthWiseFinance[index].discount || "0.00").toFixed(decimal) }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ parseFloat(vendorFinance.monthWiseFinance[index].amount || "0.00").toFixed(decimal) }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ parseFloat(vendorFinance.monthWiseFinance[index].admin_commision || "0.00").toFixed(decimal) }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ parseFloat(vendorFinance.monthWiseFinance[index].chefEarning || "0.00").toFixed(decimal) }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ parseFloat(vendorFinance.monthWiseFinance[index].adminEarning || "0.00").toFixed(decimal) }}</td>
                    <td>
                        <router-link :to="{name:'restaurant-finance-list',params: {id : base64(vendorFinance.monthWiseFinance[index].restaurant_id), month: $route.params.month }}" class="btn btn-primary btn-sm btn-rounded">
                          {{trans.get('__JSON__.View')}}
                        </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td class="total-text"><h6>{{trans.get('__JSON__.Total')}}</h6></td>
                    <td><span v-html="vendorFinance.currency"></span>{{getTotal('item_total')}}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ getTotal('delivery_fee') }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ getTotal('service_fee') }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ getTotal('discount') }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ getTotal('amount') }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ getTotal('admin_commision') }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ getTotal('chefEarning') }}</td>
                    <td align="left"><span v-html="vendorFinance.currency"></span> {{ getTotal('adminEarning') }}</td>
                    <td></td>
                  </tr>
                   <tr>
                    <td colspan="5"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Paid by customer Σ(D)')}}</h6></td>
                    <td align="left" class="mb-0"><span v-html="vendorFinance.currency"></span> {{getTotal('withcard')}} ({{trans.get('__JSON__.Online')}})</td>
                    <td align="left" class="mb-0"><span v-html="vendorFinance.currency"></span> {{getTotal('cod')}}({{trans.get('__JSON__.COD')}})</td>
                    <td align="left" class="mb-0"><span v-html="vendorFinance.currency"></span> {{getTotal('wallet')}}({{trans.get('__JSON__.Wallet')}})</td>
                    <td align="left" class="mb-0"><span v-html="vendorFinance.currency"></span> {{(parseFloat(getTotal('cod')) + parseFloat(getTotal('withcard')) + parseFloat(getTotal('wallet'))).toFixed(decimal)}} ({{trans.get('__JSON__.Total')}})</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="8"><h6 class="text-right mb-0">{{trans.trans('__JSON__.Vendor Earning',{ vendor: $auth.getRestaurantName() })}} Σ(F)</h6></td>
                    <td class="mb-0 pl-15"><span v-html="vendorFinance.currency"></span> {{getTotal('chefEarning')}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="8"><h6 class="text-right mb-0">{{trans.get('__JSON__.Admin Earning')}} Σ(G)</h6></td>
                    <td class="mb-0"><span v-html="vendorFinance.currency"></span> {{ getTotal('adminEarning') }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>