<template>
    <!-- View Detail Main Start -->
    <b-modal v-model="showModal" size="lg" scrollable title-class="font-18" hide-footer @hidden="onModalHide">
        <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <h5>
                {{ trans.get('__JSON__.Chowman Pay') + 'ID #' + (order && order.id || '000') }}
                <span class="badge badge-pill badge-soft-warning font-size-12"
                    v-if="order && order.payment_status == 'Pending'">
                    {{ trans.get('__JSON__.' + order && order.payment_status) }}
                </span>

                <span class="badge badge-pill badge-soft-danger font-size-12"
                    v-if="order && order.payment_status == 'Failed'">
                    {{ trans.get('__JSON__.' + order && order.payment_status) }}
                </span>

                <span class="badge badge-pill badge-soft-success font-size-12"
                    v-if="order && order.payment_status == 'Completed'">
                    {{ trans.get('__JSON__.' + order && order.payment_status) }}
                </span>


            </h5>
            <div class="d-flex">
                <h5 class="mr-3">
                    <span class="badge badge-pill badge-soft-dark font-size-15">
                        {{ order && order.unique_code ? order.unique_code : '-' }}
                    </span>
                </h5>
                <button @click="close()" class="close"> <i class="fas fa-times"></i> </button>
            </div>
        </template>
        <div class="text-center text-danger my-2" v-if="orderLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
        </div>
        <div v-if="!orderLoading">

            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Customer Name') }} </h6>
                        <p>
                            <b-link
                                :to="{ name: 'customer-detail', params: { user_id: btoa(order && order.customer.user_id ? order.customer.user_id : 0) } }">
                                {{ order && order.customer.user_name }} {{ order && order.customer.last_name }}</b-link>
                        </p>
                    </div>
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Restaurant Name') }} </h6>
                        <p>
                            <b-link
                                :to="{ name: 'vendor-detail', params: { restaurant_id: btoa(order && order.restaurant.restaurant_id ? order.restaurant.restaurant_id : 0) } }">
                                {{ order && order.restaurant.name }}</b-link>
                        </p>
                    </div>
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Created At') }} </h6>
                        <p>{{ order && order.created_attime }}</p>
                    </div>
                </div>
            </div>
            <hr>

            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Payment Id') }} </h6>
                        <p>{{ order && order.payment_id ? order.payment_id : '-' }}</p>
                    </div>

                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Total Amount') }} </h6>
                        <p><span v-html="currency"></span> {{ order && order.total_amount ? order.total_amount : 0 }}
                        </p>
                    </div>
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Online Paid Amount') }} </h6>
                        <p><span v-html="currency"></span> {{ order && order.online_paid_amount ?
                                order.online_paid_amount : 0
                        }}
                        <span class="badge badge-pill badge-soft-success font-size-12">
                            Online
                        </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Wallet Pay Id') }} </h6>
                        <p>{{ order && order.wallet_pay_id ? order.wallet_pay_id : '-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Wallet Paid Amount') }} </h6>
                        <p><span v-html="currency"></span> 
                        {{ order && order.wallet_price ? order.wallet_price : 0 }}
                        <span class="badge badge-pill badge-soft-warning font-size-12">
                            Wallet
                        </span>
                        </p>
                    </div>
                </div>
            </div>

            <hr>

            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Cashback Pay Id') }} </h6>
                        <p>{{ order && order.cashback_pay_id ? order.cashback_pay_id : '-' }}</p>
                    </div>

                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Cashback Price') }} </h6>
                        <p><span v-html="currency"></span> {{ order && order.cashback_price ? order.cashback_price : 0
                        }}
                        <span class="badge badge-pill badge-soft-warning font-size-12">
                            Cashback
                        </span>
                        </p>
                    </div>
                </div>

            </div>

            <hr>
            
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Promocode') }} </h6>
                        <p>{{ order && order.promocode ? order.promocode.coupon_code : '-' }}</p>
                    </div>

                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Promocode Discount') }} </h6>
                        <p><span v-html="currency"></span> {{ order && order.promocode_discount ?
                                order.promocode_discount : 0
                        }}</p>
                    </div>

                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Default Cashback') }} </h6>
                        <p><span v-html="currency"></span> {{ order && order.default_cashback ? order.default_cashback :
                                0
                        }}</p>
                    </div>
                </div>
            </div>
            <hr>
            
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <h6>{{ trans.get('__JSON__.Device Type') }} </h6>
                        <p>{{ order.device_type ? order.device_type : '-' }}</p>
                    </div>
                </div>
            </div>

        </div>
    </b-modal>
</template>
  
<script>

import { ChowmanPayService } from "../../services";
import config from "../../config";
import { get_currency } from '../../Helper/helper';

export default {
    props: ['order-id'],
    data() {
        return {
            config: config,
            orderLoading: false,
            showModal: false,
            order: null,
            currency: get_currency(),
        }
    },
    mounted() {
        this.viewDetails()
    },
    methods: {
        viewDetails() {
            this.showModal = true;
            this.orderLoading = true;
            ChowmanPayService.getPay(this.orderId)
                .then(res => {
                    this.order = res.data.result;
                    this.orderLoading = false;
                })
            console.log('order', this.order);
        },
        onModalHide() {
            this.showModal = false;
            this.$emit('hidden');
        },
        btoa(id) {
            return btoa(id);
        }
    }
}
</script>
  
<style>

</style>