import config from '../config'

export default {
    config: config,
}

const base = config.base_folder;

export const vendormenuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        link: base+"vendor/dashboard",
       
    },
    {
        id: 2,
        label: "Orders",
        icon: "bx bx-receipt",
        link: base+"vendor/orders/list",
        badge: {
            'variant'   :  'danger',
            "text"      :   ['order']
        },
    },  
    {
        id: 3,
        label: "Catalog",
        icon: "bx bx-book",
        link: base+"vendor/vendors/catalog"
    },
    {
        id: 5,
        label: "Third Party Order",
        icon: "bx bx-receipt",
        link: base+"vendor/thirdpartyorder/list",
        badge: {
            'variant'   :  'danger',
            "text"      :   ['zomatoOrderCount']
        },
    }, 
    {
        id: 4,
        label: "Booking",
        icon: "bx bx-time",
        link: base+"vendor/bookings/list",
        badge: {
            'variant'   :  'danger',
            "text"      :   ['booking']
        },
    },
    
    {
        id: 14,
        label: "Chawman Pay",
        icon: "bx bx-money",
        link: base+"vendor/chowman-pay"
    },
    // {
    //     id: 5,
    //     label: "Category",
    //     icon: "bx bx-dish",
    //     link: base+"vendor/vendor-category"
    // },
    // {
    //     id: 6,
    //     label: "Selling Time slot",
    //     icon: "bx bx-time",
    //     link: base+"vendor/selling-timeslot"
    // },
    // {
    //     id: 7,
    //     label: "Promocode",
    //     icon: "bx bx-purchase-tag-alt",
    //     link: base+"vendor/coupon/list"
    // },
    {
        id: 8,
        label: "Discount",
        icon: "bx-purchase-tag",
        link: base+"vendor/vendor-discount",
    },
    // {
    //     id: 9,
    //     label: "My Profile",
    //     icon: "bx bx-user",
    //     link: base+"vendor/vendor-profile",
    // },
    // {
    //     id: 10,
    //     label: "Finance",
    //     icon: "bx bx-rupee",
    //     link: base+"vendor/finance",
    // },
    {
        id: 11,
        label: "Reports",
        icon: "bx bx-notepad",
        link: base+"vendor/reports",
    },
    {
        id: 12,
        label: "Reviews",
        icon: "bx bx-star",
        link: base+"vendor/vendor-rating",
    },
    {
        id: 13,
        label: "Driver",
        link: base+"vendor/driver/dashboard",
        icon: "mdi mdi-bike-fast",
    },
    
    {
        id: 16,
        label: "Delivery Zones",
        link: base+"vendor/delivery_zone",
        icon: "mdi mdi-map",
    }

];
