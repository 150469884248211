<script>

import config from "../../config";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import draggable from "vuedraggable";
import DeliveryZoneMap from "./delivery-zone-map";
import DeliveryZoneRestaurant from "./delivery_zone_restaurant_list";
import { deliveryZoneService } from "../../services";
import Zonelist from "./zone-list";
import pagination from "laravel-vue-pagination";
import { required} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
import { error_message, success_message} from "../../Helper/helper";
/**
 * Products-order component
 */
export default {
  components: { Layout, PageHeader, DeliveryZoneMap, Zonelist, draggable, DeliveryZoneRestaurant,pagination, Multiselect},
  data() {
    return {
      zones: [],
      area_id: '',
      restaurant: [],
      sortBy: "restaurant_id",
      sortDesc: true,
      pages: [],
      paginations: {},
      limit: 1,
      search: "",
      config: config,
      loading: true,
      addModal: false,
      isLoading: false,
      vendors: [],
      cust_type_search: [],
      lat : parseFloat(this.$auth.setting.system_latitude),
      long : parseFloat(this.$auth.setting.system_longitude),
      franchisee_area:[],
      selectVendor: {
        cust_type_search: "",
      },
      markers:[],
      map:null,
      polygonArray:null,
      polygonArrays:[],
      polyArray:null,
      polyline: null,
      polylines: [],
      drawingManager: null,
      fields: [
        {
          key: "icon_image",
          label: this.trans.get("__JSON__.Logo"),
          sortable: false,
        },
        {
          key: "name",
          label: this.trans.get("__JSON__.Name & Location"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  validations: {
    selectVendor:{
      cust_type_search: {
        required,
      }
    }
  },
  mounted() {
    this.getDeliveryInfo();
     this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: this.lat, lng: this.long },
        zoom: 7,
        //mapTypeId: "terrain",
      });
  },
  methods: {
    getDeliveryInfo() {
      this.loading = true;
      deliveryZoneService.getDeliveryInfo({
        user_id: this.$route.params.user_id,
      })
      .then((response) => {
        this.zones = response.data.result;
        this.franchisee_area = response.data.result.franchisee_area;
        if(this.franchisee_area.length > 0 ){
          this.polygonArray = JSON.parse(response.data.result.franchisee_area[0].latlong);
          this.lat = parseFloat(this.polygonArray[0]['lat']);
          this.long = parseFloat(this.polygonArray[0]['lng']);
          this.area_id = this.franchisee_area[0].areaid;
        }else{
          this.polygonArray = [];
          this.lat = parseFloat(this.$auth.setting.system_latitude);
          this.long = parseFloat(this.$auth.setting.system_longitude);
          this.area_id = '';
        }
        this.getZoneRestaurant();
        
        
      });
    },
    changeMenu(id){
      if(this.area_id !== id){
        this.area_id = id;
        this.getZoneRestaurant();
      }
    },
    getZoneRestaurant(page=1) {
        // this.loading = true;
        deliveryZoneService.getZoneRestaurant({ 
            area_id: this.area_id !== 0 ? this.area_id : this.zones.franchisee_area.map(a => a.areaid),
            page: page && page > 0 ? page : 1,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
            type:this.area_id !== 0 ? "single" : "multi"
        }).then((response) => {
          this.paginations = response.data.result.restaurant;
          this.restaurant = response.data.result.restaurant.data;
          this.setMarkers(response.data.result.latlongs);
          if(response.data.result.zone != null){
            if(this.area_id !== 0){
              this.polygonArray = JSON.parse(response.data.result.zone.latlong);
              this.lat = parseFloat(this.polygonArray[0]['lat']);
              this.long = parseFloat(this.polygonArray[0]['lng']);
            }else{
              response.data.result.zone.forEach((zone,index) => {
                this.polygonArrays[index] = JSON.parse(zone.latlong);
                this.lat = parseFloat(this.polygonArrays[index][0]['lat']);
                this.long = parseFloat(this.polygonArrays[index][0]['lng']);
              })
            }
          }
          this.initMap();
            this.loading = false;
          
        });
    },
    setMarkers(latlongs){
      this.markers.forEach(marker => {
        marker.setMap(null);
      });
      this.markers = [];
      latlongs.forEach((marker,i) => {
        this.markers.push(new google.maps.Marker({
            position: new google.maps.LatLng(marker.latitude,marker.longitude),
            title:"Restaurant"
        }));
        this.markers[i].setMap(this.map);
      });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getZoneRestaurant();
    },
    deleteFranchiseRestById(restaurant_id,area_id){
      if (confirm("Are you sure you want to delete?")) {
        deliveryZoneService.deleteFranchiseRestById({
          restaurant_id : restaurant_id, area_id:area_id
        }).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getDeliveryInfo();
            this.getZoneRestaurant();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    deleteRestAreaId(area_id){
      console.log(area_id);
      if (confirm("Are you sure you want to delete?")) {
        deliveryZoneService.deleteRestAreaId({ area_id:area_id
        }).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getDeliveryInfo();
            this.getZoneRestaurant();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    addVendor(){
      this.$v.$touch();
        if (this.$v.selectVendor.$invalid) {
            return;
        } else {
            const fd = new FormData();
            fd.append("area_id", this.area_id);
            fd.append("frenchiese_id", this.$route.params.user_id);
            this.selectVendor.cust_type_search
            .map((item) => item.restaurant_id)
            .map((item, index) => {
              fd.append("cust_type_search[" + index + "]", item);
            });
            deliveryZoneService.addFranchiseRest(fd)
            .then(response => {
                if (response.data.code === 200) {
                    this.addModal = false;
                    this.getDeliveryInfo();
                    this.getZoneRestaurant();
                    success_message(response.data.message);
                } else {
                    error_message(response.data.message);
                }
                this.selectVendor.cust_type_search = [];
                this.$v.$reset();
            });
        }
    },
    asyncFind(searchtext) {
        this.isLoading = true;
        deliveryZoneService.getSearchRest({searchtext: searchtext})
          .then(response => {
          this.vendors = response.data.result; 
          this.isLoading = false;
        });
    },
    clearAll() {
      this.selectVendor = [];
    },
    initMap(){
      this.center = { lat: this.lat, lng: this.long };
      this.polyline && this.polyline.setMap(null);
      this.polylines && this.polylines.forEach(p => p.setMap(null));
      var bounds = new google.maps.LatLngBounds();
      if(this.area_id !== 0){
        this.polyline = new google.maps.Polyline({
          path: this.polygonArray,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        this.polygonArray.forEach(p =>{
          bounds.extend(new google.maps.LatLng(p.lat,p.lng))
        })
        // this.map.setCenter(this.center);
        this.map.fitBounds(bounds);
        this.polyline.setMap(this.map);
      }else{
        this.polygonArrays.forEach((pa,ind) => {
          this.polylines[ind] = new google.maps.Polyline({
            path: pa,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          this.polylines[ind].setMap(this.map);
          pa.forEach(p =>{
            bounds.extend(new google.maps.LatLng(p.lat,p.lng))
          })
        })
        this.map.fitBounds(bounds);
        this.map.setCenter(this.center);
      }
      // this.drawingManager && this.drawingManager.setMap(null);
      // this.drawingManager = new google.maps.drawing.DrawingManager({
      //   drawingMode: google.maps.drawing.OverlayType.MARKER,
      //   drawingControl: true,
      //   drawingControlOptions: {
      //     position: google.maps.ControlPosition.TOP_CENTER,
      //     drawingModes: [
      //       google.maps.drawing.OverlayType.POLYGON,
      //       google.maps.drawing.OverlayType.POLYLINE,
      //     ],
      //   },
      
      //   polygonOptions: {
      //     fillColor: '#BCDCF9',
      //     fillOpacity: 0.5,
      //     strokeWeight: 2,
      //     strokeColor:'#57ACF9',
      //     clickable: false,
      //     editable: false,
      //     zIndex: 1
      //   } 
      // });
      // this.drawingManager.setMap(this.map);
      
      
      
    },

    statusChange(checked, areaid){
      console.log({checked, areaid});
      deliveryZoneService.areaStatusChange({ status: checked, areaid })
        .then(response => {
          console.log(response);
        })
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-map-alt"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Delivery Zone') }}</h4>
              <p>
                <router-link :to="{ name: 'list'}">{{ trans.get('__JSON__.Delivery Zones') }}</router-link> > {{this.zones.last_name}}
              </p>
            </div>
          </div>
            <div class="page-title-right">
              <button class="btn btn-success btn-rounded" v-if="!$auth.partner_login" @click="addModal = true">
                <i class="mdi mdi-plus mr-1"></i> {{ trans.trans('__JSON__.Add Vendor',{ vendor: $auth.setting.restaurant_name }) }}
              </button>
              <router-link v-if="!$auth.partner_login" :to="{name: 'add-area',params: { frenchiese_id: base64(zones.user_id)}}" class="btn btn-success btn-rounded">
                <i class="mdi mdi-plus mr-1"></i>
                {{ trans.get('__JSON__.Add Area') }}
              </router-link>
            </div>
        </div>
      </div>
    </div>
    <div class="drag-container">
      <div class="row drag-list menu-left-main">
        <div class="col-lg-4 drag-column menu-box-main">
          <div class="card">
            <div class="card-body menu-body">
              <div class="menu-box">
                <!-- dropdown -->
                <div class="menu-add">
                  <h5>Areas</h5>
                </div>
                <div v-if="franchisee_area.length == 0 && !loading">
                  <p class="text-center">{{ trans.get('__JSON__.No Area Found') }}</p>
                </div>
                <div class="text-center text-danger my-2" v-else-if="loading">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                </div>
                <div v-if="franchisee_area.length > 0 && !loading">
                  <draggable class="list-group menu-task" group="menus">
                     <div
                        :key="0"
                        v-on:click="changeMenu(0)"
                        class="card task-box"
                        :class="{ 'active': area_id === 0 }"
                      >
                      <div class="card-body" >
                        <div>
                          <h5 class="font-size-15 title">All ({{zones && zones.franchisee_area.reduce((ac,ar) => ar.restaurant.length + ac,0)}})</h5>
                        </div>
                      </div>
                    </div>
                    <template v-for="area in zones.franchisee_area">
                      <div
                        :key="area.areaid"
                        v-on:click="changeMenu(area.areaid)"
                        class="card task-box"
                        :class="{ 'active': area_id === area.areaid }"
                      >
                        <div class="card-body" >
                          <b-dropdown right variant="white" class="float-right" toggle-class="p-0" v-if="!$auth.partner_login">
                            <template slot="button-content">
                              <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                            </template>
                            <b-dropdown-item :to="{name: 'edit-area',params: { area_id: base64(area.areaid)}}">
                                <i class="fas fa-pencil-alt text-success mr-1"></i>
                                {{ trans.get('__JSON__.Edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteRestAreaId(base64(area.areaid))">
                              <i class="fas fa-trash-alt text-danger mr-1"></i> Delete
                            </b-dropdown-item>
                          </b-dropdown>

                          <div>
                            <h5 class="font-size-15 title">{{area.area}}({{area.restaurant.length}})
                              <b-form-checkbox 
                                :value="1" :unchecked-value="0" :checked="area.status" switch class="mb-1" @input="statusChange($event, area.areaid)">
                              </b-form-checkbox>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </template>
                  </draggable>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->

        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <b-tabs nav-class="nav-tabs-custom order-tabs" content-class="text-muted">
                    <b-tab active>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Map') }}</span>
                      </template>
                      <div class="col-sm-12 mt-3">
                        <div id="map" style="height: 500px"> </div>
                      </div>
                    </b-tab>
                    <b-tab lazy>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.List') }}</span>
                      </template>
                      <div class="row">
                        <div class="col-12 list-table-main">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-12 table-main-list cuisine-table table-responsive">
                                  <b-table
                                    :items="restaurant"
                                    :fields="fields"
                                    :sort-by.sync="sortBy"
                                    :no-local-sorting="true"
                                    @sort-changed="sortingChanged"
                                    :busy="loading"
                                    show-empty
                                  >
                                    <template #table-busy>
                                      <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                                      </div>
                                    </template>

                                    <template v-slot:cell(icon_image)="data">
                                      <div v-if="data.item.icon_image">
                                        <img
                                          alt
                                          class="vendor-img avatar-xs"
                                          :src="(config.restauranticon+data.item.icon_image)"
                                          @error="$event.target.src=config.basepath+'no-img.png'"
                                        />
                                      </div>
                                      <div v-else>
                                        <img class="vendor-img avatar-xs" :src="config.basepath+'no-img.png'" alt />
                                      </div>
                                    </template>

                                    <template v-slot:cell(name)="data">
                                      <h5 class="font-size-14 mb-1"> <div>{{data.item.name}}</div></h5>
                                      <div>{{data.item.location_address}}</div>
                                    </template>

                                    <template v-slot:cell(action)="data">
                                      <div class="vendor-btn">
                                        <button
                                          type="submit"
                                          v-if="!$auth.partner_login"
                                          class="btn btn-danger btn-sm btn-rounded ml-1"
                                          @click="deleteFranchiseRestById(base64(data.item.restaurant_id),base64(data.item.area_id))"
                                        >{{ trans.get('__JSON__.Delete') }}</button>
                                      </div>
                                    </template>

                                    <template #empty>
                                      <p class="text-center">{{ trans.get('__JSON__.No Vendor Found') }}</p>
                                    </template>
                                  </b-table>
                                </div>
                                <div class="row col-sm-5 offset-sm-7">
                                  <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                      <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <pagination
                                          :data="paginations"
                                          :limit="limit"
                                          @pagination-change-page="getZoneRestaurant"
                                        ></pagination>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end col-->
      </div>
    </div>
    <!-- Add Vendor Modal Start -->
    <b-modal
        id="modal-scoped"
        v-model="addModal"
        :title="trans.trans('__JSON__.Add Vendor',{ vendor: $auth.setting.restaurant_name })"
        centered
        >
        <b-form @submit.prevent="addVendor">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="ajax">{{trans.get('__JSON__.Select Vendor Name',{ vendor: $auth.setting.restaurant_name })}}</label>
                      <multiselect
                        v-model="$v.selectVendor.cust_type_search.$model"
                        id="ajax"
                        label="name"
                        track-by="name"
                        :placeholder="trans.get('__JSON__.Search...')"
                        open-direction="bottom"
                        :options="vendors"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoading"
                        :internal-search="true"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :limit="5"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                        @search-change="asyncFind"
                        :class="{ 'is-invalid': $v.selectVendor.cust_type_search.$error }"
                      >
                    <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                        <span>{{ option.name }}</span>
                        <span class="custom__remove" @click="remove(option)">❌</span>
                    </span>
                    </template>
                    <template slot="clear" slot-scope="props">
                        <div
                            class="multiselect__clear"
                            v-if="cust_type_search.length"
                            @mousedown.prevent.stop="clearAll(props.search)"
                        ></div>
                    </template>
                    <span slot="noResult">{{trans.get('__JSON__.Oops! No elements found. Consider changing the search query')}}</span>
                    </multiselect>
                    <div v-if=" $v.selectVendor.cust_type_search.$error" class="invalid-feedback">
                        <span
                        v-if="!$v.selectVendor.cust_type_search.required"
                        >{{ trans.trans('__JSON__.Please select at least one vendor name',{ vendor: $auth.setting.restaurant_name }) }}.</span>
                    </div>
                </div> 
            </div>
        </b-form>
        <template slot="modal-footer">
        <div class="w-100">
            <b-button
            variant="primary"
            class="float-right"
            @click="addVendor"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="addModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
        </div>
        </template>
    </b-modal>
  </Layout>
</template>
