const overlappingBarChart = {
    data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
            [5, 4, 3, 6, 5, 5, 3, 4, 6, 5, 6, 3],
            [3, 2, 5, 2, 4, 6, 4, 6, 3, 4, 2, 4],
            [4, 5, 2, 2, 2, 3, 2, 5, 2, 6, 4, 5]
        ]
    },
    options: {
        seriesBarDistance: 10,
        height: 300,
        responsiveOptions: [
            ['screen and (max-width: 640px)', {
                seriesBarDistance: 5,
                axisX: {
                    labelInterpolationFnc: (value) => {
                        return value[0];
                    }
                }
            }]
        ]
    },
};

export {
   overlappingBarChart
};
