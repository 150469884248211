<script>
import config from "../../config";
import { deliveryZoneService, userService} from '../../services';
import { set_axios_defaults, error_message, success_message, get_user} from '../../Helper/helper';

import {
  required,
  email,
  numeric,

} from "vuelidate/lib/validators";

/**
 * Add-product component
 */
export default {
  components: { },
  data() {
    return {
      config:config,
      countries: [],
      states: [],
      cities: [],
      state:'',
      city:'',
      country:'',
      country_count:'',
      state_count:'',
      city_count:'',
      disable:false,
      users: {
        user_name: "",
        last_name: "",
        mobile_number: "",
        email: "",
        country: "",
        state: "",
        city: "",
        status: ""
      },
      typesubmit: false
    };
  },
  validations: {
    users: {
      user_name: {
        required
      },
      last_name: {
        required
      },
      mobile_number: {
        required,
        numeric
      },
      email: {
        required,
        email
      },
      country: {
        required
      },
      city: {
        required
      },
      state: {
        required
      }
    }
  },
  mounted(){
    this.getDeliveryInfo();
    this.getVendorCountry();
    this.getAllState();
    this.getAllCity();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  methods: {
    userCheckEmail(e) {
      userService.userCheckEmail({
        email:this.users.email,
        id:this.users.user_id,
        table:'User',
        column:'user_id',
        vendor_id:this.vendor_id,
        user_role:'3'
      })
      .then(response => {
        if (response.data.code === 101) {
          this.disable = true;
          error_message(response.data.message);
        }else{
          this.disable = false;
        }
      });
    },
    getVendorCountry(){
      deliveryZoneService.getVendorCountry()
      .then(response => { 
        this.country_count = response.data.result.length;
        this.countries = response.data.result;
        if(this.country_count == 1){
          this.users.country = this.countries[0].id;
          this.getVendorState();
        }
      });
    },
    getVendorState(country){
      deliveryZoneService.getVendorState({
        country_id: this.users.country
      })
      .then(response => {
        this.state_count = response.data.result.length;
        this.states = response.data.result;
        if(this.state_count == 1){
          this.users.state = this.states[0].id;
          this.getVendorCity();
        }else{
          if(this.users.country ==''){
            this.getAllState();
            this.getAllCity();
          }
          if(this.country != this.users.country){
            this.users.city = "";
            this.getVendorCity();
          }
          if(this.country == this.users.country){
            this.users.state = this.state;
            this.users.city = this.city;
          }
        }
      });
    },
    getVendorCity(state){
      deliveryZoneService.getVendorCity({
        state_id: this.users.state
      })
      .then(response => {
        this.city_count = response.data.result.length;
        this.cities = response.data.result; 
        if(this.city_count == 1){
          this.users.city = this.cities[0].cityid;
        }else{
          if(this.users.state ==''){
            this.users.city = '';
            this.getAllCity();
          }
        }
      });
    },
    getDeliveryInfo(){
      deliveryZoneService.getDeliveryInfo({
        user_id: this.$route.params.user_id
      })
      .then(response => {
        const{user_id, user_name, last_name, mobile_number, email, country, state, city, status } = response.data.result;
        this.users = {user_id, user_name, last_name, mobile_number, email, country, state, city, status};
        this.country = response.data.result.country;
        this.state = response.data.result.state;
        this.city = response.data.result.city;
        this.getVendorState();
        this.getVendorCity();
      });
    },
    getAllState() {
      deliveryZoneService.getAllState()
        .then((response) => {
        this.states = response.data.result;
        this.state_count = response.data.result.length;
      });
    },
    getAllCity() {
      deliveryZoneService.getAllCity().then((response) => {
        this.cities = response.data.result;
        this.city_count = response.data.result.length;
      });
    },
    typeForm(e) {
      this.$v.users.$touch();
      if (this.$v.users.$invalid) {
        return;
      }
      else{
        deliveryZoneService.editDeliveryZone(this.users)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "delivery-zone-edit" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  }
}
</script>

<template>
  <b-form action="#" @submit.prevent="typeForm">
    <!-- Delivery Zone Info Start -->
      <div class="card">
        <div class="card-body">
          <p class="card-title-desc"></p>
          <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Delivery Zone Name') }}</label>
                <input id="last_name" v-model="$v.users.last_name.$model" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Delivery Zone Name')" name="user_name" :class="{ 'is-invalid':$v.users.user_name.$error }" />
                  <div v-if=" $v.users.last_name.$error" class="invalid-feedback">
                    <span v-if="!$v.users.last_name.required">{{ trans.get('__JSON__.Please enter delivery zone name') }}</span>
                  </div>
              </div>    
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                <label class="control-label">Admin Name</label>
                <input id="user_name" v-model="$v.users.user_name.$model" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Admin Name')" name="last_name" :class="{ 'is-invalid':$v.users.last_name.$error }" />
                  <div v-if=" $v.users.user_name.$error" class="invalid-feedback">
                    <span v-if="!$v.users.user_name.required">{{ trans.get('__JSON__.Please enter admin name') }}</span>
                  </div>
              </div>    
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                <label>Contact Number</label>
                  <input maxlength="12" minlength="10" id="mobile_number" v-model="$v.users.mobile_number.$model" type="number" name="mobile_number" class="form-control" :placeholder="trans.get('__JSON__.Enter Contact Number')" :class="{ 'is-invalid':$v.users.mobile_number.$error }" />
                  <div v-if=" $v.users.mobile_number.$error" class="invalid-feedback">
                      <span v-if="!$v.users.mobile_number.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                      <span v-if="!$v.users.mobile_number.numeric">This value should be a valid number.</span>
                  </div>
              </div>    
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label>E-Mail</label>
                    <input id="email" v-model="$v.users.email.$model" type="email" name="email" class="form-control" @blur="userCheckEmail" :placeholder="trans.get('__JSON__.Enter a valid e-mail')" :class="{ 'is-invalid':$v.users.email.$error }" />
                    <div v-if=" $v.users.email.$error" class="invalid-feedback">
                        <span v-if="!$v.users.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                        <span v-if="!$v.users.email.email">This value should be a valid email.</span>
                    </div>
                </div>
            </div>
              
            <div v-if="country_count >1" class="col-sm-6">
              <div class="form-group">
              <label>Country</label>
                  <select class="custom-select" v-model="$v.users.country.$model" @change="getVendorState" id="country" name="country" :class="{ 'is-invalid':$v.users.country.$error }"> 
                     <option value="">{{trans.get('__JSON__.Select Country')}}</option>
                    <option v-for="con in countries" :value="con.id" :key="con.id">{{con.country_name}}</option>
                  </select>
                  <div v-if=" $v.users.country.$error" class="invalid-feedback">
                    <span v-if="!$v.users.country.required">{{ trans.get('__JSON__.Please select country') }}</span>
                  </div>
              </div>
            </div>

            <div v-if="country_count >=1 && state_count > 1" class="col-sm-6">
              <div class="form-group">
              <label>State</label>
                <select class="custom-select" v-model="$v.users.state.$model" @change="getVendorCity" id="state" name="state" :class="{ 'is-invalid':$v.users.state.$error }">
                    <option value="">{{trans.get('__JSON__.Select State')}}</option>
                    <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                </select>
                <div v-if=" $v.users.state.$error" class="invalid-feedback">
                  <span v-if="!$v.users.state.required">{{ trans.get('__JSON__.Please select state') }}</span>
                </div>
              </div>
            </div>

            <div v-if="state_count >= 1 && city_count > 1" class="col-sm-6">
              <div class="form-group">
              <label>City</label>
                  <select class="custom-select" v-model="$v.users.city.$model" id="city" name="city" :class="{ 'is-invalid':$v.users.city.$error }">
                    <option value="">{{trans.get('__JSON__.Select City')}}</option>
                    <option v-for="cit in cities" :value="cit.cityid" :key="cit.cityid">{{cit.cityname}}</option>
                  </select>
                  <div v-if=" $v.users.city.$error" class="invalid-feedback">
                    <span v-if="!$v.users.city.required">{{ trans.get('__JSON__.Please select city') }}</span>
                  </div>
              </div>
            </div>  

            <div class="col-sm-6 vendor-status">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Status')"
                    label-for="status"
                  >
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        name="status"
                        v-model="users.status"
                        class="switch-on"
                        true-value="0"
                        false-value="1"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                      </div>
                    </label>
                  </b-form-group>
               </div> 
          </div> 
          <div class="row">
              <div class="col-sm-6">
                <button type="submit" class="btn btn-primary mr-1 mt-3" :disabled="disable">{{ trans.get('__JSON__.Update Delivery Zone') }}</button>
                <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3" >{{ trans.get('__JSON__.Cancel') }}</b-button>
              </div>
          </div>
        </div>
      </div>
    <!-- Delivery Zone Info End -->
  </b-form>
</template>
