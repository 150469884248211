<template>
    <b-modal
        id="modal-sm"
        size="lg"
        title-class="font-18"
        @hidden="resetModal"
        @ok="addNote"
        :ok-title="trans.get('__JSON__.Add Customer Note')"
        ok-only
        ref="note-modal"
        lazy
        >
        <template #modal-header={close}>
            <div class="d-flex justify-content-between w-100">
                <h4 class="mb-0">{{trans.get('__JSON__.Add Note')}}</h4>
                <h4 class="mb-0">#{{orderId}}</h4>
            </div>
            <button type="button" aria-label="Close" class="close" @click="close()">&times;</button>
        </template>

        <div class="row">
            <div class="col-12">
                <b-form role="form">
                    <b-form-group label="Note">
                         <b-form-textarea
                            id="textarea"
                            v-model="note"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                            ></b-form-textarea>
                    </b-form-group>
                </b-form>
            </div>
        </div>
    </b-modal>
</template>
<script>
import config from '../../../config';
import { orderService } from '../../../services';

export default {
    props :['order-id'],
    data(){
        return {
            config: config,
            note: '',
        }
    },
    mounted(){
        this.getOrderNote(this.orderId)
    },
     methods:{
        getOrderNote(id){
          orderService.getOrderCustomerNote({order_id: id})
            .then(response => {
              this.note = response.data.result.customer_note;
              this.$refs['note-modal'].show();
            })

        },
        resetModal(){
            // this.order_id = null;
            this.note = '';
            this.$emit('hidden')
        },
        addNote(bvModalEvt){
            bvModalEvt.preventDefault()
            if(this.note == ''){
                this.$toasted.info('Please enter note.');
                return false;
            }
            const data = {order_id:this.orderId,customer_note:this.note};
            orderService.addOrderCustomerNote(data)
                .then(response => {
                    if(response.data.code == 200){
                        this.$toasted.success(response.data.message);
                    }else{
                        this.$toasted.error(response.data.message);
                    }
                    this.$refs['note-modal'].hide();
                    this.$emit('actionChanged');
                })
        },
    }
}
</script>
<style  scoped>
.height-100{
    height: 100px;
}
</style>