
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import {
  vendorService,
  deliveryZoneService,
  settingService,
} from "../../services";
import { debounce, error_message, replaceByDefault, success_message } from "../../Helper/helper";

export default {
  components: { pagination, vendorService, deliveryZoneService },
  data() {
    const { is_confirm,country,state,city,orderBy,sortBy,status} = this.$route.query;
    return {
      media:[],
      replaceByDefault:replaceByDefault,
      vendorData: [],
      config: config,
      sortBy: sortBy || "created_date",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      vendors: [],
      vendorCount: '',
      paginations: {},
      limit: 2,
      search: "",
      is_confirm: is_confirm == undefined ? 1 :is_confirm ,
      countries: [],
      states: [],
      cities: [],
      country: country || "0",
      state: state || "0",
      city: city || "0",
      init: true,
      restaurant_on_off: -1,
      searchData:debounce(()=>{
        if(!this.init){
          const {is_confirm,country,state,city,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'vendor-list', query: {is_confirm,country,state,city,orderBy,sortBy}})
        }
        this.getVendor();
        this.getVendorDetail();
        this.init = false;
      },500),
      setting: [],
      loading: true,
      fields: [
        {
          key: "icon_image",
          label: "#",
          sortable: false,
        },
        {
          key: "name",
          label: this.trans.get("__JSON__.Vendor Name"),
          sortable: true,
        },
        {
          key: "unique",
          label: this.trans.trans("__JSON__.Vendor Id"),
          sortable: false,
        },
        {
          key: "restaurant_on_off",
          label: this.trans.get("__JSON__.On/Off"),
          sortable: false,
        },
        {
          key: "delivery_time",
          label: this.trans.get("__JSON__.Avg. Processing Time (Min)"),
          sortable: false,
        },
        {
          key: "order_count",
          label: this.trans.get("__JSON__.Orders"),
          sortable: true,
        },
        {
          key: "is_confirm",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
      timeform:{
        restaurant_id: 0,
        delivery_time: 0,
      }
    };
  },

  mounted() {
    this.getVendorCountry();
    this.getAppSetting();
  },
  methods: {
    onRowClicked(item) {
        this.$router.push({name: 'vendor-detail',params: { restaurant_id: this.base64(item.restaurant_id)}})
      },
    getAppSetting() {
        this.fields[1].label = this.$auth.getRestaurantName();
        this.fields[2].label = this.$auth.getRestaurantName()+' Id';
    },
    downloadsCSV: function () {
      let vendorData = "\ufeff" +this.$auth.getRestaurantName()+ ",Location,Order,Status\n";
      this.vendorData.map((el) => {
        var line =
          el["name"] +
          "," +
          (el["address"] ? el["address"] : "").replaceAll(
            ",",
            " "
          ) +
          "," +
          el["order_count"] +
          "," +
          (el["is_confirm"] == 1 ? "Active" : "Inactive") +
          "\n";
        vendorData += line;
      });
      var blob = new Blob([vendorData], { type: "text/csv;charset=utf-8;" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.$auth.getRestaurantName()+ "_" + date + ".csv";
      link.click();
    },
    getVendorCount()
    {
      this.loading = true;
      vendorService
        .getVendorCount({
          search: this.search,
          is_confirm: this.is_confirm,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          country: this.country,
          state: this.state,
          city: this.city,
        })
        .then((response) => {
          this.loading = false;
          this.vendorCount = response.data.result.data;
        });
    },
    getVendor(page) {
      this.loading = true;
      if (this.sortBy == "name") {
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      };
      var filters = {
          search: this.search,
          is_confirm: this.is_confirm,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          country: this.country,
          state: this.state,
          city: this.city,
          page: page && page > 0 ? page : 1,
      }
      vendorService
        .getVendorList(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.vendor_listing;
          this.vendors = response.data.result.vendor_listing.data;
          this.vendorCount = response.data.result.vendor_listing.total;
          this.restaurant_on_off = response.data.result.closed == 0 ? 1 : 0;
        });
    },
    getVendorDetail() {
      vendorService
        .exportToCsv({ is_confirm: this.is_confirm, sortBy: this.sortBy, orderBy: this.sortDesc ? "desc" : "asc" })
        .then((response) => {
          this.vendorData = response.data.result;
        });
    },
    getVendorCountry() {
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        this.getVendorState();
      });
    },
    getVendorState() {
      this.city = "0";
      this.state = "0";
      deliveryZoneService
        .getVendorState({
          country_id: this.country,
        })
        .then((response) => {
          this.states = response.data.result;
          this.getVendorCity();
        });
    },
    getVendorCity() {
      this.city = '0';
      deliveryZoneService
        .getVendorCity({
          state_id:this.state ? this.state : '0',
          country_id: this.country
        })
        .then((response) => {
          this.cities = response.data.result;
          this.searchData();
          
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendor();
      this.getVendorDetail();
    },
    closeAllRestaurant(restaurant_on_off){
      var msg = 'Are you sure you want to close all restaurant?';
      if(restaurant_on_off == 1){
        msg = 'Are you sure you want to open all restaurant?';
      }
      if(confirm(msg)){
        vendorService.closeAllRestaurant({restaurant_on_off})
          .then(response => {
          this.getVendor(this.filter?this.filter.page:1)
            success_message(response.data.message);
          })
      }else{
        setTimeout(() => {
          this.restaurant_on_off = restaurant_on_off == 0 ? 1 : 0;
        },500)
      }
      
    },
    changeRestaurantStatus(e, restaurant_id){
      vendorService
        .changeRestaurantStatus({restaurant_id, restaurant_on_off : e.target.checked ? '1' : '0'})
        .then(response => {
          if(response.data.code !== 200){
            let index = this.vendors.findIndex(i => i.restaurant_id === restaurant_id);
            this.vendors[index].restaurant_on_off = e.target.checked ? '0' : '1'
            error_message(response.data.message)
          }else{
            success_message(response.data.message)
          }
        })
    },
    changeProcesingTime(){
      if(this.timeform.delivery_time == ''){
        alert('Please enter valid time?');
        return true;
      }
      vendorService
        .changeRestaurantStatus(this.timeform)
        .then(response => {
          if(response.data.code !== 200){
            return error_message(response.data.message)
          }
          console.log(this.timeform)
          let index = this.vendors.findIndex(i => i.restaurant_id == this.timeform.restaurant_id);
          this.vendors[index].delivery_time = this.timeform.delivery_time;
          this.timeform.restaurant_id = 0
          return success_message(response.data.message)
        })
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-store"></i>
              </span>
            </div>
            <h4
              class="mb-0 font-size-18"
            >{{ trans.trans('__JSON__.Vendors',{ vendor: $auth.setting.restaurant_name }) }}({{vendorCount}})</h4>
          </div>
          <div class="page-title-right d-flex">
            <b-form-checkbox
              id="checkbox-1"
              name="checkbox-1"
              v-if="restaurant_on_off !== -1 && $auth.hasPermission(config.permissions.merchant_write)"
              v-model="restaurant_on_off"
              @change="closeAllRestaurant"
              value="1"
              unchecked-value="0"
              switch
              class="d-flex align-items-center mr-2"
            >
              All Restaurant Accepting Order
            </b-form-checkbox>
            <router-link :to="{name: 'vendor-add'}"  v-if="$auth.hasPermission(config.permissions.merchant_write) && $auth.setting.is_single_restaurant == 'No'" class="btn btn-success btn-rounded mr-2 add-btn">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Vendor',{ vendor: $auth.setting.restaurant_name }) }}
            </router-link>
            <button
              @click="downloadsCSV" v-if="$auth.hasSubAdminPermission(config.permissions.merchant_export)"
              class="btn btn-primary btn-rounded export-btn"
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
            <div class="d-flex m-vendor-btn">
              <router-link :to="{name: 'vendor-add'}"  v-if="$auth.hasPermission(config.permissions.merchant_write) && $auth.setting.is_single_restaurant == 'No'" class="btn btn-success btn-rounded mr-2 m-add-btn">
                <i class="mdi mdi-plus"></i>
              </router-link>
              <button
                @click="downloadsCSV" v-if="$auth.hasSubAdminPermission(config.permissions.merchant_export)"
                class="btn btn-primary btn-rounded m-export-btn"
              ><i class="bx bx-archive-in"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div v-if="countries.length >1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Country') }} :</label>
                    <select class="custom-select" v-model="country" @change="getVendorState();">
                      <option value="0" selected>All</option>
                      <option
                        v-for="con in countries"
                        :value="con.id"
                        :key="con.id"
                      >{{con.country_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-else class="offset-sm-6">
              </div>
              <div v-if="countries.length >=1 && states.length >1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.State') }} :</label>
                    <select class="custom-select" v-model="state" @change="getVendorCity">
                      <option value="0" selected>All</option>
                      <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="states.length >=1 && cities.length >1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.City') }} :</label>
                    <select class="custom-select" v-model="city" @change="searchData">
                      <option value="0" selected>All</option>
                      <option
                        v-for="cit in cities"
                        :value="cit.cityid"
                        :key="cit.cityid"
                      >{{cit.cityname}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.SortBy') }} :</label>
                    <select class="custom-select" v-model="sortBy" @change="searchData()">
                      <!-- <option value="status">{{ trans.get('__JSON__.Default') }}</option> -->
                      <option value="created_date">{{ trans.get('__JSON__.Date Added') }}</option>
                      <option value="order_count">{{ trans.get('__JSON__.Most Orders') }}</option>
                      <option value="name">{{ trans.get('__JSON__.Alphabetically') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="is_confirm" @change="searchData()">
                      <option value="">All</option>
                      <option value="1" selected="selected">Active</option>
                      <option value="0">Inactive</option>
                      <option value="2">Waiting For Approval</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="search-box mr-2 mb-2 d-inline-block">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Search') }} :</label>
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        @input="searchData()"
                        v-model="search"
                        :placeholder="trans.get('__JSON__.Search...')"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list vendor-table table-responsive">
                <b-table
                  :items="vendors"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  @row-clicked="onRowClicked"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(icon_image)="data">
                    <div v-if="data.item.icon_image">
                      <img
                        alt
                        class="vendor-img avatar-xs"
                        v-lazy="(config.restauranticon+data.item.icon_image) || config.no_image"
                      />
                    </div>
                    <div v-else>
                      <img class="vendor-img avatar-xs" :src="config.basepath+'no-img.png'" alt />
                    </div>
                  </template>

                  <template v-slot:cell(name)="data">
                    <h5 class="font-size-14">
                      <div v-if="data.item.name.length > 25">{{data.item.name.substr(0, 25)+ '...'}}</div>
                      <div v-else>{{data.item.name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(unique)="data">
                    <h5 class="font-size-14"><div>{{data.item.unique}}</div></h5>
                  </template>

                  <template v-slot:cell(delivery_time)="data">
                    <h5 v-if="timeform.restaurant_id != data.item.restaurant_id" >
                      {{ data.value }}
                      <!-- <b-button :disabled="!$auth.hasPermission(config.permissions.merchant_write)" @click="timeform = {restaurant_id: data.item.restaurant_id, delivery_time: data.value }" variant="link" class="font-size-17">
                        <i class="bx bx-edit-alt"></i>
                      </b-button> -->
                      <b-button  @click="timeform = {restaurant_id: data.item.restaurant_id, delivery_time: data.value }" variant="link" class="font-size-17">
                        <i class="bx bx-edit-alt"></i>
                      </b-button>
                    </h5>
                    <div class="d-flex" v-else>
                      <input type="number" class="w-25" v-model="timeform.delivery_time" @keydown.esc="timeform.restaurant_id = 0" @keydown.enter="changeProcesingTime()"  onload="this.focus()" />
                      <b-button @click="changeProcesingTime()" variant="link" class="font-size-17">
                        <i class="bx bx-save" ></i>
                      </b-button>
                    </div>
                  </template>
                  
                  
                  <template v-slot:cell(restaurant_on_off)="data">
                      <div>
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="data.value"
                            class="switch-on"
                            :true-value="'1'"
                            :false-value="'0'"
                          
                            @input="$event => changeRestaurantStatus($event, data.item.restaurant_id)"
                          />
                          <div class="slider round">
                            <span class="on">{{ trans.get('__JSON__.Yes') }}</span>
                            <span class="off">{{ trans.get('__JSON__.No') }}</span>
                          </div>
                        </label>
                      </div>
                  </template>


                  <!-- <template v-slot:cell(address)="data">
                    <div v-if="data.item.address">
                      <div
                        v-if="data.item.address.length > 60"
                      >{{data.item.address.substr(0, 60)+ '...'}}</div>
                      <div v-else>{{data.item.address}}</div>
                    </div>
                  </template> -->

                  <template v-slot:cell(is_confirm)="data">
                    <div
                      v-if="data.item.is_confirm == '0'"
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                    <div
                      v-if="data.item.is_confirm == '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-if="data.item.is_confirm == '2'"
                      class="badge badge-pill badge-soft-warning font-size-12"
                    >{{ trans.get('__JSON__.Waiting For Approval') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        :to="{name: 'vendor-detail',params: { restaurant_id: base64(data.item.restaurant_id)}}"
                        class="btn btn-primary btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.View') }}</router-link>
                      <router-link
                        :to="{name: 'vendor-catalog',params: { restaurant_id: base64(data.item.restaurant_id)}}"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Catalog') }}</router-link>
                    </div>
                   <router-link
                      :to="{name: 'vendor-dine-in-menu',params: { restaurant_id: base64(data.item.restaurant_id)}}"
                      class="btn btn-warning btn-sm btn-rounded mt-1"
                    >{{ trans.get('__JSON__.Dine-in Menu') }}</router-link>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Vendor Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getVendor"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
