<script>
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import { settingService } from "../../../services";
import { required,numeric } from "vuelidate/lib/validators";
import { error_message, success_message } from '../../../Helper/helper';
import config from '../../../config';


/**
 * Starter component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect },
  data() {
    return {
      config: config,
      checked: true,
      value: null,
      order_assign_class:'switch-on',
      setting:[],
      // deliverycharges:[],
      delivery_charges: [
          {id:'Order Amount',text:'Amount', show: 'Value'},
          {id:'Delivery Distance (Km)',text:'Distance', show: 'Distance'}
      ],
      deliverycharges: [{ min_value: '',max_value: '',delivery_charges: '' }],
      // minimum_order_value: [{ min_value: '',max_value: '',order_amount: ''}],
      errors: '',
      setting: {
        order_commission: "",
        order_auto_refresh: "",
        is_order_assigned_manually: "",
        vendor_max_time: "",
        driver_max_time: "",
        charge:[],
        order_dashboard_default_time: "0",
        order_notification_sound: 'no_sound',
        booking_notification_sound: 'no_sound',
        delay_message:'',
        wallet_status: 'inactive',
        wallet_recharge: 'inactive',
        maximum_referral: 0,
        // no_cod_orders: 0,
        minimum_no_cod_order_amount: 0,
      },
      default_time_options:config.default_time_options,
      default_notification_sounds:config.default_notification_sounds,
      playingo: false,
      playingb: false,
      sound: null
    };
  },
   validations: {
    setting: {
      order_commission: {
        required,
        numeric,
      },
      order_auto_refresh: {
        required,
        numeric,
      },
      vendor_max_time: {
        required,
        numeric,
      },
      // no_cod_orders:{
      //   required,
      //   numeric
      // },
      minimum_no_cod_order_amount:{
        required,
        numeric
      },
      order_notification_sound: {
        required
      },
      booking_notification_sound: {
        required
      },
      delay_message: {
        required
      },
      referral_amount:{
        required,
        numeric,
      },
      maximum_referral:{
        required,
        numeric,
      },
      driver_max_time: {
        required,
        numeric,
      },
      charges: {
        $each: {
          min_value: {
            required,
          }
        }
      },
    },
    deliverycharges:{
      $each: {
        min_value: {
          required,
          numeric
        },
        max_value: {
          required,
          numeric
        },
        delivery_charges: {
          required,
          numeric
        }
      },
    },
   
  },mounted() {
    this.getSetting();
    this.value = this.delivery_charges.find(d => d.text == 'Amount');
  },
  methods: {
    AddField: function () {
      this.deliverycharges.push({ min_value: '',max_value: '',delivery_charges: '' });
    },
    getSetting(){
      settingService.getSetting()
      .then(response => {
        this.setting = {
          booking_notification_sound:'no_sound',
          // no_cod_orders: 0,
          minimum_no_cod_order_amount: 0,
          ...response.data.result,        
        };
      //  this.value = response.data.result.delivery_charges_type;
        this.value = this.delivery_charges.find(d => d.text == response.data.result.delivery_charges_type);
        this.deliverycharges = response.data.result.charges;
        // this.minimum_order_value = response.data.result.minimum_order_value && JSON.parse(response.data.result.minimum_order_value) || this.minimum_order_value;
      });
    },
    is_order_assigned($event){
      this.setting[$event.target.name] = $event.target.checked?'Yes':'No';
      console.log($event);
    },
     orderInIt(){
      this.$v.$touch();
      if (this.$v.setting.$invalid || this.$v.deliverycharges.$invalid) {
        return;
      } else {
       const fd = new FormData();
       fd.append("order_commission", this.setting.order_commission);
       fd.append("order_auto_refresh", this.setting.order_auto_refresh);
       fd.append("vendor_max_time",this.setting.vendor_max_time);
       fd.append("driver_max_time",this.setting.driver_max_time);
       fd.append("delivery_charges_type",this.value.text);
       fd.append("is_order_assigned_manually",this.setting.is_order_assigned_manually);
       fd.append("maximum_referral",this.setting.maximum_referral);
       fd.append("deliverycharges",JSON.stringify(this.deliverycharges));
       fd.append("minimum_order_value",JSON.stringify(this.minimum_order_value));
       fd.append("order_dashboard_default_time",this.setting.order_dashboard_default_time);
       fd.append("order_notification_sound",this.setting.order_notification_sound);
       fd.append("booking_notification_sound",this.setting.booking_notification_sound);
      //  fd.append("no_cod_orders",this.setting.no_cod_orders);
       fd.append("minimum_no_cod_order_amount",this.setting.minimum_no_cod_order_amount);
       fd.append("delay_message",this.setting.delay_message);
       fd.append("wallet_status",this.setting.wallet_status);
       fd.append("wallet_recharge",this.setting.wallet_recharge);
       fd.append("referral_amount",this.setting.referral_amount);
       fd.append("type",2);
       settingService.updateSetting(fd).then((response) => {
          if (response.data.code === 200) {
            if(response.data.result){
              this.$auth.updateSetting(response.data.result.settings)
            }
            success_message(response.data.message);
          } else {
              error_message(response.data.message);
          }
        });
     }
    },
      
      remove_delivery_charges(index){
        this.deliverycharges.splice(index,1);
      },
      playSound(item){
        if(!(this.playingb || this.playingo) && this.setting[item] !== 'no_sound'){
          this.sound = new Audio(this.config.appurl+this.setting[item])
          this.sound.play()
          if(item === 'booking_notification_sound'){
            this.playingb = true
          }else{
            this.playingo = true
          }
        }else{
          this.sound.pause();
          this.sound.currentTime = 0;
          this.playingb = false
          this.playingo = false
        }
      }

    }
};
</script>
<template>
  <b-form @submit.prevent="orderInIt">
       <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body setting-main">
                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>{{trans.get('__JSON__.Basic Settings')}}</h4>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <!-- <div class="col-md-12">
                                        <h6>Order Comission (Percentage)</h6>  
                                    </div> -->
                                    <div class="col-md-6 order-value">
                                        <!-- <input id="order_commission" name="order_commission" type="number" v-model="setting.order_commission" class="form-control"/> -->
                                         <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Comission (Percentage)')" label-for="name">
                                          <b-form-input
                                            id="order_commission"
                                            name="order_commission"
                                            v-model="$v.setting.order_commission.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.IOS User App Version')"
                                            :class="{ 'is-invalid':  $v.setting.order_commission.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.order_commission.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.order_commission.required"
                                            >{{ trans.get('__JSON__.IOS User App Version') }}.</span>
                                              <span
                                              v-if="!$v.setting.order_commission.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Comission Value for Each Order. It will be Applicable for All {{ $auth.setting.restaurant_name }}.</p>
                                     </div>
                                </div>
                                <!-- <div class="row mt-4">
                                  <div class="col-md-12">
                                        <h6>Order Auto Refresh (Seconds)</h6>  
                                    </div>
                                    <div class="col-md-6 order-value">
                                      <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Auto Refresh (Seconds)')" label-for="name">
                                          <b-form-input
                                            id="order_auto_refresh"
                                            name="order_auto_refresh"
                                            v-model="$v.setting.order_auto_refresh.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Order Auto Refresh')"
                                            :class="{ 'is-invalid':  $v.setting.order_auto_refresh.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.order_auto_refresh.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.order_auto_refresh.required"
                                            >{{ trans.get('__JSON__.Order Auto Refresh') }}.</span>
                                              <span
                                              v-if="!$v.setting.order_auto_refresh.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Order will be Cancelled if {{ $auth.setting.restaurant_name }} will not Accept or confirm order with this seconds.</p>
                                     </div>
                                </div> -->
                                 <div class="row mt-4">
                                   <div class="col-md-12">
                                        <h6>{{trans.get('__JSON__.Order Assigned Manually')}}</h6>  
                                    </div>
                                    <div class="col-md-6 order-value">
                                      <div class="swtich-data">
                                         <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="is_order_assigned_manually"
                                                name="is_order_assigned_manually"
                                                v-model="setting.is_order_assigned_manually"
                                                class="switch-on"
                                                true-value="Yes"
                                                false-value="No"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                          </b-form-group>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- <p>Order will be Cancelled if {{ $auth.setting.restaurant_name }} will not Accept or confirm order with this seconds.</p> -->
                                        <p>{{ trans.get('__JSON__.By Enabling this Option orders will not be assign to Delivery Boys automatically') }}.</p>
                                     </div>
                                  </div>
                                  <div class="row mt-4">
                                    <!-- <div class="col-md-6">
                                      <b-form-group
                                        label="Non cod orders"
                                        label-for="no_cod_orders"
                                        description="Number of new customer's first orders without cod option"
                                      >
                                        <b-form-input
                                          id="no_cod_orders"
                                          v-model="$v.setting.no_cod_orders.$model"
                                          type="number"
                                          placeholder="Enter non cod orders"
                                          required
                                          :class="{ 'is-invalid': $v.setting.no_cod_orders.$error }"
                                        ></b-form-input>
                                        <div v-if=" $v.setting.no_cod_orders.$error" class="invalid-feedback">
                                          <span
                                            v-if="!$v.setting.no_cod_orders.required"
                                          >Please enter non cod orders</span>
                                            <span
                                            v-if="!$v.setting.no_cod_orders.numeric"
                                          >Non cod orders field must be numeric.</span>
                                        </div>
                                      </b-form-group>
                                    </div> -->
                                    <div class="col-md-6">
                                      <b-form-group
                                        label="Minimum cod order amount"
                                        label-for="minimum_no_cod_order_amount"
                                        description="Minimum amount for new customer's first orders without cod option"
                                      >
                                        <b-form-input
                                          id="minimum_no_cod_order_amount"
                                          v-model="$v.setting.minimum_no_cod_order_amount.$model"
                                          type="number"
                                          placeholder="Enter minimum non cod order amount"
                                          required
                                          :class="{ 'is-invalid':  $v.setting.minimum_no_cod_order_amount.$error }"
                                        ></b-form-input>
                                        <div v-if=" $v.setting.minimum_no_cod_order_amount.$error" class="invalid-feedback">
                                          <span
                                            v-if="!$v.setting.minimum_no_cod_order_amount.required"
                                          >Please enter minimum non cod order amount</span>
                                            <span
                                            v-if="!$v.setting.minimum_no_cod_order_amount.numeric"
                                          >Minimum non cod order amount field must be numeric.</span>
                                        </div>
                                      </b-form-group>
                                    </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col-md-12">
                                        <h6>{{trans.get('__JSON__.Wallet')}}</h6>  
                                    </div>
                                    <div class="col-md-6 vendor-status">
                                      <div class="swtich-data">
                                         <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="wallet_status"
                                                name="wallet_status"
                                                v-model="setting.wallet_status"
                                                class="switch-on"
                                                true-value="active"
                                                false-value="inactive"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                                </div>
                                            </label>
                                          </b-form-group>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{ trans.get('__JSON__.This will enable wallet feature') }}.</p>
                                     </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col-md-12">
                                        <h6>{{trans.get('__JSON__.Wallet Recharge')}}</h6>  
                                    </div>
                                    <div class="col-md-6 vendor-status">
                                      <div class="swtich-data">
                                         <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="wallet_recharge"
                                                name="wallet_recharge"
                                                v-model="setting.wallet_recharge"
                                                class="switch-on"
                                                true-value="active"
                                                false-value="inactive"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                                </div>
                                            </label>
                                          </b-form-group>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{ trans.get('__JSON__.This will enable wallet recharge feature') }}.</p>
                                     </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                     <b-form-group id="input-group-1" :label="trans.get('Referral Amount')" label-for="name">
                                          <b-form-input
                                            id="vendor_max_time"
                                            name="vendor_max_time"
                                            v-model="$v.setting.referral_amount.$model"
                                            type="text"
                                            :placeholder="trans.get('Enter Referral Amount')"
                                            :class="{ 'is-invalid':  $v.setting.referral_amount.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.referral_amount.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.referral_amount.required"
                                            >{{ trans.get('__JSON__.required',{ attribute: 'Referral Amount' }) }}.</span>
                                              <span
                                              v-else-if="!$v.setting.referral_amount.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                     <b-form-group id="input-group-1" :label="trans.get('Maximum Referral')" label-for="maximum_referral">
                                          <b-form-input
                                            id="maximum_referral"
                                            name="maximum_referral"
                                            v-model="$v.setting.maximum_referral.$model"
                                            type="text"
                                            :placeholder="trans.get('Enter Maximum Referral')"
                                            :class="{ 'is-invalid':  $v.setting.maximum_referral.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.maximum_referral.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.maximum_referral.required"
                                            >{{ trans.get('__JSON__.required',{ attribute: 'Maximum Referral' }) }}.</span>
                                              <span
                                              v-else-if="!$v.setting.maximum_referral.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                     <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Autocancel Order For (In Minutes)',{ vendor: $auth.setting.restaurant_name })" label-for="name">
                                          <b-form-input
                                            id="vendor_max_time"
                                            name="vendor_max_time"
                                            v-model="$v.setting.vendor_max_time.$model"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.Autocancel Order For (In Minutes)',{ vendor: $auth.setting.restaurant_name })"
                                            :class="{ 'is-invalid':  $v.setting.vendor_max_time.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.vendor_max_time.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.vendor_max_time.required"
                                            >{{ trans.trans('__JSON__.Autocancel Order For (In Minutes)',{ vendor: $auth.setting.restaurant_name }) }}.</span>
                                              <span
                                              v-if="!$v.setting.vendor_max_time.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Order will be Cancelled if {{ $auth.setting.restaurant_name }} will not Accept or confirm order with this minutes.</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                       <b-form-group id="input-group-1" :label="trans.trans('__JSON__.Autocancel Order For Driver (In Minutes)', { driver: $auth.setting.driver_name })" label-for="name">
                                          <b-form-input
                                            id="driver_max_time"
                                            name="driver_max_time"
                                            v-model="$v.setting.driver_max_time.$model"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.Autocancel Order For Driver (In Minutes)', { driver: $auth.setting.driver_name })"
                                            :class="{ 'is-invalid':  $v.setting.driver_max_time.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.driver_max_time.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.driver_max_time.required"
                                            >{{ trans.trans('__JSON__.Autocancel Order For Driver (In Minutes)', { driver: $auth.setting.driver_name }) }}.</span>
                                              <span
                                              v-if="!$v.setting.driver_max_time.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Order will be Cancelled if {{ $auth.setting.driver_name }} will not Accept or confirm order with this minutes.</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                       <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Dashboard Default Date')" label-for="name">
                                          <b-form-select v-model="setting.order_dashboard_default_time" :options="default_time_options"></b-form-select>
                                          <div v-if=" $v.setting.driver_max_time.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.driver_max_time.required"
                                            >{{ trans.trans('__JSON__.Autocancel Order For Driver (In Seconds)', { driver: $auth.setting.driver_name }) }}.</span>
                                              <span
                                              v-if="!$v.setting.driver_max_time.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{ trans.get('__JSON__.Set the default date range for the Ordering Dashboard page') }}.</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                      <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Notification Sound')" label-for="name">
                                        <b-form-select v-model="setting.order_notification_sound" @input="playingo && playSound('order_notification_sound')" :options="default_notification_sounds"></b-form-select>
                                      </b-form-group>
                                      <div v-if=" $v.setting.order_notification_sound.$error" class="invalid-feedback">
                                        <span v-if="!$v.setting.order_notification_sound.required">
                                          {{ trans.get('__JSON__.Please select notification sound')}}.
                                        </span>
                                      </div>
                                    </div>
                                    <div class="col-md-6 order-value">
                                      <b-button variant="link" v-if="setting.order_notification_sound !== 'no_sound'"  @click="playSound('order_notification_sound')" class="top-26"><i class="fas" :class="{'fa-play':!playingo, 'fa-stop':playingo}"></i></b-button>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{trans.get('__JSON__.New order notification sound')}}</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6 order-value">
                                      <b-form-group id="input-group-1" :label="trans.get('__JSON__.Order Delay Message')" label-for="name">
                                         <b-form-input
                                            v-model="$v.setting.delay_message.$model"
                                            type="text"
                                            :placeholder="trans.trans('__JSON__.Order Delay Message')"
                                            :class="{ 'is-invalid':  $v.setting.delay_message.$error }"
                                          ></b-form-input>
                                        <div v-if=" $v.setting.delay_message.$error" class="invalid-feedback">
                                          <span v-if="!$v.setting.delay_message.required">
                                            {{ trans.get('__JSON__.Please enter delay order message')}}.
                                          </span>
                                        </div>
                                      </b-form-group>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                  <div class="col-md-6 order-value">
                                    <b-form-group id="input-group-1" :label="trans.get('__JSON__.Booking Notification Sound')" label-for="name1">
                                      <b-form-select v-model="setting.booking_notification_sound" @input="playingb && playSound('booking_notification_sound')" :options="default_notification_sounds"></b-form-select>
                                    </b-form-group>
                                    <div v-if=" $v.setting.booking_notification_sound.$error" class="invalid-feedback">
                                      <span v-if="!$v.setting.booking_notification_sound.required">
                                        {{ trans.get('__JSON__.Please select notification sound')}}.
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-md-6 order-value">
                                    <b-button variant="link" v-if="setting.booking_notification_sound !== 'no_sound'"  @click="playSound('booking_notification_sound')" class="top-26"><i class="fas" :class="{'fa-play':!playingb, 'fa-stop':playingb}"></i></b-button>
                                  </div>
                                  <div class="col-md-12">
                                      <p>{{trans.get('__JSON__.New booking notification sound')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Delivery Charges</h4>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6>Delivery Charge Based On</h6>
                                </div>
                                 <div class="col-md-6 order-value">
                                    <multiselect v-model="value"
                                      :options="delivery_charges"
                                      track-by="id"
                                      label="id" 
                                      value="text" 
                                      name="delivery_charges"
                                      placeholder="Select one"
                                      id="delivery_charges"
                                      :searchable="false"
                                      :allow-empty="false"></multiselect>
                                </div>
                                <div class="col-md-12">
                                    <p>Select How you wish to set Delivery Charge to Customer.</p>
                                </div>
                             </div>
                             <div class="row mt-3">
                                <div class="col-md-12" v-for="(v,index) in $v.deliverycharges.$each.$iter" :key="index">
                                    <div class="row mt-2">
                                       <div class="col">
                                            <h6> Order {{ value && value.show}} From</h6>
                                            <input v-model="v.min_value.$model" name = "min_value[]" type="number" class="form-control"
                                            :class="{ 'is-invalid':  v.min_value.$error }"
                                            />
                                          <div v-if=" v.min_value.$error" class="invalid-feedback">
                                            <span
                                              v-if="!v.min_value.required"
                                            >{{ trans.trans('__JSON__.required',{attribute: 'min value'}) }}.</span>
                                            <span
                                              v-if="!v.min_value.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                          <!-- <b-form-group >
                                          <b-form-input
                                            name="min_value[]"
                                            v-model="v.min_value"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Delivery Charge Minimum Order')"
                                              :class="{ 'is-invalid':  $v.deliverycharges.v.min_value.$error }"
                                          ></b-form-input>
                                          <div v-if="$v.deliverycharges.v.min_value.$error" class="invalid-feedback">
                                            <span v-if="!$v.deliverycharges.v.min_value.required"
                                            >{{ trans.get('__JSON__.Delivery Charge Minimum Order') }}.</span>
                                              <span
                                              v-if="!$v.deliverycharges.min_value.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group> -->
                                        </div>
                                         <div class="col">
                                            <h6> Order {{ value && value.show}} To</h6>
                                            <input v-model="v.max_value.$model" name = "max_value[]" type="number" class="form-control"
                                              :class="{ 'is-invalid':  v.max_value.$error }"
                                              />
                                            <div v-if=" v.max_value.$error" class="invalid-feedback">
                                              <span
                                                v-if="!v.max_value.required"
                                              >{{ trans.trans('__JSON__.required',{attribute: 'max value'}) }}.</span>
                                              <span
                                                v-if="!v.max_value.numeric"
                                              >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                            </div>
                                         </div>
                                         <div class="col">
                                            <h6>Charges</h6>
                                            <input v-model="v.delivery_charges.$model" name = "delivery_charges[]"  type="number" class="form-control"
                                            :class="{ 'is-invalid':  v.delivery_charges.$error }"
                                            />
                                            <div v-if=" v.delivery_charges.$error" class="invalid-feedback">
                                              <span
                                                v-if="!v.delivery_charges.required"
                                              >{{ trans.trans('__JSON__.required',{attribute: 'delivery charges'}) }}.</span>
                                              <span
                                                v-if="!v.delivery_charges.numeric"
                                              >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <h6>&nbsp;</h6>
                                            <button class="btn btn-primary btn-sm btn-rounded" v-if="index == 0" @click.prevent="AddField">+</button>
                                            <button class="btn btn-danger btn-sm btn-rounded" v-if="index > 0" @click.prevent="remove_delivery_charges(index)">-</button>
                                        </div>
                                    </div>
                                </div>
                             </div>
                          </div>
                        </div>
                    </div>
                <div class="btn-update">
                    <div class="col-sm-6">
                    <button type="submit" v-if="$auth.hasPermission(config.permissions.configuration_write)"  class="btn btn-primary mr-1 mt-3">Update</button>
                    </div>
                </div>

            </div>
        </div>
      </div>
    </div>
</b-form>
</template>
<style scoped>
.top-26{
  margin-top: 26px;
  display: inline;
}
</style>