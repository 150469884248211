<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { cuisineService } from "../../services";
import editCuisines from "./edit-cuisines";
/**
 * edit-cuisines component
 */
export default {
  components: { Layout, PageHeader, editCuisines },
  data() {
    return {
      cuisine: []
    };
  },
  mounted() {
    this.getCuisineById();
  },
  methods: {
    getCuisineById() {
      cuisineService
        .getCuisineById({ cuisine_id: this.$route.params.cuisine_id })
        .then((response) => {
            this.cuisine = response.data.result;
        });
    },
  }
};
</script>

<template>
  <Layout>
  <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-tag"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ cuisine.cuisine_name }}</h4>
              <p><router-link :to="{ name: 'cuisines-list'}">{{ trans.get('__JSON__.Cuisines') }}</router-link> > {{ cuisine.cuisine_name }}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <edit-cuisines />
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
