<script>
import config from "../../config";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import { giftVoucherService } from "../../services";
import { debounce, error_message, success_message,replaceByDefault } from "../../Helper/helper";


export default {
  components: { PageHeader, pagination, moment },
  data() {
    const { status, orderBy, sortBy, search } = this.$route.query;
    return {
      replaceByDefault,
      sortBy: sortBy || "created_at",
      sortDesc: orderBy && orderBy != "" ? true : orderBy,
      giftVouchers: null,
      giftVouchersData: [],
      giftVouchersCount: "",
      paginations: null,
      limit: 2,
      search: search || "",
      status: status == undefined ? "" : status,
      config: config,
      moment: moment,
      loading: true,
      init: true,
      searchData: debounce(() => {
        if (!this.init) {
          const { status, orderBy, sortBy, search } = this.$data;
          this.$router.replace({
            name: "gift-voucher-list",
            query: { status, orderBy, sortBy,search },
          });
        }
        this.getGiftVouchers();
        // this.giftVoucherExportCsv();
        this.init = false;
      }, 500),
      fields: [
        { key: "id", label: "Id", sortable: false },
        { key: "full_image", label: this.trans.get("__JSON__.Image"), sortable: false },
        { key: "title", label: this.trans.get("__JSON__.Title"), sortable: false },
        { key: "description", label: this.trans.get("__JSON__.Description"), sortable: false },
        { key: "created_date", label: this.trans.get("__JSON__.Created At"), sortable: false },
        { key: "created_by", label: this.trans.get("__JSON__.Created By"), sortable: false },
        { key: "status", label: this.trans.get("__JSON__.Status"), sortable: false },
        { key: "action", label: this.trans.get("__JSON__.Action"), sortable: false },
        // { key: "created_at", label: this.trans.get("__JSON__.Created At"), sortable: true, },
      ],
    };
  },
  mounted() {
    this.getGiftVouchers(0);
  },
  methods: {
    onRowClicked(item) {
      // this.$router.push({
      //   name: "giftVoucher-details",
      //   params: { giftVoucher_id: this.base64(item.giftVoucherid) },
      // });
    },
    // downloadsCSV: function () {
    //   let giftVoucherData =
    //     "\ufeff" +
    //     "Id,Customer,Restaurant,Payment Id,Order Id,Total Amount,Default Discount,Promocode Discount,Promocode,Final Amount,Unique Code,Payment Status\n";
    //   this.giftVoucherData.forEach((el) => {
    //     var line =  el["id"] + "," +
    //                 el["customer"] + "," +
    //                 (el["restaurant_name"] ? el["restaurant_name"] : "-").replaceAll("null", "-") + "," +
    //                 (el["payment_id"] ? el["payment_id"] : "-").replaceAll("null", "-") + "," +
    //                 el["order_id"] + "," +
    //                 el["total_amount"] + "," +
    //                 (el["default_discount"] ? el["default_discount"] : "-").replaceAll("null", "-") + "," +
    //                 (el["promocode_discount"] ? el["promocode_discount"] : "-").replaceAll("null", "-") + "," +
    //                 (el["coupon_name"] ? el["coupon_name"] : "-").replaceAll(",", " ") + "," +
    //                 el["final_amount"] + "," +
    //                 (el["unique_code"] ? el["unique_code"] : "-").replaceAll("null", "-") + "," +
    //                 (el["payment_status"] ? el["payment_status"] : "-").replaceAll("null", "-") + "," + "\n";
    //     giftVoucherData += line;
    //   });
  
    //   var blob = new Blob([giftVoucherData], { type: "csv/plain" });
    //   var date = new Date().toLocaleString();
    //   let link = document.createElement("a");
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = this.$auth.setting.driver_name + "_" + date + ".csv";
    //   link.click();
    // },
    getGiftVouchers(page) {
      this.loading = true;
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      };
      giftVoucherService.getGiftVoucherService(filters).then((response) => {
        this.loading = false;
        this.paginations = response.data.result;
        this.giftVouchers = response.data.result.data;
        this.giftVouchersCount = response.data.result.total;
      });
    },
    // giftVoucherExportCsv() {
    //   giftVoucherService
    //     .giftVoucherExportCsv({
    //       status: this.status,
    //       sortBy: this.sortBy,
    //       orderBy: this.sortDesc ? "desc" : "asc",
    //     })
    //     .then((response) => {
    //       this.giftVoucherData = response.data.result.data;
    //       this.downloadsCSV()
    //     });
    // },

    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getGiftVouchers();
    },
    changeStatus(status, id){
      giftVoucherService.changeGiftVoucherStatus({id: this.base64(id),status})
        .then(res => {
          if(res.data.code == 200){
            success_message(res.data.message)
            return true;
          }
          error_message(res.data.message)
        })
    }
  },
};
</script>

<template>
  <div>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-card"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Gift Vouchers', 'Gift Vouchers')
            }}({{ giftVouchersCount }})</h4>
          </div>
          <div class="page-title-right d-flex">
            <router-link :to="{name: 'gift-voucher-add'}" class="btn btn-success btn-rounded export-btn mx-4">{{
                trans.get('__JSON__.Add Gift Voucher')
            }}</router-link>
            <!-- <button  class="btn btn-primary btn-rounded export-btn">{{
                trans.get('__JSON__.Export To CSV')
            }}</button> -->
            <div class="d-flex m-vendor-btn">

              <button  class="btn btn-primary btn-rounded m-export-btn">
                <i class="bx bx-archive-in"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3 offset-sm-9">
                <div>
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  
                  <div class="search-box mr-2">
                    <div class="position-relative">
                      <input type="text" class="form-control" id="search" @input="searchData()" v-model="search"
                        :placeholder="this.trans.get('__JSON__.Search')" />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list  table-responsive">
                <b-table :items="giftVouchers && giftVouchers || []" :fields="fields" :sort-by.sync="sortBy"
                  :no-local-sorting="true" :sort-desc.sync="sortDesc" @sort-changed="sortingChanged" :busy="loading"
                  show-empty @row-clicked="onRowClicked">
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
                    </div>
                  </template>
                  <template v-slot:cell(full_image)="data">
                    <img :src="data.value" @error="replaceByDefault" alt class="rounded avatar-md" />
                  </template>
                  <template v-slot:cell(created_by)="data">
                    {{data.value.vendor_name}}
                  </template>
                  <template v-slot:cell(status)="data">
                    <b-form-checkbox switch size="lg" value='1' @change="changeStatus($event, data.item.id)" unchecked-value='0' v-model="data.value"></b-form-checkbox>
                  </template>
                  <template v-slot:cell(action)="data">
                    <router-link
                        :to="{name: 'gift-voucher-edit',params: { voucher_id: base64(data.item.id)}}"
                        class="btn btn-primary btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Data Found',{field:trans.get('__JSON__.Gift Vouchers') }) }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0" v-if="paginations">
                      <!-- pagination -->
                      <pagination :data="paginations" :limit="limit" @pagination-change-page="getGiftVouchers">
                      </pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>