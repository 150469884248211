<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { webLayoutService } from '../../../services';
import { success_message, error_message, get_user } from '../../../Helper/helper';
import config from '../../../config';
import DropzonePopup from "../../../components/widgets/dropzone-popup";
import { routes } from "../../../config/api_routes";

/**
 * Starter component
 */
export default {
  components: { DropzonePopup },
  data() {
    return {
      config:config,
      sortBy: "id",
      sortDesc: true,
      webform: [],
      loading: true,
      addModal: false,
      editModal: false,
      vendor_id: '',
      fields: [
        { key: "image", label: "Image", sortable: false },
        { key: "heading", label: "Heading", sortable: false },
        { key: "description", label: "Description", sortable: false },
        { key: "link", label: "Link", sortable: false },
        { key: "action", label: "Action", sortable: false }
      ],
      web_form:{
        id: '',
        image: '',
        heading: '',
        link: '',
        description:''
      },
      front_web: {
        id: '',
        image: '',
        heading: '',
        link: '',
        description:''
      },
      web: {
        home_page_title: '',
        home_page_image: '',
        front_images: [{ image: "", heading: "", description: "",link: "" }],
        facebook_link: '',
        twitter: '',
        instagram: ''
      },
      web_home_page_image: "",
      web_home_page_image_path: "",
      web_home_page_image_delete_url: routes.deleteWebHomePageApi,
      section_image: "",
      edit_section_image: "",
      section_image_path: "",
      section_image_delete_url: routes.deleteSectionImageApi,
    };
  },
  validations: {
    web: {}
  },
  mounted() {
    this.getWebSetting();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  methods: {
    onWebHomePageImagedeleted(value) {
      this.web_home_page_image = "";
    },
    onWebSectionImagedeletedAddForm(value) {
      this.section_image = "";
    },
    onWebSectionImagedeletedEditForm(value) {
      this.edit_section_image = "";
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getWebSetting();
    },
    editSection(id) {
      return webLayoutService.getWebSetting().then((response) => {
        let result = response.data.result;
        if (result.front_images.length > 0) {
          let find_data = result.front_images.find(a => a.id == id);
          if (find_data){
            this.front_web = find_data;
            this.edit_section_image = find_data.image;
            this.front_web.image = "";
            this.section_image_path = response.data.result.section_image_path;
            this.editModal = true;
          }
        } else {
          error_message('data not found');
        }
      });
    },
    getWebSetting(){
      webLayoutService.getWebSetting().then((response) => {
        this.loading = false;
        this.web = response.data.result;
        this.web_home_page_image = response.data.result.home_page_image;
        this.web.home_page_image = "";
        this.web_home_page_image_path = response.data.result.web_home_page_image_path;
        this.section_image_path = response.data.result.section_image_path;
      });
    },
    deleteById(vendor_id,id) {
      if (confirm("Are you sure you want to delete?")) {
        webLayoutService.deleteFrontImage({ vendor_id: get_user().vendor_id || 40818, id: id }).then((response) => {
          success_message(response.data.message);
          this.getWebSetting();
        });
      }
    },
    updateHomePageMainSection(){
      this.$v.web.$touch();
      if (this.$v.web.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("home_page_title", this.web.home_page_title);
        fd.append("home_page_image", this.web.home_page_image);
        fd.append("is_section", 0);
        fd.append('type',6);
        webLayoutService.updateWebSetting(fd).then((response) => {
          this.web_form = {
            image: "",
            heading: "",
            link: "",
            description: "",
          };
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("addModal");
            this.getWebSetting();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    updateHomePageFooterSection() {
      this.$v.web.$touch();
      if (this.$v.web.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("facebook_link", this.web.facebook_link);
        fd.append("twitter", this.web.twitter);
        fd.append("instagram", this.web.instagram);
        fd.append("is_section", 2);
        fd.append('type',6);
        webLayoutService.updateWebSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getWebSetting();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    addHomePageSection() {
      if (this.web_form.image != '' || this.web_form.heading || this.web_form.link || this.web_form.description) {
        const fd = new FormData();
        fd.append("image",this.web_form.image);
        fd.append("heading",this.web_form.heading);
        fd.append("link",this.web_form.link);
        fd.append("description",this.web_form.description);
        fd.append("is_section", 1);
        fd.append('type',6);
        webLayoutService.updateWebSetting(fd).then((response) => {
          this.web_form = {
            image: "",
            heading: "",
            link: "",
            description: "",
          };
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("addModal");
            this.getWebSetting();
          } else {
            error_message(response.data.message);
          }
        });
      } else {
        error_message("Please fill any one detail to add");
      }
    },
    updateIt(){
      if (this.front_web.image != '' || this.front_web.heading || this.front_web.link || this.front_web.description) {
        const fd = new FormData();
        fd.append("front_image_id",this.front_web.id);
        fd.append("image",this.front_web.image);
        fd.append("heading",this.front_web.heading);
        fd.append("link",this.front_web.link);
        fd.append("description",this.front_web.description);
        fd.append("is_section", 1);
        fd.append('type',6);
        webLayoutService.updateWebSetting(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("editModal");
            this.getWebSetting();
          } else {
            error_message(response.data.message);
          }
        });
      } else {
        error_message("Please fill any one detail to update");
      }
    },
    sectionClose(e) {
      this.addModal = false;
      this.web_form = {
        image: "",
        heading: "",
        link: "",
        description: "",
      };
      this.$v.web_form.$reset();
    },
    editSectionClose(e) {
      this.editModal = false;
      this.front_web = {
        id: '',
        image: '',
        heading: '',
        link: '',
        description:''
      };
      this.edit_section_image = "";
    },
    openAddSectionModle() {
      this.web_form = {
        image: "",
        heading: "",
        link: "",
        description: "",
      };
      this.section_image = "";
      this.addModal = true;
    }
  }
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body setting-main">
          <div role="tablist" class="cust-accordian">
            <b-card no-body class="mb-0 shadow-none">
               <a
                  v-b-toggle.accordion-1
                  class="text-dark"
                  href="javascript: void(0);"
                >
                  <b-card-header header-tag="header" role="tab">
                     <h6 class="m-0">
                      {{ trans.get('__JSON__.Section #1') }}
                      </h6>
                  </b-card-header>
              </a>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <b-form @submit.prevent="updateHomePageMainSection">
                        <div class="row">
                          <div class="col-md-6 order-value">
                            <b-form-group id="input-group-1" :label="trans.get('__JSON__.Heading')" label-for="name">
                              <b-form-input
                                id="home_page_title"
                                name="home_page_title"
                                v-model="web.home_page_title"
                                type="text"
                                :placeholder="trans.get('__JSON__.Enter Heading')"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6 order-value">
                            <b-form-group id="input-group-2" :label="trans.get('__JSON__.Background Image')" label-for="image">
                              <dropzone-popup
                                v-model="web.home_page_image"
                                id="home_page_image"
                                class="business-logo"
                                acceptedFiles=".jpeg,.jpg,.png"
                                :editable-images="web_home_page_image"
                                :media-path="web_home_page_image_path"
                                :delete-path="web_home_page_image_delete_url"
                                @image-deleted="this.onWebHomePageImagedeleted"
                              />
                            </b-form-group>
                          </div>
                        </div>
                        <div class="btn-update text-right">
                          <div class="col-sm-12">
                            <button
                                type="submit"
                                v-if="$auth.hasPermission(config.permissions.configuration_write)"
                                class="btn btn-primary ml-3 mt-3"
                            >{{ trans.get('__JSON__.Update') }}</button>
                          </div>
                        </div>
                      </b-form>
                    </b-card-text>
                  </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-0">
               <a
                  v-b-toggle.accordion-2
                  class="text-dark"
                  href="javascript: void(0);"
                >
                  <b-card-header header-tag="header" role="tab">
                     <h6 class="m-0">
                      {{ trans.get('__JSON__.Section #2') }}
                      </h6>
                  </b-card-header>
              </a>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                      <b-card-text>
                          <div class="row">
                              <div class="col-md-12">
                              <div class="text-sm-right">
                                  <button
                                  type="button"
                                  class="btn btn-success btn-rounded mr-2"
                                  @click="openAddSectionModle"
                                  >
                                  <!-- v-b-modal.addModal -->
                                  <i class="mdi mdi-plus mr-1"></i>
                                  {{ trans.get('__JSON__.Add') }}
                                  </button>
                              </div>
                              </div>
                          </div>
                          <div class="">
                           <div class="col-12 table-main-list table-responsive">
                           <b-table
                                  :items="web.front_images"
                                  :fields="fields"
                                  :sort-by.sync="sortBy"
                                  :no-local-sorting="true"
                                  @sort-changed="sortingChanged"
                                  :busy="loading"
                                  show-empty
                              >
                                  <template #table-busy>
                                  <div class="text-center text-danger my-2">
                                      <b-spinner class="align-middle"></b-spinner>
                                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                                  </div>
                                  </template>

                                  <template v-slot:cell(image)="data">
                                  <!-- <div v-if="data.item.image"> -->
                                      <img
                                      alt
                                      title="product-img"
                                      class="avatar-md rounded-circle-banner"
                                      :src="section_image_path+'/'+data.item.image"
                                      @error="$event.target.src=config.no_image"
                                      />
                                  <!-- </div> -->
                                  </template>

                                  <template v-slot:cell(heading)="data">
                                  <h5 class="font-size-14">
                                      <div v-if="data.item.heading && data.item.heading.length > 25">{{data.item.heading.substr(0, 25)+ '...'}}</div>
                                      <div v-else>{{data.item.heading}}</div>
                                  </h5>
                                  </template>

                                  <template v-slot:cell(description)="data">
                                  <div v-if="data.item.description && data.item.description.length > 25">{{data.item.description.substr(0, 25)+ '...'}}</div>
                                  <div v-else>{{data.item.description}}</div>
                                  </template>

                                  <template v-slot:cell(link)="data">
                                  <div>{{(data.item.link != 'null') ? data.item.link : ''}}</div>
                                  </template>

                                  <template v-slot:cell(action)="data">
                                  <button
                                      type="button"
                                      @click="editSection(data.item.id)"
                                      class="btn btn-primary btn-sm btn-rounded"
                                  >{{ trans.get('__JSON__.Edit')}}</button>
                                  <button
                                      type="button"
                                      @click="deleteById(vendor_id,data.item.id)"
                                      class="btn btn-danger btn-sm btn-rounded"
                                  >{{ trans.get('__JSON__.Delete')}}</button>
                                  </template>

                                  <template #empty>
                                  <p class="text-center">{{ trans.get('__JSON__.No Section Found') }}</p>
                                  </template>

                              </b-table>
                           </div>
                          </div>
                      </b-card-text>
                  </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-0">
              <a
                v-b-toggle.accordion-3
                class="text-dark"
                href="javascript: void(0);"
              >
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    {{ trans.get('__JSON__.Footer Section') }}
                  </h6>
                </b-card-header>
              </a>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <b-form @submit.prevent="updateHomePageFooterSection">
                      <div class="row">
                        <div class="col-md-4 order-value">
                          <b-form-group id="input-group-1" :label="trans.get('__JSON__.Facebook Link')" label-for="name">
                            <b-form-input
                            id="facebook_link"
                            name="facebook_link"
                            v-model="web.facebook_link"
                            type="text"
                            :placeholder="trans.get('__JSON__.Enter Facebook Link')"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-4 order-value">
                          <b-form-group id="input-group-1" :label="trans.get('__JSON__.Twitter')" label-for="name">
                            <b-form-input
                              id="twitter"
                              name="twitter"
                              v-model="web.twitter"
                              type="text"
                              :placeholder="trans.get('__JSON__.Enter Twitter')"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-4 order-value">
                          <b-form-group id="input-group-1" :label="trans.get('__JSON__.Instagram')" label-for="name">
                            <b-form-input
                              id="instagram"
                              name="instagram"
                              v-model="web.instagram"
                              type="text"
                              :placeholder="trans.get('__JSON__.Enter Instagram')"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="btn-update text-right">
                        <div class="col-sm-12">
                          <button
                            type="submit"
                            v-if="$auth.hasPermission(config.permissions.configuration_write)"
                            class="btn btn-primary ml-3 mt-3"
                          >{{ trans.get('__JSON__.Update') }}</button>
                        </div>
                      </div>
                    </b-form>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>

        <!-- Add Modal Start -->
        <b-modal
          id="addModal"
          v-model="addModal"
          scrollable
          :title="trans.get('__JSON__.Add Section')"
          title-class="font-18"
        >
          <b-form @submit.prevent="addHomePageSection">
            <div class="row">
              <div class="col-md-12">
                <label class="card-title">{{ trans.get('__JSON__.Image') }}</label>
                <dropzone-popup
                  v-model="web_form.image"
                  class="business-logo"
                  acceptedFiles=".jpeg,.jpg,.png"
                  :editable-images="section_image"
                  :media-path="section_image_path"
                  :delete-path="section_image_delete_url"
                  @image-deleted="this.onWebSectionImagedeletedAddForm"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <b-form-group id="input-group-1" :label="trans.get('__JSON__.Heading')" label-for="name">
                  <b-form-input
                    name="heading"
                    v-model="web_form.heading"
                    type="text"
                    :placeholder="trans.get('__JSON__.Enter Heading')"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group id="input-group-1" :label="trans.get('__JSON__.Link')" label-for="name">
                  <b-form-input
                    name="link"
                    v-model="web_form.link"
                    type="text"
                    :placeholder="trans.get('__JSON__.Link')"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group id="input-group-1" :label="trans.get('__JSON__.Description')" label-for="name">
                  <textarea
                    rows="5" 
                    name="description" 
                    type="text" 
                    v-model="web_form.description" 
                    placeholder="Enter Description" 
                    class="form-control"
                  />
                </b-form-group>
              </div>
            </div>
          </b-form>
          <template slot="modal-footer">
            <div class="w-100">
              <b-button variant="primary"  class="float-right" @click="addHomePageSection">{{ trans.get('__JSON__.Add') }}</b-button>
              <b-button class="float-left" @click="sectionClose($event)">{{ trans.get('__JSON__.Close') }}</b-button>
            </div>
          </template>
        </b-modal>
        <!-- Add Modal End -->

        <!-- Edit Modal Start -->
        <b-modal
          id="editModal"
          v-model="editModal"
          scrollable
          :title="trans.get('__JSON__.Edit Section')"
          title-class="font-18"
        >
          <b-form @submit.prevent="updateIt">
            <div class="row">
              <div class="form-group">
                <input id="id" name="id" type="hidden" v-model="front_web.id" class="form-control" />
              </div>
              <div class="col-md-12">
                <label class="card-title">{{ trans.get('__JSON__.Image') }}</label>
                <dropzone-popup
                  v-model="front_web.image"
                  class="business-logo"
                  acceptedFiles=".jpeg,.jpg,.png"
                  :editable-images="edit_section_image"
                  :media-path="section_image_path"
                  :delete-path="section_image_delete_url"
                  @image-deleted="this.onWebSectionImagedeletedEditForm"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <b-form-group id="input-group-1" :label="trans.get('__JSON__.Heading')" label-for="name">
                  <b-form-input
                    name="heading"
                    v-model="front_web.heading"
                    type="text"
                    :placeholder="trans.get('__JSON__.Enter Heading')"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group id="input-group-1" :label="trans.get('__JSON__.Link')" label-for="name">
                  <b-form-input
                    name="link"
                    v-model="front_web.link"
                    type="text"
                    :placeholder="trans.get('__JSON__.Link')"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group id="input-group-1" :label="trans.get('__JSON__.Description')" label-for="name">
                  <textarea
                    rows="5" 
                    name="description" 
                    type="text" 
                    v-model="front_web.description" 
                    placeholder="Enter Description" 
                    class="form-control"
                  />
                </b-form-group>
              </div>
            </div>
          </b-form>
          <template slot="modal-footer">
            <div class="w-100">
              <b-button variant="primary"  class="float-right" @click="updateIt">{{ trans.get('__JSON__.Update') }}</b-button>
              <b-button class="float-left"  @click="editSectionClose($event)">{{ trans.get('__JSON__.Close') }}</b-button>
            </div>
          </template>
        </b-modal>
        <!-- Edit Modal End -->
      </div>
    </div>
  </div>
</template>