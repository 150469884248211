<script>
import { vendorService } from "../../services";
import { get_currency } from '../../Helper/helper';
export default {
  data() {
    return {
      category_count:'',
      category_id: '',
      category : [],
      menu_id: 1,
      menu: [],
      search: '',
      loading: true,
      item_count: '',
      restaurant_menu_id: '',
      item_count:'',
      currency: get_currency(),
      timer: ''
    };
  },
  created (){
    this.getMenuCategories();
    this.timer = setInterval(this.getMenuCategories, 1000);
    this.getmenu();
    this.timer = setInterval(this.getmenu, 1000);
  },
  method:{
    changeMenu(id){
      this.menu_id = id;
      this.restaurant_menu_id = id;
      this.listitembyid(1);
    },
    listitembyid(page=1) {
        this.loading = true;
        vendorService.getItemByMenuID({ 
          menu_id: this.menu_id,
          search: this.search,
          page: page && page > 0 ? page : 1,
          restaurant_id: localStorage.getItem('restaurant_id'),
          category_id : this.category_id
        }).then((response) => {
          this.loading = false;
          this.menu = response.data.result.get_restaurant_menu;
        });
     },
     getmenu() {
      vendorService
      .getRestaurantMenu({ restaurant_id: localStorage.getItem('restaurant_id'), category_id : this.category_id })
      .then((response) => {
        this.menu = response.data.result;
        if( response.data.result.length > 0){
          this.menu_id = this.menu[0].id;
          this.restaurant_menu_id = this.menu[0].id;
          this.listitembyid();
        }else{
          this.menu_id = '';
          this.listitembyid();
        }
      });
    },
    getMenuCategories() {
      console.log(localStorage.getItem('restaurant_id'));
      vendorService
        .getMenuCategories({ restaurant_id: localStorage.getItem('restaurant_id') })
        .then((response) => {
          this.category_count = response.data.result.length;
          this.category = response.data.result;
        });
    },
    cancelAutoUpdate () {
            clearInterval(this.timer);
    }
  },
  beforeDestroy () {
    this.cancelAutoUpdate();
  }
};
</script>

<template>
    <div class="drag-container">
      <div class="row drag-list menu-left-main">
        <div class="col-lg-4 drag-column menu-box-main">
          <div class="card">
            <div class="card-body menu-body">
              <div class="menu-box">
                <!-- dropdown -->
                <div class="menu-add">
                  <span class="drag-column-header">
                    <h4 class="card-title mb-4 pb-1">Menu</h4>
                  </span>
                </div>

                <div class="mt-3" v-if="category_count > 0">
                  <div class="form-group">
                    <!-- <label class="control-label">{{ trans.get('__JSON__.Category') }}</label> -->
                      <select class="custom-select" v-model="category_id">
                        <option value="" selected>All</option>
                       <option v-for="con in category" :value="con.id" :key="con.id">{{con.name}}</option>
                      </select>
                  </div>
                </div>

                <draggable class="list-group menu-task" group="menus" :list="menu" v-model="menu">
                  <template v-for="menus in menu">
                  <div  
                      :key="menus.id"
                      class="card task-box"
                      v-on:click="changeMenu(menus.id)"
                     :class="{ 'active': menu_id === menus.id }"
                      v-if="(search !== '' && menus.item_count !== 0) || (search == '')"
                    >
                    <div class="card-body">
                      <div>
                        <h5 class="font-size-15 title">{{ menus.menu_name }}({{menus.item_count}})</h5>
                      </div>
                      <p
                        class="badge badge-pill font-size-12" v-bind:id="menus.id" 
                        :class=" { 
                        'badge-soft-success': (menus.status == '1'),
                        'badge-soft-danger': (menus.status == '0' )}"
                        >
                        <span v-if="menus.status == '0'">InActive</span>
                        <span v-else>Active</span>
                      </p>
                    </div>
                  </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->

        <div class="col-lg-8 drag-column">
          <div class="card">
            <div class="card-body catalog-main">
              <div class="catalog-item">
                <span class="drag-column-header">
                  <h4 class="card-title">Item</h4>
                </span>
                <div class="item-menu-main">
                  <div class="search-box mr-2 mb-2 d-inline-block">
                    <div class="position-relative">
                      <input type="text" class="form-control" id="search" v-model="search" :placeholder="this.trans.get('__JSON__.Search')" />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="left-card">
                <!-- dropdown -->
                <draggable class="list-group">
                  <!-- <div class="col-12">
                    <div class="text-center text-danger my-2" v-if="loading">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </div> -->

                  <div class="card task-box">
                    <div class="card-body menu-items">
                      <div class="media">
                        <div class="avatar-md mr-4">
                          <span class="text-danger font-size-16">
                            <img class="vendor-img avatar-xs" alt height="30"/>
                          </span>
                        </div>
                        <div class="veg"><span></span></div>
                        <div class="media-body overflow-hidden">
                          <h5 class="font-size-15">Washing Machine</h5>
                          <!-- <p class="text-truncate mb-4 overflow-hidden">Description</p> -->
                          <ul class="list-inline">
                            <li v-b-tooltip.hover.top class="list-inline-item">Qty : 10</li>
                              <li v-b-tooltip.hover.top class="list-inline-item">
                                <span v-html="currency"></span>
                                200
                              </li>
                          </ul>
                        </div>
                        <div class="task-status catalog-task-status newset-media">
                          
                         <div class="cst-swtch-set">
                            <div class="list-available">
                              <span class="badge badge-pill font-size-12 badge-soft-primary">Customized</span>
                            </div>
                          </div>

                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <div class="card task-box">
                    <div class="card-body menu-items">
                      <div class="media">
                        <div class="avatar-md mr-4">
                          <span class="text-danger font-size-16">
                            <img class="vendor-img avatar-xs" alt height="30"/>
                          </span>
                        </div>
                        <div class="veg"><span></span></div>
                        <div class="media-body overflow-hidden">
                          <h5 class="font-size-15">Microwave Oven</h5>
                          <!-- <p class="text-truncate mb-4 overflow-hidden">Description</p> -->
                          <ul class="list-inline">
                            <li v-b-tooltip.hover.top class="list-inline-item">Qty : 48</li>
                              <li v-b-tooltip.hover.top class="list-inline-item">
                                <span v-html="currency"></span>
                                100
                              </li>
                          </ul>
                        </div>
                        <div class="task-status catalog-task-status newset-media">
                          
                         <div class="cst-swtch-set">
                            <div class="list-available">
                              <span class="badge badge-pill font-size-12 badge-soft-primary">Customized</span>
                            </div>
                          </div>
                           
                        </div>
                        
                      </div>
                    </div>
                  </div>

                </draggable>
                <div v-if="item_count == 0 && loading == false">
                  <p class="text-center">{{ trans.get('__JSON__.No Search Result Found') }}</p>
                </div>
              </div>
            </div>
          </div>  
        </div>

        <!-- end col-->
      </div>

    </div>
    <!-- end row -->
</template>