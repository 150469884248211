import { routes } from '../config/api_routes'

export const giftVoucherService = {

    getGiftVoucherService: (formData) => window.axios.post(routes.getGiftVoucherApi,formData),
    getPurchasedGiftVoucherservice: (formData) => window.axios.post(routes.getPurchasedGiftVoucherApi,formData),
    addGiftVoucher: (formData) => window.axios.post(routes.addGiftVoucherApi,formData),
    updateGiftVoucher: (formData) => window.axios.post(routes.updateGiftVoucherApi,formData),
    getGiftVoucher: (id) => window.axios.post(routes.getGiftVoucherByIdApi,{id}),
    changeGiftVoucherStatus: (data) => window.axios.patch(routes.changeGiftVoucherStatusApi,data),
    exportGiftVouchers: (data) => window.axios.get(routes.exportGiftVouchersApi,{params:data,responseType: 'blob'}),
}