<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import General from './app-layout-setting/general.vue';
import HomePage from './app-layout-setting/home-page.vue';
import CategoryPage from './app-layout-setting/category-page.vue';
import VendorListingPage from './app-layout-setting/vendor-listing-page.vue';
import VendorDetailPage from './app-layout-setting/vendor-detail-page.vue';
import LoginPage from './app-layout-setting/login-detail-page.vue';
import config from '../../config';

/** 
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, General, HomePage, CategoryPage, VendorListingPage, VendorDetailPage, LoginPage },
  data() {
    return {
      config: config
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-layout"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.App Layout Settings') }}</h4>
          </div>
        </div>
      </div>
    </div>
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <!-- Tab panel start-->
                  <b-tabs justified lazy nav-class="nav-tabs-custom mt-3 order-tabs" content-class="p-3 text-muted">
                      <b-tab active>
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="fas fa-home"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.General') }}</span>
                        </template>
                        <General/>
                      </b-tab>
                      <b-tab >
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="fas fa-sign-in-alt"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Login Page') }}</span>
                        </template>
                        <LoginPage/>
                      </b-tab>
                      <b-tab v-if="$auth.setting.business_category == 'Both'">
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="far fa-user"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Home Page') }}</span>
                        </template>
                        <HomePage/>
                      </b-tab>
                      <b-tab>
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="far fa-envelope"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Category Page') }}</span>
                        </template>
                        <CategoryPage/>
                      </b-tab>
                      <b-tab v-if="$auth.setting.is_single_restaurant == 'No'">
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="fas fa-cog"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">{{ trans.trans('__JSON__.Vendor Listing Page', { vendor: $auth.setting.restaurant_name }) }}</span>
                        </template>
                      <VendorListingPage/>
                      </b-tab>
                      <b-tab>
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="far fa-envelope"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Vendor Details Page',{ vendor: $auth.setting.restaurant_name }) }}</span>
                        </template>
                      <VendorDetailPage/>
                      </b-tab>
                    </b-tabs>
                      <!-- Tab panel end -->
                </div>
            </div>
        </div>
    </div>

  </Layout>
</template>