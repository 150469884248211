<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import config from "../../config";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days") : this.moment().startOf('day').subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal:get_decimal(),
      config:config,
      currency: get_currency(),
      dateRange: { startDate, endDate },
      reports: [],
      reportsData: [],
      paginations: {},
       countries: [],
      country: "0",
      states: [],
      state: "0",
      cities: [],
      city: "0",
      country_count: "",
      state_count: "",
      city_count: "",
      date_ranges: date_ranges,
      restaurant: [],
      restaurant_count: "",
      order_status: "",
      restaurants_id: "",
      limit: 2,
      perPage: 0,
      currentPage: 1,
      payment_method: "",
      filters: {
        start_date: this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD') : this.moment().startOf('day').subtract(6, "days").format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      sortBy: "created_at",
      moment: moment,
      loading: true,
      exporting: false,
      fields: [
        { key: "table_id", label: "#", sortable: false},
        { key: "user_name", label: "Customer Name", sortable: false},
        { key: "mobile_number", label: "Customer Number", sortable: false},
        { key: "email", label: "Customer Email", sortable: false},
        { key: "referred_to", label: "Reference Customer Name", sortable: false},
        // { key: "created_at", label: "Created At", sortable: false},
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);
        
      // If the user IS an admin, return all fields.
      else 
        return this.fields;
    }
  },
  mounted() {
    this.getReferEarnReports();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD'),
      }
      this.getReferEarnReports();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD")
      };
    },
    downloadsCSV: function () {

        let reportsData =
          '\ufeff' +
          '#,Customer Name,Customer Number,Customer Email,Reference Customer Name\n';
        this.reportsData.forEach((el,index) => {
          var line =
            (index+1) +
            "," +(el["user_name"])+
            "," +el["mobile_number"] +
            "," +el["email"] +
            "," +el["referred_to"].map(re => re.user_name +'(CHOWC'+(re.unique_code && re.user_id) +')' ).join(' - ') +
            "\n";
          reportsData += line;
        });

        var blob = new Blob([reportsData], { type: "csv/plain" });
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "sales_" + date + ".csv";
        link.click();
   
    },
  
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getReferEarnReports(page) {
      this.loading = true;
      if(this.$auth.partner_login)
      {
          reportService
        .getReferEarnReports({
          ...this.filters,
          payment_method: this.payment_method,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data;
          this.reports = response.data.result;

        });
      }
      else
      {
        reportService
          .getReferEarnReports({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            page: page ? page : 1,
          })
          .then((response) => {
            this.loading = false;
            this.paginations = response;
            this.reports = response.data.result;
          });
      }
    },
    referEarnExportCsv() {
      this.exporting = true;
      if(this.$auth.partner_login)
      {
          reportService
          .referEarnExportCsv({ 
            ...this.filters,
            payment_method: this.payment_method,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
            // page: page ? page : 1,
          })
          .then((response) => {
            this.exporting = false;
            this.reportsData = response.data.result;
            this.downloadsCSV();
          });
      }
      else
      {
          reportService
          .referEarnExportCsv({ 
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            // page: page ? page : 1,
          })
          .then((response) => {
            this.exporting = false;
            this.reportsData = response.data.result;
            this.downloadsCSV();
          });
      }
    },
    searchData() {
      this.getReferEarnReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getReferEarnReports();
    },
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : "";
      },
    },
};
</script>

<template>
  <Layout>
    <div>
       <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag" v-if="!$auth.partner_login">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Refer and Earn Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Refer and Earn Report') }}</p>
                  </div>
                  <div class="report-tag" v-else>
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Refer and Earn Report') }}</h4>
                    <p><router-link :to="{ name: 'vendor.reports.list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Refer and Earn Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="referEarnExportCsv" v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)" class="btn btn-primary btn-rounded" :disabled="exporting">{{ trans.get(!exporting?'__JSON__.Export To CSV':'__JSON__.Exporting') }}</button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row" v-if="$auth.partner_login">
                <div class="col-sm-2 offset-sm-6">
                  <div class="form-group">
                   
                  </div>
                  <!-- <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="payment_method" @change="searchData()">
                        <option value selected>{{ trans.get('__JSON__.All') }}</option>
                        <option value="Cash On Delivery">{{ trans.get('__JSON__.COD') }}</option>
                        <option value="Online">{{ trans.get('__JSON__.Online') }}</option>
                      </select>
                    </div>
                  </div> -->
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        @update="updateValues"
                      >
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- <div class="row" v-if="!$auth.partner_login">
                <div class="col-sm-4 offset-sm-8">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        @update="updateValues"
                      >
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row table-responsive">
                <div class="col-12 table-main-list table table-centered table-nowrap sales-report-table">
                  <b-table
                    :items="reports && reports.data"
                    :fields="computedFields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(table_id)="data">
                        <div>{{ reports.from + data.index }}</div>
                    </template>
                    <template v-slot:cell(user_name)="data">
                        <span>{{ data.item.user_name }} {{ data.item.last_name }}</span>
                    </template>
                    <template v-slot:cell(mobile_number)="data">
                      <div>{{data.value}}</div>
                    </template>
                    <template v-slot:cell(email)="data">
                      <div>{{data.value}}</div>
                    </template>
                    <template v-slot:cell(referred_to)="data">
                      <div>{{data.value.map(re => re.user_name +'(CHOWC'+(re.unique_code && re.user_id) +')' ).join(',')}}</div>
                    </template>
                   
                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Sales Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7" v-if="reports && reports.data">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="reports"
                          :limit="limit"
                          @pagination-change-page="getReferEarnReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.sales-report-table .table thead th:nth-child(1),
.sales-report-table .table thead th:nth-child(2),
.sales-report-table .table thead th:nth-child(3),
.sales-report-table .table thead th:nth-child(9),
.sales-report-table .table thead th:nth-child(13),
.sales-report-table .table thead th:nth-child(15),
.sales-report-table .table thead th:nth-child(16){
    pointer-events: none;
}
</style>