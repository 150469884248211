
<script>
import vue2Dropzone from 'vue2-dropzone'
import config from "../../config";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { commonService, driverService, userService } from '../../services';
import { set_axios_defaults, get_user, error_message, success_message} from '../../Helper/helper';
import DropzonePopup from '../../components/widgets/dropzone-popup';
import {
    required,
    email,
    minLength,
    sameAs,
    maxLength,
    minValue,
    maxValue,
    numeric,
    url,
    alphaNum
} from "vuelidate/lib/validators";
import { routes } from '../../config/api_routes';

export default {
  components: { vueDropzone: vue2Dropzone,VueGoogleAutocomplete, DropzonePopup },
  data() {
    return {
      config:config,
      tmpProfileImage: '',
        value: null,
        value1: null,
        zones:[],
        typeform: 
        {
          firstname: "",
          lastname: "",
          email: "",
          contactno: "",
          alternate_contactno: "",
          frenchise_id: "",
          address: "",
          firstname_other_lang: "",
          lastname_other_lang: "",
          vehicle_type: "",
          vehicle_number: "",
          licence_number: "",
          vehicle_photo: [],
          vehicle_document: [],
          profileimage: "",
          city: "",
          state: "",
          zip_code: "",
          country: "",
          latitude:"",
          longitude: "",
          job_type: "full_time",
        },
      typesubmit: false
    };
  },
  validations: {
    typeform: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      },
      contactno: {
        required,
        numeric
      },
      frenchise_id: {
        required
      },
      job_type: {
          required
      },
      // address: {
      //   required
      // },
      vehicle_type: {
        required
      },
    }
  },
  mounted(){
    this.getFrenchise();
    this.$refs.address.focus();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  methods: {
    userCheckEmail(e) {
      userService.userCheckEmail({
        email:this.typeform.email,
        table:'User',
        column:'driverid',
        vendor_id:this.vendor_id,
        user_role:'3',
        table_name:'Driver'
      })
      .then(response => {
        if (response.data.code === 101) {
          error_message(response.data.message);
        }
      });
    },
    
    getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
        
        this.typeform.address = placeResultData.formatted_address;
        this.typeform.city = addressData.locality;
        this.typeform.state = addressData.administrative_area_level_1;
        this.typeform.country = addressData.country;
        this.typeform.latitude = addressData.latitude;
        this.typeform.longitude = addressData.longitude;
        this.typeform.zip_code = addressData.postal_code;
    },
    getFrenchise(){
        driverService.getFrenchise()
        .then(response => {
            this.zones =  response.data.result;
        });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }
      else{       
        driverService.addDriver(this.typeform)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "driver-list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  }
}
</script>

<template>
  <div>
    <div class="row">
     <div class="col-12">
       <form action="#" @submit.prevent="typeForm">

      <!-- Driver Info Start -->
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ trans.get('__JSON__.Personal Information') }}</h4>
            <p class="card-title-desc"></p>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group" v-if="$auth.setting">
                  <label class="required">{{ trans.trans('__JSON__.First Name Primary Language',{lang: $auth.langauges[0].name}) }}</label>
                  <input id="firstname" v-model="$v.typeform.firstname.$model" type="text" class="form-control" :placeholder="trans.trans('__JSON__.First Name Primary Language',{lang: $auth.langauges[0].name})" name="firstname" :class="{ 'is-invalid':$v.typeform.firstname.$error }" />
                    <div v-if="$v.typeform.firstname.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.firstname.required">{{ trans.get('__JSON__.Please enter first name') }}</span>
                    </div>
                </div>
              </div>

              <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
              </div>
              <div class="col-sm-6" v-else>
                <div class="form-group">
                  <label>{{ trans.trans('__JSON__.First Name Secondary Language',{lang: $auth.langauges[1].name}) }}</label>
                  <input id="firstname_other_lang" name="firstname_other_lang" v-model="typeform.firstname_other_lang" type="text" :placeholder="trans.trans('__JSON__.First Name Secondary Language',{lang: $auth.langauges[1].name})" class="form-control" />
                </div>
              </div>
              
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.trans('__JSON__.Last Name Primary Language',{lang: $auth.langauges[0].name}) }}</label>
                  <input id="lastname" v-model="$v.typeform.lastname.$model" type="text" class="form-control" :placeholder="trans.trans('__JSON__.Last Name Primary Language',{lang: $auth.langauges[0].name})" name="lastname" :class="{ 'is-invalid':$v.typeform.lastname.$error }" />
                    <div v-if="$v.typeform.lastname.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.lastname.required">{{ trans.get('__JSON__.Please enter last name') }}</span>
                    </div>
                </div>
              </div>
              
              <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
              </div>
              <div class="col-sm-6" v-else>
                <div class="form-group">
                  <label>{{ trans.trans('__JSON__.Last Name Secondary Language',{lang: $auth.langauges[1].name}) }}</label>
                  <input id="lastname_other_lang" name="lastname_other_lang" v-model="typeform.lastname_other_lang" type="text" :placeholder="trans.trans('__JSON__.Last Name Secondary Language',{lang: $auth.langauges[1].name})" class="form-control" />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.E-Mail') }}</label>
                      <input id="email" v-model="$v.typeform.email.$model" type="email" name="email" class="form-control" @blur="userCheckEmail" :class="{ 'is-invalid':$v.typeform.email.$error }" :placeholder="trans.get('__JSON__.Enter Email')" />
                      <div v-if="$v.typeform.email.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                          <span v-if="!$v.typeform.email.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                      </div>
                  </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="required">{{ trans.get('__JSON__.Primary Contact Number') }}</label>
                    <input  maxlength="10" minlength="8" id="contactno" v-model="$v.typeform.contactno.$model" type="tel" name="contactno" class="form-control" :class="{ 'is-invalid':$v.typeform.contactno.$error }" :placeholder="trans.get('__JSON__.Primary contact number')"/>
                    <div v-if="$v.typeform.contactno.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.contactno.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                        <span v-if="!$v.typeform.contactno.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                    </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.GPay Number') }}</label>
                    <input id="alternate_contactno" v-model="typeform.alternate_contactno" type="tel" name="alternate_contactno" class="form-control" :placeholder="trans.get('__JSON__.GPay Number')" maxlength="10" minlength="10" />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label required">{{ trans.get('__JSON__.Delivery Zone') }}</label>
                  <select class="custom-select" v-model="$v.typeform.frenchise_id.$model" id="frenchise_id" name="frenchise_id" :class="{ 'is-invalid':$v.typeform.frenchise_id.$error }"> 
                    <option value="">{{ trans.get('__JSON__.Select Delivery Zone') }}</option>
                    <option v-for="zone in zones" :value="zone.user_id" :key="zone.user_id">{{zone.last_name}}</option>
                  </select>
                  <div v-if="$v.typeform.frenchise_id.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.frenchise_id.required">{{ trans.get('__JSON__.Please enter select franchise') }}</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                    <div class="form-group">
                        <label for="job_type" class="control-label">{{ trans.get('__JSON__.Job Type') }}</label>
                        <select class="custom-select" :class="{'is-invalid':$v.typeform.job_type.$error}"  v-model="$v.typeform.job_type.$model" id="job_type" name="job_type"> 
                            <option value="">{{trans.get('__JSON__.Select Job Type')}}</option>
                            <option value="full_time">{{trans.get('__JSON__.Full Time')}}</option>
                            <option value="part_time">{{trans.get('__JSON__.Part Time')}}</option>
                        </select>
                        <div v-if="$v.typeform.job_type.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.job_type.required">{{ trans.get('__JSON__.Please enter field',{'name': trans.get('__JSON__.Job Type')}) }}</span>
                        </div>
                    </div>
                </div>
              
              <div class="col-sm-6">
                <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Full address') }}</label>
                      <vue-google-autocomplete
                          ref="address"
                          id="addres"
                          name="address"
                          type="text"
                          class="form-control"
                          placeholder="Please type your address"
                          v-on:placechanged="getAddressData"
                      >
                      </vue-google-autocomplete>
                      <!-- <div v-if="$v.typeform.address.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.address.required">{{ trans.get('__JSON__.Please enter address') }}</span>
                      </div> -->
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Profile Image') }}</label>
                    <!-- <dropzone-single v-model="typeform.profileimage" id="profile_image" acceptedFiles=".jpeg,.jpg,.png" /> -->
                    <dropzone-popup v-model="typeform.profileimage" id="profile_image" acceptedFiles=".jpeg,.jpg,.png" />
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- Driver Info End -->

        <!-- Vechile Info Section Start -->
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ trans.get('__JSON__.Vehicle Information') }}</h4>
            <p class="card-title-desc"></p>
            <div class="row">
              <div class="col-sm-6">

                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Vehicle Type') }}</label>
                        <select class="custom-select" v-bind:value="$v.typeform.vehicle_type.$model" v-model="$v.typeform.vehicle_type.$model"  id="vehicle_type" name="vehicle_type"
                          :class="{'is-invalid': $v.typeform.vehicle_type.$error}"> 
                          <option value="">{{trans.get('__JSON__.Select Vehicle Type')}}</option>
                          <option value="Car">Car</option>
                          <option value="Van">Van</option>
                          <option value="Cycle">Cycle</option>
                          <option value="Bike">Bike</option>
                          <option value="Motorbike">Motorbike</option>
                        </select>
                        <div v-if="$v.typeform.vehicle_type.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.vehicle_type.required">{{ trans.get('__JSON__.Please enter vehicle type') }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{trans.get('__JSON__.Vehicle Number')}}</label>
                      <input id="vehicle_number" type="text" class="form-control" v-model="typeform.vehicle_number" :placeholder="trans.get('__JSON__.Vehicle Number')" name="vehicle_number"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Licence Number') }}</label>
                      <input id="licence_number"  type="text" class="form-control" v-model="typeform.licence_number" :placeholder="trans.get('__JSON__.Licence Number')" name="licence_number"/>
                    </div>
                </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.National Identity Document') }}</label>
                      <dropzone-popup v-model="typeform.vehicle_photo" id="vehicle_photo" acceptedFiles=".jpg, .jpeg, .png,.doc,.docx,.pdf" multiple />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                      <label>{{ trans.trans('__JSON__.Delivery Boy Licence Documents',{ driver: $auth.setting.driver_name }) }}</label>
                      <dropzone-popup v-model="typeform.vehicle_document" id="vehicle_document" acceptedFiles=".jpg, .jpeg, .png,.doc,.docx,.pdf" multiple />
                    </div>
                </div>
            </div>

            <div class="row">
                  <div class="col-sm-6">
                    <button type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.trans('__JSON__.Add Driver',{ driver: $auth.setting.driver_name }) }}</button>
                    <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
            </div>
          </div>
        </div>
        <!-- Vechile Info Section End -->
        </form>
      </div>
      
    </div>
    <!-- end row -->
  </div>
</template>
