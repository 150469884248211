<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import { success_message } from '../../../Helper/helper';
import { userAccessService } from "../../../services";
import AddUser from "./add-user";
import AddPermission from "./add-permission";
import pagination from "laravel-vue-pagination";
import { menuItems } from '../../../components/menu';
import config from '../../../config';

export default {
  components: { Layout, PageHeader, AddUser, pagination,AddPermission  },
  data() {    
    return {
      config: config,
      permissions: [],
      paginations: {},
      user: {},
      limit: 1,
      search: '',
      user_permissions: [],
      permission_types: {},
      menuItems: menuItems,
      is_checked:{}
    };
  },
  mounted(){
    if(this.$route.params.id) this.getUserDetail(this.$route.params.id)
    this.getPermissions()    
  },
  watch:{
    user_permissions: function(){
      this.menuItems.forEach(item => {
        this.isChecked(item.id);
      })
    }
  },
  methods: {
    getUserDetail(id){
        userAccessService
            .getUserDetail(id)
            .then(res => {
                this.user = res.data.result;
                this.user_permissions = res.data.result.permissions && res.data.result.permissions.map(p => p.name) || [];
                this.menuItems.forEach(item => {
                  this.isChecked(item.id);
                })
            });
    },
    setPermissionTypes(){
      this.permission_types = this.permissions.reduce(function(a, p){
        var x = p.name.replace('Read','').replace('Write','').trim();
        if(!a[x]) a[x]= {};
        a[x][p.name.includes('Read')?'Read':'Write'] = p;
        return a;
      },{});
    },
    getPermissions(){
        //var id = this.$route.params.id;
        userAccessService.getAllPermissions()
            .then(res => {
                this.permissions = res.data.result;
                this.setPermissionTypes();
            });
    },
    updatePermissions(){
        userAccessService
            .updateUserPermissions(this.user.vendor_id, this.user_permissions)
            .then(res => {success_message(res.data.message)});

    },
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    selectAll($event, id){
      var newp = this.menuItems.find(i => i.id == id).subItems.reduce((a,i) => a.concat(i.permissions),[]);
      if($event.target.checked){
        var modip = [];
        var cont = newp.concat(this.user_permissions);
        cont.forEach((i) =>  (!modip.includes(i) && modip.push(i)));
        this.user_permissions = modip 
      }else{
        this.user_permissions = this.user_permissions.filter(i => !newp.includes(i));
      }
    },
    isChecked(id){
      var newp = this.menuItems.find(i => i.id == id).subItems.reduce((a,i) => a.concat(i.permissions),[]);
      this.is_checked[id] = newp.every(i => this.user_permissions.includes(i))?true:false
    }
  },
};
</script>
<template>
  <Layout>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-group"></i>
              </span>
            </div>
             <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Permissions') }}</h4>
              <p><router-link :to="{ name: 'user-access'}">{{ trans.get('__JSON__.User & Access') }}</router-link> > {{ trans.get('__JSON__.Permissions') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{trans.get('__JSON__.Permissions')}}</h4>
            <div class="row">
                <div class="col-12 access">
                  <!--- Sidemenu -->
                    <div id="sidebar-menu">
                      <!-- Left Menu Start -->
                      <ul id="side-menu" class="metismenu list-unstyled">
                        <template v-for="item in menuItems">
                          <li
                            class="menu-title"
                            v-if="item.isTitle"
                            :key="item.id"
                            :id="'side-'+item.id"
                          >{{ (item.label) }}</li>
                          <li v-if="!item.isTitle && !item.isLayout" :key="item.id" :id="'side-'+item.id">
                            <a
                              v-if="hasItems(item)"
                              href="javascript:void(0);"
                              class="is-parent"
                            >
                              <!-- :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" -->
                            <div class="row">
                              <div class="col-4">
                                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                                <span>{{ (item.label) }}</span>
                              </div>
                              <div class="col-12 text-right">
                                <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                  <input type="checkbox" class="custom-control-input" :id="'side-label-'+item.id" :checked="is_checked[item.id]" @change="selectAll($event,item.id)" true-value="true" false-value="false" />
                                  <label :for="'side-label-'+item.id" class="custom-control-label">{{'Select All'}}</label>
                                </div>
                              </div>
                            </div>
                            </a>

                            <a v-if="!hasItems(item)" class="side-nav-link-ref" href="javascript:void(0);">
                              <div class="row">
                              <div class="col-4">
                                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                                <span>{{ (item.label) }}</span>
                              </div>
                              <div class="col-4" v-for="(permission, index) in item.permissions" :key="index">
                                <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                  <input type="checkbox" class="custom-control-input" :id="(index?'write':'read')+'side-'+item.id" :value="permission" v-model="user_permissions" />
                                  <label :for="(index?'write':'read')+'side-'+item.id" class="custom-control-label">{{index?'Write':'Read'}}</label>
                                </div>
                              </div>
                            </div>
                            </a>

                            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false" href="javascript:void(0);">
                              <li v-for="(subitem, index) of item.subItems" :key="index">
                                <a
                                  href="javascript:void(0);"
                                  v-if="!hasItems(subitem)"
                                  class="side-nav-link-ref"
                                >
                                  <div class="row">
                                    <div class="col-3">
                                      <span>{{ (subitem.label) }}</span>
                                    </div>
                                    <div class="col-3" v-for="(permission, index) in subitem.permissions" :key="index">
                                      <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                        
                                        <div class="custom-control custom-checkbox custom-checkbox-right mb-3" v-if="index == 0">
                                          <input type="checkbox" class="custom-control-input" :id="'a-read-side-'+subitem.id" :value="permission" v-model="user_permissions" /> 
                                          <label :for="'a-read-side-'+subitem.id" class="custom-control-label">Read</label>
                                        </div>
                                        <div class="custom-control custom-checkbox custom-checkbox-right mb-3" v-if="index == 1">
                                          <input type="checkbox" class="custom-control-input" :id="'a-write-side-'+subitem.id" :value="permission" v-model="user_permissions" /> 
                                          <label :for="'a-write-side-'+subitem.id" class="custom-control-label">Write</label>
                                        </div>
                                        <div class="custom-control custom-checkbox custom-checkbox-right mb-3" v-if="index == 2">
                                          <input type="checkbox" class="custom-control-input" :id="'a-export-side-'+subitem.id" :value="permission" v-model="user_permissions" /> 
                                          <label :for="'a-export-side-'+subitem.id" class="custom-control-label">Export</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  v-if="hasItems(subitem)"
                                  class="side-nav-link-a-ref has-arrow"
                                  href="javascript:void(0);"
                                >
                                  <div class="row">
                                    <div class="col-4">
                                      <span>{{ (subitem.label) }}</span>
                                    </div>
                                    <div class="col-4" v-for="(permission, index) in subitem.permissions" :key="index">
                                      <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                        <input type="checkbox" class="custom-control-input" :id="'sub'+(index?'write':'read')+'side-'+subitem.id" :value="permission" v-model="user_permissions" />
                                        <label :for="'sub'+(index?'write':'read')+'side-'+subitem.id" class="custom-control-label">{{index?'Write':'Read'}}</label>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                                  <li v-for="(subSubitem, i) of subitem.subItems" :key="100+i">
                                    <a
                                      href="javascript:void(0);"
                                      class="side-nav-link-ref"
                                    >
                                      <div class="row">
                                        <div class="col-4">
                                          <span>{{ (subSubitem.label) }}</span>
                                        </div>
                                        <div class="col-4" v-for="(permission, index) in subSubitem.permissions" :key="index">
                                          <div class="custom-control custom-checkbox custom-checkbox-right mb-3">
                                            <input type="checkbox" class="custom-control-input" :id="'subsub'+(index?'write':'read')+'side-'+subSubitem.id" :value="permission" v-model="user_permissions" />
                                            <label :for="'subsub'+(index?'write':'read')+'side-'+subSubitem.id" class="custom-control-label">{{index?'Write':'Read'}}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </div>
                 
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-right">
                    <b-button variant="secondary" @click="$router.back()">
                        {{trans.get('__JSON__.Cancel')}}
                    </b-button>
                    <button class="btn btn-primary" @click="updatePermissions"
                       v-if="$auth.hasPermission(config.permissions.user_access_write)"                    >
                        {{trans.get('__JSON__.Update')}}
                    </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.access .metismenu a:hover{
  color: black !important;
}
.access .metismenu a:hover i{
  color: black !important;
}
</style>