
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { restaurantTagservice } from "../../services";
import config from "../../config";
import { required, requiredIf } from "vuelidate/lib/validators";
import { error_message, success_message , debounce} from "../../Helper/helper";
import { routes } from "../../config/api_routes";

export default {
  components: { Layout, PageHeader, restaurantTagservice, pagination },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      config: config,
      tag: [],
      count: '',
      currentPage: 1,
      secondname_view: false,
      typeform: {
        name: "",
        name_another_lang: "",
        status: 0,
        color_code: "#f2f3f4",
        text_color_code: "#000000",
      },
      edit_typeform: {
        id: "",
        name: "",
        secondary_name: "",
        // status: 0,
        status: '',
        background_color: "",
        text_color: "",
      },
      submitted: false,
      sortBy: "id",
      addModal: false,
      editModal: false,
      sortDesc: true,
      loading: false,
      paginations: {},
      limit: 1,
      search: "",
      status: status == undefined ? "0" :status,
      page: 1,
      config: config,
      error: "",
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'tag', query: {status,orderBy,sortBy}})
        }
        this.getRestaurantTag();
        // this.getCategoryDetail();
        this.init = false;
      },500),
      fields: [],
      // fields: [
      //   { key: "id", label: "#", sortable: true },
      //   {
      //     key: "name",
      //     label: this.trans.get("__JSON__.Name"),
      //     sortable: true,
      //   },
      //   {
      //     key: "secondary_name",
      //     label: this.trans.get("__JSON__.Secondary Name"),
      //     sortable: true,
      //   },
      //   {
      //     key: "status",
      //     label: this.trans.get("__JSON__.Status"),
      //     sortable: true,
      //   },
      //   {
      //     key: "action",
      //     label: this.trans.get("__JSON__.Action"),
      //     sortable: false,
      //   },
      // ],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      name_another_lang: {
        required: requiredIf(function (nestedModel) {
          return this.secondname_view == true;
        }),
      },
    },
    edit_typeform: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.secondName();
    this.getRestaurantTag();
    this.dynamic_field();
  },
  methods: {
    getRestaurantTag(page) {
      this.loading = true;
      restaurantTagservice
        .getResTag({
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page ? page : 1
        })
        .then((response) => {
          this.currentPage = page ? page : 1;
          this.loading = false;
          this.tag = response.data.result.data.data;
          this.count = response.data.result.count;
          this.paginations = response.data.result;
        });
    },
    // searchData() {
    //   this.getRestaurantTag();
    // },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getRestaurantTag();
    },
    secondName() {
      if (this.$auth.langauges[1].name === "No Secondary Language") {
        this.secondname_view = false;
      } else {
        this.second_name = this.$auth.langauges[1].name;
        this.secondname_view = true;
      }
    },
    dynamic_field(){
      if(this.secondname_view == true){
        let field = [
          { key: "#", label: "#", sortable: false },
          { key: "name", label: "Name", sortable: true },
          { key: "secondary_name", label: "Secondary Name", sortable: false },
          { key: "status", label: "Status", sortable: false },
          { key: "action", label: "Action", sortable: false }
        ];
        this.fields = field;
      }else{
        let field = [{ key: "#", label: "#", sortable: false },
        { key: "name", label: "Name", sortable: true },
        { key: "status", label: "Status", sortable: false },
        { key: "action", label: "Action", sortable: false }];
        this.fields = field;
      }
    },
    AddTag() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        this.submitted = true;
        var fd = new FormData();
        fd.append("name", this.typeform.name);
        fd.append("secondary_name", this.typeform.name_another_lang);
        fd.append("text_color", this.typeform.text_color_code);
        fd.append("background_color", this.typeform.color_code);
        fd.append("status", this.typeform.status);
        restaurantTagservice.addResTag(fd).then((response) => {
          this.submitted = false;
          this.typeform = {
            name: "",
            name_another_lang: "",
            status:0,
            color_code: "#f2f3f4",
            text_color_code : "#000000"
          };
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("addModal");
            // this.$emit('category-added');
            this.getRestaurantTag();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    UpdateTag() {
      this.$v.$touch();
      if (this.$v.edit_typeform.$invalid) {
        return;
      } else {
        this.submitted = true;
        var fd = new FormData();
        fd.append("name", this.edit_typeform.name);
        fd.append("secondary_name", this.edit_typeform.secondary_name);
        fd.append("id", this.edit_typeform.id);
        fd.append("text_color", this.edit_typeform.text_color);
        fd.append("background_color", this.edit_typeform.background_color);
        fd.append("status", this.edit_typeform.status);
        restaurantTagservice.updateResTag(fd).then((response) => {
          this.submitted = false;
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$bvModal.hide("editModal");
            this.getRestaurantTag();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    editTag(id) {
      this.fetchTag(id);
    },
    fetchTag(id) {
      return restaurantTagservice.editResTag({ tag_id: id }).then((response) => {
        const {
          id,
          restaurant_id,
          name,
          secondary_name,
          status,
          background_color,
          text_color
        } = response.data.result;

        this.id = response.data.result.id;

        this.edit_typeform = {
          id,
          restaurant_id,
          name,
          secondary_name,
          status,
          background_color,
          text_color
        };
        this.editModal = true;
      });
    },
    deleteById(id) {
      if (
        confirm(
          "Are you sure you want to delete tag? Once it deleted. It will not retrieve"
        )
      ) {
        this.loading = true;
        restaurantTagservice
          .deleteResTag({
            id: id
          })
          .then((response) => {
            if (response.data.code === 200) {
              success_message(response.data.message);
              this.loading = false;
              this.getRestaurantTag();
            } else {
              error_message(response.data.message);
            }
          });
        }
    },
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if (this.$auth.partner_login) {
        return this.fields.filter((field) => !field.requiresAdmin);
      } else {
        return this.fields;
      }
    },
    // If the user IS an admin, return all fields.
  },
};
</script>

<template>
 <Layout>
    <div class="row">
      <div class="col-12">
          <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
          >
          <div class="page-title-left">
              <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-purchase-tag"></i>
              </span>
              </div>
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Tag') }} ({{count}})</h4>
          </div>
          <div class="page-title-right">
             <button type="button" class="btn btn-success btn-rounded mr-2" @click="addModal = true">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Tag')}}
            </button>
            <button type="button" v-if="$auth.hasPermission(config.permissions.tag_write)" class="btn btn-success btn-rounded mr-2 m-add-btn" @click="addModal = true">
              <i class="mdi mdi-plus"></i>
            </button>
          </div>
          </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12 order-table">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="search-box mr-2 mb-2 d-inline-block">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Search') }} :</label>
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        @keyup="searchData()"
                        v-model="search"
                        :placeholder="trans.get('__JSON__.Search...')"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list">
                <b-table
                  :items="tag"
                  :fields="computedFields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  :busy="loading"
                  @sort-changed="sortingChanged"
                  show-empty
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{
                        trans.get("__JSON__.Loading...")
                      }}</strong>
                    </div>
                  </template>
                  <template v-slot:cell(#)="data">  
                        <div v-if="data.index >= 0">
                          {{(currentPage*5)-5 + data.index+1}}
                        </div></template>

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.name">
                      <button
                        type="button"
                        class="btn btn-rounded btn-sm"
                        :style="{
                          color: data.item.text_color,
                          backgroundColor: data.item.background_color,
                        }"
                      >
                        {{ data.item.name }}
                      </button>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </template>

                  <template v-slot:cell(secondary_name)="data">
                      <div v-if="secondname_view">
                          <div v-if="data.item.secondary_name">
                              <button
                            type="button"
                            class="btn btn-rounded btn-sm"
                            :style="{
                              color: data.item.text_color,
                              backgroundColor: data.item.background_color,
                            }"
                          >
                              {{ data.item.secondary_name }}
                          </button>
                        </div>
                        <div v-else>-</div>
                      </div>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status == '0'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                      <button
                        type="button"
                        @click="editTag(data.item.id)"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit')}}</button>
                      <button
                        type="button"
                        @click="deleteById(data.item.id)"
                        class="btn btn-danger btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Delete') }}</button>
                  </template>

                  <template #empty>
                     <p class="text-center">{{ trans.get('__JSON__.No Tag Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getRestaurantTag"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Modal Start -->
       <b-modal
        id="addModal"
        v-model="addModal"
        scrollable
        :title="trans.get('__JSON__.Add Tag')"
        title-class="font-18"
         @hidden="submitted = false"
      >
        <b-form @submit.prevent="AddTag">
          <div class="row">
            <div class="col-md-12" v-if="$auth.setting">
              <b-form-group
                id="input-group-1"
                :label="trans.trans('__JSON__.Tag Name Primary',{lang: $auth.langauges[0].name})"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  name="name"
                  v-model="$v.typeform.name.$model"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Tag Name Primary',{lang: $auth.langauges[0].name})"
                  :class="{ 'is-invalid': $v.typeform.name.$error }"
                ></b-form-input>
                <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.name.required"
                  >{{ trans.get('__JSON__.Please Enter Tag Name') }}.</span>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div
              class="col-sm-12 d-none"
              v-if="$auth.langauges[1].name === 'No Secondary Language'"
            ></div>
            <div class="col-md-12" v-else>
              <b-form-group
                id="input-group-1"
                :label="trans.trans('__JSON__.Tag Name Language',{lang: $auth.langauges[1].name })"
                label-for="v"
              >
                <b-form-input
                  id="name_another_lang"
                  name="name_another_lang"
                  v-model="typeform.name_another_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Tag Name Language',{lang: $auth.langauges[1].name })"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Backgorund Color Code')"
                label-for="Backgorund Color Code"
              >
                <b-form-input
                  type="color"
                  id="colorpickers1"
                  v-model="typeform.color_code"
                  name="color_code"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#f2f3f4"
                />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Text Color Code')"
                label-for="Text Color Code"
              >
                <b-form-input
                  type="color"
                  id="colorpickers1"
                  v-model="typeform.text_color_code"
                  name="text_color_code"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#000000"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <div class="swtich-data">
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          class="switch-on"
                          name="status"
                          v-model="typeform.status"
                          true-value="0"
                          false-value="1"
                        />
                        <div class="slider round">
                          <!--ADDED HTML -->
                          <span class="on">ON</span>
                          <span class="off">OFF</span>
                          <!--END-->
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="AddTag"
               :disabled="submitted"
            >{{ trans.get('__JSON__.Add Tag') }}</b-button>
            <b-button
              class="float-left"
              @click="addModal = false"
            >{{trans.get('__JSON__.Close')}}</b-button>
          </div>
        </template>
       </b-modal>
      <!-- Add Modal End -->

      <!-- Edit Modal Start -->
       <b-modal
        id="editModal"
        v-model="editModal"
        scrollable
        :title="trans.get('__JSON__.Edit Tag')"
        title-class="font-18"
         @hidden="submitted = false"
      >
        <b-form @submit.prevent="UpdateTag">
           <div class="form-group">
            <input id="id" name="id" type="hidden" v-model="edit_typeform.id" class="form-control" />
          </div>
          <div class="row">
            <div class="col-md-12" v-if="$auth.setting">
              <b-form-group
                id="input-group-1"
                :label="trans.trans('__JSON__.Tag Name Primary',{lang: $auth.langauges[0].name})"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  name="name"
                  v-model="$v.edit_typeform.name.$model"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Tag Name Primary',{lang: $auth.langauges[0].name})"
                  :class="{ 'is-invalid': $v.edit_typeform.name.$error }"
                ></b-form-input>
                <div v-if="$v.edit_typeform.name.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.edit_typeform.name.required"
                  >{{ trans.get('__JSON__.Please Enter Tag Name') }}.</span>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div
              class="col-sm-12 d-none"
              v-if="$auth.langauges[1].name === 'No Secondary Language'"
            ></div>
            <div class="col-md-12" v-else>
              <b-form-group
                id="input-group-1"
                :label="trans.trans('__JSON__.Tag Name Language',{lang: $auth.langauges[1].name })"
                label-for="v"
              >
                <b-form-input
                  id="secondary_name"
                  name="secondary_name"
                  v-model="edit_typeform.secondary_name"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Tag Name Language',{lang: $auth.langauges[1].name })"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Backgorund Color Code')"
                label-for="Backgorund Color Code"
              >
                <b-form-input
                  type="color"
                  id="colorpickers1"
                  v-model="edit_typeform.background_color"
                  name="background_color"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#f2f3f4"
                />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Text Color Code')"
                label-for="Text Color Code"
              >
                <b-form-input
                  type="color"
                  id="colorpickers1"
                  v-model="edit_typeform.text_color"
                  name="text_color"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#000000"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <div class="swtich-data">
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          class="switch-on"
                          name="status"
                          v-model="edit_typeform.status"
                          true-value="0"
                          false-value="1"
                        />
                        <div class="slider round">
                          <!--ADDED HTML -->
                          <span class="on">ON</span>
                          <span class="off">OFF</span>
                          <!--END-->
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="UpdateTag"
               :disabled="submitted"
            >{{ trans.get('__JSON__.Update Tag') }}</b-button>
            <b-button
              class="float-left"
              @click="editModal = false"
            >{{trans.get('__JSON__.Close')}}</b-button>
          </div>
        </template>
       </b-modal>
      <!-- Edit Modal End -->

    </div>
    <!-- end row -->
 </Layout>
</template>

<style>
</style>