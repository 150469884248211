
// Banner services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const bookingsService = {
    getBookings: (formData) => window.axios.get(routes.getBookingsApi,{params: formData}),
    getBookingsCount: (formData) => window.axios.get(routes.getBookingsCountApi,{params: formData}),
    changeBookingsStatusApi: (formData) => window.axios.post(routes.changeBookingsStatusApi, formData),
    getNewBookings: (formData) => window.axios.get(routes.getNewBookingsApi, {params: formData}),
    getBooking: (formData) => window.axios.get(routes.getBookingApi,{params: {booking_id:formData}}),
}