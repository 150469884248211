<template>
  <div class="absolute bg-white border m-popup rounded-sm shadow-lg w-100">
    <ul class="m-0 p-0" ref="ul">
      <template v-for="(item, index) in items">
        <li  role="presentation" :key="index">
          <a @click="$emit('onItemSelect',item)" class="p-2 d-block" href="javascript:void(0)" role="menuitem"
            >{{item}}</a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: []
    }
  },
  mounted(){
    // this.$refs.ul.children.length > 0 && this.$refs.ul.children[0].children[0].focus();
  }
};
</script>

<style scoped>
.m-popup {
  bottom: 70px;
}
.m-popup ul li {
  cursor: pointer;
  list-style-type: none;
}
.m-popup ul li a:hover,
.m-popup ul li a:focus {
  background-color: #ddd;
}
</style>