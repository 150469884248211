<script>
import config from "../../config";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import { menuService,reportService } from "../../services";
import { error_message,debounce } from "../../Helper/helper";
/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader, pagination, menuService },
   data() {
    const { orderBy,sortBy} = this.$route.query;
    return {
      restaurant: [],
      restaurant_count: "",
      restaurants_id: "",
      menu_item: "",
      sortBy:sortBy || "id",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      feedback: [],
      paginations: {},
      limit: 1,
      search: "",
      moment: moment,
      config: config,
      loading: true,
      fields: [
        { key: "name", label: "Name", sortable: false },
        { key: "restaurant_name", label: this.trans.get("__JSON__.Vendor Name"), sortable: true },
        { key: "phone_number", label: this.trans.get("__JSON__.Phone Number"), sortable: true },
        {
          key: "feedback",
          label: this.trans.get("__JSON__.Feedback"),
          sortable: false,
        },
        {
          key: "ambience",
          label: this.trans.get("__JSON__.Ambience"),
          sortable: false,
        },
        {
          key: "time_taken_to_serve",
          label: this.trans.get("__JSON__.Serve Time"),
          sortable: false,
        },
        {
          key: "food_taste",
          label: this.trans.get("__JSON__.Food Taste"),
          sortable: false,
        },
        {
          key: "staff_courteousness",
          label: this.trans.get("__JSON__.Staff Courteousness"),
          sortable: false,
        },
        {
          key: "food_quality",
          label: this.trans.get("__JSON__.Food Quality"),
          sortable: false,
        },
        {
          key: "birthday",
          label: this.trans.get("__JSON__.Birthday"),
          sortable: false,
        },
        {
          key: "anniversary",
           label: this.trans.get("__JSON__.Anniversary"),
          sortable: false
        }
      ],
    };
  },
  mounted() {
    this.$data.fields[1].label = this.$auth.getRestaurantName() +' Name';
    this.getfeedback();
    this.getRestaurants();
  },
  methods: {
    getRestaurants() {
      reportService.getAllRestaurantName().then((response) => {
        this.restaurant_count = response.data.result.length;
        this.restaurant = response.data.result;
        if (this.restaurant_count > 0) {
          this.restaurant_id = this.restaurant[0].restaurant_id;
        }
        this.searchData();
      });
    },
    getfeedback(page) {
      this.loading = true;
      var filters = {
        restaurants_id: this.restaurants_id,
        search: this.search,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      }
      menuService
        .getFeedbackListApi(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.feedback = response.data.result.data;
        });
    },
    searchData() {
      this.getfeedback();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getfeedback();
    },
  },
};
</script>

<template>
  <Layout>
     <div>
       <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page" >
                    <div class="page-title-left avatar-main-icon">
                        <div class="avatar-xs mr-3">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i class="bx bx-store"></i>
                            </span>
                        </div>
                       <div class="report-tag">
                            <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Feedback') }}</h4>
                            <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.trans('__JSON__.Feedback') }}</p>
                        </div>
                    </div>
                </div>
            </div>
      </div>
        <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
             <div class="col-sm-2 offset-sm-8">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                      <select class="custom-select" v-model="restaurants_id" @change="searchData()">
                        <option value selected>{{ trans.trans('__JSON__.All Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                        <option v-for="res in restaurant" :value="res.restaurant_id" :key="res.restaurant_id">{{res.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list banner-table table-responsive">
                <b-table
                  :items="feedback"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>

                  <template v-slot:cell(name)="data">
                    <h5 class="font-size-14">
                      <div v-if="data.item.name > 15">{{data.item.name.substr(0, 15)+ '...'}}</div>
                      <div v-else>{{data.item.name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(restaurant_name)="data">
                    <h5 class="font-size-14">
                      <div v-if="data.item.restaurant_name > 25">{{data.item.restaurant_name .substr(0, 25)+ '...'}}</div>
                      <div v-else>{{data.item.restaurant_name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(ambience)="data">
                    <div
                      v-if="data.item.ambience === '1'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Best') }}</div>
                    <div
                      v-else-if="data.item.ambience === '2'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Good') }}</div>
                     <div
                      v-else
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Average') }}</div>
                  </template>

                  <template v-slot:cell(time_taken_to_serve)="data">
                    <div
                      v-if="data.item.time_taken_to_serve === '1'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Best') }}</div>
                    <div
                      v-else-if="data.item.time_taken_to_serve === '2'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Good') }}</div>
                     <div
                      v-else
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Average') }}</div>
                  </template>

                  <template v-slot:cell(food_taste)="data">
                    <div
                      v-if="data.item.food_taste === '1'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Best') }}</div>
                    <div
                      v-else-if="data.item.food_taste === '2'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Good') }}</div>
                     <div
                      v-else
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Average') }}</div>
                  </template>

                  <template v-slot:cell(staff_courteousness)="data">
                    <div
                      v-if="data.item.staff_courteousness === '1'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Best') }}</div>
                    <div
                      v-else-if="data.item.staff_courteousness === '2'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Good') }}</div>
                     <div
                      v-else
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Average') }}</div>
                  </template>

                  <template v-slot:cell(food_quality)="data">
                    <div
                      v-if="data.item.food_quality === '1'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Best') }}</div>
                    <div
                      v-else-if="data.item.food_quality === '2'"
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Good') }}</div>
                     <div
                      v-else
                      class="font-size-12"
                    >{{ trans.get('__JSON__.Average') }}</div>
                  </template>

                  <template v-slot:cell(birthday)="data">
                    <div
                      v-if="data.item.birthday"
                    >{{moment(data.item.birthday).format('D MMM,Y')}}</div>
                    <div v-else>
                      -
                    </div>
                  </template>

                   <template v-slot:cell(anniversary)="data">
                    <div
                      v-if="data.item.anniversary"
                    >{{moment(data.item.anniversary).format('D MMM,Y')}}</div>
                    <div v-else>
                      -
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Feedback') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getfeedback"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
   </div>
  </Layout>
</template>
