<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DashboardList from "./driver_tabs/dashboard-list";
import DatePicker from "vue2-datepicker";
import { driverService } from '../../services';
import { debounce } from '../../Helper/helper';
import Pagination from "laravel-vue-pagination";
/**
 * Starter component
 */
const statuses = {free_delivery:'free_delivery',busy_delivery:'busy_delivery'};
export default {
  components: { Layout, PageHeader, DatePicker, DashboardList, Pagination },
  data() {
    return {
      statuses,
      filters: {
        status: statuses.busy_delivery,
        search: '',
        sort_by: 'driverid',
        sort_type: 'desc',
      },
      limit: 1,
      loading: false,
      drivers: null,
      debounce,
      searchChanged:debounce(()=>{
          this.getDrivers()
      },500)
    };
  },
  mounted(){
    this.getDrivers()
  },
  methods:{
    getDrivers(page = 1){
      this.loading = true;
      driverService.driverByAvailability(
        {...this.filters, page: page}
        )
        .then(response => {
          this.loading = false;
          this.drivers = response.data.result;
        })
    },
    downloadCSV: function(){
      let DriverFree = '\ufeff' + 'Driver Name,Driver ID,Phone Number,Delivery Zone\n'
      this.DriverFree.forEach(el => {
        var line = el['name'] + ',' + el['id'] + ',' + el['phone'] + ',' + el['zone'] +'\n'
        DriverFree += line
      })
      var blob = new Blob([ DriverFree ], { "type" : "csv/plain" });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'dashboard.csv'
      link.click()
    },
    onTabChanged(event){
      this.filters.status = $(event.target).attr('id')??$(event.target).parent().attr('id');
      this.getDrivers();
    },
  }
};
</script>
<template>
   <Layout>    
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Driver Dashboard', { driver: $auth.setting.driver_name }) }}</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    
   <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                   <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-sm-2 offset-sm-10">
                            <div class="search-box mr-2 mb-0 d-inline-block form-group">
                              <div class="date-range-list">
                                <label>{{trans.get('__JSON__.Search')}} :</label>
                                 <div class="position-relative">
                                  <input type="text" class="form-control" 
                                    :placeholder="trans.get('__JSON__.Search')"
                                    @change="searchChanged"
                                    v-model="filters.search" />
                                  <i class="bx bx-search-alt search-icon"></i>
                                 </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="crypto-buy-sell-nav mt-3">
                      <b-tabs
                        nav-class="nav-tabs-custom mt-0 order-tabs"
                        content-class="p-3 text-muted"
                        lazy
                      >
                        <b-tab :button-id="statuses.busy_delivery" @click="onTabChanged">
                          <template v-slot:title>
                            <span class="d-inline-block d-sm-none"><i class="fas fa-bus"></i></span><span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Busy with Delivery')}}</span>
                          </template>
                          <DashboardList :drivers="drivers" :loading="loading" withAction />
                        </b-tab>
                        <b-tab :button-id="statuses.free_delivery" @click="onTabChanged">
                          <template v-slot:title>
                            <span class="d-inline-block d-sm-none"><i class="fas fa-bus"></i></span><span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Free for Delivery')}}</span>
                          </template>                     
                          <DashboardList :drivers="drivers" :loading="loading" />
                        </b-tab>
                      </b-tabs>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0" v-if="drivers">
                            <pagination
                                :data="drivers"
                                :limit="limit"
                                @pagination-change-page="getDrivers"
                            ></pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        
   </div>
    <!-- end row -->
</Layout>
</template>