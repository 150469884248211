
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { locationService } from "../../services";
import { set_axios_defaults, error_message } from "../../Helper/helper";

import { required,numeric } from "vuelidate/lib/validators";

export default {
  components: { pagination, locationService },
  data() {
    return {
      config: config,
      addModal: false,
      editModal: false,
      cities: [],
      sortBy: "cityname",
      cityCount: '',
      country_id: "",
      sortDesc: true,
      paginations: {},
      limit: 1,
      search: "",
      status: 1,
      config: config,
      getCityById: {
        cityid: "",
        state_id: "",
        cityname: "",
        isactive: "",
        minimum_order_value: [{ min_value: '',max_value: '',order_amount: ''}],
        processing_time: 0
      },
      loading:true,
      fields: [
        { key: "cityid", label: this.trans.get("__JSON__.Id"), sortable: true },
        {
          key: "cityname",
          label: this.trans.get("__JSON__.City Name"),
          sortable: true,
        },
        {
          key: "isactive",
          label: this.trans.get("__JSON__.Status"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
      typeform: {
        cityname: "",
        state_id: this.$route.params.state_id,
        isactive: "1",
        minimum_order_value: [{ min_value: '',max_value: '',order_amount: ''}],
        processing_time: 0
      },
    };
  },
  validations: {
    typeform: {
      cityname: {
        required,
      },
      processing_time:{
        required,
        numeric
      },
      minimum_order_value:{
        $each: {
          min_value: {
            required,
            numeric
          },
          max_value: {
            required,
            numeric
          },
          order_amount: {
            required,
            numeric
          },
        },
      }
    },
    getCityById: {
      cityname: {
        required,
      },
      processing_time:{
        required,
        numeric
      },
      minimum_order_value:{
        $each: {
          min_value: {
            required,
            numeric
          },
          max_value: {
            required,
            numeric
          },
          order_amount: {
            required,
            numeric
          },
        },
      }
    },
  },
  created() {
    this.getCity();
  },
  methods: {
    getCity(page) {
      this.loading = true;
      locationService
        .getCity({
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page ? page : 1,
          state_id: this.$route.params.state_id,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.data;
          this.cities = response.data.result.data.data;
          this.cityCount = response.data.result.city_count;
          if(this.cities.length > 0)
          {
              this.country_id = this.base64(this.cities[0].id);
          }
          else
          {
              this.country_id = 'Mg==';
          }
        });
    },
    searchData() {
      this.getCity();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCity();
    },
    addCity() {
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        locationService.addCity(this.typeform).then((response) => {
          this.typeform = {
                cityname: "",
                state_id: this.$route.params.state_id,
                isactive: "1",
                minimum_order_value: [{ min_value: '',max_value: '',order_amount: ''}],
                processing_time: 0
          }
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            this.$toasted.success(response.data.message);
            this.addModal = false;
            this.getCity();
          } else {
            this.$toasted.error(response.data.message);
          }
        });
      }
    },
    cityUpdate() {
      this.$v.getCityById.$touch();
      if (this.$v.getCityById.$invalid) {
        return;
      } else {
        locationService.updateCityById(this.getCityById).then((response) => {
          if (response.data.code === 200) {
            this.$toasted.success(response.data.message);
            this.editModal = false;
            this.getCity();
          } else {
            this.$toasted.error(response.data.message);
          }
        });
      }
    },
    editCity(id) {
      this.fetchCity(id);
    },
    fetchCity(id) {
      return locationService.getCityById({ id: id }).then((response) => {
        if (response.data.code === 200) {
          this.getCityById = response.data.result;
          this.getCityById.minimum_order_value = response.data.result.minimum_order_value
            && JSON.parse(response.data.result.minimum_order_value)
            || [{ min_value: '',max_value: '',order_amount: ''}];
          this.editModal = true;
        } else {
          this.$toasted.error(response.data.message);
        }
      });
    },
    AddFieldForMin(){
      if(this.addModal){
        this.typeform.minimum_order_value.push({ min_value: '',max_value: '',order_amount: '' });
      } else{
        this.getCityById.minimum_order_value.push({ min_value: '',max_value: '',order_amount: '' });
      }
    },
    removeFieldMin(index){
      if(this.addModal){
        this.typeform.minimum_order_value.splice(index,1);
      } else{
        this.getCityById.minimum_order_value.splice(index,1);
      }
    }

  },
};
</script>

<template>
  <div>
      <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-map"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.City') }}({{cities.length}})</h4>
              <p><router-link :to="{ name: 'country-list'}">{{ trans.get('__JSON__.Country') }}</router-link> > <router-link :to="{ name: 'state-list', params: { country_id }}">{{ trans.get('__JSON__.State') }}</router-link> > {{ trans.get('__JSON__.City') }}</p>
            </div>
          </div>
          <div class="page-title-right">
            <button type="button" v-if="$auth.hasPermission(config.permissions.location_write)" class="btn btn-success btn-rounded mr-2" @click="addModal = true">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add City')}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status')}} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value>{{ trans.get('__JSON__.All')}}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active')}}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive')}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="search-box mr-2 mb-2 d-inline-block">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Search')}} :</label>
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        @keyup="searchData()"
                        v-model="search"
                        :placeholder="trans.get('__JSON__.Search Placeholder')"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list city-table table-responsive">
                <b-table
                  :items="cities"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>
                  
                  <template v-slot:cell(cityid)="data">{{ data.index + 1 }}</template>

                  <template v-slot:cell(cityname)="data">
                    <h5 class="font-size-14">{{data.item.cityname}}</h5>
                  </template>

                  <template v-slot:cell(isactive)="data">
                    <div
                      v-if="data.item.isactive == '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <button
                      type="button"
                      v-if="$auth.hasPermission(config.permissions.location_write)"
                      @click="editCity(data.item.cityid)"
                      class="btn btn-primary btn-sm btn-rounded"
                    >{{ trans.get('__JSON__.Edit')}}</button>
                  </template>

                  <template #empty>
                     <p class="text-center">{{ trans.get('__JSON__.No City') }}</p>
                   </template>

                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getCity"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add City Modal Start -->
      <b-modal v-model="addModal" :title="trans.get('__JSON__.Add City')" centered size="lg">
        <b-form @submit.prevent="addCity">
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.City Name')"
                label-for="cityname"
              >
                <b-form-input
                  id="cityname"
                  name="cityname"
                  v-model="$v.typeform.cityname.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.City Name Placeholder')"
                  :class="{ 'is-invalid': $v.typeform.cityname.$error }"
                ></b-form-input>
                <div v-if="$v.typeform.cityname.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.cityname.required"
                  >{{ trans.get('__JSON__.City Name Error') }}</span>
                </div>
              </b-form-group>
            </div>
            <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12" v-for="(v,index) in $v.typeform.minimum_order_value.$each.$iter" :key="index">
                      <div class="row">
                          <div class="col">
                              <h6> Order Distance From</h6>
                              <input v-model="v.min_value.$model" type="number" class="form-control"
                              :class="{ 'is-invalid':  v.min_value.$error }"
                              />
                            <div v-if=" v.min_value.$error" class="invalid-feedback">
                              <span
                                v-if="!v.min_value.required"
                              >{{ trans.trans('__JSON__.required',{attribute: 'min value'}) }}.</span>
                              <span
                                v-if="!v.min_value.numeric"
                              >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                            </div>
                          </div>
                            <div class="col">
                              <h6> Order Distance To</h6>
                              <input v-model="v.max_value.$model" type="number" class="form-control"
                                :class="{ 'is-invalid':  v.max_value.$error }"
                                />
                              <div v-if="v.max_value.$error" class="invalid-feedback">
                                <span
                                  v-if="!v.max_value.required"
                                >{{ trans.trans('__JSON__.required',{attribute: 'max value'}) }}.</span>
                                <span
                                  v-if="!v.max_value.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                            </div>
                            <div class="col">
                              <h6>Order Value</h6>
                              <input v-model="v.order_amount.$model"  type="number" class="form-control"
                              :class="{ 'is-invalid':  v.order_amount.$error }"
                              />
                              <div v-if=" v.order_amount.$error" class="invalid-feedback">
                                <span
                                  v-if="!v.order_amount.required"
                                >{{ trans.trans('__JSON__.required',{attribute: 'order amount'}) }}.</span>
                                <span
                                  v-if="!v.order_amount.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                          </div>
                          <div class="col-md-2">
                              <h6>&nbsp;</h6>
                              <button class="btn btn-primary btn-sm btn-rounded" v-if="index == 0"  @click.prevent="AddFieldForMin">+</button>
                              <button class="btn btn-danger btn-sm btn-rounded" v-if="index > 0" @click.prevent="removeFieldMin(index)">-</button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="col-sm-12 mt-2">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.Avg. Processing Time (Min)')+' (per 500 M)'"
                label-for="cityname"
              >
                <b-form-input
                  v-model="$v.typeform.processing_time.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.Avg. Processing Time (Min)')"
                  :class="{ 'is-invalid': $v.typeform.processing_time.$error }"
                ></b-form-input>
                <div v-if="$v.typeform.processing_time.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.typeform.processing_time.required"
                  >{{ trans.get('__JSON__.Please enter key', {attr: 'Avg. Processing Time'}) }}</span>
                  <span
                    v-if="!$v.typeform.processing_time.numeric"
                  >{{ trans.get('__JSON__.Please enter valid key',{attr:'Avg. Processing Time' }) }}</span>
                </div>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input
                  id="state_id"
                  name="state_id"
                  type="hidden"
                  v-model="typeform.state_id"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="typeform.isactive"
                      name="isactive"
                      value="1"
                      checked
                    >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                  </div>
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="typeform.isactive"
                      name="isactive"
                      value="0"
                    >{{ trans.get('__JSON__.Inactive')}}</b-form-radio>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="addCity"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="addModal=false">{{trans.get('__JSON__.Close')}}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Add City Modal End -->

      <!-- Edit City Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.location_write)" size="lg" v-model="editModal" :title="trans.get('__JSON__.Edit City')" centered>
        <b-form @submit.prevent="cityInIt">
          <div class="row">
            <div class="form-group">
              <input
                id="cityid"
                name="cityid"
                type="hidden"
                v-model="getCityById.cityid"
                class="form-control"
              />
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.City Name')"
                label-for="cityname"
              >
                <b-form-input
                  id="cityname"
                  name="cityname"
                  v-model="$v.getCityById.cityname.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.City Name Placeholder')"
                  :class="{ 'is-invalid': $v.getCityById.cityname.$error }"
                ></b-form-input>
                <div v-if="$v.getCityById.cityname.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.getCityById.cityname.required"
                  >{{ trans.get('__JSON__.City Name Error') }}</span>
                </div>
              </b-form-group>
            </div>
            <div class="form-group">
              <input
                id="state_id"
                name="state_id"
                type="hidden"
                v-model="getCityById.state_id"
                class="form-control"
              />
            </div>
            <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12" v-for="(v,index) in $v.getCityById.minimum_order_value.$each.$iter" :key="index">
                      <div class="row">
                          <div class="col">
                              <h6> Order Distance From</h6>
                              <input v-model="v.min_value.$model" type="number" class="form-control"
                              :class="{ 'is-invalid':  v.min_value.$error }"
                              />
                            <div v-if=" v.min_value.$error" class="invalid-feedback">
                              <span
                                v-if="!v.min_value.required"
                              >{{ trans.trans('__JSON__.required',{attribute: 'min value'}) }}.</span>
                              <span
                                v-if="!v.min_value.numeric"
                              >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                            </div>
                          </div>
                            <div class="col">
                              <h6> Order Distance To</h6>
                              <input v-model="v.max_value.$model" type="number" class="form-control"
                                :class="{ 'is-invalid':  v.max_value.$error }"
                                />
                              <div v-if="v.max_value.$error" class="invalid-feedback">
                                <span
                                  v-if="!v.max_value.required"
                                >{{ trans.trans('__JSON__.required',{attribute: 'max value'}) }}.</span>
                                <span
                                  v-if="!v.max_value.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                            </div>
                            <div class="col">
                              <h6>Order Value</h6>
                              <input v-model="v.order_amount.$model"  type="number" class="form-control"
                              :class="{ 'is-invalid':  v.order_amount.$error }"
                              />
                              <div v-if=" v.order_amount.$error" class="invalid-feedback">
                                <span
                                  v-if="!v.order_amount.required"
                                >{{ trans.trans('__JSON__.required',{attribute: 'order amount'}) }}.</span>
                                <span
                                  v-if="!v.order_amount.numeric"
                                >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                              </div>
                          </div>
                          <div class="col-md-2">
                              <h6>&nbsp;</h6>
                              <button class="btn btn-primary btn-sm btn-rounded" v-if="index == 0"  @click.prevent="AddFieldForMin">+</button>
                              <button class="btn btn-danger btn-sm btn-rounded" v-if="index > 0" @click.prevent="removeFieldMin(index)">-</button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>            
            <div class="col-sm-12 mt-2">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.Avg. Processing Time (Min)')+' (per 500 M)'"
                label-for="cityname"
              >
                <b-form-input
                  v-model="$v.getCityById.processing_time.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.Avg. Processing Time (Min)')"
                  :class="{ 'is-invalid': $v.getCityById.processing_time.$error }"
                ></b-form-input>
                <div v-if="$v.getCityById.processing_time.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.getCityById.processing_time.required"
                  >{{ trans.get('__JSON__.Please enter key', {attr: 'Avg. Processing Time'}) }}</span>
                  <span
                    v-if="!$v.getCityById.processing_time.numeric"
                  >{{ trans.get('__JSON__.Please enter valid key',{attr:'Avg. Processing Time' }) }}</span>
                </div>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="getCityById.isactive"
                      name="isactive"
                      value="1"
                      checked
                    >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                  </div>
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="getCityById.isactive"
                      name="isactive"
                      value="0"
                    >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="cityUpdate"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="editModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Edit City Modal End -->
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.city-table .table thead th:nth-child(4){
  pointer-events: none;
}
</style>