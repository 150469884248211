<script>
import NavBar from "../../components/menu-nav-bar";
import config from "../../config";
import MenuBar from "../../components/menu-bar";
import { menuService } from "../../services";
import { get_currency, debounce } from "../../Helper/helper";
export default {
  components: { NavBar, MenuBar},
  data() {
    return {
      config: config,
      menu: [],
      banner: [],
      rec_menu: [],
      restaurant_id: "",
      currency: get_currency(),
      active: "first",
      slide: 0,
      search: "",
      customization: "",
      veg: "",
      custType: [],
      slide: 0,
      rec_menu_count: "",
      searchData: debounce(() => {
        this.getmenu();
      }, 500),
      // popOpen:false,
    };
  },
  mounted() {
    setTimeout(()=>{
      window.$("#testimonial-slider").owlCarousel({
        items:3,
        itemsDesktop:[1000,2],
        itemsDesktopSmall:[980,1],
        itemsTablet:[768,1],
        pagination:true,
        navigation:true,
        navigationText:["<",">"],
        autoPlay:true
      });
    },1000)
    window.scroll(function() {
      var nav = $('#page-topbar');
      var top = 200;
      if ($(window).scrollTop() >= top) {
          nav.addClass('remove-bg');

      } else {
          nav.removeClass('remove-bg');
      }
    });
    this.getmenu();
    this.getBanner();
  },
  methods: {
    show(id) {
      var show = document.getElementById(id);
      show.style.display = "block";
      console.log("show");
    },
    hide(event, id) {
      event.stopPropagation();
      var hide = document.getElementById(id);
      hide.style.display = "none";
    },
    setMenu(e, id) {
      var elmnt = document.getElementById(id);
      elmnt.scrollIntoView(true);
      var menu = document.getElementById('menu');
      menu.style.display = "none";
      //alert()
    },
    getBanner() {
      menuService.getMenuBanner({
        pet_pooja_restaurant_id: this.$route.params.pet_pooja_restaurant_id,
      }).then((response) => {
        // this.banner = this.splitArrayIntoChunksOfLen(response.data.result, 3);
        this.banner = response.data.result;
      });
    },
    splitArrayIntoChunksOfLen(arr, len) {
      var chunks = [],
        i = 0,
        n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
      }
      return chunks;
    },
    vegcheck(e) {
      this.getmenu();
    },
    checkisfeatured(menu) {
      var newmenu = JSON.parse(JSON.stringify(menu));
      return newmenu.item.some(function (i) {
        return i.is_featured == "1";
      });
    },
    getmenu() {
      menuService
        .getMenuList({
          pet_pooja_restaurant_id: this.$route.params.pet_pooja_restaurant_id,
          search: this.search,
          veg: this.veg,
        })
        .then((response) => {
          this.menu = response.data.result.get_menu;
          this.restaurant_id = response.data.result.restaurant_id;
          this.rec_menu = response.data.result.get_rec_item;
          this.rec_menu_count = response.data.result.rec_menu_count;
        });
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
};
</script>
<template>
  <div layout="vertical">
    <div id="layout-wrapper">
      <NavBar />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
      <div class="main-content w-100 ml-0 m-main-content">
        <div class="page-content m-container-fluid-menu position-relative">
          <!-- Start Content-->
          <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div id="testimonial-slider" class="owl-carousel m-owl-carousel">
                        <div class="testimonial-item equal-height style-6" v-for="b in banner" :key="b.id">
                          <img :src="config.dineinbannerpath+b.image">
                        </div>
                        <!-- <div class="testimonial-item equal-height style-6" >
                          <img src="https://deonde.co/deondefront/images/banner2.png">
                        </div>
                        <div class="testimonial-item equal-height style-6" >
                          <img src="https://deonde.co/deondefront/images/banner3.png">  
                        </div>
                        <div class="testimonial-item equal-height style-6" >
                          <img src="https://deonde.co/deondefront/images/banner4.png">
                        </div>
                        <div class="testimonial-item equal-height style-6" >
                          <img src="https://deonde.co/deondefront/images/banner5.png">
                        </div> -->
                    </div>
                  </div>
                </div>
                <div class="row mt-3 align-items-center justify-content-end">
                  <div class="col-sm-2 m-search-menu">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          v-model="search"
                          @keyup="searchData()"
                          id="search"
                          :placeholder="this.trans.get('__JSON__.Search')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1 pt-3 m-veg-tag">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          @change="vegcheck($event)"
                          v-model="veg"
                          class="custom-control-input"
                          id="veg"
                        />
                        <label class="custom-control-label" for="veg">Veg</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-list">
                  <div class="row">
                    <div class="col-12 menu-header" v-if="rec_menu_count > 0" id="recommended">
                      <h5>Recommended</h5>
                    </div>
                    <div
                      class="col-md-6 col-lg-4 item-body px-mob-0"
                      v-for="item in rec_menu"
                      :key="item.id"
                    >
                      <div class="product-item">
                        <div class="product-content">
                          <div class="row">
                            <h6>{{ item.item_name }}</h6>
                            <button class="btn btn-danger spicy-tag" v-if="item.tags">SPICY</button>
                          </div>
                          <span class="item-price text-dark">
                            <span v-html="currency" class="currency__icon pr-0"></span>{{ item.price }}
                          </span>
                          <span class="item-customizable">
                            <span v-if="item.customization.length != 0" @click="show('popup_'+item.restaurant_menu_item_id)">Customizable</span>
                          </span>
                          <div class="popup" :id="'popup_'+item.restaurant_menu_item_id">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="product-img ml-2">
                                  <div v-if="item.item_image.length > 0">
                                    <img
                                      class="vendor-img avatar-xs"
                                      :src="`${item.item_image[0].item_image_path}`"
                                      alt
                                      height="30"
                                    />
                                  </div>
                                  <div v-else>
                                    <img class="vendor-img avatar-xs no-image" alt />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-8 pt-2">
                                <div class="product-items">
                                  <div class="product-content">
                                    <h6>{{ item.item_name }}</h6>
                                    <button class="btn btn-danger spicy-tag" v-if="item.tags">SPICY</button>
                                  </div>
                                  <span class="item-price text-dark">
                                    <span v-html="currency" class="currency__icon pr-0"></span>{{ item.price }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="product-content">
                              <div class="row">
                                <div
                                  class="col-12 pro-popup-content"
                                  v-for="ces in item.customization"
                                  :key="ces.customize_type_id"
                                >
                                  <h5>
                                    {{ ces.type_name }}
                                    <!-- ({{ ces.option.map((i)=>i.selection_name).join(',') }}) -->
                                  </h5>
                                  <div class="w-100">
                                    <div
                                      class="opt-main d-flex align-items-center justify-content-between w-100"
                                      v-for="opt in ces.menu_customization"
                                      :key="opt.menu_item_selection_id"
                                    >
                                      <p>{{ opt.selection_name }}</p>
                                      <p>{{ opt.selection_price }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              class="btn btn-primary"
                              @click="hide($event,'popup_'+item.restaurant_menu_item_id)"
                            >Close</button>
                          </div>
                          <p>{{ item.item_description }}</p>
                        </div>
                        <div class="product-img ml-2">
                          <div v-if="item.item_type == '0'" class="veg">
                            <span></span>
                          </div>
                          <div v-if="item.item_type == '1'" class="non-veg">
                            <span></span>
                          </div>
                          <div v-if="item.item_image.length > 0">
                            <img
                              class="vendor-img avatar-xs"
                              :src="`${item.item_image[0].item_image_path}`"
                              alt
                              height="30"
                            />
                          </div>
                          <div v-else>
                            <img class="vendor-img avatar-xs no-image" alt />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-for="menus in menu" :key="menus.id" :id="menus.id">
                    <div class="col-12 menu-header">
                      <h5>{{ menus.menu_name }}</h5>
                    </div>
                    <div
                      class="col-md-6 col-lg-4 item-body px-mob-0"
                      v-for="items in menus.item"
                      :key="items.id"
                    >
                      <div class="product-item">
                        <div class="product-content">
                          <div class="row">
                            <h6>{{ items.item_name }}</h6>
                            <button class="btn btn-danger spicy-tag" v-if="items.item_tags">SPICY</button>
                          </div>
                          <span class="item-price text-dark">
                            <span v-html="currency" class="currency__icon pr-0"></span>{{ items.price }}
                          </span>
                          <span class="item-customizable">
                            <span v-if="items.customization.length != 0" @click="show('popup_'+items.restaurant_menu_item_id)">Customizable</span>
                          </span>
                          <div class="popup" :id="'popup_'+items.restaurant_menu_item_id">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="product-img  ml-2">
                                  <div v-if="items.item_image.length > 0">
                                    <img
                                      class="vendor-img avatar-xs"
                                      :src="`${items.item_image[0].item_image_path}`"
                                      alt
                                      height="30"
                                      @error="$event.target.src=config.basepath+'no-img.png'"
                                    />
                                  </div>
                                  <div v-else>
                                    <img class="vendor-img avatar-xs no-image" alt />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-8 pt-2">
                                <div class="product-items">
                                  <div class="product-content">
                                    <h6>{{ items.item_name }}</h6>
                                    <button class="btn btn-danger spicy-tag" v-if="items.tags">SPICY</button>
                                  </div>
                                  <span class="item-price text-dark">
                                    <span v-html="currency" class="currency__icon pr-0"></span>{{ items.price }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="product-content">
                              <div class="w-100">
                                <div
                                  class="col-12 pro-popup-content"
                                  v-for="ces in items.customization"
                                  :key="ces.customize_type_id"
                                >
                                  <h5>
                                    {{ ces.type_name }}
                                    <!-- ({{ ces.option.map((i)=>i.selection_name).join(',') }}) -->
                                  </h5>
                                  <div class="row">
                                    <div
                                      class="opt-main d-flex align-items-center justify-content-between w-100"
                                      v-for="opt in ces.menu_customization"
                                      :key="opt.menu_item_selection_id"
                                    >
                                      <p>{{ opt.selection_name }}</p>
                                      <p>{{ opt.selection_price }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              class="btn btn-primary"
                              href="javascript:void(0)"
                              @click="hide($event,'popup_'+items.restaurant_menu_item_id)"
                            >Close</button>
                          </div>
                          <p>{{ items.item_description }}</p>
                        </div>
                        <div class="product-img ml-2">
                          <div v-if="items.item_type == '0'" class="veg">
                            <span></span>
                          </div>
                          <div v-if="items.item_type == '1'" class="non-veg">
                            <span></span>
                          </div>
                          <div v-if="items.item_image.length > 0">
                            <img
                              class="vendor-img avatar-xs"
                              :src="`${items.item_image[0].item_image_path}`"
                              alt
                              height="30"
                              @error="$event.target.src=config.basepath+'no-img.png'"
                            />
                          </div>
                          <div v-else>
                            <img class="vendor-img avatar-xs no-image" alt />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="w-100 position-relative feedback-menu">
                <div class="col-sm-6">
                  <div class="fed-btn">
                    <router-link
                      :to="{ name: 'feedbacks', params: { restaurant_id: base64(this.restaurant_id)}  }"
                      class="btn btn-primary"
                      target="_blank"
                    >Feedback</router-link>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div id="toggle-container">
                    <ul id="menu">
                      <li
                        v-if="rec_menu_count > 0"
                        @click="setMenu($event,'recommended')"
                      >Recommended</li>
                      <li
                        v-for="menus in menu" :key="menus.id" :id="menus.id"
                        @click="setMenu($event,menus.id)"
                      >{{ menus.menu_name }}</li>
                    </ul>

                    <div id="toggle">
                      <h6>Menu</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MenuBar />
    </div>
</template>