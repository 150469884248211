<script>
import PageHeader from "../../components/page-header";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
} from "../../Helper/helper";
import moment from "moment";

/**
 * Report component
 */
export default {
  components: { PageHeader },
  data() {
    return {
      partner_reports: [
        {
          label: this.trans.get('__JSON__.Sales Report'),
          desc: this.trans.get('__JSON__.All sales reports Order wise & Merchant wise for Specific date range'),
          link: 'vendor.reports.sale.report'
        },
        {
          label: this.trans.get('__JSON__.Items Report'),
          desc: this.trans.get('__JSON__.Menu Items sales Reports based on Particular date'),
          link:'vendor.reports.item.report'
        },
        {
          label: this.trans.trans('__JSON__.Vendor - Dine in QR feedback Report',{ vendor : this.$auth.setting.restaurant_name }),
          desc: this.trans.get('__JSON__.Vendor - Dine in QR feedback Report',{ vendor : this.$auth.setting.restaurant_name }),
          link: 'vendor.dine_feedback.report'
        },
        {
          label: this.trans.trans('__JSON__.Chowman Pay Report'),
          desc: this.trans.get('__JSON__.Chowman Pay Report'),
          link: 'vendor.chowman-pay-report'
        }
      ],
      admin_reports: [
        {
          label: this.trans.get('__JSON__.Customer Report'),
          desc: this.trans.get('__JSON__.All Active/InActive Customer report with No of Delivered Orders'),
          link: 'reports.customer.report'
        },
        {
          label: this.trans.get('__JSON__.Sales Report'),
          desc: this.trans.get('__JSON__.All sales reports Order wise & Merchant wise for Specific date range'),
          link: 'reports.sale.report'
        },
        {
          label: this.trans.get('__JSON__.Total Sales Vs Earning Report'),
          desc: this.trans.get('__JSON__.Order wise Admin & Merchant Earning For specific Date range'),
          link: 'reports.total.earning'
        },
        {
          label: this.trans.get('__JSON__.Tax Report'),
          desc: this.trans.get('__JSON__.Order wise Tax Reports For each Vendor for specific Date range'),
          link: 'reports.tax.report'
        },
        {
          label: this.trans.trans('__JSON__.Driver Report',{ driver: this.$auth.setting.driver_name}),
          desc: this.trans.get('__JSON__.Get Drivers earning & Wallet Report For specific date range'),
          link: 'reports.driver.report'
        },
        {
          label: this.trans.trans('__JSON__.Inactive Customer Report'),
          desc: this.trans.get('__JSON__.Customers who did not placed order since 30,60 or 90 Days'),
          link: 'reports.inactive.customer.report'
        },
        {
          label: this.trans.trans('__JSON__.Vendor - Average Feedback Report',{ vendor : this.$auth.setting.restaurant_name }),
          desc: this.trans.get('__JSON__.Average vendor’s rating given by customer for specific Date range'),
          link: 'reports.avg.rating.report'
        },
        {
          label: this.trans.trans('__JSON__.Promocode Report'),
          desc: this.trans.get('__JSON__.Promo codes used by customers for specific date range'),
          link: 'reports.promo.code.report'
        },
        {
          label: this.trans.trans('__JSON__.Driver Order Report'),
          desc: this.trans.get('__JSON__.Driver order wise earning report'),
          link: 'reports.driver.order.report'
        },
        {
          label: this.trans.trans('__JSON__.Vendor - Feedback and rating report',{ vendor : this.$auth.setting.restaurant_name }),
          desc: this.trans.get('__JSON__.Vendor - Feedback and rating report',{ vendor : this.$auth.setting.restaurant_name }),
          link: 'reports.vendor.feedback.report'
        },
        {
          label: this.trans.trans('__JSON__.Vendor - Dine in QR feedback Report',{ vendor : this.$auth.setting.restaurant_name }),
          desc: this.trans.get('__JSON__.Vendor - Dine in QR feedback Report',{ vendor : this.$auth.setting.restaurant_name }),
          link: 'reports.vendor.dine_feedback.report'
        },
        {
          label: this.trans.trans('__JSON__.Food Ready Mark Report'),
          desc: this.trans.get('__JSON__.Food Ready Mark Report'),
          link: 'reports.food_ready_mark.report'
        },
        {
          label: this.trans.trans('__JSON__.Refer and Earn Report'),
          desc: this.trans.get('__JSON__.Refer and Earn Report'),
          link: 'reports.refer_earn.report'
        },
        {
          label: this.trans.trans('__JSON__.New SignUps Report'),
          desc: this.trans.get('__JSON__.New Customer SignUp Report'),
          link: 'reports.new_signup.report'
        },
        {
          label: this.trans.trans('__JSON__.Cashback Report'),
          desc: this.trans.get('__JSON__.Order Cashback Report'),
          link: 'reports.cashback.report'
        },
        {
          label: this.trans.trans('__JSON__.Chowman Pay Report'),
          desc: this.trans.get('__JSON__.Chowman Pay Report'),
          link: 'reports.chowmanpay.report'
        },
        {
          label: this.trans.get('__JSON__.Zomato Self Order Report'),
          desc: this.trans.get('__JSON__.All sales Zomato Order Specific date range'),
          link: 'reports.zomato.report'
        }
      ]
    };
  },
  methods: {
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-purchase-tag"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Reports') }}</h4>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="row" v-if="$auth.partner_login">
          <div class="col-xl-4 col-sm-6" v-for="(report,index) in partner_reports" :key="index">
            <router-link :to="{ name: report.link }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-dollar-circle"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ report.label }}</h5>
                      <p
                        class="text-muted"
                      >{{ report.desc }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'vendor.reports.sale.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-dollar-circle"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.get('__JSON__.Sales Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.All sales reports Order wise & Merchant wise for Specific date range') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div> 
         <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'vendor.reports.item.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-cart"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.get('__JSON__.Items Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.Menu Items sales Reports based on Particular date') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div> -->
          <!-- end col -->
        </div>
        <div class="row" v-if="!$auth.partner_login">
          <div class="col-xl-4 col-sm-6" v-for="(report,index) in admin_reports" :key="index">
            <router-link :to="{ name: report.link }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-dollar-circle"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ report.label }}</h5>
                      <p
                        class="text-muted"
                      >{{ report.desc }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.customer.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-user"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.get('__JSON__.Customer Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.All Active/InActive Customer report with No of Delivered Orders') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.sale.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-dollar-circle"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.get('__JSON__.Sales Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.All sales reports Order wise & Merchant wise for Specific date range') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.total.earning' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-dollar"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.get('__JSON__.Total Sales Vs Earning Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.Order wise Admin & Merchant Earning For specific Date range') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.tax.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-dollar-circle"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5 class="text-truncate font-size-15">{{ trans.get('__JSON__.Tax Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.Order wise Tax Reports For each Vendor for specific Date range') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.driver.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-user"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.trans('__JSON__.Driver Report',{ driver: $auth.setting.driver_name}) }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.Get Drivers earning & Wallet Report For specific date range') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.inactive.customer.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-user"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.get('__JSON__.Inactive Customer Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.Customers who did not placed order since 30,60 or 90 Days') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.avg.rating.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-star"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.trans('__JSON__.Vendor - Average Feedback Report',{ vendor : $auth.setting.restaurant_name }) }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.Average vendor’s rating given by customer for specific Date range') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-sm-6">
            <router-link :to="{ name: 'reports.promo.code.report' }">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar-sm mx-auto mr-4 mt-2">
                      <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                      >
                        <i class="bx bx-dollar-circle"></i>
                      </span>
                    </div>

                    <div class="media-body overflow-hidden mt-2 ml-2 report-main-p">
                      <h5
                        class="text-truncate font-size-15"
                      >{{ trans.get('__JSON__.Promocode Report') }}</h5>
                      <p
                        class="text-muted"
                      >{{ trans.get('__JSON__.Promo codes used by customers for specific date range') }}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div> -->
          <!-- end col -->
        </div>
      </div>
    </div>
  </div>
</template>