<script>
import BookingAction from './booking-action';
import Swal from "sweetalert2";
import { booking_statuses, get_currency, get_decimal } from '../../../Helper/helper';
import { bookingsService } from '../../../services';
import BookingShow from './booking-show.vue';
import BookingReject from './booking-reject.vue';
export default {
  components: {BookingAction,BookingShow, BookingReject},
  props:{
      'bookings':{
        default: {},
        type: Object
      },
      'loading':{
        default: false,
        type: Boolean
      },
  },
  data() {
    return {
      filters: {
        booking_status: this.booking_status
      },
      meta: [
                { charset: 'utf-8' },
                { equiv: 'Content-Type', content: 'text/html' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ],
      decimal: get_decimal(),
      currency: get_currency(),
      fields:[
        { key: "booking_id", label: this.trans.get("__JSON__.Booking ID"), sortable: false },
        { key: "vendor_name", label: this.trans.get("__JSON__.Vendor's Name",{vendor:this.$auth.getRestaurantName()}), sortable: false },
        { key: "customer_name", label: this.trans.get("__JSON__.Customer Name"), sortable: false },
        { key: "future_delivery_date", label: this.trans.get("__JSON__.Schedule Date"), sortable: false },        
        { key: "created_at", label: this.trans.get("__JSON__.Place Time"), sortable: false },
        { key: "action", label: this.trans.get("__JSON__.Action"), sortable: false },
      ],
      showModal: false,
      showReject: false,
      order: null,
      viewIndex: 0,
      bookingId: null,
    };
  },
   computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      //if(this.$auth.partner_login)
      //  return this.fields.filter(field => !field.requiresAdmin);
        
      // If the user IS an admin, return all fields.
      //else
        return this.fields;
    }
  },
  mounted(){
    this.$data.fields[1].label = this.$auth.setting && this.$auth.setting.restaurant_name;
  },
  methods: {

      btoa(str){
        return btoa(str);
      },
    viewDetails(id){
      this.showModal = true;
      this.bookingId = id;
      // orderService.getOrder(id)
      //   .then(res => {
      //     this.order = res.data.result;
      //     this.orderLoading = false;
      //   })

    },
    viewPageDetails(item,index, event){
      this.viewIndex = index;
      this.viewDetails(item.id);
      this.$refs.selectableTable.clearSelected()
    },
    actionClicked(attr){
      this.showReject = true;
      this.bookingId = atob(attr.id);
    },

    getAlert(status){ 
      switch(status){
        case booking_statuses.expired:
          return "Are you sure you want to expire this booking?";
        case booking_statuses.completed:
          return "Are you sure you want to complete this booking?";
        case booking_statuses.upcoming:
          return "Are you sure you want to confirm this booking?";
        case booking_statuses.rejected:
          return "Are you sure you want to reject this booking?";
      }
    },

    changeBookingStatus(booking,status){
      // if(status == booking_statuses.upcoming){
        if(status == booking_statuses.rejected){
            this.showReject = true;
            this.bookingId = booking.id;
            return false;
        }
        Swal.fire({
          title: this.getAlert(status),
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes"
        }).then(result => {
          if (result.value) {
            bookingsService.changeBookingsStatusApi({
              booking_id: booking.id,
              status: status
            }).then(response => {
              if(response.data.code == 200){
                switch(status){
                  case booking_statuses.completed:
                    Swal.fire("Completed!",  "#"+ booking.booking_id +  " booking has been completed.", "success");
                    break;
                  case booking_statuses.expired:
                    Swal.fire("Expired!",  "#"+ booking.booking_id + " booking has been expired.", "success");
                    break;
                  case booking_statuses.upcoming:
                    Swal.fire("Confirmed!", "#"+ booking.booking_id + " Your booking has been confirmed.", "success");
                    break;
                  case booking_statuses.rejected:
                    Swal.fire("Rejected!", "#"+ booking.booking_id +" booking has been rejected.", "success");
                    break;
                }
                this.$emit('refreshData')
              }
            })
          }
        });
      // }
    }
  },
};
</script>
<template>

<div class="row">
  <div class="col-12 m-order-main">
    <div class="table-responsive">
    <b-table
      :items="bookings && bookings.data || []"
      :fields="computedFields"
      ref="selectableTable"
      show-empty
      :busy="loading"
      class="table-centered table-nowrap table-hover table-main-border order-menu"
      selectable
      select-mode="single"
      @row-clicked="viewPageDetails"
    >
      <template #empty>
          <p class="text-center">{{ trans.get('__JSON__.No Booking Found') }}</p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{trans.get('__JSON__.Loading...')}}</strong>
        </div>
      </template>
      <template #cell(booking_id)="data">
          <span class="font-weight-bold">{{ data.value }}</span>
      </template>
      <template #cell(vendor_name)="data">
        <b-link :to="{ name: 'vendor-detail', params:{restaurant_id: btoa(data.item.restaurant_id) }}"><span class="font-weight-bold">{{ data.value }}</span></b-link>
      </template>
      <template #cell(customer_name)="data">
        <b-link :to="{ name: 'customer-detail', params:{user_id: base64(data.item.user_id) }}">{{ data.value }}</b-link>
      </template>
      <template #cell(future_delivery_date)="data">
        {{ moment(data.value).fromNow() }} <span v-b-tooltip.hover.v-light :title="data.value" class="font-size-15"><i class="bx bx-info-circle"></i></span>
      </template>
      <template #cell(created_at)="data">
        {{ moment(data.value).fromNow() }} <span v-b-tooltip.hover.v-light :title="data.value" class="font-size-15"><i class="bx bx-info-circle"></i></span>
      </template>
      <template #cell(action)="data">
        <BookingAction :booking="data.item" @changeStatus="changeBookingStatus" />
      </template>
    </b-table>
    </div>
  </div>
  <booking-show v-if="showModal" :booking_id="bookingId" @hidden="showModal = false" /> 
  <booking-reject v-if="showReject"  :booking-id="bookingId" @hidden="showReject = false" />
</div>
</template>
<style>

</style>