
<script>
import { categoryService } from "../../services";
import { required, requiredIf } from "vuelidate/lib/validators";
import config from "../../config";
import { error_message, replaceByDefault } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { routes } from "../../config/api_routes";

export default {
  components: { DropzonePopup },
  data() {
    return {
      checked: true,
      file1: null,
      url: null,
      config: config,
      checked: true,
      file1: null,
      url: null,
      config: config,
      category: {
        category_id: "",
        category_name: "",
        category_name_thai: "",
        category_desc: "",
        category_desc_thai: "",
        color_code: "#000000",
        text_color_code: "#000000",
        status: "1",
        category_img: null,
        vendor_id: ''
      },
      category_img: "",
      category_image_path: "",
      category_image_delete_url: routes.categoryImageDeleteUrl,
      category_id: "",
    };
  },
  validations: {
    category: {
      category_name: {
        required,
      },
      category_desc: {
        required,
      },
      category_img: {
        required: requiredIf(function (nestedModel) {
          return !(!!this.category_img || !!this.category.category_img);
        }),
      },
    },
  },
  mounted() {
    this.getCategoryById();
  },
  methods: {
    onCategoryImagedeleted(value) {
      console.log('hi');
      this.category_img = "";
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.category.category_img = file;
    },
    getCategoryById() {
      categoryService
        .getCategoryById({ category_id: this.$route.params.category_id })
        .then((response) => {
          const {
            category_id,
            category_name,
            category_name_thai,
            category_desc,
            category_desc_thai,
            status,
            text_color_code,
            color_code,
          } = response.data.result;
          this.category_image_path = response.data.result.category_image_path;
          this.category_img = response.data.result.category_img;
          this.category_id = response.data.result.category_id;
          this.category = {
            category_id,
            category_name,
            category_name_thai,
            category_desc,
            category_desc_thai,
            status,
            text_color_code,
            color_code,
          };
        });
    },
    categoryInIt() {
      this.$v.category.$touch();

      if (this.$v.category.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("category_id", this.category.category_id);
        fd.append("vendor_id",this.$auth.user.vendor_id);
        if (!!this.category.category_img) {
          fd.append("category_img", this.category.category_img);
        }
        fd.append("category_name", this.category.category_name);
        fd.append("category_name_thai", this.category.category_name_thai);
        fd.append("category_desc", this.category.category_desc);
        fd.append("category_desc_thai", this.category.category_desc_thai);
        fd.append("color_code", this.category.color_code);
        fd.append("text_color_code", this.category.text_color_code);
        fd.append("status", this.category.status);
        categoryService.updateCategoryById(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            // this.$router.push({ name: "category-edit" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <b-form @submit.prevent="categoryInIt">
    <!-- Category Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Category Info') }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="form-group">
            <input
              id="category_id"
              name="category_id"
              type="hidden"
              @id="category_id"
              v-model="category.category_id"
              class="form-control"
            />
          </div>
          <div class="col-sm-6" v-if="$auth.setting">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[0].name})"
              label-for="category_name"
            >
              <b-form-input
                id="category_name"
                name="category_name"
                v-model="$v.category.category_name.$model"
                type="text"
                :placeholder="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[0].name})"
                :class="{ 'is-invalid': $v.category.category_name.$error }"
              ></b-form-input>
              <div v-if="$v.category.category_name.$error" class="invalid-feedback">
                <span
                  v-if="!$v.category.category_name.required"
                >{{ trans.get('__JSON__.Please Enter category Name Error') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name })"
              label-for="v"
            >
              <b-form-input
                id="category_name_thai"
                name="category_name_thai"
                v-model="category.category_name_thai"
                type="text"
                :placeholder="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name })"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Category Description Language',{lang: $auth.langauges[0].name })"
              label-for="category_desc"
            >
              <b-form-textarea
                rows="5"
                id="category_desc"
                v-model="$v.category.category_desc.$model"
                type="text"
                class="form-control"
                :placeholder="trans.trans('__JSON__.Category Description Language',{lang: $auth.langauges[0].name })"
                name="category_desc"
                :class="{ 'is-invalid': $v.category.category_desc.$error }"
              ></b-form-textarea>
              <div v-if="$v.category.category_desc.$error" class="invalid-feedback">
                <span
                  v-if="!$v.category.category_desc.required"
                >{{ trans.get('__JSON__.Please Enter Category Description Error') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Category Description Language',{ lang: $auth.langauges[1].name })"
              label-for="category_desc_thai"
            >
              <b-form-textarea
                rows="5"
                id="category_desc_thai"
                v-model="category.category_desc_thai"
                type="text"
                class="form-control"
                :placeholder="trans.trans('__JSON__.Category Description Language',{ lang: $auth.langauges[1].name })"
                name="category_desc_thai"
              ></b-form-textarea>
            </b-form-group>
          </div>

        <div class="col-md-6 vendor-status">
            <b-form-group
              id="input-group-2"
              :label="trans.get('__JSON__.Status')"
              label-for="Status"
            >
              <div class="row">
                <div class="col-sm-6">
                  <div class="swtich-data">
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        class="switch-on"
                        name="status"
                        v-model="category.status"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <!--ADDED HTML -->
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        <!--END-->
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>


          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-6">
                <b-form-group
                  id="input-group-2"
                  :label="trans.get('__JSON__.Menu Color Code')"
                  label-for="Menu Color Code"
                ></b-form-group>
              </div>
              <div class="col-sm-6">
                <b-form-group
                  id="input-group-2"
                  :label="trans.get('__JSON__.Text Color Code')"
                  label-for="Text Color Code"
                ></b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <input
                  type="color"
                  id="colorpickers1"
                  v-model="category.color_code"
                  name="color_code"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#000000"
                />
              </div>
              <div class="col-sm-6">
                <input
                  type="color"
                  id="colorpickers1"
                  v-model="category.text_color_code"
                  name="text_color_code"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#000000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Category Info End -->

    <!-- Category Image Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Category Image') }}</h4>
        <p class="card-title-desc"></p>

        <div class="row">
          <div class="col-sm-3">
            <!-- <dropzone-popup
              v-model="$v.category.category_img.$model"
              id="category_img"
              acceptedFiles=".jpeg,.jpg,.png"
              :editable-images="category_img"
              :media-path="category_image_path+'/original'"
              :delete-path="category_image_delete_url"
              @image-deleted="this.onCategoryImagedeleted"
            />-->
            <dropzone-popup
              v-model="category.category_img"
              id="category_img"
              acceptedFiles=".jpeg,.jpg,.png"
              :editable-images="category_img"
              :media-path="category_image_path+'/original'"
              :delete-path="category_image_delete_url"
              @image-deleted="this.onCategoryImagedeleted"
            />
            <div v-if="$v.category.category_img.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.category.category_img.required"
              >{{ trans.get('__JSON__.Please Select Category Image Error') }}.</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update Category') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Category Image End -->
  </b-form>
</template>

<style>
</style>