<script>
import { required } from "vuelidate/lib/validators";
import { settingService } from '../../../services';
import { success_message, error_message } from '../../../Helper/helper';
import config from '../../../config';
/**
 * Starter component
 */
export default {
  data() {
    return {
      config: config,
      setting: [],
      setting: {
        promocode_text: '',
      }
    };
  },
  validations: {
    setting: {
      promocode_text: {
        required,
      },
    },
  },
  mounted() {
      this.getSetting();
  },
  methods: {
      getSetting(){
      settingService.getSetting()
      .then(response => {
       this.setting = response.data.result;
      });
    },
    generalIt(){
      this.$v.setting.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("promocode_text", this.setting.promocode_text);
        settingService.updateReferral(fd).then((response) => {
          if (response.data.code === 200) {
              success_message(response.data.message);
            } else {
              error_message(response.data.message);
            }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Promocode Text') }}</label>
                <input
                  v-model="setting.promocode_text"
                  id="promocode_text"
                  name="promocode_text"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Promocode Text')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.setting.promocode_text.$error }"
                />
                 <div v-if="$v.setting.promocode_text.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.promocode_text.required">{{ trans.get('__JSON__.Please enter promocode text')}}</span>
                  </div>
               </div>
              </div>
            </div>
            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>