
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { restaurantService } from "../../services";
import {
  error_message,
  success_message,
  replaceByDefault,
} from "../../Helper/helper";
import { routes } from "../../config/api_routes";

/**
 * update-profile component
 */
export default {
  name: "FixedTimeList",
  components: { Layout, PageHeader, DatePicker, moment },
  data() {
    return {
      title: "Selling Time",
      times: [{ start_time: "", end_time: "", id: "" }],
      restaurant_id: this.$route.params.restaurant_id,
    };
  },
  methods: {
    handleSubmit() {
      const fd = new FormData();
      fd.append("selling_times", JSON.stringify(this.times));
      fd.append("restaurant_id", this.restaurant_id);
      restaurantService.addTimeSlot(fd).then((response) => {
        if (response.data.code === 200) {
          this.times = response.data.result;
          success_message(response.data.message);
        } else {
          error_message(response.data.message);
        }
      });
    },
    getSelingTimes: function (restaurant_id) {
      restaurantService.getSelingTimes(restaurant_id).then((response) => {
        if (response.data.result.length > 0) {
          this.times = response.data.result;
        }
      });
    },
    AddSlot: function () {
      this.times.push({ start_time: "", end_time: "" });
      //this.getSelingTimes(this.restaurant_id)
    },
    remove_slot(id, index) {
      if (id) {
        restaurantService.removeSellingTime(id).then((response) => {
          if (response.data.code === 200) {
            this.times = this.times.filter((time) => id != time.id);
            if (this.times.length == 0) {
              this.times.push({ start_time: "", end_time: "", id: "" });
            }
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      } else {
        this.times.splice(index, 1);
      }
    },
  },
  mounted() {
    this.getSelingTimes(this.$route.params.restaurant_id);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div>
      <div class="card">
        <div class="card-body">
          <b-form @submit.prevent="handleSubmit">
            <div class="table-responsive mt-5">
              <table class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Start Time</th>
                    <th>Closing Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <input type="hidden" id="restaurant_id" name="restaurant_id" v-model="restaurant_id" />

                  <tr v-for="(time,index) in times" :key="time.id">
                    <td>{{ index + 1 }}</td>
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="time.start_time"
                        :time-picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:30',
                      }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        placeholder="hh:mm A"
                        name="start_time[]"
                        value-type="format"
                      ></date-picker>
                    </td>
                    <td class="selling-time-slot">
                      <date-picker
                        v-model="time.end_time"
                        :time-picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:30',
                      }"
                        format="hh:mm A"
                        type="time"
                        class="date-time"
                        placeholder="hh:mm A"
                        name="end_time[]"
                        value-type="format"
                      ></date-picker>
                    </td>
                    <td>
                      <button @click.prevent="AddSlot" class="btn btn-success btn-sm btn-rounded">Add</button>
                      <button
                        @click.prevent="remove_slot(time.id,index)"
                        v-if="time.id!=''"
                        class="btn btn-danger btn-sm btn-rounded"
                      >Remove</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-6 operation-btn">
                <button type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
  <!-- end row -->
</template>
