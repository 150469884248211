<template>
    <b-modal
        id="modal-sm"
        size="lg"
        title-class="font-18"
        @hidden="resetModal"
        @ok="customerNotes"
        :ok-title="trans.get('__JSON__.Customer Notes')"
        ok-only
        ok-variant="danger"
        ref="cancel-modal"
        lazy
        >
        <div class="main-message">
            <div class="ctext-wrap"    v-for="(messages, index) in userCustomNotes"
                :key="messages.id + '/' + index">
                      <div class="conversation-name"><b>
                        {{ messages.vendors.vendor_name}}</b>
                      </div>
                      <p>
                        <span class="pull-left">
                            {{ messages.message }}
                        </span>
                        <span class="message-right">
                                <i class="bx bx-time-five align-right mr-1"></i
                                >{{ moment.utc(messages.updated_at).fromNow() }}
                                <span
                                v-b-tooltip.hover.v-light
                                :title="
                                    moment(
                                    moment.utc(messages.updated_at).toISOString()
                                    ).format('lll')
                                "
                                class="font-size-15 pull-right"
                                ><i class="bx bx-info-circle pull-right"></i
                                ></span>
                        </span>
                      </p>
            </div>
        </div>
       
        <template #modal-header={close}>
            <div class="d-flex justify-content-between w-100">
                <h4 class="mb-0">{{trans.get('__JSON__.Customer Notes')}}</h4>
                <h4 class="mb-0">{{userDetails.user_name}} {{userDetails.last_name}}</h4>
            </div>
            <button type="button" aria-label="Close" class="close" @click="close()">&times;</button>
        </template>
        <b-form-group label="Customer Notes" >
            <b-form-textarea
            id="textarea"
            v-model="customer_notes"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        </b-form-group>
    </b-modal>
</template>
<script>
import config from '../../config';
import { orderService } from '../../services';
import { chatService } from "../../services";
export default {
    components: {
        chatService,
    },
    props :['userDetails','userCustomNotes','userMessage'],
    data(){
        return {
            config: config,
            customer_notes:'',
            showModal: false,
     
            cancelled_desc: '',
            send_email:false,
            is_refundable: false,
            showPopup: false,
            order_id: null,
           
            other_selected: false,
        }
    },
    mounted(){
        this.cancelOrderPopup()
    },
     methods:{
        resetModal(){
            // this.order_id = null;
            this.is_refundable = false;
            this.$emit('hidden')
        },
        cancelOrderPopup(id,status){
            this.$refs['cancel-modal'].show();
            // this.order_id = id;
        },
        customerNotes(bvModalEvt){
            bvModalEvt.preventDefault()
            if(this.customer_notes == '' || this.customer_notes.trim() == ''){
                this.$toasted.info('Please Enter the Custom Notes.');
                return false;
            }
            const data = {user_id:this.userDetails.user_id,customer_notes:this.customer_notes};
            chatService.customerNotes(data)
                .then(response => {
                    if(response.data.code == 200){
                        this.$toasted.success(response.data.message);
                    }else{
                        this.$toasted.error(response.data.message);
                    }
                    this.userMessage(this.userDetails.user_id);
                    this.$refs['cancel-modal'].hide();
                  
                })
        },
      
     }
}
</script>
<style  scoped>
.height-100{
    height: 100px;
}
.message-right{
    float:right;
}
.ctext-wrap{
    padding:10px 10px;
    background-color: #eff2f7;
    margin:10px;
    border-radius: 8px 8px 0px 8px;
}
.main-message
{
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
}
</style>