
<script>
import Vue from "vue";
import { date_ranges, generate_csv, get_currency, get_decimal } from '../../Helper/helper'
import { reportService } from '../../services'
import Layout from "../../layouts/main";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import config from "../../config";
Vue.component('card-view',{
  props:['title','currency','text'],
  template: `<div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-dollar"></i>
                </span>
              </div>
              <h5 class="font-size-14 mb-0">{{title}}</h5>
            </div>
            <div class="text-muted mt-4">
              <h4><span v-if="currency" v-html="currency"></span> {{text}}</h4>
            </div>
          </div>
        </div>`
})
export default {
    components: { Layout, pagination,DateRangePicker },
    data(){
      let startDate = this.moment().startOf('day').toDate();
      let endDate = this.moment().endOf('day').toDate();
        return {
            date_ranges: date_ranges,
            limit:  1,
            config:config,
            dateRange: { startDate, endDate },
            decimal:get_decimal(),
            currency: get_currency(),
            sortBy: 'delivered_date',
            sortByDesc : false,
            reports: null,
            loading: false,
            exporting: false,
            report_total: [],
            fields: [
                { key: "table_id", label: "#", sortable: false},
                { key: "hash_id", label: "Order Id", sortable: false},
                { key: "order_source", label: "Order Source", sortable: false},
                { key: "drivers", label: "Driver Name", sortable: false},
                { key: "driver_job_type", label: "Job Type", sortable: false},
                { key: "restaurant", label: this.trans.trans("__JSON__.Vendor's Name",{vendor: this.$auth.getRestaurantName()}), sortable: false},
                { key: "readytoserve_date", label: "Food is ready time", sortable: false},
                { key: "ontheway_date", label: "Driver pickup time", sortable: false},
                { key: "delivered_date", label: "Driver delivered time", sortable: false},
                { key: "delivery_time", label: "Time in delivery", sortable: false},
                { key: "distance_km", label: "Distance (KM)", sortable: false},
                { key: "driver_earning", label: "Driver Commission", sortable: false},
            ]
        }
    },
    filters: {
      date(val) {
          return val ? val.toLocaleString() : "";
      },
    },
    mounted(){
        this.getDriverOrderReports();
    },
    methods: {
        getDriverOrderReports(page = 1){
            this.loading = true;
            reportService.getDriverOrderReport({
                start_date: this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                end_date: this.moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                page,
                sortBy: this.sortBy,
                orderBy: this.sortDesc ? "desc" : "asc",
            })
            .then((res) => {
                this.loading = false;
                this.reports = res.data.result.orders
                this.report_total = res.data.result.totals
            });
        },
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.getDriverOrderReports();
        },
        getRestaurantNameExport(el){
          let name = el.vendor_order_id != null && el.vendor_order_id != '' ? el.fa_order_address.restaurant_name : el.restaurant.name;
          return name;
        },  
        getDate(date){
            if(date && date!='0000-00-00 00:00:00'){
                return this.moment(date).format('MMM Do YYYY h:mm a');
            }
            return '-';
        },
        downloadsCSV(){
          this.exporting = true;
            reportService.exportDriverOrderReport({
                start_date: this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                end_date: this.moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                sortBy: this.sortBy,
                orderBy: this.sortDesc ? "desc" : "asc",
            })
            .then((res) => {
                
                this.exporting = false;
                let vendorFinance = '\ufeff' +
                '#,'
                +"Order Id"+','
                +"Driver Name"+','
                +"Job Type"+','
                +this.trans.trans("__JSON__.Vendor's Name",{vendor: this.$auth.getRestaurantName()})+','
                +"Food is ready time"+','  
                +"Driver pickup time"+','
                +"Driver delivered time"+','
                +"Time in delivery"+','
                +"Distance (KM)"+','
                +"Driver Commission"+','
                +'\n';
            res.data.result.orders.map((el,index)=> {
              var line = ''
              + (index+1) + ',' 
              + el['hash_id'] + ',' 
              + el['drivers'][0]['fullname'] + ',' 
              + el['drivers'][0]['driver_job_type'] + ',' 
              + this.getRestaurantNameExport(el) + ',' 
              + this.getDate((el['readytoserve_date'] &&  el['readytoserve_date']!=='0000-00-00 00:00:00'?el['readytoserve_date']:el['confirm_date'])) + ',' 
              + this.getDate(el['ontheway_date']) + ',' 
              + this.getDate(el['delivered_date']) + ',' 
              + (el['delivery_time']) + ',' 
              + (el['distance_km']) + ',' 
              + parseFloat(el['driver_earning']).toFixed(this.decimal) 
              +'\n'
              vendorFinance +=line;
            })
            generate_csv(vendorFinance,this.$auth.getDriverName() + "-orders-"
            +this.moment(this.dateRange.startDate).format('YYYY-MM-DD')+'-'+
            this.moment(this.dateRange.endDate).format('YYYY-MM-DD')+
            '.csv');
            });
        }
    }
}
</script>

<template>
  <Layout>
    <div>
       <div class="row">
          <div class="col-12">
              <div
                  class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag" v-if="!$auth.partner_login">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Driver Order Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Driver Order Report') }}</p>
                  </div>
                  <div class="report-tag" v-else>
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Driver Order Report') }}</h4>
                    <p><router-link :to="{ name: 'vendor.reports.list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Driver Order Report') }}</p>
                  </div>
              </div>
              <div class="justify-content-end mb-3 row">
                <div class="">
                    <label for="">{{trans.get('__JSON__.Date')}}</label>
                    <div class="text-sm-right">
                        <div class="mr-2 ">
                          <date-range-picker
                            ref="picker"
                            :locale-data="{
                              firstDay: 1,
                              format: 'dd-mm-yyyy',
                            }"
                            :showDropdowns="true"
                            :showWeekNumbers="true"
                            opens="left"
                            v-model="dateRange"
                            :ranges="date_ranges()"
                            @update="getDriverOrderReports"
                          >
                            <template
                              v-slot:input="picker"
                              style="min-width: 350px"
                            >
                              {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                              {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                            </template>
                          </date-range-picker>
                        </div>
                    </div>
                </div>
                <div class="mt-4 pt-1 mr-3">
                    <div class="text-sm-right">
                      <button @click="downloadsCSV" 
                      v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)"
                      class="btn btn-primary btn-rounded" :disabled="exporting">
                      {{trans.get(!exporting?'__JSON__.Export To CSV':'__JSON__.Exporting')}}
                      </button>
                  </div>
                  </div>
              </div>
            </div>              
          </div>
      </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-3"><card-view title="Total Commission" :text="(report_total && report_total.total_earning || 0).toFixed(decimal)" :currency="currency" /></div>
          <div class="col-3"><card-view title="Total Orders" :text="report_total && report_total.total_orders" /></div>
          <!-- <div class="col-3"><card-view title="card" text="12" :currency="currency" /></div>
          <div class="col-3"><card-view title="card" text="12" :currency="currency" /></div> -->
        </div>
      </div>
    </div>
      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <!-- <div class="row justify-content-between" v-if="!$auth.partner_login">
                <div v-if="countries.length >1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Country') }} :</label>
                      <select class="custom-select" v-model="country" @change="getVendorState">
                        <option value="0" selected>All</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-7">
                </div>
                <div v-if="countries.length >=1 && states.length >1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.State') }} :</label>
                      <select class="custom-select" v-model="state" @change="getVendorCity">
                        <option value="0" selected>All</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="states.length >=1 && cities.length >1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.City') }} :</label>
                      <select class="custom-select" v-model="city" @change="citychange">
                        <option value="0" selected>All</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="(countries.length >1 || states.length > 1 || cities.length > 1) && restaurant.length > 1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                      <select class="custom-select" v-model="restaurants_id" @change="searchData()">
                        <option value selected>{{ trans.trans('__JSON__.All Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                        <option v-for="res in restaurant" :value="res.restaurant_id" :key="res.restaurant_id">{{res.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="order_status" @change="searchData()">
                        <option value >{{ trans.get('__JSON__.All') }}</option>
                        <option value="Delivered" selected>{{ trans.get('__JSON__.Delivered') }}</option>
                        <option value="Cancelled">{{ trans.get('__JSON__.Cancelled') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                 <div class="sales-report" v-if="!$auth.partner_login">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Date') }} :</label>
                     <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row table-responsive">
                <div class="col-12 table-main-list table table-centered table-nowrap sales-report-table">
                  <b-table
                    :items="reports && reports.data || []"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>
                    
                    <template v-slot:cell(table_id)="data">
                        <div>{{ data.index + 1 }}</div>
                    </template>
                    <template v-slot:cell(order_source)="data">
                        <div>{{ data.value || "Chowman" }}</div>
                    </template>


                    <template v-slot:cell(driver_job_type)="data">
                        <div>{{ data.item.drivers[0].driver_job_type }}</div>
                    </template>
                    <template v-slot:cell(readytoserve_date)="data">
                        <div>{{ getDate(data.value && data.value!=='0000-00-00 00:00:00'?data.value:data.item.confirm_date)}}</div>
                    </template>
                    <template v-slot:cell(ontheway_date)="data">
                        <div>{{ getDate(data.value) }}</div>
                    </template>
                    <template v-slot:cell(delivered_date)="data">
                        <div>{{ getDate(data.value) }}</div>
                    </template>
                    <template v-slot:cell(drivers)="data">
                      <div>{{ data.value[0].fullname }}</div>
                    </template>
                    <template v-slot:cell(restaurant)="data">
                      <div>{{ data.value.name }}</div>
                    </template>
                    <template v-slot:cell(distance_km)="data">
                      <div>{{ parseFloat((data.value || '0').replace('km','')).toFixed(1) }}</div>
                    </template>
                    <template v-slot:cell(driver_earning)="data">
                      <div><span v-html="currency"></span> {{ parseFloat(data.value).toFixed(decimal) }}</div>
                    </template>
                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Driver Order Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0" v-if="reports">
                        <!-- pagination -->
                        <pagination
                          :data="reports"
                          :limit="limit"
                          @pagination-change-page="getDriverOrderReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>

</style>