<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { restaurantTagservice } from "../../services";
import config from "../../config";
import { required, requiredIf } from "vuelidate/lib/validators";
import { error_message, success_message } from "../../Helper/helper";
import { routes } from "../../config/api_routes";
export default {
  components: { Layout, PageHeader, restaurantTagservice, pagination },
  data() {
    return {
      typeform: {
        restaurant_id: this.$route.params.restaurant_id,
        name: "",
        name_another_lang: "",
        status: 0,
        color_code: "#f2f3f4",
        text_color_code: "#000000",
      },
      edit_typeform: {
        restaurant_id: this.$route.params.restaurant_id,
        tag_id: "",
        name: "",
        name_another_lang: "",
        // status: 0,
        status: '',
        color_code: "",
        text_color_code: "",
      },
      Addbtndisable: false,
      Updatebtndisable: false,
      secondname_view: false,
      second_name: "",
      selected: "",
      listing_status: "",
      addTagView: true,
      sortBy: "asc",
      loading: true,
      sortDesc: true,
      limit: 1,
      data: [],
      paginations: {},
      search: "",
      config: config,
      addTagModal: false,
      fields:[],
      currentPage: 1
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      name_another_lang: {
        required: requiredIf(function (nestedModel) {
          return this.secondname_view == true;
        }),
      },
    },
    edit_typeform: {
      name: {
        required,
      },
      name_another_lang: {
        required: requiredIf(function (nestedModel) {
          return this.secondname_view == true;
        }),
      },
    },
  },
  methods: {
    getRestaurantTag(page) {
      this.loading = true;
      restaurantTagservice
        .getResTag({
          search: this.search,
          is_confirm: this.listing_status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page ? page : 1,
          restaurant_id: this.$route.params.restaurant_id,
        })
        .then((response) => {
          this.currentPage = page ? page : 1;
          this.loading = false;
          this.data = response.data.result.data.data;
          this.paginations = response.data.result.data;
        });
    },
    base64_encode(id) {
      return btoa(id);
    },
    searchData() {
      this.search = this.search;
      this.getRestaurantTag();
    },
    AddTag() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        this.Addbtndisable = true;
        var fd = new FormData();
        fd.append("name", this.typeform.name);
        fd.append("secondary_name", this.typeform.name_another_lang);
        fd.append("restaurant_id", this.$route.params.restaurant_id);
        fd.append("text_color", this.typeform.text_color_code);
        fd.append("background_color", this.typeform.color_code);
        fd.append("status", this.typeform.status);
        restaurantTagservice.addResTag(fd).then((response) => {
          if (response.data.code === 200) {
            this.Addbtndisable = false;
            this.loading = true;
            success_message(response.data.message);
            this.loading = false;
            this.getRestaurantTag();
            this.addTagFrm();
            this.$v.typeform.$reset();
            this.$v.$reset();
            this.typeform.name = "";
            this.typeform.name_another_lang = "";
            this.typeform.status = 0;
            this.typeform.color_code = "#f2f3f4";
            this.typeform.text_color_code = "#000000";
          } else {
            this.Addbtndisable = false;
            error_message(response.data.message);
          }
        }).catch((error)=>{
          this.Addbtndisable = false;
        });
      }
    },
    addTagFrm() {
      this.addTagView = true;
      this.typeform.name = "";
      this.typeform.name_another_lang = "";
      this.typeform.status = 0;
      this.typeform.color_code = "#f2f3f4";
      this.typeform.text_color_code = "#000000";
      this.$v.typeform.$reset();
      this.search = "";
      this.listing_status="";
    },
    editTag(restaurant_id,id) {
        let res_id = "";
        if(restaurant_id == "" || restaurant_id ==  undefined){
            res_id = "";
        }else{
            res_id = restaurant_id;
        }
      this.addTagView = false;
      restaurantTagservice
        .editResTag({ restaurant_id:res_id, tag_id: this.base64_encode(id) })
        .then((response) => {
          this.edit_typeform.name = response.data.result.name;
          this.edit_typeform.name_another_lang = (!response.data.result.secondary_name ? '' : response.data.result.secondary_name);
          this.edit_typeform.color_code =
            response.data.result.background_color;
          this.edit_typeform.text_color_code = response.data.result.text_color;
          this.edit_typeform.status = response.data.result.status;
          this.edit_typeform.tag_id = response.data.result.id;
        });
    },
    UpdateTag() {
      this.$v.$touch();
      if (this.$v.edit_typeform.$invalid) {
        return;
      } else {
        this.Updatebtndisable = true;
        var fd = new FormData();
        fd.append("name", this.edit_typeform.name);
        fd.append("secondary_name", this.edit_typeform.name_another_lang);
        fd.append("restaurant_id", this.$route.params.restaurant_id);
        fd.append("tag", this.edit_typeform.tag_id);
        fd.append("text_color", this.edit_typeform.text_color_code);
        fd.append("background_color", this.edit_typeform.color_code);
        fd.append("status", this.edit_typeform.status);
        restaurantTagservice.updateResTag(fd).then((response) => {
          if (response.data.code === 200) {
            this.Updatebtndisable = false;
            this.loading = true;
            success_message(response.data.message);
            this.loading = false;
            this.addTagFrm();
            this.getRestaurantTag();
          } else {
            this.Updatebtndisable = false;
            error_message(response.data.message);
          }
        }).catch((error)=>{
          this.Updatebtndisable = false;
        });
      }
    },
    deleteTag(tag_id) {
      if (
        confirm(
          "Are you sure you want to delete tag? Once it deleted. It will not retrieve"
        )
      ) {
        this.loading = true;
        restaurantTagservice
          .deleteResTag({
            tag_id: this.base64_encode(tag_id),
            restaurant_id: this.$route.params.restaurant_id,
          })
          .then((response) => {
            if (response.data.code === 200) {
              success_message(response.data.message);
              this.loading = false;
              this.getRestaurantTag();
            } else {
              error_message(response.data.message);
            }
          });
        this.addTagFrm();
      }
    },
    StringSpt(str) {
      return str.slice(0, 12);
    },
    onRowClicked(items,index,event) {
        let data = event.path[2];
        let check = $(data).prop('aria-selected', true);
        if(check[0].ariaSelected == 'false' || check[0].ariaSelected == null){
            this.editTag(this.$route.params.restaurant_id,items.id);
        }else{
            this.addTagFrm();
        }
       
    },
    sortingChanged() {
      let sort =
        this.sortDesc == true ? false : this.sortDesc == false ? true : true;
      this.sortDesc = sort;
      this.getRestaurantTag();
    },
    secondName() {
      if (this.$auth.langauges[1].name === "No Secondary Language") {
        this.secondname_view = false;
      } else {
        this.second_name = this.$auth.langauges[1].name;
        this.secondname_view = true;
      }
    },
    rowClass(item, type) {
      return "table-success";
      // if (!item || type !== 'row') return
      // if (item.status === 'awesome') return 'table-success'
    },
    dynamic_field(){
      if(this.secondname_view == true){
        let field = [
          { key: "#", label: "#", sortable: false },
          { key: "name", label: "Name", sortable: true },
          { key: "secondary_name", label: "Secondary Name", sortable: false },
          { key: "status", label: "Status", sortable: false },
          { key: "action", label: "Action", sortable: false }
        ];
        this.fields = field;
      }else{
        let field = [{ key: "#", label: "#", sortable: false },
        { key: "name", label: "Name", sortable: true },
        { key: "status", label: "Status", sortable: false },
        { key: "action", label: "Action", sortable: false }];
        this.fields = field;
      }
    }
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if (this.$auth.partner_login) {
        return this.fields.filter((field) => !field.requiresAdmin);
      } else {
        return this.fields;
      }
    },
    // If the user IS an admin, return all fields.
  },
  mounted() {
    this.secondName();
    this.getRestaurantTag();
    this.dynamic_field();
  },
};
</script>

<template>
  <div v-if="$auth.partner_login">
    <Layout>
      <PageHeader title="Tag" />
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="row">
              <div
                class="
                  col-8
                  table-main-list table-responsive
                  vendor-category
                  custom-table
                "
              >
                <div class="card task-box custom-tableheader ml-2">
                  <div class="card-body menu-items">
                    <div class="container">
                      <div class="row d-flex">
                        <div class="col-sm-4">
                          <label>{{ trans.get("__JSON__.Status") }} :</label>
                          <select
                            class="custom-select"
                            v-model="listing_status"
                            @change="searchData()"
                          >
                            <option value selected>
                              {{ trans.get("__JSON__.All") }}
                            </option>
                            <option value="0">
                              {{ trans.get("__JSON__.Active") }}
                            </option>
                            <option value="1">
                              {{ trans.get("__JSON__.Inactive") }}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-4">
                          <div class="search-box d-inline-block">
                            <div class="date-range-list">
                              <label
                                >{{ trans.get("__JSON__.Search") }} :</label
                              >
                              <div class="position-relative">
                                <input
                                  type="text"
                                  class="form-control"
                                  @keyup="searchData()"
                                  v-model="search"
                                  :placeholder="trans.get('__JSON__.Search...')"
                                />
                                <i class="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 pr-0">
                          <button
                            type="button"
                            class="btn btn-success btn-rounded mr-2 custome_btn float-right"
                            @click="addTagFrm"
                          >
                            <i class="mdi mdi-plus mr-1"></i>
                            {{ trans.get("__JSON__.Add Tag") }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="container tagtable">
                      <b-table
                        
                        :fields="computedFields"
                        :items="data"
                        :sort-by.sync="sortBy"
                        :no-local-sorting="true"
                        :busy="loading"
                        show-empty
                        responsive
                        @row-clicked="onRowClicked"
                        @sort-changed="sortingChanged"
                        selected-variant="secondary"
                        select-mode="single"
                        selectable
                      >
                        <!-- 
                          selectable
                          selected-variant="info"
                      -->
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>{{
                              trans.get("__JSON__.Loading...")
                            }}</strong>
                          </div>
                        </template>
                        <template v-slot:cell(#)="data">
                        <div v-if="data.index >= 0">
                          {{(currentPage*5)-5 + data.index+1}}
                        </div>
                        </template>
                        <template v-slot:cell(name)="data">
                          <div v-if="data.item.name">
                            <button
                              type="button"
                              class="btn btn-rounded btn-sm"
                              :style="{
                                color: data.item.text_color,
                                backgroundColor: data.item.background_color,
                              }"
                            >
                              {{ StringSpt(data.item.name) }}
                            </button>
                          </div>
                        </template>
                        <template v-slot:cell(secondary_name)="data">
                            <div v-if="secondname_view">
                                <div v-if="data.item.secondary_name">
                                  <button
                                type="button"
                                class="btn btn-rounded btn-sm"
                                :style="{
                                  color: data.item.text_color,
                                  backgroundColor: data.item.background_color,
                                }"
                              >
                                  {{ StringSpt(data.item.secondary_name) }}
                              </button>
                              </div>
                            </div>
                          </template>
                        <template v-slot:cell(status)="data">
                          <div class="table-heading mr-4">
                            <div
                              v-if="data.item.status == '0'"
                              class="
                                badge badge-pill badge-soft-success
                                font-size-12
                              "
                            >
                              {{ trans.get("__JSON__.Active") }}
                            </div>
                            <div
                              v-else
                              class="
                                badge badge-pill badge-soft-danger
                                font-size-12
                              "
                            >
                              {{ trans.get("__JSON__.Inactive") }}
                            </div>
                          </div>
                        </template>
                        <template v-slot:cell(action)="data">
                          <div class="table-heading mr-4 w-50">
                            <button
                              type="submit"
                              @click="deleteTag(data.item.id)"
                              class="btn btn-danger btn-sm btn-rounded ml-1"
                            >
                              {{ trans.get("__JSON__.Delete") }}
                            </button>
                          </div>
                        </template>
                        <template #empty>
                          <p class="text-center">
                            {{ trans.get("__JSON__.No Tag Found") }}
                          </p>
                        </template>
                      </b-table>
                      <div class="row col-sm-5 offset-sm-7">
                        <div class="col">
                          <div
                            class="
                              dataTables_paginate
                              paging_simple_numbers
                              float-right
                            "
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <pagination
                                :data="paginations"
                                :limit="limit"
                                @pagination-change-page="getRestaurantTag"
                              ></pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- add and edit tag -->
              <div class="col-4" v-if="addTagView">
                <div class="card custome_frm">
                  <div class="card-body">
                    <b-form @submit.prevent="AddTag">
                      <h4>Add Tag</h4>
                      <b-form-group
                        id="input-group-1"
                        :label="trans.trans('__JSON__.Tag Name')"
                        label-for="name"
                        label-class="required"
                      >
                        <b-form-input
                          id="name"
                          name="name"
                          ref="name"
                          v-model="$v.typeform.name.$model"
                          type="text"
                          :placeholder="trans.trans('__JSON__.Enter Tag Name')"
                          :class="{ 'is-invalid': $v.typeform.name.$error }"
                        ></b-form-input>
                        <div
                          v-if="$v.typeform.name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.name.required"
                            >{{
                              trans.get("__JSON__.Please Enter Tag Name")
                            }}.</span
                          >
                        </div>
                      </b-form-group>
                      <div v-if="secondname_view">
                      <b-form-group
                      id="input-group-1"
                      :label="trans.trans('__JSON__.Tag Name')+'('+second_name+')'"
                      label-for="name"
                      label-class="required"
                    >
                      <b-form-input
                        id="name"
                        name="second_name"
                        ref="second_name"
                        v-model="$v.typeform.name_another_lang.$model"
                        type="text"
                        :placeholder="trans.trans('__JSON__.Enter Second Tag Name')"
                        :class="{ 'is-invalid': $v.typeform.name_another_lang.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.typeform.name_another_lang.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.typeform.name_another_lang.required"
                          >{{
                            trans.get("__JSON__.Please Enter Tag Name")
                          }}.</span
                        >
                      </div>
                    </b-form-group>
                      
                    </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-6">
                              <b-form-group
                                id="input-group-2"
                                :label="
                                  trans.get('__JSON__.Backgorund Color Code')
                                "
                                label-for="Backgorund Color Code"
                                class="mt-3"
                              >
                                <b-form-input
                                  type="color"
                                  id="colorpickers1"
                                  v-model="typeform.color_code"
                                  name="color_code"
                                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                  value="#f2f3f4"
                                />
                              </b-form-group>
                            </div>
                            <div class="col-md-6">
                              <b-form-group
                                id="input-group-2"
                                :label="trans.get('__JSON__.Text Color Code')"
                                label-for="Text Color Code"
                                class="mt-3"
                              >
                                <b-form-input
                                  type="color"
                                  id="colorpickers1"
                                  v-model="typeform.text_color_code"
                                  name="text_color_code"
                                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                  value="#000000"
                                />
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <label class="custome_txt">Status</label>
                          <div class="swtich-data">
                            <label class="switch">
                              <b-form-group
                                id="input-group-2"
                                :label="trans.get('__JSON__.Status')"
                                label-for="Status"
                                class="mt-3"
                              >
                                <input
                                  type="checkbox"
                                  id="togBtn"
                                  class="switch-on"
                                  name="status"
                                  v-model="typeform.status"
                                  true-value="0"
                                  false-value="1"
                                />
                                <div class="slider round custome_switch">
                                  <!--ADDED HTML -->
                                  <span class="on font-weight-bold">ON</span>
                                  <span class="off font-weight-bold">OFF</span>
                                  <!--END-->
                                </div>
                              </b-form-group>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="w-100 mt-3" v-if="Addbtndisable">
                            <b-button variant="primary" class="float-left mt-3" disabled>
                              <b-spinner small type="grow"></b-spinner>Loading...
                            </b-button>
                          </div>
                          <div v-else class="w-100 mt-3">
                              <b-button
                              variant="primary"
                              class="float-left mt-3"
                              @click="AddTag"
                            >
                              {{ trans.get(`__JSON__.Add`) }}
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>
              <div class="col-4" v-else>
                <div class="card custome_frm">
                  <div class="card-body">
                    <b-form @submit.prevent="UpdateTag">
                      <h4>Edit Tag</h4>
                      <b-form-group
                        id="input-group-1"
                        :label="trans.trans('__JSON__.Tag Name')"
                        label-for="name"
                        label-class="required"
                      >
                        <b-form-input
                          id="name"
                          name="name"
                          ref="name"
                          v-model="$v.edit_typeform.name.$model"
                          type="text"
                          :placeholder="trans.trans('__JSON__.Enter Tag Name')"
                          :class="{
                            'is-invalid': $v.edit_typeform.name.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="$v.edit_typeform.name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.edit_typeform.name.required"
                            >{{
                              trans.get("__JSON__.Please Enter Tag Name")
                            }}.</span
                          >
                        </div>
                      </b-form-group>
                      <div v-if="secondname_view">
                      <b-form-group
                      id="input-group-1"
                      :label="trans.trans('__JSON__.Tag Name')+'('+second_name+')'"
                      label-for="name"
                      label-class="required"
                    >
                      <b-form-input
                        id="name"
                        name="second_name"
                        ref="second_name"
                        v-model="$v.edit_typeform.name_another_lang.$model"
                        type="text"
                        :placeholder="trans.trans('__JSON__.Enter Second Tag Name')"
                        :class="{ 'is-invalid': $v.edit_typeform.name_another_lang.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.edit_typeform.name_another_lang.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.edit_typeform.name_another_lang.required"
                          >{{
                            trans.get("__JSON__.Please Enter Tag Name")
                          }}.</span
                        >
                      </div>
                    </b-form-group>
                      
                    </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-6">
                              <b-form-group
                                id="input-group-2"
                                :label="
                                  trans.get('__JSON__.Backgorund Color Code')
                                "
                                label-for="Backgorund Color Code"
                                class="mt-3"
                              >
                                <b-form-input
                                  type="color"
                                  id="colorpickers1"
                                  v-model="edit_typeform.color_code"
                                  name="color_code"
                                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                  value="#f2f3f4"
                                />
                              </b-form-group>
                            </div>
                            <div class="col-md-6">
                              <b-form-group
                                id="input-group-2"
                                :label="trans.get('__JSON__.Text Color Code')"
                                label-for="Text Color Code"
                                class="mt-3"
                              >
                                <b-form-input
                                  type="color"
                                  id="colorpickers1"
                                  v-model="edit_typeform.text_color_code"
                                  name="text_color_code"
                                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                  value="#000000"
                                />
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <label class="custome_txt">Status</label>
                          <div class="swtich-data">
                            <label class="switch">
                              <b-form-group
                                id="input-group-2"
                                :label="trans.get('__JSON__.Status')"
                                label-for="Status"
                                class="mt-3"
                              >
                                <input
                                  type="checkbox"
                                  id="togBtn"
                                  class="switch-on"
                                  name="status"
                                  v-model="edit_typeform.status"
                                  true-value="0"
                                  false-value="1"
                                />
                                <div class="slider round custome_switch">
                                  <!--ADDED HTML -->
                                  <span class="on">ON</span>
                                  <span class="off">OFF</span>
                                  <!--END-->
                                </div>
                              </b-form-group>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="w-100 mt-3" v-if="Updatebtndisable">
                            <b-button variant="primary" class="float-left mt-3" disabled>
                              <b-spinner small type="grow"></b-spinner>Loading...
                            </b-button>
                          </div>
                          <div v-else class="w-100 mt-3">
                            <b-button
                              variant="primary"
                              class="float-left mt-3"
                              @click="UpdateTag"
                            >
                              {{ trans.get(`__JSON__.Update`) }}
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>
              <!-- add and edit tag -->
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>

  <!-- else partt -->
  <div v-else>
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
         
          <div class="row">
            <div
              class="
                col-8
                table-main-list table-responsive
                vendor-category
                custom-table
              "
            >
              <div class="card task-box custom-tableheader ml-2">
                <div class="card-body menu-items">
                  <div class="container">
                    <div class="row d-flex">
                      <div class="col-sm-4">
                        <label>{{ trans.get("__JSON__.Status") }} :</label>
                        <select
                          class="custom-select"
                          v-model="listing_status"
                          @change="searchData()"
                        >
                          <option value selected>
                            {{ trans.get("__JSON__.All") }}
                          </option>
                          <option value="0">
                            {{ trans.get("__JSON__.Active") }}
                          </option>
                          <option value="1">
                            {{ trans.get("__JSON__.Inactive") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <div class="search-box d-inline-block">
                          <div class="date-range-list">
                            <label>{{ trans.get("__JSON__.Search") }} :</label>
                            <div class="position-relative">
                              <input
                                type="text"
                                class="form-control"
                                @keyup="searchData()"
                                v-model="search"
                                :placeholder="trans.get('__JSON__.Search...')"
                              />
                              <i class="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4 pr-0">
                        <button
                          type="button"
                          class="btn btn-success btn-rounded mr-2 custome_btn float-right"
                          @click="addTagFrm"
                        >
                          <i class="mdi mdi-plus mr-1"></i>
                          {{ trans.get("__JSON__.Add Tag") }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="container tagtable">
                    <b-table
                      :fields="computedFields"
                      :items="data"
                      :sort-by.sync="sortBy"
                      :no-local-sorting="true"
                      :busy="loading"
                      show-empty
                      responsive
                      @row-clicked="onRowClicked"
                      @sort-changed="sortingChanged"
                      @click="rowClass"
                      selected-variant="secondary"
                      select-mode="single"
                      selectable
                    >
                      <!-- 
                          selected-variant="info"
                          selectable
                      -->
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>{{
                            trans.get("__JSON__.Loading...")
                          }}</strong>
                        </div>
                      </template>
                      <template v-slot:cell(#)="data">
                        <div v-if="data.index >= 0">
                          {{(currentPage*5)-5 + data.index+1}}
                        </div>
                      </template>
                      <template v-slot:cell(name)="data">
                        <div v-if="data.item.name">
                          <button
                            type="button"
                            class="btn btn-rounded btn-sm"
                            :style="{
                              color: data.item.text_color,
                              backgroundColor: data.item.background_color,
                            }"
                          >
                            {{ StringSpt(data.item.name) }}
                          </button>
                        </div>
                      </template>
                       <template v-slot:cell(secondary_name)="data">
                            <div v-if="secondname_view">
                                <div v-if="data.item.secondary_name">
                                  <button
                                type="button"
                                class="btn btn-rounded btn-sm"
                                :style="{
                                  color: data.item.text_color,
                                  backgroundColor: data.item.background_color,
                                }"
                              >
                                  {{ StringSpt(data.item.secondary_name) }}
                              </button>
                              </div>
                            </div>
                        </template>
                      <template v-slot:cell(status)="data">
                        <div class="table-heading mr-4">
                          <div
                            v-if="data.item.status == '0'"
                            class="
                              badge badge-pill badge-soft-success
                              font-size-12
                            "
                          >
                            {{ trans.get("__JSON__.Active") }}
                          </div>
                          <div
                            v-else
                            class="
                              badge badge-pill badge-soft-danger
                              font-size-12
                            "
                          >
                            {{ trans.get("__JSON__.Inactive") }}
                          </div>
                        </div>
                      </template>
                      <template v-slot:cell(action)="data">
                        <div class="table-heading mr-4 w-50">
                          <button
                            type="submit"
                            @click="deleteTag(data.item.id)"
                            class="btn btn-danger btn-sm btn-rounded ml-1"
                          >
                            {{ trans.get("__JSON__.Delete") }}
                          </button>
                        </div>
                      </template>
                      <template #empty>
                        <p class="text-center">
                          {{ trans.get("__JSON__.No Tag Found") }}
                        </p>
                      </template>
                    </b-table>
                    <div class="row col-sm-5 offset-sm-7">
                      <div class="col">
                        <div
                          class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-right
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <pagination
                              :data="paginations"
                              :limit="limit"
                              @pagination-change-page="getRestaurantTag"
                            ></pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- add and edit tag -->
            <div class="col-4" v-if="addTagView">
              <div class="card custome_frm">
                <div class="card-body">
                  <b-form @submit.prevent="AddTag">
                    <h4>Add Tag</h4>
                    <b-form-group
                      id="input-group-1"
                      :label="trans.trans('__JSON__.Tag Name')"
                      label-for="name"
                      label-class="required"
                    >
                      <b-form-input
                        id="name"
                        name="name"
                        ref="name"
                        v-model="$v.typeform.name.$model"
                        type="text"
                        :placeholder="trans.trans('__JSON__.Enter Tag Name')"
                        :class="{ 'is-invalid': $v.typeform.name.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.typeform.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.typeform.name.required"
                          >{{
                            trans.get("__JSON__.Please Enter Tag Name")
                          }}.</span
                        >
                      </div>
                    </b-form-group>
                    <div v-if="secondname_view">
                      <b-form-group
                      id="input-group-1"
                      :label="trans.trans('__JSON__.Tag Name')+'('+second_name+')'"
                      label-for="name"
                      label-class="required"
                    >
                      <b-form-input
                        id="name"
                        name="second_name"
                        ref="second_name"
                        v-model="$v.typeform.name_another_lang.$model"
                        type="text"
                        :placeholder="trans.trans('__JSON__.Enter Second Tag Name')"
                        :class="{ 'is-invalid': $v.typeform.name_another_lang.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.typeform.name_another_lang.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.typeform.name_another_lang.required"
                          >{{
                            trans.get("__JSON__.Please Enter Tag Name")
                          }}.</span
                        >
                      </div>
                    </b-form-group>
                      
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <b-form-group
                              id="input-group-2"
                              :label="
                                trans.get('__JSON__.Backgorund Color Code')
                              "
                              label-for="Backgorund Color Code"
                              class="mt-3"
                            >
                              <b-form-input
                                type="color"
                                id="colorpickers1"
                                v-model="typeform.color_code"
                                name="color_code"
                                pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                value="#f2f3f4"
                              />
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              id="input-group-2"
                              :label="trans.get('__JSON__.Text Color Code')"
                              label-for="Text Color Code"
                              class="mt-3"
                            >
                              <b-form-input
                                type="color"
                                id="colorpickers1"
                                v-model="typeform.text_color_code"
                                name="text_color_code"
                                pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                value="#000000"
                              />
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <label class="custome_txt">Status</label>
                        <div class="swtich-data">
                          <label class="switch">
                            <b-form-group
                              id="input-group-2"
                              :label="trans.get('__JSON__.Status')"
                              label-for="Status"
                              class="mt-3"
                            >
                              <input
                                type="checkbox"
                                id="togBtn"
                                class="switch-on"
                                name="status"
                                v-model="typeform.status"
                                true-value="0"
                                false-value="1"
                              />
                              <div class="slider round custome_switch">
                                <!--ADDED HTML -->
                                <span class="on font-weight-bold">ON</span>
                                <span class="off font-weight-bold">OFF</span>
                                <!--END-->
                              </div>
                            </b-form-group>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="w-100 mt-3" v-if="Addbtndisable">
                            <b-button variant="primary" class="float-left mt-3" disabled>
                              <b-spinner small type="grow"></b-spinner>Loading...
                            </b-button>
                          </div>
                          <div v-else class="w-100 mt-3">
                              <b-button
                              variant="primary"
                              class="float-left mt-3"
                              @click="AddTag"
                            >
                              {{ trans.get(`__JSON__.Add`) }}
                            </b-button>
                          </div>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
            <div class="col-4" v-else>
              <div class="card custome_frm">
                <div class="card-body">
                  <b-form @submit.prevent="UpdateTag">
                    <h4>Edit Tag</h4>
                    <b-form-group
                      id="input-group-1"
                      :label="trans.trans('__JSON__.Tag Name')"
                      label-for="name"
                      label-class="required"
                    >
                      <b-form-input
                        id="name"
                        name="name"
                        ref="name"
                        v-model="$v.edit_typeform.name.$model"
                        type="text"
                        :placeholder="trans.trans('__JSON__.Enter Tag Name')"
                        :class="{
                          'is-invalid': $v.edit_typeform.name.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.edit_typeform.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.edit_typeform.name.required"
                          >{{
                            trans.get("__JSON__.Please Enter Tag Name")
                          }}.</span
                        >
                      </div>
                    </b-form-group>
                    <div v-if="secondname_view">
                      <b-form-group
                      id="input-group-1"
                      :label="trans.trans('__JSON__.Tag Name')+'('+second_name+')'"
                      label-for="name"
                      label-class="required"
                    >
                      <b-form-input
                        id="name"
                        name="second_name"
                        ref="second_name"
                        v-model="$v.edit_typeform.name_another_lang.$model"
                        type="text"
                        :placeholder="trans.trans('__JSON__.Enter Second Tag Name')"
                        :class="{ 'is-invalid': $v.edit_typeform.name_another_lang.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.edit_typeform.name_another_lang.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.edit_typeform.name_another_lang.required"
                          >{{
                            trans.get("__JSON__.Please Enter Tag Name")
                          }}.</span
                        >
                      </div>
                    </b-form-group>
                      
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <b-form-group
                              id="input-group-2"
                              :label="
                                trans.get('__JSON__.Backgorund Color Code')
                              "
                              label-for="Backgorund Color Code"
                              class="mt-3"
                            >
                              <b-form-input
                                type="color"
                                id="colorpickers1"
                                v-model="edit_typeform.color_code"
                                name="color_code"
                                pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                value="#f2f3f4"
                              />
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              id="input-group-2"
                              :label="trans.get('__JSON__.Text Color Code')"
                              label-for="Text Color Code"
                              class="mt-3"
                            >
                              <b-form-input
                                type="color"
                                id="colorpickers1"
                                v-model="edit_typeform.text_color_code"
                                name="text_color_code"
                                pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                value="#000000"
                              />
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <label class="custome_txt">Status</label>
                        <div class="swtich-data">
                          <label class="switch">
                            <b-form-group
                              id="input-group-2"
                              :label="trans.get('__JSON__.Status')"
                              label-for="Status"
                              class="mt-3"
                            >
                              <input
                                type="checkbox"
                                id="togBtn"
                                class="switch-on"
                                name="status"
                                v-model="edit_typeform.status"
                                true-value="0"
                                false-value="1"
                              />
                              <div class="slider round custome_switch">
                                <!--ADDED HTML -->
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                                <!--END-->
                              </div>
                            </b-form-group>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="w-100 mt-3" v-if="Updatebtndisable">
                          <b-button variant="primary" class="float-left mt-3" disabled>
                            <b-spinner small type="grow"></b-spinner>Loading...
                          </b-button>
                        </div>
                        <div class="w-100 mt-3" v-else>
                          <b-button
                            variant="primary"
                            class="float-left mt-3"
                            @click="UpdateTag"
                          >
                            {{ trans.get(`__JSON__.Update`) }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
            <!-- add and edit tag -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- else partt -->
</template>    
<style>
.custome_frm {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  min-height: 390px;
}
.custome_switch{
  padding: 13px !important;
}
.custome_switch:before {
  top: 3px !important;
}
.custom-tableheader .card-body {
  background-color: #fefefe !important;
}
.custome_btn {
  position: relative;
  top: 26px;
}
.tagtable .table-secondary > td {
  background-color: #2a3042 !important;
}
.custome_txt{
  position: relative;
  top: 18px;
  color: #666666;
  font-weight: 600;
}
</style>