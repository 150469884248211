<script>
import pagination from "laravel-vue-pagination";

import config from "../../../config";

import { success_message, error_message } from "../../../Helper/helper";

import {
  required,
} from "vuelidate/lib/validators";
import { apiMessageTextService } from "../../../services";
/**
 * Starter component
 */
export default {
  components: { pagination },
  data() {
    return {
      addModal: false,
      sortBy: "id",
      limit: 1,
      config: config,
      apitextmessage: [],
      paginations: {},
      typeform: {
        key: "",
        message: "",
        message_another_lang: "",
      },
      list: [{
        message: "",
        message_another_lang: "",
      }],
    };
  },
  validations: {
    typeform: {
      key: {
        required,
      },
      message: {
        required,
      }
    }
  },
  mounted() {
    this.getApiTextList();
  },
  methods: {
    getApiTextList(page) {
      apiMessageTextService
        .getApiMessageTextListing({
          page: page ? page : 1,
        })
        .then((response) => {
          this.paginations = response.data.result;
          this.apitextmessage = response.data.result.data;
        });
    },
    apitextInIt() {
      this.$v.$touch();

      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("key", this.typeform.key);
        fd.append("message", this.typeform.message);
        fd.append("message_another_lang", this.typeform.message_another_lang);
        apiMessageTextService.addApiMessageText(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.addModal = false;
            this.getApiTextList();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    updatetextInIt() {
        const fd = new FormData();
        fd.append('list',JSON.stringify(this.apitextmessage));
        apiMessageTextService.updateApiMessageText(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getApiTextList();
          } else {
            error_message(response.data.message);
          }
        });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body settings-main">
          <div class="row sections-main">
            <div class="col-md-12">
              <div class="text-sm-right">
                <button @click="addModal = true" class="btn btn-success btn-rounded mb-2 mr-2" v-if="$auth.hasPermission(config.permissions.terminology_write)">
                  <i class="mdi mdi-plus mr-1"></i>
                  {{ trans.get('__JSON__.Add Text Message') }}
                </button>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div class="row">
                <div class="col-md-4" v-if="$auth.setting">
                  <h6>{{ trans.get('__JSON__.Key') }}</h6>
                </div>
                <div class="col-md-4">
                  <h6>{{ trans.trans('__JSON__.Primary Message', { lang: $auth.langauges[0].name }) }}</h6>
                </div>
                <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                </div>
                <div class="col-md-4" v-else>
                  <h6>{{ trans.trans('__JSON__.Secondary Message', { lang: $auth.langauges[1].name }) }}</h6>
                </div>
              </div>
            </div>
          </div>

          <b-form @submit.prevent="updatetextInIt">
            <div class="row sections-main" v-for="(list,index) in apitextmessage" :key="index">
              <div class="col-md-12">
                <div class="row align-items-center api-text-list">
                  <div class="col-md-4">
                    <h6>{{list.key}}</h6>
                  </div>
                  <input type="hidden" v-model="list.id" name="id" />
                  <div class="col-md-4">
                    <b-form-group id="input-group-1">
                      <b-form-input
                        v-model="list.message"
                        name="message[]"
                        id="message"
                        type="text"
                      ></b-form-input>
                
                    </b-form-group>
                  </div>
                  <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4" v-else>
                    <b-form-group id="input-group-1">
                      <b-form-input
                        v-model="list.message_another_lang"
                        name="message_another_lang[]"
                        id="message"
                        type="text"
                      ></b-form-input>
                    
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <b-button
                  variant="primary"
                  v-if="$auth.hasPermission(config.permissions.terminology_write)"
                  @click="updatetextInIt"
                  class="float-left mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</b-button>
              </div>
            </div>
          </b-form>

          <div class="row col-sm-5 offset-sm-7 mt-3">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <pagination
                    :data="paginations"
                    :limit="limit"
                    @pagination-change-page="getApiTextList"
                  ></pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Api Text Modal Start -->

    <b-modal
      id="modal-scoped"
      v-model="addModal"
      :title="this.trans.get('__JSON__.Add Terminology')"
      centered
    >
      <b-form @submit.prevent="apitextInIt">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              id="input-group-1"
              :label="this.trans.get('__JSON__.key Values')"
              label-for="key"
            >
              <b-form-input
                id="key"
                name="key"
                v-model="$v.typeform.key.$model"
                type="text"
                :placeholder="this.trans.get('__JSON__.Enter Key Name')"
                :class="{ 'is-invalid': $v.typeform.key.$error }"
              ></b-form-input>
              <div v-if="$v.typeform.key.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.key.required"
                >{{ trans.get('__JSON__.Please enter key name') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Primary Message', { lang: $auth.langauges[0].name })"
              label-for="message"
            >
              <b-form-input
                id="message"
                name="message"
                v-model="$v.typeform.message.$model"
                type="text"
                :placeholder="trans.trans('__JSON__.Primary Message', { lang: $auth.langauges[0].name })"
                :class="{ 'is-invalid': $v.typeform.message.$error }"
              ></b-form-input>
              <div v-if="$v.typeform.message.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.message.required"
                >{{ trans.get('__JSON__.Please enter message') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-md-12 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
          </div>
          <div class="col-md-12" v-else>
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Secondary Message', { lang: $auth.langauges[1].name })"
              label-for="message_another_lang"
            >
              <b-form-input
                id="message_another_lang"
                name="message_another_lang"
                v-model="typeform.message_another_lang"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-form>
      <template slot="modal-footer">
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            v-if="$auth.hasPermission(config.permissions.terminology_write)"
            @click="apitextInIt"
          >{{ trans.get('__JSON__.Submit') }}</b-button>
          <b-button class="float-left" @click="addModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
        </div>
      </template>
    </b-modal>
    <!-- Add Add Api Text Modal End -->
  </div>
</template>