import config from "./index";

const routes = {
    //login
    login: config.api_url + "login",

    //signup
    signupApi: config.api_url + "signup",

    //logout
    logout: config.api_url + "logout",

    //UploadDocument
    uploadImageApi: config.api_url + "upload-image",
    removeFileApi: config.api_url + "remove-File",
    uploadDocumentApi: config.api_url + "upload-document",
    uploadApi: config.api + "upload",
    uploadDriveProfileApi: config.api_url + "update-driver-profile",
    uploadProfileImageApi: config.api + "update-driver-profile",

    //Setting
    getSettingApi: config.api_url + "get-setting",
    updateSettingApi: config.api_url + "update-setting",
    getAppLayoutSettingApi: config.api_url + "get-app-layout-setting",
    updateAppLayoutApi: config.api_url + "update-app-layout-setting",
    updateGeneralTerminologyApi:
        config.api_url + "update-general-terminology-setting",
    getOrderNotificationTextApi: config.api_url + "get-order-notification",
    getSmsTextApi: config.api_url + "get-sms-notification",
    updateSmsTextApi: config.api_url + "update-sms-notification",
    updateOrderTextApi: config.api_url + "update-text-notification",
    updatepaymentgatewayApi: config.api_url + "update-payment-gateway",
    updateReferralApi: config.api_url + "update-referral",
    imageDeleteApi: config.api_url + "icon-image-delete",
    updateWeatherMessageApi: config.api_url + "update-weather-message",

    //UserDetail
    userDetailApi: config.api_url + "user-detail",
    userCheckEmailApi: config.api_url + "user-check-email",

    //ApiText
    getApiTextApi: config.api_url + "get-api-text",
    addApiTextApi: config.api_url + "add-api-text",
    updateApiTextApi: config.api_url + "update-api-text",

    //Customer
    getCustomerApi: config.api_url + "get-customer",
    customerExportToCsvApi: config.api_url + "customer-export-csv",
    getCustomerProfileApi: config.api_url + "get-customer-profile",
    updateCustomerProfileApi: config.api_url + "update-customer-profile",
    getCustomerOrderApi: config.api_url + "get-customer-order",
    codStatusUpdateApi: config.api_url + "customer/cod-status-update",

    //Chowman Pay
    getChowmanPayApi: config.api_url + "get-chowman-pay",
    getTheChowman: config.api_url + "get-the-chowman-pay",
    chowmanPayExportToCsvApi: config.api_url + "chowman-pay-export-csv",
    chowmanPayRefundPaymentApi: config.api_url + "chowman-pay-refund",

    // Gift Voucher
    getGiftVoucherApi: config.api_url + "get-gift-vouchers",
    getPurchasedGiftVoucherApi: config.api_url + "get-purchased-gift-vouchers",
    addGiftVoucherApi: config.api_url + "add-gift-voucher",
    getGiftVoucherByIdApi: config.api_url + "get-gift-vouchers-by-id",
    changeGiftVoucherStatusApi: config.api_url + "change-gift-vouchers-status",
    exportGiftVouchersApi: config.api_url + "export-gift-vouchers",
    deleteGiftVoucherImageApi: config.api_url + "delete-gift-voucher-image",
    updateGiftVoucherApi: config.api_url + "update-gift-voucher",
    //chowmanPayExportToCsvApi: config.api_url+'chowman-pay-export-csv',
    //Driver
    getDriverApi: config.api_url + "get-driver",
    driverExportToCsvApi: config.api_url + "driver-export-csv",
    getDriverInfoApi: config.api_url + "get-driver-info",
    getFrenchiseApi: config.api_url + "get-frenchisee",
    addDriverApi: config.api_url + "add-driver",
    getDriverRatingApi: config.api_url + "get-driver-rating",
    updateDriveProfileApi: config.api_url + "update-driver-profile",
    driverProfileDeleteUrl: config.api_url + "driver/delete-profile-by-name",
    driverDocumentDeleteUrl: config.api_url + "driver/delete-document-by-name",
    driverLicenceDeleteUrl: config.api_url + "driver/delete-licence-by-name",
    driverByAvailabilityApi: config.api_url + "driver/availablilty/get",
    updateDriverStatusApi: config.api_url + "update-driver-status",
    updateAvailbilityStatusApi:
        config.api_url + "update-driver-availbilty-status",
    changeDriverPasswordApi: config.api_url + "change-driver-password",
    // getDriverDashboardApi: config.api_url+'get-driver-dashboard',
    // Eagleview
    eagleViewDriverListApi: config.api_url + "get-eagleview",

    //Vendor
    getVendorApi: config.api_url + "get-vendor",
    getVendorDetailApi: config.api_url + "restaurant-export-csv",
    getVendorCategoryApi: config.api_url + "get-vendor-category",
    getRestaurantCategoryApi: config.api_url + "get-res-category",
    getRestaurantCuisineApi: config.api_url + "get-res-cuisine",
    getRestaurantbyIdApi: config.api_url + "get-restaurant-detail-by-id",
    changePasswordApi: config.api_url + "change-password",
    addVendorApi: config.api_url + "add-restaurant",
    addRestaurantCategoryApi: config.api_url + "add-menu-category",
    getMenuCategoryByIdApi: config.api_url + "get-category-menu-by-id",
    updateRestaurantCategoryApi: config.api_url + "update-menu-category",
    deleteMenuCategoryApi: config.api_url + "delete-menu-category",
    vendorImageDeleteUrl: config.api_url + "restaurant/delete-icon-by-name",
    categroyImageDeleteUrl:
        config.api_url + "restaurant/delete-category-image-by-name",
    getBusinessProfileApi: config.api_url + "get-business-profile",
    getLanguageApi: config.api_url + "get-language",
    updateBusinessProfileApi: config.api_url + "update-business-profile",
    getVendorLanguageApi: config.api_url + "get-vendor-language",
    getRestaurantMenuApi: config.api_url + "restaurant/menu",
    getItemByMenuIDApi: config.api_url + "restaurant/menu/item",
    deleteAppLogoApi: config.api_url + "delete-app-logo",
    deleteFlatIconApi: config.api_url + "delete-flat-icon",
    addMenuApi: config.api_url + "add-menu",
    editMenuApi: config.api_url + "edit-menu",
    getMenuByIdApi: config.api_url + "get-menu-by-id",
    resImageDeleteUrl: config.api_url + "delete-menu-image",
    updateMenuStatusApi: config.api_url + "update-menu-status",
    addItmeMenuApi: config.api_url + "add-menu-item",
    getResTimeSlotApi: config.api_url + "get-res-time-slot",
    getMenuCategoriesApi: config.api_url + "get-menu-categrory-list",
    addTimeSlotApi: config.api_url + "add-time-slot",
    getSelingTimes: config.api_url + "get-sellingtime-slot",
    removeSellingTime: config.api_url + "remove-sellingtime-slot",
    getRestaurantOperationTimeApi: config.api_url + "vendor/get-operation-time",
    saveRestaurantOperationTimeApi:
        config.api_url + "vendor/save-operation-time",
    restaurantOnOffApi: config.api_url + "vendor/restaurant-on-off",
    getMenuItemApi: config.api_url + "get-menu-item",
    deleteMenuApi: config.api_url + "delete-menu",
    deleteMenuWithItemApi: config.api_url + "delete-menu-with-item",
    menuItemEditApi: config.api_url + "update-menu-item",
    deleteMenuItemApi: config.api_url + "delete-menu-item",
    getCustomizationApi: config.api_url + "get-item-customization",
    getMenuImageApi: config.api_url + "get-menu-item-image",
    menuImageDeleteUrl: config.api_url + "delete-menu-image-by-id",
    changeResPasswordApi: config.api_url + "change-res-password",
    importMenuItemApi: config.api_url + "import-menu-item",
    getDeliveryZonesApi: config.api_url + "vendor/delivery_zones",
    updateUserPasswordApi: config.api_url + "vendor/user/change-password",
    updateStatusApi: config.api_url + "update-status",
    updateMenuOrderApi: config.api_url + "update-menu-order",
    updateItemOrderApi: config.api_url + "update-item-order",
    updateItemAvailabilityApi: config.api_url + "update-item-availability",
    syncDatabaseApi: config.api_url + "update-database",
    clearApiCacheApi: config.api_url + "clear-api-cache",
    updateDeliveryZoneStatusApi: config.api_url + "updateDeliveryZoneStatus",
    areaStatusChangeApi: config.api_url + "areaStatusChange",
    deleteRatingApi: config.api_url + "deleteRating",

    //Cuisine
    getCuisineApi: config.api_url + "get-cuisine",
    getCuisineListApi: config.api_url + "cuisine-export-csv",
    addCuisineApi: config.api_url + "add-cuisine",
    getCuisineByIdApi: config.api_url + "get-cuisine-by-id",
    updateCuisineApi: config.api_url + "update-cuisine",
    deleteCuisineApi: config.api_url + "delete-cuisine",
    cuisineImageDeleteUrl: config.api_url + "cuisine/delete-cuisine-by-name",

    //Location
    getCountryListApi: config.api_url + "get-country-list",
    getStateListApi: config.api_url + "get-state-list",
    getCityListApi: config.api_url + "get-city-list",
    addCountryApi: config.api_url + "add-country",
    addStateApi: config.api_url + "add-state",
    addCityApi: config.api_url + "add-city",
    updateCountryApi: config.api_url + "update-country",
    updateStateApi: config.api_url + "update-state",
    updateCityApi: config.api_url + "update-city",
    getCountryByIdApi: config.api_url + "get-country-by-id",
    getStateByIdApi: config.api_url + "get-state-by-id",
    getCityByIdApi: config.api_url + "get-city-by-id",

    //Banners
    getBannerApi: config.api_url + "get-banner",
    addbannerApi: config.api_url + "add-banner",
    getRestaurantApi: config.api_url + "get-restaurant",
    getBannerByIdApi: config.api_url + "get-banner-by-id",
    updateBannerApi: config.api_url + "update-banner",
    deleteBannerApi: config.api_url + "delete-banner",
    bannerImageDeleteUrl: config.api_url + "banner/delete-banner-by-name",

    //Category
    getCategoryApi: config.api_url + "get-category",
    categoryExportToCsvApi: config.api_url + "category-export-csv",
    addCategoryApi: config.api_url + "add-category",
    getCategoryByIdApi: config.api_url + "get-category-by-id",
    updateCategoryApi: config.api_url + "update-category",
    deleteCategoryApi: config.api_url + "delete-category",
    categoryImageDeleteUrl: config.api_url + "category/delete-category-by-name",

    //PromoCode
    getPromoCodeApi: config.api_url + "get-promocode",
    promoCodeExportToCsvApi: config.api_url + "promocode-export-csv",
    addPromoCodeApi: config.api_url + "add-promocode",
    getCustomerLisingApi: config.api_url + "get-customer-listing",
    getPromoCodeByIdApi: config.api_url + "get-promocode-by-id",
    getSpecialPromoCodeByIdApi: config.api_url + "get-special-promocode-by-id",
    promoCodeImageDeleteUrl:
        config.api_url + "promocode/delete-promocode-by-name",
    promoCodeSpecialImageDeleteUrl:
        config.api_url + "promocode/delete-promocode-special-image",
    promoCodeCkImageDeleteUrl:
        config.api_url + "promocode/delete-promocode-ckimage",
    updatePromoCodeApi: config.api_url + "update-promocode",
    applyPromoCodeApi: config.app_api_url + "coupon_redeem",
    getSpecialPromoCodeApi: config.api_url + "get-special-promocode",
    updatePromoCodeSpecialApi: config.api_url + "update-promocode/special",
    importPromocodeApi: config.api_url + "promocode/import",
    //DeliveryZone
    getDeliveryZoneApi: config.api_url + "get-delivery-zone",
    getVendorCountryApi: config.api_url + "get-vendor-country",
    getVendorStateApi: config.api_url + "get-vendor-state",
    getVendorCityApi: config.api_url + "get-vendor-city",
    getAllStateApi: config.api_url + "get-all-state",
    getAllCityApi: config.api_url + "get-all-city",
    addDeliveryZoneApi: config.api_url + "add-delivery-zone",
    getDeliveryInfoApi: config.api_url + "get-delivery-info",
    editDeliveryZoneApi: config.api_url + "edit-delivery-zone",
    zoneExportCsvApi: config.api_url + "zoneExportCsv",
    getZoneRestaurantApi: config.api_url + "getZoneRestaurant",
    deleteFranchiseRestByIdApi: config.api_url + "deleteFranchiseRestById",
    addDeliveryZoneAreaApi: config.api_url + "add-delivery-zone-area",
    deleteRestAreaIdApi: config.api_url + "delete-rest-area-id",
    getRestAreaByIdApi: config.api_url + "get-rest-area-by-id",
    editDeliveryZoneAreaApi: config.api_url + "edit-delivery-zone-area",
    getSearchRestApi: config.api_url + "get-search-rest",
    addFranchiseRestApi: config.api_url + "add-franchise-rest",

    //Restaurant
    getRestaurantListApi: config.api_url + "get-restaurant-list",
    getRestaurantDetailApi: config.api_url + "restaurant-export-csv",
    getRestaurantCategoryListApi: config.api_url + "get-res-category",
    addRestaurantApi: config.api_url + "add-restaurant",
    getRestaurantById: config.api_url + "restaurant/get",
    getRatingApi: config.api_url + "get-rating",
    getBankInfoApi: config.api_url + "restaurant/bank-info",

    getDayWiseFinanceApi: config.api_url + "restaurant/finance/daywise",
    getPastDayWiseFinanceApi: config.api_url + "restaurant/finance/pastdaywise",
    getMonthwiseRestaurantEarningsApi:
        config.api_url + "restaurant/finance/monthwise",
    getOrderwiseRestaurantEarningsApi:
        config.api_url + "restaurant/finance/orderwise",
    getOrderwiseRestaurantEarningsExportApi:
        config.api_url + "restaurant/finance/orderwise/export",
    getRestaurantSettlementApi:
        config.api_url + "restaurant/finance/settlements",
    paySettlementApi: config.api_url + "restaurant/finance/pay-settlements",
    getTransactionsApi: config.api_url + "restaurant/finance/get-transactions",

    //Rating
    getRestaurantRatingApi: config.api_url + "get-restaurant-rating",
    getRestaurantAvgRatingApi: config.api_url + "get-restaurant-avg-rating",

    //VendorInfo
    updateVendorInfoApi: config.api_url + "update-vendor-info",

    //user-access
    getUserRolesApi: config.api_url + "user-access/get-all-roles",
    addUserRoleApi: config.api_url + "user-access/add-role",
    addPermissionApi: config.api_url + "user-access/add-permission",
    getPermissionsByGuardApi:
        config.api_url + "user-access/get-permission-by-user",
    changePermissionsByRoleApi:
        config.api_url + "user-access/change-permissions-by-role",
    getAccessUsersApi: config.api_url + "user-access/get-access-users",
    addUserApi: config.api_url + "user-access/add-user",
    getAllPermissionsApi: config.api_url + "user-access/get-all-permissions",
    getUserPermissionsApi: config.api_url + "user-access/get-user-permissions",
    getUserDetailApi: config.api_url + "user-access/get-user-details",
    updateUserPermissionsApi:
        config.api_url + "user-access/update-user-permissions",
    updateUserApi: config.api_url + "user-access/update-user",
    getAuthPermissionsApi: config.api_url + "user-access/get-auth-permissions",

    // Vendor Finance
    getVendorFinanceApi: config.api_url + "finance/vendor",
    getVendorFinanceCsvApi: config.api_url + "finance/csv/vendor",
    getVendorFinanceMonthApi: config.api_url + "finance-month/vendor",
    getVendorFinanceMonthCsvApi: config.api_url + "finance-month/vendor/csv",

    //upload-image
    uploadTmpImageApi: config.api_url + "upload-temp-image",
    removeTmpImageApi: config.api_url + "remove-temp-image",

    // Faq
    getFaqListingApi: config.api_url + "get-faq-listing",
    addFaqApi: config.api_url + "add-faq",
    getFaqByIdApi: config.api_url + "get-faq-by-id",
    updateFaqApi: config.api_url + "update-faq",
    deleteFaqApi: config.api_url + "delete-faq",

    // LogActivity
    getLogActivityApi: config.api_url + "get-log-activity",

    //Driver Finance
    getDriverFinanceApi: config.api_url + "driver/finance",
    getDriverFinanceMonthApi: config.api_url + "driver/finance-month",
    getDriverFinanceDaysApi: config.api_url + "driver/finance/days",
    getDriverDayWiseDataApi: config.api_url + "driver/finance/day-wise",
    getDriverSevenDayEarningApi:
        config.api_url + "driver/finance/last-seven-day",
    getDriverMonthWiseEarningApi:
        config.api_url + "driver/finance/month-earning",
    getDriverOrderWiseEarningApi:
        config.api_url + "driver/finance/order-earning",
    getDriverSettlementsApi: config.api_url + "driver/finance/settlements",
    getDriverPendingSettlementApi: config.api_url + "driver/finance/pending",
    receiveDriverSettlementApi: config.api_url + "driver/finance/receive",
    driverTransactionsApi: config.api_url + "driver/finance/get-transactions",

    //upload-ios-pushnotification-pem file
    uploadIOSPemApi: config.api_url + "upload-ios-pem",
    removeIOSPemApi: config.api_url + "remove-ios-pem",

    //Orders
    getOrdersApi: config.api_url + "orders/get",
    getNewOrdersApi: config.api_url + "orders/new/get",
    exportOrdersApi: config.api_url + "orders/export",
    getOrderApi: config.api_url + "order/get",
    getOrdersCountApi: config.api_url + "order/get/count",
    checkAvailableDriversApi: config.api_url + "delivery_zone/drivers",
    assignDriverApi: config.api_url + "order/assign_driver",
    getOrderCustomerNoteApi: config.api_url + "order/customer_note/get",
    addOrderCustomerNoteApi: config.api_url + "order/customer_note/add",
    getCustomerNotesApi: config.api_url + "order/customer_notes",

    // zomato
    getZomatoOrdersApi: config.api_url + "zomato/orders/get",
    getZomatoOrdersCountApi: config.api_url + "zomato/order/get/count",
    exportZomatoOrdersApi: config.api_url + "zomato/order/export",
    // zomato

    //Discount
    getDiscountApi: config.api_url + "get-discount",
    addDiscountApi: config.api_url + "add-discount",
    getDiscountByIdApi: config.api_url + "get-discount-by-id",
    discountImageDeleteUrl: config.api_url + "discount/delete-discount-by-name",
    updateDiscountApi: config.api_url + "update-discount",

    //Reports
    getSalesReportApi: config.api_url + "get-sale-report",
    getChowmanPayReportApi: config.api_url + "get-chowmanpay-report",
    exportChowmanPayReportApi: config.api_url + "export-chowmanpay-report",
    getDriverOrderReportApi: config.api_url + "get-driver-order-report",
    exportDriverOrderReportApi: config.api_url + "export-driver-order-report",
    saleReportExportToCsvApi: config.api_url + "sale-report-export-csv",
    getItemReportApi: config.api_url + "get-item-report",
    getCustomerReportApi: config.api_url + "get-customer-report",
    reportcustomerExportToCsvApi:
        config.api_url + "report-customer-export-to-csv",
    totalSaleReportApi: config.api_url + "get-total-sale-report",
    getAllRestuarntApi: config.api_url + "get-all-restaurant",
    taxReportReportApi: config.api_url + "get-tax-report",
    getDriverReportApi: config.api_url + "get-driver-report-listing",
    exportDriverReportApi: config.api_url + "export-driver-report-listing",
    getInactiveCustomerReportApi:
        config.api_url + "get-inactive-customer-report",
    getCODOnlineOrderReportApi: config.api_url + "get-cod-online-order-report",
    getVendorAvgRatingReportApi:
        config.api_url + "get-vendor-avg-rating-report",
    getGSTDeliveryChargeReportApi:
        config.api_url + "get-gst-delivery-charge-report",
    getRestaurantsApi: config.api_url + "get-all-restuarants",
    totalSaleReportExportToCsvApi:
        config.api_url + "total-sale-report-export-csv",
    getRestaurantsConfirmApi: config.api_url + "get-restaurants-confirm",
    taxReportExportToCsvApi: config.api_url + "tax-report-export-to-csv",
    getAllUserApi: config.api_url + "get-all-user",
    exportInactiveCustomerApi: config.api_url + "export-inactive-customer",
    exportVendorAvgRatingApi: config.api_url + "vendor-avg-export-to-csv",
    getCashbackReportApi: config.api_url + "get-cashback-report",
    getCashbackReportExportApi: config.api_url + "get-cashback-export-report",
    exportGSTDelivertReportApi: config.api_url + "gst-delivery-export-to-csv",
    exportCODOnlineReportApi: config.api_url + "export-to-cod-online",
    getPromoCodereportApi: config.api_url + "get-promo-code-report",
    getVendorRatingReportApi: config.api_url + "get-vendor-rating-report",
    getVendorDineRatingReportApi:
        config.api_url + "get-vendor-dine-rating-report",
    getVendorRatingReportExportApi: config.api_url + "get-vendor-rating-export",
    getVendorDineRatingReportExportApi:
        config.api_url + "get-vendor-dine-rating-export",
    getFoodReadyMarkReportApi: config.api_url + "get-food-ready-mark-report",
    exportFoodReadyMarkcsvApi:
        config.api_url + "food-ready-mark-report-export-csv",
    getReferEarnReportsApi: config.api_url + "refer-earn-report",
    exportReferEarncsvApi: config.api_url + "refer-earn-report-export-csv",
    getNewSignupReportApi: config.api_url + "new-signup-report",
    getNewSignupReportExportApi: config.api_url + "new-signup-report/export",
    //Dashboard
    getDashboardApi: config.api_url + "get-dashboard",
    getStatisticsApi: config.api_url + "get-stats",

    //Menu Image
    addMenuImageApi: config.api_url + "add-menu-image",

    cancelOrderApi: config.api_url + "order/cancel",
    /// APP APIS STARTED FROM HERE

    changeOrderStatusApi: config.app_api_url + "edit_order_status",
    changeOrderStatusDeliveredApi: config.app_api_url + "owner_order_status",
    ownerOrderStatusApi: config.app_api_url + "owner_order_status",
    petpoojaSyncApi: config.api_url + "place_order",
    notRespondingCustomerApi: config.api_url + "order/not_responding_customer",
    delayOrderCustomerApi: config.api_url + "order/delay_order_customer",
    checkMailtemplateActiveApi: config.api_url + "mail_template_status",

    // Dunzo
    sendToDunzoApi: config.api_url + "order/send_to_dunzo",

    // Pidge
    sendToPindgeApi: config.api_url + "order/send_to_pidge",

    //Customization
    getItemCustmizationApi: config.api_url + "get-item-customization",
    addCustmizationApi: config.api_url + "add-item-customization",
    getCustmizationTypeApi: config.api_url + "get-customization-type",
    customizationEditApi: config.api_url + "update-customization-type",
    deleteCustmizationTypeApi: config.api_url + "delete-customization-type",
    saveTemplateApi: config.api_url + "save-template",
    deleteItemCustmizationApi: config.api_url + "delete-item-customization",
    getTemplateApi: config.api_url + "get-template",
    addTemplateApi: config.api_url + "add-template",
    addMenuSelectionApi: config.api_url + "add-menu-selection",
    getSubCustomizationApi: config.api_url + "get-sub-customization",
    minSelectionApi: config.api_url + "min-selection",
    maxSelectionApi: config.api_url + "max-selection",
    addSubMenuSelectionApi: config.api_url + "add-sub-menu-selection",

    //ContectPage
    getContentPageApi: config.api_url + "get-content-page",
    addContentPageApi: config.api_url + "add-content-page",
    getContentPageByIdApi: config.api_url + "get-content-page-by-id",
    editContentPageApi: config.api_url + "edit-content-page",
    viewContentPageApi: config.api_url + "view-content-page",
    deleteContentPageApi: config.api_url + "delete-content-page",

    //ForgotPassword
    forgotPasswordApi: config.api_url + "forgot-password",

    //wallet
    getHistoryApi: config.api_url + "wallet/get-history",
    getBalanceApi: config.api_url + "wallet/get-balance",
    changeWalletStatusApi: config.api_url + "wallet/change-status",

    //HelpPage
    getHelpPageApi: config.api_url + "get-help-page",

    //Update
    getUpdateApi: config.api_url + "get-updates",

    //Chat
    getUserListing: config.api_url + "chat/userlisting",
    getUserMessage: config.api_url + "chat/userMessage",
    sendMessage: config.api_url + "chat/addMessage",
    getUnreadMessageApi: config.api_url + "chat/unread-messages",
    setMarkAsReadApi: config.api_url + "chat/mark-as-read",
    getCustomerChatNotesApi: config.api_url + "chat/addCustomerNotes",
    //Menu
    getMenuListApi: config.api_url + "get-menu",
    getMenuItemByIdApi: config.api_url + "get-menu-by-id",
    getMenuListDineInApi: config.api_url + "get-menu-dine-in",
    getMenuItemDineInByIdApi: config.api_url + "get-menu-dine-in-by-id",
    addDineInMenuApi: config.api_url + "add-dine-in-menu",
    getDineinMenuByIdApi: config.api_url + "get-dine-in-menu-by-id",
    updateDineInMenuApi: config.api_url + "update-dine-in-menu",
    deleteDineInMenuUrl: config.api_url + "delete-dine-in-menu",
    deleteDineMenuApi: config.api_url + "delete-dine-menu",
    deleteDineRestaurantAllMenuApi:
        config.api_url + "delete-dine-restaurant-all-menu",
    deleteRestaurantAllMenuApi: config.api_url + "delete-restaurant-all-menu",
    addDineInMenuItem: config.api_url + "add-dine-in-menu-item",
    updateDineInItemAvailability:
        config.api_url + "update-dine-in-item-availability",
    getDineInMenuItem: config.api_url + "get-dine-in-menu-item",
    updateDineInMenuItem: config.api_url + "update-dine-in-menu-item",
    addDineInItemImageApi: config.api_url + "add-dine-in-menu-item-image",
    getDineInItemImage: config.api_url + "get-dine-in-menu-item-image",
    deleteDineInMenuItemUrl: config.api_url + "delete-dine-in-menu-item-image",
    getDineInItemCustomzation:
        config.api_url + "get-dine-in-item-customization",
    addDineInItemCustomization:
        config.api_url + "add-dine-in-item-customization",
    getDineInTemplate: config.api_url + "get-dine-in-template",
    addDineInTemplate: config.api_url + "save-dine-in-template",
    getDineItemCustomization: config.api_url + "get-dine-customization",
    updateDineInCustomization: config.api_url + "update-dine-customization",
    addDineInMenuSelection: config.api_url + "add-dine-item-customization",
    deleteDineInItemCustomization:
        config.api_url + "delete-dine-item-customization",
    addDineInTemplates: config.api_url + "add-dine-in-template",
    deleteDineInCustomization: config.api_url + "delete-dine-in-item",
    getDineInQrCode: config.api_url + "get-dine-in-qrcode",
    searchMenuItemDine: config.api_url + "search-menu",
    getDineInBanner: config.api_url + "get-dine-in-banner",
    addDineInBanner: config.api_url + "add-banner-dine-in",
    getRestaurantDineIn: config.api_url + "get-restaurant-dine-in",
    getDineInBannerbyId: config.api_url + "get-dine-in-by-id",
    updateDineInBanner: config.api_url + "update-dine-in-banner",
    deleteDineInBanner: config.api_url + "delete-dine-in-banner",
    getMenuDineBanner: config.api_url + "get-banner-dine-in",
    importDineInMenu: config.api_url + "import-dine-in-menu",
    getDineInSubCustomization: config.api_url + "get-dine-in-sub-customization",
    addDineInItemSubCustomization:
        config.api_url + "add-dine-in-item-sub-customization",
    minDineInSelectionApi: config.api_url + "dine-in-min-selection",
    maxDineInSelectionApi: config.api_url + "dine-in-max-selection",
    deleteMenuDineInItem: config.api_url + "delete-dine-in-menu-item",
    updateMenuDineInOrderApi: config.api_url + "update-dine-in-menu-order",
    updateItemDineInOrderApi: config.api_url + "update-dine-in-item-order",
    aboutUsPage: config.api_url + "about-us",
    addFeedbackApi: config.api_url + "add-feedback",
    getFeedbackApi: config.api_url + "get-feedback-dine-in",
    getRestaurantByDineApi: config.api_url + "get-restaurant-dine-by-id",
    changeDineStatusApi: config.api_url + "change-dine-status",
    closeAllRestaurantApi: config.api_url + "close-all-restaurant",
    changeRestaurantStatusApi: config.api_url + "restaurant-status-change",
    getActiveRestaurantsApi: config.api_url + "get-active-restaurants",
    copyOperationTimeApi: config.api_url + "copy-operation-time",

    //WebLayoutSetting
    getWebSettingApi: config.api_url + "get-web-layout",
    updateWebSettingApi: config.api_url + "update-web-layout",
    deleteWebLogoApi: config.api_url + "delete-web-logo",
    deleteWebHomePageApi: config.api_url + "delet-home-page-image",
    deleteSectionImageApi: config.api_url + "delete-section-image",
    deleteFrontImageApi: config.api_url + "delete-front-image",

    //CallCenterDashboard
    getCallCenterRestaurant: config.api_url + "call-center-restaurant",
    checkCustomerNumberApi: config.api_url + "check-customer-number",
    getCustomerAddressApi: config.api_url + "get-customer-address",
    addCustomerAddressApi: config.api_url + "add-customer-address",
    placeOrderApi: config.app_api_url + "place_order",
    checkEmailApi: config.api_url + "check_email",
    userCreateApi: config.api_url + "user_create",
    getCustomerLastOrdersApi: config.api_url + "call-center/last-orders",

    //bookig api
    getBookingsApi: config.api_url + "bookings",
    getBookingApi: config.api_url + "booking",
    getBookingsCountApi: config.api_url + "bookings/count",
    changeBookingsStatusApi: config.api_url + "bookings/status",
    getNewBookingsApi: config.api_url + "bookings/new",

    // restaurant tag
    getRestaurantTagApi: config.api_url + "get-restaurant-tag",
    addRestaurantTagApi: config.api_url + "add-restaurant-tag",
    editRestaurantTagApi: config.api_url + "edit-restaurant-tag",
    updateRestaurantTagApi: config.api_url + "update-restaurant-tag",
    deleteRestaurantTagApi: config.api_url + "delete-restaurant-tag",

    //Mail
    getMailApi: config.api_url + "get-mail-list",
    addMailApi: config.api_url + "add-mail",
    getMailByIdApi: config.api_url + "get-mail-by-id",
    updateMailByIdApi: config.api_url + "update-mail",
    viewMailApi: config.api_url + "view-mail",

    // zomato api
    getZomatoApi: config.api_url + "get-zomato-report",
    zomatoReportExportToCsvApi: config.api_url + "zomato-report-export-csv"
};

export { routes };
