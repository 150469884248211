
<script>
import simplebar from "simplebar-vue";
import config from "../config";
/**
 * Nav-bar Component
 */
export default {
  components: { simplebar },
  data() {
    return {
      config: config,
      logo: '/public/images/logo.png'
    };
  },
  methods:{
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header menu-nav">
      <div class="d-flex w-100 align-items-center relative">

        <div class="dropdown d-inline-block menu-nav-header d-none">
          <button
            type="button"
            class="btn btn-sm px-3 font-size-16 header-item toggle-right d-none"
            @click="toggleRightSidebar"
          >
            <i class="fa fa-fw fa-bars toggle-right"></i>
          </button>
        </div>

        <div class="menu-icon-image logo-position">
            <span class="logo-lg">
              <img :src="logo" alt height="65"/>
            </span>
        </div>
        <div class="ml-auto">
          <a href="/page/Chowman%20Hospitality%20Pvt%20Ltd/about-us" class="btn btn-default" role="button">About Us</a>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.menu-icon-image{
  margin-left: 660px;
}
.menu-dine-image{
    margin-left: 500px;
}
</style>
