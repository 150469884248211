<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import config from "../config";
import { menuItems } from "./menu";
import { vendormenuItems } from "./vendor";
// import { clientmenuItems } from "./client";
import { userAccessService, settingService } from "../services";
import {
  get_partner_login,
  get_permitted_menus,
  get_user,
  set_user,
} from "../Helper/helper";


export default {
  data() {
    return {
      config : config,
      menuItems: this.$auth.partner_login
        ? vendormenuItems :
        this.$menus || menuItems,
      // vendormenuItems: vendormenuItems,
      permissions: this.$auth.user && this.$auth.user.access,
      setting: [],
      visible: true
    };
  },
  components: {},
  mounted: function () {
    this.getPermissions();
    if(!this.$auth.partner_login)
    {
      this.getAppSetting();
    }

    // this.$data.menuItems[0].subItems[1].label = this.$auth.setting.driver_name;
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      var x = paths.reduce((a, p) => (window.location.pathname.indexOf(p) !== -1?p:a),0)
      matchingMenuItem = links[paths.indexOf(x)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
      else
      {
          matchingMenuItem.classList.add("mm-active");
      }
    }    
  },
  methods: {
    getAppSetting(){
      // settingService.getAppSettingApi().then((response) => {
            // this.setting = response.data.result;
            // this.$auth.updateSetting(response.data.result.settings)
            if(!this.$auth.partner_login && this.$auth.user.vendor_role != '1')
            {

              const dindex = this.menuItems.findIndex(i => i.id == 1);
              if(dindex !== -1){
                let sindex = this.menuItems[dindex].subItems.findIndex(i => i.id == 5);
                if(sindex !== -1){
                  this.menuItems[dindex].subItems[sindex].label = this.$auth.getDriverName();
                }
              }


              /* if (this.menuItems[0]) {
                if (this.menuItems[0].subItems[2]) {
                  this.menuItems[0].subItems[2].label = this.$auth.getDriverName();
                }
              } */
              const dindex2 = this.menuItems.findIndex(i => i.id == 6);
              if(dindex2 !== -1){
                let sindex = this.menuItems[dindex2].subItems.findIndex(i => i.id == 8);
                if(sindex !== -1){
                  this.menuItems[dindex2].subItems[sindex].label = this.$auth.getDriverName();
                }
                let rindex = this.menuItems[dindex2].subItems.findIndex(i => i.id == 7);
                if(rindex !== -1){
                  this.menuItems[dindex2].subItems[rindex].label = this.$auth.getRestaurantName();
                }
              }
              /* if (this.menuItems[1]) {
                if (this.menuItems[1].subItems[0]) {
                  this.menuItems[1].subItems[0].label = this.$auth.getRestaurantName();
                }
                if (this.menuItems[1].subItems[1]) {
                  this.menuItems[1].subItems[1].label = this.$auth.getDriverName();
                }
              } */
              const dindex3 = this.menuItems.findIndex(i => i.id == 10);
              if(dindex3 !== -1){
                let sindex = this.menuItems[dindex3].subItems.findIndex(i => i.id == 12);
                if(sindex !== -1){
                  this.menuItems[dindex3].subItems[sindex].label = this.$auth.getDriverName();
                }
                let rindex = this.menuItems[dindex3].subItems.findIndex(i => i.id == 11);
                if(rindex !== -1){
                  this.menuItems[dindex3].subItems[rindex].label = this.$auth.getRestaurantName();
                }
              }
              /* if (this.menuItems[4]) {
                if (this.menuItems[4].subItems[0]) {
                  this.menuItems[4].subItems[0].label = this.$auth.getRestaurantName();
                }
                if (this.menuItems[4].subItems[1]) {
                  this.menuItems[4].subItems[1].label = this.$auth.getDriverName();
                }
              } */
              const dindex4 = this.menuItems.findIndex(i => i.id == 17);
              if(dindex4 !== -1){
                let sindex = this.menuItems[dindex4].subItems.findIndex(i => i.id == 19);
                if(sindex !== -1){
                  this.menuItems[dindex4].subItems[sindex].label = this.$auth.getCuisineName();
                }
              }
              /* if (this.menuItems[5]) {
                if (this.menuItems[5].subItems[1]) {
                  this.menuItems[5].subItems[1].label = this.$auth.getCuisineName();
                }
              } */

              // this.menuItems[0].subItems[2].label = this.$auth.getDriverName();
              // this.menuItems[1].subItems[0].label = this.$auth.getRestaurantName();
              // this.menuItems[1].subItems[1].label = this.$auth.getDriverName();
              // this.menuItems[4].subItems[0].label = this.$auth.getRestaurantName();
              // this.menuItems[4].subItems[1].label = this.$auth.getDriverName();
              // this.menuItems[5].subItems[1].label = this.$auth.getCuisineName();
            }
            // if(this.setting.settings.business_category != 'Both') 
            // {
            //   this.menuItems[5].subItems.splice(0,1);
            // }
      // })
    },
    getPermissions() {
      if (this.$auth.partner_login) {
        this.vendormenuItems;
      } else {
        const user = get_user();
        userAccessService.getAuthPermissions().then((response) => {
          this.permissions = response.data.result;
          let access = response.data.result.map((p) => p.name);
          this.$auth.updatePermissions(access);
          this.menuItems = get_permitted_menus(menuItems, response.data.result);
          Vue.prototype.$menus = this.menuItems;
        });
      }
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    getBadgeCount(badge){
        switch(badge){
        case 'order':
          return this.$auth && this.$auth.orders && this.$auth.orders.length || 0;
        case 'chat':
          return this.$auth && this.$auth.unreadMessages && this.$auth.unreadMessages.length || 0;
        case 'booking':
          return this.$auth && this.$auth.booking_count!== 0 && this.$auth.booking_count || 0;
        case 'zomatoOrderCount':
          return this.$auth && this.$auth.zomato_order_count !== 0 && parseInt(this.$auth.zomato_order_count) || 0;
        default: 
          return '';
      }
    },

    getBadge(badges){
      const cout = badges.reduce((a, c) => a + this.getBadgeCount(c), 0)
      return cout !== 0 ? cout : '';
    }
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
      <ul id="side-menu" class="metismenu list-unstyled">
        <template v-for="item in menuItems">
          <li
            class="menu-title"
            v-if="item.isTitle"
            :key="item.id"
            :id="'side-'+item.id"
          >{{ (item.label) }}</li>
          <li v-if="!item.isTitle && !item.isLayout" :key="item.id" :id="'side-'+item.id">
            <a
              v-if="hasItems(item)"
              href="javascript:void(0);"
              class="is-parent"
              :class="{'has-arrow': !!(!(item.badge && getBadge(item.badge.text) !== '')), 'has-dropdown': item.badge}"
            >
              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
              <span>{{ (item.label) }}</span>
              <span
                :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                v-if="item.badge && getBadge(item.badge.text) !== ''"
              >{{getBadge(item.badge.text)}}</span>
              <!-- <span
                :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                v-if="item.badge"
              >{{ (item.badge.text)}}</span> -->
            </a>

            <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
              <span>{{ (item.label) }}</span>
              <span
                :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                v-if="item.badge && getBadge(item.badge.text) !== ''"
              >{{getBadge(item.badge.text)}}</span>
            </router-link>

            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li v-for="(subitem, index) of item.subItems" :key="index">
                <router-link
                  :to="subitem.link"
                  v-if="!hasItems(subitem)"
                  class="side-nav-link-ref"
                > <span>{{ (subitem.label) }}</span>
                  <span
                        :class="`badge badge-pill badge-${subitem.badge.variant} float-right`"
                        v-if="subitem.badge && getBadge(subitem.badge.text) !== ''"
                      >{{ getBadge(subitem.badge.text)}}</span>
                </router-link>
                <a
                  v-if="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                >{{ (subitem.label) }}</a>
                <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li v-for="(subSubitem, i) of subitem.subItems" :key="100+i">
                    <router-link
                      :to="subSubitem.link"
                      class="side-nav-link-ref"
                    >
                      <span>{{ (subSubitem.label) }}</span>
                    </router-link>

                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </template>
      </ul>
  </div>
  <!-- Sidebar -->
</template>

<style scoped>
.mgauto {
  margin: 0 auto;
}
</style>