<script>
import { pageService } from "../services";
import config from "../config";
import Feedback from '../views/menu/feedback.vue';
/**
 * Right sidebar component
 */
export default {

  components: {pageService, Feedback},
  data() {
    
    return {
     
      sortBy: "id",
      sortDesc: true,
      config: config,
      pages: [],
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
      layout: this.$root.layout.type,
      width: this.$root.layout.width,
      sidebarType: this.$root.layout.sidebar,
      topbar: this.$root.layout.topbar,
      loader: this.$root.layout.loader,
    };
  },
  mounted() {
    // this.getContentPage();
  },
  methods: {
    // getContentPage(page){
    //   pageService.getContentPage({
    //     search: this.search,
    //     sortBy: this.sortBy,
    //     orderBy: this.sortDesc ? "asc" : "desc",
    //     page: page && page > 0 ? page : 1,
    //   })
    //     .then((response) => {
    //       this.pages = response.data.result.data[4].page_title;
    //     });
    // },
    hide() {
      this.$parent.toggleRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      return !event.target.classList.contains("toggle-right");
    },
    changeLayout(layout) {
        this.sidebarType = "dark";
        this.topbar = "light";
      this.$root.changeLayout({
          type: layout,
          sidebar: "dark",
          topbar: "light"
      });
    },
    changeType(type) {
      return this.$root.changeLayout({ sidebar: type });
    },
    changeWidth(width) {
      return this.$root.changeLayout({ width: width });
    },
    changeTopbartype(value) {
      return this.$root.changeLayout({ topbar: value });
    },
    changeloader() {
      return this.$root.changeLayout({ loader: this.loader });
    },
  },
};
</script>

<template>
  <div>
    <div v-click-outside="config" class="right-bar">
      <div>
        <div class="rightbar-title px-3 py-4">
          <a href="javascript:void(0);" class="right-bar-toggle float-right" @click="hide">
            <i class="mdi mdi-close noti-icon"></i>
          </a>
        </div>
        <div class="p-3">
          <hr class="mt-1" />
          <!-- <b-form-radio-group v-model="layout" stacked> -->
            <!-- <a :href="'page/Chowman/'+pages.toLowerCase().replaceAll(' ','-')" target="_blank">About Us</a></b-form-radio-group> -->
            
          <!-- <route-link :to="{ name: 'feedback' }">feedback</route-link>-->
          <router-link to="/feedback" class="nav-link">Feedback
          </router-link>
        
          
       
          <!-- Width -->
          <!-- <h6 class="mt-3">Width</h6>
          <hr class="mt-1" />
          <b-form-radio-group v-model="width" stacked @input="changeWidth($event)">
            <b-form-radio value="fluid" class="mb-1">Fluid</b-form-radio>
            <b-form-radio value="boxed">Boxed</b-form-radio>
          </b-form-radio-group> -->

        </div>
        <!-- MenuBar -->
      </div>
    </div>

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>

<style lang="scss"></style>
