<script>
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import { settingService } from "../../../services";
import { required,numeric,requiredIf } from "vuelidate/lib/validators";
import { error_message, success_message } from '../../../Helper/helper';
import config from '../../../config';

/**
 * Starter component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect },
  data() {
    return {
        config: config,
      checked: true,
      checked1: true,
      value: null,
      setting:[],
      allocation: [
         {value:'true',text:'After '+this.$auth.getRestaurantName()+' will Accept Order'},
         {value:'false',text:'After '+this.$auth.getRestaurantName()+' will Change Order Ready To Delivery'}
      ],
      vehicle_name:[{ vehicle_name: '',vehicle_name_another_language: '',is_licence_required: '' }],
      driver_earning: [{ min_km_value: '',max_km_value: '',driver_delivery_charges_new: '' }],
      errors: '',
    //   driver_earning :[],
      setting: {
        driver_accept_multiple_order_count: "",
        is_order_assigned_manually: "",
        driver_assign_km: "",
        driver_max_time: "",
        driver_earning:[],
        vehicle_name:[],
       }, 
    };
  },validations:{
      setting:{
        driver_accept_multiple_order_count:{
          	required: requiredIf(function(form){
            	return this.setting.is_driver_allocation == 'Yes';
            })
        },
        driver_assign_km:{
            required,
            numeric
        },
        driver_earning: {
            required,
             $each: {
                min_km_value: {
                required,
                }  
            }
        },
      },
  },
   mounted() {
    this.getSetting();
    this.value = this.allocation.find(d => d.text == 'true');
  },
  methods: {
    AddDriverEarning: function () {
        console.log("Inside AddDriverEarning");
      this.driver_earning.push({ min_km_value: '',max_km_value: '',driver_delivery_charges_new: '' });
    },
    AddVehicleType: function(){ 
                console.log("Inside AddVehicleType");

        this.vehicle_name.push({ vehicle_name: '',vehicle_name_another_language: '',is_licence_required: '' });
    },
    getSetting(){
      settingService.getSetting()
      .then(response => {
         this.setting = response.data.result;
        this.value = this.allocation.find(d => d.value == response.data.result.is_driver_allocation);
        if(response.data.result.driver_earning){
            this.driver_earning = response.data.result.driver_earning;
        }else{
            this.setting.driver_earning = this.driver_earning;
        }
        if(response.data.result.vehicle_name){
            this.vehicle_name = response.data.result.vehicle_name;
        }else{
            this.setting.vehicle_name = this.vehicle_name;
        }
      });
    },
    swichbox($event){
      this.setting[$event.target.name] = $event.target.checked?'Yes':'No';
     },
     deliveryBoyInIt(){
      this.$v.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
       const fd = new FormData();
       fd.append("is_driver_allocation", this.value.value);
       fd.append("driver_accept_multiple_order_count",this.setting.driver_accept_multiple_order_count);
       fd.append("is_driver_accept_multipleorder",this.setting.is_driver_accept_multipleorder);
       fd.append("driver_assign_km",this.setting.driver_assign_km);
       fd.append("driver_earning",JSON.stringify(this.setting.driver_earning));
        fd.append("vehicle_name",JSON.stringify(this.setting.vehicle_name));
       fd.append("type",3);
       settingService.updateSetting(fd).then((response) => {
        if (response.data.code === 200) {
            if(response.data.result){
                this.$auth.updateSetting(response.data.result.settings)
            }
            success_message(response.data.message);
            //this.$router.push({ name: "banner-list" });
        } else {
            error_message(response.data.message);
        }
        });
     }
    },
    remove_delivery_charges(index){
         this.driver_earning.splice(index,1);
    },
    remove_vehicle_name(index){
         this.vehicle_name.splice(index,1);
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="deliveryBoyInIt">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body setting-main">
                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Basic Settings</h4>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                               
                                <div class="row mt-4">
                                   <div class="col-md-12">
                                        <h6>{{ $auth.setting.driver_name }} Allocation</h6>  
                                    </div>
                                    <div class="col-md-7 order-value">
                                       <!-- <multiselect v-model="value" :options="allocation"  name="is_driver_allocation" id="is_driver_allocation"></multiselect> -->
                                       <multiselect v-model="value"
                                      :options="allocation"
                                      track-by="text"
                                      label="text" 
                                      value="value" 
                                      name="is_driver_allocation"
                                      placeholder="Select one"
                                      id="is_driver_allocation"
                                      :searchable="false"
                                      :allow-empty="false"></multiselect>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Select When {{ $auth.setting.driver_name }} Allocation Will Happen.</p>
                                     </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6">
                                        <h6>{{trans.get('__JSON__.Multiple Order Assignment')}}</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="swtich-data">
                                             <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="is_driver_accept_multipleorder"
                                                name="is_driver_accept_multipleorder"
                                                v-model="setting.is_driver_accept_multipleorder"
                                                class="switch-on"
                                                true-value="Yes"
                                                false-value="No"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                            </b-form-group>
                                         
                                        </div>
                                    </div>
                                     <div class="col-md-6">
                                        <p>{{ trans.get('__JSON__.If you enable Delivery boy can accept Multiple orders at the same time') }}.</p>
                                     </div>
                                </div>
                                 <div class="row mt-4" v-if="setting.is_driver_accept_multipleorder == 'Yes'">
                                        <!-- <div class="col-md-12">
                                            <h6>Number of Order At a time</h6>
                                        </div> -->
                                    <div class="col-md-6 order-value">
                                        <!-- <input id="name" name="name" type="number" class="form-control"/> -->
                                          <b-form-group id="input-group-1" :label="trans.get('__JSON__.Number of Order At a time')" label-for="name">
                                          <b-form-input
                                            id="driver_accept_multiple_order_count"
                                            name="driver_accept_multiple_order_count"
                                            v-model="$v.setting.driver_accept_multiple_order_count.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Number of Order At a time')"
                                            :class="{ 'is-invalid':  $v.setting.driver_accept_multiple_order_count.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.driver_accept_multiple_order_count.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.driver_accept_multiple_order_count.required"
                                            >{{ trans.get('__JSON__.Number of Order At a time') }}.</span>
                                              <span
                                              v-if="!$v.setting.driver_accept_multiple_order_count.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Order will be Cancelled if {{ $auth.setting.driver_name }} will not Accept or confirm order with this seconds.</p>
                                     </div>
                                </div>
                                 <div class="row mt-4">
                                        <div class="col-md-12">
                                            <h6>{{ trans.get('__JSON__.Order Assignment Range (In KM)') }}</h6>
                                        </div>
                                    <div class="col-md-6 order-value">
                                        <input id="name" name="name" v-model="setting.driver_assign_km" type="number" class="form-control"/>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- <p>Order will be Cancelled if {{ $auth.setting.driver_name }} will not Accept or confirm order with this seconds.</p> -->
                                        <p>{{ trans.get('__JSON__.Order will be assigned to Delivery Boys who are under the Specified range') }}.</p>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Earning</h4>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                             <div class="row">
                                <div class="col-md-12" v-for="(v,index) in driver_earning" :key="index">
                                    <div class="row mt-2">
                                       <div class="col-md-3">
                                            <h6>Value From (Km)</h6>
                                            <input v-model="v.min_km_value" name="min_km_value[]" type="number" class="form-control"/>
                                        </div>
                                         <div class="col-md-3">
                                            <h6>Value To (Km)</h6>
                                            <input v-model="v.max_km_value" name="max_km_value[]" type="number" class="form-control"/>
                                         </div>
                                         <div class="col-md-3">
                                            <h6>Earning</h6>
                                            <input v-model="v.driver_delivery_charges_new" name="driver_delivery_charges_new[]" type="number" class="form-control"/>
                                        </div>
                                         <div class="col-md-3">
                                            <h6>&nbsp;</h6>
                                            <button class="btn btn-primary btn-sm btn-rounded" @click.prevent="AddDriverEarning">+</button>&nbsp;&nbsp;
                                            <button class="btn btn-danger btn-sm btn-rounded" v-if="index != 0 || index!=''" @click.prevent="remove_delivery_charges(index)">-</button>
                                        </div>
                                    </div>
                                </div>
                             </div>
                          </div>
                        </div>
                    </div>

                     <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>{{ $auth.setting.driver_name }} Vehicle Type</h4>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                             <div class="row">
                                <div class="col-md-12" v-for="(v,index) in vehicle_name" :key="index">
                                    <div class="row mt-2">
                                        <div class="col-md-3">
                                            <h6>Vehicle Type</h6>
                                            <input v-model="v.vehicle_name" name="vehicle_name[]" type="text" class="form-control"/>
                                        </div>
                                        <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                                        </div>
                                         <div class="col-md-4" v-else>
                                            <h6>{{ trans.trans('__JSON__.Vehicle Type Secondary Language',{ lang: $auth.langauges[1].name } )}}</h6>
                                            <input v-model="v.vehicle_name_another_language" name="vehicle_name_another_language[]" type="text" class="form-control"/>
                                         </div>
                                         <div class="col-md-3">
                                            <h6>Licence Required</h6>
                                            <!-- <input v-model="v.is_licence_required" name="is_licence_required[]" type="number" class="form-control"/> -->
                                            <div class="swtich-data">
                                                <b-form-group>
                                                    <label class="switch">
                                                        <input
                                                        type="checkbox"
                                                        id="is_licence_required"
                                                        name="is_licence_required[]"
                                                        v-model="v.is_licence_required"
                                                        class="switch-on"
                                                        true-value="1"
                                                        false-value="0"
                                                        />
                                                        <div class="slider round">
                                                        <span class="on">{{ trans.get('__JSON__.Yes') }}</span>
                                                        <span class="off">{{ trans.get('__JSON__.No') }}</span>
                                                        </div>
                                                    </label>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <h6>&nbsp;</h6>
                                             <button class="btn btn-primary btn-sm btn-rounded" @click.prevent="AddVehicleType">+</button>&nbsp;&nbsp;
                                            <button class="btn btn-danger btn-sm btn-rounded" v-if="index != 0 || index!=''" @click.prevent="remove_vehicle_name(index)">-</button>
                                        </div>
                                    </div>
                                </div>
                             </div>
                          </div>
                        </div>
                    </div>

                
                <div class="btn-update">
                    <div class="col-sm-6">
                    <button type="submit" v-if="$auth.hasPermission(config.permissions.configuration_write)" class="btn btn-primary mr-1 mt-3">Update</button>
                    </div>
                </div>

            </div>
        </div>
      </div>
    </div>
 </b-form>
</template>