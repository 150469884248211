<script>
import { order_statuses } from "../../../Helper/helper";

export default{
    props:['order','loading'],
    data() {
        return {
            statuses: order_statuses
        }
    },
    methods:{
        btoa(str) {
            return btoa(str);
        }
    }
};
</script>

<template>
    <div class="col-md-12 mt-3">
        <div class="row">
            <div class="col-12" v-if="loading">
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                </div>
            </div>
            <div class="col-md-6" v-if="!loading && order">
                <div data-simplebar="init" class="mt-2">
                    <div class="simplebar-wrapper" style="margin: 0px;">
                        <div class="simplebar-height-auto-observer-wrapper">
                            <div class="simplebar-height-auto-observer"></div>
                        </div>
                        <div class="simplebar-mask">
                            <div class="simplebar-offset" style="right: -15px; bottom: 0px;">
                                <div class="simplebar-content-wrapper" style="height: auto;">
                                    <div class="simplebar-content" style="padding: 0px;">
                                        <ul class="verti-timeline list-unstyled">
                                            <li class="event-list" :class="{ 'active' : [statuses.placed].includes(order.order_status)}" v-if="[statuses.placed,statuses.inkitchen,statuses.readytoserve,statuses.ontheway,statuses.arrived,statuses.delivered,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status) && order.created_date != '0000-00-00 00:00:00'">
                                                <div class="event-timeline-dot">
                                                    <i :class="{ 'bx bxs-right-arrow-circle font-size-18 bx-fade-right' : [statuses.placed].includes(order.order_status)}" class="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{ trans.get('__JSON__.Placement time') }}</h5>
                                                        <p v-if="order.created_date != '0000-00-00 00:00:00'">{{ order.created_date }}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="event-list" v-if="[statuses.placed,statuses.inkitchen,statuses.readytoserve,statuses.ontheway,statuses.arrived,statuses.delivered,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status) && order.future_delivery_date != '0000-00-00 00:00:00' && order.future_delivery_date != null">
                                                <div class="event-timeline-dot">
                                                    <i class="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{ trans.get('__JSON__.Schedule Time') }}</h5>
                                                        <p>{{ moment(order.future_delivery_date).format("MMM DD[,] YYYY hh:mm A") }}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="event-list" :class="{ 'active' : [statuses.inkitchen].includes(order.order_status)}" v-if="[statuses.inkitchen,statuses.readytoserve,statuses.ontheway,statuses.arrived,statuses.delivered,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status) && order.confirm_date != '0000-00-00 00:00:00' && order.confirm_date != null">
                                                <div class="event-timeline-dot">
                                                    <i :class="{ 'bx bxs-right-arrow-circle font-size-18 bx-fade-right' : [statuses.inkitchen].includes(order.order_status)}" class="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{ trans.get('__JSON__.Accepted Time') }}</h5>
                                                        <p v-if="order.confirm_date != '0000-00-00 00:00:00'">{{ order.confirm_at }}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="event-list" :class="{ 'active' : [statuses.readytoserve].includes(order.order_status)}" v-if="[statuses.readytoserve,statuses.ontheway,statuses.arrived,statuses.delivered,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status) && order.readytoserve_date != '0000-00-00 00:00:00' && order.readytoserve_date != null">
                                                <div class="event-timeline-dot">
                                                    <i :class="{ 'bx bxs-right-arrow-circle font-size-18 bx-fade-right' : [statuses.readytoserve].includes(order.order_status)}" class="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{ trans.get("__JSON__.Ready To Serve time") }}</h5>
                                                        <p v-if="order.readytoserve_date != '0000-00-00 00:00:00'">{{ order.readytoserve_at }}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="event-list" :class="{ 'active' : [statuses.ontheway].includes(order.order_status)}" v-if="[statuses.ontheway,statuses.arrived,statuses.delivered,statuses.cancelled,statuses.paymentpending,statuses.paymentfailure].includes(order.order_status) && order.ontheway_date != '0000-00-00 00:00:00' && order.ontheway_date != null">
                                                <div class="event-timeline-dot">
                                                    <i :class="{ 'bx bxs-right-arrow-circle font-size-18 bx-fade-right' : [statuses.ontheway].includes(order.order_status)}" class="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{ trans.get("__JSON__.Picked up Time") }}</h5>
                                                        <p v-if="order.ontheway_at != '0000-00-00 00:00:00'">{{ order.ontheway_at }}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="event-list" :class="{ 'active' : [statuses.delivered].includes(order.order_status)}" v-if="[statuses.delivered].includes(order.order_status) && order.delivered_date != '0000-00-00 00:00:00' && order.delivered_date != null">
                                                <div class="event-timeline-dot">
                                                    <i :class="{ 'bx bxs-right-arrow-circle font-size-18 bx-fade-right' : [statuses.delivered].includes(order.order_status)}" class="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{ trans.get("__JSON__.Delivered time") }}</h5>
                                                        <p v-if="order.delivered_at != '0000-00-00 00:00:00'">{{ order.delivered_at }}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="event-list" :class="{ 'active' : [statuses.cancelled].includes(order.order_status)}" v-if="[statuses.cancelled].includes(order.order_status) && order.cancelled_date != '0000-00-00 00:00:00' && order.cancelled_date != null">
                                                <div class="event-timeline-dot">
                                                    <i :class="{ 'bx bxs-right-arrow-circle font-size-18 bx-fade-right' : [statuses.cancelled].includes(order.order_status)}" class="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{trans.get('__JSON__.Cancelled time')}}</h5>
                                                        <p>{{ order.cancelled_date }}</p>
                                                        <p>{{ order.cancelled_desc }}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="simplebar-placeholder" style="width: auto; height: 414px;"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" v-if="!loading && order">
                <div class="mt-2">
                    <div class="simplebar-wrapper" style="margin: 0px;">
                        <div class="simplebar-height-auto-observer-wrapper">
                            <div class="simplebar-height-auto-observer"></div>
                        </div>
                        <div class="simplebar-mask">
                            <div class="simplebar-offset" style="right: -15px; bottom: 0px;">
                                <div class="simplebar-content-wrapper" style="height: auto;">
                                    <div class="simplebar-content" style="padding: 0px;">
                                        <ul class="list-unstyled">
                                            <li class="event-list">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <h5 class="font-size-14">{{ trans.get('__JSON__.Driver Request', { driver: $auth.getDriverName() }) }}</h5>               
                                                        <template v-if="order.dunzo_driver == '0' && order.pidge_driver == '0'">
                                                           <span> 
                                                                <p>{{ trans.get('__JSON__.Request sent to') }} {{ (order.driver_requests) ? order.driver_requests.length : 0 }} {{ $auth.getDriverName() }}  
                                                                <span v-if="order.request_to_driver && order.request_to_driver.length > 0" v-b-popover.html.hover.bottom="order.request_to_driver"
                                                                class="font-size-14">
                                                                    <i class="bx bx-info-circle"></i>
                                                                </span>
                                                            </p>
                                                        </span>
                                                        </template>
                                                        <template v-if="order.dunzo_driver == '1' && order.pidge_driver == '0'">
                                                            {{ trans.get('__JSON__.Request sent to') }} <span class="badge badge-pill font-size-12 badge-soft-success">DUNZO</span>
                                                        </template>
                                                        <template v-if="order.pidge_driver == '1' && order.dunzo_driver == '0'">
                                                            {{ trans.get('__JSON__.Request sent to') }} <span class="badge badge-pill font-size-12 badge-soft-success">PIDGE</span>
                                                        </template>
                                                    </div>
                                                </div>
                                            </li>
                                            <template v-if="order.dunzo_driver == '0' && order.pidge_driver == '0'">
                                                <li class="event-list" v-if="order.rejectdriver && order.rejectdriver.length > 0">
                                                    <div class="d-flex">
                                                        <div class="flex-shrink-0 me-3">
                                                            <h5 class="font-size-14">{{ trans.get('__JSON__.Rejected Request')}}</h5>
                                                            <template v-for="rejdir in order.rejectdriver">
                                                                <p>
                                                                    {{ trans.get('__JSON__.By')}} <span class="bolddriver"><b-link :to="{ name: 'driver-details', params:{driver_id: btoa(rejdir.driverid) }}"> {{ (rejdir.driver) ? rejdir.driver.firstname + ' ' + rejdir.driver.lastname : '-' }}</b-link></span> {{ trans.get('__JSON__.At')}} {{ (rejdir.updateddate != null && rejdir.updateddate != 'null') ?  moment(rejdir.updateddate).format("MMM DD[,] YYYY hh:mm A") : '-' }}
                                                                </p>
                                                                <p v-if="rejdir.reject_order_reason != '' && rejdir.reject_order_reason != null && rejdir.reject_order_reason != 'null'">{{ rejdir.reject_order_reason }}</p>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="event-list" v-if="order.canceldriver && order.canceldriver.length > 0">
                                                    <div class="d-flex">
                                                        <div class="flex-shrink-0 me-3">
                                                            <h5 class="font-size-14">{{ trans.get('__JSON__.Cancelled Request')}}</h5>
                                                            <template v-for="candir in order.canceldriver">
                                                                <p>
                                                                    {{ trans.get('__JSON__.By')}} <span class="bolddriver"><b-link :to="{ name: 'driver-details', params:{driver_id: btoa(candir.driverid) }}"> {{ (candir.driver) ? candir.driver.firstname + ' ' + candir.driver.lastname : '-' }}</b-link></span> {{ trans.get('__JSON__.At')}} {{ (candir.updateddate != null && candir.updateddate != 'null') ? moment(candir.updateddate).format("MMM DD[,] YYYY hh:mm A") : '-' }}
                                                                </p>
                                                                <p v-if="candir.reject_order_reason != '' && candir.reject_order_reason != null && candir.reject_order_reason != 'null'">{{ candir.reject_order_reason }}</p>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="event-list" v-if="order.driver && order.driver.length > 0 && ![statuses.cancelled,statuses.paymentfailure,statuses.paymentpending].includes(order.order_status)">
                                                    <div class="d-flex">
                                                        <div class="flex-shrink-0 me-3">
                                                            <h5 class="font-size-14">{{ trans.get('__JSON__.Accepted Request')}}</h5>
                                                            <p>
                                                                {{ trans.get('__JSON__.By')}} <span class="bolddriver"><b-link :to="{ name: 'driver-details', params:{driver_id: btoa(order.driver[0].driverid) }}"> {{ order.driver[0].firstname + ' ' + order.driver[0].lastname }}</b-link></span> {{ trans.get('__JSON__.At')}} {{ order.confirm_at }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<style>
.vert-timeline .event-timeline-dot .bx-fade-right{
    color: #556ee6;
}
.verti-timeline .event-list {
    padding: 0px 0px 15px 30px;
}
p{
    margin-bottom:0.5em !important;
}
.bx-fade-right {
    animation: fade-right 1.5s infinite linear;
}
.event-list {
    padding: 0px 0px 15px 30px;
}
.event-list-ar {
    padding: 0px 30px 15px 30px !important;
}
.bolddriver {
    font-weight : 600;
}

.tooltip {
  max-width: none !important;
}
</style>