<script>
import { get_currency, get_decimal } from '../../Helper/helper';
export default {
  data(){
    return {
      currency: get_currency(),
      decimal: get_decimal(),
    }
  }
}
</script>

<template>

   <!-- View Detail Main Start -->
  <div class="row mt-3">
    <div class="col-12">
      <div class="text-center text-danger my-2" v-if="loading">
        <b-spinner class="align-middle"></b-spinner>
        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
      </div>
    </div>
    <div class="table-responsive item-order-main" v-if="!loading">
      <table class="table table-centered table-bordered item-table-main">
          <thead>
            <tr>
              <th scope="col" class="item-name-main">{{trans.get('__JSON__.Item Name')}}</th>
              <th scope="col">{{trans.get('__JSON__.Quantity')}}</th>
              <!-- <th scope="col">{{trans.get('__JSON__.Unit Price')}}</th> -->
              <th scope="col">{{trans.get('__JSON__.Original')}}</th>
              <th scope="col">{{trans.get('__JSON__.Discount')}}</th>
              <th scope="col">{{trans.get('__JSON__.Total')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Onion Blossoms Pizza</td>
              <td align="center">
                <p>1</p>
              </td>
              <td align="center">
                <!-- <p><span v-html="currency"></span> 200.50 </p> -->
                <p><span v-html="currency"></span> 200.50 </p>
              </td>
              <!-- <td align="center">
                <p><span v-html="currency"></span> 200.50 </p>
              </td> -->
              <td align="center">
                <p><span v-html="currency"></span> - </p>
              </td>
              <td align="center">
                <p><span v-html="currency"></span> 200.50 </p>
              </td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> 200.50 </td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">GST</h6></td>
              <td align="center"> <span v-html="currency"></span> 20.05 </td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Delivery Fees')}}</h6></td>
              <td align="center"><span v-html="currency"></span> 0.00 </td>
            </tr>
            <tr>
              <td colspan="4" align="right">
                <span class="text-right text-danger">{{trans.get('__JSON__.Discount')}}</span>
                <span class="text-right text-danger"> - </span>
              </td>
              <td align="center" class="text-danger">
                <span class="text-danger d-none" v-html="currency"></span>
                <span class="text-danger"> - </span>
              </td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> 220.55 </td>
            </tr>
          </tbody>
        </table>
    </div>
    <!-- View Detail Main End -->

  </div>
</template>
