resource/js/views/zomato_order/main.vue

<script>
import Layout from '../../layouts/main';
import PageHeader from '../../components/page-header';
import OrderList from './list';
import { zomatoOrderService } from '../../services';
import { get_decimal } from '../../Helper/helper';
import moment from 'moment';
import config from "../../config";

export default {
    components: { Layout, PageHeader, OrderList },
    data() {
        return {
            filters: null,
            config:config,
            orders: null,
            decimal: get_decimal(), 
            moment
        }
    },
    methods:{
        exportOrders(){
            zomatoOrderService.exportZomatoOrders({
                ...this.filters,
            }).then((response) => {
                this.loading = false;
                this.orders = response.data.result;
                this.downloadCSV()
            })
            .catch(function (error) {
            // this.loading = false;
            console.log(error);
            });
        },
        downloadCSV: function(){
            let orders = '\ufeff' + 'SR,Order ID,Vendor Order Id , Customer Name,Customer Number,Order Date,Restaurant Name,Restaurant Number,Payment Method,Order Status,Sub Total,Total Amount,Item Name,Item Price,Item Quantity\n';
            this.orders.forEach((el,ind) => {
                var line = (ind+1)+ ',' 
                + el['id'] + ',' 
                + (el['vendor_order_id'] && el['vendor_order_id'] != '' && el['vendor_order_id'] != null ? (el['vendor_order_id']).replaceAll('#','') : '-') + ',' 
                + (el['fa_order_address'] && el['fa_order_address']['customer_name'] != '' ? el['fa_order_address']['customer_name'] : '-') + ','
                + (el['fa_order_address'] && el['fa_order_address']['customer_contact_number'] != '' ? el['fa_order_address']['customer_contact_number'] : '-') + ',' 
                + (this.moment(el['created_at']).format('DD-MM-YYYY hh:mm A')) + ','
                + (el['fa_order_address'] && el['fa_order_address']['restaurant_name'] != '' ? el['fa_order_address']['restaurant_name'] : '-') + ','
                + (el['fa_order_address'] && el['fa_order_address']['restaurant_contact_number'] ? el['fa_order_address']['restaurant_contact_number'] : '-')+ ',' 
                + el['payment_method'] +','
                + el['order_status'] +','
                + el['sub_total'] +','
                + el['total_amount'] +',';
                var i = 0;
                do{
                    line += (el['order_item'] && el['order_item'][i] && el['order_item'][i]['item_name'] && el['order_item'][i]['item_name'].replaceAll(',','') || '') +',' 
                            + (el['order_item'] && el['order_item'][i] && el['order_item'][i]['item_price'] && el['order_item'][i]['item_price']|| '0') +',' 
                            + (el['order_item'] && el['order_item'][i] && el['order_item'][i]['quantity'] && el['order_item'][i]['quantity']|| '0') +','
                            +'\n';
                        
                    i++;
                    orders += line  
                    line = ',,,,,,,,,,,,';
                } while(el['order_item'].length > i);
            });
            var blob = new Blob([ orders ], { "type" : "csv/plain" });
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'zomato_orders-' + new Date().getTime() +'.csv'
            link.click()
        },
        updateFilters(filters){
            this.filters = filters;
        }
    }
}
</script>
<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div
                    class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
                >
                    <div class="page-title-left">
                        <div class="avatar-xs mr-3">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i class="bx bx-copy-alt"></i>
                            </span>
                        </div>
                        <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Orders') }}</h4>
                    </div>
                    <div class="page-title-right">
                        <button
                            @click="exportOrders()"
                            v-if="$auth.hasSubAdminPermission(config.permissions.zomato_order_export)"
                            class="btn btn-primary btn-rounded export-btn"
                        >{{trans.get('__JSON__.Export To CSV')}}</button>
                        <button
                            @click="exportOrders()"
                            v-if="$auth.hasSubAdminPermission(config.permissions.zomato_order_export)"
                            class="btn btn-primary btn-rounded m-export-btn"
                        ><i class="bx bx-archive-in"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <OrderList @filterChange="updateFilters"/>
    </Layout>
</template>