<template>
  <b-modal
      id="modal-sm"
      size="lg"
      title-class="font-18"
      @hidden="resetModal"
      @ok="couponInIt"
      :ok-title="trans.get('__JSON__.Promo Code')"
      ok-only
      ok-variant="danger"
      ref="cancel-modal"
      lazy
      >
      <template #modal-header={close}>
          <div class="d-flex justify-content-between w-100">
              <h4 class="mb-0">{{trans.get('__JSON__.Promo Code')}}</h4>
              <h4 class="mb-0">#{{(order_id)}}</h4>
          </div>
          <button type="button" aria-label="Close" class="close" @click="close()">&times;</button>
      </template>

      <div class="row">
          <div class="col-12">
           
            <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Discount Type') }}</label>
                      <select
                        class="custom-select"
                        v-model="typeform.is_flat_discount"
                        id="discount_type"
                        name="discount_type"
                      >
                        <!-- <option value>{{ trans.get('__JSON__.Select Discount Type') }}</option> -->
                        <option :value="0">{{ trans.get('__JSON__.Percentage') }}</option>
                        <option :value="1">{{ trans.get('__JSON__.Flat') }}</option>
                        <option :value="2">{{ trans.get('__JSON__.Cashback') }}</option>
                        <option :value="3">{{ trans.get('__JSON__.Cashback With Percentage') }}</option>
                        <option :value="4">{{ trans.get('__JSON__.Cashback With Flat') }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Cashback') }} (%)</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.cashback.$model"
                        type="text"
                        :placeholder="this.trans.get('__JSON__.Enter Cashback in Percentage')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.cashback.$error }"
                      />
                      <div v-if="$v.typeform.cashback.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.cashback.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'cashback'})}}.</span>
                          <span
                            v-else-if="$v.typeform.cashback.floating"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'cashback'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 0 || typeform.is_flat_discount == 3">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Discount') }} (%)</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.discount_percentage.$model"
                        type="text"
                        :placeholder="this.trans.get('__JSON__.Enter field',{name: 'Discount'})"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.discount_percentage.$error }"
                      />
                      <div v-if="$v.typeform.discount_percentage.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.discount_percentage.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'percentage'})}}.</span>
                          <span
                            v-else-if="$v.typeform.discount_percentage.floating"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'percentage'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Flat Discount') }} (
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="flat_discount"
                        name="flat_discount"
                        v-model="$v.typeform.flat_discount.$model"
                        type="number"
                        :placeholder="this.trans.get('__JSON__.Enter field',{name: 'Flat Discount'})"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.flat_discount.$error }"
                      />
                      <div v-if="$v.typeform.flat_discount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.flat_discount.required"
                        >{{ trans.get('__JSON__.Please enter flat discount')}}.</span>
                        <span
                          v-if="!$v.typeform.flat_discount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount != 1 && this.typeform.is_flat_discount != 4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Maximum Discount Amount') }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="maximum_discount_amount"
                        name="maximum_discount_amount"
                        type="text"
                        v-model="$v.typeform.maximum_discount_amount.$model"
                        :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.maximum_discount_amount.$error }"
                      />
                       <div v-if="$v.typeform.maximum_discount_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.required"
                        >{{ trans.get('__JSON__.Please enter maximum discount')}}.</span>
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
          
          </div>
      </div>
  </b-modal>
</template>
<script>
import {order_statuses, success_message,get_currency} from './../../../Helper/helper'
import config from '../../../config';
import { orderService } from '../../../services';
import {required,requiredIf,helpers} from "vuelidate/lib/validators";
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,3}|\d+)$/)
const notEqualTo = (value) => (value !== "-")
export default {
  props :['order_id','cancel_order'],
  data(){
      return {
        currency: get_currency(),
          config: config,
          showModal: false,
          statuses: order_statuses,
          cancelled_desc: '',
          send_email:false,
          is_refundable: false,
          showPopup: false,
         
          discount_type: "0",
          typeform: {
         
           
            discount_percentage: "",
            maximum_discount_amount: "",
            is_flat_discount: "0",
            cashback:0,
            max_user_count: "",
            flat_discount: "",
          },
      }
  },
  validations: {
    typeform: {
    
    
      discount_percentage: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '3';
        }),
        floating,
      },
      maximum_discount_amount: {
        required : requiredIf(function(form){
        	return this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3';
        }),
        floating,
      },
      flat_discount: {
        required : requiredIf(function(form){
        	return this.typeform.is_flat_discount == '1' || this.typeform.is_flat_discount == '4';
        }),
        floating,
      },
      cashback:{
        required:requiredIf(function(){
          return this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3'
            || this.typeform.is_flat_discount == '4';
        })
      },
    
      
    },
  },
  mounted(){
      this.cancelOrderPopup()
  },
   methods:{
      resetModal(){
          // this.order_id = null;
          this.is_refundable = false;
          this.$emit('hidden')
      },
      cancelOrderPopup(id,status){
          this.$refs['cancel-modal'].show();
          // this.order_id = id;
      },
     couponInIt(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return false;
      } else {
        const fd = new FormData();
        fd.append("is_flat_discount", this.typeform.is_flat_discount);
        fd.append("flat_discount", this.typeform.flat_discount);
        fd.append("cashback", this.typeform.cashback);
        fd.append("discount_percentage", this.typeform.discount_percentage);
        fd.append("maximum_discount_amount",this.typeform.maximum_discount_amount);
         fd.append("duration_type", this.typeform.duration_type);
        fd.append("order_id", this.order_id);
        fd.append("vendor_id", this.$auth.getVendorId());
        fd.append("type", 'manual');
        
           if(window.confirm("Are you sure want to Send Email?")){
            orderService.delayOrderCustomer(fd)
                .then(response => {
                    if(response.data.code == 200){
                        this.$toasted.success(response.data.msg);
                        this.$refs['cancel-modal'].hide();
                    }else{
                        this.$toasted.error(response.data.msg);
                    }
                    window.order_count_refresh = true;
                    this.$emit('actionChanged');
                })
            return;
            }
      }
    },
   }
}
</script>
<style  scoped>
.height-100{
  height: 100px;
}
</style>