<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { mailService } from "../../services";
import { error_message, success_message , debounce} from "../../Helper/helper";
/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, pagination, mailService },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      sortBy: "id",
      sortDesc: true,
      mail: [],
      paginations: {},
      limit: 1,
      search: "",
      config: config,
      status: status == undefined ? "0" :status,
      loading: true,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'mail-list', query: {status,orderBy,sortBy}})
        }
        this.getMails();
        // this.getCategoryDetail();
        this.init = false;
      },500),
      fields: [
        {
          key: "subject",
          label: this.trans.get("__JSON__.Subject"),
          sortable: true,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getMails(1);
  },
  methods: {
    getMails(page) {
      this.loading = true;
      mailService.getMail({
          search: this.search,
          sortBy: this.sortBy,
          status: this.status,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page && page > 0 ? page : 1,
        })
        .then((response) => {
          console.log("response",response);
          this.loading = false;
          this.paginations = response.data.result;
          this.mail = response.data.result.data;
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getMails();
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row header-new">
        <div class="col-12">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              icon-main-page email-list
            "
          >
            <div class="page-title-left">
              <div class="avatar-xs mr-3">
                <span
                  class="
                    avatar-title
                    rounded-circle
                    bg-soft-primary
                    text-primary
                    font-size-18
                  "
                >
                  <i class="bx bx-mail-send"></i>
                </span>
              </div>
              <h4 class="mb-0 font-size-18">
                {{ trans.trans("__JSON__.Mail Template") }}
              </h4>
            </div>
            <div class="page-title-right align-items-center d-flex col-md-4">
              <!-- <div class="">
                  <router-link
                    :to="{ name: 'add-mail' }"
                    v-if="$auth.hasPermission(config.permissions.email_template_write)"
                    class="btn btn-success btn-rounded mr-2 add-btn avatar-xs d-flex align-items-center justify-content-center"
                  >
                  <i class="mdi mdi-plus mdi-18px" v-b-tooltip.hover.v-light
                    :title="trans.get('__JSON__.Add Mail')"></i>
                </router-link>
              </div> -->
              <div class="col-sm-4 brand-status">
                <div class="form-group">
                  <div class="date-range-list">
                    <!-- <label>{{ trans.get('__JSON__.Status') }} :</label> -->
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="search-box d-inline-block mail-search">
                <div class="date-range-list form-group">
                  <div class="position-relative">
                    <input type="text" class="form-control" id="search" @input="searchData()" v-model="search"
                      :placeholder="trans.get('__JSON__.Search Placeholder')" />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
               <!--<div class="row">
                   <div class="col-sm-2 offset-sm-8">
                    <div class="form-group">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Status') }} :</label>
                        <select class="custom-select" v-model="status" @change="searchData()">
                          <option value="">{{ trans.get('__JSON__.All') }}</option>
                          <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                          <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="search-box mr-2 mb-2 d-inline-block">
                      <div class="date-range-list">
                        <label>{{ trans.get('__JSON__.Search') }} :</label>
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control"
                            @keyup="searchData()"
                            v-model="search"
                            :placeholder="trans.get('__JSON__.Search...')"
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                   </div>
              </div> -->
              <div class="row">
                <div
                  class="col-12 table-main-list cuisine-table  email-report "
                >
                <div class="table-responsive table-border-0 scroll-table">
                  <b-table
                    :items="mail"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(subject)="data">
                      <h5 class="font-size-14 mb-1">
                        <div>{{ data.item.subject }}</div>
                      </h5>
                    </template>

                    <template v-slot:cell(status)="data">
                      <div
                        v-if="data.item.status == '0'"
                        class="badge badge-pill badge-soft-success font-size-12"
                      >{{ trans.get('__JSON__.Active') }}</div>
                      <div
                        v-else
                        class="badge badge-pill badge-soft-danger font-size-12"
                      >{{ trans.get('__JSON__.Inactive') }}</div>
                    </template>


                    <template v-slot:cell(action)="data">
                        <div class="vendor-btn">
                        <router-link
                            v-if="$auth.hasPermission(config.permissions.email_template_write)"
                            :to="{name: 'edit-mail',params: { id: base64(data.item.id) }}"
                            class="btn btn-success btn-sm btn-rounded ml-1"
                        >{{ trans.get('__JSON__.Edit') }}</router-link>
                        <i v-else v-b-tooltip="'Need Permission'" class="mdi mdi-information h4 text-danger"></i>
                         <router-link
                            v-if="$auth.hasPermission(config.permissions.email_template_write)"
                            :to="{name: 'view-mail',params: { id: base64(data.item.id) }}"
                            class="btn btn-primary btn-sm btn-rounded ml-1"
                        >{{ trans.get('__JSON__.View') }}</router-link>
                        </div> 
                    </template>

                    <template #empty>
                      <p class="text-center">
                        {{ trans.get("__JSON__.No Mail") }}
                      </p>
                    </template>
                  </b-table>
                </div>
                
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getMails"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
  </Layout>
</template>

<style>
.email-report .table thead th:nth-child(2){
    pointer-events: none;
}
.mail-search .form-group{
  margin-bottom:0;
}
</style>