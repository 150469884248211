
<script>
import { generate_csv, get_currency, get_decimal, toKebabCase } from '../../../Helper/helper';
import pagination from "laravel-vue-pagination";
import Layout from "../../../layouts/main";
import { driverService } from '../../../services';
import config from "../../config";
export default {
    components: { Layout, pagination },
    data(){
        return {
            decimal: get_decimal(),
            currency: get_currency(),
            driverFinance: null,
            sortBy: "driver_name",
            sortDesc: true,
            limit: 1,
            loading:true,
            config:config,
            fields: [
                { key: "driver_name", label: this.trans.get("__JSON__.Driver Name",{driver:this.$auth.getDriverName()}), sortable: true , class: 'bold-text' },
                { key: "order_count", label: this.trans.get("__JSON__.Delivered Order"), sortable: false },
                { key: "driver_earning", label: this.trans.get("__JSON__.Earning"), sortable: false },
                { key: "cod_amount", label: this.trans.get("__JSON__.Total Wallet Amount"), sortable: false },
                { key: "pending_amount", label: this.trans.get("__JSON__.Pending Wallet Amount"), sortable: false },
                { key: "driverid", label: this.trans.get("__JSON__.Action"), sortable: false },
            ],
        }
    },

    mounted(){
        // this.$data.fields[0].label = this.$auth.setting.driver_name; 
        this.getMonthFinanceData();
    },
    methods:{
        sortingChanged(ctx) {
            // this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.getMonthFinanceData();
        },
        getMonthFinanceData(page = 1){
            this.loading = true;
            driverService.getDriverFinanceMonth({
                month:this.$route.params.month,
                sortBy: this.sortBy,
                page,
			    orderBy: this.sortDesc ? "desc" : "asc",
            })
            .then(res => {
                this.loading = false;
                this.driverFinance = res.data.result;
            });
        },
        downloadsCSV: function(){
            driverService.getDriverFinanceMonth({
                month:this.$route.params.month,
                sortBy: this.sortBy,
                orderBy: this.sortDesc ? "desc" : "asc",
                paginate: 0
            })
            .then(res => {
                this.loading = false;
                let vendorsData = '' + this.$auth.getDriverName()+' Name,Delivered Order,Earning,Total Wallet Amount,Pending Wallet Amount\n';                
                res.data.result.forEach(element => {
                    var line = element['driver_name'] + ',' 
                    + element['order_count'] + ',' 
                    + element['driver_earning'].toFixed(this.decimal) + ',' 
                    + element['cod_amount'].toFixed(this.decimal) + ',' 
                    + element['pending_amount'].toFixed(this.decimal) + '\n';
                    vendorsData+=line;
                })
                generate_csv(vendorsData,toKebabCase(this.$auth.getDriverName())+ '-finance-'+this.$route.params.month+'.csv')
                
            });
        }
    }
}
</script>

<template>
  <Layout>
     <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                  <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Finance Month') }}</h4>
                  <p>
                    <router-link :to="{ name: 'driver'}">{{ trans.trans('__JSON__.Driver Finance',{ driver: $auth.getDriverName() }) }}</router-link>
                    >
                    {{$route.params.month}}
                    </p>
                  </div>
              </div>
               <div class="page-title-right">
                  <button
                    @click="downloadsCSV"
                    v-if="$auth.hasSubAdminPermission(config && config.permissions && config.permissions.driver_finance_export)"
                    class="btn btn-primary btn-rounded"
                  >{{trans.get('__JSON__.Export To CSV')}}</button>
                </div>
              </div>
          </div>
      </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
              <div class="row" v-if="driverFinance">
                <div class="col-12">
                    <div class="table-responsive table-border">
                        <b-table
                            :items="driverFinance.data"
                            :fields="fields"
                            :sort-by.sync="sortBy"
                            :no-local-sorting="true"
                            @sort-changed="sortingChanged"
                            :busy="loading" 
                            show-empty
                            class="table-centered table-nowrap table-hover order-menu"
                        >
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                                </div>
                            </template>
                            <template #cell(driver_name)="data">
                                <span class="font-weight-bold">{{ data.value }}</span>
                            </template>
                            <template #cell(order_count)="data">
                                {{ data.value }}
                            </template>
                            <template #cell(driver_earning)="data">
                                <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) }}
                            </template>
                            <template #cell(cod_amount)="data">
                                <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) }}
                            </template>
                            <template #cell(pending_amount)="data">
                                <span v-html="currency"></span> {{ (data.value || 0).toFixed(decimal) }}
                            </template>
                            <template #cell(driverid)="data">
                                <router-link :to="{name: 'driver-finance',params: {driverid: data.value, month:$route.params.month}}"
                                        class="btn btn-primary btn-sm btn-rounded"
                                    >{{trans.get('__JSON__.View')}}</router-link>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                        :data="driverFinance"
                        :limit="limit"
                        @pagination-change-page="getMonthFinanceData"
                        ></pagination>
                    </ul>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>

</style>