<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { settingService } from '../../../services';
import config from "../../../config";
import { success_message, error_message } from '../../../Helper/helper';

/**
 * Starter component
 */
export default {
  data() {
    return {
      config:config,
      setting: [],
      setting: {
        category_layout: ''
      }
    };
  },
  validations: {
    setting: {
      category_layout: {
        required : requiredIf(function(form){
        	return !this.setting.category_layout;
        })
      }
    },
  },
 mounted() {
    this.getAppSetting();
  },
  methods: {
    getAppSetting(){
      settingService.getAppSettingApi().then((response) => {
            this.setting = response.data.result.settings;
      })
    },
    generalIt(){
      this.$v.setting.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("category_layout", this.setting.category_layout);
        fd.append('type',2);
        settingService.updateAppSetting(fd).then((response) => {
          if (response.data.code === 200) {
              success_message(response.data.message);
            } else {
              error_message(response.data.message);
            }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Home Page Layout') }}</label>
                  <select class="custom-select" id="category_layout" name="category_layout" v-model="setting.category_layout">
                    <option v-bind:value="1" selected>{{ trans.get('__JSON__.Layout 1') }}</option>
                    <option v-bind:value="2">{{ trans.get('__JSON__.Layout 2') }}</option>
                    <option v-bind:value="3">{{ trans.get('__JSON__.Layout 3') }}</option>
                    <option v-bind:value="4">{{ trans.get('__JSON__.Layout 4') }}</option>
                    <option v-bind:value="5">{{ trans.get('__JSON__.Layout 5') }}</option>
                    <option v-bind:value="6">{{ trans.get('__JSON__.Layout 6') }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 offset-sm-3">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Preview') }}</label>
                   <div v-if="setting.category_layout == '1'" class="home-layout">
                    <img :src="config.homepageimage+'1.png'">
                  </div>
                   <div v-else-if="setting.category_layout == '2'" class="home-layout">
                    <img :src="config.homepageimage+'2.png'">
                  </div>
                   <div v-else-if="setting.category_layout == '3'" class="home-layout">
                    <img :src="config.homepageimage+'3.png'">
                  </div>
                   <div v-else-if="setting.category_layout == '4'" class="home-layout">
                    <img :src="config.homepageimage+'4.png'">
                  </div>
                   <div v-else-if="setting.category_layout == '5'" class="home-layout">
                    <img :src="config.homepageimage+'5.png'">
                  </div>
                   <div v-else-if="setting.category_layout == '6'" class="home-layout">
                    <img :src="config.homepageimage+'6.png'">
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  v-if="$auth.hasPermission(config.permissions.layout_write)"
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>