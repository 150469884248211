<template>  
    <b-modal
        id="modal-sm"
        size="lg"
        title-class="font-18"
        @hidden="resetModal"
        @ok="sendTodunzo"
        :ok-title="trans.get('__JSON__.Schedule')"
        ok-variant="danger"
        ref="dunzo-modal"
        lazy
        >
        <template #modal-header={close}>
            <div class="d-flex justify-content-between w-100">
                <h4 class="mb-0">Schedule To Dunzo</h4>
                <h4 class="mb-0">{{orderId}}</h4>
            </div>
            <button type="button" aria-label="Close" class="close" @click="close()">&times;</button>
        </template>

        <div class="row">
            <div class="col-12">
                <b-form role="form">
                    <b-form-group label="Select Time">
                      <date-picker v-model="dunzo.schedule_time"
                      :disabled-time="checkTime" :disabled-date="checkDate"  type="datetime" format="DD-MM-YYYY HH:mm:ss" valueType="YYYY-MM-DD HH:mm:ss"></date-picker>
                    </b-form-group>
                </b-form>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { orderService } from '../../../services';
export default {
  components: { DatePicker },
  props :['order-id'],
  data(){
    return {
      dunzo:{
        schedule_time: null
      },
      moment
    }
  },
  mounted(){
    this.$refs['dunzo-modal'].show();
  },
  methods:{
    sendTodunzo(){
      if(confirm('Are sure you want to send order to dunzo?')){
        const data = {order_id:this.orderId,vendor_id:this.$auth.getVendorId(), ...this.dunzo};
        orderService.sendToDunzo(data)
          .then(response => {
            if(response.data.code == 200){
                this.$toasted.success(response.data.message);
                this.$emit('actionChanged');
            }else{
                this.$toasted.error(response.data.message);
            }
          })
      }
    },
    resetModal(){
       this.$emit('hidden')
      this.dunzo.schedule_time = null
    },
    checkTime(date){
      console.log(date,this.moment().add(30,'minute').diff(this.moment(date),'minutes'));
      return (this.moment().add(30,'minute').diff(this.moment(date),'minutes') > 0)
    },
    checkDate(date){
      
      return (this.moment().add(30,'minute').diff(this.moment(date),'days') > 0)
    }
  }
}
</script>

<style>

</style>