<template>
  <div>
    <router-view></router-view>
    <div class="floating-button" v-if="$auth.logging_done && $auth.partner_login == false" >
      <div class="badge-text" v-if="$auth.unreadMessages.length">{{ $auth.unreadMessages.length }}</div>
      <router-link target="_blank" class="text-white" :to="{name:'chat-list'}"><i class="fab fa-rocketchat"></i></router-link>

    </div>
  </div>
</template>
<script>
import config from '../config';
import { bookingsService, orderService , zomatoOrderService } from '../services';
export default {
  name: 'app',
  data(){
    return {
      isConnected: false,
      login_id: null,
      interval: null
    }
  },
  watch:{
    "$auth.logging_done":function(){
      if(this.$route.name != 'menu-list'){
        if(this.$auth.logging_done){
          this.addEvents();
        }else{
          if(this.login_id){
            this.sockets.unsubscribe('booking_placed_'+this.login_id)
            this.sockets.unsubscribe('order_placed_'+this.login_id)
            this.sockets.unsubscribe('order_confirmed_'+this.login_id)
            this.sockets.unsubscribe('order_cancelled_'+this.login_id)
            this.sockets.unsubscribe('booking_placed_partner_'+this.login_id)
            this.sockets.unsubscribe('order_placed_partner_'+this.login_id)
            this.sockets.unsubscribe('order_confirmed_partner_'+this.login_id)
            this.sockets.unsubscribe('order_cancelled_partner_'+this.login_id)
            this.login_id = null;
          }
        }
      }
    }
  },
  mounted() {
    HTMLMediaElement.autoplay = true;
    setTimeout(() => {
      if(this.$auth.logging_done && this.$route.name != 'menu-list'){
        this.getOrdersCount();
        this.getBookingCount();
        this.addEvents();
        this.getZomatoCount();
      }
    },5000)
    Notification.requestPermission();
  },
  created(){
      Vue.prototype.base64 = (i)  => btoa(i);
      // let token = localStorage.getItem('token');
      // if(!token && window.location.href.indexOf('login') === -1){
      //     this.$router.push({name:'login'});
      // }else{
      //   axios.defaults.headers.common = {
      //     'Accept': 'application/json',
      //     'Authorization': 'Bearer '+ token
      //   };
      // }
      
      this.$auth.setUser((type)=> {
        if(type) {
          if(this.$route && this.$route.name.includes('login')){
            this.$router.push({ name: type }).catch(()=>{});
          }
        }
      });
  },
  sockets:{
    connect() {
      this.isConnected = true;
    },
    disconnect() {
      this.isConnected = false;

    },
    // message(data){      
    //   this.getOrdersCount();
    //   var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
    //   myaudio.play();
    //   myaudio.autoplay;
    // }
  },
  methods:{
    notifyMessage(data){
      let alertTitle = "You have new message from " + (data?.sender_name??'User');
      let message = (data?.message && data?.message != '') ? data?.message : 'Image';
      let notificationicon = (config?.foodicon??'')+(this.$auth?.setting?.flat_icon??'');
      if (!("Notification" in window)) {
        // alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // var notification = new Notification(message);
        new Notification(alertTitle, {
          body: message,
          icon: notificationicon
        });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            // var notification = new Notification(message);
            new Notification(alertTitle, {
              body: message,
              icon: notificationicon
            });
          }
        });
      }
    },
    addEvents(){
      if(this.$route.name != 'menu-list'){
        this.$auth.getUnreadMessages();
        this.sockets.subscribe('message_notification',
          (data) => {
            this.interval && clearTimeout(this.interval);
            this.notifyMessage(data);
            if (data.room == this.$auth.room) {
              this.$auth.received++;
            } else {
              setTimeout(()=> this.$auth.getUnreadMessages(), 500)
            }
          })
        this.sockets.subscribe('messagePass',
          (data) => {
            console.log(data)
            this.interval && clearTimeout(this.interval);
            if(data.room == this.$auth.room){
                this.$auth.received++;
            }
          })
        if(this.$auth.user && this.$auth.user.login_type == 'vendor'){
          this.login_id = this.$auth.getVendorId();
          this.sockets.subscribe('booking_placed_'+this.$auth.getVendorId(),
            (data) => {
              this.$auth.booking_count = parseInt(data && data.booking_count || 0);
              this.getBookingCount()
              if(this.$auth.setting.booking_notification_sound !== 'no_sound'){
                var myaudio = new Audio(config.appurl+this.$auth.setting.booking_notification_sound);
                myaudio.play();
                myaudio.autoplay;
              }
            })
          this.sockets.subscribe('order_placed_'+this.$auth.getVendorId(),
            () => {
              this.getOrdersCount();
              if(this.$auth.setting.order_notification_sound !== 'no_sound'){
                var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
                myaudio.play();
                myaudio.autoplay;
              }
            })
          this.sockets.subscribe('order_confirmed_'+this.$auth.getVendorId(),
            () => {
              this.getOrdersCount();
            })
          this.sockets.subscribe('order_cancelled_'+this.$auth.getVendorId(),
            () => {
              this.getOrdersCount();
            })
          this.sockets.subscribe('zomato_order_placed_'+this.$auth.getVendorId(), () => {
            this.getZomatoCount();
            if(this.$auth.setting.order_notification_sound !== 'no_sound'){
              var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
              myaudio.play();
              myaudio.autoplay;
            }
          }),
          this.sockets.subscribe('zomato_order_cancelled_'+this.$auth.getVendorId(), () => {
            this.getZomatoCount();
          }),
          this.sockets.subscribe('zomato_order_change_'+this.$auth.getVendorId(), () => {
            this.getZomatoCount();
          })     
        }else{
          if(!this.$auth.user ){
            return false;
          }
          this.login_id = this.$auth.user.user_id
          this.sockets.subscribe('booking_placed_partner_'+this.login_id,
            (data) => {
              this.$auth.booking_count = parseInt(data && data.booking_count || 0);
              this.getBookingCount()
              if(this.$auth.setting.booking_notification_sound !== 'no_sound'){
                var myaudio = new Audio(config.appurl+this.$auth.setting.booking_notification_sound);
                myaudio.play();
                myaudio.autoplay;
              }
            })
          this.sockets.subscribe('order_placed_partner_'+this.login_id,
            () => {
              this.getOrdersCount();
              if(this.$auth.setting.order_notification_sound !== 'no_sound'){
                var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
                myaudio.play();
                myaudio.autoplay;
              }
            })
          this.sockets.subscribe('order_confirmed_partner_'+this.login_id,
            () => {
              this.getOrdersCount();
            })
          this.sockets.subscribe('order_cancelled_partner_'+this.login_id,
            () => {
              this.getOrdersCount();
            })
        }      
      }
    },
    getBookingCount(){
      bookingsService.getNewBookings().then((response) => {
        this.$auth.updateBookings(response.data.result)
      })
      .catch(function (error) { });
    },
    getOrdersCount(){
      orderService.getNewOrders().then((response) => {
        this.$auth.updateOrders(response.data.result)
      })
      .catch(function (error) { });
    },
    getZomatoCount(){
      zomatoOrderService.getOrdersCount().then((response) => {
        let count = response.data.result.orders_count.kitchen_orders || 0;
        this.$auth.updateZomatoCount(count)
      })
      .catch(function (error) { });
    }
  }
}
</script>
<style scoped>
  .floating-button{
    position: fixed;
    z-index: 9999;
    background: #03a9f4;
    bottom: 40px;
    right: 40px;
    /* height: 30px; */
    padding: 10px 14px;
    font-size: 20px;
    border-radius: 50%;
  }
  .badge-text{
    position: absolute;
    top: 0px;
    left: 36px;
    background: #f44336;
    color: wheat;
    border-radius: 10px;
    font-size: 11px;
    padding: 0 4px;
  }
</style>