<script>

import Multiselect from 'vue-multiselect'
import PageHeader from "../../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Layout from "../../../layouts/main";
import { vendorFinanceService, settingService } from '../../../services';
import { date_ranges, generate_csv, get_currency, get_decimal } from '../../../Helper/helper';
import pagination from "laravel-vue-pagination";
import config from "../../config";
/**
 * Contacts-list component
 */ 

export default {
  components: { Layout, PageHeader,Multiselect,DateRangePicker,pagination },
  props: ['restaurant_id'],
  data() {
    let startDate = this.moment().startOf('day').toDate();
    let endDate = this.moment().endOf('day').toDate();
    return {
        dateRange: { startDate, endDate },
        decimal:get_decimal(),
        month_data: {},
        limit:1,
        config: config,
        month: this.moment().format('MMMM'),
        selected_date: this.moment().format('YYYY-MM-DD'),
        sortBy: "date",
        sortDesc: true,
        setting: [],
        years: [],
        currency: get_currency(),
        months: Array.apply(0, Array(12)).map((_,i) => this.moment().month(i).format('MMMM')).reverse(),
        fields: [
            { key: "hash_id", label: '#', sortable: false },
            { key: "date", label: this.trans.get("__JSON__.Date"), sortable: false },
            { key: "sum_sub_total", label: this.trans.get("__JSON__.Item Price"), sortable: false },
            { key: "sum_delivery_fee", label: this.trans.get("__JSON__.Delivery Charges"), sortable: false },
            { key: "sum_service_fee", label: this.$auth.getTaxName(), sortable: false },
            { key: "sum_discount", label: this.trans.get("__JSON__.Coupon Discount"), sortable: false },
            { key: "sum_admin_commision", label: this.trans.get("__JSON__.Admin Commission"), sortable: false },
            { key: "sum_amount", label: this.trans.get("__JSON__.Total Amount"), sortable: false },
            { key: "sum_admin_earning", label: this.trans.get("__JSON__.Admin Earning"), sortable: false , requiresAdmin: true},
            { key: "chef_earning", label: this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()}), sortable: false },
        ],
        date_ranges: date_ranges
    };
  },
  filters: {
    date(val) {
        return val ? val.toLocaleString() : "";
    },
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);
        
      // If the user IS an admin, return all fields.
      else
        return this.fields;
    }
  },
  mounted(){
    this.orderWiseRestaurantEarnings();
  },
  methods:{
    orderWiseRestaurantEarnings(page = 1){
      vendorFinanceService.getOrderwiseRestaurantEarnings({
        start_date: this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        restaurant_id: this.restaurant_id,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page ? page : 1,
          
      })
        .then(res => {
            this.month_data = res.data.result.data.data;
            const total = res.data.result.data.total||{};
            if(this.month_data.total!==0){
              total.hash_id = this.trans.get('__JSON__.Total');
              total.date = '-';
              this.month_data.data = [...this.month_data.data,(total||{})]
              this.currency = res.data.result.currency
            }
        });
    },
    downloadsCSV(page = 1){
      vendorFinanceService.getOrderwiseRestaurantEarningsExport({
        start_date: this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        restaurant_id: this.restaurant_id,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page ? page : 1,  
      })
        .then(res => {
            let export_data = res.data.result.data.data;
            const total = res.data.result.data.total || {};
            if(export_data.length > 0){
              total.hash_id = this.trans.get('__JSON__.Total');
              total.date = '-';
            export_data = [...export_data,(total||{})]
            this.currency = res.data.result.currency
            }

            let vendorFinance = '\ufeff' +
                '#,'
                +this.trans.get("__JSON__.Date")+','
                +this.trans.get("__JSON__.Item Price")+','
                +this.trans.get("__JSON__.Delivery Charges")+','  
                +this.$auth.getTaxName()+','
                +this.trans.get("__JSON__.Coupon Discount")+','
                +this.trans.get("__JSON__.Admin Commission")+','
                +this.trans.get("__JSON__.Total Amount")+','
                +this.trans.get("__JSON__.Admin Earning")+','
                +this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()})
                +'\n';
            export_data.map(el=> {
              var line = ''+el['hash_id'] + ',' 
              + el['date'] + ',' 
              + el['sum_sub_total'].toFixed(this.decimal) + ',' 
              + el['sum_delivery_fee'].toFixed(this.decimal) + ',' 
              + el['sum_service_fee'].toFixed(this.decimal) + ',' 
              + el['sum_discount'].toFixed(this.decimal) + ',' 
              + el['sum_admin_commision'].toFixed(this.decimal) + ',' 
              + el['sum_amount'].toFixed(this.decimal) + ',' 
              + el['sum_admin_earning'].toFixed(this.decimal) + ',' 
              + el['chef_earning'].toFixed(this.decimal) +'\n'
              vendorFinance +=line;
            })
            generate_csv(vendorFinance,this.$auth.getRestaurantName() + "-finance-"
            +this.moment(this.dateRange.startDate).format('YYYY-MM-DD')+'-'+
            this.moment(this.dateRange.endDate).format('YYYY-MM-DD')+
            '.csv');
        });
    },
    // downloadsCSV: function(){
    //   let vendorFinance = '\ufeff' + 'OrderID,Item Price,Delivery Charge,Coupon Discount,Admin Commission,Total Amount,Admin Earning,'+this.$auth.getRestaurantName()+' Earning\n'
    //   this.month_data.map(el=> {
    //     var line = '#'+el['hash_id'] + ',' 
    //     + el['sum_sub_total'].toFixed(this.decimal) + ',' 
    //     + el['sum_delivery_fee'].toFixed(this.decimal) + ',' 
    //     + el['sum_discount'].toFixed(this.decimal) + ',' 
    //     + el['sum_admin_commision'].toFixed(this.decimal) + ',' 
    //     + el['sum_amount'].toFixed(this.decimal) + ',' 
    //     + el['sum_admin_earning'].toFixed(this.decimal) + ',' 
    //     + el['chef_earning'].toFixed(this.decimal) +'\n'
    //     vendorFinance +=line;
    //   })

    //   var blob = new Blob([ vendorFinance ], { "type" : "csv/plain" });
    //   let link = document.createElement('a')
    //   link.href = window.URL.createObjectURL(blob)
    //   link.download = this.$auth.getRestaurantName() + "-finance-"+this.month+'-'+this.year+'.csv'
    //   link.click()
    // },
    disabledDate(date){
      return this.moment(date).isAfter(this.moment())
    }
    /* getTotal(key){
      return Object.keys(this.vendorFinance && this.vendorFinance.monthWiseFinance || {})
        .reduce((acc, index) => {
          return acc + parseFloat(this.vendorFinance.monthWiseFinance[index][key]?this.vendorFinance.monthWiseFinance[index][key].toString().replaceAll(',',''):0);
        },0).toFixed(2);
    } */
  }
};
</script>

<template>
<div class="row">
    <div class="col-12">
                <div class="justify-content-end mb-3 row">
                    <div class="">
                        <label for="">{{trans.get('__JSON__.Date')}}</label>
                        <div class="text-sm-right">
                            <div class="mr-2 ">
                              <date-range-picker
                                ref="picker"
                                :locale-data="{
                                  firstDay: 1,
                                  format: 'dd-mm-yyyy',
                                }"
                                :showDropdowns="true"
                                :showWeekNumbers="true"
                                opens="left"
                                v-model="dateRange"
                                :ranges="date_ranges()"
                                @update="orderWiseRestaurantEarnings"
                              >
                                <template
                                  v-slot:input="picker"
                                  style="min-width: 350px"
                                >
                                  {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                                  {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                                </template>
                              </date-range-picker>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 pt-1 mr-3">
                        <div class="text-sm-right">
                            <button @click="downloadsCSV" 
                            v-if="$auth.hasSubAdminPermission(config.permissions.vendor_finance_export)"
                            class="btn btn-primary btn-rounded">
                            {{trans.get('__JSON__.Export To CSV')}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive table-border">
                    <b-table
                        :items="month_data && month_data.data"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :no-local-sorting="true"
                        show-empty
                        class="table-centered table-nowrap table-hover order-menu"
                    >
                        <template #cell(hash_id)="data">
                          <span class="font-weight-bold">{{data.value}}</span>
                        </template>
                        <template #cell(date)="data">
                            {{ data.value }}
                        </template>
                        <template #cell(sum_sub_total)="data">
                            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                        </template>
                        <template #cell(sum_delivery_fee)="data">
                            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                        </template>
                        <template #cell(sum_service_fee)="data">
                            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                        </template>
                        <template #cell(sum_discount)="data">
                            <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
                        </template>
                        <template #cell(sum_admin_commision)="data">
                            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                        </template>
                        <template #cell(sum_amount)="data">
                            <span v-html="currency"></span> {{ data.value.toFixed(decimal) }}
                        </template>
                        <template #cell(sum_admin_earning)="data">
                            <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
                        </template>
                        <template #cell(chef_earning)="data">
                            <span v-html="currency"></span> {{ data.value && data.value.toFixed(decimal) || '0.00'}}
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="col-12" v-if="month_data && month_data.total > 0">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <pagination
                    :data="month_data"
                    :limit="limit"
                    @pagination-change-page="orderWiseRestaurantEarnings"
                  ></pagination>
                </ul>
              </div>
            </div>
</div>
</template>