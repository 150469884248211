<script>

  import Layout from "../../layouts/main";
  import PageHeader from "../../components/page-header";
  import GiftVoucherList from './gift-voucher-list';
  /**
   * Customers component
   */
  export default {
    components: { Layout, PageHeader, GiftVoucherList},
    data() {
      return {};
    }, 
  };

</script>

<template>
  <Layout>
    <gift-voucher-list/>
  </Layout>
</template>
