<script>
import DateRangePicker from "vue2-daterange-picker";
import pagination from "laravel-vue-pagination";
// import OrderList from "./order_tabs/order-list";
import OrderList from "../orders/order_tabs/order-list.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { zomatoOrderService} from '../../services';
import { date_ranges, debounce, order_statuses } from '../../Helper/helper';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel;
let cancelCount;
export default {
    components: { DateRangePicker,OrderList,pagination },
    props:['filterChange'],
    data() {
        let startDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().startOf('day').toDate();
        let endDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().endOf('day').toDate();
        return {
            dateRange: { startDate, endDate },
            statuses: order_statuses,
            orders: null,
            ordersCount: null,
            loading: false,
            live_orders:0,
            limit: 1,
            tabIndex: 0,
            page: 1,
            statusesArray:[],
            delay_order_btn:true,
            not_response_btn:true,
            cancel_order:true,
            filters: {
                status: order_statuses.inkitchen,
                search_type:'order',
                search: '',
                start_date: this.moment().format('YYYY-MM-DD'),
                end_date: this.moment().format('YYYY-MM-DD')
            },
            driverColumns: [
                { key: "driver", label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}), sortable: false,formatter: value => (value.length > 0 ?`${value[0].firstname+' '+value[0].lastname}`:'-')}
            ],
            cancelledColumns: [
                { key: "cancelled_date", label: this.trans.get("__JSON__.Cancel Time"), sortable: false },
            ],
            readyToDeliveryColumns: [
                { key: "assign", label: this.trans.get("__JSON__.Assign"), sortable: false}
            ],
            debounce,
            searchChanged:debounce(()=>{
                this.getOrders()
                this.getOrdersCount();
            },500),
            getCount:debounce(()=>{
                this.getOrdersCount();
            },500),      
            date_ranges: date_ranges
        }
    },
    methods:{
        updateValues(date) {
            this.filters = {
                ...this.filters,
                start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
                end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
            }
            window.order_count_refresh = true;
            this.getData()
        },
        getData(){
            if (cancel !== undefined) {
                cancel();
            }
            this.getOrders();
            this.getOrdersCount();
            // if(window.order_count_refresh){
                
            //     window.order_count_refresh = false;
            // }
        },
        onTabChanged(tabIndex){
            this.tabIndex = tabIndex;
            this.filters.status = this.statusesArray[tabIndex];
            this.getData();
            this.getOrdersCount();
        },
        getOrders(page = 1){
            this.page = page;
            this.loading = true;
            zomatoOrderService.getOrders({...this.filters,page},{cancelToken : new CancelToken(c => {cancel = c;})}).then((response) => {
                this.orders = response.data.result;
                setTimeout(() => {
                    this.loading = false;
                }, 500);
                this.updateCount(response.data.result.total);
                this.$emit('filterChange',this.filters);
            }).catch((error) => {
                this.loading = false;
                console.log(error);
            });
        },
        getOrdersCount(){
            if (cancelCount !== undefined) {
                cancelCount();
            }
            //   this.checkMailTemplate();
            zomatoOrderService.getOrdersCount({...this.filters},{cancelToken : new CancelToken(c => {cancelCount = c;})}).then((response) => {
                this.ordersCount = response.data.result.orders_count;
            }).catch((error) => {
                this.loading = false;
                console.log(error);
            });
        },
        changeSettingRange(){
            var now = this.moment();
            var then = this.moment();
            if(this.$auth && this.$auth.setting){
                var range = this.$auth.setting.order_dashboard_default_time;
                var date_ranges = this.date_ranges();
                var keys = Object.keys(date_ranges)
                switch(range){
                case "0":
                    return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
                case "1":
                    return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
                case "6":
                    return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
                case "29":
                    return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
                case "365":
                    return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
                case "101":
                    return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
                default:
                    return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
                }
            }
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        },
        updateCount(total){
            const { placed, inkitchen, readytoserve, ontheway, delivered, cancelled, scheduled, paymentfailure } = order_statuses;
            switch(this.filters.status){
                case placed:
                    this.ordersCount.placed_orders = total;
                break;
                case inkitchen: 
                    this.ordersCount.kitchen_orders = total;
                break;
                case readytoserve:
                    this.ordersCount.ready_orders = total;
                break;
                case ontheway:
                    this.ordersCount.ontheway_orders = total;
                break;
                case delivered:
                    this.ordersCount.delivered_orders = total;
                break;
                case cancelled:
                    this.ordersCount.cancelled_orders = total;
                break;
                case scheduled:
                    this.ordersCount.scheduled_orders = total;
                break;
                case paymentfailure:
                    this.ordersCount.failed_orders = total;
                break;
            }
        },
        newOrderArrange(data){
            this.getOrdersCount();
            let order = data?.order??null;
            if (order && order?.order_status) {
                if ((this.filters && this.filters.status) && order?.order_status == this.filters.status) {
                    this.refreshOrderList();
                } else {
                    if (this.orders && this.orders.data && this.orders.data.length > 0) {
                        let findOrder = this.orders.data.find(o => o.id == order.id);
                        if (findOrder) {
                            this.refreshOrderList();
                        }
                    }
                }
            }
        },
        refreshOrderList() {
            this.getOrders();
        }
    },
    computed:{
        getSelected:function(){
            const { start_date, end_date } = this.filters;
            const date_ranges = this.date_ranges();
            return Object.keys(date_ranges).reduce((accu, day) => {
                if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
                    return day;
                }
                return accu;
            },'');
        }
    },
    filters: {
        date(val) {
            return val ? val.toLocaleString() : "";
        },
    },
    watch:{
        // "$auth.zomato_order_count": function(){
        //     this.getOrders();
        //     this.getOrdersCount();
        //     this.ordersCount.placed_orders = parseInt(this.ordersCount.placed_orders);
        // },
    },
    mounted() {
        window.order_count_refresh = false;
        const { placed, inkitchen, readytoserve, ontheway, delivered, cancelled, scheduled, paymentfailure } = order_statuses;
        this.statusesArray = [inkitchen, readytoserve, ontheway, delivered , cancelled];
        this.dateRange =this.changeSettingRange();
        this.filters = {
            ...this.filters,
            status: this.$route.query.status?this.$route.query.status:order_statuses.inkitchen,
            search_type: this.$route.query.search_type?this.$route.query.search_type:'order',
            start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
            end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
        }
        this.getOrders();
        this.getOrdersCount();
        this.sockets.subscribe('zomato_order_placed_'+this.$auth.getVendorId(),this.newOrderArrange)
        this.sockets.subscribe('zomato_order_change_'+this.$auth.getVendorId(),this.newOrderArrange)
        this.sockets.subscribe('zomato_order_cancelled_'+this.$auth.getVendorId(),this.newOrderArrange)
    }
}
</script>
<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-md-12">
                            <!-- <div class="sales-report float-lg-right"> -->
                            <div class="d-flex justify-content-end">

                                <!-- Filter -->
                                <div class="ml-2">
                                    <div class="search-box mr-2 mb-0 d-inline-block form-group">
                                        <div class="date-range-list">
                                            <label>{{trans.get('__JSON__.Filter')}} :</label>
                                            <div class="position-relative">
                                                <select
                                                    @change="searchChanged"
                                                    class="custom-select"
                                                    v-model="filters.search_type"
                                                    id="admin_commision_type"
                                                    name="admin_commision_t ype"
                                                >
                                                    <option value="">{{ trans.get('__JSON__.Select Filter') }}</option>
                                                    <option value="order">Orders</option>
                                                    <option value="user_name">User Name</option>
                                                    <option value="mobile_no">Mobile Number</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Filter -->

                                <!-- search -->
                                <div class="ml-2">
                                    <div class="search-box mr-2 mb-0 d-inline-block form-group">
                                        <div class="date-range-list">
                                            <label>{{trans.get('__JSON__.Search')}} :</label>
                                            <div class="position-relative">
                                                <input
                                                    @change="searchChanged"
                                                    type="text"
                                                    class="form-control"
                                                    v-model="filters.search"
                                                    :placeholder="trans.get('__JSON__.Search...')"
                                                />
                                                <i class="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- search -->

                                <!-- date picker -->
                                <div class="">
                                    <div class="form-group ml-2">
                                        <div class="date-range-list">
                                            <label>{{trans.get('__JSON__.Date')}} {{(getSelected!=''?('('+getSelected+')'):'')}} :</label>
                                            <date-range-picker
                                            ref="picker"
                                            :locale-data="{
                                                firstDay: 1,
                                                format: 'dd-mm-yyyy',
                                            }"
                                            :showDropdowns="true"
                                            :showWeekNumbers="true"
                                            opens="left"
                                            v-model="dateRange"
                                            class="w-100"
                                            :ranges="date_ranges()"
                                            @update="updateValues"
                                            >
                                            <template
                                                v-slot:input="picker"
                                                style="min-width: 350px"
                                            >
                                                {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                                                {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                                            </template>
                                            </date-range-picker>
                                        </div>
                                    </div>
                                </div>
                                <!-- date picker -->
                            </div>
                        </div>
                        <div class="col-12">
                            <b-tabs
                                justified
                                nav-class="nav-tabs-custom mt-0 order-tabs"
                                content-class="p-3 text-muted"
                                lazy
                                @activate-tab="onTabChanged"
                            >
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">{{
                                            trans.get("__JSON__.Confirmed")
                                        }}</span>
                                        <span v-if="ordersCount && ordersCount.kitchen_orders && ordersCount.kitchen_orders !== '0'" class="badge badge-success font-size-12">
                                            {{ ordersCount && ordersCount.kitchen_orders || 0 }}
                                        </span>
                                    </template>
                                    <OrderList :orders="orders" :loading="loading" @refreshData="getData" :extra-columns="readyToDeliveryColumns" :cancel_order="cancel_order"  :thirdPartyOrder="true"/>
                                </b-tab>
                                <b-tab :button-id="statuses.readytoserve">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">{{
                                            trans.get("__JSON__.Ready to Delivery")
                                        }}</span>
                                        <span v-if="ordersCount && ordersCount.ready_orders && ordersCount.ready_orders !== '0'" class="badge badge-info font-size-12">
                                            {{ ordersCount && ordersCount.ready_orders || 0 }}
                                        </span>
                                    </template>
                                    <OrderList :orders="orders" :loading="loading" @refreshData="getData" :extra-columns="readyToDeliveryColumns" :cancel_order="cancel_order" :thirdPartyOrder="true" />
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">{{
                                            trans.get("__JSON__.On The Way")
                                        }}</span>
                                        <span v-if="ordersCount && ordersCount.ontheway_orders && ordersCount.ontheway_orders !== '0'" class="badge badge-warning font-size-12">
                                            {{ ordersCount && ordersCount.ontheway_orders || 0 }}
                                        </span>
                                    </template>
                                    <OrderList :orders="orders" :loading="loading" @refreshData="getData" :extra-columns="driverColumns" :cancel_order="cancel_order" :thirdPartyOrder="true" />
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">{{
                                            trans.get("__JSON__.Delivered")
                                        }}</span>
                                        <span v-if="ordersCount && ordersCount.delivered_orders && ordersCount.delivered_orders !== '0'" class="badge badge-info font-size-12">
                                            {{ ordersCount && ordersCount.delivered_orders || 0 }}
                                        </span>
                                    </template>
                                    <OrderList :orders="orders" :loading="loading" @refreshData="getData" :extra-columns="driverColumns" :cancel_order="cancel_order" :thirdPartyOrder="true" />
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">{{
                                            trans.get("__JSON__.Cancelled")
                                        }}</span>
                                        <span v-if="ordersCount && ordersCount.cancelled_orders && ordersCount.cancelled_orders !== '0'" class="badge badge-danger font-size-12">
                                            {{ ordersCount && ordersCount.cancelled_orders || 0 }}
                                        </span>
                                    </template>
                                    <OrderList :orders="orders" :loading="loading" @refreshData="getData" :extra-columns="cancelledColumns" :cancel_order="cancel_order" :thirdPartyOrder="true" />
                                </b-tab>
                            </b-tabs>
                        </div>
                        <div class="col-12">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0" v-if="orders">
                                    <pagination
                                        :data="orders"
                                        :limit="limit"
                                        @pagination-change-page="getOrders"
                                    ></pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>