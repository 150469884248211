<script>

import config from "../../config";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { mailService } from "../../services";

export default {
    components: { Layout, PageHeader,mailService },
    data() {
        return {
        config: config,
        typeform: {}
        };
    },
    mounted(){
        this.viewMail();
    },
    methods: {
        viewMail(){
            mailService.viewMail({id: this.$route.params.id})
                .then(response => {
                    this.typeform = response.data.result;
            });
        }
    },
};
</script>
<template>
 <Layout>  
    <div> 
      <div class="row header-new">
        <div class="col-12">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              icon-main-page
            "
          >
            <div class="page-title-left">
              <div class="avatar-xs mr-3">
                <span
                  class="
                    avatar-title
                    rounded-circle
                    bg-soft-primary
                    text-primary
                    font-size-18
                  "
                >
                  <i class="bx bx-mail-send"></i>
                </span>
              </div>
              <div class="report-tag">
                <h4 class="mb-0 font-size-18">{{ typeform.subject }}</h4>
                <p class="m-0"><router-link :to="{ name: 'mail-list'}">{{ trans.get('__JSON__.View') }}</router-link> > {{ typeform.subject }}</p>
              </div>
            </div>
          </div>
        </div>
      <!-- end row --> 
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
                <span v-html="typeform.content"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
 </Layout>
</template>