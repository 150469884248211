
<script>
import PageHeader from "../../components/page-header";
import { faqService } from "../../services";
import { success_message, error_message } from "../../Helper/helper";

export default {
  components: { faqService, PageHeader },
  data() {
    const { type} = this.$route.query;
    return {
      faq: [],
      // type: type == undefined ? "customer" :type,
    };
  },
  init: true,
  mounted() {
    this.getFaqListing();
  },
  methods: {
    getFaqListing() {
      faqService.getFaqListing().then((response) => {
        this.faq = response.data.result;
      });
    },
    deleteById(id) {
      if (confirm("Are you sure you want to delete?")) {
        faqService.deleteFaqById({ id: id }).then((response) => {
          error_message(response.data.message);
          this.getFaqListing();
        });
      }
    },
    onTabChanged(tabindex){
      var type = 'customer';
      switch(tabindex){
        case 1:
          type = 'store';
          break;
        case 2:
          type = 'driver';
          break;
      }
      if(!this.init){
        this.$router.replace({name: 'faq-list', query: {type}})
      }
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-question-mark"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.FAQs') }}</h4>
          </div>
          <div class="page-title-right">
            <router-link :to="{name: 'faq-add'}" class="btn btn-success btn-rounded mr-2">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.ADD FAQ') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="checkout-tabs">
      <b-tabs pills vertical nav-class="p-0" nav-wrapper-class="col-lg-2 w-100" @activate-tab="onTabChanged">
        <b-tab  :active="!!$route.query.type || ($route.query.type != 'store' || $route.query.type != 'driver')">
          <template v-slot:title>
            <i class="bx bx-user d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">{{ trans.get('__JSON__.Customer') }}</p>
          </template>
          <b-card-text>
            <div class="faq-list-card">
              <div class="card">
                <div class="card-body">
                  <div class="tab-pane fade show active">
                    <h4 class="card-title mb-5">{{ trans.get('__JSON__.FAQs') }}</h4>
                    <div v-if="faq.filter(i => i.type == 'customer').length">
                      <div v-for="customer in faq" :key="customer.id">
                        <div v-if="customer.type == 'customer'">
                          <div class="faq-box media">
                            <div class="faq-icon mr-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="faq-qa">
                                  <h5 class="font-size-15">{{ customer.question }}</h5>
                                  <p class="text-muted">{{ customer.answer }}</p>
                                </div>
                                <div class="d-flex align-items-center faq-btn">
                                  <router-link
                                    :to="{ name: 'faq-edit', params: { id: base64(customer.id) } }"
                                    class="btn btn-success btn-sm btn-rounded"
                                  >{{ trans.get('__JSON__.Edit') }}</router-link>
                                  <button
                                    type="submit"
                                    class="btn btn-danger btn-sm btn-rounded"
                                    @click="deleteById(base64(customer.id))"
                                  >{{ trans.get('__JSON__.Delete') }}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p class="text-center">{{ trans.get('__JSON__.No FAQ Found') }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </b-card-text>
        </b-tab>

        <b-tab :active="$route.query.type == 'store'">
          <template v-slot:title>
            <i class="bx bx-store d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">{{ trans.trans('__JSON__.Vendors Faq',{ vendor: $auth.setting.restaurant_name }) }}</p>
          </template>
          <b-card-text>
            <div class="faq-list-card">
              <div class="card">
                <div class="card-body">
                  <div class="tab-pane fade show active">
                    <h4 class="card-title mb-5">{{ trans.get('__JSON__.FAQs') }}</h4>
                    <div v-if="faq.filter(i => i.type == 'store').length">
                      <div v-for="store in faq" :key="store.id">
                        <div v-if="store.type == 'store'">
                          <div class="faq-box media">
                            <div class="faq-icon mr-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="faq-qa">
                                  <h5 class="font-size-15">{{ store.question }}</h5>
                                  <p class="text-muted">{{ store.answer }}</p>
                                </div>
                                <div class="d-flex align-items-center faq-btn">
                                  <router-link
                                    :to="{ name: 'faq-edit', params: { id: base64(store.id) } }"
                                    class="btn btn-success btn-sm btn-rounded"
                                  >{{ trans.get('__JSON__.Edit') }}</router-link>
                                  <button
                                    type="submit"
                                    class="btn btn-danger btn-sm btn-rounded"
                                    @click="deleteById(base64(store.id))"
                                  >{{ trans.get('__JSON__.Delete') }}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p class="text-center">{{ trans.get('__JSON__.No FAQ Found') }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </b-card-text>
        </b-tab>

        <b-tab title-item-class="mb-2" :active="$route.query.type == 'driver'">
          <template v-slot:title>
            <i class="bx bx-cycling d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">{{ trans.trans('__JSON__.Driver Faq',{ driver: $auth.setting.driver_name }) }}</p>
          </template>
          <b-card-text>
            <div class="faq-list-card">
              <div class="card">
                <div class="card-body">
                  <div class="tab-pane fade show active">
                    <h4 class="card-title mb-5">{{ trans.get('__JSON__.FAQs') }}</h4>
                    <div v-if="faq.filter(i => i.type == 'driver').length">
                      <div v-for="driver in faq" :key="driver.id">
                        <div v-if="driver.type == 'driver'">
                          <div class="faq-box media">
                            <div class="faq-icon mr-3">
                              <i class="bx bx-help-circle font-size-20 text-success"></i>
                            </div>
                            <div class="media-body">
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="faq-qa">
                                  <h5 class="font-size-15">{{ driver.question }}</h5>
                                  <p class="text-muted">{{ driver.answer }}</p>
                                </div>
                                <div class="d-flex align-items-center faq-btn">
                                  <router-link
                                    :to="{ name: 'faq-edit', params: { id: base64(driver.id) } }"
                                    class="btn btn-success btn-sm btn-rounded"
                                  >{{ trans.get('__JSON__.Edit') }}</router-link>
                                  <button
                                    type="submit"
                                    class="btn btn-danger btn-sm btn-rounded"
                                    @click="deleteById(base64(driver.id))"
                                  >{{ trans.get('__JSON__.Delete') }}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p class="text-center">{{ trans.get('__JSON__.No FAQ Found') }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
</style>