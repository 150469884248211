<template>
  <div class="row">
    <div class="col-12 mt-3" v-if="pending">
      <PageHeader :title="trans.get('__JSON__.Pending Settlement')" />
      <div class="table-responsive mb-0 table-border" data-pattern="priority-columns">
        <table class="table table-centered table-nowrap table-hover" v-if="pending">
           <thead class="thead-light">
          <tr role="row" class="">
            <th>{{trans.get('__JSON__.Duration')}}</th>
            <th>{{trans.get('__JSON__.Order Count')}}</th>
            <th>{{trans.get('__JSON__.Item Price')}}</th>
            <th>{{trans.get('__JSON__.Delivery Charges')}}</th>
            <th>{{trans.get('__JSON__.Coupon Discount')}}</th>
            <th>{{trans.get('__JSON__.Total Amount')}}</th>
            <th>{{trans.get('__JSON__.Admin Commission')}}</th>
            <th>{{trans.trans('__JSON__.Vendor Earning',{ vendor: $auth.getRestaurantName() })}}</th>
            <th>{{trans.get('__JSON__.Status')}}</th>
            <th>{{trans.get('__JSON__.Action')}}</th>
          </tr>
           </thead>
           <tbody>
          <tr>
            <td>{{pending.duration_period}}</td>
            <td>{{pending.order_count}}</td>
            <td>
              <span v-html="currency"></span>
              {{pending.sum_sub_total.toFixed(decimal)}}
            </td>
            <td>
              <span v-html="currency"></span>
              {{pending.sum_delivery_fee.toFixed(decimal)}}
            </td>
            <td>
              <span v-html="currency"></span>
              {{(pending.sum_discount || 0).toFixed(decimal)}}
            </td>
            <td>
              <span v-html="currency"></span>
              {{pending.sum_total.toFixed(decimal)}}
            </td>
            <td>
              <span v-html="currency"></span>
              {{pending.sum_admin_commission.toFixed(decimal)}}
            </td>
            <td>
              <span v-html="currency"></span>
              {{pending.chef_earning.toFixed(decimal)}}
            </td>
            <td>
              <span
                class="badge badge-pill font-size-12"
                :class=" { 'badge-soft-success':pending.chef_earning == 'Paid',
                            'badge-soft-danger': pending.chef_earning != 'Paid' }"
              >{{trans.get('__JSON__.Pending')}}</span>
              <!-- <b-button :variant="pending.chef_earning == 'Paid'?'success':'danger'" class="btn-xs">{{trans.get('__JSON__.Pending')}}</b-button> -->
            </td>
            <td>
              <b-button
                variant="success"
                v-if="$auth.hasPermission(config.permissions.vendor_finance_write) && !$auth.partner_login"
                @click="openPaymodel"
              >{{trans.get('__JSON__.Pay')}}</b-button>
              <b-button variant="primary" @click="viewTransaction(pending.min_date, pending.max_date)">{{trans.get('__JSON__.View')}}</b-button>
            </td>
          </tr>
           </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 mt-3">
      <PageHeader :title="trans.get('__JSON__.Processed Settlement')" />
      <div class="table-responsive mb-0 table-border" data-pattern="priority-columns">
        <b-table :items="finances.data" :fields="fields" show-empty :filter="null" class="table-centered table-nowrap table-hover order-menu">
          <template #cell(sum_sub_total)="data">
            <span v-html="currency"></span>
            {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_delivery_fee)="data">
            <span v-html="currency"></span>
            {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_total)="data">
            <span v-html="currency"></span>
            {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_admin_commission)="data">
            <span v-html="currency"></span>
            {{ data.value.toFixed(decimal) }}
          </template>
          <template #cell(sum_discount)="data">
            <span v-html="currency"></span>
            {{ data.value && data.value.toFixed(decimal) || 0}}
          </template>
          <template #cell(chef_earning)="data">
            <span v-html="currency"></span>
            {{ data.value && data.value.toFixed(decimal) || 0}}
          </template>
          <template #cell(status)="data">
            <span
              class="badge badge-pill badge-soft-success font-size-12"
              :class=" { 'badge-soft-success':data.value == 'Paid',
                                'badge-soft-danger': data.value != 'Paid' }"
            >{{data.value == 'Paid'?'Received':'Pending'}}</span>
            <!-- <b-button :variant="data.value == 'Paid'?'success':'danger'" class="btn-xs">{{data.value }}</b-button> -->
          </template>
          <template #cell(id)="data">
            <b-button
              variant="primary"
              @click="paymentInfo(data.value)"
            >{{trans.get('__JSON__.Info')}}</b-button>
            <b-button
              variant="primary"
              @click="openTransaction(data.item.duration_period)"
            >{{trans.get('__JSON__.View')}}</b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="col-12">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <pagination
            :data="finances"
            :limit="limit"
            @pagination-change-page="getRestaurantSettlement"
          ></pagination>
        </ul>
      </div>
    </div>
    <b-modal id="modal-pay-info" size="lg" :title="trans.get('__JSON__.Info')" hide-footer>
      <table v-if="payment_info" class="table">
        <tr>
          <td>
            <span class="font-weight-bold">{{trans.get('__JSON__.Date Of Payment')}}</span>
          </td>
          <td>
            <span class="font-weight-bold">{{trans.get('__JSON__.Payment Mode')}}</span>
          </td>
          <td>
            <span class="font-weight-bold">{{trans.get('__JSON__.Transaction Id / Cheque No')}}</span>
          </td>
          <td>
            <span class="font-weight-bold">{{trans.get('__JSON__.Notes')}}</span>
          </td>
        </tr>
        <tr>
          <td>{{ payment_info.date_of_payment }}</td>
          <td>{{ payment_info.payment_mode }}</td>
          <td>{{ payment_info.transaction_id }}</td>
          <td>{{ payment_info.notes }}</td>
        </tr>
      </table>
    </b-modal>
    <b-modal id="modal-pay" :title="trans.get('__JSON__.Book Payment')" hide-footer>
      <form @submit.prevent="postBookPayment">
        <div class="row">
          <div class="col">
            <b-form-group
              id="example text"
              :label="trans.get('__JSON__.Date Of Payment')"
              label-for="date_of_payment"
            >
              <b-form-input
                v-model="$v.book_form.date_of_payment.$model"
                :class="{ 'is-invalid': $v.book_form.date_of_payment.$error }"
                class="form-control"
                id="date_of_payment"
                value="2019-08-19"
                type="date"
              ></b-form-input>
              <div v-if="$v.book_form.date_of_payment.$error" class="invalid-feedback">
                <span
                  v-if="!$v.book_form.date_of_payment.required"
                >{{ trans.get('__JSON__.Please enter date') }}</span>
              </div>
            </b-form-group>
            <div class="form-group">
              <label for="payment_mode">{{trans.get('__JSON__.Payment Mode')}}</label>
              <select
                name="payment_mode"
                :class="{ 'is-invalid': $v.book_form.payment_mode.$error }"
                id="payment_mode"
                class="form-control"
                v-model="$v.book_form.payment_mode.$model"
              >
                <option value="Wire">Wire</option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
              </select>
              <div v-if="$v.book_form.payment_mode.$error" class="invalid-feedback">
                <span
                  v-if="!$v.book_form.payment_mode.required"
                >{{ trans.get('__JSON__.Please enter payment mode') }}</span>
              </div>
            </div>
            <b-form-group
              :label="trans.get('__JSON__.Transaction Id / Cheque No')"
              label-for="transaction_id"
            >
              <b-form-input
                id="transaction_id"
                type="text"
                :class="{ 'is-invalid': $v.book_form.transaction_id.$error }"
                :placeholder="trans.get('__JSON__.Transaction Id / Cheque No')"
                v-model="$v.book_form.transaction_id.$model"
              ></b-form-input>
              <div v-if="$v.book_form.transaction_id.$error" class="invalid-feedback">
                <span
                  v-if="!$v.book_form.transaction_id.required"
                >{{ trans.get('__JSON__.Please enter transaction id') }}</span>
              </div>
            </b-form-group>
            <div class="form-group">
              <label for="notes">{{trans.get('__JSON__.Notes')}}</label>
              <textarea
                id="notes"
                cols="30"
                rows="10"
                class="form-control"
                :class="{ 'is-invalid': $v.book_form.notes.$error }"
                :placeholder="trans.get('__JSON__.Notes')"
                v-model="$v.book_form.notes.$model"
              ></textarea>
              <div v-if="$v.book_form.notes.$error" class="invalid-feedback">
                <span
                  v-if="!$v.book_form.notes.required"
                >{{ trans.get('__JSON__.Please enter notes') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              class="btn btn-secondary"
              type="button"
              @click="$bvModal.hide('modal-pay')"
            >{{trans.get('__JSON__.Cancel')}}</button>
            <button class="btn btn-primary" type="submit">{{trans.get('__JSON__.Pay')}}</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from "../../../components/page-header";
import pagination from "laravel-vue-pagination";
import { vendorFinanceService,settingService } from "../../../services";
import config from "../../../config";
import { required } from "vuelidate/lib/validators";
import {
  get_currency,
  get_decimal,
  success_message,
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
} from "../../../Helper/helper";
export default {
  components: { pagination, PageHeader },
  props: ["restaurant_id"],
  data() {
    return {
      config:config,
      setting: [],
      currency: get_currency(),
      decimal: get_decimal(),
      book_form: {
        date_of_payment: this.moment().format("YYYY-MM-DD"),
        payment_mode: "Wire",
        transaction_id: "",
        notes: "",
        restaurant_id: this.restaurant_id,
        order_ids: "",
      },
      limit: 1,
      sortBy: "date",
      sortDesc: true,
      finances: {},
      pending: null,
      payment_info: {},
      fields: [
        {
          key: "duration_period",
          label: this.trans.get("__JSON__.Duration"),
          sortable: false,
        },
        {
          key: "order_count",
          label: this.trans.get("__JSON__.Order Count"),
          sortable: false,
        },
        {
          key: "sum_sub_total",
          label: this.trans.get("__JSON__.Item Price"),
          sortable: false,
        },
        {
          key: "sum_delivery_fee",
          label: this.trans.get("__JSON__.Delivery Charges"),
          sortable: false,
        },
        {
          key: "sum_discount",
          label: this.trans.get("__JSON__.Coupon Discount"),
          sortable: false,
        },
        {
          key: "sum_total",
          label: this.trans.get("__JSON__.Total Amount"),
          sortable: false,
        },
        {
          key: "sum_admin_commission",
          label: this.trans.get("__JSON__.Admin Commission"),
          sortable: false,
        },
        {
          key: "chef_earning",
          label: this.trans.trans('__JSON__.Vendor Earning',{ vendor: this.$auth.getRestaurantName() }),
          sortable: false,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "id",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  validations: {
    book_form: {
      date_of_payment: { required },
      payment_mode: { required },
      transaction_id: { required },
      notes: {},
    },
  },
  created() {
    this.getRestaurantSettlement();
  },
  methods: {
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getRestaurantSettlement();
    },
    getRestaurantSettlement(page = 1) {
      vendorFinanceService
        .getRestaurantSettlement({
          restaurant_id: this.restaurant_id,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
        .then((res) => {
          this.finances = res.data.result.finances;
          this.pending = res.data.result.order;
          /* this.pastdayWise = res.data.result.data;
                this.currency = res.data.result.currency; */
        });
    },
    paymentInfo(id) {
      console.log(id);
      this.$bvModal.show("modal-pay-info");
      this.payment_info = this.finances.data.find((fins) => fins.id == id);
    },
    postBookPayment() {
      this.$v.book_form.$touch();
      if (!this.$v.book_form.$invalid) {
        vendorFinanceService.paySettlement(this.book_form).then((response) => {
          success_message(response.data.message);
          this.$bvModal.hide("modal-pay");
          this.getRestaurantSettlement();
        });
      }
    },
    openPaymodel() {
      this.book_form.order_ids = this.pending.order_ids;
      this.book_form.restaurant_id = this.restaurant_id;
      this.book_form.duration_period = this.pending.duration_period;
      this.$bvModal.show("modal-pay");
    },
    viewTransaction(min_date,max_date){
        // get orders between start date and end date
        this.$router.push({name : 'restaurant-transaction-list', params: {id: this.$route.params.id},query: {start_date: min_date,end_date: max_date}})
    },
    openTransaction(duration){
      this.viewTransaction(
        this.moment(duration.split('-')[0].trim(),'DD MMM YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        this.moment(duration.split('-')[1].trim(),'DD MMM YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
      );

    }
  },
};
</script>

<style>
</style>