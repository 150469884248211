export default{

        appname: 'DeOnDe',
        appurl: 'http://dev.deonde.co/deonde/',
        basepath: 'http://dev.deonde.co/deonde/public/images/',
        bannerpath: 'http://dev.deonde.co/deonde/public/media/Banner/thumb/',
        restauranticon: 'http://dev.deonde.co/deonde/public/media/Restaurant_Icon_image/thumb/',
        foodicon: 'http://dev.deonde.co/deonde/public/media/foodapp/original/',
        brandicon: 'http://dev.deonde.co/deonde/public/images/brands/',
        api_url: 'https://dev.deonde.co/deonde/api/',
        category: 'https://dev.deonde.co/deonde/public/media/Category/original/',
        coupon: 'https://dev.deonde.co/deonde/public/media/Coupon/',
        driver: 'https://dev.deonde.co/deonde/public/media/Driver/Profile/original/',
        cuisineicon: 'https://dev.deonde.co/deonde/public/media/cuisine_image/original/',
        driver_document: 'https://dev.deonde.co/deonde/public/media/Driver/Document/thumb/',
        restaurantmenu: 'https://dev.deonde.co/deonde/public/media/Restaurant_Menu_image/',
}