
let instance;

import { error_message, remove_partner_token, remove_token, set_axios_defaults,set_partner_token, set_partner_login,set_token, get_token, get_partner_token, success_message } from '../Helper/helper';
import config from './../config'
import { authService, chatService, userService } from './../services';
export const useAuth = (options = {}) => {
  if (instance) return instance;
  instance = new Vue({
    data() {
      return {
        user: null,
        setting: null,
        partner_login: true,
        languages: [],
        orders: [],
        logging_done: false,
        unreadMessages: [],
        received: 0,
        booking_count: 0,
        bookings: [],
        zomato_order_count:0
      }
    },
    methods: {
      login(request, callback) {
        authService.loginUser(request).then((response) => {
          let token = response.data.result.token;
          /* remove_get_user();
          remove_get_user_detail();
          remove_partner_login();
          remove_partner();
          remove_partner_token();
          remove_partner_details(); */
          if (this.partner_login) {
            set_partner_login();
          }
          if (token) {
            this.user = response.data.result.user;
            this.setting = response.data.result.userDetail;
            this.logging_done = true;
            if(response.data.result.languages){
              this.setLanguages(response.data.result.languages)
            }
            if (this.partner_login) {
              set_partner_token(token)
            } else {
              set_token(token)
            }
            set_axios_defaults(token)
            // var permission = this.user.access.find(per => per.includes('Read'));
            // menuItems.reduce((a, item) => item)
            // menuItems.find(per => per.subItems.filter(p => p.required_permission.includes('Read')))
            callback(this.partner_login ? 'vendor.dashboard' : 'dashboard')
          }
          else {
            error_message(response.data.message);
          }
        });
      },
      logout(callback) {
        authService.logout()
          .then(res => {
            this.logging_done = false;
            if (this.partner_login) {
              remove_partner_token();
              callback('vendor.login')
            } else {
              remove_token();
              callback('login')
            }
          });
      },
      setUser(callback,token = "") {
        if(token == ""){
          this.checkLoginType()
        }
        if(this.checkLoginType()){
          this.logging_done = true;
          userService.getUserDetail()
            .then(response => {
              this.user = response.data.users;
              this.setting = response.data.userDetail;
              if(response.data.languages){
                this.setLanguages(response.data.languages)
              }
              callback(this.partner_login?'vendor.dashboard':'dashboard')
            })
        }else{
          this.logging_done = false;
          callback(this.partner_login?'vendor.login':'login')
        }
      },
      checkLoginType() {
        let path = window.location.href;
        let token = get_token();
        let ptoken = get_partner_token();

        let type = path.replace(config.appurl, '').split('/')[0];
        this.partner_login = false;
        if (type == 'vendor') {
          set_axios_defaults(ptoken)
          this.partner_login = true;
          return !!ptoken
        }else{
          set_axios_defaults(token)
          return !!token
        }
        // return !!(token || ptoken);
      },
      updatePermissions(permissions){
        this.user = {
          ...this.user,
          access: permissions
        }
      },
      getVendorId(){
        return !this.user.parent_vendor_id?this.user.vendor_id:this.user.parent_vendor_id;
      },
      setLanguages(languages){
        this.langauges = languages;
      },
      getLangauge(abbr){
        return this.langauges.find(l => l.abbrivation == abbr);
      },
      updateOrders(orders){
        this.orders = orders
      },
      updateBookings(bookings){
        this.bookings = bookings;
        if(this.booking_count !== bookings.length){
          this.booking_count = bookings.length;
        }
      },
      updateZomatoCount(zomato){        
        this.zomato_order_count = zomato;
        // if(this.zomato_order_count !== zomato){
        //   this.zomato_order_count = zomato;
        // }        
      },
      hasPermission(permission){
        if(this.user.login_type == 'vendor')
        {
           return this.user && this.user.access.includes(permission);
        }
        else
        {
           return true;
        }
      },
      hasSubAdminPermission(permission){
        if(this.user.is_admin_user == 0)
        {
           return this.user && this.user.access.includes(permission);
        }
        else
        {
           return true;
        }
      },
      getDriverName(){
        return this.setting && this.setting.driver_name || 'Driver';
      },
      getRestaurantName(){
        return this.setting && this.setting.restaurant_name || 'Restaurant';
      },
      getCuisineName(){
        return this.setting && this.setting.cuisine_name || 'Cuisine';
      },
      updateSetting(setting){
        this.setting = setting;
      },
      getTaxName(){
        return this.setting && this.setting.tax_name_primary_lang || 'Service tax';
      },
      getUnreadMessages(){
        chatService.getUnreadMessage()
          .then(response => {
            this.unreadMessages = response.data.result;
          })
      }
    }
  });
  return instance;

}

export default {
  install(Vue, options = {}) {
    Vue.prototype.$auth = useAuth(options);
  }
};