<script>
import Multiselect from 'vue-multiselect';
import DateRangePicker from "vue2-daterange-picker";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DatePicker from "vue2-datepicker";
import { date_ranges, generate_csv, get_currency, get_decimal, toKebabCase } from '../../../Helper/helper';
import { driverService } from '../../../services';
import config from "../../config";
/**
 * Driver Earning
 */
export default {
    components: {  	Multiselect, DatePicker, DateRangePicker },
    props: ['driver_id'],
    data() {
        let startDate = this.moment().startOf('day').toDate();
        let endDate = this.moment().endOf('day').toDate();
        return {
            dateRange: { startDate, endDate },
            currency: get_currency(),
            config:config,
            decimal: get_decimal(),
            selected_date: this.moment().format('YYYY-MM-DD'),
            // months: Array.apply(0, Array(12)).map((_,i) => this.moment().month(i).format('MMMM')).reverse(),
            driverEarning: [],
            loading: false,
            fields:[
                { key: "hash_id", label: this.trans.get("__JSON__.Order ID"), sortable: false },
                { key: "date", label: this.trans.get("__JSON__.Date"), sortable: false },
                { key: "cod_amount", label: this.trans.get("__JSON__.Cash Collected"), sortable: false },
                { key: "driver_earning", label: this.trans.get("__JSON__.Driver Earning", {driver:this.$auth.getDriverName()}), sortable: false },
            ],
            date_ranges: date_ranges
        };
    },
    mounted(){
        this.getDriverOrderWiseEarning();
    },
    filters: {
        date(val) {
            return val ? val.toLocaleString() : "";
        },
    },
    methods: {
        getDriverOrderWiseEarning(){
            this.loading = true;
            driverService.getDriverOrderWiseEarning({
                driver_id: this.driver_id,
                start_date: this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                end_date: this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
            })
            .then(response => {
                this.loading = false;
                this.driverEarning = response.data.result;
                var total = {
                    date: "Total",
                    hash_id: response.data.result.length,
                    cod_amount: this.driverEarning.reduce((a, m) => (a + m.cod_amount),0),
                    driver_earning: this.driverEarning.reduce((a, m) => (a + parseFloat(m.driver_earning)),0),
                }
                this.driverEarning = [...this.driverEarning,total]
            })
        },
        downloadsCSV: function(){
            let vendorFinance = '\ufeff' + 'Date,Order ID,Cash Collected,'+this.$auth.getDriverName()+' Earning\n'
            this.driverEarning.map(el=> {
                var line = el['date'] + ',' 
                + el['hash_id'] + ',' 
                + parseFloat(el['cod_amount'] || 0).toFixed(this.decimal) + ',' 
                + parseFloat(el['driver_earning'] || 0).toFixed(this.decimal) + '\n';
                vendorFinance +=line;
            })
            generate_csv(vendorFinance,toKebabCase(this.$auth.getDriverName())+'-earning-'+this.selected_date+'.csv');
        },
        disabledDate(date){
            return (this.moment(date).format('YYYY') > this.moment().format('YYYY'))
        },
    },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
        <div class="justify-content-end mb-3 row">
            <div class="">
                <label for="">{{trans.get('__JSON__.Date')}}</label>
                <div class="text-sm-right">
                    <div class="mr-2 ">
                        <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="getDriverOrderWiseEarning"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                        </template>
                      </date-range-picker>
                        <!-- <date-picker v-model="selected_date"
                            value-type="format"
                            lang="en"
                            :disabledDate="disabledDate"
                            :clearable="false"
                            @change="getDriverOrderWiseEarning()"
                            confirm
                            :placeholder="trans.get('__JSON__.Select Date')"></date-picker> -->
                    </div>
                </div>
            </div>
            <div class="mt-4 pt-1 mr-3">
                <div class="text-sm-right">
                    <button @click="downloadsCSV" 
                    v-if="$auth.hasSubAdminPermission(config.permissions.driver_finance_export)"
                    class="btn btn-primary btn-rounded">
                    {{trans.get('__JSON__.Export To CSV')}}
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive table-border">
            <b-table
                :items="driverEarning || []"
                :fields="fields"
                :busy="loading"
                show-empty
                class="table-centered table-nowrap table-hover order-menu"
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                </template>
                <template #cell(date)="data">
                    <span class="font-weight-bold">{{data.value}}</span>
                </template>
                <template #cell(hash_id)="data">
                    <span class="font-weight-bold">{{data.value}}</span>
                </template>
                <template #cell(cod_amount)="data">
                    <span v-html="currency"></span> {{(data.value || 0).toFixed(decimal)}}
                </template>
                <template #cell(driver_earning)="data">
                    <span v-html="currency"></span> {{parseFloat(data.value || 0).toFixed(decimal)}}
                </template>
            </b-table>
        </div>
    </div>
</div>
</template>