<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { required } from "vuelidate/lib/validators";
import { settingService,deliveryZoneService } from "../../services";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { error_message, success_message} from "../../Helper/helper";
let map = null;
export default {
  components: { Layout, PageHeader, VueGoogleAutocomplete},
  props: ['user_id'],
  data() {
    return {
      center: { lat: 0.00, lng: 0.00 },
      markers: null,
      map:null,
      polygonArray:null,
      polyline: null,
      drawingManager: null,
      submitted:false,
      typeform: {
        location: "",
        area:"",
        zoom_level: 8,
        frenchiese_id: this.$route.params.frenchiese_id
      },
    };
  },
  mounted() {
    this.getSetting();
    this.initMap();
  },
  validations: {
    typeform: {
      area: {
        required,
      },
      location: {
        required,
      }
    }
  },
  methods: {
    getSetting(){
      settingService.getSetting()
      .then(response => {
        const cent = {
          lat: parseFloat(response.data.result.system_latitude),
          lng: parseFloat(response.data.result.system_longitude)
        };
        this.center = cent; 
        // this.markers.push({ position: cent });
        this.markers = new google.maps.Marker({
            position: new google.maps.LatLng(cent.lat,cent.long),
            title:"Restaurant"
        })
      });
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.typeform.location = placeResultData.formatted_address;
      this.typeform.latitude = addressData.latitude;
      this.typeform.longitude = addressData.longitude;
      this.markers && this.markers.setMap(null);
      const marker = {
        lat: addressData.latitude,
        lng: addressData.longitude
      };
      this.markers = new google.maps.Marker({
            position: new google.maps.LatLng(marker.lat,marker.lng),
            title:"Restaurant"
      })
      this.markers && this.markers.setMap(map);
      map.setCenter(marker)

      // this.markers.push({ position: marker });
      this.center = marker;
    },
    initMap(){
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
      });
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            //google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
          ],
        },
        polygonOptions: {
          fillColor: '#BCDCF9',
          fillOpacity: 0.5,
          strokeWeight: 2,
          strokeColor:'#57ACF9',
          clickable: false,
          editable: false,
          zIndex: 1
        } 
      });
      this.drawingManager.setMap(map);
      this.markers && this.markers.setMap(map);
      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
        
        if (event.type == 'polyline') {
          this.polyline = event.overlay;
          var locations = event.overlay.getPath().getArray()
          this.polygonArray = JSON.stringify(locations.reduce((a,i) => a.concat({lat:i.lat(),lng:i.lng()}),[]));
          this.drawingManager.setMap(null);
        }
      });
    },
    saveArea(event){
      event.target.disabled = true;
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }
      else{
        deliveryZoneService.addDeliveryZoneArea({area : this.typeform, corrdinates : this.polygonArray})
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: 'delivery-zone-details', params: { user_id: this.$route.params.frenchiese_id} });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    remove_polygon(){
      this.polyline.setMap(null);
      this.polygonArray = null,
      this.drawingManager.setMap(map);
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-map-alt"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Add Area') }}</h4>
              <p>            
                <router-link :to="{ name: 'list'}">{{ trans.get('__JSON__.Delivery Zones') }}</router-link>
                > <router-link :to="{ name: 'delivery-zone-details',params: {user_id: this.$route.params.frenchiese_id }}">{{ trans.get('__JSON__.Area') }}</router-link>
                > {{ trans.get('__JSON__.Add Area') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form action="#" @submit.prevent="saveArea">
          <!-- Area Info Start -->
          <div class="card">
            <div class="card-body">
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Name Of Area') }}</label>
                    <input
                      id="area"
                      type="text"
                      class="form-control"
                      v-model="$v.typeform.area.$model"
                      :placeholder="trans.get('__JSON__.Enter name of area')"
                      name="area"
                      :class="{'is-invalid':$v.typeform.area.$error}"
                    />
                    <div v-if="$v.typeform.area.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.area.required">{{ trans.get('__JSON__.Please enter name of area') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Location') }}</label>
                    <vue-google-autocomplete
                      ref="location"
                      id="location"
                      name="location"
                      type="text"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.Enter location')"
                      v-model="$v.typeform.location.$model"
                      v-on:placechanged="getAddressData"
                      types=""
                      :class="{'is-invalid':$v.typeform.location.$error}"
                    >
                    </vue-google-autocomplete>
                    <div v-if="$v.typeform.location.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.location.required">{{ trans.get('__JSON__.Please enter location') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 text-right">
                  <button type="button" class="btn btn-secondary ml-5" @click="remove_polygon">{{ trans.get('__JSON__.Clear Area') }}</button>
                </div>

                <div class="col-sm-12 mt-3">
                  <div id="map" style="height: 500px"> </div>
                  <input type="hidden" name="latlng" id="latlng" value="">
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <button
                    type="submit"
                    class="btn btn-primary mr-1 mt-3"
                    @click="saveArea($event)"
                    submitted = true
                    :disabled="submitted"
                  >{{ trans.get('__JSON__.Add Area') }}</button>
                  <router-link
                    :to="{name: 'delivery-zone-details',params: {user_id: this.$route.params.frenchiese_id }}"
                    type="submit"
                    class="btn btn-secondary mt-3"
                  >{{ trans.get('__JSON__.Cancel') }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Area Info End -->
        </b-form>
      </div>
    </div>
  </Layout>
</template>
