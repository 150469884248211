<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import config from "../../config";
import {
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
export default {
    components: { Layout, PageHeader, pagination, DateRangePicker },
    data() {
        let startDate = this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days") : this.moment().startOf('day').subtract(6, "days");
        let endDate = this.moment().endOf("day").toDate();
        return {
            decimal:get_decimal(),
            currency: get_currency(),
            dateRange: { startDate, endDate },
            reports: [],
            config:config,
            reportsData: [],
            paginations: {},
            countries: [],
            country: "0",
            states: [],
            state: "0",
            cities: [],
            city: "0",
            country_count: "",
            state_count: "",
            city_count: "",
            date_ranges: date_ranges,
            restaurant: [],
            restaurant_count: "",
            order_status: "",
            restaurants_id: "",
            limit: 2,
            perPage: 0,
            currentPage: 1,
            payment_method: "",
            filters: {
                start_date: this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD') : this.moment().startOf('day').subtract(6, "days").format('YYYY-MM-DD'),
                end_date: this.moment().format('YYYY-MM-DD'),
            },
            sortBy: "created_at",
            moment: moment,
            loading: true,
            exporting: false,
            fields: [
                { key: "index", label: "#", sortable: false},
                { key: "id", label: "Order Id", sortable: false},
                { key: "vendor_order_id", label: "Vendor Order Id", sortable: false},
                { key: "customer_name", label: "Customer Name", sortable: false},
                { key: "customer_number", label: "Customer Number", sortable: false},
                { key: "restarunt_name", label: "Restarunt Name", sortable: false},
                { key: "restarunt_number", label: "Restarunt Number", sortable: false},
                { key: "driver_name", label: "Feet Member Name", sortable: false},
                { key: "driver_number", label: "Feet Member Number", sortable: false},
                { key: "distance_km", label: "Distance km", sortable: false},
                { key: "total_amount", label: "Total Amount", sortable: true},
            ]
        }
    },
    computed: {
        computedFields() {
        // If the user isn't an admin, filter out fields that require auth.
        if(this.$auth.partner_login)
            return this.fields.filter(field => !field.requiresAdmin);
            
        // If the user IS an admin, return all fields.
        else 
            return this.fields;
        }
    },
    mounted() {
        this.getZomatoReport();
    },
    methods:{
        updateValues(date) {
            this.filters = {
                ...this.filters,
                start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
                end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD'),
            }
            this.getZomatoReport();
        },
        getZomatoReport(page){
            this.loading = true;
            reportService
            .getZomatoReport({
                ...this.filters,
                page: page ? page : 1,
            })
            .then((response)=>{
                this.loading = false;
                this.paginations = response.data.result;
                this.reports = response.data.result.data;
            });
        },
        downloadsCSV:function(){
            let reportsData = 
            '\ufeff' +
            '#,Order Id,Vendor Order Id,Customer Name,Customer Number,Restarunt Name,Restarunt Number,Feet Member Name, Feet Member Number , Distance km ,Total Amount\n';
            this.reportsData.forEach((el,index) => {
                var line = 
                (index+1) + 
                "," +(el["id"])+
                "," +(el["customer_order_id"])+
                "," +(el.fa_order_address["customer_name"])+
                "," +(el.fa_order_address["customer_contact_number"])+
                "," +(el.fa_order_address["restaurant_name"])+
                "," +(el.fa_order_address["restaurant_contact_number"])+
                "," +(el["driver"].length > 0 ? el["driver"][0].firstname + ' - ' + el["driver"][0].lastname: '-')+
                "," +(el["driver"].length > 0 ? el["driver"][0].contactno??'-' : '-')+
                "," +(el["distance_km"] != '' ? el["distance_km"] : '-')+
                "," +(el["total_amount"]);
                line+="\n";
                reportsData += line;
            });
            var blob = new Blob([reportsData], { type: "csv/plain" });
            var date = new Date().toLocaleString();
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "zomato_" + date + ".csv";
            link.click();
        },
        zomatoExportCsv(){
            this.exporting = true;
            reportService
            .exportZomatoToCsv({ 
                ...this.filters,
            })
            .then((response)=>{
                this.exporting = false;
                this.reportsData = response.data.result;
                this.downloadsCSV();
            });
        },
        dateFormat(classes, date) {
            this.filters = {
                ...this.filters,
                start_date: this.moment(date.startDate.toISOString()).format(
                "YYYY-MM-DD"
                ),
                end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD")
            };
        }
    },
    filters: {
      date(val) {
        return val ? val.toLocaleString() : "";
      },
    },
}
</script>

<template>
    <Layout>
        <div>
            <div class="row">
                <div class="col-12">
                    <div
                        class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
                    >
                        <div class="page-title-left avatar-main-icon">
                            <div class="avatar-xs mr-3">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                    <i class="bx bx-purchase-tag"></i>
                                </span>
                            </div> 
                            <div class="report-tag" v-if="!$auth.partner_login">
                                <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Zomato Report') }}</h4>
                                <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Zomato Report') }}</p>
                            </div>
                            <div class="report-tag" v-else>
                                <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Sales Report') }}</h4>
                                <p><router-link :to="{ name: 'vendor.reports.list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Zomato Report') }}</p>
                            </div>
                        </div>
                        <div class="page-title-right">
                            <button @click="zomatoExportCsv" v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)" class="btn btn-primary btn-rounded" :disabled="exporting">{{ trans.get(!exporting?'__JSON__.Export To CSV':'__JSON__.Exporting') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- table content with filter -->
            <div class="row">
                <div class="col-12 list-table-main">
                    <div class="card">
                        <div class="card-body">
                            <div class="row justify-content-between" v-if="!$auth.partner_login">
                                <div class="col-md-12">
                                    <div class="sales-report float-lg-right" v-if="!$auth.partner_login">
                                        <div class="form-group">
                                            <div class="date-range-list">
                                            <label>{{ trans.get('__JSON__.Date') }} :</label>
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="{
                                                firstDay: 1,
                                                format: 'dd-mm-yyyy',
                                                }"
                                                :showDropdowns="true"
                                                :showWeekNumbers="true"
                                                opens="left"
                                                v-model="dateRange"
                                                :ranges="date_ranges()"
                                                @update="updateValues"
                                            >
                                                <template
                                                v-slot:input="picker"
                                                style="min-width: 350px"
                                                >
                                                {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                                                {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                                                </template>
                                            </date-range-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- table content -->
                                <div class="row table-responsive">
                                    <div class="col-12 table-main-list table table-centered table-nowrap sales-report-table">
                                        <b-table
                                            :items="reports"
                                            :fields="computedFields"
                                            show-empty
                                            :busy="loading"
                                            show-empty
                                        >
                                            <template #table-busy>
                                                <div class="text-center text-danger my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                                                </div>
                                            </template>

                                            <template v-slot:cell(index)="data">
                                                <div>{{ data.index + 1 }}</div>
                                            </template>

                                            <template v-slot:cell(customer_name)="data">
                                                <span>{{ data.item.fa_order_address.customer_name }}</span>
                                            </template>

                                            <template v-slot:cell(vendor_order_id)="data">
                                                <span>{{ (data.item.customer_order_id != null && data.item.customer_order_id != '') ? data.item.customer_order_id : data.item.vendor_order_id }}</span>
                                            </template>

                                            <template v-slot:cell(customer_number)="data">
                                                <span>{{ data.item.fa_order_address.customer_contact_number }}</span>
                                            </template>

                                            <template v-slot:cell(restarunt_name)="data">
                                                <span>{{ data.item.fa_order_address.restaurant_name }}</span>
                                            </template>

                                            <template v-slot:cell(restarunt_number)="data">
                                                <span>{{ data.item.fa_order_address.restaurant_contact_number }}</span>
                                            </template>

                                            <template v-slot:cell(driver_name)="data">
                                                <template v-if="data.item.driver.length > 0">
                                                    <span>{{ data.item.driver[0].firstname + '-' + data.item.driver[0].lastname }}</span>
                                                </template>
                                                <template v-else>
                                                    <span>-</span>
                                                </template>
                                            </template>

                                            <template v-slot:cell(driver_number)="data">
                                                <template v-if="data.item.driver.length > 0">
                                                    <span>{{ data.item.driver[0].contactno }}</span>
                                                </template>
                                                <template v-else>
                                                    <span>-</span>
                                                </template>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                                <!-- table content -->

                                <!-- pagination -->
                                <div class="row col-sm-5 offset-sm-7">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <pagination
                                            :data="paginations"
                                            :limit="limit"
                                            @pagination-change-page="getZomatoReport"
                                            ></pagination>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- pagination -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- table content with filter -->
        </div>
    </Layout>
</template>