<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CustomerProfile from "./customer-profile";
// import CustomerOrder from "./customer-order";
import { customerService, orderService } from "../../services";
import { debounce, order_statuses } from '../../Helper/helper';
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "../orders/order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DriverOrder from '../../views/driver/pages/driver-order';
import WalletHistory from './wallet/history';
import CustomerNote from './customer-note.vue';
/**
 * Products-order component
 */
export default {
  props:['user_id'],
  components: { Layout, PageHeader, CustomerProfile, OrderList , DateRangePicker, pagination,DriverOrder,WalletHistory, CustomerNote},
  data() {
    
    return {
      customers: [],
      statuses: order_statuses,
      loading: false,
      debounce,
    };
  },
  mounted() {
    this.getCustomerProfile();
  },
  methods: {
    getCustomerProfile() {
      customerService
        .getCustomerProfile({
          user_id: this.$route.params.user_id,
        })
        .then((response) => {
          this.customers = response.data.result;
          this.user_id = this.$route.params.user_id;
        });
    },
  },
   filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user-circle"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ customers.user_name }} {{ customers.last_name }}</h4>
              <p>
                <router-link :to="{ name: 'customer-list'}">{{ trans.get('__JSON__.Customer') }}</router-link>
                > {{ customers.user_name }} {{ customers.last_name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
              <b-tabs
                nav-class="nav-tabs-custom"
                content-class="p-3 text-muted"
                lazy
              >
                <b-tab :title="trans.get('__JSON__.Profile')">
                  <b-card-text><CustomerProfile/></b-card-text>
                </b-tab>
                <b-tab :button-id="statuses.delivered" >
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Delivered")
                    }}</span>
                  </template>
                  <!-- code now -->
                  <driver-order :customer_id="$route.params.user_id" :status="statuses.delivered" />
                </b-tab>
                <b-tab :button-id="statuses.cancelled" >
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Cancelled")
                    }}</span>
                  </template>
                  <!-- code now -->
                  <driver-order :customer_id="$route.params.user_id" :status="statuses.cancelled" />
                </b-tab>
                <b-tab >
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Wallet History")
                    }}</span>
                  </template>
                  <!-- code now -->
                  <WalletHistory :customer_id="$route.params.user_id" :wallet_status="customers.wallet_status" />
                </b-tab>
                <b-tab >
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Notes")
                    }}</span>
                  </template>
                  <!-- code now -->
                  <customer-note :customer_id="$route.params.user_id"  />
                </b-tab>
              </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
