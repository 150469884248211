
// Dashboard services rest api calling

import { routes } from '../config/api_routes'

export const dashboardService = {
    // get dashboard 

    getDashboard : () => {    
        return window.axios.get(routes.getDashboardApi)
    },

    getStatistics: () => window.axios.get(routes.getStatisticsApi)
}