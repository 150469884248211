<script>
import Layout from "../../layouts/main";
import config from "../../config";
import PageHeader from "../../components/page-header";;
import pagination from "laravel-vue-pagination";
import Multiselect from 'vue-multiselect'
import { error_message,success_message } from "../../Helper/helper";
import { customizationService, settingService, userService } from "../../services";

import { required} from "vuelidate/lib/validators";

/**
 * Add-product component
 */
export default {
    components: {pagination,Layout, PageHeader, Multiselect},
    data() {
        return {
            config: config,
            customizations: [],
            menu_customizations: [],
            cust_type_search: [],
            templates: [],
            item_detail: [],
            addModal: false,
            editModal: false,
            templateModal: false,
            selectModal: false,
            sortDesc: true,
            paginations: {},
            limit: 1,
            search: "",
            isLoading: false,
            status: 1,
            page: 1,
            error: "",
            customize_id: "",
            parent_item_id: "",
            restaurant_id: "",
            menu_item_id: "",
        };
    },
    validations: {
        typeform: {
            type_name: {
                required,
            },
        },
        custType: {
            type_name: {
                required,
            },
        },
        template:{
            template_name: {
                required,
            }
        },
        selectTem:{
            cust_type_search: {
                required,
            }
        }
    },
    mounted() {
        this.getSubCustomization();
    },
    methods: {
        getSubCustomization() {
            customizationService.getSubCustomization({menu_item_selection_id : this.$route.params.menu_item_selection_id})
                .then((response) => {
                    if(response.data.result.item.length > 0){
                        this.customizations = response.data.result.item;
                    }else{
                        this.customizations = [{ 
                            selection_name: '',
                            selection_name_thai:'',
                            selection_price:'',
                            is_default:'',
                            status:'',
                            menu_item_selection_id:'',
                            customize_type_id: response.data.result.item_detail.customize_type_id,
                            parent_item_id: response.data.result.parent_item_id
                        }]
                    }
                    this.item_detail = response.data.result.item_detail;
                    this.parent_item_id = response.data.result.parent_item_id;
                    this.restaurant_id = response.data.result.restaurant_id;
                    console.log(this.restaurant_id);
                    this.menu_item_id = response.data.result.menu_item_id;
            });
        },
        removeSlot(id,index){
            if(id){
                customizationService.deleteitemCustmization({menu_item_selection_id:id})
                    .then(response => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                        this.getSubCustomization();
                    } else {
                        error_message(response.data.message);
                    }
                });
            }else{
                this.customizations.splice(index,1);  
            }
            
        },
        addSlot: function () {
            this.customizations.push({ 
               selection_name: '',
               selection_name_thai:'',
               selection_price:'',
               is_default:'',
               status:'',
               customize_type_id: this.item_detail.customize_type_id,
               parent_item_id: this.parent_item_id
            });
            
        },
        checkItem(event,index){
            if(event.target.checked){
                console.log(event.target.checked);
                this.customizations.forEach((element,i) => {
                    this.customizations[i].is_default = 0;
                });
                this.customizations[index].is_default = 1;
            }else{
                this.customizations[index].is_default = 0;
            }
        },
        
        saveCustomisation(id){   
            var customization = this.customizations///.filter(c => c.customize_type_id == id); 
            if(customization){
               
                var menu_customization = customization
                .reduce((a, r) => r.selection_name == ''?a:a.concat({
                    customize_type_id:  r.customize_type_id,
                    is_default: r.is_default,
                    is_min_selection:  r.is_min_selection,
                    is_selection: r.is_selection,
                    menu_item_selection_id: r.menu_item_selection_id,
                    parent_item_id: r.parent_item_id,
                    selection_name: r.selection_name,
                    selection_name_thai: r.selection_name_thai,
                    selection_price: r.selection_price,
                    status: r.status,
                }),[]);
                if(menu_customization.length > 0){
                    customizationService.addSubMenuSelection({menu_customization:menu_customization})
                        .then(response => {
                        if (response.data.code === 200) {
                            success_message(response.data.message);
                            this.getSubCustomization();
                        } else {
                            error_message(response.data.message);
                        }
                    });
                }else{
                    error_message('Please Enter Deatils');
                }
            }
        },

        minSelection(id){
            var min = this.item_detail.is_selection;
            var max = this.item_detail.is_min_selection;
            if(parseInt(min) > parseInt(max)){
                error_message('necessary item selection should be less than or equal to max item selection.');
            }else{
                customizationService.minSelection({menu_item_selection_id:id, is_selection:min})
                    .then(response => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                });
            }
        },
        maxSelection(id){
            var max = this.item_detail.is_min_selection;
            customizationService.maxSelection({menu_item_selection_id:id, is_min_selection:max})
                .then(response => {
                if (response.data.code === 200) {
                    success_message(response.data.message);
                } else {
                    error_message(response.data.message);
                }
            });
        },
        floating(e,index){   
            const floating = /^[0-9.]\d*(\.\d{1,2})?$/;
            this.customizations.forEach((element,i) => {
                let char = String.fromCharCode(e.keyCode);
                if (floating.test(char)) return true;
                else e.preventDefault();
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page" >
                    <div class="page-title-left avatar-main-icon">
                        <div class="avatar-xs mr-3">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i class="bx bx-store"></i>
                            </span>
                        </div>
                        <div class="report-tag" v-if="!$auth.partner_login">
                            <h4 class="mb-0 font-size-18">{{item_detail.selection_name}}</h4>
                            <!-- <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > <router-link :to="{name: 'vendor-add-customization',params: { menu_item_id:  base64(this.menu_item_id)}}">{{ trans.trans('__JSON__.Customization') }}</router-link> > {{item_detail.selection_name}}</p> -->
                            <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > <router-link :to="{name: 'vendor-add-customization',params: { menu_item_id:  base64(this.menu_item_id)}}">{{ trans.trans('__JSON__.Customization') }}</router-link> > {{ trans.trans('__JSON__.Sub Customization') }}</p>
                        </div>
                         <div v-else>
                            <h4 class="mb-0 font-size-18">{{item_detail.selection_name}}</h4>
                            <!-- <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > <router-link :to="{name: 'vendor-add-customization',params: { menu_item_id:  base64(this.menu_item_id)}}">{{ trans.trans('__JSON__.Customization') }}</router-link> > {{item_detail.selection_name}}</p> -->
                            <p><router-link :to="{name: 'vendor.vendor.catalog'}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > <router-link :to="{name: 'vendor.vendor.add.customization',params: { menu_item_id:  base64(this.menu_item_id)}}">{{ trans.trans('__JSON__.Customization') }}</router-link> > {{ trans.trans('__JSON__.Sub Customization') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="drag-container">
            <div class="row drag-list menu-left-main">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body menu-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Customization') }}({{customizations.length}})</h4>
                                        </div>
                                    </div>
                                </div>
                                <!-- end row -->
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div role="tablist">
                                                    <b-card no-body class="mb-1 shadow-none">
                                                            <b-card-header header-tag="header" role="tab">
                                                                <div class="col-12">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <a
                                                                            v-b-toggle="'accordion-' + item_detail.menu_item_selection_id"
                                                                            class="text-dark"
                                                                            href="javascript: void(0);"
                                                                            >
                                                                            <h6 class="pt-1" style="margin: 0px 0px 25px;">
                                                                                {{item_detail.selection_name}} - <span v-html="$auth.setting && $auth.setting.currency"></span> {{item_detail.selection_price}}
                                                                            </h6>
                                                                        </a>  
                                                                        <div class="page-title-right">
                                                                            <div class="form-group">
                                                                                <label for="">Max Item Selection :</label>
                                                                                <select @change="maxSelection(item_detail.menu_item_selection_id)" id="is_min_selection" style="width: 80px;" name="is_min_selection" v-model="item_detail.is_min_selection">
                                                                                    <option v-for="(x,i) in (Array(21))" :key="i" :value="i" :disabled="i<item_detail.is_selection">
                                                                                        {{i}}
                                                                                    </option>
                                                                                </select>
                                                                                <label for="">Necessary Item Selection :</label>
                                                                                <select @change="minSelection(item_detail.menu_item_selection_id)" id="is_selection" style="width: 80px;" name="is_selection" v-model="item_detail.is_selection">
                                                                                    <option v-for="(x,i) in (Array(21))" :key="i" :value="i" :disabled="i>item_detail.is_min_selection">
                                                                                        {{i}}
                                                                                    </option>
                                                                                </select>
                                                        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-header>
                                                        
                                                        <b-collapse v-if="customizations.length > 0" class="ctmz-accordion" :id="'accordion-' + item_detail.menu_item_selection_id" visible accordion="my-accordion" role="tabpanel">
                                                            <b-card-body v-if="customizations.length > 0">
                                                                <form @submit.prevent="saveCustomisation">
                                                                    <div class="table-responsive ">
                                                                        <table class="table mb-0 addcust-tbl">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th> Item Name </th>
                                                                                    <th v-if="$auth.langauges[1].name != 'No Secondary Language'"> Item Name Other Langauges </th>
                                                                                    <th> Price (<span v-html="$auth.setting && $auth.setting.currency"></span>)</th>
                                                                                    <th> Default </th>
                                                                                    <th> Active </th>
                                                                                </tr>
                                                                            </thead>
                                                                            
                                                                            <tbody v-for="(menucustomization,index) in customizations" :key="menucustomization.menu_item_selection_id">
                                                                                <tr>
                                                                                    <td><input type="text" class="form-control" v-model="menucustomization.selection_name"></td>
                                                                                    <td v-if="$auth.langauges[1].name != 'No Secondary Language'"><input type="text" class="form-control" v-model="menucustomization.selection_name_thai"></td>
                                                                                    <td><input type="text" class="form-control" v-model="menucustomization.selection_price" v-on:keypress="floating($event,index)"></td>
                                                                                    <td><input type="checkbox" class="custom-checkbox" @change="checkItem($event,index)" :checked="parseInt(menucustomization.is_default)" id="is_default" true-value="1" false-value="0"/></td>
                                                                                    <td class="vendor-status vendr-switch"><label class="switch"><input type="checkbox" id="togBtn" name="status" v-model="menucustomization.status" class="switch-on" true-value="1" false-value="0"/>
                                                                                        <div class="slider round">
                                                                                            <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                                                            <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                                                        </div>
                                                                                    </label>
                                                                                    </td>
                                                                                    <td style="display:none"><input type="hidden" class="form-control" :value="menucustomization.parent_item_id"></td>
                                                                                    <td><button class="btn btn-primary btn-sm btn-rounded" @click.prevent="addSlot()">+</button></td>
                                                                                    <td><button class="btn btn-danger btn-sm btn-rounded" @click.prevent="removeSlot(menucustomization.menu_item_selection_id,index)" v-if="index!='0' || index!=''">-</button></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </form>
                                                            </b-card-body>
                                                            <button class="btn btn-primary cust" @click=saveCustomisation(item_detail.customize_type_id)>{{ trans.get('__JSON__.Save') }}</button>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end row -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  <!-- end row -->
</template>
