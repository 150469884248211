<template>
  <div>
    <b-dropdown
      lazy
      id="dropdown-1"
      variant="link"
      right
      role="navigation"
      class="m-md-2"
      v-if="$auth.hasPermission(config.permissions.order_write)"
    >
      <template #button-content>
        <i class="bx bx-dots-vertical-rounded"></i>
      </template>
      <b-dropdown-item @click="viewDetails(order.base_id)">{{
        trans.get("__JSON__.View")
      }}</b-dropdown-item>
      <b-dropdown-item
        v-if="order.order_status == statuses.placed && !$auth.partner_login"
        @click="changeStatus(order.base_id, statuses.inkitchen)"
        >{{ trans.get("__JSON__.Confirm Order") }}</b-dropdown-item
      >
      <b-dropdown-item
        v-if="order.order_status == statuses.placed && order.petpooja_orderid == null"
        @click="petpoojaSync(order.id)"
        >{{ trans.get("__JSON__.Petpooja Sync") }}</b-dropdown-item
      >
      <b-dropdown-item
        v-if="order.order_status == statuses.inkitchen"
        @click="changeStatus(order.base_id, statuses.readytoserve)"
        >{{ trans.get("__JSON__.Ready To Delivery Order") }}</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          [statuses.readytoserve, statuses.inkitchen].includes(order.order_status) &&
          order.pidge_enable === '1' &&
          order.pidge_driver == '0' &&
          order.driver.length == 0
        "
        @click="sendToPidge(order.base_id)"
        ><span class="text-info">{{
          trans.get("__JSON__.Send To Pidge")
        }}</span></b-dropdown-item
      >
      <!-- <b-dropdown-item
          v-if="
            [statuses.readytoserve, statuses.inkitchen].includes(order.order_status) &&
            order.pidge_enable === '1' &&
            order.pidge_driver == '0' &&
            order.driver.length == 0
          "
          @click="scheduleToPidge(order.base_id, statuses.readytoserve)"
          ><span class="text-primary">{{
            trans.get("__JSON__.Schedule To Pidge")
          }}</span></b-dropdown-item
        > -->
      <b-dropdown-item
        v-if="
          [statuses.readytoserve, statuses.inkitchen].includes(order.order_status) &&
          order.dunzo_enable === '1' &&
          order.dunzo_driver == '0' &&
          order.driver.length == 0
        "
        @click="sendToDunzo(order.base_id)"
        ><span class="text-primary">{{
          trans.get("__JSON__.Send To Dunzo")
        }}</span></b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          [statuses.readytoserve, statuses.inkitchen].includes(order.order_status) &&
          order.dunzo_enable === '1' &&
          order.dunzo_driver == '0' &&
          order.driver.length == 0
        "
        @click="scheduleToDunzo(order.base_id, statuses.readytoserve)"
        ><span class="text-primary">{{
          trans.get("__JSON__.Schedule To Dunzo")
        }}</span></b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          [
            statuses.placed,
            statuses.inkitchen,
            statuses.readytoserve,
            statuses.ontheway,
            statuses.scheduled,
            statuses.delivered,
          ].includes(order.order_status)
        "
        @click="addNotePopup(order.base_id)"
        ><span>{{ trans.get("__JSON__.Add Note") }}</span></b-dropdown-item
      >
      <template v-if="!$auth.partner_login">
        <b-dropdown-item
          v-if="
            [
              statuses.inkitchen,
              statuses.readytoserve,
              statuses.ontheway,
              statuses.scheduled,
              statuses.arrived,
              statuses.delivered,
              statuses.cancelled,
            ].includes(order.order_status) &&
            order.is_no_response_email == 0 &&
            not_response_btn == true
          "
          @click="notRespondingCustomer(order.base_id)"
          >{{ trans.get("__JSON__.Not Responding Customer") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="
            [
              statuses.inkitchen,
              statuses.readytoserve,
              statuses.ontheway,
              statuses.scheduled,
              statuses.arrived,
              statuses.delivered,
              statuses.cancelled,
            ].includes(order.order_status) &&
            order.is_delay_email == 0 &&
            delay_order_btn == true
          "
          @click="delayOrderCustomer(order.base_id)"
          >{{ trans.get("__JSON__.Delay/Complain Email To Customer") }}</b-dropdown-item
        >
        <b-dropdown-divider
          v-if="
            [
              statuses.placed,
              statuses.inkitchen,
              statuses.readytoserve,
              statuses.ontheway,
              statuses.scheduled,
              statuses.delivered,
            ].includes(order.order_status)
          "
        ></b-dropdown-divider>
        <b-dropdown-item
          v-if="
            [
              statuses.placed,
              statuses.inkitchen,
              statuses.readytoserve,
              statuses.ontheway,
              statuses.scheduled,
              statuses.delivered,
            ].includes(order.order_status)
          "
          @click="cancelOrderPopup(order.base_id, statuses.cancelled)"
          ><span class="text-danger">{{
            trans.get("__JSON__.Cancel Order")
          }}</span></b-dropdown-item
        >
        <b-dropdown-divider
          v-if="
            [statuses.readytoserve, statuses.ontheway, statuses.arrived].includes(
              order.order_status
            )
          "
        ></b-dropdown-divider>
        <b-dropdown-item
          v-if="
            [statuses.readytoserve, statuses.ontheway, statuses.arrived].includes(
              order.order_status
            )
          "
          @click="changeStatusDelivered(order.base_id, statuses.delivered)"
          ><span class="text-success">{{
            trans.get("__JSON__.Complete Order")
          }}</span></b-dropdown-item
        >
      </template>
    </b-dropdown>
    <i
      v-else
      v-b-tooltip="'Need Permission'"
      class="mdi mdi-information h4 text-danger"
    ></i>
    <OrderShow
      v-if="showModal"
      :order-id="order.base_id"
      @hidden="showModal = false"
      :thirdPartyOrder="thirdPartyOrder"
    />
    <DelayOrderShow
      v-if="delayOrderShow"
      :order_id="order.id"
      @hidden="delayOrderShow = false"
    />
    <!-- <b-modal
          id="modal-sm"
          size="lg"
          title-class="font-18"
          @hidden="resetModal"
          @ok="cancelOrder"
          :ok-title="trans.get('__JSON__.Cancel Order')"
          ok-only
          ok-variant="danger"
          ref="cancel-modal"
          lazy
          >
          <template #modal-header={close}>
              <div class="d-flex justify-content-between w-100">
                  <h4 class="mb-0">{{trans.get('__JSON__.Cancel Order')}}</h4>
                  <h4 class="mb-0">{{order.order_id}}</h4>
              </div>
              <button type="button" aria-label="Close" class="close" @click="close()">&times;</button>
          </template>
  
          <div class="row">
              <div class="col-12">
                  <b-form role="form">
                      <div class="row">
                          <div class="col" v-for="(reason, index) in cancel_reasons" :key="index">
                              <a href="#" @click="onReasonSelect(reason)">
                              <b-card header-class="bg-transparent border-primary" class="border border-primary"
                                  :bg-variant="other_selected && reason == 'Other' || reason == cancelled_desc ? 'primary' : 'default'"
                                   body-class="d-flex align-items-center justify-content-center height-100">
                                  <h5 class="card-title mt-0" :class="{'text-white': (other_selected && reason == 'Other' || reason == cancelled_desc)}">{{reason}}</h5>
                              </b-card>
                              </a>
                          </div>
                      </div>
                      <b-form-group label="Cancel Description" v-if="other_selected">
                           <b-form-textarea
                              id="textarea"
                              v-model="cancelled_desc"
                              placeholder="Enter something..."
                              rows="3"
                              max-rows="6"
                              ></b-form-textarea>
                      </b-form-group>
                      <b-form-group v-if="!$auth.partner_login">
                           <b-form-checkbox
                              id="checkbox-1"
                              switch
                              v-model="is_refundable"
                              class="text-right"
                              name="checkbox-1"
                              size="lg"
                              value="1"
                              unchecked-value="0"
                          >
                          Is Refundable
                          </b-form-checkbox>
                      </b-form-group>
                  </b-form>
              </div>
          </div>
      </b-modal> -->
  </div>
</template>

<script>
import OrderShow from "./order-show";
import DelayOrderShow from "./delay-order-show";
import { order_statuses, success_message } from "./../../../Helper/helper";
import config from "../../../config";
import { orderService } from "../../../services";

export default {
  // props: [ 'order','not_response_btn','delay_order_btn'],
  props: {
    order: {
      type: Object,
    },
    not_response_btn: {
      type: Boolean,
    },
    delay_order_btn: {
      type: Boolean,
    },
    thirdPartyOrder: {
      type: Boolean,
      default: false,
    },
  },
  components: { OrderShow, DelayOrderShow },
  data() {
    return {
      config: config,
      showModal: false,
      delayOrderShow: false,
      statuses: order_statuses,
      cancelled_desc: "",
      is_refundable: false,
      showPopup: false,
      order_id: null,

      cancel_reasons: [
        "Double Order",
        "Promocode not Applied",
        "Order Delayed",
        "Not Responding",
        // 'Too Busy',
        // 'Out of Delivery Area',
        // 'Out of Stock',
        "Other",
      ],
      other_selected: false,
      dunzo_schedule: null,
      dunzo: {
        schedule_time: 0,
      },
    };
  },
  methods: {
    viewDetails(id) {
      this.showModal = true;
      this.orderLoading = true;
      this.orderId = id;
    },
    notRespondingCustomer(id) {
      if (window.confirm("Are you sure want to Send Email?")) {
        const data = { order_id: atob(id), vendor_id: this.$auth.getVendorId() };
        orderService.notRespondingCustomer(data).then((response) => {
          if (response.data.code == 200) {
            this.$toasted.success(response.data.msg);
          } else {
            this.$toasted.error(response.data.msg);
          }
          window.order_count_refresh = true;
          this.$emit("actionChanged");
        });
        return;
      }
    },
    delayOrderCustomer(id) {
      this.delayOrderShow = true;
      this.orderId = id;
      // if(window.confirm("Are you sure want to Send Email?")){
      // const data = {order_id:atob(id),vendor_id:this.$auth.getVendorId(),type:'manual'};
      // orderService.delayOrderCustomer(data)
      //     .then(response => {
      //         if(response.data.code == 200){
      //             this.$toasted.success(response.data.msg);
      //         }else{
      //             this.$toasted.error(response.data.msg);
      //         }
      //         window.order_count_refresh = true;
      //         this.$emit('actionChanged');
      //     })
      // return;
      // }
    },
    addNotePopup(id) {
      this.order_id = id;
      this.$emit("actionClicked", { id, type: "note" });
    },
    petpoojaSync(id) {
      orderService.petpoojaSync({ id: id }).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.msg);
        } else {
          this.$toasted.error(response.data.msg);
        }
        //success_message("Petpooja Synced");
        this.$emit("actionChanged");
      });
    },
    changeStatus(id, status) {
      const data = {
        order_id: atob(id),
        status_name: status,
        vendor_id: this.$auth.getVendorId(),
      };
      orderService.changeOrderStatus(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.msg);
        } else {
          this.$toasted.error(response.data.msg);
        }
        window.order_count_refresh = true;
        this.$emit("actionChanged");
      });
    },
    changeStatusDelivered(id, status) {
      const data = {
        orderid: atob(id),
        order_status: status,
        vendor_id: this.$auth.getVendorId(),
      };
      orderService.changeOrderStatusDelivered(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.msg);
        } else {
          this.$toasted.error(response.data.msg);
        }
        window.order_count_refresh = true;
        this.$emit("actionChanged");
      });
    },
    ownerOrderStatus(id, status) {
      const data = {
        order_id: atob(id),
        status_name: status,
        vendor_id: this.$auth.getVendorId(),
      };
      orderService.ownerOrderStatus(data).then((response) => {
        if (response.data.code == 200) {
          this.$toasted.success(response.data.msg);
        } else {
          this.$toasted.error(response.data.msg);
        }
        window.order_count_refresh = true;
        this.$emit("actionChanged");
      });
    },
    resetModal() {
      // this.order_id = null;
      // this.is_refundable = false;
    },
    cancelOrderPopup(id, status) {
      // this.$refs['cancel-modal'].show();
      this.order_id = id;
      this.$emit("actionClicked", { id, status, type: "cancel" });
    },
    /* cancelOrder(bvModalEvt){
              bvModalEvt.preventDefault()
              if(this.cancelled_desc == ''){
                  this.$toasted.info('Please select cancel reason.');
                  return false;
              }
              const data = {orderId:atob(this.order_id),cancelled_desc:this.cancelled_desc, is_refundable:this.is_refundable};
              orderService.cancelOrder(data)
                  .then(response => {
                      if(response.data.code == 200){
                          this.$toasted.success(response.data.message);
                      }else{
                          this.$toasted.error(response.data.message);
                      }
                      window.order_count_refresh = true;
                      this.$emit('actionChanged');
                      this.$refs['cancel-modal'].hide();
                  })
          },
          onReasonSelect(reason){
              if(reason !== 'Other'){
                  this.cancelled_desc = reason;
                  this.other_selected = false;
              }else{
                  this.other_selected = true;
                  this.cancelled_desc = '';
              }
          }, */
    sendToDunzo(id) {
      if (confirm("Are sure you want to send order to dunzo?")) {
        const data = { order_id: atob(id), vendor_id: this.$auth.getVendorId() };
        orderService.sendToDunzo(data).then((response) => {
          if (response.data.code == 200) {
            this.$toasted.success(response.data.message);
            this.$emit("actionChanged");
          } else {
            this.$toasted.error(response.data.message);
          }
        });
      }
    },
    sendToPidge(id) {
      if (confirm("Are sure you want to send order to Pidge?")) {
        const data = { order_id: atob(id), vendor_id: this.$auth.getVendorId() };
        orderService.sendToPidge(data).then((response) => {
          if (response.data.code == 200) {
            this.$toasted.success(response.data.message);
            this.$emit("actionChanged");
          } else {
            this.$toasted.error(response.data.message);
          }
        });
      }
    },
    scheduleToDunzo(id, status) {
      this.$emit("actionClicked", { id, status, type: "dunzo" });
    },
    // scheduleToPidge(id, status) {
    //   this.$emit("actionClicked", { id, status, type: "pidge" });
    // },
  },
};
</script>
<style scoped>
.height-100 {
  height: 100px;
}
</style>
