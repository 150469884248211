<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import config from "../../config";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days") : this.moment().startOf('day').subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal:get_decimal(),
      currency: get_currency(),
      dateRange: { startDate, endDate },
      reports: [],
      config:config,
      reportsData: [],
      paginations: {},
       countries: [],
      country: "0",
      states: [],
      state: "0",
      cities: [],
      city: "0",
      country_count: "",
      state_count: "",
      city_count: "",
      date_ranges: date_ranges,
      restaurant: [],
      restaurant_count: "",
      order_status: "",
      restaurants_id: "",
      limit: 2,
      perPage: 0,
      currentPage: 1,
      payment_method: "",
      filters: {
        start_date: this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD') : this.moment().startOf('day').subtract(6, "days").format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      sortBy: "created_at",
      moment: moment,
      loading: true,
      exporting: false,
      fields: [
        { key: "table_id", label: "#", sortable: false},
        { key: "user_name", label: "Customer Name", sortable: false},
        { key: "user", label: "Customer Number", sortable: false},
        { key: "id", label: "Order ID", sortable: true},
        { key: "sub_total", label: "Gross total(Item Total)", sortable: true},
        { key: "total_amount", label: "Net Total(Total after coupon and tax)", sortable: true},
        { key: "CGST", label: "CGST", sortable: true},
        { key: "SGST", label: "SGST", sortable: true},
        { key: "coupon_discount", label: "Coupon Charge", sortable: false},
        { key: "total_packaging_charge", label: "Container charge", sortable: true},
        { key: "wallet_amount", label: "Wallet", sortable: true},
        { key: "amount", label: "Online/COD", sortable: true},
        { key: "cashback", label: "Cashback", sortable: true},
        { key: "order_status", label: "Order Status", sortable: true},
        { key: "delivery_pickup_types", label: "Order Type", sortable: true},
        { key: "name", label: this.trans.get("__JSON__.Vendor's Name",{vendor: this.$auth.getRestaurantName()}), sortable: false},
        { key: "payment_method", label: "Payment mode", sortable: true},
        { key: "coupon_code", label: "Coupon Code Name", sortable: false},
        { key: "driver_name", label: "Delivery Boy name", sortable: false},
        { key: "delivery_time", label: "Delivery Time", sortable: true},
        { key: "created_at", label: "Placed Time", sortable: true},
        { key: "confirm_date", label: "Confirm time", sortable: true},
        { key: "readytoserve_date", label: "Picked up Time", sortable: true},
        { key: "ontheway_date", label: "On The Way Time", sortable: true},
        { key: "delivered_date", label: "Delivered Time", sortable: true},
        { key: "distance_km", label: "Distance KM", sortable: true},
        { key: "device_type", label: "Device Type", sortable: true},
        // { key: "created_date", label: "Order Date", sortable: false},
        // { key: "user_name", label: "First Name", sortable: false},
        // { key: "name", label: "Vendor Name", sortable: false,requiresAdmin: true },
        // { key: "sub_total", label: "Item Price", sortable: true },
        // { key: "delivery_fee", label: "Delivery Charge", sortable: true},
        // { key: "coupon_discount", label: "Coupon Discount", sortable: false },
        // { key: "total_amount", label: "Total Amount", sortable: true},
        // { key: "admin_commision", label: "Admin Commission", sortable: true},
        // { key: "payment_method", label: "Payment Method", sortable: true },
        // { key: "order_status", label: "Order Status", sortable: true},
        // { key: "earning", label: "Your Earning", sortable: false }
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if(this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);
        
      // If the user IS an admin, return all fields.
      else 
        return this.fields;
    }
  },
  mounted() {
    // this.$data.fields[4].label = this.$auth.getRestaurantName() +' Name';
    // this.getSalesReports();
    // this.getRestaurants();
    this.getVendorCountry();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD'),
      }
      this.getSalesReports();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD")
      };
    },
    downloadsCSV: function () {
      if(this.$auth.partner_login)
      {
        let reportsData =
          '\ufeff' +
          '#,Customer Name,Customer Number,Order ID,Gross total(Item Total),Net Total(Total after coupon and tax),CGST,SGST,Coupon Charge,Container charge,Wallet,Online/COD,Cashback,Order Status,Order Type,Vendor Name,Payment mode,Coupon Code Name,Delivery Boy name,Delivery Time,Placed Time,Confirm time,Picked up Time,On The Way Time,Delivered Time,Distance KM,Device Type,Delayed By\n';
        this.reportsData.forEach((el,index) => {
          const coupon_code = '"' + el["coupon_code"] + '"';
          var line =
            (index+1) +
            "," +(el["user_name"])+
            "," +el["customer_mobile_number"] +
            "," +el["id"] +
            "," +el["sub_total"] +
            "," +el["total_amount"] +
            "," +el["CGST"] +
            "," +el["SGST"] +
            "," +(el["coupon_discount"] && parseFloat(el['coupon_discount']).toFixed(2) || '0.00')+
            "," +(el["total_packaging_charge"] && parseFloat(el['total_packaging_charge']).toFixed(2) || '0.00')+
            "," +(el["wallet_amount"] && parseFloat(el['wallet_amount']).toFixed(2) || '0.00')+
            "," +(el["amount"] && parseFloat(el['amount']).toFixed(2) || '0.00')+
            "," +(el["cashback"] && parseFloat(el['cashback']).toFixed(2) || '0.00')+
            "," + 
             (el["order_status"] == "Cancelled"
              ? "Cancelled"
              : "Delivered"
            ) +
            "," +el["delivery_pickup_types"] + 
            "," +el["name"] + 
            "," +(el["payment_method"] == "Cash On Delivery"
              ? "COD"
              : el["payment_method"]) + 
            "," +coupon_code +
            "," +el["driver_name"] +
            ","+(el['delivery_time'] && el['delivery_time'] != '0000-00-00 00:00:00' && this.moment(el['delivery_time']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['created_at'] && el['created_at'] != '0000-00-00 00:00:00' && this.moment(el['created_at']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['confirm_date'] && el['confirm_date'] != '0000-00-00 00:00:00' && this.moment(el['confirm_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['readytoserve_date'] && el['readytoserve_date'] != '0000-00-00 00:00:00' && this.moment(el['readytoserve_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['ontheway_date'] && el['ontheway_date'] != '0000-00-00 00:00:00' && this.moment(el['ontheway_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['delivered_date'] && el['delivered_date'] != '0000-00-00 00:00:00' && this.moment(el['delivered_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['distance_km'] && el['distance_km'] +'KM' || '-')+
            ","+(el['device_type'] && el['device_type'] || '-');
            if(el['eta'] && el['eta'] !== ''){
              if(el['order_status'] == 'ReadyToServe' || el['order_status'] == 'OnTheWay' || el['order_status'] == 'Arrived'){
                if(this.moment().diff(this.moment(el['eta'])) > 0){
                  line += this.moment.duration(this.moment().diff(this.moment(el['eta']))).asMinutes().toFixed() + ' minutes,';
                }else{
                  line += '--,';
                }
              }else if(el['order_status'] == 'Delivered'){
                if(this.moment(el['delivered_date']).diff(this.moment(el['eta'])) > 0){
                  line += this.moment.duration(this.moment(el['delivered_date']).diff(this.moment(el['eta']))).asMinutes().toFixed()+' minutes,';
                }else{
                  line += '--,';
                }
              }else{
                line += '--,';
              }
            } else {
              if(el['order_status'] == 'ReadyToServe' || el['order_status'] == 'OnTheWay' || el['order_status'] == 'Arrived'){
                if(el['future_delivery_date'] != '0000-00-00 00:00:00' && this.moment().diff(moment(el['future_delivery_date'])) > 0){
                  line += this.moment.duration(this.moment().diff(this.moment(el['future_delivery_date']))).asMinutes()  +' minutes,';
                } else if (this.moment().diff(this.moment(el['created_at']).add(el['delivery_time'],'minutes')) > 0){
                  line += this.moment.duration(moment().diff(this.moment(el['created_at']).add(el['delivery_time'],' minutes'))).asMinutes().toFixed(0)  +' minutes,';
                } else {
                  line+='--,';
                }
              }else{
                line+='--,';
              }
            }
           line+="\n";
          reportsData += line;
        });

        var blob = new Blob([reportsData], { type: "csv/plain" });
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "sales_" + date + ".csv";
        link.click();
      }
      else
      {
        let reportsData =
          '\ufeff' +
          '#,Customer Name,Customer Number,Order ID,Gross total(Item Total),Net Total(Total after coupon and tax),CGST,SGST,Coupon Charge,Container charge,Wallet,Online/COD,Cashback,Order Status,Order Type,Vendor Name,Payment mode,Coupon Code Name,Delivery Boy name,Delivery Time,Placed Time,Confirm time,Picked up Time,On The Way Time,Delivered Time,Distance KM,Device Type,Delayed By\n';
        this.reportsData.forEach((el,index) => {
          const coupon_code = '"' + el["coupon_code"] + '"';
          var line =
            (index+1) +
            "," +(el["user_name"])+
            // "," +el["user"]["mobile_number"] +
            "," +el["customer_mobile_number"] +
            "," +el["id"] +
            "," +el["sub_total"] +
            "," +el["total_amount"] +
            "," +el["CGST"] +
            "," +el["SGST"] +
            "," +(el["coupon_discount"] && parseFloat(el['coupon_discount']).toFixed(2) || '0.00')+
            "," +(el["total_packaging_charge"] && parseFloat(el['total_packaging_charge']).toFixed(2) || '0.00')+
            "," +(el["wallet_amount"] && parseFloat(el['wallet_amount']).toFixed(2) || '0.00')+
            "," +(el["amount"] && parseFloat(el['amount']).toFixed(2) || '0.00')+
            "," +(el["cashback"] && parseFloat(el['cashback']).toFixed(2) || '0.00')+
            "," + 
             (el["order_status"] == "Cancelled"
              ? "Cancelled"
              : "Delivered"
            ) +
            "," +el["delivery_pickup_types"] + 
            "," +el["name"] + 
            "," +(el["payment_method"] == "Cash On Delivery"
              ? "COD"
              : el["payment_method"]) + 
            "," +coupon_code +
            "," +el["driver_name"] +
            ","+(el['delivery_time'] && el['delivery_time'] != '0000-00-00 00:00:00' && this.moment(el['delivery_time']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['created_at'] && el['created_at'] != '0000-00-00 00:00:00' && this.moment(el['created_at']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['confirm_date'] && el['confirm_date'] != '0000-00-00 00:00:00' && this.moment(el['confirm_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['readytoserve_date'] && el['readytoserve_date'] != '0000-00-00 00:00:00' && this.moment(el['readytoserve_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['ontheway_date'] && el['ontheway_date'] != '0000-00-00 00:00:00' && this.moment(el['ontheway_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['delivered_date'] && el['delivered_date'] != '0000-00-00 00:00:00' && this.moment(el['delivered_date']).format('MMMM Do YYYY h:mm a') || '-')+
            ","+(el['distance_km'] && el['distance_km'] +'KM' || '-')+
            ","+(el['device_type'] && el['device_type'] || '-')+
            ",";
          if(el['eta'] && el['eta'] !== ''){
            if(el['order_status'] == 'ReadyToServe' || el['order_status'] == 'OnTheWay' || el['order_status'] == 'Arrived'){
              if(this.moment().diff(this.moment(el['eta'])) > 0){
                line += this.moment.duration(this.moment().diff(this.moment(el['eta']))).asMinutes().toFixed() + ' minutes,';
              }else{
                line += '--';
              }
            }else if(el['order_status'] == 'Delivered'){
              if(this.moment(el['delivered_date']).diff(this.moment(el['eta'])) > 0){
                line += this.moment.duration(this.moment(el['delivered_date']).diff(this.moment(el['eta']))).asMinutes().toFixed()+' minutes,';
              }else{
                line += '--';
              }
            }else{
              line += '--';
            }
          } else {
            if(el['order_status'] == 'ReadyToServe' || el['order_status'] == 'OnTheWay' || el['order_status'] == 'Arrived'){
              if(el['future_delivery_date'] != '0000-00-00 00:00:00' && this.moment().diff(moment(el['future_delivery_date'])) > 0){
                line += this.moment.duration(this.moment().diff(this.moment(el['future_delivery_date']))).asMinutes()  +' minutes,';
              } else if (this.moment().diff(this.moment(el['created_at']).add(el['delivery_time'],'minutes')) > 0){
                line += this.moment.duration(moment().diff(this.moment(el['created_at']).add(el['delivery_time'],' minutes'))).asMinutes().toFixed(0)  +' minutes,';
              } else {
                line+='--';
              }
            }else{
              line+='--';
            }
          }
          line+="\n";
          reportsData += line;
        });

        var blob = new Blob([reportsData], { type: "csv/plain" });
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "sales_" + date + ".csv";
        link.click();
      }
    },
    getVendorCountry() {
      this.countries = [];
      this.states = [];
      this.cities = [];
      deliveryZoneService.getVendorCountry().then((response) => {
        this.countries = response.data.result;
        this.getVendorState();
      });
    },
    
     getRestaurants(){
        this.restaurant = [];
        reportService.getAllRestaurantName({
            country_id: this.country,
            state_id: this.state,
            city_id: this.city
        }).then((response) => {
            this.restaurant = response.data.result;
            this.restaurant_id = this.restaurant[0].restaurant_id;
            this.searchData();
          });
    },
     getVendorState() {
      this.city = '0';
      this.state = '0';
      this.states = [];
      this.cities = [];
      deliveryZoneService
        .getVendorState({
          country_id: this.country,
        })
        .then((response) => {
          this.states = response.data.result;
          this.getVendorCity();
        });
    },
    getVendorCity() {
      this.city = '0';
      this.cities = [];
      deliveryZoneService
        .getVendorCity({
          state_id:this.state ? this.state : '0',
          country_id: this.country
        })
        .then((response) => {
          this.cities = response.data.result;
          this.searchData();
          this.getRestaurants();
        });
    },
    citychange()
    {
        this.searchData();
        this.getRestaurants();
    },
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getSalesReports(page) {
      this.loading = true;
      if(this.$auth.partner_login)
      {
          reportService
        .getSalesReport({
          ...this.filters,
          payment_method: this.payment_method,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.reports = response.data.result.data;
          // if(response.data.result.length > 0)
          // {
          //   var total = {
          //     table_id: "Total",
          //     sub_total: this.reports.reduce((a, m) => a + m.sub_total, 0),
          //     delivery_fee: this.reports.reduce((a, m) => a + m.delivery_fee,0),
          //     coupon_discount: this.reports.reduce(
          //       (a, m) => a + m.coupon_discount,
          //       0
          //     ),
          //     total_amount: this.reports.reduce((a, m) => a + m.total_amount,0),
          //     earning: this.reports.reduce((a, m) => a + m.earning,0),
          //   };
          //   this.reports = [...this.reports, total];

          // }
        });
      }
      else
      {
        reportService
          .getSalesReport({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            country: this.country,
            state: this.state,
            city: this.city,
            order_status:  this.order_status,
            restaurants_id: this.restaurants_id,
            page: page ? page : 1,
          })
          .then((response) => {
            this.loading = false;
            this.paginations = response.data.result;
            this.reports = response.data.result.data;
          });
      }
    },
    saleExportCsv() {
      this.exporting = true;
      if(this.$auth.partner_login)
      {
          reportService
          .exportToCsv({ 
            ...this.filters,
            payment_method: this.payment_method,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
            // page: page ? page : 1,
          })
          .then((response) => {
            this.exporting = false;
            this.reportsData = response.data.result;
            this.downloadsCSV();
          });
      }
      else
      {
          reportService
          .exportToCsv({ 
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            country: this.country,
            state: this.state,
            city: this.city,
            order_status:  this.order_status,
            restaurants_id: this.restaurants_id,
            // page: page ? page : 1,
          })
          .then((response) => {
            this.exporting = false;
            this.reportsData = response.data.result;
            this.downloadsCSV();
          });
      }
    },
    searchData() {
      this.getSalesReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getSalesReports();
    },
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : "";
      },
    },
};
</script>

<template>
  <Layout>
    <div>
       <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag" v-if="!$auth.partner_login">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Sales Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Sales Report') }}</p>
                  </div>
                  <div class="report-tag" v-else>
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Sales Report') }}</h4>
                    <p><router-link :to="{ name: 'vendor.reports.list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Sales Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="saleExportCsv" v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)" class="btn btn-primary btn-rounded" :disabled="exporting">{{ trans.get(!exporting?'__JSON__.Export To CSV':'__JSON__.Exporting') }}</button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row" v-if="$auth.partner_login">
                <div class="col-sm-2 offset-sm-6">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="payment_method" @change="searchData()">
                        <option value >{{ trans.get('__JSON__.All') }}</option>
                        <option value="Delivered" selected>{{ trans.get('__JSON__.Delivered') }}</option>
                        <option value="Cancelled">{{ trans.get('__JSON__.Cancelled') }}</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="payment_method" @change="searchData()">
                        <option value selected>{{ trans.get('__JSON__.All') }}</option>
                        <option value="Cash On Delivery">{{ trans.get('__JSON__.COD') }}</option>
                        <option value="Online">{{ trans.get('__JSON__.Online') }}</option>
                      </select>
                    </div>
                  </div> -->
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        @update="updateValues"
                      >
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-between" v-if="!$auth.partner_login">
                <div v-if="countries.length >1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Country') }} :</label>
                      <select class="custom-select" v-model="country" @change="getVendorState">
                        <option value="0" selected>All</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else class="offset-sm-7">
                </div>
                <div v-if="countries.length >=1 && states.length >1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.State') }} :</label>
                      <select class="custom-select" v-model="state" @change="getVendorCity">
                        <option value="0" selected>All</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="states.length >=1 && cities.length >1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.City') }} :</label>
                      <select class="custom-select" v-model="city" @change="citychange">
                        <option value="0" selected>All</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="(countries.length >1 || states.length > 1 || cities.length > 1) && restaurant.length > 1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }} :</label>
                      <select class="custom-select" v-model="restaurants_id" @change="searchData()">
                        <option value selected>{{ trans.trans('__JSON__.All Vendor',{ vendor: $auth.setting.restaurant_name }) }}</option>
                        <option v-for="res in restaurant" :value="res.restaurant_id" :key="res.restaurant_id">{{res.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="order_status" @change="searchData()">
                        <option value >{{ trans.get('__JSON__.All') }}</option>
                        <option value="Delivered" selected>{{ trans.get('__JSON__.Delivered') }}</option>
                        <option value="Cancelled">{{ trans.get('__JSON__.Cancelled') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                 <div class="sales-report" v-if="!$auth.partner_login">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Date') }} :</label>
                     <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row" v-if="!$auth.partner_login">
                <div class="col-sm-4 offset-sm-8">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        @update="updateValues"
                      >
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row table-responsive">
                <div class="col-12 table-main-list table table-centered table-nowrap sales-report-table">
                  <b-table
                    :items="reports"
                    :fields="computedFields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(table_id)="data">
                        <div>{{ data.index + 1 }}</div>
                    </template>
                    <template v-slot:cell(user_name)="data">
                        <span>{{ data.item.user_name }} {{ data.item.last_name }}</span>
                    </template>
                    <template v-slot:cell(user)="data">
                      <div>{{data.item.customer_mobile_number }}</div>
                    </template>
                    <template v-slot:cell(hash_id)="data">
                      <div>{{data.value}}</div>
                    </template>
                    <template v-slot:cell(sub_total)="data">
                      <span v-html="currency"></span>
                      {{data.item.sub_total.toFixed(2) || '0.00' }}
                    </template>
                    <template v-slot:cell(total_amount)="data">
                      <span v-html="currency"></span>
                      {{data.item.total_amount.toFixed(2) || '0.00' }}
                    </template>
                    <template v-slot:cell(coupon_discount)="data">
                      <span v-html="currency"></span>
                      {{ parseFloat(data.value) && parseFloat(data.item.coupon_discount).toFixed(2) || '0.00' }}
                    </template>
                    <template v-slot:cell(total_packaging_charge)="data">
                      <span v-html="currency"></span>
                      {{ parseFloat(data.value) && parseFloat(data.item.total_packaging_charge).toFixed(2) || '0.00' }}
                    </template>
                    <template v-slot:cell(wallet_amount)="data">
                      <span v-html="currency"></span>
                      {{ parseFloat(data.item.wallet_amount).toFixed(2) || '0.00' }}
                    </template>
                    <template v-slot:cell(amount)="data">
                      <span v-html="currency"></span>
                      {{ parseFloat(data.item.amount).toFixed(2) || '0.00' }}
                    </template>
                    <template v-slot:cell(cashback)="data">
                      <span v-html="currency"></span>
                      {{ parseFloat(data.item.cashback).toFixed(2) || '0.00' }}
                    </template>
                    <template v-slot:cell(order_status)="data">
                        <div v-if="data.item.order_status === 'Cancelled'" class="text-danger">
                          {{ trans.get('__JSON__.Cancelled') }}
                        </div>
                        <div v-else-if="data.item.order_status === 'Delivered'">
                          {{ trans.get('__JSON__.Delivered') }}
                        </div> 
                    </template>
                    <template v-slot:cell(delivery_pickup_types)="data">
                      {{data.item.delivery_pickup_types}}
                    </template>

                    <template v-slot:cell(name)="data">
                      {{data.value }}
                    </template>
                    <template v-slot:cell(payment_method)="data">
                      <div
                        v-if="data.item.payment_method === 'Cash On Delivery'"
                      >{{ trans.get('__JSON__.COD') }}</div>
                      <div v-else>{{data.item.payment_method}}</div>
                    </template>
                    <template v-slot:cell(coupon_code)="data">
                      {{data.item.coupon_code}}
                    </template>
                    <template v-slot:cell(driver_name)="data">
                      {{data.item.driver_name}}
                    </template>
                    <template #cell(delivery_time)="data" >
                      <div class="align-items-center" v-if="data.item.eta && data.item.eta !== ''">
                        <span>
                        {{ moment(data.item.eta).format('MMM DD[,] YYYY hh:mm A') }}
                        </span>
                        <span v-if="data.item.future_delivery_date != '0000-00-00 00:00:00'" class="mdi mdi-calendar text-primary font-size-18 pl-1"></span>
                        <template v-if="data.item.order_status == 'ReadyToServe' || data.item.order_status == 'OnTheWay' || data.item.order_status == 'Arrived'">
                          <br v-if="moment().diff(moment(data.item.eta)) > 0">
                          <small v-if="moment().diff(moment(data.item.eta)) > 0">
                            DELAYED by {{moment.duration(moment().diff(moment(data.item.eta))).asMinutes().toFixed()}} minutes
                          </small>
                        </template>
                        <template v-if="data.item.order_status == 'Delivered'">
                          <br v-if="moment(data.item.delivered_date).diff(moment(data.item.eta)) > 0">
                          <small v-if="moment(data.item.delivered_date).diff(moment(data.item.eta)) > 0">
                            DELAYED by {{moment.duration(moment(data.item.delivered_date).diff(moment(data.item.eta))).asMinutes().toFixed()}} minutes
                          </small>
                        </template>
                      </div>
                      <div v-else>
                        <span v-if="data.item.future_delivery_date != '0000-00-00 00:00:00'">
                        {{ moment(data.item.future_delivery_date).format('MMM DD[,] YYYY hh:mm A') }}
                        </span>
                        <span v-else>
                        {{ moment(data.item.created_at).add(data.value,'minutes').format('HH:mm:ss') }}
                        </span>
                        <template v-if="data.item.order_status == 'ReadyToServe' || data.item.order_status == 'OnTheWay' || data.item.order_status == 'Arrived'">
                          <br v-if="data.item.future_delivery_date != '0000-00-00 00:00:00' && moment().diff(moment(data.item.future_delivery_date)) > 0">
                          <br v-else-if="moment().diff(moment(data.item.created_at).add(data.value,'minutes')) > 0">
                          <small v-if="data.item.future_delivery_date != '0000-00-00 00:00:00' && moment().diff(moment(data.item.future_delivery_date)) > 0">
                            DELAYED by {{moment.duration(moment().diff(moment(data.item.future_delivery_date))).asMinutes()}} minutes
                          </small>
                          <small v-else-if="moment().diff(moment(data.item.created_at).add(data.value,'minutes')) > 0">
                            DELAYED by {{moment.duration(moment().diff(moment(data.item.created_at).add(data.value,'minutes'))).asMinutes().toFixed(0)}} minutes
                          </small>
                        </template>
                      </div>
                      <!-- {{ moment(data.item.created_at).add(data.value,'minutes').format('LT') }} <span v-b-tooltip.hover.v-light :title="moment(data.item.created_at).add(data.value,'minutes').format('YYYY-MM-DD HH:mm:ss')" class="font-size-15"><i class="bx bx-info-circle"></i></span> -->
                    </template>
                    <template v-slot:cell(device_type)="data">
                      {{data.value}}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      {{data.value && data.value != '0000-00-00 00:00:00' && moment(data.value).format('MMMM Do YYYY h:mm a') || '-'}}
                    </template>
                    <template v-slot:cell(confirm_date)="data">
                      {{data.value && data.value != '0000-00-00 00:00:00' && moment(data.value).format('MMMM Do YYYY h:mm a') || '-'}}
                    </template>
                    <template v-slot:cell(readytoserve_date)="data">
                      {{data.value && data.value != '0000-00-00 00:00:00' && moment(data.value).format('MMMM Do YYYY h:mm a') || '-'}}
                    </template>
                    <template v-slot:cell(ontheway_date)="data">
                      {{data.value && data.value != '0000-00-00 00:00:00' && moment(data.value).format('MMMM Do YYYY h:mm a') || '-'}}
                    </template>
                    <template v-slot:cell(delivered_date)="data">
                      {{data.value && data.value != '0000-00-00 00:00:00' && moment(data.value).format('MMMM Do YYYY h:mm a') || '-'}}
                    </template>
                    <template v-slot:cell(distance_km)="data">
                      {{data.value}} KM
                    </template>
                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Sales Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="paginations"
                          :limit="limit"
                          @pagination-change-page="getSalesReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.sales-report-table .table thead th:nth-child(1),
.sales-report-table .table thead th:nth-child(2),
.sales-report-table .table thead th:nth-child(3),
.sales-report-table .table thead th:nth-child(9),
.sales-report-table .table thead th:nth-child(13),
.sales-report-table .table thead th:nth-child(15),
.sales-report-table .table thead th:nth-child(16){
    pointer-events: none;
}
</style>