<template>
  <div class="d-flex">
    <b-button
      v-if="multiple || !((!!value || !!editableImages))"
      variant="link"
      v-b-modal="'modal-'+id"
      class="plus_button m-1"
    >
      <i class="bx bx-plus plus"></i>
    </b-button>
    <div class="d-flex flex-wrap">
      <template v-if="!multiple && editableImages && ((typeof editableImages == 'string' && editableImages != '') || editableImages.length > 0 )">
        <div class="mt-1 relative ml-2">
          <div class="remove" @click="deleteImage(editableImages,deletePath)" v-if="!readonly">
            <i class="bx bx-x"></i>
          </div>
          <img
            :src="getPath(mediaPath+'/'+editableImages)"
            alt
            class="rounded avatar-lg image_thumb"
            
            @click="openGallery(getPath(mediaPath+'/'+editableImages))"
          />
        </div>
      </template>
      <template v-else-if="multiple && editableImages && ((typeof editableImages == 'string' && editableImages != '') || editableImages.length > 0 )" class="d-flex flex-wrap">
        <div class="mt-1 relative ml-2" v-for="(v,i) in editableImages" :key="i">
          <div class="remove" @click="deleteImage(v,deletePath)" v-if="!readonly">
            <i class="bx bx-x"></i>
          </div>
          <img
            :src="getPath(mediaPath+'/'+v)"
            alt
            class="rounded avatar-lg image_thumb"
            
            @click="openGallery(getPath(mediaPath+'/'+v))"
          />
        </div>
      </template>
      <template v-if="multiple && value && ((typeof value == 'string' && value != '') || value.length > 0 )" class="d-flex flex-wrap">
        <div class="mt-1 relative ml-2" v-for="(v,i) in value" :key="i">
          <div class="remove" @click="deleteImage(v)" v-if="!readonly">
            <i class="bx bx-x"></i>
          </div>
          <img
            :src="getPath(baseUrl+'/'+v)"
            alt
            class="rounded avatar-lg image_thumb"
            
            @click="openGallery(getPath(baseUrl+'/'+v))"
          />
        </div>
      </template>
      <template v-else-if="!multiple && value && ((typeof value == 'string' && value != '') || value.length > 0 )">
        <div class="mt-1 relative ml-2">
          <div class="remove" @click="deleteImage(value)" v-if="!readonly">
            <i class="bx bx-x"></i>
          </div>
          <img
            :src="getPath(baseUrl+'/'+value)"
            alt
            class="rounded avatar-lg image_thumb"
            
            @click="openGallery(getPath(baseUrl+'/'+value))"
          />
        </div>
      </template>
    </div>
    <b-modal :id="'modal-'+id" :ref="'my-modal-'+id" hide-footer centered>
      <vue-dropzone
        :id="id"
        ref="proDropzone"
        v-if="!readonly"
        :use-custom-slot="true"
        @vdropzone-removed-file="removeTmpFile"
        @vdropzone-max-files-exceeded="maxFileExceed"
        :options="dropzoneOpt"
        @vdropzone-error="checkFile"
        @vdropzone-file-added="changeThumbNail"
      >
        <!-- @vdropzone-file-added="checkFile" -->
        <div class="dropzone-custom-content">
          <div class="mb-1">
            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
          </div>
          <h4>Drop files here or click to upload.</h4>
        </div>
      </vue-dropzone>
      <div class="d-flex justify-content-between mt-3">
        <b-button variant="secondary" @click="$refs['my-modal-'+id].hide()">{{trans.get('__JSON__.Cancel')}}</b-button>
        <b-button variant="primary" @click="saveImage" v-bind:disabled="this.files == ''">{{trans.get('__JSON__.Save')}}</b-button>
      </div>
    </b-modal>
    <LightBox
      v-if="media && media.length > 0"
      ref="lightbox"
      :media="media"
      :show-caption="false"
      :show-light-box="false"      
    />
  </div>
</template>

<script>
import { routes } from "../../config/api_routes";
import vue2Dropzone from "vue2-dropzone";
import { commonService } from "../../services";
import LightBox from 'vue-image-lightbox';
import { error_message, replaceByDefault } from "../../Helper/helper";
import config from '../../config';

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  components: { vueDropzone: vue2Dropzone, LightBox },
  props: {
    value: {
      default: "",
      type: [String, Array],
    },
    acceptedFiles: String,
    multiple: Boolean,
    id: String,
    editableImages: {
      default: "",
      type: [String, Array],
    },
    mediaPath: String,
    deletePath: String,
    customParams: {
      default() {
        return {};
      },
      type: Object,
    },
    "image-deleted": {},
    readonly:{
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      files: [],
      baseUrl:'',
      replaceByDefault,
      media:[],
      config:config,
      dropzoneOpt: {
        url: routes.uploadTmpImageApi,
        headers: window.axios.defaults.headers.common,
        thumbnailHeight: 100,
        maxFilesize: 10,
        acceptedFiles: this.acceptedFiles,
        addRemoveLinks: true,
        success: (response) => {
          if (response.status == "success") {
            const data = JSON.parse(response.xhr.response);
            // this.$emit('input', this.multiple ? [...this.value?this.value:[], data.result.file] : [data.result.file]);
            this.baseUrl = data.result.base_url;
            this.files = this.multiple
              ? [...this.files, data.result.file]
              : data.result.file;
            $(response.previewElement)
              .find(".dz-remove")
              .attr("href", data.result.file);
          }
        }
      },
      error:function(file){
        console.log(file);
      },
    };
  },
  mounted() {
    // $(this.$refs['modal-1']).on("hidden.bs.modal", this.doSomethingOnHidden)
  },
  created() {
    if (!this.multiple) {
      this.dropzoneOpt.maxFiles = 1;
    }
  },
  methods: {
    maxFileExceed(file) {
      this.$refs.proDropzone.removeFile(file);
    },
    openGallery(image){
      if(image.indexOf('.pdf') !== -1 || image.indexOf('.doc') !== -1 || image.indexOf('.zip') !== -1 || image.indexOf('.xls') !== -1 || image.indexOf('.pem') !== -1){
        window.open(image)
        return false;
      }
      if(!this.multiple){
        this.media = [{
            src: image,
            thumb: image
          }];
        this.$refs.lightbox.showImage(0);
      }else{
        const media = [...(this.editableImages || []).map(i => ({  src:this.mediaPath+'/'+i+'',thumb: this.mediaPath+'/'+i+''})) , ...(this.value || []).map(i => ({src:this.baseUrl+'/'+i,thumb:this.baseUrl+'/'+i}))];
        this.media = media.filter(m => (m.src.indexOf('.pdf') === -1 && m.src.indexOf('.doc') === -1 && m.src.indexOf('.zip') === -1 && m.src.indexOf('.xls') === -1 && m.src.indexOf('.pem') === -1));
        this.$refs.lightbox.showImage(this.media.reduce((a,i,index) => (i.src.indexOf(image)!==-1?index:a),0));
      }
    },
    checkFile(file,message){
      console.log(message);
      error_message(message)
      this.$refs.proDropzone.removeFile(file)
    },
    saveImage() {
      this.$emit(
        "input",
        this.multiple ? [...this.value, ...this.files] : this.files
      );
      this.files = [];
      this.uploaded = true;
      this.$refs["my-modal-" + this.id].hide();
    },
    removeTmpFile(file, error, xhr) {
      console.log(file)
      const filename = $(file._removeLink).attr("href");
      if (!this.uploaded && file.status !== "error") {
        commonService.removeTmpImage([filename]).then((res) => {
          if (this.multiple) this.files.splice(this.files.indexOf(filename), 1);
          else this.files = "";
        });
      }
    },
    removeAllFile() {
      commonService.removeTmpImage(this.files).then((res) => {
        this.files.map((file) => {
          if (this.multiple) this.files.splice(this.files.indexOf(file), 1);
        });
        this.files = [];
        this.$emit("input", this.multiple ? this.files : "");
      });
    },
    deleteImage(value, path = null) {
      if (!path) {
        this.deleteTmpImage(value);
      } else {
        const params = { ...this.customParams, file: value };
        window.axios.post(path, params).then((res) => {
          if (res.data.result.deleted) {
            this.$emit("image-deleted", value);
            this.$toasted.success(res.data.message);
          }
        });
      }
    },
    deleteTmpImage(value) {
      commonService.removeTmpImage([value]).then((res) => {
        if (this.multiple) {
          var data = [...this.value];
          console.log(data);
          data.splice(this.value.indexOf(value), 1);
          this.$emit("input", data);
        } else {
          this.$emit("input", "");
        }
      });
    },
    changeThumbNail(file){
      if(file.type.indexOf('pdf') !== -1){
        $(file.previewElement).find('img').attr('src',config.media_path+'images/file/pdf-icon.png');
        $(file.previewElement).find('img').attr('height',120);
        $(file.previewElement).find('.dz-details').css({opacity:0});
      }else if(file.type.indexOf('doc') !== -1){
        $(file.previewElement).find('img').attr('src',config.media_path+'images/file/doc-icon.png');
        $(file.previewElement).find('img').attr('height',120);
        $(file.previewElement).find('.dz-details').css({opacity:0});
      }else if(file.type.indexOf('zip') !== -1){
        $(file.previewElement).find('img').attr('src',config.media_path+'images/file/zippfile.png');
        $(file.previewElement).find('img').attr('height',120);
        $(file.previewElement).find('.dz-details').css({opacity:0});
      }else if(file.type.indexOf('xls') !== -1){
        $(file.previewElement).find('img').attr('src',config.media_path+'images/file/doc-xls.png');
        $(file.previewElement).find('img').attr('height',120);
        $(file.previewElement).find('.dz-details').css({opacity:0});
      }else if(file.type.indexOf('pem') !== -1){
        $(file.previewElement).find('img').attr('src',config.media_path+'images/file/keyfile.png');
        $(file.previewElement).find('img').attr('height',120);
        $(file.previewElement).find('.dz-details').css({opacity:0});
      }
    },
    getPath(path){
      if(path.indexOf('.pdf') !== -1){
        return config.media_path+'images/file/pdf-icon.png';
      }else if(path.indexOf('.doc') !== -1){
        return config.media_path+'images/file/doc-icon.png'
      }else if(path.indexOf('.zip') !== -1){
        return config.media_path+'images/file/zippfile.png'
      }else if(path.indexOf('.xls') !== -1){
        return config.media_path+'images/file/doc-xls.png'
      }else if(path.indexOf('.pem') !== -1){
        return config.media_path+'images/file/keyfile.png'
      }
      console.log(path.split('://').length);
      if(path.split('://').length > 2){
        return path.replace(this.mediaPath+'/','')
      }
      return path;
    }
  },
};
</script>
