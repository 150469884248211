<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import pagination from "laravel-vue-pagination";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, DateRangePicker, pagination },
  data() {
    let startDate = this.moment().startOf("day").subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      sortBy: "id",
      sortDesc: true,
      decimal: get_decimal(),
      dateRange: { startDate, endDate },
      filters: {
         start_date: this.moment().subtract(29,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      promoCodeReport: [],
      promoCodeReports: {},
      date_ranges: date_ranges,
      limit: 2,
      currency: get_currency(),
      loading: true,
      fields: [
        { key: "coupon_name", label: "Promocode", sortable: true },
        { key: "coupon_code", label: "Code", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "total", label: "# Of Time Usage", sortable: true }
      ],
    };
  },
  mounted() {
    this.getPromoCodeReports();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getPromoCodeReports();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getPromoCodeReports(page) {
      this.loading = true;
      reportService
        .getPromoCodeReport({
          ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
        })
        .then((response) => {
          this.loading = false;
          this.promoCodeReport = response.data.result;
        });
    },
    searchData() {
      this.getPromoCodeReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getPromoCodeReports();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Promocode Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Promocode Report') }}</p>
                  </div>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 offset-sm-8 promo-code-reports">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        @update="updateValues"
                      >
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
                 <div class="row table-responsive">
                <div class="col-12 table-main-list table table-centered table-nowrap tax-report-table">
                  <b-table
                    :items="promoCodeReport.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(status)="data">
                      <div v-if="moment(data.item.end_datetime).format('YYYY-MM-DD h:mm:ss a') < moment().startOf('now').format('YYYY-MM-DD h:mm:ss a')"
                      class="badge badge-pill badge-soft-warning font-size-12"
                      >{{ trans.get('__JSON__.Expired') }}</div>
                      <div
                        v-else-if="data.item.status === '1'"
                        class="badge badge-pill badge-soft-success font-size-12"
                      >{{ trans.get('__JSON__.Active') }}</div>
                      <div
                        v-else
                        class="badge badge-pill badge-soft-danger font-size-12"
                      >{{ trans.get('__JSON__.Inactive') }}</div>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Promocode Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="promoCodeReports"
                          :limit="limit"
                          @pagination-change-page="getPromoCodeReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>