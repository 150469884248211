
// Banner services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const chatService = {
    // get banner 
    // info: sortDesc : Boolean

    getUserListing: (formData) => window.axios.post(routes.getUserListing,formData),   
    getUserMessage: (formData) => window.axios.post(routes.getUserMessage,formData), 
    sendMessage: (formData) =>  window.axios.post(routes.sendMessage,formData), 
    getUnreadMessage: () => window.axios.post(routes.getUnreadMessageApi),
    setMarkAsRead: (formData) => window.axios.post(routes.setMarkAsReadApi, formData),
    customerNotes: (formData) => window.axios.post(routes.getCustomerChatNotesApi,formData)
  
}