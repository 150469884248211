<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'

import vendorAdd from './vendor-add'

/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader, vendorAdd },
  data() {
    return {};
  }
}
</script>

<template>
  <Layout>
  <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-store"></i>
              </span>
            </div>
             <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Add Vendor',{ vendor: $auth.setting && $auth.setting.restaurant_name }) }}</h4>
              <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendors',{ vendor: $auth.setting && $auth.setting.restaurant_name }) }}</router-link> > {{ trans.get('__JSON__.Add Vendor',{ vendor: $auth.setting && $auth.setting.restaurant_name }) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vendor-add/>
  </Layout>
</template>
