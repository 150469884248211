<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import { vendorService} from '../../services';

export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.trans.get('__JSON__.Plan Details'),
      profile: {
        subscription_plan_type: "",
        subscription_date: "",
        payment_link: "",
        is_single_restaurant: "",
        business_category: ""
      }, 
      typesubmit: false,
    };
  },

  mounted(){
    this.getBusinessProfile();
  },
  methods: {
    getBusinessProfile(){
      vendorService.getBusinessProfile()
      .then(response => {
        const { 
          subscription_plan_type,
          subscription_date,
          payment_link,
          is_single_restaurant,
          business_category
        } = response.data.result;
        this.profile = { 
          subscription_plan_type,
          subscription_date,
          payment_link,
          is_single_restaurant,
          business_category
        };
      });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.users.$invalid) {
        return;
      }
      else{
        vendorService.updateProfile(this.users)
        .then(response => {
            if (response.data.code === 200) {
              success_message(response.data.message);
              this.$router.push({ name: "profile-info" });
            } else {
              error_message(response.data.message);
            }
        });
      }  
    } 
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <form action="#" @submit.prevent="">
            <!-- Plan Details Start -->
              <div class="card">
                <div class="card-body">
                  <!-- <h4 class="card-title">Plan Details</h4> -->
                  <p class="card-title-desc"></p>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">{{ trans.get('__JSON__.Plan Type') }}</label>
                        <input id="plan_type" name="plan_type" type="text" v-model="profile.subscription_plan_type" class="form-control" readonly/>
                      </div>    
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">{{ trans.get('__JSON__.Subscription Date') }}</label>
                        <input id="subscription_date" name="subscription_date" v-model="profile.subscription_date" type="text" class="form-control" readonly/>
                      </div>    
                    </div>

                    <div class="col-sm-6" v-if="profile.payment_link">
                      <div class="form-group">
                        <label class="control-label payment-link">{{ trans.get('__JSON__.Payment Link') }}</label>
                        <a v-bind:href="''+profile.payment_link+''" target="_blank"><input id="payment_link" name="payment_link" v-model="profile.payment_link" type="url" class="form-control" readonly/></a>
                      </div>    
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">{{ trans.get('__JSON__.Business Type') }}</label>
                        <div v-if="profile.is_single_restaurant == 'Yes'">
                          <input id="is_single_restaurant" name="is_single_restaurant" value="Single Vendor" type="text" class="form-control" readonly/>
                        </div>
                        <div v-else>
                          <input id="is_single_restaurant" name="is_single_restaurant" value="Multi Vendor" type="text" class="form-control" readonly/>
                        </div>
                      </div>    
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">{{ trans.get('__JSON__.Business Category') }}</label>
                         <div v-if="profile.business_category == 'Both'">
                          <input id="business_category" name="business_category" value="Multi Category" type="text" class="form-control" readonly/>
                        </div>
                        <div v-else>
                          <input id="business_category" name="business_category" value="Single Category" type="text" class="form-control" readonly/>
                        </div>
                      </div>    
                    </div>
                  </div> 
                </div>
              </div>
            <!-- Plan Details End -->
        </form>
    </div>
  </div>
    <!-- end row -->
  </Layout>
</template>
