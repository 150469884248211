<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import tinymce from "vue-tinymce-editor";
import { mailService } from "../../services";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import { success_message, get_user, error_message } from "../../Helper/helper";
/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader, tinymce },
  data() {
    return {
      mail: {
        id: "",
        subject: "",
        content: "",
        status: ""
      },
      plugins: [
        // "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | l      ink image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
    };
  },
  validations: {
    mail: {},
  },
  mounted() {
    this.getMailById();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  methods: {
    getMailById() {
      mailService
        .getMailById({ id: this.$route.params.id })
        .then((response) => {
          const { subject, content, id, vendor_id, status } =
            response.data.result;
          this.mail = {
            subject,
            content,
            id,
            vendor_id,
            status
          };
        });
    },
    generalIt() {
      this.$v.$touch();
      // console.log(this.$v.setting.$invalid);
      if (this.$v.mail.$invalid) {
        // console.log("if");
        return;
      } else {
        const fd = new FormData();
        fd.append("id", this.mail.id);
        fd.append("subject", this.mail.subject);
        fd.append("content", this.mail.content);
        fd.append("status", this.mail.status);
        mailService.updateMailById(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row header-new">
      <div class="col-12">
        <div
          class="
            d-flex
            align-items-center
            justify-content-between
            icon-main-page
          "
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span
                class="
                  avatar-title
                  rounded-circle
                  bg-soft-primary
                  text-primary
                  font-size-18
                "
              >
                <i class="bx bx-mail-send"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ mail.subject }}</h4>
              <p class="m-0"><router-link :to="{ name: 'mail-list'}">{{ trans.get('__JSON__.Mail') }}</router-link> > {{ mail.subject }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-form @submit.prevent="generalIt">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <input
                  id="id"
                  name="id"
                  type="hidden"
                  v-model="mail.id"
                  class="form-control"
                />
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="required">{{
                      trans.trans("__JSON__.Subject")
                    }}</label>
                    <input
                      id="subject"
                      v-model="mail.subject"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Subject')"
                      name="subject"
                    />
                  </div>
                </div>
                <div class="col-md-6 notes">
                  <h5 class="text-danger">*Please don't change or remove name use in {curly brackets}.</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="control-label">{{ trans.get('Status') }} 
                      <span
                      v-b-tooltip.hover title="Once's status off email will not send to mail">
                      <i class="bx bx-info-circle"></i>
                    </span>
                   </label>
                   <div class="swtich-data">
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        class="switch-on"
                        name="status"
                        v-model="mail.status"
                        true-value="0"
                        false-value="1"
                      />
                      <div class="slider round">
                        <!--ADDED HTML -->
                        <span class="on">ON</span>
                        <span class="off">OFF</span>
                        <!--END-->
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <tinymce
                    id="d1"
                    v-model="mail.content"
                    :plugins="plugins"
                    :toolbar="toolbar"
                    :other_options="options"
                  ></tinymce>
                </div>
                <div class="col-md-12">
                  <div class="d-flex align-items-center">
                    <button type="submit" class="btn btn-primary mr-1 mt-3">Update</button>
                     <router-link class="mt-3 btn btn-secondary" variant="secondary" :to="{ name: 'mail-list'}">{{ trans.get('__JSON__.Cancel') }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </b-form>
  </Layout>
</template>
<style scoped>
.notes h5{
  margin-top: 35px;
}
</style>
