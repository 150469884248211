/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
import Lang from 'lang.js';
window.Vue = Vue;

import { BootstrapVue, BIcon,IconsPlugin } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import pagination from "laravel-vue-pagination";
// import Main from './layouts/main.vue';
import VueSocketIO from 'vue-socket.io';
import DataTable from 'laravel-vue-datatable';
import Moment from 'moment';
import Toasted from 'vue-toasted';
import VuePlaceAutocomplete from 'vue-place-autocomplete';
import GoogleAutocomplete from 'vue-google-autocomplete';
import AuthPlugin from './plugins/authPlugin';
import VueLazyLoad from 'vue-lazyload';
// window.default_language = 'en';
const default_locale = window.default_locale;
const fallback_locale = window.fallback_locale;
const messages = window.messages;
// start gloable variables
Vue.prototype.trans = new Lang( { messages, locale: default_locale, fallback: fallback_locale } );
Vue.prototype.moment = Moment;
Vue.prototype.moment.relativeTimeThreshold('m',120);
Vue.prototype.$isDev = process.env.NODE_ENV !== "production";
Vue.prototype.no_image = config.no_image;
Vue.prototype.$order_count = 0;

// end 

Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;
Vue.use(IconsPlugin)
Vue.use(BootstrapVue);
Vue.component('b-icon', BIcon)
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(pagination);
Vue.use(DataTable);
// Vue.use(Main);
Vue.use(Moment);
Vue.use(VuePlaceAutocomplete);
Vue.use(GoogleAutocomplete);
Vue.use(Toasted, {
    duration: 4000,
    position: 'top-right',
    action : {
      onClick : (e, toastObject) => {
          toastObject.goAway(0);
      }
    }
});
Vue.use(VueGoogleMaps, {
    load: {
        key: config.google_key,
        libraries: "places,drawing",
    },
    installComponents: true,
});
Vue.use(AuthPlugin)

Vue.use(new VueSocketIO({
    debug: true,
    connection: config.socket_url
}))

Vue.use(VueLazyLoad, {
    preLoad: 1.3,
    error: config.no_image,
    // loading: ,
    attempt: 1
  })
  
Vue.component("apexchart", VueApexCharts);
// Vue.component(
//     "dynamic-component",
//     require("./components/dynamic-component").default
// );

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

import NProgress from 'nprogress';
const progressShowDelay = 100;
let routeResolved = false;
/**
 * Initializes NProgress after a specified delay only if the route was not resolved yet.
 */

export function cancelTopProgress() {
  routeResolved = true;
  NProgress.done();
}
function tryInitProgress() {
  routeResolved = false;
  setTimeout(()=> {
    if (!routeResolved) {
      NProgress.start();
    }
  }, progressShowDelay);
}
export default function initProgress(router) {
  router.afterEach(cancelTopProgress);
  router.beforeEach((to, from, next) => {
    tryInitProgress();
    return next();
  });
}


 
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import App from "./views/App.vue"; 
import routes from "./views";
import "./views";
import Layouts from "./mixins/layouts.mixin";
import config from "./config"
import { set_axios_defaults } from "./Helper/helper";

const app = new Vue({
    el: "#app",
    mixins: [Layouts],
    router: routes,
    components: { App }
});

var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
