<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DriverProfile from "./pages/driver-profile";
import DriverOrder from "./pages/driver-order";
import DriverRating from "./driver-rating";
import { driverService, orderService } from "../../services";
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "../orders/order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {  order_statuses,success_message,error_message } from "../../Helper/helper";
import ChangePassword from "./change-password";
import config from '../../config';
/**
 * Tabs & accordions component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DriverProfile,
    DriverRating,
    DateRangePicker,
    OrderList,
    pagination,
    DriverOrder,
    ChangePassword
  },
  props:['driver_id'],
  data() {
    return {
      driver: [],
      loading: false,
      statuses: order_statuses,
      is_block: '',
      availbility_status: '',
      config: config,
    };
  },
  mounted() {
    this.getDriverInfo();
  },
  methods: {
    
    getDriverInfo() {
      driverService
        .getDriverInfo({ driver_id: this.$route.params.driver_id })
        .then((response) => {
          this.driver = response.data.result;
          this.is_block = response.data.result.is_block;
          this.availbility_status = response.data.result.availbility_status;
        });
    },
    changeStatus(){
      driverService.updateDriverStatus({
        driver_id:this.$route.params.driver_id,
        is_block: this.is_block
      }).then(response => {
        if(response.data.code == 200){
          success_message(response.data.message)
        }
      })
    },
    onStatusChange(){
      driverService.updateAvailbilityStatus({
        driver_id:this.$route.params.driver_id,
        availbility_status: this.availbility_status
      }).then(response => {
        if(response.data.code == 200){
          success_message(response.data.message)
        }
      })
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ driver.firstname }} {{ driver.lastname }}</h4>
              <p>
                <router-link
                  :to="{ name: $auth.partner_login?'vendor-driver-dashboard':'driver-list'}"
                >{{ trans.trans('__JSON__.Driver',{ driver: $auth.setting.driver_name }) }}</router-link>
                > {{ driver.firstname }} {{ driver.lastname }}
              </p>
            </div>
          </div>
          <div class="page-title-left avatar-main-icon">
            <div class="row">
              <div class="col-md-5 ml-auto">
                <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Profile Status') }}</label> 
                  <select class="custom-select" v-bind:value="is_block" v-model="is_block"  id="is_block" name="is_block" @change="changeStatus" :disabled="$auth.partner_login"> 
                      <option value="0">{{ trans.get('__JSON__.Active') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                      <option value="2">{{ trans.get('__JSON__.Waiting For Approval') }}</option>
                  </select>
              </div>
              <div class="col-md-3">
                <label class="control-label font-weight-bold">{{ trans.get('__JSON__.Job Status') }}</label>
                <div class="swtich-data big-swtch">
                  <b-form-group>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="availbility_status"
                        name="availbility_status"
                        v-model="availbility_status"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                        @change="onStatusChange"
                        :disabled="$auth.partner_login"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Online') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Offline') }}</span>
                      </div>
                    </label>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs nav-class="nav-tabs-custom" content-class="p-3 text-muted" lazy>
              <b-tab :title="trans.get('__JSON__.Profile')">
                <b-card-text>
                  <DriverProfile />
                </b-card-text>
              </b-tab>
              <b-tab :button-id="statuses.delivered">
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">
                    {{
                    trans.get("__JSON__.Delivered")
                    }}
                  </span>
                </template>
                <DriverOrder :driver_id="$route.params.driver_id" :status="statuses.delivered" />
              </b-tab>
              <b-tab :button-id="statuses.cancelled">
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">
                    {{
                    trans.get("__JSON__.Rejected")
                    }}
                  </span>
                </template>
                <DriverOrder :driver_id="$route.params.driver_id" :status="statuses.cancelled" />
              </b-tab>
              <b-tab :title="trans.get('__JSON__.Rating & Reviews')">
                <b-card-text>
                  <DriverRating />
                </b-card-text>
              </b-tab>
              <b-tab v-if="$auth.hasPermission(config.permissions.driver_write)">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-lock"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Change Password') }}</span>
                </template>
                <ChangePassword v-if="!!driver" :driver_data="driver"/>
              </b-tab>
              
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>