<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  get_decimal
} from "../../Helper/helper";
import moment from "moment";
import { reportService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.moment().startOf('day').subtract(29, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal:get_decimal(),
      dateRange: { startDate, endDate },
      itemreports: [],
      paginations: {},
      limit: 2,
       filters: {
        start_date: this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD')
      },
      sortBy: "menu_name",
      moment: moment,
      loading: true,
      fields: [
        { key: "id", label: "#", sortable: false },
        { key: "menu_name", label: "Menu Name", sortable: true },
        { key: "item_name", label: "Item Name", sortable: true },
        { key: "price", label: "Item Price", sortable: true },
        { key: "count", label: "Sales Count", sortable: true },
      ],
    };
  },
  mounted() {
    this.getItemReports();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.getItemReports();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getItemReports(page) {
      this.loading = true;
      reportService
        .getItemReport({
          ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.itemreports = response.data.result;
        });
    },
    searchData() {
      this.getItemReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getItemReports();
    },
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : "";
      },
    },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Item Report') }}</h4>
                    <p><router-link :to="{ name: 'vendor.reports.list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Item Report') }}</p>
                  </div>
              </div>
              </div>
          </div>
      </div>


      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 offset-sm-8">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        @update="updateValues"
                      >
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list">
                  <b-table
                    :items="itemreports"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                        {{ data.index + 1 }}
                      </template>

                    <template v-slot:cell(menu_name)="data">
                      <div>{{ data.item.menu_name }}</div>
                    </template>

                     <template v-slot:cell(item_name)="data">
                      <div>{{ data.item.item_name }}</div>
                    </template>

                     <template v-slot:cell(price)="data">
                      <div>{{ data.item.price }}</div>
                    </template>


                    <template v-slot:cell(count)="data">
                      <div>{{ data.item.count }}</div>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Item Report Found') }}</p>
                    </template>
                    
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>