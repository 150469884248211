<script>
import pagination from "laravel-vue-pagination";

import config from "../../../config";

import { success_message, error_message } from "../../../Helper/helper";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import { settingService } from "../../../services";
/**
 * Starter component
 */
export default {
  components: { pagination },
  data() {
    return {
      limit: 1,
      config: config,
      ordertext: [],
      paginations: {},
      list: [{
        message: "",
        message_another_lang: "",
      }],
    };
  },
  mounted() {
    this.getOrderTextList();
  },
  methods: {
    getOrderTextList(page) {
      settingService
        .getNotificationText({
          page: page ? page : 1,
        })
        .then((response) => {
          this.paginations = response.data.result;
          this.ordertext = response.data.result.data;
        });
    },
    updateOrder() {
        const fd = new FormData();
        fd.append('list',JSON.stringify(this.ordertext));
        settingService.updateNotificationtext(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getOrderTextList();
          } else {
            error_message(response.data.message);
          }
        });
    },
  }
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body settings-main">
          <div class="row sections-main">
            <div class="col-md-12 mt-4">
              <div class="row">
                <div class="col-md-4" v-if="$auth.setting">
                  <h6>{{ trans.get('__JSON__.Key') }}</h6>
                </div>
                <div class="col-md-4">
                  <h6>{{ trans.trans('__JSON__.Primary Message', { lang: $auth.langauges[0].name }) }}</h6>
                </div>
                <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                </div>
                <div class="col-md-4" v-else>
                  <h6>{{ trans.trans('__JSON__.Secondary Message', { lang: $auth.langauges[1].name }) }}</h6>
                </div>
              </div>
            </div>
          </div>

          <b-form @submit.prevent="updateOrder">
            <div class="row sections-main" v-for="(list,index) in ordertext" :key="index">
              <div class="col-md-12">
                <div class="row align-items-center api-text-list">
                  <input type="hidden" name="id" v-model="list.id" />
                  <div class="col-md-4">
                    <h6>{{list.type}}</h6>
                  </div>
                  <div class="col-md-4">
                    <b-form-group>
                      <b-form-input
                        v-model="list.message"
                        name="message[]" 
                        type="text"
                      ></b-form-input>
                
                    </b-form-group>
                  </div>
                  <div class="col-md-4 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                  </div>
                  <div class="col-md-4" v-else>
                    <b-form-group>
                      <b-form-input
                        v-model="list.message_another_lang"
                        name="message_another_lang[]"
                        type="text"
                      ></b-form-input>
                    
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <b-button
                  v-if="$auth.hasPermission(config.permissions.terminology_write)"
                  variant="primary"
                  @click="updateOrder"
                  class="float-left mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</b-button>
              </div>
            </div>
          </b-form>

          <div class="row col-sm-5 offset-sm-7 mt-3">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <pagination
                    :data="paginations"
                    :limit="limit"
                    @pagination-change-page="getOrderTextList"
                  ></pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>