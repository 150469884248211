<script>
import config from "../../config";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import { menuService,vendorService } from "../../services";
import { error_message,debounce } from "../../Helper/helper";
/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader, pagination, menuService },
   data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      restaurant_id: "",
      restaurant: [],
      sortBy:sortBy || "id",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      banners: [],
      paginations: {},
      limit: 1,
      search: "",
      status: status == undefined ? 1 :status,
      config: config,
      loading: true,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'dine-in-banner', query: {status,orderBy,sortBy}})
        }
        this.getBanner();
        this.init = false;
      },500),
      
      fields: [
        { key: "image", label: "#", sortable: false },
        { key: "name", label: this.trans.get("__JSON__.Name"), sortable: true },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getBanner();
    this.getRestaurantById();
  },
  methods: {
     getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
            restaurant_on_off
          } = response.data.result;
          this.restaurant_id = restaurant_id;
          this.restaurant_on_off = restaurant_on_off;
          this.is_confirm = is_confirm;
          this.restaurant = {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots
          };
        });
    },
    getBanner(page) {
      this.loading = true;
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      }
      menuService
        .getBanner(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.banners = response.data.result.data;
          this.bannerCount = response.data.result.bannerCount;
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getBanner();
    },
    deleteById(id) {
      if (confirm("Are you sure you want to delete?")) {
        menuService.deletebanner({ id: id }).then((response) => {
          error_message(response.data.message);
          this.getBanner();
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
     <div>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page" >
            <div class="page-title-left avatar-main-icon">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-purchase-tag"></i>
                </span>
              </div>
              <div class="report-tag">
                <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Banner') }}</h4>
                
                <!-- <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-dine-in-menu',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > <router-link :to="{name: 'vendor-add-customization',params: { menu_item_id: $route.params.menu_item_id}}">{{ trans.trans('__JSON__.Customization') }}</router-link> > {{this.menu_item_name}}</p> -->
                <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-dine-in-menu',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Dine In Menu') }}</router-link> > {{ trans.get('__JSON__.Banner') }}</p>
              </div>
            </div>
            <div class="page-title-right">
                <router-link :to="{name: 'add-dine-in-banner'}" class="btn btn-success btn-rounded mr-2 add-btn">
                  <i class="mdi mdi-plus mr-1"></i> {{ trans.get('__JSON__.Add Banner') }}
                </router-link>
            </div>
          </div>
        </div>
      </div>
        <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list banner-table table-responsive">
                <b-table
                  :items="banners"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>
                  
                  <template v-slot:cell(image)="data">
                    <div v-if="data.item.image">
                      <img
                        alt
                        title="product-img"
                        class="avatar-md rounded-circle-banner"
                        :src="config.dineinbannerpath+data.item.image"
                         @error="config.basepath+'no-img.png'"
                      />
                    </div>
                    <div v-else>
                      <img
                        class="avatar-md rounded-circle-banner"
                        :src="config.basepath+'no-img.png'"
                        alt
                      />
                    </div>
                  </template>

                  <template v-slot:cell(name)="data">
                    <h5 class="font-size-14">
                      <div v-if="data.item.name > 15">{{data.item.name.substr(0, 15)+ '...'}}</div>
                      <div v-else>{{data.item.name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status === '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        v-if="$auth.hasPermission(config.permissions.banner_write)"
                        :to="{name: 'edit-dine-in-banner',params: { id: base64(data.item.id) }}"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                      <button
                        v-if="$auth.hasPermission(config.permissions.banner_write)"
                        type="submit"
                        class="btn btn-danger btn-sm btn-rounded ml-1"
                        @click="deleteById(base64(data.item.id))"
                      >{{ trans.get('__JSON__.Delete') }}</button>
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Banner') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getBanner"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
   </div>
  </Layout>
</template>
