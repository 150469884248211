<script>
import { get_currency, get_decimal } from '../../../Helper/helper';
export default {
  // props:['order','loading'],
  props:{
    'order':{
      type:Object
    },
    'loading':{
      type:Boolean
    },
    thirdPartyOrder:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return {
      currency: get_currency(),
      decimal: get_decimal(),
    }
  },
  methods:{
    getSelections(items,price){
      return items.length > 0 ? items.reduce((accu, item) => {
        return accu.concat(item.selection_name +' x '+ parseFloat(item.selection_price || price || 0).toFixed(this.decimal));
      },[]).join(', '): '';
    },
    getSelectionPrices(items){
      return parseFloat(items.length > 0 ? items.reduce(function(accu, item){
        return accu + parseFloat(item.selection_price);
      },0): 0);
    },
    mrpPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_mrp || 0)))
    },
    totalPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_price || 0)))
    },
    getOrderItem(){
      return this.order.delivery_pickup_types != 'Dine In' ? this.order.order_item : this.order.order_item_dine;
    }
  }
}
</script>

<template>

   <!-- View Detail Main Start -->
  <div class="row mt-3">
    <div class="col-12">
      <div class="text-center text-danger my-2" v-if="loading">
        <b-spinner class="align-middle"></b-spinner>
        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
      </div>
    </div>
    <div class="table-responsive item-order-main" v-if="!loading">
      <table class="table table-centered table-bordered item-table-main">
          <thead>
            <tr>
              <th scope="col" class="item-name-main">{{trans.get('__JSON__.Item Name')}}</th>
              <th scope="col">{{trans.get('__JSON__.Quantity')}}</th>
              <th scope="col">{{trans.get('__JSON__.Unit Price')}}</th>
              <th scope="col">{{trans.get('__JSON__.Total Price')}}</th>
              <th scope="col">{{trans.get('__JSON__.Original Price')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getOrderItem()" :key="item.id">
              <td>
                {{ 
                  thirdPartyOrder
                  ? item.item_name
                  : item.menu_item && item.menu_item.item_name
                }}
                {{ 
                  !thirdPartyOrder
                  ? getSelections(item.menu_item_selections,item.item_mrp)
                  : ''
                }}
              </td>
              <td align="center">
                <p>{{item.quantity}}</p>
              </td>
              <td align="center">
                <!-- <p><span v-html="currency"></span> {{((parseFloat(item.amount/item.quantity || 0))).toFixed(decimal)}} </p> -->
                <!-- <p><span v-html="currency"></span> {{((parseFloat(item.item_mrp || 0))).toFixed(decimal)}} </p> -->
                
                <!-- <p><span v-html="currency"></span> {{((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0))).toFixed(decimal)}} </p> -->

                <p>
                  <span v-html="currency"></span> 
                  {{ 
                    thirdPartyOrder
                    ? item.item_price 
                    :  ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0))).toFixed(2)
                  }}
                </p>
              </td>
              <td align="center">
                <!-- <p><span v-html="currency"></span> {{ (parseFloat(item.amount || 0)).toFixed(decimal)}} </p> -->
                
                <!-- <p><span v-html="currency"></span> {{mrpPrice(item).toFixed(decimal)}} </p> -->
                
                <p>
                  <span v-html="currency"></span> 
                  {{ 
                    thirdPartyOrder
                    ? item.amount
                    : mrpPrice(item).toFixed(2)
                  }}
                </p>
              </td>
              
              <td align="center">
                <!-- <p><span v-html="currency"></span> {{totalPrice(item).toFixed(decimal)}} </p> -->
                <p><span v-html="currency"></span> {{parseFloat(item.amount || 0).toFixed(2)}} </p>
              </td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.sub_total || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">CGST</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.CGST || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">SGST</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.SGST || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">Packaging Charges</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.total_packaging_charge || 0).toFixed(2)}}</td>
            </tr>
            <tr v-if="order.service_fee > 0">
              <td colspan="4" align="center"><h6 class="text-right mb-0">{{$auth.setting.tax_name_primary_lang}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.service_fee || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Delivery Fees')}}</h6></td>
              <td align="center"><span v-html="currency"></span> {{parseFloat(order.delivery_fee || 0).toFixed(2)}}</td>
            </tr>
            <tr v-if="order.reddemed_coupon_order">
              <td colspan="4" align="right">
                <span class="text-right text-danger">{{trans.get('__JSON__.Discount')}}</span>
                <span class="text-right text-danger"> {{order.reddemed_coupon_order.coupon?' ('+order.reddemed_coupon_order.coupon.coupon_code+')':''}}</span>
              </td>
              <td align="center" class="text-danger">
                <span class="text-danger" v-html="currency"></span>
                <span class="text-danger">{{parseFloat((order.reddemed_coupon_order.original_price - order.reddemed_coupon_order.discount_price) || 0).toFixed(2)}}</span>
              </td>
            </tr>
            <tr>
              <td colspan="4" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.total_amount || 0).toFixed(2)}}</td>
            </tr>
          </tbody>
        </table>
    </div>
    <!-- View Detail Main End -->

  </div>
</template>
