<script>
import config from "../../config";
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import moment from 'moment';
import PayShow from './chowman-show';
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { ChowmanPayService, deliveryZoneService } from "../../services";
import { base64, debounce, error_message, success_message,date_ranges } from "../../Helper/helper";


export default {
  components: { Layout, PageHeader, pagination, moment , PayShow,DateRangePicker},
  data() {
    let startDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().startOf('day').toDate();
    let endDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().endOf('day').toDate();
    const { payment_status,status, orderBy, sortBy } = this.$route.query;
    return {
      dateRange: { startDate, endDate },
      sortBy: sortBy || "created_at",
      sortDesc: orderBy && orderBy != "" ? true : orderBy,
      chowmanPays: null,
      chowmanPayData: [],
      chowmanPayCount: "",
      paginations: null,
      payment_status: payment_status || "all",
      limit: 2,
      search: "",
      status: status == undefined ? "0" : status,
      config: config,
      moment: moment,
      loading: true,
      init: true,
      showModal:false,
      date_ranges: date_ranges,
      pay_id:0,
      datefilters: {
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD')
      },
      searchData: debounce(() => {
        if (!this.init) {
          const { status, orderBy, sortBy } = this.$data;
          this.$router.replace({
            name: "chowman-pay-list",
            query: { status, orderBy, sortBy },
          });
        }
        this.getChowmanPay();
        // this.chowmanPayExportCsv();
        this.init = false;
      }, 500),
      fields: [
        { key: "id", label: "ID#", sortable: true },
        { key: "customer", label: this.trans.get("__JSON__.Customer"), sortable: true, },
        { key: "restaurant_name", label: this.trans.get("__JSON__.Restaurant"), sortable: true, },
        { key: "payment_id", label: this.trans.get("__JSON__.Payment Id"), sortable: true, },
        { key: "unique_code", label: this.trans.get("__JSON__.Unique Code"), sortable: true, },
        { key: "payment_status", label: this.trans.get("__JSON__.Payment Status"), sortable: true, },
        // { key: "order_id", label: this.trans.get("__JSON__.Order Id"), sortable: true, },
        { key: "total_amount", label: this.trans.get("__JSON__.Total Amount"), sortable: true, },
        { key: "default_cashback", label: this.trans.get("__JSON__.Default Cashback"), sortable: true, },
        { key: "promocode_discount", label: this.trans.get("__JSON__.Promocode Discount"), sortable: true, },
        { key: "coupon_name", label: this.trans.get("__JSON__.Promocode"), sortable: true, },
        { key: "final_amount", label: this.trans.get("__JSON__.Final Amount"), sortable: true, },
        { key: "wallet_price", label: this.trans.get("__JSON__.Wallet Paid Amount"), sortable: true, },
        // { key: "created_at", label: this.trans.get("__JSON__.Created At"), sortable: true, },
        { key: "online_paid_amount", label: this.trans.get("__JSON__.Online Paid Amount"), sortable: true, },
        { key: "action", label: this.trans.get("__JSON__.Action"), sortable: true, },
        
      ],
    };
  },
  mounted() {
    this.getChowmanPay(0);
    this.dateRange =this.changeSettingRange();
    this.datefilters = {
      ...this.datefilters,
      start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
    }
  },
  computed:{
    getSelected:function(){
      const { start_date, end_date } = this.datefilters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
  watch: {
    "$route.query": function(){
      this.dateRange =this.changeSettingRange();
      this.datefilters = {
        ...this.datefilters,
        start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }
     
    }
  },
  methods: {
    viewDetails(id){
      this.showModal = true;
      this.pay_id = id;
    },
    dateFormat(classes, date) {
      this.datefilters = {
        ...this.datefilters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
    },
    changeSettingRange(){
      var now = this.moment();
      var then = this.moment();
      if(this.$auth && this.$auth.setting){
        var range = this.$auth.setting.order_dashboard_default_time;
        var date_ranges = this.date_ranges();
        var keys = Object.keys(date_ranges)
        switch(range){
          case "0":
            return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
          case "1":
            return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
          case "6":
            return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
          case "29":
            return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
          case "365":
            return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
          case "101":
            return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
          default:
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        }
      }
      return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
    },
  
    onRowClicked(item,index, event) {
     console.log('item' ,item);
     this.viewDetails(item.id);
    },
    downloadsCSV: function () {
      let chowmanPayData =
        "\ufeff" +
        "ID#,Customer,Restaurant,Payment Id,Unique Code ,Payment Status,Total Amount,Default Cashback,Promocode Discount,Final Amount,Promocode,Wallet Paid Amount, Online Paid Amount ,Cashback Price ,Created At \n";
      
        this.chowmanPayData.forEach((el) => {
        var line =  el["id"] + "," +
                    el["customer"] + "," +
                    (el["restaurant_name"] ? el["restaurant_name"] : "-").replaceAll("null", "-") + "," +
                    (el["payment_id"] ? el["payment_id"] : "-").replaceAll("null", "-") + "," +
                    (el["unique_code"] ? el["unique_code"] : "-").replaceAll("null", "-") + "," +
                    ((el["payment_status"] == 'Completed' && el["refunded"] == '1') ? 'Refunded' : el["payment_status"]).replaceAll("null", "-") + "," +
                    el["total_amount"] + "," +
                    (el["default_cashback"] ? el["default_cashback"] : "0").replaceAll("null", "0") + "," +
                    (el["promocode_discount"] ? el["promocode_discount"] : "0").replaceAll("null", "0") + "," +
                     el["final_amount"] + "," +
                    (el["coupon_code"] ? el["coupon_code"] : "-").replaceAll(",", " ") + "," +
                    (el["wallet_price"] ? el["wallet_price"] : "0").replaceAll("null", "0") + "," +
                    (el["online_paid_amount"] ? el["online_paid_amount"] : "0").replaceAll("null", "0") + "," +
                    (el["cashback_price"] ? el["cashback_price"] : "0").replaceAll("null", "0") + "," +
                    (el["created_at"] ? el["created_at"] : "-").replaceAll("null", "-") +
            "\n";
                    
        chowmanPayData += line;
      });
        
        

      var blob = new Blob([chowmanPayData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = 'chowman_pay' + "_" + date + ".csv";
      link.click();
    },
    updateValues(date) {
      this.is_export_button=false;
      this.datefilters = {
        ...this.datefilters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
     this.getChowmanPay();
    },
    getChowmanPay(page=1) {
      this.loading = true;
      var filters = {
        ...this.datefilters,
        payment_status: this.payment_status,
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        page: page && page > 0 ? page : 1,
      };
      ChowmanPayService.getChowmanPay(filters).then((response) => {
        this.loading = false;
        this.paginations = response.data.result;
        this.chowmanPays = response.data.result.data;
        this.chowmanPayCount = response.data.result.total;
      });
    },
    chowmanPayExportCsv() {
      ChowmanPayService
        .chowmanPayExportCsv({
          ...this.datefilters,
          payment_status: this.payment_status,
          search: this.search,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
        })
        .then((response) => {
          this.chowmanPayData = response.data.result.data;
          this.downloadsCSV()
        });
    },

    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getChowmanPay();
    },
    makeRefund(id){
      if(confirm('Are you sure you want to refund this payment?')){
        ChowmanPayService.refundPayment({id:id})
          .then(response => {
            this.getChowmanPay(1)
            if(response.data.code == 200){
              success_message(response.data.message)
            }else{
              error_message(response.data.message)
            }
          });
      }
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Chowman Pay', 'Chowman Pay')
            }}({{ chowmanPayCount }})</h4>
          </div>
          <div class="page-title-right d-flex">

            <button @click="chowmanPayExportCsv" 
            v-if="$auth.hasSubAdminPermission(config.permissions.chowman_pay_export)"
            class="btn btn-primary btn-rounded export-btn">{{
                trans.get('__JSON__.Export To CSV')
            }}</button>
            <div class="d-flex m-vendor-btn">

              <button @click="downloadsCSV" 
              v-if="$auth.hasSubAdminPermission(config.permissions.chowman_pay_export)"
              class="btn btn-primary btn-rounded m-export-btn">
                <i class="bx bx-archive-in"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-5 pull-right"></div>
              <div class="col-sm-3 pull-right">
                <div class="mt-4">
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          class="w-100"
                          :ranges="date_ranges()"
                          @update="updateValues"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') }} -
                            {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') }}
                          </template>
                        </date-range-picker>
                        </div>
                    </div>
              <div class="col-sm-2">
               
                <div>
                  <b-form-group  id="status-1"  :label="trans.get('__JSON__.Status')" label-for="status"  >
                    <b-form-select v-model="payment_status" :options="[
                      {value: 'all', text: 'All'},
                      {value: 'Completed', text: 'Completed'},
                      {value: 'Failed', text: 'Failed'},
                      {value: 'Pending', text: 'Pending'},
                      {value: 'refunded', text: 'Refunded'},
                    ]" @input="getChowmanPay">
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Search') }}</label>
                  <div class="search-box mr-2">
                    <div class="position-relative">
                      <input type="text" class="form-control" id="search" @input="searchData()" v-model="search"
                        :placeholder="this.trans.get('__JSON__.Search')" />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list table-responsive">
                <b-table :items="chowmanPays && chowmanPays || []" :fields="fields" :sort-by.sync="sortBy"
                  :no-local-sorting="true" :sort-desc.sync="sortDesc" @sort-changed="sortingChanged" :busy="loading"
                  show-empty @row-clicked="onRowClicked">
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
                    </div>
                  </template>

                  <template v-slot:cell(unique_code)="data">
                    <div  class="badge badge-pill badge-soft-dark  font-size-15">
                      {{  data.item.unique_code  }}
                    </div>
                  </template>

                  <template v-slot:cell(payment_status)="data">

                    <div v-if="data.item.payment_status == 'Completed' && data.item.refunded == '1'"
                      class="badge badge-pill badge-soft-secondary font-size-12">
                      {{ trans.get('__JSON__.Refunded') }}
                    </div>

                    <div v-else-if="data.item.payment_status == 'Completed'"
                      class="badge badge-pill badge-soft-success font-size-12">
                      {{ trans.get('__JSON__.Completed') }}
                    </div>

                    <div v-else-if="data.item.payment_status == 'Failed'"
                      class="badge badge-pill badge-soft-danger font-size-12">
                      {{ trans.get('__JSON__.Failed') }}
                    </div>

                    <div v-else-if="data.item.payment_status == 'Pending'"
                      class="badge badge-pill badge-soft-warning font-size-12">
                      {{ trans.get('__JSON__.Pending') }}
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button 
                      @click="makeRefund(data.item.id)"
                      v-if="data.item.refunded == '0' && data.item.payment_status === 'Completed'" 
                      variant="warning" size="sm" rounded
                      >Refund</b-button>
                    <span v-else>-</span>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Chowman Pay Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0" v-if="paginations">
                      <!-- pagination -->
                      <pagination :data="paginations" :limit="limit" @pagination-change-page="getChowmanPay">
                      </pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PayShow v-if="showModal"  :order-id="pay_id" @hidden="showModal = false" />
  </div>
</template>
<style scoped>
.exporbtn{
  float:right;
  position: relative;
}

</style>