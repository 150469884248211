<script>
import Layout from "../../layouts/main";
import config from "../../config";
import PageHeader from "../../components/page-header";
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import DropzonePopup from '../../components/widgets/dropzone-popup';
import { upcomingTasks, progressTasks, completedTasks } from "./catalog.js";
import { menuService, vendorService } from "../../services";
import { required,requiredIf,numeric,helpers} from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import DatePicker from "vue2-datepicker";
import { error_message, success_message, debounce, get_currency, get_user} from "../../Helper/helper";
import { routes } from '../../config/api_routes';
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,2}|\d+)$/)


/**
 * Kanban-board component
 */
export default {
  components: { Layout, PageHeader, draggable, DropzonePopup,routes,pagination,DatePicker,Multiselect},
  data() {
    return {
        debounce: false,
        title: "Catalog",
        menu_id: 1,
        config: config,
        showModal: false,
        itemModal: false,
        checked: true,
        limit:2,
        editMenuModal: false,
        editItemModal: false,
        imageModal: false,
        importModal: false,
        itemImportModal: false,
        menuItemExport: false,
        multiple: false,
        loading: true,
        search:'',
        menu: [],
        items: [],
        slots: [],
        restaurant_id: "",
        restaurant: [],
        category_count:'',
        item_count:'',
        slot_count:'',
        paginations:{},
        menu_item_time:'',
        currency: get_currency(),
        item_menu_name:"",
        sload:false,
        typeform: {
          menu_name: "",
          status: "1",
          image: "",
          menu_name_thai:"",
          parent_menu_id:"",
          selectedTag: [],
        },
        res_menu: {
          id: "",
          menu_name: "",
          status: "",
          image: "",
          menu_name_thai: "",
          parent_menu_id:""
        },
        typeForm:{
          item_name:"",
          item_name_thai:"",
          item_description:"",
          item_description_thai:"",
          is_available:"1",
          is_featured:"0",
          price :"",
          item_type:"2",
          quantity:"",
          is_selling_time_slot:"0",
          qty_reset:"Never",
          is_wholeday:"1",
          start_date:"",
          restaurant_menu_id:"",
          selling_time:"",
          restaurant_id : this.$route.params.restaurant_id,
          mrp:"",
          tags:[],
          packaging_charge: 0,
          edit_tag: [],
        },
        menu_item:{
          item_name:"",
          item_name_thai:"",
          item_description:"",
          item_description_thai:"",
          is_available:"1",
          is_featured:"",
          price :"",
          item_type:"2",
          quantity:"",
          is_selling_time_slot:"0",
          qty_reset:"",
          is_wholeday_edit:"1",
          start_date:"",
          restaurant_menu_id:"",
          selling_time:"",
          mrp:"",
          tags:[],
          packaging_charge: 0
        },
        menu_item_image:{
          image_name:[],
          restaurant_menu_item_id:"",
          restaurant_id:"",
        },
        res_image : "",
        menu_media_path: "",
        menu_item_media_path:"",
        menu_image_delete_url: routes.resImageDeleteUrl,
        menu_item_image_delete_url: routes.menuImageDeleteUrl,
        item_image : [],
        showTimeRangePanel: false,
        import_file : '',
        import_image_zip: '',
        custome_search: "",
        searchData:debounce(()=>{
          this.listitembyid();
        },500),
        category_id: '',
        category : [],
        vendor_id: "",
        tag: [],
        // tag
        // tag
    };
  },
  mounted() {
    this.getTimeSlot();
    this.getmenu();
    this.getMenuCategories();
    this.getRestaurantById();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  watch:{
    'menu_item.is_wholeday' : function(){
    },
    itemModal(){
      this.typeform.selectedTag = "";
    }
  },
  validations: {
    typeform: {
      menu_name: {
        required,
      },
      parent_menu_id: {
        required: requiredIf(function(form){
          return this.category_count > '0';
        }),
      },
    },
    res_menu: {
      menu_name: {
        required,
      },
      parent_menu_id: {
        required: requiredIf(function(form){
          return this.category_count > '0';
        }),
      },
    },
    typeForm:{
      item_name: {
        required,
      },
      price: {
        required,
        floating
      },
      quantity: {
        required,
        numeric
      },
      start_date: {
        required: requiredIf(function(form){
          return this.typeForm.is_selling_time_slot == '1';
        }),
      },
      packaging_charge:{
        floating
      }
    },
    menu_item:{
      item_name: {
        required,
      },
      price: {
        required,
        floating
      },
      quantity: {
        required,
        numeric
      },
      datetime: {
        required: requiredIf(function(form){
          return this.menu_item.is_selling_time_slot == '1';
        }),
      },
      packaging_charge:{
        floating
      }
    },
    import_file: {
      required,
    },
  },
  methods: {
    getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
            pet_pooja_restaurant_id
          } = response.data.result;
          this.restaurant_id = response.data.result.restaurant_id;
          this.restaurant = {
            name,
            restaurant_id,
            pet_pooja_restaurant_id
          };
        }).catch((error) => {
           if(error.response.status == '403') {
              this.$router.push({name: 'vendor-list'});
           }
        });
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    onImagedeleted(value){
        this.res_image = ""
    },
    onMenuImageDeleted(value) {
      console.log(value)
      this.item_image = this.item_image.filter(doc => doc.image_name !== value);
    },
    changeMenu(id){
      this.menu_id = id;
      this.typeForm.restaurant_menu_id = id;
      this.listitembyid(1);
    },
    listitembyid(page=1) {
      this.loading = true;
      vendorService.getItemByMenuID({ 
        menu_id: this.menu_id,
        search: this.search,
        page: page && page > 0 ? page : 1,
        restaurant_id: this.$route.params.restaurant_id,
        category_id : this.category_id
      }).then((response) => {
        this.loading = false;
        this.item_count = response.data.result.get_menus_item.data.length;
        this.items = response.data.result.get_menus_item.data;
        this.paginations = response.data.result.get_menus_item;
        this.menu = response.data.result.get_restaurant_menu;
        this.tag = response.data.result.get_restaurant_menu_tag;
      });
    },
    getmenu() {
      console.log(this.category_id);
      vendorService
      .getRestaurantMenu({ restaurant_id: this.$route.params.restaurant_id, category_id : this.category_id })
      .then((response) => {
        this.menu = response.data.result;
        if( response.data.result.length > 0){
          this.menu_id = this.menu[0].id;
          this.typeForm.restaurant_menu_id = this.menu[0].id;
          this.listitembyid();
        }else{
          this.menu_id = '';
          this.listitembyid();
        }
      });
    },
    getMenuCategories() {
      vendorService
        .getMenuCategories({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          this.category_count = response.data.result.length;
          this.category = response.data.result;
        });
    },
    addMenu() {
        this.$v.typeform.$touch();
        if (this.$v.typeform.$invalid) {
            return;
        } else {
            const fd = new FormData();
            fd.append("menu_name", this.typeform.menu_name);
            fd.append("status", this.typeform.status);
            fd.append("image", this.typeform.image);
            fd.append("menu_name_thai", this.typeform.menu_name_thai);
            fd.append("restaurant_id", this.$route.params.restaurant_id);
            fd.append("parent_menu_id", this.typeform.parent_menu_id);
            vendorService.addMenu(fd).then((response) => {
            if (response.data.code === 200) {
                success_message(response.data.message);
                this.typeform ={
                  menu_name: "",
                  status: "1",
                  image: "",
                  menu_name_thai:"",
                  parent_menu_id:""
                },
                this.showModal = false;
                this.$v.typeform.$reset();
                this.getmenu();
            } else {
              error_message(response.data.message);
            }
          });
        }
    },
    fetchMenu(id) {
        return vendorService.getMenuById({ menu_id: id }).then((response) => {
            if (response.data.code === 200) {
                const{id, restaurant_id, menu_name, menu_name_thai, menu_description, image, status,parent_menu_id} = response.data.result;
                this.res_menu = {id, restaurant_id, menu_name, menu_name_thai, menu_description, status,parent_menu_id};
                this.res_image = response.data.result.image;
                this.menu_media_path = response.data.result.menu_media_path;
                this.editMenuModal = true;
            } else {
                this.$toasted.error(response.data.message);
            }
        });
    },
    updateMenu(e) {
      this.$v.res_menu.$touch();
      if (this.$v.res_menu.$invalid) {
        return;
      }
      else{
        vendorService.editMenu(this.res_menu)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.editMenuModal = false;
            this.getmenu();
            this.$router.push({ name: "vendor-catalog" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    onChangeEventHandler(e,id){
        this.status = e;
        this.id = id;
        vendorService.updateMenuStatus({status:this.status, id:this.menu_id})
        .then((response) => {
            if (response.data.code === 200) {
                success_message(response.data.message);
            } else {
                error_message(response.data.message);
            }
        });
    },
    deleteMenuWithItem(){
      if(confirm("Do you really want to delete?")){
        vendorService.deleteMenuWithItem({id:this.menu_id,restaurant_id: this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            this.listitembyid();
            this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    menuItemAdd(e) {
      const buttonValue = e.target.value;
      this.$v.typeForm.$touch();
      if (this.$v.typeForm.$invalid) {
        return;
      } else {
        let itemtag = [];
        if (this.typeform.selectedTag && this.typeform.selectedTag.length > 0) {
          itemtag = this.typeform.selectedTag.map(tag => tag.id)
        } 
        vendorService.addItmeMenu({...this.typeForm, tag: itemtag})
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            if(buttonValue == 0){
              this.typeForm = {
                item_name:"",
                item_name_thai:"",
                item_description:"",
                item_description_thai:"",
                is_available:"1",
                is_featured:"0",
                price :"",
                item_type:"2",
                quantity:"",
                is_selling_time_slot:"0",
                qty_reset:"Never",
                is_wholeday:"1",
                start_date:"",
                selling_time:"",
                mrp:"",
                packaging_charge: 0,
                restaurant_id : this.$route.params.restaurant_id,
                restaurant_menu_id : this.typeForm.restaurant_menu_id,
              };
              this.typeform.selectedTag = "";
            }else{
              this.typeForm = {
                item_name:"",
                item_name_thai:"",
                item_description:"",
                item_description_thai:"",
                is_available:"1",
                is_featured:"0",
                price :"",
                item_type:"2",
                quantity:"",
                is_selling_time_slot:"0",
                qty_reset:"Never",
                is_wholeday:"1",
                start_date:"",
                selling_time:"",
                mrp:"",
                restaurant_id : this.$route.params.restaurant_id,
                restaurant_menu_id : this.typeForm.restaurant_menu_id,
                packaging_charge: 0
              };
              this.typeform.selectedTag = "";
              this.itemModal = false;
              this.getmenu();
            }
            this.$v.typeForm.$reset();
            this.listitembyid();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    itemModalClose(e){
      this.itemModal = false;
      this.typeForm = {
        item_name:"",
        item_name_thai:"",
        item_description:"",
        item_description_thai:"",
        is_available:"1",
        is_featured:"0",
        price :"",
        item_type:"2",
        quantity:"",
        is_selling_time_slot:"0",
        qty_reset:"Never",
        is_wholeday:"1",
        start_date:"",
        selling_time:"",
        packaging_charge: 0,
        restaurant_id : this.$route.params.restaurant_id,
        restaurant_menu_id : this.typeForm.restaurant_menu_id,
      };
      this.listitembyid();
      this.getmenu();
      this.$v.typeForm.$reset();
    },
    getTimeSlot(){
      vendorService.getResTimeSlot({id:this.$route.params.restaurant_id})
      .then(response => {
        if (response.data.code === 200) {
          this.slot_count = response.data.result.length;
          this.slots = response.data.result;
        } else {
          this.slots = [];
          error_message(response.data.message);
        }
      });
    },
    getMenuItemById(id){
      vendorService.getMenuItem({id:id, restaurant_id: this.$route.params.restaurant_id})
      .then(response => {
        const{restaurant_menu_item_id,restaurant_menu_id, item_name,item_name_thai, item_description, item_description_thai,is_available,is_featured,price,item_type, quantity, is_selling_time_slot, qty_reset, start_date, selling_time,mrp,time_slot,datetime,time,is_wholeday,tags, packaging_charge} = response.data.result;
        this.menu_item = {restaurant_menu_item_id, restaurant_menu_id, item_name,item_name_thai, item_description, item_description_thai,is_available,is_featured,price,item_type, quantity, is_selling_time_slot, qty_reset, start_date, selling_time,mrp,is_wholeday,packaging_charge};
        this.menu_item.datetime = response.data.result.datetime.split("~");
        this.menu_item.time = response.data.result.time;
        this.menu_item.is_wholeday = response.data.result.is_wholeday;
        this.menu_item.restaurant_id = this.$route.params.restaurant_id;
        this.menu_item.tags = tags && tags != ''?JSON.parse(tags):[];
        this.editItemModal = true;
        this.menu_item.is_wholeday_edit = is_wholeday;
        this.menu_item = {...this.menu_item};
        this.menu_item.edit_tag = response.data.result.tag;
      });
    },
    deleteMenu(id){
      if(confirm("Do you really want to delete?")){
        vendorService.deleteMenu({id:id,restaurant_id: this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    menuItemEdit(e) {
      this.$v.menu_item.$touch();
      if (this.$v.menu_item.$invalid) {
        return;
      }
      else{
        vendorService.menuItemEdit({...this.menu_item, is_wholeday:this.menu_item.is_wholeday_edit, edit_tag: this.menu_item.edit_tag.map(tag => tag.id)})
        .then(response => {
          if (response.data.code === 200) {
            this.editItemModal = false;
            this.listitembyid();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    deleteMenuItem(id){
      if(confirm("Do you really want to delete?")){
        vendorService.deleteMenuItem({id:id,restaurant_id :this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            this.listitembyid();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    addItemImage(id){
      this.imageModal = true;
      this.menu_item_image.restaurant_menu_item_id = id;
      this.menu_item_image.restaurant_id = this.$route.params.restaurant_id;
      vendorService.getMenuImage({id:id, restaurant_id: this.$route.params.restaurant_id}).then(response => {
        if(response.data.result.item_image.length > 0){
          const{id,image_name,restaurant_id,restaurant_menu_item_id,item_image} = response.data.result;
          this.menu_item_media_path = response.data.result.item_image[0].menu_media_path;
          this.item_image = response.data.result.item_image;

          this.menu_item_image = {
            id,
            restaurant_id,
            restaurant_menu_item_id,
            image_name:'',
            image_name:[],
          }
        }else{
          this.menu_item_media_path = response.data.result.menu_item_media_path;
          this.item_image = [];
        } 
      });
    },
    addMenuImage(){
      this.menu_item_image.restaurant_menu_item_id = this.menu_item_image.restaurant_menu_item_id
      this.menu_item_image.restaurant_id = this.$route.params.restaurant_id;

      vendorService.addMenuImage(this.menu_item_image)
      .then(response => {
        this.menu_item_image.image_name = [];
        if (response.data.code === 200) {
          this.imageModal = false;
          this.listitembyid();
          // this.getmenu();
          success_message(response.data.message);
        } else {
          error_message(response.data.message);
        }
      });
    },
    filterInput(e){
      const key = e.key;

      // If is '.' key, stop it
      if (key === '.')
        return e.preventDefault();
      
      // OPTIONAL
      // If is 'e' key, stop it
      if (key === 'e')
        return e.preventDefault();
    },
    Uploadfile(){
      this.importModal = true;
    },
    importMenuItem(e){
      this.$v.import_file.$touch();
      if (this.$v.import_file.$invalid) {
        return;
      } else {
        vendorService.importMenuItem({import_file:this.import_file,import_image_zip: this.import_image_zip,restaurant_id :this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            this.importModal = false;
            this.listitembyid(this.menu_id);
            this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    updateMenuOrder: function() {
      var items = this.menu.map(function(item, index) {
        return { item: item, order: index }
      })
      if(this.debounce) return
      var ids = items.map(item => item.item.id);

      this.debounce = setTimeout(function(items) {
        this.debounce = false;
        vendorService.updateMenuOrder({ menu_ids : ids})
        .then(response => {
          if (response.data.code === 200) {
            this.listitembyid();
            //this.getmenu();
          }
        });
      }.bind(this, items), 10)
    },
    updateItemOrder: function() {
      var items = this.items.map(function(item, index) {
        return { item: item, order: index }
      });

      if(this.debounce) return
      var ids = items.map(item => item.item.restaurant_menu_item_id);

      this.debounce = setTimeout(function(items) {
        this.debounce = false;
        vendorService.updateItemOrder({ menu_item_ids : ids})
        .then(response => {
          if (response.data.code === 200) {
            this.listitembyid();
            //this.getmenu();
          }
        });
      }.bind(this, items), 10)
    },
    updateItemAvailability(event,id){
      var availability = event.target.value;
      console.log(availability);
      vendorService.updateItemAvailability({ is_available:availability, id:id, restaurant_id: this.$route.params.restaurant_id})
        .then((response) => {
            if (response.data.code === 200) {
                success_message(response.data.message);
            } else {
                error_message(response.data.message);
            }
        });
    },
    someHandler(event){
      this.typeForm.mrp = event.target.value;
    },
    addMrp(event){
      this.menu_item.mrp = event.target.value;
    },
    syncDatabase(){
      this.sload = true;
      vendorService.syncDatabase({pet_pooja_restaurant_id: this.restaurant.pet_pooja_restaurant_id}).then(() => {
        success_message('Sync Complete');
        this.sload = false;
        this.getmenu();
        this.getMenuCategories();
      })
    },
    clearApiCache(){
      this.sload = true;
      vendorService.clearApiCache({restaurant_id: this.restaurant.restaurant_id}).then(() => {
        success_message('Refresh Complete');
        this.sload = false;
      })
    },
    menuClose(e){
      this.showModal = false;
      this.typeform ={
        menu_name: "",
        status: "1",
        image: "",
        menu_name_thai:"",
        parent_menu_id:""
      },
      this.getmenu();
      this.$v.typeform.$reset();
    },
    menuItemImport(){
      this.$v.import_file.$touch();
      if (this.$v.import_file.$invalid) {
        return;
      } else {
        vendorService.importMenuItem({import_file:this.import_file,import_image_zip: this.import_image_zip,restaurant_id :this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {

            this.importModal = false;
            this.itemImportModal = false;
            this.menuItemExport = false;
            this.listitembyid(this.menu_id);
            this.getmenu();
            
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    deleteRestaurantAllMenu(){
      if(confirm("Do you really want to delete all menus?")){
        menuService.deleteRestaurantAllMenu({restaurant_id: this.$route.params.restaurant_id})
        .then(response => {
          if (response.data.code === 200) {
            // this.listitembyid();
            this.getmenu();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    clearAll() {
      this.typeform.selectedTag = [];
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page" >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-store"></i>
              </span>
            </div>
            <div class="report-tag" v-if="!$auth.partner_login">
              <h4 class="mb-0 font-size-18">{{ restaurant.name }}</h4>
              
              <!-- <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: $route.params.restaurant_id}}">{{ trans.trans('__JSON__.Catalog') }}</router-link>  >  {{ restaurant.name }}</p> -->
              <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > {{ trans.get('__JSON__.Catalog') }}</p>
            </div>
            <div v-else>
              <h4 class="mb-4 font-size-18">{{ restaurant.name }}</h4>
            </div>
          </div>
          <div class="d-flex" v-if="!$auth.partner_login">
            <!-- <b-button :disabled="sload" variant="primary" class="mr-2" pill @click="syncDatabase">
              <b-icon icon="arrow-clockwise" :animation="sload?'spin':''" ></b-icon>
            </b-button> -->
            <b-button :disabled="sload" variant="primary" class="mr-2" pill @click="clearApiCache">
              Refresh
            </b-button>
            <div class="page-title-right">
              <b-dropdown right variant="white" class="float-right" toggle-class="p-0">
                <template slot="button-content">
                  <button class="btn btn-primary btn-rounded">{{ trans.get('__JSON__.Import Items') }}</button>
                </template>

                <b-dropdown-item v-if="$auth.hasPermission(config.permissions.merchant_write)" @click="Uploadfile()">{{ trans.get('__JSON__.Fresh Import Items') }}</b-dropdown-item>
                <!-- <b-dropdown-item v-if="$auth.hasPermission(config.permissions.merchant_write)" @click="menuItemExport = true">{{ trans.get('__JSON__.Update Items') }}</b-dropdown-item> -->

              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="drag-container">
      <div class="row drag-list menu-left-main">
        <div class="col-lg-4 drag-column menu-box-main">
          <div class="card">
            <div class="card-body menu-body">
              <div class="menu-box">
                <!-- dropdown -->
                <div class="menu-add">
                  <div right variant="white" class="float-right" toggle-class="p-0" v-if="$auth.hasPermission(config.permissions.merchant_write)">
                    <button class="btn btn-danger btn-rounded mb-0 mr-0" @click="deleteRestaurantAllMenu">
                      Delete All Menu
                    </button>
                    <button class="btn btn-success btn-rounded mb-0 mr-0" @click="showModal = true" v-if="!$auth.partner_login">
                      <i class="mdi mdi-plus mr-1"></i> Add
                    </button>
                  </div>
                  <span class="drag-column-header">
                    <h4 class="card-title mb-4 pb-1">Menu</h4>
                  </span>
                </div>

                <div class="mt-3" v-if="category_count > 0">
                  <div class="form-group">
                    <!-- <label class="control-label">{{ trans.get('__JSON__.Category') }}</label> -->
                      <select class="custom-select" v-model="category_id" @change="getmenu()">
                        <option value="" selected>All</option>
                       <option v-for="con in category" :value="con.id" :key="con.id">{{con.name}}</option>
                      </select>
                  </div>
                </div>

                <draggable class="list-group menu-task" group="menus" :list="menu" @end="updateMenuOrder" v-model="menu">
                  <template v-for="menus in menu">
                  <div
                    :key="menus.id"
                    v-on:click="changeMenu(menus.id)"
                    class="card task-box"
                    :class="{ 'active': menu_id === menus.id }"
                    v-if="(search !== '' && menus.item_count !== 0) || (search == '')"
                  >
                    <div class="card-body">
                      <b-dropdown right variant="white" class="float-right" toggle-class="p-0"  v-if="$auth.hasPermission(config.permissions.merchant_write) && !$auth.partner_login">
                        <template slot="button-content">
                          <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                        </template>
                        <b-dropdown-item @click="fetchMenu(menus.id)"  v-if="!$auth.partner_login">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> {{ trans.get('__JSON__.Edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteMenu(menus.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Delete
                        </b-dropdown-item>
                      </b-dropdown>
                      
                      <div class="float-right swtich-task check-status">
                        <b-form-checkbox
                            v-if="$auth.hasPermission(config.permissions.merchant_write)"
                            v-model="menus.status"
                            switch
                            v-bind:id="menus.id.toString()"
                            @change="onChangeEventHandler($event,menus.id)"
                            value="1"
                            unchecked-value="0"
                        ></b-form-checkbox>
                      </div>
                      <div>
                        <h5 class="font-size-15 title">{{ menus.menu_name }}({{menus.item_count}})</h5>
                      </div>
                      <p
                        class="badge badge-pill font-size-12" v-bind:id="menus.id" 
                        :class=" { 
                        'badge-soft-success': (menus.status == '1'),
                        'badge-soft-danger': (menus.status == '0' )}"
                        >
                        <span v-if="menus.status == '0'">InActive</span>
                        <span v-else>Active</span>
                      </p>
                    </div>
                  </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->

        <div class="col-lg-8 drag-column">
          <div class="card">
            <div class="card-body catalog-main">
              <div class="catalog-item">
                <span class="drag-column-header">
                  <h4 class="card-title">Item</h4>
                </span>
                <div class="item-menu-main">
                  <div class="search-box mr-2 mb-2 d-inline-block">
                    <div class="position-relative">
                      <input type="text" class="form-control" id="search" @keyup="searchData()" v-model="search" :placeholder="this.trans.get('__JSON__.Search')" />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                  <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write) && !$auth.partner_login"
                    type="button"
                    class="btn btn-success btn-rounded mb-2 mr-2"
                    @click="itemModal = true"
                  >
                    <!-- <i class="mdi mdi-plus mr-1"></i>  -->
                    Add
                  </button>
                  <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write) && !$auth.partner_login"
                    type="button" class="btn btn-danger btn-rounded mb-2 mr-2" @click="deleteMenuWithItem();">
                    <!-- <i class="mdi mdi-trash-can mr-1"></i>  -->
                    {{ trans.get('__JSON__.Delete') }}
                  </button>

                  <!-- <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write)"
                    type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="downloadsCSV();">
                    {{ trans.get('__JSON__.Export') }}
                  </button> -->

                  <!-- <button
                    v-if="$auth.hasPermission(config.permissions.merchant_write)"
                    type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="itemImportModal = true">
                    {{ trans.get('__JSON__.Bulk Update') }}
                  </button> -->
                </div>
              </div>
              <div class="left-card">
                <!-- dropdown -->
                <draggable class="list-group" group="item" :list="items" @end="updateItemOrder" v-model="items">
                  <!-- <div class="col-12">
                    <div class="text-center text-danger my-2" v-if="loading">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </div> -->

                  <div
                    v-for="item in items"
                    :key="item.restaurant_menu_item_id"
                    class="card task-box"
                  >
                    <div class="card-body menu-items">
                      <div class="media">
                        <div class="avatar-md mr-4">
                          <span class="text-danger font-size-16" v-bind:id="item.restaurant_menu_item_id" >
                          <div v-if="item.item_image.length > 0">
                            <img class="vendor-img avatar-xs" :src="`${item.item_image[0].item_image_path}`" alt height="30" @error="$event.target.src=config.basepath+'no-img.png'" @click="addItemImage(item.restaurant_menu_item_id)"/>
                          </div>
                          <div v-else>
                            <img class="vendor-img avatar-xs" :src="config.basepath+'no-img.png'" alt @click="addItemImage(item.restaurant_menu_item_id)"/>
                          </div>
                          </span>
                        </div>
                        <div v-if="item.item_type == '0'" class="veg"><span></span></div>
                        <div v-if="item.item_type == '1'" class="non-veg"><span></span></div>
                        <div class="media-body overflow-hidden">
                          <h5 class="font-size-15">{{item.item_name | truncate(35, '...')}}</h5>
                          <!-- <p class="text-truncate mb-4 overflow-hidden">{{item.item_description | truncate(50, '...')}}</p> -->
                          <ul class="list-inline">
                            <li v-b-tooltip.hover.top class="list-inline-item">Qty : {{item.quantity}}</li>
                              <li v-b-tooltip.hover.top class="list-inline-item">
                                <span v-html="currency"></span>
                                {{item.price}}
                              </li>
                          </ul>
                        </div>
                        <div class="task-status catalog-task-status newset-media">
                      	  
                        <div class="cst-swtch-set">
                          <div class="list-available">
                            <span v-if="item.customization.length != 0" class="badge badge-pill font-size-12 badge-soft-primary">Customized</span>
                          </div>
                          <div v-if="!$auth.partner_login">
                              <b-dropdown right variant="white" class="float-right" toggle-class="p-0"  v-if="$auth.hasPermission(config.permissions.merchant_write) && !$auth.partner_login">
                              <template slot="button-content">
                                <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                              </template>
                              <b-dropdown-item v-if="item.customization.length == 0 " :to="{name: 'vendor-add-customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}}">
                                <i class="fas fa-plus text-success mr-1"></i> Add Customization
                              </b-dropdown-item>
                              <b-dropdown-item v-else :to="{name: 'vendor-add-customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}}">
                                <i class="fas fa-eye text-success mr-1"></i> View Customization
                              </b-dropdown-item>
                              <b-dropdown-item @click="getMenuItemById(item.restaurant_menu_item_id)">
                                <i class="fas fa-pencil-alt text-success mr-1"></i> Edit
                              </b-dropdown-item>
                              <b-dropdown-item @click="deleteMenuItem(item.restaurant_menu_item_id)">
                                <i class="fas fa-trash-alt text-danger mr-1"></i> Delete
                              </b-dropdown-item>
                            </b-dropdown>
                            <b-dropdown right variant="white" class="float-right" toggle-class="p-0"  v-else>
                              <template slot="button-content">
                                <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                              </template>
                              <b-dropdown-item v-if="item.customization.length == 0" :to="{name: 'vendor.vendor.add.customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}}">
                                <i class="fas fa-plus text-success mr-1"></i> Add Customization
                              </b-dropdown-item>
                              <b-dropdown-item v-else :to="{name: 'vendor.vendor.add.customization',params: { menu_item_id: base64(item.restaurant_menu_item_id)}}">
                                <i class="fas fa-eye text-success mr-1"></i> View Customization
                              </b-dropdown-item>
                              <b-dropdown-item @click="getMenuItemById(item.restaurant_menu_item_id)">
                                <i class="fas fa-pencil-alt text-success mr-1"></i> Edit
                              </b-dropdown-item>
                              <b-dropdown-item @click="deleteMenuItem(item.restaurant_menu_item_id)">
                                <i class="fas fa-trash-alt text-danger mr-1"></i> Delete
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          </div>
                           <div class="list-available cst-small-swtch">
                            <b-form-group id="input-group-1">
                              <label class="switch">
                                <input type="checkbox" 
                                  id="togBtn" 
                                  name="is_available" 
                                  :value="item.is_available"
                                  v-model="item.is_available"
                                  class="switch-on" 
                                  true-value="1" 
                                  false-value="0"
                                  @change="updateItemAvailability($event,item.restaurant_menu_item_id)"/>
                                <div class="slider round">
                                  <span class="on"></span>
                                  <span class="off"></span>
                                </div>
                              </label>
                            </b-form-group>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </draggable>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                          <pagination :data="paginations" :limit="limit" @pagination-change-page="listitembyid"></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-if="item_count == 0 && loading == false">
                  <p class="text-center">{{ trans.get('__JSON__.No Search Result Found') }}</p>
                </div>
              </div>
            </div>
          </div>  
        </div>

        <!-- end col-->
      </div>

      <!-- Add Menu Modal Start -->
      <b-modal v-model="showModal" scrollable title="Add Menu" title-class="font-18" centered>
        <b-form @submit.prevent="addMenu">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Menu Image') }}</label>
                        <dropzone-popup v-model="typeform.image" id="image" acceptedFiles=".jpeg,.jpg,.png" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" v-if="$auth.setting">
                        <b-form-group
                        id="input-group-1" :label="trans.get('__JSON__.Menu Name',{ lang: $auth.langauges[0].name})" label-for="menu_name" >
                        <b-form-input
                            id="menu_name"
                            name="menu_name"
                            v-model="$v.typeform.menu_name.$model"
                            type="text"
                            :placeholder="trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name})"
                            :class="{ 'is-invalid': $v.typeform.menu_name.$error }"
                        ></b-form-input>
                        <div v-if="$v.typeform.menu_name.$error" class="invalid-feedback">
                            <span
                            v-if="!$v.typeform.menu_name.required"
                            >{{ trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name}) }}</span>
                        </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
            </div>
            <div class="row" v-else>
                <div class="col-md-12">
                    <div class="form-group" v-if="$auth.setting">
                        <label>{{ trans.trans('__JSON__.Menu Name Secondary',{lang: $auth.langauges[1].name })}}</label>    
                        <input id="menu_name_thai" type="text" v-model="typeform.menu_name_thai" class="form-control" :placeholder="trans.get('__JSON__.Please enter menu name secondary',{lang: $auth.langauges[0].name})" name="menu_name_thai"
                        />
                    </div>
                </div>
            </div>
            <div v-if="category_count > 0">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Menu Category') }}</label>
                      <select class="custom-select" v-model="$v.typeform.parent_menu_id.$model" id="parent_menu_id" name="parent_menu_id" :class="{ 'is-invalid':$v.typeform.parent_menu_id.$error }"> 
                        <option value="">{{trans.get('__JSON__.Select Category')}}</option>
                        <option v-for="con in category" :value="con.id" :key="con.id">{{con.name}}</option>
                      </select>
                      <div v-if="$v.typeform.parent_menu_id.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.parent_menu_id.required">{{ trans.get('__JSON__.Please select category') }}</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <b-form-group
                        id="input-group-2"
                        :label="trans.get('__JSON__.Status')"
                        label-for="Status"
                        >
                        <div class="row">
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.status"
                                name="status"
                                value="1"
                                checked
                            >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                            </div>
                            <div class="col-sm-6">
                            <b-form-radio
                                v-model="typeform.status"
                                name="status"
                                value="0"
                            >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                            </div>
                        </div>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="addMenu"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="menuClose($event)">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Add Menu Modal End -->

      <!-- Edit Menu Modal Start -->
      <b-modal v-model="editMenuModal" scrollable title="Edit Menu" title-class="font-18" centered>
        <b-form @submit.prevent="updateMenu">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                <label class="control-label">{{ trans.get('__JSON__.Menu Image') }}</label>
                  <dropzone-popup
                      v-model="res_menu.image"
                      id="res_image"
                      acceptedFiles=".jpeg,.jpg,.png"
                      :editable-images="res_image"
                      :media-path="menu_media_path+'/original'"
                      :delete-path="menu_image_delete_url"
                      @image-deleted="this.onImagedeleted"
                  />
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" v-if="$auth.setting">
              <label>{{ trans.trans('__JSON__.Menu Name',{ lang: $auth.langauges[0].name})}}</label>
                <input id="menu_name" type="text" class="form-control" v-model="$v.res_menu.menu_name.$model" :placeholder="trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name})" name="menu_name" :class="{ 'is-invalid':$v.res_menu.menu_name.$error }"/>
                <div v-if="$v.res_menu.menu_name.$error" class="invalid-feedback">
                    <span v-if="!$v.res_menu.menu_name.required">{{ trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name}) }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{ trans.trans('__JSON__.Menu Name Secondary',{lang: $auth.langauges[1].name })}}</label>
              <input id="menu_name_thai" type="text" v-model="res_menu.menu_name_thai" class="form-control" :placeholder="trans.get('__JSON__.Please enter menu name secondary',{lang: $auth.langauges[1].name})" name="menu_name_thai"/>
            </div>
          </div>
        </div>
        <div v-if="category_count > 0">
            <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.Menu Category') }}</label>
                    <select class="custom-select" v-model="$v.res_menu.parent_menu_id.$model" id="parent_menu_id" name="parent_menu_id" :class="{ 'is-invalid':$v.res_menu.parent_menu_id.$error }"> 
                      <option value="">{{trans.get('__JSON__.Select Category')}}</option>
                      <option v-for="con in category" :value="con.id" :key="con.id">{{con.name}}</option>
                    </select>
                    <div v-if="$v.res_menu.parent_menu_id.$error" class="invalid-feedback">
                      <span v-if="!$v.res_menu.parent_menu_id.required">{{ trans.get('__JSON__.Please select category') }}</span>
                    </div>
                  </div>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <b-form-group
                    id="input-group-2"
                    :label="trans.get('__JSON__.Status')"
                    label-for="Status"
                    >
                    <div class="row">
                        <div class="col-sm-6">
                        <b-form-radio
                            v-model="res_menu.status"
                            name="status"
                            value="1"
                            checked
                        >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                        </div>
                        <div class="col-sm-6">
                        <b-form-radio
                            v-model="res_menu.status"
                            name="status"
                            value="0"
                        >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                        </div>
                    </div>
                    </b-form-group>
                </div>
            </div>
        </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary" class="float-right" @click="updateMenu">{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="editMenuModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Edit Menu Modal End -->

      <!-- Add Item Modal Start -->
      <b-modal v-model="itemModal" scrollable title="Add Item" title-class="font-18" centered size="lg">
        <form @submit.prevent="menuItemAdd">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" v-if="$auth.setting">
              <label>{{trans.trans('__JSON__.Item Name Primary',{ lang: $auth.langauges[0].name})}}</label>
              <input id="item_name" type="text" class="form-control" v-model="$v.typeForm.item_name.$model" :placeholder="trans.get('__JSON__.Enter Item Name',{lang: $auth.langauges[0].name})" name="item_name" :class="{ 'is-invalid': $v.typeForm.item_name.$error }"/>
              <div v-if="$v.typeForm.item_name.$error" class="invalid-feedback">
                <span v-if="!$v.typeForm.item_name.required">{{ trans.get('__JSON__.Please enter item name')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Name Secondary',{lang: $auth.langauges[1].name })}}</label>
              <input id="item_name_thai " type="text" class="form-control" v-model="typeForm.item_name_thai " :placeholder="trans.get('__JSON__.Enter Item Name Secondary',{lang: $auth.langauges[1].name})" name="item_name_thai "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">  
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Price')}} (<span v-html="currency"></span>)</label>
              <input id="price" type="number" @input="filterInput" v-on:keyup="someHandler($event)" class="form-control" v-model="$v.typeForm.price.$model" placeholder="Enter Item Price" name="price" :class="{ 'is-invalid': $v.typeForm.price.$error }" />
              <div v-if="$v.typeForm.price.$error" class="invalid-feedback">
                <span v-if="!$v.typeForm.price.required">{{ trans.get('__JSON__.Please enter price')}}</span>
                <span v-if="!$v.typeForm.price.floating">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item MRP')}} (<span v-html="currency"></span>) <span v-b-tooltip.hover.v-light title="When Item Price will be less Then MRP It will show with strikethrough In Ordering App (Eg. $1000)" class="font-size-15"><i class="bx bx-info-circle"></i></span></label>
              <input id="mrp" type="number" @input="filterInput" class="form-control" v-model="typeForm.mrp" placeholder="Enter Item MRP" name="mrp"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Quantity')}}</label>
              <input id="quantity" type="number" @input="filterInput" class="form-control" v-model="$v.typeForm.quantity.$model" placeholder="Enter Item Quantity" name="quantity" :class="{ 'is-invalid': $v.typeForm.quantity.$error }"
              />
              <div v-if="$v.typeForm.quantity.$error" class="invalid-feedback">
                <span v-if="!$v.typeForm.quantity.required">{{ trans.get('__JSON__.Please enter quantity')}}</span>
                <span v-if="!$v.typeForm.quantity.numeric">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-2" v-if="tag && tag.length > 0">
                <label>Item Tag</label>
                <multiselect
                  v-model="typeform.selectedTag"
                  id="ajax"
                  label="name"
                  track-by="name"
                  :placeholder="
                    trans.trans('__JSON__.Tag Search', {
                      vendor: $auth.setting.restaurant_name,
                    })
                  "
                  open-direction="bottom"
                  :options="tag"
                  :multiple="true"
                  :searchable="true"
                  :internal-search="true"
                  :clear-on-select="true"
                  :close-on-select="false"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                >
                  <!-- 
                @search-change="asyncFind" 
                :loading="isLoading"
              -->
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                      <span>{{ option.name }}</span>
                      <span class="custom__remove" @click="remove(option)"
                        >❌</span
                      >
                    </span>
                  </template>
                </multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.trans('__JSON__.Item Description Primary',{ lang: $auth.langauges[0].name})}}</label>
              <textarea rows="5" id="item_description" type="text" class="form-control" v-model="typeForm.item_description" :placeholder="trans.get('__JSON__.Enter Item Description Primary',{lang: $auth.langauges[0].name})" name="item_description"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Description Secondary',{lang: $auth.langauges[1].name })}}</label>
              <textarea rows="5" id="item_description_thai" type="text" class="form-control" v-model="typeForm.item_description_thai" :placeholder="trans.get('__JSON__.Enter Item Description Secondary',{lang: $auth.langauges[1].name})"  name="item_description_thai"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Packaging Charge')}}</label>
                <!-- <input id="packaging_charge" type="number" @input="filterInput" class="form-control" v-model="typeForm.packaging_charge" :placeholder="trans.get('__JSON__.Enter Packaging Charge')" name="packaging_charge"/> -->
                <input id="packaging_charge" type="number" @input="filterInput" class="form-control" v-model="$v.typeForm.packaging_charge.$model" :placeholder="trans.get('__JSON__.Enter Packaging Charge')" name="packaging_charge"  :class="{ 'is-invalid': $v.typeForm.packaging_charge.$error }"/>
              <div v-if="$v.typeForm.packaging_charge.$error" class="invalid-feedback">
                <span v-if="!$v.typeForm.packaging_charge.floating">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row status-switch">
          <div class="col-md-4">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Status')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Recommended Item')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Quantity Reset')}}</label>
            </div>
          </div>
        </div>

        <div class="row cstm-switch">
          <div class="col-md-4">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeForm.is_available"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
          <div class="col-sm-4 pl-0">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeForm.is_featured"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
          <div class="col-sm-4 pl-0">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeForm.qty_reset"
                  true-value="Never"
                  false-value="Daily"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Type')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns">
          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="radio3" value="2" v-model="typeForm.item_type" class="custom-control-input" />
              <label class="custom-control-label" for="radio3">{{trans.get('__JSON__.None')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="radio1" v-model="typeForm.item_type" class="custom-control-input" value="0" />
              <label class="custom-control-label" for="radio1">{{trans.get('__JSON__.Veg')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="radio2" v-model="typeForm.item_type" class="custom-control-input" value="1"/>
              <label class="custom-control-label" for="radio2">{{trans.get('__JSON__.Non Veg')}}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label>{{trans.get('__JSON__.Tags')}}</label>
              <b-form-checkbox-group
                id="catalog-item-status"
                v-model="typeForm.tags"
                name="tags"
              >
                <b-form-checkbox class="mb-1" value="SPICY">
                  Spicy
                </b-form-checkbox>
                <b-form-checkbox class="mb-1" value="HEALTHY">
                  Healthy
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </div>
        </div>  
        <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Selling Timeslot')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns">
          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="is_selling_time_slot1" name="is_selling_time_slot" v-model="typeForm.is_selling_time_slot" class="custom-control-input selling_slot" value="1"/>
              <label class="custom-control-label" for="is_selling_time_slot1">{{trans.get('__JSON__.Yes')}}</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="is_selling_time_slot2" name="is_selling_time_slot" v-model="typeForm.is_selling_time_slot" class="custom-control-input selling_slot" value="0" checked/>
              <label class="custom-control-label" for="is_selling_time_slot2">{{trans.get('__JSON__.No')}}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="typeForm.is_selling_time_slot == 1">
            <input type="hidden" name="start_date" id="start_date" value="">
            <input type="hidden" name="end_date" id="end_date" value="">
            <label class="control-label">{{trans.get('__JSON__.Do you want to sell this dish across multiple days')}}</label>
              
              <date-picker
                v-model="$v.typeForm.start_date.$model"
                :disabled-date="disabledBeforeTodayAndAfterAWeek"
                type="date"
                id="start_end_date"
                value-type="format"
                placeholder="Select datetime range"
                range
                @close="handleRangeClose"
                :class="{ 'is-invalid': $v.typeForm.start_date.$error }"
              >
                <template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="toggleTimeRangePanel"
                  >{{ showTimeRangePanel ? 'select date' : '' }}</button>
                </template>
              </date-picker>
              <div v-if="$v.typeForm.start_date.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeForm.start_date.required"
                >{{ trans.get('__JSON__.Please select date') }}.</span>
              </div>
          </div>
        </div>
        <!-- <input type="hidden" name="is_wholeday" value="1"> -->
        <div class="row">
          <div class="col-md-12 whole-main" v-if="typeForm.is_selling_time_slot == 1">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group catalog-item-status">
                  <label class="control-label">{{trans.get('__JSON__.Whole Day')}}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="is_wholeday1" name="is_wholeday_add" v-model="typeForm.is_wholeday" class="custom-control-input selling_slot" value="1"/>
                  <label class="custom-control-label" for="is_wholeday1">{{ trans.get('__JSON__.Yes') }}</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="is_wholeday2" name="is_wholeday_add" v-model="typeForm.is_wholeday" class="custom-control-input selling_slot" value="0"/>
                  <label class="custom-control-label" for="is_wholeday2">{{ trans.get('__JSON__.No') }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 timeslt" v-if="typeForm.is_wholeday == 0 && typeForm.is_selling_time_slot == 1 && slot_count > 0">
            <label class="control-label">{{ trans.get('__JSON__.Time Slot') }} </label>
            <select class="form-control" id="selling_time" name="selling_time[]" v-model=typeForm.selling_time v-bind:class="{ 'fix-height': multiple === 'true' }">
              <option v-for="con in slots" :value="con.start_time + '-' + con.end_time" :key="con.id">{{con.start_time}} - {{con.end_time}}  </option>
            </select>
          </div>
        </div>

        <input type="hidden" name="restaurant_menu_id" v-model="typeForm.restaurant_menu_id" value="typeForm.restaurant_menu_id">
      </form>
      <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right add_item_"  @click="menuItemAdd($event)" value="0">{{ trans.get('__JSON__.Continuous Add') }}</b-button>
            <b-button variant="primary"  class="float-right add_item_"  @click="menuItemAdd($event)" value="1">{{ trans.get('__JSON__.Add Item') }}</b-button>
            <b-button class="float-left" @click="itemModalClose($event)">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Add Item Modal End -->
      
      <!-- Edit Item Modal Start -->
      <b-modal v-model="editItemModal" scrollable :title="trans.get('__JSON__.Edit Item')" title-class="font-18" centered size="lg">
        <form @submit.prevent="menuItemEdit">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" v-if="$auth.setting">
              <label>{{trans.trans('__JSON__.Item Name Primary',{ lang: $auth.langauges[0].name})}}</label>
              <input id="edit_item_name" type="text" class="form-control" v-model="$v.menu_item.item_name.$model" :placeholder="trans.get('__JSON__.Enter Item Name',{lang: $auth.langauges[0].name})" name="item_name" :class="{ 'is-invalid': $v.menu_item.item_name.$error }"/>
              <div v-if="$v.menu_item.item_name.$error" class="invalid-feedback">
                <span v-if="!$v.menu_item.item_name.required">{{ trans.get('__JSON__.Please enter item name')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Name Secondary',{lang: $auth.langauges[1].name })}}</label>
              <input id="edit_item_name_thai " type="text" class="form-control" v-model="menu_item.item_name_thai " :placeholder="trans.get('__JSON__.Enter Item Name Secondary',{lang: $auth.langauges[1].name})" name="item_name_thai "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Price')}} (<span v-html="currency"></span>)</label>
              <input id="edit_price" type="number" @input="filterInput" v-on:keyup="addMrp($event)" class="form-control" v-model="$v.menu_item.price.$model" placeholder="Enter Item Price" name="price" :class="{ 'is-invalid': $v.menu_item.price.$error }"
              />
              <div v-if="$v.menu_item.price.$error" class="invalid-feedback">
                <span v-if="!$v.menu_item.price.required">{{ trans.get('__JSON__.Please enter price')}}</span>
                <span v-if="!$v.menu_item.price.floating">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item MRP')}} (<span v-html="currency"></span>) <span v-b-tooltip.hover.v-light title="When Item Price will be less Then MRP It will show with strikethrough In Ordering App (Eg. $1000)" class="font-size-15"><i class="bx bx-info-circle"></i></span></label>
              <input id="mrp" type="number" @input="filterInput" class="form-control" v-model="menu_item.mrp" placeholder="Enter Item MRP" name="mrp"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Quantity')}}</label>
              <input id="edit_quantity" type="number" @input="filterInput" class="form-control" v-model="$v.menu_item.quantity.$model" placeholder="Enter Item Quantity" name="quantity" :class="{ 'is-invalid': $v.menu_item.quantity.$error }"
              />
              <div v-if="$v.menu_item.quantity.$error" class="invalid-feedback">
                <span v-if="!$v.menu_item.quantity.required">{{ trans.get('__JSON__.Please enter quantity')}}</span>
                <span v-if="!$v.menu_item.quantity.numeric">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-2" v-if="tag && tag.length > 0">
              <label>Item Tag</label>
              <multiselect
                v-model="menu_item.edit_tag"
                id="ajax"
                label="name"
                track-by="name"
                :placeholder="
                  trans.trans('__JSON__.Tag Search', {
                    vendor: $auth.setting.restaurant_name,
                  })
                "
                open-direction="bottom"
                @input="menu_item = {...menu_item}"
                :options="tag"
                :multiple="true"
                :searchable="true"
                :internal-search="true"
                :clear-on-select="true"
                :close-on-select="false"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
              >
                <!-- 
              @search-change="asyncFind" 
              :loading="isLoading"
            -->
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag">
                    <span>{{ option.name }}</span>
                    <span class="custom__remove" @click="remove(option)"
                      >❌</span
                    >
                  </span>
                </template>
                <template slot="clear" slot-scope="props">
                    <div
                      class="multiselect__clear"
                      v-if="menu_item.edit_tag.length"
                      @mousedown.prevent.stop="clearAll(props.search)"
                    ></div>
                </template>
              </multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.trans('__JSON__.Item Description Primary',{ lang: $auth.langauges[0].name})}}</label>
              <textarea rows="5" id="edit_item_description" type="text" class="form-control" v-model="menu_item.item_description" :placeholder="trans.get('__JSON__.Enter Item Description Primary',{lang: $auth.langauges[0].name})" name="item_description"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="$auth.langauges[1].name == 'No Secondary Language'">
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="$auth.setting">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Item Description Secondary',{lang: $auth.langauges[1].name })}}</label>
              <textarea rows="5" id="edit_item_description_thai" type="text" class="form-control" v-model="menu_item.item_description_thai" :placeholder="trans.get('__JSON__.Enter Item Description Secondary',{lang: $auth.langauges[1].name})"  name="item_description_thai"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>{{trans.get('__JSON__.Packaging Charge')}}</label>
              <input id="packaging_charge" type="number" @input="filterInput" class="form-control" v-model="$v.menu_item.packaging_charge.$model" :placeholder="trans.get('__JSON__.Enter Packaging Charge')" name="packaging_charge"  :class="{ 'is-invalid': $v.menu_item.packaging_charge.$error }"/>
              <div v-if="$v.menu_item.packaging_charge.$error" class="invalid-feedback">
                <span v-if="!$v.menu_item.packaging_charge.floating">{{ trans.get('__JSON__.This value should be digits')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row status-switch">
          <div class="col-md-4">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Status')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Recommended Item')}}</label>
            </div>
          </div>
          <div class="col-md-4 pl-0">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Quantity Reset')}}</label>
            </div>
          </div>
        </div>
        <div class="row cstm-switch">
          <div class="col-md-4">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="edit_togBtn"
                  class="switch-on"
                  checked
                  v-model="menu_item.is_available"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>

          <div class="col-sm-4 pl-0">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="edit_togBtn"
                  class="switch-on"
                  checked
                  v-model="menu_item.is_featured"
                  true-value="1"
                  false-value="0"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>

          <div class="col-sm-4 pl-0">
            <b-form-group id="input-group-1">
              <label class="switch">
                <input
                  type="checkbox"
                  id="edit_togBtn"
                  class="switch-on"
                  checked
                  v-model="menu_item.qty_reset"
                  true-value="Never"
                  false-value="Daily"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.On') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Type')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns">
          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="edit_radio3" value="2" v-model="menu_item.item_type" class="custom-control-input" />
              <label class="custom-control-label" for="edit_radio3">{{trans.get('__JSON__.None')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="edit_radio1" v-model="menu_item.item_type" class="custom-control-input" value="0" />
              <label class="custom-control-label" for="edit_radio1">{{trans.get('__JSON__.Veg')}}</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="custom-control custom-radio">
              <input type="radio" name="item_type" id="edit_radio2" v-model="menu_item.item_type" class="custom-control-input" value="1"/>
              <label class="custom-control-label" for="edit_radio2">{{trans.get('__JSON__.Non Veg')}}</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label>{{trans.get('__JSON__.Tags')}}</label>
              <b-form-checkbox-group
                id="catalog-item-status"
                v-model="menu_item.tags"
                name="tags"
              >
                <b-form-checkbox class="mb-1" value="SPICY">
                  Spicy
                </b-form-checkbox>
                <b-form-checkbox class="mb-1" value="HEALTHY">
                  Healthy
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </div>
        </div>  
        <div class="row">
          <div class="col-md-12">
            <div class="form-group catalog-item-status">
              <label class="control-label">{{trans.get('__JSON__.Selling Timeslot')}}</label>
            </div>
          </div>
        </div>
        <div class="row rdio-btns">
          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="edit_is_selling_time_slot1" name="is_selling_time_slot" v-model="menu_item.is_selling_time_slot" class="custom-control-input selling_slot" value="1"/>
              <label class="custom-control-label" for="edit_is_selling_time_slot1">{{trans.get('__JSON__.Yes')}}</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="custom-control custom-radio">
              <input type="radio" id="edit_is_selling_time_slot2" name="is_selling_time_slot" v-model="menu_item.is_selling_time_slot" class="custom-control-input selling_slot" value="0"/>
              <label class="custom-control-label" for="edit_is_selling_time_slot2">{{trans.get('__JSON__.No')}}</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" v-show="menu_item.is_selling_time_slot == 1">
            <input type="hidden" name="start_date" id="edit_start_date" value="">
            <input type="hidden" name="end_date" id="edit_end_date" value="">
            <label class="control-label">{{trans.get('__JSON__.Do you want to sell this dish across multiple days')}}</label>
              
              <date-picker
                v-model="$v.menu_item.datetime.$model"
                :disabled-date="disabledBeforeTodayAndAfterAWeek"
                type="date"
                id="datetime"
                name="datetime"
                value-type="format"
                placeholder="Select datetime range"
                range
                @close="handleRangeClose"
                :class="{ 'is-invalid': $v.menu_item.datetime.$error }"
              >
                <template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text"
                    @click="toggleTimeRangePanel"
                  >{{ showTimeRangePanel ? 'select date' : '' }}</button>
                </template>
              </date-picker>
              <div v-if="$v.menu_item.datetime.$error" class="invalid-feedback">
                <span
                  v-if="!$v.menu_item.datetime.required"
                >{{ trans.get('__JSON__.Please select date') }}.</span>
              </div>
          </div>
        </div>
        <!-- <input type="hidden" name="is_wholeday" value="1"> -->
        <div class="row">
          <div class="col-md-12 whole-main" v-show="menu_item.is_selling_time_slot == 1">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group catalog-item-status">
                  <label class="control-label">{{trans.get('__JSON__.Whole Day')}}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="edit_is_wholeday1" name="is_wholeday_1" v-model="menu_item.is_wholeday_edit" class="custom-control-input selling_slot" value="1"/>
                  <label class="custom-control-label" for="edit_is_wholeday1">{{ trans.get('__JSON__.Yes') }}</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="custom-control custom-radio">
                  <input type="radio" id="edit_is_wholeday2" name="is_wholeday_1" v-model="menu_item.is_wholeday_edit" class="custom-control-input selling_slot" value="0"/>
                  <label class="custom-control-label" for="edit_is_wholeday2">{{ trans.get('__JSON__.No') }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 timeslt" v-if="menu_item.is_wholeday_edit != 1 && menu_item.is_selling_time_slot == 1">
            <label class="control-label">{{ trans.get('__JSON__.Time Slot') }} </label>
            <select class="form-control" id="time" name="time[]" v-model=menu_item.time>
              <option v-for="con in slots" :value="con.start_time + '-' + con.end_time" :key="con.id">{{con.start_time}} - {{con.end_time}}  </option>
            </select>
          </div>
        </div>

        <input type="hidden" name="restaurant_menu_id" v-model="menu_item.restaurant_menu_id" value="menu_item.restaurant_menu_id">
      </form>
      <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="menuItemEdit($event)" >{{ trans.get('__JSON__.Update') }}</b-button>
            <b-button class="float-left" @click="editItemModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Edit Item Modal End -->

      <!--Image Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="imageModal" scrollable :title="trans.get('__JSON__.Upload Item Images')" title-class="font-18" centered>
        <form @submit.prevent="addMenuImage">
          <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Item Image') }}</label>
                  <dropzone-popup
                    multiple
                    v-model="menu_item_image.image_name"
                    id="image_name"
                    name="image_name"
                    acceptedFiles=".jpeg,.jpg,.png"
                    :editable-images="item_image.map(o => o.image_name)"
                    :media-path="menu_item_media_path"
                    :delete-path="menu_item_image_delete_url"
                    :custom-params="{menu_item_id: this.menu_item_image.restaurant_menu_item_id}"
                    @image-deleted="this.onMenuImageDeleted"
                  />
                </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="addMenuImage">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="imageModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Image Modal End -->

      <!--Import Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="importModal" scrollable :title="trans.get('__JSON__.Import Menu & Item')" title-class="font-18" centered>
        <form @submit.prevent="importMenuItem">
          <div class="row">
            <h5 class="download">{{trans.get('__JSON__.To Upload Fresh Menu & Items, Download Sample File')}} <a :href="config.appurl+'public/images/Sample_menu_item_file.xls'" download> {{ trans.get('__JSON__.from Here') }} </a></h5>
            <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload .xls file') }}</label>
                  <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".xls" :class="{ 'is-invalid': $v.import_file.$error }"/>
                  <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.import_file.required"
                    >{{ trans.get('__JSON__.Please select file') }}.</span>
                  </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload ZIP file of item images (Optional)') }}</label>
                  <dropzone-popup v-model="import_image_zip" id="import_image_zip" name="import_image_zip" acceptedFiles=".zip"/>
                </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="importMenuItem($event)">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="importModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Import Modal End -->
      
      <!--Bulk Update Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="itemImportModal" scrollable :title="trans.get('__JSON__.Bulk Update Menu Items')" title-class="font-18" centered>
        <form @submit.prevent="menuItemImport">
          <div class="row">
            <h5 class="download">{{ trans.get('__JSON__.You can download Existing Menu XLS File and Upload New File with Changes') }}.</h5>

            <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Download Current Menu Items') }}</label>
                  <a :href="config.appurl+'export/'+ base64(this.menu_id)+'/'+ this.$route.params.restaurant_id +'/'+base64('item')" target="_blank">
                    <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'xls_download.png'"/>
                  </a>
                </div>
            </div>
            
            <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload Updated Menu Items') }}</label>
                  <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".xls" :class="{ 'is-invalid': $v.import_file.$error }"/>
                  <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.import_file.required"
                    >{{ trans.get('__JSON__.Please select file') }}.</span>
                  </div>
                </div>
            </div>

            <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload .zip file of item images') }}</label>
                  <dropzone-popup v-model="import_image_zip" id="import_image_zip" name="import_image_zip" acceptedFiles=".zip"/>
                </div>
            </div> -->
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="menuItemImport($event)">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="itemImportModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Bulk Update Modal End -->

      <!--Bulk Update Menu & Item Modal Start -->
      <b-modal v-if="$auth.hasPermission(config.permissions.merchant_write)" v-model="menuItemExport" scrollable :title="trans.get('__JSON__.Update Menu & Items')" title-class="font-18" centered>
        <form @submit.prevent="menuItemImport">
          <div class="row">
            <h5 class="download">{{ trans.get('__JSON__.You can download Existing Menu & Item XLS File and Upload New File with Changes') }}.</h5>
            <h5 class="download">{{ trans.get('__JSON__.Please Note Updating Image Files not supported & Don’t Change “ID” & “Vendor Item ID” In Excel') }}.</h5>

            <div class="col-sm-6">
              <div class="form-group">
                <label>{{ trans.get('__JSON__.Download Current Menu & Items') }}</label>
                <a :href="config.appurl+'export/'+ base64(this.menu_id)+'/'+ this.$route.params.restaurant_id +'/'+base64('menu')" target="_blank">
                  <img alt class="rounded avatar-lg image_thumb" :src="config.basepath+'xls_download.png'"/>
                </a>
              </div>
            </div>
            
            <div class="col-sm-6">
                <div class="form-group">
                  <label>{{ trans.get('__JSON__.Upload Updated Menu & Items') }}</label>
                  <dropzone-popup v-model="import_file" id="import_file" name="import_file" acceptedFiles=".xls" :class="{ 'is-invalid': $v.import_file.$error }"/>
                  <div v-if="$v.import_file.$error" class="invalid-feedback cuisine-img">
                    <span
                      v-if="!$v.import_file.required"
                    >{{ trans.get('__JSON__.Please select file') }}.</span>
                  </div>
                </div>
            </div>
          </div>
          <input type="hidden" name="restaurant_menu_item_id" v-model="menu_item_image.restaurant_menu_item_id" value="menu_item.restaurant_menu_id">
        </form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button variant="primary"  class="float-right"  @click="menuItemImport($event)">{{ trans.get('__JSON__.Upload') }}</b-button>
            <b-button class="float-left" @click="menuItemExport=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Bulk Update Menu & Item Modal End -->

    </div>
    <!-- end row -->
  </Layout>
</template>