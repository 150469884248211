<script>
import Multiselect from 'vue-multiselect'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DatePicker from "vue2-datepicker";
import config from "../../config";
import { generate_csv, get_currency, get_decimal, toKebabCase } from '../../../Helper/helper';
import { driverService } from '../../../services';

/**
 * Driver Earning
 */
export default {
    components: {  	Multiselect, DatePicker },
    props: ['driver_id'],
    data() {
        return {
            currency: get_currency(),
            config:config,
            decimal: get_decimal(),
            month: this.moment().format('MMMM'),
            year: this.moment().format('YYYY'),
            months: Array.apply(0, Array(12)).map((_,i) => this.moment().month(i).format('MMMM')).reverse(),
            driverEarning: null,
            loading: false,
            fields:[
                { key: "date", label: this.trans.get("__JSON__.Date"), sortable: false },
                { key: "order_count", label: this.trans.get("__JSON__.Orders Finished"), sortable: false },
                { key: "cod_amount", label: this.trans.get("__JSON__.Cash Collected"), sortable: false },
                { key: "driver_earning", label: this.trans.get("__JSON__.Driver Earning",{driver:this.$auth.getDriverName()}), sortable: false },
            ]
        };
    },
    mounted(){
        this.getDriverMonthWiseEarning();
    },
    methods: {
        getDriverMonthWiseEarning(){
            this.loading = true;
            driverService.getDriverMonthWiseEarning({
                driver_id: this.driver_id,
                month: this.month,
                year: this.year
            })
            .then(response => {
                this.loading = false;
                this.driverEarning = response.data.result;
                var total = {
                    date: "Total",
                    order_count: this.driverEarning.reduce((a, m) => (a + m.order_count),0),
                    cod_amount: this.driverEarning.reduce((a, m) => (a + m.cod_amount),0),
                    driver_earning: this.driverEarning.reduce((a, m) => (a + m.driver_earning),0),
                }
                this.driverEarning = [...this.driverEarning,total]
            })
        },
        downloadsCSV: function(){
            let vendorFinance = '\ufeff' + 'Date,Orders Finished,Cash Collected,'+this.trans.get('__JSON__.Driver Earning',{driver:this.$auth.getDriverName()})+'\n'
            this.driverEarning.map(el=> {
                var line = el['date'] + ',' 
                + el['order_count'] + ',' 
                + el['cod_amount'].toFixed(this.decimal) + ',' 
                + el['driver_earning'].toFixed(this.decimal) + '\n';
                vendorFinance +=line;
            })
            generate_csv(vendorFinance,toKebabCase(this.$auth.getDriverName())+'-earning-'+this.month+'-'+this.year+'.csv');
        },
        disabledDate(date){
            return (this.moment(date).format('YYYY') > this.moment().format('YYYY'))
        }
    },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
        <div class="justify-content-end mb-3 row">
            <div class="w-xl">
                <label for="">{{trans.get('__JSON__.Month')}}</label>
                <div class="text-sm-right">
                    <div class="search-box mr-2 ">
                        <multiselect v-model="month" :options="months" @input="getDriverMonthWiseEarning()" :allow-empty="false"></multiselect>
                    </div>
                </div>
            </div>
            <div class="">
                <label for="">{{trans.get('__JSON__.Year')}}</label>
                <div class="text-sm-right">
                    <div class="search-box mr-2 ">
                        <date-picker v-model="year" type="year" value-type="format" lang="en" :disabledDate="disabledDate" :clearable="false" @change="getDriverMonthWiseEarning()" confirm :placeholder="trans.get('__JSON__.Select Year')"></date-picker>
                    </div>
                </div>
            </div>
            <div class="mt-4 pt-1 mr-3">
                <div class="text-sm-right">
                    <button @click="downloadsCSV" 
                    v-if="$auth.hasSubAdminPermission(config.permissions.driver_finance_export)"
                    class="btn btn-primary btn-rounded">
                    {{trans.get('__JSON__.Export To CSV')}}
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive table-border">
            <b-table
                :items="driverEarning || []"
                :fields="fields"
                :busy="loading"
                show-empty
                class="table-centered table-nowrap table-hover order-menu"
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                </template>
                <template #cell(date)="data">
                    <span class="font-weight-bold">{{data.value}}</span>
                </template>
                <template #cell(order_count)="data">
                    {{data.value}}
                </template>
                <template #cell(cod_amount)="data">
                    <span v-html="currency"></span> {{(data.value || 0).toFixed(decimal)}}
                </template>
                <template #cell(driver_earning)="data">
                    <span v-html="currency"></span> {{(data.value || 0).toFixed(decimal)}}
                </template>
            </b-table>
        </div>
    </div>
</div>
</template>