<script>

import config from "../../../config";
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import DropzonePopup from '../../../components/widgets/dropzone-popup';
import { error_message,  success_message, get_user } from "../../../Helper/helper";
import {
    required,
    email,
    minLength,
    sameAs,
    maxLength,
    minValue,
    maxValue,
    numeric,
    url,
    alphaNum
} from "vuelidate/lib/validators";
import { driverService, userService } from '../../../services';
import { routes } from '../../../config/api_routes';


export default {
    components: { PageHeader, VueGoogleAutocomplete,DropzonePopup},
    data() {
        return {
            title: 'DRIVER DETAILS',
            value: null,
            value1: null,
            zones:[],
            disable:false,
            vehicle_type: [
                'Car',
                'Bicycle',
                'Two Wheeler (Scooter or Bike)',
            ],
            driver: {
                firstname: "",
                lastname: "",
                email: "",
                contactno: "",
                alternate_contactno: "",
                frenchise_id: "",
                address: "",
                firstname_other_lang: "",
                lastname_other_lang: "",
                vehicle_type: "",
                vehicle_number: "",
                licence_number: "",
                driver_licence_document: [],
                driver_vehicle_document: [],
                profileimage: "",
                city: "",
                state: "",
                zip_code: "",
                country: "",
                latitude:"",
                longitude: "",
                driverid: "",
                availbility_status: "",
                job_type: "full_time",
            },
            profile_image : "",
            driver_media_path: "",
            driver_licences: [],
            driver_documents: [],
            profile_delete_url: routes.driverProfileDeleteUrl,
            document_delete_url: routes.driverDocumentDeleteUrl,
            licence_delete_url: routes.driverLicenceDeleteUrl,
            
        };
    },
    validations: {
        driver: {
            firstname: {
                required
            },
            lastname: {
                required
            },
            email: {
                required,
                email
            },
            contactno: {
                required,
                numeric
            },
            frenchise_id: {
                required
            },
            job_type: {
                required
            },
        }
    },
    mounted(){
        this.getDriverInfo();
        this.getFrenchise();
        this.$refs.address.focus();
        this.vendor_id = get_user().vendor_id || 40818;
    },
    methods: {
        onProfiledeleted(value){
            this.profile_image = ""
        },
        onDocumentDeleted(value) {
            console.log(value)
            this.driver_documents = this.driver_documents.filter(doc => doc.document_name !== value);
        },
        onLicenceDeleted(value) {
            console.log(value)
            this.driver_licences = this.driver_licences.filter(doc => doc.document_name !== value);
        },
        userCheckEmail(e) {
            userService.userCheckEmail({
                email:this.driver.email,
                driverid:this.driver.driverid,
                table:'User',
                column:'driverid',
                vendor_id:this.vendor_id,
                user_role:'3',
                table_name:'Driver'
            })
            .then(response => {
                if (response.data.code === 101) {
                this.disable = true;
                error_message(response.data.message);
                }else{
                this.disable = false;
                }
            });
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.address = addressData;
            this.driver.address = placeResultData.formatted_address;
            this.driver.city = addressData.locality;
            this.driver.state = addressData.administrative_area_level_1;
            this.driver.country = addressData.country;
            this.driver.latitude = addressData.latitude;
            this.driver.longitude = addressData.longitude;
            this.driver.zip_code = addressData.postal_code;
        },
        getDriverInfo(){
            driverService.getDriverInfo({driver_id: this.$route.params.driver_id})
            .then(response => {
                const {
                    firstname, 
                    lastname,
                    email,
                    contactno,
                    alternate_contactno,
                    frenchise_id,
                    address,
                    firstname_other_lang,
                    lastname_other_lang,
                    vehicle_type,
                    vehicle_number,
                    licence_number,
                    driver_licence_document,
                    driver_vehicle_document,
                    profileimage,
                    city,
                    state,
                    zip_code,
                    country,
                    latitude,
                    longitude,
                    driver_vehicle,
                    driverid,
                    availbility_status,
                    job_type
                } = response.data.result;
                this.driverid = response.data.result.driverid;
                this.driver_media_path = response.data.result.driver_media_path;
                this.profile_image = response.data.result.profileimage;
                this.profile_path = response.data.result.profile_path;
                this.driver_licences = response.data.result.driver_licence_document;
                this.driver_documents = response.data.result.driver_vehicle_document;
                this.driver = {
                    firstname, 
                    lastname,
                    email,
                    contactno,
                    alternate_contactno,
                    frenchise_id,
                    address,
                    firstname_other_lang,
                    lastname_other_lang,
                    vehicle_type,
                    vehicle_number,
                    licence_number,
                    driver_licence_document,
                    driver_vehicle_document,
                    city,
                    state,
                    zip_code,
                    country,
                    latitude,
                    longitude, 
                    driverid,
                    profileimage: "",
                    availbility_status,
                    driver_licence_document: [], 
                    driver_vehicle_document: [], 
                    vehicle_type:driver_vehicle && driver_vehicle.vehicle_type || '',
                    licence_number:driver_vehicle && driver_vehicle.licence_number || '',
                    vehicle_number:driver_vehicle && driver_vehicle.vehicle_number || '',   
                    job_type
                }

            });
        },
        getFrenchise(){
            driverService.getFrenchise()
            .then(response => {
                this.zones =  response.data.result;
            });
        },
        typeForm(e) {
            this.$v.$touch();
            if (this.$v.driver.$invalid) {
                return;
            }
            else{
                driverService.updateDriveProfile(this.driver)
                .then(response => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                        // this.$router.push({ name: "driver-details" });
                    } else {
                        error_message(response.data.message);
                    }
                });
            } 
        },
        addressChange(addr){
            this.driver.address = addr.newVal;
        }
    }
}
</script>

<template>
    <div> 
        <div class="row">
            <div class="col-12">
                <form action="#" @submit.prevent="typeForm">
                <!-- Driver Info Start -->
                <div class="card details-profile-main">
                    <div class="card-body">
                        <h4 class="card-title">{{ trans.get('__JSON__.Personal Information') }}</h4>
                        <p class="card-title-desc"></p>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group" v-if="$auth.setting">
                                <label>{{ trans.trans('__JSON__.First Name Primary Language',{lang: $auth.langauges[0].name}) }}</label>
                                <input id="firstname" v-model="$v.driver.firstname.$model" type="text" class="form-control" :placeholder="trans.trans('__JSON__.First Name Primary Language',{lang: $auth.langauges[0].name})" name="firstname" :class="{ 'is-invalid':$v.driver.firstname.$error }" />
                                    <div v-if="$v.driver.firstname.$error" class="invalid-feedback">
                                    <span v-if="!$v.driver.firstname.required">{{ trans.get('__JSON__.Please enter first name') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                            </div>
                            <div class="col-sm-6" v-else>
                                <div class="form-group">
                                    <label>{{ trans.trans('__JSON__.First Name Secondary Language',{lang: $auth.langauges[1].name}) }}</label>
                                    <input id="firstname_other_lang" name="firstname_other_lang" v-model="driver.firstname_other_lang" type="text" :placeholder="trans.trans('__JSON__.First Name Secondary Language',{lang: $auth.langauges[1].name})" class="form-control" />
                                </div>
                            </div>
              
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>{{ trans.trans('__JSON__.Last Name Primary Language',{lang: $auth.langauges[0].name}) }}</label>
                                <input id="lastname" v-model="$v.driver.lastname.$model" type="text" class="form-control" :placeholder="trans.trans('__JSON__.Last Name Primary Language',{lang: $auth.langauges[0].name})" name="lastname" :class="{ 'is-invalid':$v.driver.lastname.$error }" />
                                    <div v-if="$v.driver.lastname.$error" class="invalid-feedback">
                                    <span v-if="!$v.driver.lastname.required">{{ trans.get('__JSON__.Please enter last name') }}</span>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                            </div>
                            <div class="col-sm-6" v-else>
                                <div class="form-group">
                                <label>{{ trans.trans('__JSON__.Last Name Secondary Language',{lang: $auth.langauges[1].name}) }}</label>
                                <input id="lastname_other_lang" name="lastname_other_lang" v-model="driver.lastname_other_lang" type="text" :placeholder="trans.trans('__JSON__.Last Name Secondary Language',{lang: $auth.langauges[1].name})" class="form-control" />
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>{{ trans.get('__JSON__.E-Mail') }}</label>
                                    <input id="email" v-model="$v.driver.email.$model" type="email" name="email" class="form-control" @blur="userCheckEmail" :class="{ 'is-invalid':$v.driver.email.$error }" :placeholder="trans.get('__JSON__.Enter Email')" />
                                    <div v-if="$v.driver.email.$error" class="invalid-feedback">
                                        <span v-if="!$v.driver.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                                        <span v-if="!$v.driver.email.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>{{ trans.get('__JSON__.Primary Contact Number') }}</label>
                                    <input id="contactno" v-model="$v.driver.contactno.$model" type="tel" name="contactno" class="form-control" :class="{ 'is-invalid':$v.driver.contactno.$error }" :placeholder="trans.get('__JSON__.Primary contact number')" maxlength="12" minlength="10" />
                                    <div v-if="$v.driver.contactno.$error" class="invalid-feedback">
                                        <span v-if="!$v.driver.contactno.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                                        <span v-if="!$v.driver.contactno.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>{{ trans.get('__JSON__.Alternate contact number') }}</label>
                                    <input id="alternate_contactno" v-model="driver.alternate_contactno" type="tel" name="alternate_contactno" class="form-control" :placeholder="trans.get('__JSON__.Alternate contact number')" maxlength="12" minlength="10" />
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                <label class="control-label">{{ trans.get('__JSON__.Delivery Zone') }}</label>
                                <select class="custom-select" v-model="$v.driver.frenchise_id.$model" id="frenchise_id" name="frenchise_id" :class="{'is-invalid':$v.driver.frenchise_id.$error}" > 
                                    <option value="">{{trans.get('__JSON__.Select Delivery Zone')}}</option>
                                    <option v-for="zone in zones" :value="zone.user_id" :key="zone.user_id">{{zone.last_name}}</option>
                                </select>
                                <div v-if="$v.driver.frenchise_id.$error" class="invalid-feedback">
                                    <span v-if="!$v.driver.frenchise_id.required">{{ trans.get('__JSON__.Please enter select franchise') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="job_type" class="control-label">{{ trans.get('__JSON__.Job Type') }}</label>
                                    <select class="custom-select" :class="{'is-invalid':$v.driver.job_type.$error}"  v-model="$v.driver.job_type.$model" id="job_type" name="job_type"> 
                                        <option value="">{{trans.get('__JSON__.Select Job Type')}}</option>
                                        <option value="full_time">{{trans.get('__JSON__.Full Time')}}</option>
                                        <option value="part_time">{{trans.get('__JSON__.Part Time')}}</option>
                                    </select>
                                    <div v-if="$v.driver.job_type.$error" class="invalid-feedback">
                                        <span v-if="!$v.driver.job_type.required">{{ trans.get('__JSON__.Please enter field',{'name': trans.get('__JSON__.Job Type')}) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="control-label">{{ trans.get('__JSON__.Full address') }}</label>
                                    <vue-google-autocomplete  
                                        ref="address"
                                        id="addres"
                                        name="address"
                                        type="text"
                                        class="form-control"
                                        placeholder="Please type your address"
                                        :value="driver.address"
                                        @inputChange="addressChange"
                                        
                                        v-on:placechanged="getAddressData"   
                                    >
                                    </vue-google-autocomplete>
                                    <!-- <div v-if="$v.driver.address.$error" class="invalid-feedback">
                                        <span v-if="!$v.driver.address.required">{{ trans.get('__JSON__.Please enter address') }}</span>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                <label class="control-label">{{ trans.get('__JSON__.Profile Image') }}</label>
                                    <dropzone-popup
                                        v-model="driver.profileimage"
                                        id="profile_image"
                                        acceptedFiles=".jpeg,.jpg,.png"
                                        :editable-images="profile_image"
                                        :media-path="driver_media_path+'/Profile/original'"
                                        :delete-path="profile_delete_url"
                                        @image-deleted="this.onProfiledeleted"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Driver Info End -->

                <!-- Vechile Info Section Start -->
                <div class="card details-profile-main">
                    <div class="card-body">
                        <h4 class="card-title">{{ trans.get('__JSON__.Vehicle Information') }}</h4>
                        <p class="card-title-desc"></p>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label class="control-label">{{ trans.get('__JSON__.Vehicle Type') }}</label> 
                                <select class="custom-select" v-bind:value="driver.vehicle_type" v-model="driver.vehicle_type"  id="vehicle_type" name="vehicle_type"> 
                                    <option value="">{{trans.get('__JSON__.Select Vehicle Type')}}</option>
                                    <option value="Car">Car</option>
                                    <option value="Van">Van</option>
                                    <option value="Cycle">Cycle</option>
                                    <option value="Bike">Bike</option>
                                    <option value="Motorbike">Motorbike</option>
                                </select>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6 vendor-status">
                                <b-form-group
                                id="input-group-1"
                                :label="trans.get('__JSON__.Status')"
                                label-for="availbility_status"
                                >
                                    <label class="switch">
                                        <input
                                        type="checkbox"
                                        id="togBtn"
                                        name="availbility_status"
                                        v-model="driver.availbility_status"
                                        class="switch-on"
                                        true-value="1"
                                        false-value="0"
                                        />
                                        <div class="slider round">
                                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                        </div>
                                    </label>
                                </b-form-group>
                           </div> -->
                        </div>
                        

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>{{trans.get('__JSON__.Vehicle Number')}}</label>
                                <input id="vehicle_number" type="text" class="form-control" v-model="driver.vehicle_number" :placeholder="trans.get('__JSON__.Vehicle Number')" name="vehicle_number"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>{{ trans.get('__JSON__.Licence Number') }}</label>
                                <input id="licence_number"  type="text" class="form-control" v-model="driver.licence_number" :placeholder="trans.get('__JSON__.Licence Number')" name="licence_number"/>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>{{ trans.get('__JSON__.National Identity Document') }}</label>
                                     <dropzone-popup
                                        multiple
                                        v-model="driver.driver_vehicle_document"
                                        id="document_name"
                                        acceptedFiles=".jpg, .jpeg, .png,.doc,.docx,.pdf"
                                        :editable-images="driver_documents.map(doc => doc.document_name)"
                                        :media-path="driver_media_path+'/Document/original'"
                                        :delete-path="document_delete_url"
                                        :custom-params="{driverid: $route.params.driver_id}"
                                        @image-deleted="this.onDocumentDeleted"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ trans.trans('__JSON__.Delivery Boy Licence Documents',{ driver: $auth.setting.driver_name }) }}</label>
                                        <dropzone-popup
                                            multiple
                                            v-model="driver.driver_licence_document"
                                            id="licence_name"
                                            acceptedFiles=".jpg, .jpeg, .png,.doc,.docx,.pdf"
                                            :editable-images="driver_licences.map(doc => doc.document_name)"
                                            :media-path="driver_media_path+'/Document/original'"
                                            :delete-path="licence_delete_url"
                                            :custom-params="{driverid: $route.params.driver_id}"
                                            @image-deleted="this.onLicenceDeleted"
                                        />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <button type="submit" class="btn btn-primary mr-1 mt-3" :disabled="disable || $auth.partner_login">{{ trans.trans('__JSON__.Update Driver', {driver: $auth.setting.driver_name }) }}</button>
                                <router-link v-if="!$auth.partner_login" :to="{name: 'driver-list'}" type="submit" class="btn btn-secondary mt-3">{{ trans.trans('__JSON__.Cancel') }}</router-link>
                                <router-link v-else :to="{name: 'vendor-driver-dashboard'}" type="submit" class="btn btn-secondary mt-3">{{ trans.trans('__JSON__.Cancel') }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Vechile Info Section End -->
                </form>
            </div>
        </div>
      <!-- end row -->
    </div>
</template>