<template>
  <div>
    <b-dropdown lazy id="dropdown-1" variant="link" right role="navigation" class="m-md-2" v-if="$auth.hasPermission(config.permissions.booking_write)">
        <template #button-content>
        <i class="bx bx-dots-vertical-rounded"></i>
        </template>
        <b-dropdown-item v-if="booking.status == booking_statuses.pending" @click="$emit('changeStatus',booking, booking_statuses.upcoming)">Confirm Booking</b-dropdown-item>
        <b-dropdown-item v-if="booking.status == booking_statuses.upcoming" @click="$emit('changeStatus',booking, booking_statuses.completed)">Complete Booking</b-dropdown-item>
        <b-dropdown-item v-if="booking.status == booking_statuses.upcoming" @click="$emit('changeStatus',booking, booking_statuses.expired)">Expire Booking</b-dropdown-item>
        <b-dropdown-item v-if="booking.status == booking_statuses.completed" @click="$emit('changeStatus',booking, booking_statuses.expired)">Expire Booking</b-dropdown-item>
        <b-dropdown-item v-if="booking.status == booking_statuses.completed" @click="$emit('changeStatus',booking, booking_statuses.upcoming)">Confirm Booking</b-dropdown-item>
        <b-dropdown-item v-if="booking.status == booking_statuses.rejected" @click="$emit('changeStatus',booking, booking_statuses.upcoming)">Confirm Booking</b-dropdown-item>
        <b-dropdown-divider v-if="booking.status == 'Pending'" />
        <b-dropdown-item v-if="booking.status == 'Pending'" @click="$emit('changeStatus',booking, booking_statuses.rejected)"><span class="text-danger">Reject Booking</span></b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { booking_statuses } from '../../../Helper/helper';
import config from "./../../../config";

export default {
  props: ['booking'],
  data(){
    return {
      booking_statuses:booking_statuses,
      config:config
    }
  },
}


</script>
