<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { settingService } from "../../../services";
import config from "../../../config";
import { success_message, error_message } from "../../../Helper/helper";

/**
 * Starter component
 */
export default {
  data() {
    return {
      config: config,
      setting: [],
      setting: {
        payment_gateway : '',
        merchant_id: '',
        access_code: '',
        working_key: '',
        payumoney_header: '',
        payumoney_salt: '',
        payumoney_key: '',
        key_id: '',
        key_secret: '',
        stripe_publishable_key: '',
        stripe_secret_key: '',
        publishable_key : '',
        secret_key: '',
        wompi_client_id: '',
        wompi_client_secret: '',
        paytab_merchant_email: '',
        paytab_client_secret: ''
      }
    };
  },
  mounted() {
    this.getAppSetting();
  },
  methods: {
    getAppSetting() {
      settingService.getAppSettingApi().then((response) => {
        this.setting = response.data.result.settings;
      });
    },
   generalIt(){
    // this.$v.$touch();
    // if (this.$v.setting.$invalid) {
    //   return;
    // } else {
      const fd = new FormData();
      fd.append("payment_gateway",this.setting.payment_gateway);
      if(this.setting.payment_gateway == 'ccavenue')
      {
        fd.append("merchant_id", this.setting.merchant_id);
        fd.append("access_code", this.setting.access_code);
        fd.append("working_key", this.setting.working_key);
        fd.append('type',1);
      }
      else if(this.setting.payment_gateway == 'payumoney')
      {
        fd.append("payumoney_header", this.setting.payumoney_header);
        fd.append("payumoney_salt", this.setting.payumoney_salt);
        fd.append("payumoney_key", this.setting.payumoney_key);
        fd.append('type',2);
      }
      else if(this.setting.payment_gateway == 'razorpay')
      {
        fd.append("merchant_id", this.setting.merchant_id);
        fd.append("key_id", this.setting.key_id);
        fd.append("key_secret", this.setting.key_secret);
        fd.append('type',3);
      }
      else if(this.setting.payment_gateway == 'stripe')
      {
        fd.append("stripe_publishable_key", this.setting.stripe_publishable_key);
        fd.append("stripe_secret_key", this.setting.stripe_secret_key);
        fd.append('type',4);
      }
      else if(this.setting.payment_gateway == 'stripe')
      {
        fd.append("publishable_key", this.setting.publishable_key);
        fd.append("secret_key", this.setting.secret_key);
        fd.append('type',5);
      }
      else if(this.setting.payment_gateway == 'wompi')
      {
        fd.append("wompi_client_id", this.setting.wompi_client_id);
        fd.append("wompi_client_secret", this.setting.wompi_client_secret);
        fd.append('type',6);
      }
      else if(this.setting.payment_gateway == 'wompi')
      {
        fd.append("wompi_client_id", this.setting.wompi_client_id);
        fd.append("wompi_client_secret", this.setting.wompi_client_secret);
        fd.append('type',7);
      }
      else if(this.setting.payment_gateway == 'paytab')
      {
        fd.append("paytab_merchant_email", this.setting.paytab_merchant_email);
        fd.append("paytab_client_secret", this.setting.paytab_client_secret);
        fd.append('type',8);
      }
      settingService.updatepaymentgateway(fd).then((response) => {
        if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
      });
    // }
  }
 }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
                  <p class="text-danger">Note: Please don't change value of gateway this setting will use for developer only</p>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{ trans.get('__JSON__.Payment Method') }}</label>
                  <select
                    class="custom-select"
                    id="payment_gateway"
                    name="payment_gateway"
                    v-model="setting.payment_gateway"
                  >
                    <option value="ccavenue" selected>{{ trans.get('__JSON__.ccavenue') }}</option>
                    <option value="payumoney">{{ trans.get('__JSON__.payumoney') }}</option>
                    <option value="razorpay">{{ trans.get('__JSON__.razorpay') }}</option>
                    <option value="stripe">{{ trans.get('__JSON__.stripe') }}</option>
                    <option value="omise">{{ trans.get('__JSON__.omise') }}</option>
                    <option value="wompi">{{ trans.get('__JSON__.wompi') }}</option>
                    <option value="paytab">{{ trans.get('__JSON__.paytab') }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="setting.payment_gateway == 'ccavenue'">
              <div class="row">
                <div class="col-sm-4">
                  <b-form-group 
                    id="input-group-1"
                    :label="trans.get('__JSON__.Merchant ID')"
                    label-for="name"
                  >
                    <b-form-input
                      id="merchant_id"
                      name="merchant_id"
                      v-model="setting.merchant_id"
                      type="text"
                      :placeholder="trans.get('__JSON__.Merchant ID')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Access Code')"
                    label-for="name"
                  >
                    <b-form-input
                      id="access_code"
                      name="access_code"
                      v-model="setting.access_code"
                      type="text"
                      :placeholder="trans.get('__JSON__.Access Code')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Working Key')"
                    label-for="name"
                  >
                    <b-form-input
                      id="working_key"
                      name="working_key"
                      v-model="setting.working_key"
                      type="text"
                      :placeholder="trans.get('__JSON__.Working Key')"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
             <div v-else-if="setting.payment_gateway == 'payumoney'">
              <div class="row">
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.PayUMoney Header')"
                    label-for="name"
                  >
                    <b-form-input
                      id="payumoney_header"
                      name="payumoney_header"
                      v-model="setting.payumoney_header"
                      type="text"
                      :placeholder="trans.get('__JSON__.PayUMoney Header')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.PayUMoney Salt')"
                    label-for="name"
                  >
                    <b-form-input
                      id="payumoney_salt"
                      name="payumoney_salt"
                      v-model="setting.payumoney_salt"
                      type="text"
                      :placeholder="trans.get('__JSON__.PayUMoney Salt')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.PayUMoney Key')"
                    label-for="name"
                  >
                    <b-form-input
                      id="payumoney_key"
                      name="payumoney_key"
                      v-model="setting.payumoney_key"
                      type="text"
                      :placeholder="trans.get('__JSON__.PayUMoney Key')"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div v-else-if="setting.payment_gateway == 'razorpay'">
              <div class="row">
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Merchant ID')"
                    label-for="name"
                  >
                   <b-form-input
                      id="merchant_id"
                      name="merchant_id"
                      v-model="setting.merchant_id"
                      type="text"
                      :placeholder="trans.get('__JSON__.Merchant ID')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Key Id')"
                    label-for="name"
                  >
                    <b-form-input
                      id="key_id"
                      name="key_id"
                      v-model="setting.key_id"
                      type="text"
                      :placeholder="trans.get('__JSON__.Key Id')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Key Secret')"
                    label-for="name"
                  >
                    <b-form-input
                      id="key_secret"
                      name="key_secret"
                      v-model="setting.key_secret"
                      type="text"
                      :placeholder="trans.get('__JSON__.Key Secret')"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
              <div v-else-if="setting.payment_gateway == 'stripe'">
              <div class="row">
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Publishable key')"
                    label-for="name"
                  >
                    <b-form-input
                      id="stripe_publishable_key"
                      name="stripe_publishable_key"
                      v-model="setting.stripe_publishable_key"
                      type="text"
                      :placeholder="trans.get('__JSON__.Publishable key')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Secret Key')"
                    label-for="name"
                  >
                    <b-form-input
                      id="stripe_secret_key"
                      name="stripe_secret_key"
                      v-model="setting.stripe_secret_key"
                      type="text"
                      :placeholder="trans.get('__JSON__.Secret Key')"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
              <div v-else-if="setting.payment_gateway == 'omise'">
              <div class="row">
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Publishable key')"
                    label-for="name"
                  >
                    <b-form-input
                      id="publishable_key"
                      name="publishable_key"
                      v-model="setting.publishable_key"
                      type="text"
                      :placeholder="trans.get('__JSON__.Publishable key')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Secret Key')"
                    label-for="name"
                  >
                    <b-form-input
                      id="secret_key"
                      name="secret_key"
                      v-model="setting.secret_key"
                      type="text"
                      :placeholder="trans.get('__JSON__.Secret Key')"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div v-else-if="setting.payment_gateway == 'wompi'">
              <div class="row">
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Client ID')"
                    label-for="name"
                  >
                    <b-form-input
                      id="wompi_client_id"
                      name="wompi_client_id"
                      v-model="setting.wompi_client_id"
                      type="text"
                      :placeholder="trans.get('__JSON__.Client ID')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Client Secret')"
                    label-for="name"
                  >
                    <b-form-input
                      id="wompi_client_secret"
                      name="wompi_client_secret"
                      v-model="setting.wompi_client_secret"
                      type="text"
                      :placeholder="trans.get('__JSON__.Client Secret')"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
             <div v-else-if="setting.payment_gateway == 'paytab'">
              <div class="row">
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Paytab Merchant Email')"
                    label-for="name"
                  >
                    <b-form-input
                      id="paytab_merchant_email"
                      name="paytab_merchant_email"
                      v-model="setting.paytab_merchant_email"
                      type="text"
                      :placeholder="trans.get('__JSON__.Paytab Merchant Email')"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-4">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Paytab Secret Key')"
                    label-for="name"
                  >
                    <b-form-input
                      id="paytab_client_secret"
                      name="paytab_client_secret"
                      v-model="setting.paytab_client_secret"
                      type="text"
                      :placeholder="trans.get('__JSON__.Paytab Secret Key')"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div class="btn-update">
              <div class="col-sm-6">
                <button
                  type="submit"
                  v-if="$auth.hasPermission(config.permissions.configuration_write)" 
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>