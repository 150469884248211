<script>

import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CouponList from "./coupon-list";
/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader, CouponList},
  data() {
    return {};
  },
};

</script>

<template>
  <Layout>
    <coupon-list/>
  </Layout>
</template>
