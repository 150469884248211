<script>
import DatePicker from "vue2-datepicker";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import Layout from "../../layouts/main";
import config from "../../config";
import { vendorFinanceService } from "./../../services";
import { overlappingBarChart } from "./vendor-chartist";
import { get_currency, get_decimal, toKebabCase } from "../../Helper/helper";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, pagination, DatePicker },
  data() {
    return {
      year: this.moment().format("YYYY"),
      years: [],
      config:config,
      restaurant_id: "",
      sortBy: "delivered_date",
      sortDesc: true,
      limit: 1,
      currency: get_currency(),
      decimal: get_decimal(),
      overlappingBarChart: overlappingBarChart,
      vendorsData: [],
      dayWiseFinance: null,
      monthWiseFinance: {},
      loading: true,
      fields: [
        {
          key: "month",
          label: this.trans.get("__JSON__.Month"),
          sortable: true,
          class: "bold-text",
        },
        {
          key: "item_total",
          label: this.trans.get("__JSON__.Item Price"),
          sortable: false,
        },
        {
          key: "delivery_fee",
          label: this.trans.get("__JSON__.Delivery Charges"),
          sortable: false,
        },
        {
          key: "service_fee",
          label: this.$auth.getTaxName(),
          sortable: false,
        },
        {
          key: "discount",
          label: this.trans.get("__JSON__.Coupon Discount"),
          sortable: false,
        },
        {
          key: "amount",
          label: this.trans.get("__JSON__.Total Amount"),
          sortable: false,
        },
        {
          key: "admin_commision",
          label: this.trans.get("__JSON__.Admin Commission"),
          sortable: false,
        },
        {
          key: "adminEarning",
          label: this.trans.get("__JSON__.Admin Earning"),
          sortable: false,
        },
        {
          key: "chefEarning",
          label: this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()}),
          sortable: false,
        },
        // { key: "status", label: this.trans.get("__JSON__.Status"), sortable: false },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getVendorFinance();
    this.getYears();
  },
  methods: {
    getYears() {
      var year = 2017;
      var years = [];
      while (year != this.moment().format("YYYY")) {
        years.push(++year);
      }
      this.years = years.reverse();
    },
    sortingChanged(ctx) {
      // this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendorFinance();
    },
    getVendorFinance(page = 1) {
      this.loading = true;
      vendorFinanceService
        .getVendorFinance({
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page ? page : 1,
          year: this.year,
        })
        .then((res) => {
          this.loading = false;
          this.vendorsData = res.data.result;
          this.dayWiseFinance = res.data.result.finances;
          this.monthWiseFinance = res.data.result.monthWiseFinance;
        });
    },
    removeCommas(text) {
      return text.replaceAll(",", "");
    },
    downloadsCSV: function () {
      vendorFinanceService
        .getVendorFinanceCsv({
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          year: this.year,
        })
        .then((res) => {
          const monthWise = res.data.result.monthWiseFinance;

          let vendorsData =
            ''+ this.trans.get("__JSON__.Month")+','
            +this.trans.get("__JSON__.Item Price")+','
            +this.trans.get("__JSON__.Delivery Charges")+','
            +this.$auth.getTaxName()+','
            +this.trans.get("__JSON__.Coupon Discount")+','
            +this.trans.get("__JSON__.Total Amount")+','
            +this.trans.get("__JSON__.Admin Commission")+','
            +this.trans.get("__JSON__.Admin Earning")+','
            +this.trans.get("__JSON__.Vendor Earning",{vendor: this.$auth.getRestaurantName()})
            +'\n';
          monthWise.forEach((element) => {
            var line =
              element.month +
              "," +
              element["item_total"].toFixed(this.decimal) +
              "," +
              element["delivery_fee"].toFixed(this.decimal) +
              "," +
              element["service_fee"].toFixed(this.decimal) +
              "," +
              element["discount"].toFixed(this.decimal) +
              "," +
              element["amount"].toFixed(this.decimal) +
              "," +
              element["admin_commision"].toFixed(this.decimal) +
              "," +
              element["adminEarning"].toFixed(this.decimal) +
              "," +
              element["chefEarning"].toFixed(this.decimal) +
              "\n";
            vendorsData += line;
          });
          var blob = new Blob([vendorsData], { type: "csv/plain" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = toKebabCase(this.$auth.getRestaurantName()) + "-finance-" + this.year + ".csv";
          link.click();
        });
    },
    disabledDate(date) {
      return this.moment(date).format("YYYY") > this.moment().format("YYYY");
    },
  },
};
</script>
<template>
  <Layout>
     <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <h4 class="mb-0 font-size-18">{{ trans.trans("__JSON__.Vendor Finance",{ vendor: $auth.setting.restaurant_name }) }}</h4>
              </div>
               <div class="page-title-right">
                  <button
                    @click="downloadsCSV"
                    v-if="$auth.hasSubAdminPermission(config.permissions.vendor_finance_export)"
                    class="btn btn-primary btn-rounded"
                  >{{trans.get('__JSON__.Export To CSV')}}</button>
                </div>
              </div>
          </div>
      </div>
    <div class="row">
      <div class="col-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-dollar"></i>
                </span>
              </div>
              <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Today')}}</h5>
            </div>
            <div class="text-muted mt-4">
              <h4>
                <span v-html="currency"></span>
               {{dayWiseFinance && dayWiseFinance.today || 0}}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-dollar"></i>
                </span>
              </div>
              <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.7 Days')}}</h5>
            </div>
            <div class="text-muted mt-4">
              <h4>
                <span v-html="currency"></span>
                {{dayWiseFinance && dayWiseFinance.lastWeek || 0}}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-dollar"></i>
                </span>
              </div>
              <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.This Month')}}</h5>
            </div>
            <div class="text-muted mt-4">
              <h4>
                <span v-html="currency"></span>
                {{dayWiseFinance && dayWiseFinance.thisMonth || 0}}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-dollar"></i>
                </span>
              </div>
              <h5 class="font-size-14 mb-0">{{trans.get('__JSON__.Last Month')}}</h5>
            </div>
            <div class="text-muted mt-4">
              <h4>
                <span v-html="currency"></span>
                {{dayWiseFinance && dayWiseFinance.lastMonth || 0}}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-2 offset-sm-10">
                <label for>{{trans.get('__JSON__.Year')}}</label>
                <div class="text-sm-right">
                  <div class="search-box mr-2">
                    <date-picker
                      v-model="year"
                      type="year"
                      value-type="format"
                      lang="en"
                      :disabledDate="disabledDate"
                      :clearable="false"
                      @change="getVendorFinance()"
                      confirm
                      :placeholder="trans.get('Select Year')"
                    ></date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive table-border">
                  <b-table
                    class="table-centered table-nowrap table-hover order-menu"
                    :items="monthWiseFinance.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>
                    <template #cell(month)="data">
                      <span class="font-weight-bold">{{ data.value }}</span>
                    </template>
                    <template #cell(item_total)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(service_fee)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(delivery_fee)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(discount)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(amount)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(admin_commision)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(adminEarning)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(chefEarning)="data">
                      <span v-html="currency"></span>
                      {{ data.value.toFixed(decimal) }}
                    </template>
                    <template #cell(action)="data">
                      <router-link
                        :to="{name: 'vendor-finance-month',params: {month: data.item.month}}" 
                        class="btn btn-primary btn-sm btn-rounded" v-if="!$auth.partner_login"
                      >{{trans.get('__JSON__.View')}}</router-link>
                      <router-link :to="{name:'vendor.restaurant.finance.list',params: {restaurant_id : base64(data.item.restaurant_id) }}" class="btn btn-primary btn-sm btn-rounded" v-if="$auth.partner_login">
                          View
                      </router-link>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="col-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <pagination
                      :data="monthWiseFinance"
                      :limit="limit"
                      @pagination-change-page="getVendorFinance"
                    ></pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>