var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 m-order-main"},[_c('div',{staticClass:"table-responsive mh-400"},[_c('b-table',{ref:"selectableTable",staticClass:"table-centered table-nowrap table-hover table-main-border order-menu",attrs:{"items":(_vm.orders && _vm.orders.data) || [],"fields":_vm.computedFields,"show-empty":"","busy":_vm.loading,"selectable":"","select-mode":"single"},on:{"row-clicked":_vm.viewPageDetails},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.trans.get("__JSON__.No Order Found")))])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.trans.get("__JSON__.Loading...")))])],1)]},proxy:true},{key:"cell(order_id)",fn:function(data){return [_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("\n              "+_vm._s(_vm.thirdPartyOrder
                  ? data.item.customer_order_id != null &&
                    data.item.customer_order_id != ""
                    ? "#" + data.item.customer_order_id
                    : data.item.vendor_order_id
                  : data.item.order_id)+"\n            ")]),_vm._v(" "),(data.item.delivery_pickup_types == 'Delivery')?_c('span',{staticClass:"badge badge-pill badge-soft-primary font-size-12"},[_vm._v("\n              "+_vm._s(_vm.trans.get("__JSON__." + data.item.delivery_pickup_types))+"\n            ")]):_vm._e(),_vm._v(" "),(data.item.delivery_pickup_types == 'Takeaway')?_c('span',{staticClass:"badge badge-pill badge-soft-danger font-size-12"},[_vm._v("\n              "+_vm._s(_vm.trans.get("__JSON__." + data.item.delivery_pickup_types))+"\n            ")]):_vm._e(),_vm._v(" "),(data.item.delivery_pickup_types == 'Pickup')?_c('span',{staticClass:"badge badge-pill badge-soft-danger font-size-12"},[_vm._v("\n              "+_vm._s(_vm.trans.get("__JSON__." + data.item.delivery_pickup_types))+"\n            ")]):_vm._e(),_vm._v(" "),(data.item.delivery_pickup_types == 'Dine In')?_c('span',{staticClass:"badge badge-pill badge-soft-pink font-size-12"},[_vm._v("\n              "+_vm._s(_vm.trans.get("__JSON__." + data.item.delivery_pickup_types))+"\n            ")]):_vm._e()]),_vm._v(" "),(data.item.access_name)?_c('b-badge',{staticClass:"font-size-12 badge-soft-secondary mt-1",attrs:{"pill":""}},[_c('i',{staticClass:"bx bx-phone-call"}),_vm._v(" "+_vm._s(data.item.access_name))]):_vm._e()]}},{key:"cell(vendor_name)",fn:function(data){return [_c('span',{staticClass:"font-weight-bold"},[(_vm.thirdPartyOrder && data.item.fa_order_address && data.item.fa_order_address.restaurant_name)?[_vm._v("\n              "+_vm._s(data.item.fa_order_address.restaurant_name)+"\n            ")]:[_vm._v("\n              "+_vm._s(data.value)+"\n            ")]],2)]}},{key:"cell(customer_name)",fn:function(data){return [(_vm.thirdPartyOrder && data.item.fa_order_address && data.item.fa_order_address.customer_name)?[_vm._v("\n            "+_vm._s(data.item.fa_order_address.customer_name)),_c('br'),_vm._v(" "),_c('small',[_vm._v(_vm._s(_vm.thirdPartyOrder
                ? data.item.fa_order_address.customer_contact_number
                : data.item.customer_phone))])]:[_vm._v("\n            "+_vm._s(data.value)),_c('br'),_vm._v(" "),_c('small',[_vm._v(_vm._s(data.item.customer_phone))])]]}},{key:"cell(created_at)",fn:function(data){return [_vm._v("\n          "+_vm._s(_vm.moment(data.value, "YYYY-MM-DD HH:mm:ss").fromNow())+"\n          "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-light",modifiers:{"hover":true,"v-light":true}}],staticClass:"font-size-15",attrs:{"title":data.value}},[_c('i',{staticClass:"bx bx-info-circle"})]),_vm._v(" "),(data.item.order_status == 'ReadyToServe')?_c('br'):_vm._e(),_vm._v(" "),(data.item.order_status == 'ReadyToServe')?_c('small',[_vm._v("\n            READY since "+_vm._s(_vm.moment(data.item.readytoserve_date).fromNow())+"\n          ")]):_vm._e()]}},{key:"cell(delivery_time)",fn:function(data){return [(data.item.eta == '')?_c('div',[_c('span',[_vm._v("--")])]):_c('div',[(data.item.eta && data.item.eta !== '')?_c('div',{staticClass:"align-items-center"},[_c('span',[_vm._v("\n                "+_vm._s(_vm.moment(data.item.eta).format("MMM DD[,] YYYY hh:mm A"))+"\n              ")]),_vm._v(" "),(
                  data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                  !_vm.thirdPartyOrder
                )?_c('span',{staticClass:"mdi mdi-calendar text-primary font-size-18 pl-1"}):_vm._e(),_vm._v(" "),(
                  data.item.order_status == 'ReadyToServe' ||
                  data.item.order_status == 'OnTheWay' ||
                  data.item.order_status == 'Arrived'
                )?[(_vm.moment().diff(_vm.moment(data.item.eta)) > 0)?_c('br'):_vm._e(),_vm._v(" "),(_vm.moment().diff(_vm.moment(data.item.eta)) > 0)?_c('small',[_vm._v("\n                  DELAYED by\n                  "+_vm._s(_vm.moment
                      .duration(_vm.moment().diff(_vm.moment(data.item.eta)))
                      .asMinutes()
                      .toFixed())+"\n                  minutes\n                ")]):_vm._e()]:_vm._e(),_vm._v(" "),(data.item.order_status == 'Delivered')?[(
                    _vm.moment(data.item.delivered_date).diff(_vm.moment(data.item.eta)) > 0
                  )?_c('br'):_vm._e(),_vm._v(" "),(
                    _vm.moment(data.item.delivered_date).diff(_vm.moment(data.item.eta)) > 0
                  )?_c('small',[_vm._v("\n                  DELAYED by\n                  "+_vm._s(_vm.moment
                      .duration(
                        _vm.moment(data.item.delivered_date).diff(_vm.moment(data.item.eta))
                      )
                      .asMinutes()
                      .toFixed())+"\n                  minutes\n                ")]):_vm._e()]:_vm._e()],2):_c('div',[(data.item.future_delivery_date != '0000-00-00 00:00:00')?_c('span',[_vm._v("\n                "+_vm._s(_vm.moment(data.item.future_delivery_date).format(
                    "MMM DD[,] YYYY hh:mm A"
                  ))+"\n              ")]):_c('span',[_vm._v("\n                "+_vm._s(_vm.moment(data.item.created_at)
                    .add(data.value, "minutes")
                    .format("HH:mm:ss"))+"\n              ")]),_vm._v(" "),(
                  data.item.order_status == 'ReadyToServe' ||
                  data.item.order_status == 'OnTheWay' ||
                  data.item.order_status == 'Arrived'
                )?[(
                    data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                    _vm.moment().diff(_vm.moment(data.item.future_delivery_date)) > 0
                  )?_c('br'):(
                    _vm.moment().diff(
                      _vm.moment(data.item.created_at).add(data.value, 'minutes')
                    ) > 0
                  )?_c('br'):_vm._e(),_vm._v(" "),(
                    data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                    _vm.moment().diff(_vm.moment(data.item.future_delivery_date)) > 0
                  )?_c('small',[_vm._v("\n                  DELAYED by\n                  "+_vm._s(_vm.moment
                      .duration(_vm.moment().diff(_vm.moment(data.item.future_delivery_date)))
                      .asMinutes())+"\n                  minutes\n                ")]):(
                    _vm.moment().diff(
                      _vm.moment(data.item.created_at).add(data.value, 'minutes')
                    ) > 0
                  )?_c('small',[_vm._v("\n                  DELAYED by\n                  "+_vm._s(_vm.moment
                      .duration(
                        _vm.moment().diff(
                          _vm.moment(data.item.created_at).add(data.value, "minutes")
                        )
                      )
                      .asMinutes()
                      .toFixed(0))+"\n                  minutes\n                ")]):_vm._e()]:_vm._e()],2)])]}},{key:"cell(total_amount)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.currency)}}),_vm._v("\n          "+_vm._s(parseFloat(data.value || 0).toFixed(2))+"\n          ")]}},{key:"cell(payment_method)",fn:function(data){return [_c('span',{staticClass:"badge badge-pill font-size-12",class:{
              'badge-soft-success': data.value == 'Cash On Delivery',
              'badge-soft-warning': data.value.toLowerCase() == 'online',
              'badge-soft-info': data.value == 'Wallet',
            }},[_vm._v("\n            "+_vm._s(data.value == "Cash On Delivery" ? "COD" : data.value)+"\n          ")])]}},_vm._l((_vm.extraColumns),function(col,index){return {key:("cell(" + (col.key) + ")"),fn:function(data){return [(col.key.indexOf('date') !== -1)?_c('span',{key:index},[_c('span',[_vm._v(_vm._s(_vm.moment(data.value).fromNow()))]),_vm._v(" "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-light",modifiers:{"hover":true,"v-light":true}}],staticClass:"font-size-15",attrs:{"title":data.value}},[_c('i',{staticClass:"bx bx-info-circle"})])]):(col.key == 'assign')?_c('div',{key:index},[(data.item.driver.length > 0)?_c('span',{staticClass:"badge badge-pill font-size-12 badge-soft-success"},[_vm._v("Assigned")]):(data.item.dunzo_driver == '1')?_c('span',{staticClass:"badge badge-pill font-size-12 badge-soft-success"},[_vm._v("DUNZO DRIVER")]):(data.item.pidge_driver == '1')?_c('span',{staticClass:"badge badge-pill font-size-12 badge-soft-success"},[_vm._v("PIDGE DRIVER")]):_vm._e(),_vm._v(" "),_c('OrderAssign',{attrs:{"order":data.item}})],1):(col.key == 'driver')?_c('span',{key:index},[_vm._v("\n            "+_vm._s(data.item.dunzo_driver == "0" && data.item.pidge_driver == "0"
                ? data.value
                : data.item.dunzo_task && data.item.dunzo_task.runner
                ? data.item.dunzo_task.runner.name
                : data.item.pidge_task && data.item.pidge_task.runner
                ? data.item.pidge_task.runner.name
                : "")+"\n            "),(data.item.dunzo_driver == '1' && data.item.pidge_driver == '0')?_c('span',{staticClass:"badge badge-pill font-size-12 badge-soft-success"},[_vm._v("DUNZO")]):_vm._e(),_vm._v(" "),(data.item.pidge_driver == '1' && data.item.dunzo_driver == '0')?_c('span',{staticClass:"badge badge-pill font-size-12 badge-soft-success"},[_vm._v("PIDGE")]):_vm._e(),_vm._v(" "),(
                data.item.dunzo_driver == '0' &&
                data.item.pidge_driver == '0' &&
                ['Arrived', 'OnTheWay', 'Delivered'].includes(data.item.order_status)
              )?_c('small',[_c('br'),_vm._v("\n              "+_vm._s((data.item.driver &&
                  data.item.driver.length > 0 &&
                  data.item.driver[0].contactno) ||
                "")+"\n            ")]):_vm._e()]):_vm._e()]}}}),{key:"cell(action)",fn:function(data){return [_c('OrderAction',{attrs:{"order":data.item,"delay_order_btn":_vm.delay_order_btn,"not_response_btn":_vm.not_response_btn,"thirdPartyOrder":_vm.thirdPartyOrder},on:{"actionChanged":function($event){return _vm.$emit('refreshData')},"actionClicked":_vm.actionClicked}})]}}],null,true)})],1)]),_vm._v(" "),(_vm.showModal && _vm.vendor_order_id != null)?_c('OrderShow',{attrs:{"order-id":_vm.orderId,"thirdPartyOrder":(_vm.thirdPartyOrder = true)},on:{"hidden":function($event){_vm.showModal = false}}}):(_vm.showModal && _vm.vendor_order_id == null)?_c('OrderShow',{attrs:{"order-id":_vm.orderId,"thirdPartyOrder":(_vm.thirdPartyOrder = false)},on:{"hidden":function($event){_vm.showModal = false}}}):_vm._e(),_vm._v(" "),(_vm.showCancel)?_c('OrderCancel',{attrs:{"order-id":_vm.orderId,"cancel_order":_vm.cancel_order,"thirdPartyOrder":_vm.thirdPartyOrder},on:{"hidden":function($event){_vm.showCancel = false},"actionChanged":function($event){return _vm.$emit('refreshData')}}}):_vm._e(),_vm._v(" "),(_vm.showDunzo)?_c('OrderDunzo',{attrs:{"order-id":_vm.orderId},on:{"hidden":function($event){_vm.showDunzo = false}}}):_vm._e(),_vm._v(" "),(_vm.showNote)?_c('order-customer-notes',{attrs:{"order-id":_vm.orderId},on:{"hidden":function($event){_vm.showNote = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }