<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import { chatService } from "../../services";
import { debounce, replaceByDefault,date_ranges } from "../../Helper/helper";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import moment from 'moment';
import Autocomplete from './../../components/autocomplete'
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import CustomNotes from './custom-notes';


var observe;
export default {
  components: {
    Layout,
    PageHeader,
    chatService,
    Autocomplete,
    DateRangePicker,
    CustomNotes
  },
  data() {
    let startDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().startOf('day').toDate();
    let endDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().endOf('day').toDate();
    return {
      dateRange: { startDate, endDate },
      interval: null,
      userListingData: [],
      userMessageData: [],
      userMessageExportData:[],
      userCustomNotes:[],
      userDetails: {},
      sender:[],
      title: "Chat",
      room: "",
      showCustomNotes: false,
      is_export_button:false,
      is_date_filter:false,
      typeform: {
        message: "",
      },
      datefilters: {
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD')
      },
      items: [
        {
          text: "Skote",
          href: "javascript:void(0)",
        },
        {
          text: "Chat",
          active: true,
        },
      ],
      config: config,
      replaceByDefault: replaceByDefault,
      page: 1,
      search: "",
      debounce,
      searchChanged: debounce(() => {
        this.page = 1;
        this.userListing();
      }, 500),
      date_ranges: date_ranges
    };
  },
  validations: {
    typeform: {
      message: {
        required,
      },
    },
  },
  computed:{
    getSelected:function(){
      const { start_date, end_date } = this.datefilters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
  mounted() {
    this.userListing();
    this.sockets.subscribe('message_notification',
          (data) => {
            this.userListingOnly();
          })
    if (window.attachEvent) {
      observe = function (element, event, handler) {
        element.attachEvent("on" + event, handler);
      };
    } else {
      observe = function (element, event, handler) {
        element.addEventListener(event, handler, false);
      };
    }
    this.dateRange =this.changeSettingRange();
    this.datefilters = {
      ...this.datefilters,
      start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
    }
    // this.$nextTick(() => {
    this.$refs.chatListing.addEventListener(
      "scroll",
      _.debounce((e) => {
        console.log(
          this.$refs.chatListing.scrollTop,
          this.$refs.chatListing.clientHeight,
          this.$refs.chatListing.scrollHeight
        );
        if (
          this.$refs.chatListing.scrollTop +
            this.$refs.chatListing.clientHeight +
            10 >=
          this.$refs.chatListing.scrollHeight
        ) {
          this.onScroll(); // needed for initial loading on page
        }
      }, 500)
    );
    // });
    this.init();
  },
  beforeDestroy() {
    this.interval && clearTimeout(this.interval);
    this.$auth.room = "";

    this.$refs.chatListing.removeEventListener("scroll", this.onScroll);
  },

  watch: {
    "$auth.received": function () {
      setTimeout(() => {
        this.userListing(1);
        this.getUserMessage(this.userDetails.user_id);
      }, 1000);
    },
    "$route.query": function(){
      this.dateRange =this.changeSettingRange();
      this.datefilters = {
        ...this.datefilters,
        start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }
     
    }
  },
  // sockets:{
  //     'messagePass':function (data){
  //         this.interval && clearTimeout(this.interval);
  //         if(data.room == this.room){
  //             this.interval = setTimeout(() => {
  //                 this.getUserMessage(this.userDetails.user_id);
  //             },1000);
  //         }
  //     }
  // },
  methods: {
    onScroll() {
      ++this.page;
      this.userListing();
    },
    btoa(str) {
      return btoa(str);
    },
    userListing(page) {
     
      this.loading = true;
      var filters = { page: this.page, search: this.search };
      chatService.getUserListing(filters).then((response) => {
        this.sender = response.data.message;
        this.loading = false;
        if (this.page != 1) {
          this.userListingData = [
            ...this.userListingData,
            ...response.data.result,
          ];
        } else {
          this.userListingData = [...response.data.result];
        }
        if (this.page == 1) {
          
          this.getUserMessage(response.data.result[0].user_id);
        }
        //
      });
    },
    userListingOnly(page) {
      console.log("inside userlisting only");
      this.loading = true;
      var filters = { page: this.page, search: this.search };
      chatService.getUserListing(filters).then((response) => {
        this.sender = response.data.message;
        this.loading = false;
        if (this.page != 1) {
          this.userListingData = [
            ...this.userListingData,
            ...response.data.result,
          ];
        } else {
          this.userListingData = [...response.data.result];
        }
      });
    },
    dateFormat(classes, date) {
      this.datefilters = {
        ...this.datefilters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
    },
     changeSettingRange(){
      var now = this.moment();
      var then = this.moment();
      if(this.$auth && this.$auth.setting){
        var range = this.$auth.setting.order_dashboard_default_time;
        var date_ranges = this.date_ranges();
        var keys = Object.keys(date_ranges)
        switch(range){
          case "0":
            return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
          case "1":
            return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
          case "6":
            return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
          case "29":
            return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
          case "365":
            return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
          case "101":
            return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
          default:
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        }
      }
      return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
    },
  
    setMarkAsRead() {
      chatService
        .setMarkAsRead({ user_id: this.userDetails.user_id })
        .then((response) => {
          this.$auth.getUnreadMessages();
        });
    },
    sendMessage() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        // fd.append("sender_id", this.$auth.getVendorId());
        fd.append("sender_id",this.$auth.user.vendor_id);
        fd.append("sender_type", 'admin');
        fd.append("message", this.typeform.message);
        fd.append("receiver_id", this.userDetails.user_id);
        fd.append("type", "customer");
        axios.post(config.app_api_url + "sendmessage", fd).then((res) => {
          var msg_data = {
            sender_id: this.$auth.user.vendor_id,
            sender_name: this.$auth.user.vendor_name,
            room: this.$auth.room,
            message: this.typeform.message,
            image_url: res?.data?.chat?.image_url??'',
            receiver_id: this.userDetails.user_id,
          };
          this.$socket.emit("messagePass", msg_data);
          this.getUserMessage(this.userDetails.user_id);
          this.typeform.message = "";
        });

        // chatService.sendMessage(fd).then((response) => {
        //   if (response.data.code === 200) {
        //     success_message(response.data.message);
        //     this.$router.push({ name: "category-list" });
        //   } else {
        //     error_message(response.data.message);
        //   }
        // });
      }
    },
    getUserMessage(user_id) {
      this.dateRange =this.changeSettingRange();
      this.datefilters = {
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD')
    }
      this.$auth.room = "R40818" + "S" + user_id;
      this.$socket.emit("room", this.$auth.room);

      this.loading = true;
      var filters = {
        another_user_id: user_id,
      };
      chatService.getUserMessage(filters).then((response) => {
        this.loading = false;
        this.is_export_button=true;
        this.is_date_filter=true;
        this.userMessageData = response.data.result.messages;
        this.userDetails = response.data.result.another_user_id;
        this.sender = response.data.result.getAllVendor;
        this.userCustomNotes = response.data.result.customerNotes;
        console.log("userCustomNotes",this.userCustomNotes)
        const elem = this.$refs.scrollToMe;
        setTimeout(function () {
          elem.scrollTo(0, elem.scrollHeight);
        }, 1000);
        this.setMarkAsRead();
      });
    },
    updateValues(date) {
      this.is_export_button=false;
      this.datefilters = {
        ...this.datefilters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      this.is_export_button=true;
    },
    delayedResize() {
      window.setTimeout(this.resize, 0);
    },
    resize() {
      this.$refs.chatInput.style.height = "auto";
      this.$refs.chatInput.style.height =
        this.$refs.chatInput.scrollHeight + "px";
    },
    init() {
      var text = this.$refs.chatInput;
      observe(text, "change", this.resize);
      observe(text, "cut", this.delayedResize);
      observe(text, "paste", this.delayedResize);
      observe(text, "drop", this.delayedResize);
      observe(text, "keydown", this.delayedResize);

      text.focus();
      text.select();
      this.resize();
    },
    setMessage(message) {
      this.typeform.message = message.replaceAll(
        '"customer_name"',
        this.userDetails.user_name
      );
    },
    customNotes(user_id){
      this.showCustomNotes = true;
     
    },
    downloadCSV: function(user_id){
        var filtersdata = {
          ...this.datefilters,
        another_user_id: user_id,
        type:'export'
      };
      var userMessageExportData = [];
      let transactions = '\ufeff' + 'Name,Time,Message,Image\n'
      var sender_id = user_id;
      var data = 'Name'+ '\t';
      data += 'Time'+ '\t';
      data += 'Message'+ '\t';
      data += 'Image'+ '\t';
      data += '\r\n';
      chatService.getUserMessage(filtersdata).then((response) => {
        userMessageExportData = response.data.result.messages;
       
            userMessageExportData.forEach(el => {
          
              if(sender_id == el['sender_id']){
                data += this.userDetails.user_name + '\t';
                  data += el['export_date']+ '\t';
                  data += el['message']+ '\t';
                  data += el['image_url']+ '\t';
                  data += '\r\n';
              }else{
                data += el['vendor_name'] + '\t';
                  data += el['export_date']+ '\t';
                  data += el['message']+ '\t';
                  data += el['image_url']+ '\t';
                  data += '\r\n';
              }
        
          });
          var blob = new Blob([ data ], { type: 'application/vnd.ms-excel'  });
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'chat_'+this.userDetails.user_name+'_'+this.userDetails.last_name+'.xls'
          link.click()
       
      });
     
     
      

    }
  },
};
</script>
<template>
  
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-lg-flex">
      <div class="chat-leftsidebar mr-lg-4">
        <div class="">
          <div class="search-box chat-search-box py-2">
            <div class="position-relative">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                v-model="search"
                @keyup="searchChanged"
              />
              <i class="bx bx-search-alt search-icon"></i>
            </div>
          </div>

          <div class="chat-leftsidebar-nav">
            <!-- <ul class="nav nav-pills nav-justified">
                        <li class="nav-item">
                            <a href="#chat" data-toggle="tab" aria-expanded="true" class="nav-link active">
                                <i class="bx bx-chat font-size-20 d-sm-none"></i>
                                <span class="d-none d-sm-block">Chat</span>
                            </a>
                        </li>
                       
                    </ul> -->
            <div class="tab-content">
              <div class="tab-pane show active" id="chat">
                <div
                  style="height: 410px; overflow: hidden scroll"
                  ref="chatListing"
                >
                  <h5 class="font-size-14 mb-3">Recent</h5>
                  <ul
                    class="list-unstyled chat-list"
                    data-simplebar
                    style="max-height: 410px"
                    v-for="(users, uindex) in userListingData"
                    :key="users.id + '-' + uindex"
                  >
                    <li
                      :class="{ active: userDetails.user_id == users.user_id }"
                      @click="getUserMessage(users.user_id)"
                    >
                      <a href="#">
                        <div class="media">
                          <div class="align-self-center mr-3">
                            <i class="mdi mdi-circle font-size-10"></i>
                          </div>
                          <div class="align-self-center mr-3">
                            <img
                              :src="users.user_profile"
                              @error="replaceByDefault"
                              class="rounded-circle avatar-xs"
                              alt=""
                            />
                          </div>

                          <div class="media-body overflow-hidden">
                            <h5
                              class="text-truncate font-size-14 mb-1"
                              :title="'CHOWC' + users.user_id"
                            >
                              <span
                                class="badge badge-pill badge-danger ml-2"
                                v-if="
                                  $auth.unreadMessages.filter(
                                    (urm) => urm.sender_id == users.user_id
                                  ).length !== 0
                                "
                                >{{
                                  $auth.unreadMessages.filter(
                                    (urm) => urm.sender_id == users.user_id
                                  ).length
                                }}</span
                              >
                              {{ users.user_name }} {{ users.last_name || "" }}
                            </h5>
                          </div>
                          <div class="font-size-11">
                            {{
                              users.created_at &&
                              moment.utc(users.created_at).fromNow()
                            }}
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-pane" id="contact">
                <h5 class="font-size-14 mb-3">Contact</h5>

                <div data-simplebar style="max-height: 410px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 user-chat">
        <div class="card relative h-100">
          <div class="p-4 border-bottom">
            <div class="row">
              <div class="col-md-4 col-6">
                <h5 class="font-size-15 mb-1">
                  <b-link
                    :to="{
                      name: 'customer-detail',
                      params: { user_id: btoa(userDetails.user_id) },
                    }"
                    >{{ userDetails.user_name }} {{ userDetails.last_name }}  (Total : {{  userDetails.total_orders }}) </b-link> </br>
                    <span ><b>Live</b> : {{  userDetails.ongoingOrder }}
                           <span
                           v-b-tooltip.hover.v-light.html
                          :title="
                           userDetails.ongoingOrderDetails
                          "
                          class="font-size-15"
                          ><i class="bx bx-info-circle"></i
                        ></span></span>
                </h5>
                <p class="text-muted mb-0">
                  <i class="mdi mdi-circle text-success align-middle mr-1"></i>
                  Active now
                </p>
               
              </div>
             
              <div class="col-md-8 col-3" v-if="this.is_date_filter==true">
                <div class="form-group ml-2 exporbtn">
                      <button @click="customNotes(userDetails.user_id)" class="btn btn-primary btn-rounded exporbtn">
                          Custom Notes
                      </button>
                    </div>
                    <div class="form-group ml-2 exporbtn">
                      <div class="date-range-list">
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          class="w-100"
                          :ranges="date_ranges()"
                          @update="updateValues"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') }} -
                            {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                    <button @click="downloadCSV(userDetails.user_id)" class="btn btn-primary btn-rounded exporbtn">
                      Export To Excel
                    </button>
                  </div>
          
                
            </div>
          </div>

          <div>
            <div
              class="chat-conversation p-3"
              id="chat_conversation"
              ref="scrollToMe"
            >
              <ul
                class="list-unstyled"
                v-for="(messages, index) in userMessageData"
                :key="messages.id + '/' + index"
                data-simplebar
                style="max-height: 470px"
              >
                <!-- <li>
                                <div class="chat-day-title">
                                    <span class="title">Today</span>
                                </div>
                            </li> -->
                <li v-if="!sender.includes(messages.sender_id)">
                  <div class="conversation-list">
                    <!-- <div class="dropdown">

                                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">Copy</a>
                                            <a class="dropdown-item" href="#">Save</a>
                                            <a class="dropdown-item" href="#">Forward</a>
                                            <a class="dropdown-item" href="#">Delete</a>
                                        </div>
                                    </div> -->
                    <div class="ctext-wrap">
                      <b-link
                        :to="{
                          name: 'customer-detail',
                          params: { user_id: btoa(userDetails.user_id) },
                        }"
                        >{{ userDetails.user_name }}</b-link
                      >
                      <!-- <div class="conversation-name">{{userDetails.user_name}}</div> -->
                      <p>
                        {{ messages.message }}
                      </p>
                      <div
                        v-if="messages.image_url && messages.image_url != ''"
                      >
                        <a :href="messages.image_url" target="_blank">
                          <img :src="messages.image_url" style="height: 50px" />
                        </a>
                      </div>
                      <p class="chat-time mb-0">
                        <i class="bx bx-time-five align-middle mr-1"></i
                        >{{ moment.utc(messages.updated_at).fromNow() }}
                        <span
                          v-b-tooltip.hover.v-light
                          :title="
                            moment(
                              moment.utc(messages.updated_at).toISOString()
                            ).format('lll')
                          "
                          class="font-size-15"
                          ><i class="bx bx-info-circle"></i
                        ></span>
                      </p>
                    </div>
                  </div>
                </li>

                <li class="right" v-else>
                  <div class="conversation-list">
                    <!-- <div class="dropdown">

                                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">Copy</a>
                                            <a class="dropdown-item" href="#">Save</a>
                                            <a class="dropdown-item" href="#">Forward</a>
                                            <a class="dropdown-item" href="#">Delete</a>
                                        </div>
                                    </div> -->
                    <div class="ctext-wrap">
                      <div class="conversation-name">
                        {{ messages.vendor_name}}
                      </div>
                      <p>
                        {{ messages.message }}
                      </p>
                      <div
                        v-if="messages.image_url && messages.image_url != ''"
                      >
                        <a :href="messages.image_url" target="_blank">
                          <img :src="messages.image_url" style="height: 50px" />
                        </a>
                      </div>
                      <p class="chat-time mb-0">
                        <i class="bx bx-time-five align-middle mr-1"></i
                        >{{ moment.utc(messages.updated_at).fromNow() }}
                        <span
                          v-b-tooltip.hover.v-light
                          :title="
                            moment(
                              moment.utc(messages.updated_at).toISOString()
                            ).format('lll')
                          "
                          class="font-size-15"
                          ><i class="bx bx-info-circle"></i
                        ></span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <b-form
              @submit.prevent="sendMessage"
              class="absolute w-100"
              style="bottom: 0px"
            >
              <!-- <div v-if="$auth.setting.admin_default_msg && $auth.setting.admin_default_msg.length" class="overflow-auto message-panel" style="white-space:nowrap;">
                        <a href="javascript:void(0);" @click="setMessage(message)" v-for="(message,index) in $auth.setting.admin_default_msg" :key="index" class="border mx-2 px-2 py-1 rounded-pill d-inline-block">
                            {{ message }} 
                        </a>
                    </div> -->
              <div class="p-3 chat-input-section w-100">
                <div class="row">
                  <div class="col-1 relative">
                    <b-button id="default_message" variant="link" class="pt-3"><i class="bx bx-message-alt-dots font-size-20"></i></b-button>
                    <b-popover target="default_message" triggers="hover focus" placement="top" custom-class="mw-100 shadow">
                      <template #title>Default Messages</template>
                      <b-list-group class="mx-h-500 mx-w-500">
                        <template v-for="(msg,index) in $auth.setting.admin_default_msg">
                          <b-list-group-item class="p-0" :key="index">
                            <b-button v-b-toggle="msg.heading" variant="link" class="w-100 text-left">{{ msg.heading }}</b-button>
                          </b-list-group-item>
                          <b-collapse :id="msg.heading" :key="'br'+index" accordion="my-accordion">
                            <b-list-group>
                              <template v-for="(message,i) in msg.messages">
                                  <b-list-group-item :key="'er'+i" href="javascript:void(0)" @click="setMessage(message)"> {{ message }}</b-list-group-item>
                              </template>
                            </b-list-group>
                          </b-collapse>
                        </template>
                      </b-list-group>
                    </b-popover>
                    <!-- <b-dropdown
                      text=""
                      dropup
                      class="m-md-2"
                      no-caret
                      variant="link"
                      menu-class="dropmenu-class"
                    >
                      <template #button-content>
                        <i class="mdi mdi-arrow-down font-size-20"></i>
                      </template>
                      <template
                        v-if="
                          $auth.setting.admin_default_msg &&
                          $auth.setting.admin_default_msg.length
                        "
                      >
                        <template
                          v-for="(message, index) in $auth.setting
                            .admin_default_msg"
                        >
                          <b-dropdown-divider
                            :key="index"
                            v-if="index != 0"
                          ></b-dropdown-divider>
                          <b-dropdown-item
                            :key="index + '-12'"
                            @click="setMessage(message)"
                            style="min-width: 500px"
                            link-class="dropdown-tag"
                            >{{ message }}</b-dropdown-item
                          >
                        </template>
                      </template>
                    </b-dropdown> -->
                  </div>
                  <div class="col">
                    <div class="position-relative">
                      <!-- <template v-if="$auth.setting.admin_default_msg && $auth.setting.admin_default_msg.length && typeform.message.length > 1 
                            && typeform.message.startsWith('/')">
                        <Autocomplete :items="$auth.setting.admin_default_msg.reduce((acc,msgs) => msgs.heading.toLowerCase().startsWith(typeform.message.toLowerCase().replace('/','')) && acc.concat(msgs.messages) || acc, [])" @onItemSelect="setMessage" ></Autocomplete>
                      </template> -->
                      <input
                        id="sender_id"
                        name="sender_id"
                        type="hidden"
                        v-model="userDetails.user_id"
                        class="form-control"
                      />

                      <!-- <textarea rows="1" style="height:1em;" id="text"></textarea> -->

                      <textarea
                        id="message"
                        name="message"
                        type="text"
                        ref="chatInput"
                        v-model="typeform.message"
                        placeholder="Send Message"
                        class="form-control chat-input"
                      ></textarea>

                      <!-- <div class="chat-input-links">
                                        <ul class="list-inline mb-0">
                                            <li class="list-inline-item"><a href="#" data-toggle="tooltip" data-placement="top" title="Emoji"><i class="mdi mdi-emoticon-happy-outline"></i></a></li>
                                            <li class="list-inline-item"><a href="#" data-toggle="tooltip" data-placement="top" title="Images"><i class="mdi mdi-file-image-outline"></i></a></li>
                                            <li class="list-inline-item"><a href="#" data-toggle="tooltip" data-placement="top" title="Add Files"><i class="mdi mdi-file-document-outline"></i></a></li>
                                        </ul>
                                    </div> -->
                    </div>
                  </div>
                  <div class="col-auto">
                    <b-button
                      type="submit"
                      variant="secondary"
                      class="
                        btn btn-primary btn-rounded
                        chat-send
                        w-md
                        waves-effect waves-light
                      "
                      ><span class="d-none d-sm-inline-block mr-2">Send</span>
                      <i class="mdi mdi-send"></i
                    ></b-button>
                    <!-- <button type="submit" class="btn btn-primary btn-rounded chat-send w-md waves-effect waves-light"><span class="d-none d-sm-inline-block mr-2">Send</span> <i class="mdi mdi-send"></i></button> -->
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <CustomNotes v-if="showCustomNotes"  :userCustomNotes="userCustomNotes" :userDetails="userDetails" @hidden="showCustomNotes = false" :userMessage="getUserMessage" />

  </Layout>
</template>
<style scoped>
.message-panel {
  white-space: nowrap;
  max-width: 50vw;
  padding-bottom: 6px;
}
.message-panel::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.message-panel::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #f5f5f5;
}

.message-panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #555; */
}
.dropdown-tag {
  white-space: normal;
}

.dropmenu-class {
  max-height: 200px;
  overflow-y: scroll;
}
.mx-h-500{
  max-height: 50vh;
  overflow-y: auto;
}
.mx-w-500{
  max-width: 100%;
  width:500px;
}
.exporbtn{
  float:right;
  position: relative;
}

</style>