import { routes } from '../config/api_routes'

export const customerService = {

    getCustomer: (formData) => window.axios.post(routes.getCustomerApi,formData),

    customerExportCsv: (formData) => window.axios.post(routes.customerExportToCsvApi, formData),

    getCustomerProfile: (formData) => window.axios.post(routes.getCustomerProfileApi,formData),

    updateCustomerProfile: (formData) => window.axios.post(routes.updateCustomerProfileApi,formData),

    getCustomerOrder: (formData) => window.axios.post(routes.getCustomerOrderApi,formData),
    
    codStatusUpdate: (formData) => window.axios.post(routes.codStatusUpdateApi,formData),
}