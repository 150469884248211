<script>
import pagination from "laravel-vue-pagination";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header.vue";
import { debounce, get_currency, get_decimal, success_message } from '../../Helper/helper';
import { orderService } from '../../services';
export default {
  components: { pagination, Layout, PageHeader },
  props:['customer_id'],
  data() {
    return {
      loading:false,
      fields: [
        { label: "Order #", key: "id", sortable: false },
        { label: "Note", key: "customer_note", sortable: false },
      ],
      search: '',
      currency: get_currency(),
      decimal: get_decimal(),
      customer_notes: null,
      searchChanged:debounce(()=>{
          this.getCustomerNotes();
      },500),
    };
  },
  mounted(){
      this.getCustomerNotes();
  },
  methods: {
    getCustomerNotes(page = 1) {
      this.loading = true;
      orderService.getCustomerNotes({customer_id: this.customer_id, page: page, search:this.search })
        .then((response) => {
          this.loading = false;
          this.customer_notes = response.data.result;
        });
    },
  },
};
</script>
<template>
  <div>
    <PageHeader :title="trans.get('__JSON__.Customer Notes')" >
      <template #right>
        <div class="search-box mr-2 mb-0 d-inline-block form-group">
                      <div class="date-range-list">
        <label>{{trans.get('__JSON__.Search')}} :</label>
        <div class="position-relative">
          <input
            type="text"
            class="form-control"
            v-model="search"
            @keyup="searchChanged"
            :placeholder="trans.get('__JSON__.Search...')"
          />
          <i class="bx bx-search-alt search-icon"></i>
        </div>
        </div>
        </div>
      </template>
    </PageHeader>
    <div class="row">
        <div class="col-12 table-responsive">
            <b-table
                :items="customer_notes && customer_notes.data || []"
                :fields="fields"
                :busy="loading"
                show-empty
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                </template>
                <template v-slot:cell(id)="data">
                    <span>#{{ data.value }}</span>
                </template>
            </b-table>  
        </div>
        <div class="row col-sm-5 offset-sm-7" v-if="customer_notes">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <pagination
                    :data="customer_notes"
                    :limit="1"
                    @pagination-change-page="getCustomerNotes"
                    ></pagination>
                </ul>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<style>
</style>