<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { pageService } from "../../services";
import { success_message, error_message } from "../../Helper/helper";

/**
 * Starter component
 */
export default {
  components: { Layout,PageHeader,pagination,pageService},
  data() {
    return {
      sortBy: "id",
      sortDesc: true,
      pages: [],
      paginations: {},
      limit: 1,
      search: "",
      config: config,
      loading: true,
      fields: [
        {
          key: "page_name",
          label: this.trans.get("__JSON__.Page Name"),
          sortable: true,
        },
        {
          key: "page_title",
          label: this.trans.get("__JSON__.Page Title"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getContentPage();
  },
  methods: {
    getContentPage(page){
      this.loading = true;
      pageService.getContentPage({
        search: this.search,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        page: page && page > 0 ? page : 1,
      })
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.pages = response.data.result.data;
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getContentPage();
    },
    deleteById(id){
      pageService.deleteContentPage({id:id})
        .then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getContentPage();
          } else {
            error_message(response.data.message);
          }
        });
    }
  }
};
</script>

<template>
  <Layout>
    <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-tag"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Content Pages') }}</h4>
          </div>
          <div class="page-title-right">
             <router-link :to="{name: 'content-page-add'}" class="btn btn-success btn-rounded mr-2">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Content Page') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12 list-table-main">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <!-- <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-12 table-main-list cuisine-table table-responsive">
                <b-table
                  :items="pages"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>

                  <template v-slot:cell(page_name)="data">
                    <h5 class="font-size-14 mb-1">
                      <div>{{data.item.page_name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(page_title)="data">
                    <h5 class="font-size-14 mb-1">
                      <div>{{data.item.page_title}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        :to="{name: 'content-page-edit',params: { id: base64(data.item.id) }}"
                        class="btn btn-success btn-sm btn-rounded ml-1"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                      <!-- <a :href="config.appurl+'page/'+base64(data.item.id)+'/'+data.item.vendor_id+'/'+data.item.page_title.toLowerCase().replaceAll(' ','-')" class="btn btn-primary btn-sm btn-rounded" target="_blank"> {{ trans.get('__JSON__.View') }} </a> -->
                      <a :href="config.appurl+'page/'+$auth.setting.app_name+'/'+data.item.page_title.toLowerCase().replaceAll(' ','-')" class="btn btn-primary btn-sm btn-rounded" target="_blank"> {{ trans.get('__JSON__.View') }} </a>
                      <button
                        type="submit"
                        class="btn btn-danger btn-sm btn-rounded ml-1"
                        @click="deleteById(base64(data.item.id))"
                      >{{ trans.get('__JSON__.Delete') }}</button>
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Pages') }}</p>
                  </template>

                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getContentPage"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
      
  </Layout>
</template>