<script>
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import { settingService } from "../../../services";
import { required } from "vuelidate/lib/validators";

import { error_message } from '../../../Helper/helper';
import { success_message } from '../../../Helper/helper';
import config from '../../../config';


/**
 * Starter component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      config: config,
      checked: true,
      setting:{},
      errors: '',
      setting: {
        google_map_key: "",
        system_latitude: "",
        system_longitude: "",
      }, 
    };
  }, 
  validations: {
    setting: {
      google_map_key: {
        required,
      },
      system_latitude: {
        required,
      },
      system_longitude: {
        required,
      },
    },
  },
  mounted() {
    this.getSetting();
  },
   methods: {
   getSetting(){
      settingService.getSetting()
      .then(response => {
        this.setting = response.data.result;
      });
    },
    keyInIt(){
      this.$v.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
       const fd = new FormData();
       fd.append("google_map_key", this.setting.google_map_key);
       fd.append("system_latitude", this.setting.system_latitude);
       fd.append("system_longitude", this.setting.system_longitude);
        fd.append("type",5);
       settingService.updateSetting(fd).then((response) => {
          if (response.data.code === 200) {
            if(response.data.result){
              this.$auth.updateSetting(response.data.result.settings)
            }
            success_message(response.data.message);
          } else {
              error_message(response.data.message);
          }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="keyInIt">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body setting-main">
                  
                 <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>{{ trans.get('__JSON__.Google Map') }}</h4>
                                <p class="text-danger">Note: Please don't change value of map key this setting will use for developer only</p>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6>{{ trans.get('__JSON__.Google Map Key') }}</h6>
                                </div>
                                 <div class="col-md-6 order-value">
                                      <b-form-group>
                                          <b-form-input
                                            id="google_map_key"
                                            name="google_map_key"
                                            v-model="$v.setting.google_map_key.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Google Map Key')"
                                            :class="{ 'is-invalid':  $v.setting.google_map_key.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.google_map_key.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.google_map_key.required"
                                            >{{ trans.get('__JSON__.Google Map Key Required') }}.</span>
                                          </div>
                                        </b-form-group>
                                    <!-- <input id="google_map_key" name="google_map_key" v-model="setting.google_map_key" type="text" class="form-control"/> -->
                                </div>
                             </div>

                              <div class="row mt-4">
                                <div class="col-md-6">
                                    <h6>{{ trans.get('__JSON__.Latitude') }}</h6>
                                        <b-form-group>
                                          <b-form-input
                                            id="system_latitude"
                                            name="system_latitude"
                                            v-model="$v.setting.system_latitude.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Latitude')"
                                            :class="{ 'is-invalid':  $v.setting.system_latitude.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.system_latitude.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.system_latitude.required"
                                            >{{ trans.get('__JSON__.Latitude Required') }}.</span>
                                          </div>
                                        </b-form-group>
                                    <!-- <input id="system_latitude" name="system_latitude" v-model="setting.system_latitude" type="text" class="form-control"/> -->
                                </div>
                                <div class="col-md-6">
                                    <h6>{{ trans.get('__JSON__.Longitude') }}</h6>
                                    <b-form-group id="input-group-1" label-for="name">
                                          <b-form-input
                                            id="system_longitude"
                                            name="system_longitude"
                                            v-model="$v.setting.system_longitude.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Longitude')"
                                            :class="{ 'is-invalid':  $v.setting.system_longitude.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.system_longitude.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.system_longitude.required"
                                            >{{ trans.get('__JSON__.Longitude Required') }}.</span>
                                          </div>
                                        </b-form-group>
                                    <!-- <input id="system_longitude" name="system_longitude" v-model="setting.system_longitude" type="text" class="form-control"/> -->
                                </div>
                             </div>
                             
                          </div>
                        </div>
                 </div>

                
                <div class="btn-update">
                    <div class="col-sm-6">
                    <button type="submit" v-if="$auth.hasPermission(config.permissions.configuration_write)" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update') }}</button>
                    </div>
                </div>

            </div>
        </div>
      </div>
    </div>
   </b-form>
</template>