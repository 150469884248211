import { routes } from "../config/api_routes";

export const orderService = {
    getOrders: (filters, options) =>
        window.axios.post(routes.getOrdersApi, filters, options),
    getOrder: id => window.axios.post(routes.getOrderApi, { order_id: id }),
    getOrdersCount: filters =>
        window.axios.post(routes.getOrdersCountApi, filters),
    exportOrders: filters => window.axios.post(routes.exportOrdersApi, filters),
    getNewOrders: filters => window.axios.post(routes.getNewOrdersApi, filters),
    checkAvailableDrivers: data =>
        window.axios.get(routes.checkAvailableDriversApi, { params: data }),
    assignDriver: data => window.axios.post(routes.assignDriverApi, data),
    // customer Notes
    getOrderCustomerNote: data =>
        window.axios.post(routes.getOrderCustomerNoteApi, data),
    addOrderCustomerNote: data =>
        window.axios.post(routes.addOrderCustomerNoteApi, data),
    getCustomerNotes: data =>
        window.axios.post(routes.getCustomerNotesApi, data),

    // app apis
    // ADD APP API BELOW
    changeOrderStatus: data =>
        window.axios.post(routes.changeOrderStatusApi, data),
    changeOrderStatusDelivered: data =>
        window.axios.post(routes.changeOrderStatusDeliveredApi, data),
    ownerOrderStatus: data =>
        window.axios.post(routes.ownerOrderStatusApi, data),
    cancelOrder: data => window.axios.post(routes.cancelOrderApi, data),
    petpoojaSync: data =>
        window.axios.get(routes.petpoojaSyncApi, { params: data }),

    //dunzo api
    sendToDunzo: data => window.axios.post(routes.sendToDunzoApi, data),
    //Not Responding Customer Email
    notRespondingCustomer: data =>
        window.axios.post(routes.notRespondingCustomerApi, data),
    delayOrderCustomer: data =>
        window.axios.post(routes.delayOrderCustomerApi, data),
    checkMailtemplateActive: data =>
        window.axios.get(routes.checkMailtemplateActiveApi, { params: data }),

    //Pidge api
    sendToPidge: data => window.axios.post(routes.sendToPindgeApi, data)
};
