
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { locationService } from "../../services";
import { set_axios_defaults, error_message,debounce } from "../../Helper/helper";

import { required } from "vuelidate/lib/validators";

export default {
  components: { pagination, locationService },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      countries: [],
      sortBy: sortBy || "country_name",
      countryCount: '',
      addModal: false,
      editModal: false,
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      paginations: {},
      limit: 1,
      search: "",
      status: status == undefined ? 1 :status,
      page: 1,
      getCountryById: {
        id: "",
        country_name: "",
        status: "",
      },
      config: config,
      error: "",
      loading :true,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'country-list', query: {status,orderBy,sortBy}})
        }
        this.getCountry();
        this.init = false;
      },500),
      fields: [
        { key: "id", label: this.trans.get("__JSON__.Id"), sortable: true },
        {
          key: "country_name",
          label: this.trans.get("__JSON__.Country Name"),
          sortable: true,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false
        }
      ],
    };
  },
  validations: {
    getCountryById: {
      country_name: {
        required,
      },
    },
  },
  mounted() {
    this.getCountry();
  },
  methods: {
    getCountry(page) {
      this.loading = true;
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      }
      locationService
        .getCountry(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.data;
          this.countries = response.data.result.data.data;
          this.countryCount = response.data.result.countryCount;
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCountry();
    },
    countryUpdate() {
      this.$v.getCountryById.$touch();
      if (this.$v.getCountryById.$invalid) {
        return;
      } else {
        locationService
          .updateCountryById(this.getCountryById)
          .then((response) => {
            if (response.data.code === 200) {
              this.$toasted.success(response.data.message);
              this.editModal = false;
              this.getCountry();
            } else {
              this.$toasted.error(response.data.message);
            }
          });
      }
    },
    editCountry(id) {
      this.fetchCountry(id);
    },
    fetchCountry(id) {
      return locationService.getCountryById({ id: id }).then((response) => {
        if (response.data.code === 200) {
          this.getCountryById = response.data.result;
          this.editModal = true;
        } else {
          this.$toasted.error(response.data.message);
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-map"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Country') }}({{countryCount}})</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="search-box mr-2 mb-2 d-inline-block">
                  <div class="date-range-list form-group">
                    <label>{{ trans.get('__JSON__.Search') }} :</label>
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        @input="searchData()"
                        v-model="search"
                        :placeholder="trans.get('__JSON__.Search Placeholder')"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list country-table table-responsive">
                <b-table
                  :items="countries"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>

                  <template v-slot:cell(id)="data">{{ data.index + 1 }}</template>

                  <template v-slot:cell(country_name)="data">
                    <h5 class="font-size-14">{{data.item.country_name}}</h5>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status == '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <button
                        v-if="$auth.hasPermission(config.permissions.location_write)"
                        type="button"
                        @click="editCountry(data.item.id)"
                        class="btn btn-primary btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit') }}</button>
                      <router-link
                        class="btn btn-success btn-sm btn-rounded ml-1"
                        :to="{ name: 'state-list', params: { country_id: base64(data.item.id) }}"
                      >{{ trans.get('__JSON__.View') }}</router-link>
                    </div>
                  </template>
                   <template #empty>
                     <p class="text-center">{{ trans.get('__JSON__.No Country') }}</p>
                   </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getCountry"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Country Modal Start -->

      <b-modal
        id="modal-scoped"
        v-model="editModal"
        :title="trans.get('__JSON__.Edit Country')"
        v-if="$auth.hasPermission(config.permissions.location_write)"
        centered
      >
        <b-form @submit.prevent="countryUpdate">
          <div class="row">
            <div class="form-group">
              <input
                id="id"
                name="id"
                type="hidden"
                v-model="getCountryById.id"
                class="form-control"
              />
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-1"
                :label="trans.get('__JSON__.Country Name')"
                label-for="country_name"
              >
                <b-form-input
                  id="country_name"
                  name="country_name"
                  v-model="$v.getCountryById.country_name.$model"
                  type="text"
                  :placeholder="trans.get('__JSON__.Country Name Placeholder')"
                  :class="{ 'is-invalid': $v.getCountryById.country_name.$error }"
                ></b-form-input>
                <div v-if="$v.getCountryById.country_name.$error" class="invalid-feedback">
                  <span
                    v-if="!$v.getCountryById.country_name.required"
                  >{{ trans.get('__JSON__.Please enter country name') }}</span>
                </div>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                :label="trans.get('__JSON__.Status')"
                label-for="Status"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="getCountryById.status"
                      name="status"
                      value="1"
                      checked
                    >{{ trans.get('__JSON__.Active') }}</b-form-radio>
                  </div>
                  <div class="col-sm-6">
                    <b-form-radio
                      v-model="getCountryById.status"
                      name="status"
                      value="0"
                    >{{ trans.get('__JSON__.Inactive') }}</b-form-radio>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template slot="modal-footer">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="countryUpdate"
            >{{ trans.get('__JSON__.Submit') }}</b-button>
            <b-button class="float-left" @click="editModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Edit Country Modal End -->
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.country-table .table thead th:nth-child(4){
  pointer-events: none;
}
</style>