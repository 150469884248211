<template>
    <div class="text-center">
    <b-button v-if="$auth.hasPermission(config.permissions.order_write)" variant="primary" size="sm" @click="checkDrivers()">{{trans.get('__JSON__.Assign')}}</b-button>
    <i v-else v-b-tooltip="'Need Permission'" class="mdi mdi-information h4 text-danger"></i>    
    <b-modal :id="'modal-assign-'+order.order_id"
        size='md'
        lazy
        :ok-title="trans.get('__JSON__.Assign Driver')"
        @show="getDeliveryZones"
        @ok="assignDriver"
        scrollable
        >
        <template #modal-header="{ close }">
            <div class="d-flex justify-content-between w-100">
                <h4 class="mb-0">{{trans.get('__JSON__.Assign Driver')}}</h4>
                <h4 class="mb-0">{{order.order_id}}</h4>
            </div>
            <button type="button" aria-label="Close" class="close" @click="close()"><i class="mdi mdi-close"></i> </button>
        </template>
        <div class="row">
            <div class="col-12">
                <b-form role="form">
                    <b-form-group :label="trans.get('__JSON__.Delivery Zone')">
                        <multiselect label="last_name" track-by="user_id" @input="onDeliveryZoneChange" v-model="selected" :options="options"></multiselect>
                    </b-form-group>
                    <b-form-group :label="trans.get('__JSON__.Search')">
                        <b-input type="text" v-model.lazy="search" :placeholder="trans.get('__JSON__.Search')+'...'" />
                    </b-form-group>
                    
                    <b-table striped hover :items="drivers.filter(d => search!=''?(d.firstname+' '+d.lastname).toLowerCase().indexOf(search.toLowerCase()) != -1:true ) || []" :fields="fields">
                        <template #cell(profile_path)="data">
                            <img :src="data.value" class="avatar-xs rounded-circle"
                                @error="$event.target.src = config.no_image"
                                alt=""/>
                        </template>
                        <template #cell(firstname)="data">
                            <span>{{data.value +' '+data.item.lastname}}</span>
                        </template>
                        <template #cell(driverid)="data">
                            <b-form-checkbox
                                v-model="selected_driver"
                                :value="data.value"
                                :id="'order-driver-'+data.value"
                                ></b-form-checkbox>
                        </template>
                    </b-table>
                </b-form>
            </div>
        </div>
    </b-modal>
    </div>

</template>

<script>
import Multiselect from "vue-multiselect";
import config from '../../../config';
import { orderService, vendorService, } from '../../../services'
export default {
    props: ['order'],
    components:{Multiselect},
    data(){
        return {
            config: config,
            frenchise_id: '0',
            options: [],
            selected: {last_name:'All',user_id:'0'},
            search: '',
            fields: [
                { key: "profile_path", label: this.trans.get("__JSON__.Profile Image"), sortable: false },
                { key: "firstname", label: this.trans.get("__JSON__.Driver Name",{driver:this.$auth.getDriverName()}), sortable: false },
                { key: "driverid", label: "#", sortable: false },
            ],
            drivers: [],
            selected_driver: {last_name:'All',user_id:'0'}
        }
    },
    methods:{
        checkDrivers(){
            this.$bvModal.show('modal-assign-'+this.order.order_id)
            orderService.checkAvailableDrivers({order_id:this.order.base_id, frenchise_id: this.frenchise_id})
                .then(response => {
                    this.drivers = response.data.result;
                })
        },
        getDeliveryZones(){
            vendorService.getDeliveryZones()
                .then(response => {
                    this.selected_driver = {last_name:'All',user_id:'0'};
                    this.options = [{last_name:'All',user_id:'0'},...response.data.result];
                });
        },
        onDeliveryZoneChange(){
            this.frenchise_id = '0';
            if(this.selected){
                this.frenchise_id = this.selected.user_id;
            }
            this.checkDrivers();
        },
        assignDriver(){
            if(this.selected_driver.length == 0){
                this.$toasted.error(this.trans.get('__JSON__.Please select driver'));
                return false;
            }
            orderService.assignDriver({
                order_id: this.order.base_id,
                driver_id: this.selected_driver
            })
            .then(response => {
                this.$toasted.success(response.data.message);
                this.$bvModal.hide('modal-assign-'+this.order.order_id)
            })
        }
    }
}
</script>