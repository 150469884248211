<template>
  <div class="row">
    <div class="col-12 border-bottom ">
        <div class="row mb-3">
            <div class="col-lg-4">
                <div class="media" v-if="driver">
                    <div class="mr-3">
                        <img :src="driver.profile_path" @error="$event.target.src=no_image" alt="" class="avatar-md rounded-circle img-thumbnail">
                    </div>
                    <div class="media-body align-self-center">
                        <div class="text-muted">
                            <h5 class="mb-1">{{driver.firstname}}</h5>
                            <p class="mb-0">{{driver.email}}</p>
                            <p class="mb-0">{{trans.get('__JSON__.Contact')}} : {{driver.country_code}} {{driver.contactno}}</p>
                            <!-- <p class="mb-0">{{driver.location_address}}</p> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 align-self-center">
                <div class="text-lg-center mt-4 mt-lg-0">
                    <div class="row" v-if="dayWise">
                        <div class="col-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.Today')}}</p>
                                <h5 class="mb-0"><span v-html="currency"></span> {{(dayWise.s_today || 0).toFixed(decimal)}}</h5>
                            </div>
                        </div>
                        <div class="col-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.7 Days')}}</p>
                                <h5 class="mb-0"><span v-html=" currency"></span> {{(dayWise.s_week|| 0).toFixed(decimal)}}</h5>
                            </div>
                        </div>
                        <div class="col-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.This Month')}}</p>
                                <h5 class="mb-0"><span v-html="currency"></span> {{(dayWise.s_month|| 0).toFixed(decimal)}}</h5>

                            </div>
                        </div>
                        <div class="col-3">
                            <div>
                                <p class="text-muted text-truncate mb-2">{{trans.get('__JSON__.Last Month')}}</p>
                                <h5 class="mb-0"><span v-html="currency"></span> {{(dayWise.last_month|| 0).toFixed(decimal)}}</h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row border-bottom mt-3">
            <div class="col-12">
                <h4>{{trans.get('__JSON__.Last 7 Days')}}</h4>
            </div>
            <div class="col-lg-12">
                <div class="table-responsive table-border">
                    <b-table
                        :items="sevenDayEarning || []"
                        :fields="fields"
                        show-empty
                        :busy="loading"
                        class="table-centered table-nowrap table-hover order-menu"
                    >
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                            </div>
                        </template>
                        <template #cell(date)="data">
                            <span class="font-weight-bold">{{data.value}}</span>
                        </template>
                        <template #cell(order_count)="data">
                            {{data.value}}
                        </template>
                        <template #cell(cod_amount)="data">
                            <span v-html="currency"></span> {{(data.value || 0).toFixed(decimal)}}
                        </template>
                        <template #cell(driver_earning)="data">
                            <span v-html="currency"></span> {{(data.value || 0).toFixed(decimal)}}
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { get_currency, get_decimal } from '../../../Helper/helper'
import { driverService, restaurantService } from '../../../services'
export default {
  props: [ 'driver_id', 'driver' ],
  data:function(){
      return {
        currency: get_currency(),
        decimal: get_decimal(),
        dayWise: null,
        sevenDayEarning: null,
        loading:true,
        fields:[
            { key: "date", label: this.trans.get("__JSON__.Date"), sortable: false },
            { key: "order_count", label: this.trans.get("__JSON__.Orders Finished"), sortable: false },
            { key: "cod_amount", label: this.trans.get("__JSON__.Cash Collected"), sortable: false },
            { key: "driver_earning", label: this.trans.trans("__JSON__.Driver Earning",{driver:this.$auth.getDriverName()}), sortable: false },
        ]
      }
  },
  mounted(){
      this.getDayWiseData()
      this.getDriverSevenDayEarning()
  },
  methods:{
      getDayWiseData(){
          driverService.getDayWiseData(this.driver_id)
            .then(response => {
                this.dayWise = response.data.result;
            })
      },
      getDriverSevenDayEarning(){
            this.loading = true,
            driverService.getDriverSevenDayEarning({driver_id: this.driver_id})
                .then(response => {
                    this.loading = false,
                    this.sevenDayEarning = response.data.result;
                })
      }
  }
}
</script>

<style>

</style>