
<script>
import OrderShow from './../../orders/order_tabs/order-show';
export default {
  components: {OrderShow},
  props:[ 'drivers', 'loading','withAction' ],
  data(){
    return {
      fields:[
        { key: "fullname", label: this.$auth.setting && this.$auth.setting.driver_name +' '+ this.trans.get("__JSON__.Name"), sortable: false },
        { key: "driverid", label: this.$auth.setting && this.$auth.setting.driver_name +' '+ this.trans.get("__JSON__.ID"), sortable: false },
        { key: "contactno", label: this.trans.get("__JSON__.Phone Number"), sortable: false },
        { key: "user", label: this.trans.get("__JSON__.Delivery Zone"), sortable: false },
        ...this.withAction == ""?[{ key: "ongoing_requests", label: this.trans.get("__JSON__.Active Orders"), sortable: false }]:[],
      ],
      showModal: false,
      orderId: null,
      thirdPartyOrder:false
    }
  },
  methods:{
    showOrder(id,status){
      this.orderId = id;
      this.thirdPartyOrder = status;
      this.showModal = true;
    }
  }
}
</script>
<template>
<div class="row">
  <div class="col-12 m-order-main">
   <div class="table-responsive">
    <b-table
      :items="drivers && drivers.data || []"
      :fields="fields"
      ref="selectableTable"
      show-empty
      :busy="loading"
      class="table-centered table-nowrap table-hover table-main-border order-menu"
      selectable
      select-mode="single"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{trans.get('__JSON__.Loading...')}}</strong>
        </div>
      </template>
      <template #cell(fullname)="data">
        <router-link :to="{name: ($auth.partner_login?'vendor-driver-details':'driver-details'), params:{'driver_id': base64(data.item.driverid)}}">
        <span class="font-weight-bold">{{ data.value }}</span>
        </router-link>
      </template>
      <template #cell(driverid)="data">
        <!-- {{ data.item.restaurant && data.item.restaurant.name || data.item.id}} -->
        {{ data.value }}
      </template>
      <template #cell(customer_name)="data">
        <!-- {{ data.item.user && (data.item.user.user_name +" "+ data.item.user.last_name) || data.item.id}} -->
        {{ data.value}}
      </template>
      <template #cell(user)="data">
        {{ data.value && data.value.last_name }}
      </template>
      <template #cell(ongoing_requests)="data" v-if="withAction ==''">
        <template v-for="(order,index) in data.value">
          <b-link variant="link" @click="showOrder(order.base_id,false)" v-if="order.order.vendor_order_id == null" :key="order.orderid">#{{order.orderid}}</b-link>
          <b-link variant="link" @click="showOrder(order.base_id,true)" v-else :key="order.orderid">#{{order.order.vendor_order_id}}</b-link>
          <span :key="order.orderid+'-12'" v-if="(index+1) != data.value.length">, </span>
        </template>
      </template>
    </b-table>
    </div>
  </div>
  <OrderShow v-if="showModal"  :order-id="orderId" @hidden="showModal = false" :thirdPartyOrder="thirdPartyOrder"/>
</div>
</template>

<style>

</style>