import { render, staticRenderFns } from "./add-dine-in-sub-customization.vue?vue&type=template&id=c802d02c&"
import script from "./add-dine-in-sub-customization.vue?vue&type=script&lang=js&"
export * from "./add-dine-in-sub-customization.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports