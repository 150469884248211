<template>  
  <!-- View Detail Main Start -->
  <b-modal
    v-model="showModal"
    size="lg"
    scrollable
    title-class="font-18"
    hide-footer
    @hidden="onModalHide"
  >
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <h5>
        <!-- {{ (order && order.vendor_order_id == null) ? 'Yes':'No' }} -->
        {{trans.get('__JSON__.Order No')+' '+(order && order.vendor_order_id == null ? order && order.hash_id || '000' : order && order.customer_hash_id || '000' )}}

        <span class="badge badge-pill badge-soft-primary font-size-12" v-if="order && order.delivery_pickup_types == 'Delivery'">
          {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="order && order.delivery_pickup_types == 'Takeaway'">
          {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="order && order.delivery_pickup_types == 'Pickup'">
          {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
        </span>
        <span class="badge badge-pill badge-soft-pink font-size-12" v-if="order && order.delivery_pickup_types == 'Dine In'">
          {{trans.get('__JSON__.'+order && order.delivery_pickup_types)}}
        </span>
        <span class="badge badge-pill font-size-12" :class="{'badge-soft-success': order && (order.payment_method == 'Cash On Delivery'),'badge-soft-warning':order && (order.payment_method != 'Cash On Delivery')}">
          {{order && (order.payment_method == 'Cash On Delivery'?'COD':order.payment_method)}}
        </span>
        <span class="badge badge-pill badge-soft-secondary font-size-12" v-if="order && order.access">
          <i class="bx bx-phone-call"></i> {{trans.get('__JSON__.'+order && order.access && order.access.vendor_name)}}
        </span>
      </h5>
      <div>
      <a v-b-tooltip.hover.v-light :title="trans.get('__JSON__.Print')" :href="config.appurl+'print-order/'+base64(order && order.id+','+$auth.getVendorId())" target="_blank"><i class="mdi mdi-printer font-size-15"></i></a>
      <button @click="close()" class="close">
        <i class="fas fa-times"></i>
      </button>
      </div>
    </template>
    <b-tabs
      justified
      nav-class="nav-tabs-custom mt-0 order-tabs"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Order Details')}}</span
          >
        </template>
        <order-details :order="order" :loading="orderLoading" :thirdPartyOrder="thirdPartyOrder"/> 
      </b-tab>
      <b-tab >
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Order Item Details')}}</span>
        </template>
        <item-order-details :order="order" :loading="orderLoading" :thirdPartyOrder="thirdPartyOrder"/> 
      </b-tab>
      <b-tab>
          <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                  <i class="fas fa-list-ul"></i>
              </span>
              <span class="d-none d-sm-inline-block">{{trans.get('__JSON__.Log')}}</span>
          </template>
          <OrderLog :order="order" :loading="orderLoading" /> 
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import OrderDetails from './order-details'
import ItemOrderDetails from './item-order-detail'
import { orderService } from '../../../services'
import config from '../../../config'
import OrderCustomerNotes from './order-customer-notes.vue'
import OrderLog from './order-log.vue'
export default {
    components : {OrderDetails, ItemOrderDetails, OrderCustomerNotes, OrderLog},  
    // props :['order-id'],
    props:{
      'order-id':{
        type:String,
      },
      thirdPartyOrder:{
        type:Boolean,
        default:false,
      } 
    },
    data(){
        return {
            config:config,
            orderLoading: false,
            showModal: false,
            order: null
        }
    },
    mounted(){
        this.viewDetails()
    },
    methods : {
      viewDetails(){
        this.showModal = true;
        this.orderLoading = true;
        orderService.getOrder(this.orderId)
          .then(res => {
            this.order = res.data.result;
            this.orderLoading = false;
          })
      },
      onModalHide(){
        this.showModal = false;
        this.$emit('hidden');
      }
    }
}
</script>

<style>

</style>