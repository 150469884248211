
<script>
import { required } from "vuelidate/lib/validators";
import config from "../../config";
import { error_message } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { pageService } from "../../services";
import tinymce from "vue-tinymce-editor";

export default {
  components: {config,ckeditor: CKEditor.component,pageService,tinymce},
  data() {
    return {
      config: config,
      editor: ClassicEditor,
      typeform: {
        page_name: "",
        page_title: "",
        page_content: "",
      },
      content_options: [
        {
          text: "Content",
          value: 1,
        },
        {
          text: "Link",
          value: 0,
        }
      ],
      display_in: { customer: "1", partner: "1", driver: "1" },
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | l      ink image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
    };
  },
  validations: {
    typeform: {
      page_name: {
        required,
      },
      page_title: {
        required,
      },
      page_content: {
        required,
      },
    },
  },
  methods: {
    addPage(e){
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }
      else{       
        pageService.addContentPage(this.typeform)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "content-list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    }
  },
};
</script>

<template>
    <form @submit.prevent="addPage">
        <div class="card">
            <div class="card-body">
                <p class="card-title-desc"></p>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="required">{{ trans.trans('__JSON__.Page Name') }}</label>
                            <input
                                id="page_name"
                                v-model="$v.typeform.page_name.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Name')"
                                name="page_name"
                                :class="{ 'is-invalid': $v.typeform.page_name.$error }"
                            />
                            <div v-if="$v.typeform.page_name.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_name.required">{{ trans.get('__JSON__.Please Enter Page Name') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="required">{{ trans.trans('__JSON__.Page Title') }}</label>
                            <input
                                id="page_title"
                                v-model="$v.typeform.page_title.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Title')"
                                name="page_title"
                                :class="{ 'is-invalid': $v.typeform.page_title.$error }"
                            />
                            <div v-if="$v.typeform.page_title.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_title.required">{{ trans.get('__JSON__.Please Enter Page Title') }}</span>
                            </div>
                        </div>
                    </div>       
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label class="required">{{ trans.trans('__JSON__.Page Content') }}</label>
                            <!-- <ckeditor
                                id="page_content"
                                v-model="$v.typeform.page_content.$model"
                                type="text"
                                class="form-control"
                                :placeholder="trans.trans('__JSON__.Page Content')"
                                name="page_content"
                                :editor="editor"
                                :class="{ 'is-invalid': $v.typeform.page_content.$error }"
                            ></ckeditor> -->
                            <tinymce
                              id="d1"
                              v-model="$v.typeform.page_content.$model"                              
                              :plugins="plugins"
                              :toolbar="toolbar"
                              :other_options="options"
                              :class="{ 'is-invalid': $v.typeform.page_content.$error }"
                            >
                            </tinymce>
                            <div v-if="$v.typeform.page_content.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.page_content.required">{{ trans.get('__JSON__.Please Enter Page Content') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Add Content Page') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
    </form>
</template>

