<script>
import Layout from '../../layouts/main';
import PageHeader from '../../components/page-header';

import OrderList from './list';
import { orderService } from '../../services';
import { get_decimal } from '../../Helper/helper';
import moment from 'moment';
import config from "../../config";
/**
 * Products-order component
 */
export default {
  components: { Layout, PageHeader, OrderList },
  data() {
    return {
        filters: null,
        config:config,
        orders: null,
        decimal: get_decimal(), 
        moment
    }
  },
  methods:{
    exportOrders() {
      // this.loading = true;
      orderService
        .exportOrders({
          ...this.filters,
        })
        .then((response) => {
          // this.loading = false;
          this.orders = response.data.result;
          this.downloadCSV()
        })
        .catch(function (error) {
          // this.loading = false;
          console.log(error);
        });
    },
    getSelectionPrices(items){
      return parseFloat(items.length > 0 ? items.reduce(function(accu, item){
        return accu + parseFloat(item.selection_price);
      },0): 0);
    },
    getPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_price || 0))).toFixed(this.decimal)
    },
    downloadCSV: function(){
      let orders = '\ufeff' + 'SR,Order ID,Customer Name,Customer Number,Customer Email,Order Date,'+this.$auth.getRestaurantName()+' Name,'+this.$auth.getRestaurantName()+' Number,Payment Method,Order Status,Coupon Code,Sub Total,Delivery Charge,Discount,Tax,Total Amount,Special Instruction,Device Type,Delayed By,Item Name,Item Price,Item Quantity,Item Customization,Delay Minutes\n'
      this.orders.forEach((el,ind) => {
        var line = (ind+1)+ ',' + el['id'] + ',' + el['customer']['customer_name'] + ',' 
          + el['customer']['mobile_number'] + ',' 
          + el['customer']['email'] + ',' + this.moment(el['created_at']).format('DD-MM-YYYY hh:mm A') + ',' 
          + (el['restaurant']?el['restaurant']['name']:'-') +',' 
          + (el['restaurant']?el['restaurant']['phone']:'-') +',' 
          + el['payment_method'] +',' 
          + el['order_status'] +',' 
          + (el['reddemed_coupon_order']?el['reddemed_coupon_order']['coupon']['coupon_code']:'--') +',' 
          + el['sub_total'] +',' 
          + el['delivery_fee'] +',' 
          + (el['reddemed_coupon_order']?parseFloat(el['reddemed_coupon_order']['original_price'] - el['reddemed_coupon_order']['discount_price']):'--') +',' 
          + (el['service_fee']?el['service_fee']:'--') +',' 
          + el['total_amount'] +','
          + (el['suggestion']?el['suggestion']:'--' )+','
          + el['device_type'] +',';
        if(el['eta'] && el['eta'] !== ''){
          if(el['order_status'] == 'ReadyToServe' || el['order_status'] == 'OnTheWay' || el['order_status'] == 'Arrived'){
            if(this.moment().diff(this.moment(el['eta'])) > 0){
              line += this.moment.duration(this.moment().diff(this.moment(el['eta']))).asMinutes().toFixed() + ' minutes,';
            }else{
              line += '--,';
            }
          }else if(el['order_status'] == 'Delivered'){
            if(this.moment(el['delivered_date']).diff(this.moment(el['eta'])) > 0){
              line += this.moment.duration(this.moment(el['delivered_date']).diff(this.moment(el['eta']))).asMinutes().toFixed()+' minutes,';
            }else{
              line += '--,';
            }
          }else{
            line += '--,';
          }
        } else {
          if(el['order_status'] == 'ReadyToServe' || el['order_status'] == 'OnTheWay' || el['order_status'] == 'Arrived'){
            if(el['future_delivery_date'] != '0000-00-00 00:00:00' && this.moment().diff(moment(el['future_delivery_date'])) > 0){
              line += this.moment.duration(this.moment().diff(this.moment(el['future_delivery_date']))).asMinutes()  +' minutes,';
            } else if (this.moment().diff(this.moment(el['created_at']).add(el['delivery_time'],'minutes')) > 0){
              line += this.moment.duration(moment().diff(this.moment(el['created_at']).add(el['delivery_time'],' minutes'))).asMinutes().toFixed(0)  +' minutes,';
            } else {
              line+='--,';
            }
          }else{
            line+='--,';
          }
        }
        var i = 0;
        do{
          line += (el['order_item'] && el['order_item'][i] && el['order_item'][i]['menu_item'] && el['order_item'][i]['menu_item']['item_name'].replaceAll(',','') || '') +',' 
                + (el['order_item'] && el['order_item'][i] && this.getPrice(el['order_item'][i]) || '0') +',' 
                + (el['order_item'] && el['order_item'][i] && el['order_item'][i]['quantity'] || '') +',' 
                + (el['order_item'] && el['order_item'][i] && el['order_item'][i]['menu_item_selections'].length > 0?el['order_item'][i]['menu_item_selections'].map((i) => i.selection_name).join('|'):'--')
                + ','
                + (i == 0 && el['eta'] && el['order_status'] == 'Delivered' && this.moment(el['delivered_date']).diff(this.moment(el['eta'])) > 0 ?this.moment.duration(this.moment(el['delivered_date']).diff(this.moment(el['eta']))).asMinutes().toFixed():'')
                + ''
                +'\n'
              
          i++;
          orders += line  
          line = ',,,,,,,,,,,,,,,,,,,';
        } while(el['order_item'].length > i);
        // orders += line
      })
      var blob = new Blob([ orders ], { "type" : "csv/plain" });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'orders-' + new Date().getTime() +'.csv'
      link.click()
    },
    updateFilters(filters){
      this.filters = filters;
    }
  }
}
</script>

<template>
 <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-copy-alt"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Orders') }}</h4>
          </div>
          <div class="page-title-right">
            <!-- <b-spinner v-if="loading" class="m-2" variant="primary" role="status"></b-spinner> -->
            <button
              @click="exportOrders()"
              v-if="$auth.hasSubAdminPermission(config.permissions.order_export)"
              class="btn btn-primary btn-rounded export-btn"
            >{{trans.get('__JSON__.Export To CSV')}}</button>
            <button
              @click="exportOrders()"
              v-if="$auth.hasSubAdminPermission(config.permissions.order_export)"
              class="btn btn-primary btn-rounded m-export-btn"
            ><i class="bx bx-archive-in"></i></button>
          </div>
        </div>
      </div>
    </div>
    <OrderList @filterChange="updateFilters" />
</Layout>
</template>
