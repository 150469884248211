import { routes } from '../config/api_routes'

export const pageService = {

    getContentPage: (formData) => {    
        return window.axios.post(routes.getContentPageApi,formData)
    },
    
    addContentPage: (formData) => {    
        return window.axios.post(routes.addContentPageApi,formData)
    },
    
    getContentPageById: (formData) => {    
        return window.axios.post(routes.getContentPageByIdApi,formData)
    },
    
    editContentPage: (formData) => {    
        return window.axios.post(routes.editContentPageApi,formData)
    },
    
    viewContentPage: (formData) => {    
        return window.axios.post(routes.viewContentPageApi,formData)
    },
    
    deleteContentPage: (formData) => {    
        return window.axios.post(routes.deleteContentPageApi,formData)
    }
}