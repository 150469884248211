<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Multiselect from "vue-multiselect";
import config from "../../config";
import {
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService, bannerService } from "../../services";
import { routes } from '../../config/api_routes';
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker, Multiselect },
  data() {
    let startDate = this.moment().startOf("day").subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      sortBy: "id",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      loading: true,
      totalSale:null,
      dateRange: { startDate, endDate },
      filters: {
        start_date: this.moment().subtract(6,'days').format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      isLoading : false,
      restaurant:[],
      selectedRestaurant:[],
      date_ranges: date_ranges,
      fields: [
        { key: "id", label: "#", sortable: false },
        { key: "restaurants", label: (this.$auth.getRestaurantName()+" Name"), sortable: false },
        { key: "name", label: "Customer Name", sortable: false },
        { key: "phone_number", label: "Phone", sortable: false },
        { key: "feedback", label: "Feedback", sortable: false },
        { key: "birthday", label: "Birthday", sortable: false },
        { key: "anniversary", label: "Anniversary", sortable: false },
        { key: "created_at", label: "Date", sortable: false },
        { key: "action", label: "Action", sortable: false },
      ],
      feedbackReprt: [],
      config:config,
    };
  },
  mounted() {
    this.getVendorDineRatingReport()
  },
  methods: {
    asyncFind(query) {
      this.isLoading =true;
      bannerService.getRestaurant(query).then((response) => {
        this.restaurant = response.data.result;
        this.isLoading =false;
      });
    },
    clearAll() {
      this.selectedRestaurant = [];
    },
    downloadsCSV: function () {
      let feedbackReport =
        '\ufeff' +
        '#,'+this.$auth.getRestaurantName()+' Name,Name,Phone,Feedback,Birthday,Anniversary,Ambience,Time Taken To Serve,Food Taste,Staff Courteousness,Food Quality,Manager Or Captain Visit Your Table?,Date \n'
      this.feedbackReport.forEach((el, index) => {
        var line =
          (index+1) +
          "," +
          (el["restaurants"]['name'] || '-') +
          "," +
          (el['name'] || 0)+
          "," +
          (el["phone_number"] || '-') +
          "," +
          el["feedback"].replaceAll(',','-') +
          "," +
          (el["birthday"] || '-') +
          "," +
          (el["anniversary"] || '-') +
          "," +
          this.getRating(el["ambience"] || 0)+
          "," +
          this.getRating(el["time_taken_to_serve"] || 0)+
          "," +
          this.getRating(el["food_taste"] || 0)+
          "," +
          this.getRating(el["staff_courteousness"] || 0)+
          "," +
          this.getRating(el["food_quality"] || 0)+
          "," +
          (el["manager_or_captain_visit_your_table"]?'Yes':'No')+
          "," +
          this.moment(el["created_at"]).format('MMMM Do YYYY[-] h:mm a') +
          "\n"
        feedbackReport += line;
      });

      var blob = new Blob([feedbackReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "vendor_feedback_dine_rating_report_" + date + ".csv";
      link.click();
    },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
      this.getVendorDineRatingReport();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD"),
      };
    },
    getVendorDineRatingReport(page) {
      this.loading = true;
      reportService
        .getVendorDineRatingReport({
          ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          page: page ? page : 1,
          restaurant: this.selectedRestaurant && this.selectedRestaurant.length > 0 ? this.selectedRestaurant.map(r=>r.restaurant_id) : ''
        })
        .then((response) => {
          this.loading = false;
          // this.paginations = response.data.result;
          this.totalSale = response.data.result;
        });
    },
    getVendorDineRatingReportExport() {
      var params = new URLSearchParams({
           ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
          restaurant: this.selectedRestaurant && this.selectedRestaurant.length > 0 ? this.selectedRestaurant.map(r=>r.restaurant_id) : ''
      })
      window.open(routes.getVendorDineRatingReportExportApi+'?'+params);

      /* reportService
        .getVendorDineRatingReportExport({ 
           ...this.filters,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "asc" : "desc",
        })
        .then((response) => {
          this.feedbackReport  = response.data.result;
          this.downloadsCSV()
        }); */
    },
    searchData() {
      this.getVendorDineRatingReport();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getVendorDineRatingReport();
    },
    getRating(rating){
      switch (rating) {
        case '3':
          return 'AVERAGE';
        case '2':
          return 'GOOD';
        case '1':
          return 'BEST';
        default:
          return '-'
      }
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Vendor - Feedback and rating report',{ vendor : $auth.setting.restaurant_name }) }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Vendor - Feedback and rating report',{ vendor : $auth.setting.restaurant_name }) }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="getVendorDineRatingReportExport"  v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)" class="btn btn-primary btn-rounded">{{ trans.get('__JSON__.Export To CSV') }}</button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 col-xs-12 offset-sm-4">
                  <div class="form-group">
                    <label>{{trans.get('__JSON__.Search...')}}:</label>
                    <multiselect
                      v-model="selectedRestaurant"
                      id="ajax"
                      label="name"
                      track-by="restaurant_id"
                      :placeholder="trans.trans('__JSON__.Search...')"
                      open-direction="bottom"
                      :options="restaurant"
                      :multiple="true"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :options-limit="50"
                      :limit="3"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                      @input="searchData"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.name }}</span>
                          <span class="custom__remove" @click="remove(option)">❌</span>
                        </span>
                      </template>
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="selectedRestaurant.length"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div>
                      </template>
                      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-4 col-xs-12 ">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Date')}}:</label>
                         <date-range-picker
                        ref="picker"
                        :locale-data="{
                          firstDay: 1,
                          format: 'dd-mm-yyyy',
                        }"
                        :showDropdowns="true"
                        :showWeekNumbers="true"
                        opens="left"
                        v-model="dateRange"
                        :ranges="date_ranges()"
                        @update="updateValues"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive" v-if="totalSale">
                  <b-table
                    :items="totalSale.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <div>{{ (totalSale.per_page * (totalSale.current_page-1)) + data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(restaurants)="data">
                      <span>{{ data.value.name  }}</span>
                    </template>

                    <template v-slot:cell(name)="data">
                      <span>{{ data.value }}</span>
                    </template>

                    <template v-slot:cell(phone_number)="data">
                      {{data.value }}
                    </template>
                    <template v-slot:cell(feedback)="data">
                      {{data.value }}
                    </template>
                    <template v-slot:cell(birthday)="data">
                      <div width="105px">{{data.value || '-' }}</div>
                    </template>
                    <template v-slot:cell(anniversary)="data">
                      <div width="105px">{{data.value || '-' }}</div>
                    </template>
                    <template v-slot:cell(created_at)="data">
                      {{moment(data.value).format('lll') }}
                    </template>
                    <template v-slot:cell(action)="row">
                      <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                        {{ row.detailsShowing ? 'Hide' : 'View'}}
                      </b-button>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Sales Report Found') }}</p>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><b>Ambience</b></b-col>
                          <b-col>{{getRating(row.item.ambience || 0)}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><b>Time Taken To Serve</b></b-col>
                          <b-col>{{getRating(row.item.time_taken_to_serve || 0)}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><b>Food Taste</b></b-col>
                          <b-col>{{getRating(row.item.food_taste || 0)}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><b>Staff Courteousness</b></b-col>
                          <b-col>{{getRating(row.item.staff_courteousness || 0)}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><b>Food Quality</b></b-col>
                          <b-col>{{getRating(row.item.food_quality || 0)}}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><b>Manager Or Captain Visit Your Table?</b></b-col>
                          <b-col>{{ row.item.manager_or_captain_visit_your_table == '1'?'Yes':'No' }}</b-col>
                        </b-row>
                      </b-card>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7" v-if="totalSale">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                       <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalSale"
                          :limit="limit"
                          @pagination-change-page="getVendorDineRatingReport"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}
</style>