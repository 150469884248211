
<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { menuService,vendorService } from "../../services";
import { required } from "vuelidate/lib/validators";
import config from "../../config";
import { routes } from "../../config/api_routes";
import { error_message } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import DropzoneSingle from "../../components/widgets/dropzone-single";
import DropzonePopup from "../../components/widgets/dropzone-popup";

export default {
  components: {
    menuService,
    Layout, 
    PageHeader,
    vueDropzone: vue2Dropzone,
    Multiselect,
    DropzoneSingle,
    DropzonePopup,
  },
  data() {
    return {
      restaurant_id: "",
      restaurant: [],
      value: null,
      tmpBannerImage: "",
      url: null,
      config: config,
      search: "",
      errors: "",
      isLoading: false,
      resturant: [],
      selectedRestaurant: [],
      typeform: {
        name: "",
        selectedRestaurant: "",
        image: null,
        status: true,
      },
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      image: {
        required,
      },
      selectedRestaurant: {
        required,
      },
    },
  },
  mounted() {
    this.getRestaurantById();
  },
  methods: {
     getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
            restaurant_on_off
          } = response.data.result;
          this.restaurant_id = restaurant_id;
          this.restaurant_on_off = restaurant_on_off;
          this.is_confirm = is_confirm;
          this.restaurant = {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots
          };
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.typeform.image = file;
    },
    asyncFind(query) {
      this.isLoading = true;
      menuService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.selectedRestaurant = [];
    },
    bannerInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("image", this.typeform.image);
        fd.append("name", this.typeform.name);
        fd.append("status", this.typeform.status ? 1 : 0);
        this.typeform.selectedRestaurant
          .map((item) => item.restaurant_id)
          .map((item, index) => {
            fd.append("selectedRestaurant[" + index + "]", item);
          });
        menuService.addBanner(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "dine-in-banner" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
  <div class="row">
    <div class="col-12">
    <div
        class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
    >
        <div class="page-title-left avatar-main-icon">
        <div class="avatar-xs mr-3">
            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
            <i class="bx bx-purchase-tag"></i>
            </span>
        </div>
        <div class="report-tag">
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Add Banner') }}</h4>
            <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-dine-in-menu',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Dine In Menu') }}</router-link> > <router-link :to="{ name: 'dine-in-banner',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.get('__JSON__.Banner') }}</router-link> > {{ trans.get('__JSON__.Add Banner') }}</p>
        </div>
        </div>
    </div>
    </div>
</div>
  <b-form @submit.prevent="bannerInIt">
    <!-- Banner Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Banner Info') }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="col-sm-6">
            <b-form-group id="input-group-1" :label="trans.get('__JSON__.Name')" label-for="name">
              <b-form-input
                id="name"
                name="name"
                v-model="$v.typeform.name.$model"
                type="text"
                :placeholder="trans.get('__JSON__.Banner Name Placeholder')"
                :class="{ 'is-invalid':  $v.typeform.name.$error }"
              ></b-form-input>
              <div v-if=" $v.typeform.name.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.name.required"
                >{{ trans.get('__JSON__.Banner Name Error') }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <b-form-group
              id="input-group-1"
              :label="trans.trans('__JSON__.Vendors',{ vendor: $auth.setting.restaurant_name })"
              label-for="banner_name"
              for="ajax"
            >
              <multiselect
                v-model="$v.typeform.selectedRestaurant.$model"
                id="ajax"
                label="name"
                track-by="name"
                :placeholder="trans.trans('__JSON__.Banner Vendor Search Placeholder',{ vendor: $auth.setting.restaurant_name })"
                open-direction="bottom"
                :options="resturant"
                :multiple="true"
                :searchable="true"
                :loading="isLoading"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="false"
                :options-limit="5"
                :limit="3"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFind"
                :class="{ 'is-invalid': $v.typeform.selectedRestaurant.$error }"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag">
                    <span>{{ option.name }}</span>
                    <span class="custom__remove" @click="remove(option)">❌</span>
                  </span>
                </template>
                <template slot="clear" slot-scope="props">
                  <div
                    class="multiselect__clear"
                    v-if="selectedRestaurant.length"
                    @mousedown.prevent.stop="clearAll(props.search)"
                  ></div>
                </template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </multiselect>
              <div v-if=" $v.typeform.selectedRestaurant.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.selectedRestaurant.required"
                >{{ trans.trans('__JSON__.Vendor Select Error',{ vendor: $auth.setting.restaurant_name }) }}.</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-sm-6 vendor-status">
            <b-form-group
              id="input-group-1"
              :label="trans.get('__JSON__.Status')"
              label-for="status"
            >
              <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  class="switch-on"
                  checked
                  v-model="typeform.status"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                </div>
              </label>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Info End -->

    <!-- Banner Image Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Banner Image') }}</h4>
        <p class="card-title-desc"></p>

        <div class="row">
          <div class="col-sm-3">
            <dropzone-popup
              v-model="typeform.image"
              type="file"
              id="banner_image"
              name="image"
              acceptedFiles=".jpeg,.jpg,.png"
              :class="{ 'is-invalid': $v.typeform.image.$error }"
            />
            <div v-if="$v.typeform.image.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.typeform.image.required"
              >{{ trans.get('__JSON__.Banner Image Error') }}.</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Add Banner') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Image End -->
  </b-form>
  </Layout>
</template>

<style>
</style>