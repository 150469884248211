<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  promoCodeService,
  bannerService,
  deliveryZoneService,
} from "../../services";
import { error_message, get_currency, success_message } from "../../Helper/helper";
import DropzonePopup from "../../components/widgets/dropzone-popup";

import {required,requiredIf,helpers} from "vuelidate/lib/validators";
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,3}|\d+)$/)
const notEqualTo = (value) => (value !== "-")

export default {
  name: "Range",
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect,
    PageHeader,
    DatePicker,
    promoCodeService,
    bannerService,
    DropzonePopup,
  },
  data() {
    return {
      currency: get_currency(),
      tmpCouponImage: "",
      search: "",
      search1: "",
      isLoading: false,
      checkbox: false,
      customercheckbox: false,
      isLoading1: false,
      customer: [],
      value2: [],
      showTimeRangePanel: false,
      checkOpen: true,
      // value1: [this.moment().startOf('now').toDate(), this.moment().endOf('day').toDate()],
      rangeSeparator: "-",
      format: "YYYY/MM/DD HH:s",
      discount_type: "0",
      selectedCustomer: [],
      resturant: [],
      selectedRestaurant: [],
      updateValues: "",
      countries: [],
      states: [],
      cities: [],
      state: "",
      city: "",
      country: "",
      country_count: 0,
      state_count: 0,
      city_count: 0,
      typeform: {
        coupon_name: "",
        coupon_code: "",
        coupon_name_other_lang: "",
        description_other_lang: "",
        minimum_order_amount: "",
        // country_name: "",
        orders_valid_for_first: "",
        // state_name: "",
        // city_name: "",
        checkbox: false,
        customercheckbox: false,
        coupon_type: "-",
        coupon_image: "",
        selectedRestaurant: "",
        selectedCustomer: "",
        is_show_customer_app: "",
        discount_percentage: "",
        maximum_discount_amount: "",
        is_flat_discount: "0",
        cashback:0,
        per_user_usage: "1",
        description: "",
        max_user_count: "",
        flat_discount: "",
        start_datetime: "",
        end_datetime: "",
        duration_type: ""
      },
    };
  },
  validations: {
    typeform: {
      coupon_name: {
        required,
      },
      coupon_code: {
        required,
      },
      minimum_order_amount: {
        required,
        floating,
      },
      coupon_image: {
        required,
      },
      coupon_type: {
        notEqualTo
      },
      /* country_name: {
        required,
      },
      city_name: {
        required,
      },
      state_name: {
        required,
      }, */
      start_datetime: {
        required,
      },
      max_user_count: {
        required,
        floating,
      },
      // key:{
      //   requiredIf
      // },
      description: {
        required,
      },
      discount_percentage: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '3';
        }),
        floating,
      },
      maximum_discount_amount: {
        required : requiredIf(function(form){
        	return this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3';
        }),
        floating,
      },
      flat_discount: {
        required : requiredIf(function(form){
        	return this.typeform.is_flat_discount == '1' || this.typeform.is_flat_discount == '4';
        }),
        floating,
      },
      cashback:{
        required:requiredIf(function(){
          return this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3'
            || this.typeform.is_flat_discount == '4';
        })
      },
      selectedRestaurant: {
        required: requiredIf(function (nestedModel) {
          return this.typeform.checkbox == false;
        }),
      },
      selectedCustomer: {
        required: requiredIf(function (nestedModel) {
           return this.typeform.customercheckbox == false;
        }),
      },
      
    },
  },
  mounted() {
    // this.getVendorCountry();
    // this.getAllState();
    // this.getAllCity();
  },
  methods: {
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    Onclick: function () {
      if (this.typeform.checkbox == false) this.typeform.checkbox = !this.typeform.checkbox;
    },
    customerOnclick: function () {
      if (this.typeform.customercheckbox == false)
        this.typeform.customercheckbox = !this.typeform.customercheckbox;
    },
    asyncFind(query) {
      this.isLoading = true;
      bannerService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.selectedRestaurant = [];
    },
    asyncFindCustomer(query) {
      this.isLoading = true;
      promoCodeService.getCustomer({search:query}).then((response) => {
        this.customer = response.data.result;
        this.isLoading1 = false;
      });
    },
    clearAllCustomer() {
      this.selectedCustomer = [];
    },
    getVendorCountry() {
      deliveryZoneService.getVendorCountry().then((response) => {
        this.country_count = response.data.result.length;
        this.countries = response.data.result;
        if (this.country_count == 1) {
          this.typeform.country_name = this.countries[0].id;
          this.getVendorState();
        } else {
          this.typeform.country_name = "";
          this.state = "";
          this.city = "";
        }
      });
    },
    getVendorState() {
      deliveryZoneService
        .getVendorState({
          country_id: this.typeform.country_name,
        })
        .then((response) => {
          this.state_count = response.data.result.length;
          this.states = response.data.result;
          if (this.state_count == 1) {
            this.typeform.state_name = this.states[0].id;
            this.getVendorCity();
          } else {
            if (this.typeform.country_name == "") {
              this.getAllState();
              this.getAllCity();
              this.state = "";
              this.city = "";
            }
          }
        });
    },
    getVendorCity() {
      deliveryZoneService
        .getVendorCity({
          state_id: this.typeform.state_name,
        })
        .then((response) => {
          this.cities = response.data.result;
          this.city = "";
        });
    },
    getAllState() {
      deliveryZoneService.getAllState().then((response) => {
        this.states = response.data.result;
        this.state_count = response.data.result.length;
      });
    },
    getAllCity() {
      deliveryZoneService.getAllCity().then((response) => {
        this.cities = response.data.result;
        this.city_count = response.data.result.length;
      });
    },
    couponInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        console.log(this.$v);
        return;
      } else {
        const fd = new FormData();
        fd.append("coupon_name", this.typeform.coupon_name);
        fd.append(
          "coupon_name_other_lang",
          this.typeform.coupon_name_other_lang
        );
        fd.append("description", this.typeform.description);
        fd.append(
          "description_other_lang",
          this.typeform.description_other_lang
        );
        fd.append("coupon_code", this.typeform.coupon_code);
        fd.append("coupon_image", this.typeform.coupon_image);
        fd.append("coupon_type", this.typeform.coupon_type);
        fd.append("is_show_customer_app", this.typeform.is_show_customer_app);
        fd.append("is_flat_discount", this.typeform.is_flat_discount);
        fd.append("flat_discount", this.typeform.flat_discount);
        fd.append("cashback", this.typeform.cashback);
        fd.append("discount_percentage", this.typeform.discount_percentage);
        fd.append(
          "maximum_discount_amount",
          this.typeform.maximum_discount_amount
        );
        fd.append("minimum_order_amount", this.typeform.minimum_order_amount);
        fd.append("per_user_usage", this.typeform.per_user_usage);
        fd.append("max_user_count", this.typeform.max_user_count);
        fd.append("duration_type", this.typeform.duration_type);
        fd.append(
          "orders_valid_for_first",
          this.typeform.orders_valid_for_first
        );
        // fd.append("country", this.typeform.country_name);
        fd.append("start_datetime", this.typeform.start_datetime);
        // fd.append("state", this.typeform.state_name);
        // fd.append("city", this.typeform.city_name);
        // console.log(this.typeform.checkbox);
        if (this.typeform.checkbox == true) {
          fd.append("checkbox", 0);
        } else {
          this.typeform.selectedRestaurant
            .map((item) => item.restaurant_id)
            .map((item, index) => {
              fd.append("selectedRestaurant[" + index + "]", item);
            });
        }
        if (this.typeform.customercheckbox == true) {
          fd.append("customercheckbox", 0);
        } else {
          this.typeform.selectedCustomer
            .map((items) => items.user_id)
            .map((items, index) => {
              fd.append("selectedCustomer[" + index + "]", items);
            });
        }
        promoCodeService.addPromoCode(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "coupon-list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-purchase-tag"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Add Promo Code') }}</h4>
              <p><router-link :to="{ name: 'coupon-list'}">{{ trans.trans('__JSON__.PROMO CODES') }}</router-link> > {{ trans.get('__JSON__.Add Promo Code') }}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form action="#" @submit.prevent="couponInIt">
          <!-- Promo Code Detail Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Promo Code Detail') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6" v-if="$auth.setting">
                  <div class="form-group">
                    <label
                      class="control-label required"
                    >{{ trans.trans('__JSON__.Promo Code Name Language',{ lang: $auth.langauges[0].name }) }}</label>
                    <input
                      id="coupon_name"
                      v-model="$v.typeform.coupon_name.$model"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Name Language',{ lang: $auth.langauges[0].name })"
                      name="coupon_name"
                      :class="{ 'is-invalid': $v.typeform.coupon_name.$error }"
                    />
                    <div v-if="$v.typeform.coupon_name.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.coupon_name.required"
                      >{{ trans.get('__JSON__.Please enter promo code name') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div>
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Name Secondary Language(Optional)',{ lang: $auth.langauges[1].name }) }}</label>
                    <input
                      maxlength="100"
                      id="coupon_name_other_lang"
                      name="coupon_name_other_lang"
                      v-model="typeform.coupon_name_other_lang"
                      type="text"
                      :placeholder="trans.trans('__JSON__.Promo Code Name Secondary Language(Optional)',{ lang: $auth.langauges[1].name })"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label required"
                    >{{ trans.trans('__JSON__.Promo Code Description Primary Language', { lang: $auth.langauges[0].name }) }}</label>
                    <textarea
                      rows="5"
                      id="description"
                      v-model="$v.typeform.description.$model"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Description Primary Language', { lang: $auth.langauges[0].name })"
                      name="description"
                      :class="{ 'is-invalid': $v.typeform.description.$error }"
                    ></textarea>
                    <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.description.required"
                      >{{ trans.get('__JSON__.Please enter promo code description') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div>                
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Description Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                    <textarea
                      rows="5"
                      id="description_other_lang"
                      v-model="typeform.description_other_lang"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Description Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                      name="description_other_lang"
                    ></textarea>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Promo Code') }}</label>
                    <input
                      id="coupon_code"
                      v-model="$v.typeform.coupon_code.$model"
                      type="text"
                      class="form-control"
                      :placeholder="this.trans.get('__JSON__.Enter Promo Code Name')"
                      name="coupon_code"
                      :class="{ 'is-invalid': $v.typeform.coupon_code.$error }"
                    />
                    <div v-if="$v.typeform.coupon_code.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.coupon_code.required"
                      >{{ trans.get('__JSON__.Please enter promo code') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label required"
                    >{{ trans.get('__JSON__.Promo Code Redeem start/end Period') }}</label>
                    <br />
                    <!-- <date-picker
                      v-model="value1"
                      type="datetime"
                      :disabled-date="disabledBeforeTodayAndAfterAWeek"
                      :format="format"
                      :rangeSeparator="rangeSeparator"
                      range
                      placeholder="Select date range"
                    ></date-picker>-->
                    <date-picker
                      v-model="$v.typeform.start_datetime.$model"
                      :disabled-date="disabledBeforeTodayAndAfterAWeek"
                      type="datetime"
                      value-type="format"
                      :rangeSeparator="rangeSeparator"
                      placeholder="Select datetime range"
                      range
                      :show-time-panel="showTimeRangePanel"
                      @close="handleRangeClose"
                      :class="{ 'is-invalid': $v.typeform.start_datetime.$error }"
                    >
                      <template v-slot:footer>
                        <button
                          class="mx-btn mx-btn-text"
                          @click="toggleTimeRangePanel"
                        >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                      </template>
                    </date-picker>
                    <div v-if="$v.typeform.start_datetime.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.start_datetime.required"
                      >{{ trans.get('__JSON__.Please select date') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="control-label required">{{ trans.get('__JSON__.Promo Code Image') }}</label>
                    <dropzone-popup
                      v-model="typeform.coupon_image"
                      type="file"
                      id="coupon_image"
                      name="coupon_image"
                      acceptedFiles=".jpeg,.jpg,.png"
                      :class="{ 'is-invalid': $v.typeform.coupon_image.$error }"
                    />
                    <div
                      v-if="$v.typeform.coupon_image.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span
                        v-if="!$v.typeform.coupon_image.required"
                      >{{ trans.get('__JSON__.Please select coupon image') }}.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Promo Code Detail Section End -->

          <!-- Discount Details Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Discount Details') }}</h4>
              <p class="card-title-desc"></p>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Coupon Valid For') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.coupon_type.$model"
                        id="coupon_type"
                        name="coupon_type"
                        :class="{ 'is-invalid': $v.typeform.coupon_type.$error }"
                      >
                        <option value="-">{{ trans.get('__JSON__.Select Coupon Type') }}</option>
                        <option value="Delivery">{{ trans.get('__JSON__.Delivery') }}</option>
                        <option value="Pickup">{{ trans.get('__JSON__.Pickup') }}</option>
                        <option value="">{{ trans.get('__JSON__.Delivery') + ' + ' + trans.get('__JSON__.Pickup') }}</option>
                        <option value="ChowmanPay">{{ trans.get('__JSON__.Chowman Pay') }}</option>
                      </select>
                      <div
                        v-if="$v.typeform.coupon_type.$error"
                        class="invalid-feedback cuisine-img"
                      >
                        <span
                          v-if="!$v.typeform.coupon_type.required"
                        >{{ trans.get('__JSON__.Please select field', {field: trans.get('__JSON__.Coupon Valid For')}) }}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Promo Code Redeem Count') }}</label>
                      <select
                        class="custom-select"
                        v-bind:value="typeform.per_user_usage"
                        v-model="typeform.per_user_usage"
                        id="per_user_usage"
                        name="per_user_usage"
                      >
                        <!-- <option value="">{{ trans.get('__JSON__.Select Redeem Count') }}</option> -->
                        <option v-bind:value="1">1</option>
                        <option v-bind:value="2">2</option>
                        <option v-bind:value="3">3</option>
                        <option v-bind:value="4">4</option>
                        <option v-bind:value="5">5</option>
                        <option v-bind:value="6">6</option>
                        <option v-bind:value="7">7</option>
                        <option v-bind:value="8">8</option>
                        <option v-bind:value="9">9</option>
                        <option v-bind:value="10">10</option>
                        <option value="Unlimited">Unlimited</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Coupon Valid For First X Order') }}</label>
                      <select
                        class="custom-select"
                        v-bind:value="typeform.orders_valid_for_first"
                        v-model="typeform.orders_valid_for_first"
                        id="orders_valid_for_first"
                        name="orders_valid_for_first"
                      >
                        <option value>{{ trans.get('__JSON__.Any Orders') }}</option>
                        <option v-bind:value="1">1</option>
                        <option v-bind:value="2">2</option>
                        <option v-bind:value="3">3</option>
                        <option v-bind:value="4">4</option>
                        <option v-bind:value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Discount Type') }}</label>
                      <select
                        class="custom-select"
                        v-model="typeform.is_flat_discount"
                        id="discount_type"
                        name="discount_type"
                      >
                        <!-- <option value>{{ trans.get('__JSON__.Select Discount Type') }}</option> -->
                        <option :value="0">{{ trans.get('__JSON__.Percentage') }}</option>
                        <option :value="1">{{ trans.get('__JSON__.Flat') }}</option>
                        <option :value="2">{{ trans.get('__JSON__.Cashback') }}</option>
                        <option :value="3">{{ trans.get('__JSON__.Cashback With Percentage') }}</option>
                        <option :value="4">{{ trans.get('__JSON__.Cashback With Flat') }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Cashback') }} (%)</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.cashback.$model"
                        type="text"
                        :placeholder="this.trans.get('__JSON__.Enter Cashback in Percentage')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.cashback.$error }"
                      />
                      <div v-if="$v.typeform.cashback.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.cashback.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'cashback'})}}.</span>
                          <span
                            v-else-if="$v.typeform.cashback.floating"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'cashback'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 0 || typeform.is_flat_discount == 3">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Discount') }} (%)</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.discount_percentage.$model"
                        type="text"
                        :placeholder="this.trans.get('__JSON__.Enter field',{name: 'Discount'})"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.discount_percentage.$error }"
                      />
                      <div v-if="$v.typeform.discount_percentage.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.discount_percentage.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'percentage'})}}.</span>
                          <span
                            v-else-if="$v.typeform.discount_percentage.floating"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'percentage'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Flat Discount') }} (
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="flat_discount"
                        name="flat_discount"
                        v-model="$v.typeform.flat_discount.$model"
                        type="number"
                        :placeholder="this.trans.get('__JSON__.Enter field',{name: 'Flat Discount'})"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.flat_discount.$error }"
                      />
                      <div v-if="$v.typeform.flat_discount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.flat_discount.required"
                        >{{ trans.get('__JSON__.Please enter flat discount')}}.</span>
                        <span
                          v-if="!$v.typeform.flat_discount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount != 1 && this.typeform.is_flat_discount != 4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Maximum Discount Amount') }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="maximum_discount_amount"
                        name="maximum_discount_amount"
                        type="text"
                        v-model="$v.typeform.maximum_discount_amount.$model"
                        :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.maximum_discount_amount.$error }"
                      />
                       <div v-if="$v.typeform.maximum_discount_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.required"
                        >{{ trans.get('__JSON__.Please enter maximum discount')}}.</span>
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Minimum Order Values') }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="minimum_order_amount"
                        name="minimum_order_amount"
                        v-model="$v.typeform.minimum_order_amount.$model"
                        type="text"
                        :placeholder="this.trans.get('Enter Minimum Order Amount')"
                        class="form-control"
                        :class="{ 'is-invalid': $v.typeform.minimum_order_amount.$error }"
                      />
                      <div v-if="$v.typeform.minimum_order_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.minimum_order_amount.required"
                        >{{ trans.get('__JSON__.Please enter minimum order amount')}}.</span>
                        <span
                          v-if="!$v.typeform.minimum_order_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        class="control-label required"
                      >{{ trans.get('__JSON__.Coupon Valid For First X User') }}</label>
                      <input
                        id="max_user_count"
                        name="max_user_count"
                        v-model="$v.typeform.max_user_count.$model"
                        type="text"
                        :placeholder="this.trans.get('Enter Max Count (FIrst X User)')"
                        class="form-control"
                        :class="{ 'is-invalid': $v.typeform.max_user_count.$error }"
                      />
                       <div v-if="$v.typeform.max_user_count.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.max_user_count.required"
                        >{{ trans.get('__JSON__.Please enter max count number')}}.</span>
                        <span
                          v-if="!$v.typeform.max_user_count.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                   <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        class="control-label required"
                      >{{ trans.get('__JSON__.Duration Type') }}</label>
                      <select
                        class="custom-select"
                        v-model="typeform.duration_type"
                        id="duration_type"
                        name="duration_type"
                      >
                        <!-- <option value>{{ trans.get('__JSON__.Select Discount Type') }}</option> -->
                        <option value="">{{ trans.get('__JSON__.Select One') }}</option>
                        <option value="daily">{{ trans.get('__JSON__.Daily') }}</option>
                        <option value="weekly">{{ trans.get('__JSON__.Weekly') }}</option>
                        <option value="monthly">{{ trans.get('__JSON__.Monthly') }}</option>
                      </select>
                      <!-- <div v-if="$v.typeform.duration_type.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.duration_type.required"
                        >{{ trans.get('__JSON__.Please select duration type')}}.</span>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Show in Customer App') }}?</label>
                      <div class>
                        <div class="swtich-data">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              class="switch-on"
                              v-model="typeform.is_show_customer_app"
                            />
                            <div class="slider round">
                              <!--ADDED HTML -->
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                              <!--END-->
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Discount Details Section End -->

          <!-- Vendors Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title required">{{ trans.trans('__JSON__.Vendors',{ vendor: $auth.setting.restaurant_name }) }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-main">
                    <div class="col-sm-4">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-on:click="Onclick()"
                          v-model="typeform.checkbox"
                          class="custom-control-input"
                          id="all_rest"
                        />
                        <label
                          class="custom-control-label"
                          for="all_rest"
                        >{{ trans.trans('__JSON__.Promo code valid for all Vendors',{ vendor: $auth.setting.restaurant_name }) }}</label>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <p>{{ trans.get('__JSON__.OR') }}</p>
                    </div>
                    <div class="col-sm-4 offset-sm-2">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative">
                          <multiselect
                            v-model="$v.typeform.selectedRestaurant.$model"
                            id="ajax"
                            v-bind:disabled="typeform.checkbox"
                            label="name"
                            track-by="name"
                            :placeholder="trans.trans('__JSON__.Vendor Search',{ vendor: $auth.setting.restaurant_name })"
                            open-direction="bottom"
                            :options="resturant"
                            :multiple="true"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="30"
                            :limit="100"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFind"
                            :class="{ 'is-invalid': $v.typeform.selectedRestaurant.$error }"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="selectedRestaurant.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div>
                            </template>
                            <template slot="noOptions">
                              <span                              
                              >{{ trans.get('__JSON__.Search...') }}.</span>
                            </template>
                            <template slot="noResult">
                              <span
                              >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                            </template>
                          </multiselect>
                          <div
                            v-if=" $v.typeform.selectedRestaurant.$error"
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.typeform.selectedRestaurant.required"
                            >{{ trans.get('__JSON__.Vendor Select Error') }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Vendors Section End -->

          <!-- Customer Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title required">{{ trans.get('__JSON__.Customers') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-main">
                    <div class="col-sm-4">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-on:click="customerOnclick()"
                          v-model="typeform.customercheckbox"
                          class="custom-control-input"
                          id="all_cust"
                        />
                        <label
                          class="custom-control-label"
                          for="all_cust"
                        >{{ trans.get('__JSON__.Promo code valid for all Customers') }}</label>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <p>{{ trans.get('__JSON__.OR') }}</p>
                    </div>
                    <div class="col-sm-4 offset-sm-2">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative">
                          <multiselect
                            v-model="$v.typeform.selectedCustomer.$model"
                            id="ajax"
                            label="user_name"
                            v-bind:disabled="typeform.customercheckbox"
                            track-by="user_name"
                            :placeholder="trans.get('__JSON__.Customer Search')"
                            open-direction="bottom"
                            :options="customer"
                            :multiple="true"
                            :searchable="true"
                            :loading="isLoading1"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="5"
                            :limit="100"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFindCustomer"
                            :class="{ 'is-invalid': $v.typeform.selectedCustomer.$error }"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.user_name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="selectedCustomer.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div>
                            </template>
                            <template slot="noOptions">
                              <span                              
                              >{{ trans.get('__JSON__.Search...') }}.</span>
                            </template>
                            <template slot="noResult">
                              <span
                              >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                            </template>

                          </multiselect>
                          <div v-if=" $v.typeform.selectedCustomer.$error" class="invalid-feedback">
                            <span
                              v-if="!$v.typeform.selectedCustomer.required"
                            >{{ trans.get('__JSON__.Please choose at least one customer') }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Customer Section End -->

          <!-- location Section Start -->
          <div class="card" v-if="country_count >1 && false">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Select Location') }}</h4>
              <p class="card-title-desc"></p>
              <div class="col-md-12">
                <div class="row">
                  <div v-if="country_count >1" class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Country') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.country_name.$model"
                        @change="getVendorState"
                        id="country"
                        name="country"
                        :class="{ 'is-invalid': $v.typeform.country_name.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select Country') }}</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                      <div v-if="$v.typeform.country_name.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.country_name.required"
                        >{{ trans.get('__JSON__.Please select country') }}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="country_count >=1 && state_count > 1" class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.State') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.state_name.$model"
                        @change="getVendorCity"
                        id="state"
                        name="state"
                        :class="{ 'is-invalid': $v.typeform.state_name.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select State') }}</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                      <div v-if="$v.typeform.state_name.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.state_name.required"
                        >{{ trans.get('__JSON__.Please select state')}}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="state_count >1 && city_count > 1" class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.City') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.city_name.$model"
                        id="city"
                        name="city"
                        :class="{ 'is-invalid': $v.typeform.city_name.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select City') }}</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                      <div v-if="$v.typeform.city_name.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.city_name.required"
                        >{{ trans.get('__JSON__.Please select city') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
              <div class="row">
                <div class="col-sm-6">
                  <button
                    class="btn btn-primary mr-1 mt-3"
                  >{{ trans.get('__JSON__.Add Promo Code') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
          <!-- location Section End -->
        </form>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
</style>