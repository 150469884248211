<script>
import config from "../../config";
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import { customerService} from '../../services';
import { set_axios_defaults } from '../../Helper/helper';
import { error_message,success_message } from "../../Helper/helper"
import {
  required,
  email,
  numeric
}from "vuelidate/lib/validators";
/**
 * Add-product component
 */
export default {
  components: { Layout, PageHeader},
  data() {
    return {
      config:config,
      customers: {
        user_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        status: "",
        cod_limit_validation: '0'
      }, 
      typesubmit: false,
    };
  },
  validations: {
    customers: {
      user_name: {
        required
      },
      last_name: {
        required,
      },
      email: {
        required,
        email
      },
      mobile_number: {
        required,
        numeric
      }, 
    },
  },
  mounted(){
    this.getCustomerProfile();
  },
  methods: {
    copyReferral() {
      var Url = this.$refs.referral;
      // Url.innerHTML = window.location.href;
      // console.log(Url.innerHTML)
      Url.select();
      document.execCommand("copy");
      success_message('Copied')
    },
    getCustomerProfile(){
      customerService.getCustomerProfile({
        user_id: this.$route.params.user_id
      })
      .then(response => {
        this.customers = {
          cod_limit_validation: 0,
          ...response.data.result,
        };
      });
    },
    typeForm(e) {
      this.$v.$touch();
      if (this.$v.customers.$invalid) {
        return;
      }
      else{
        customerService.updateCustomerProfile(this.customers)
        .then(response => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            // this.$router.push({ name: "customer-detail" });
          } else {
            error_message(response.data.message);
          }
        });
      }  
    } 
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <form action="#" @submit.prevent="typeForm">  
      <!-- profile Info Start -->
        <div class="card details-profile-main">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                      <label>{{ trans.get('__JSON__.First Name') }}</label>
                      <input id="user_name" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter First Name')" v-model="$v.customers.user_name.$model" name="user_name" :class="{ 'is-invalid':$v.customers.user_name.$error }"/>
                    <div v-if="$v.customers.user_name.$error" class="invalid-feedback">
                      <span v-if="!$v.customers.user_name.required">{{ trans.get('__JSON__.Please enter first name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.Last Name') }}</label>
                    <input id="last_name" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Last Name')" v-model="$v.customers.last_name.$model" name="last_name" :class="{ 'is-invalid':$v.customers.last_name.$error }"/>
                    <div v-if="$v.customers.last_name.$error" class="invalid-feedback">
                        <span v-if="!$v.customers.last_name.required">{{ trans.get('__JSON__.Please enter last name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get('__JSON__.E-Mail') }}</label>
                    <input id="email" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Email')" v-model="$v.customers.email.$model" name="email" :class="{ 'is-invalid':$v.customers.email.$error }"/>
                      <div v-if="$v.customers.email.$error" class="invalid-feedback">
                        <span v-if="!$v.customers.email.required">{{ trans.get('__JSON__.Please enter email') }}</span>
                        <span v-if="!$v.customers.email.email">{{ trans.get('__JSON__.This value should be a valid email') }}</span>
                      </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Phone Number') }}</label>
                    <input id="mobile_number" type="text" class="form-control" :placeholder="trans.get('__JSON__.Enter Phone Number')" v-model="$v.customers.mobile_number.$model" name="mobile_number" :class="{ 'is-invalid':$v.customers.mobile_number.$error }"/>
                      <div v-if="$v.customers.mobile_number.$error" class="invalid-feedback">
                        <span v-if="!$v.customers.mobile_number.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                        <span v-if="!$v.customers.mobile_number.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                      </div>
                  </div>
                </div>
              

                <div class="col-sm-6 vendor-status">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Status')"
                    label-for="status"
                  >
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        name="status"
                        v-model="customers.status"
                        class="switch-on"
                        true-value="0"
                        false-value="1"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                      </div>
                    </label>
                  </b-form-group>
               </div>
                <div class="col-sm-6 ">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Referral Code')"  
                    label-for="referral_code"
                  >
                    <label class="align-items-center d-flex switch">
                      <code ref="referral" class="font-size-15">{{customers.referral_code}}</code>
                      <!-- <b-button variant="link" @click="copyReferral">
                        <i class="mdi mdi-content-copy"></i>
                      </b-button> -->
                    </label>
                  </b-form-group>
                </div>
                <div class="col-sm-6 vendor-status">
                  <b-form-group
                    label="Remove cod order validation?" 
                    label-for="cod_limit_validation"
                  >
                    <label class="switch">
                      <input
                        type="checkbox" 
                        id="cod_limit_validation"
                        name="status"
                        v-model="customers.cod_limit_validation"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                      </div>
                    </label>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button v-if="$auth.hasPermission(config.permissions.customer_write)" type="submit" class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Update Profile') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
          </div>
        </div>
      <!-- profile Info End -->
      </form>
    </div>
  </div>
</template>
