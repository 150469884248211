<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import config from "../../config";
import {
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    let startDate = this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days") : this.moment().startOf('day').subtract(6, "days");
    let endDate = this.moment().endOf("day").toDate();
    return {
      decimal: get_decimal(),
      currency: get_currency(),
      dateRange: { startDate, endDate },
      reports: [],
      config:config,
      reportsData: [],
      paginations: {},
      city: "0",
      city_count: "",
      date_ranges: date_ranges,
      restaurant: [],
      restaurant_count: "",
      payment_status: "all",
      restaurants_id: "",
      limit: 2,
      perPage: 0,
      currentPage: 1,
      filters: {
        start_date: this.$auth.partner_login ? this.moment().startOf('day').subtract(29, "days").format('YYYY-MM-DD') : this.moment().startOf('day').subtract(6, "days").format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD'),
      },
      sortBy: "created_at",
      moment: moment,
      loading: true,
      exporting: false,
      total_data: {},
      fields: [
        { key: "id", label: "Id", sortable: true },
        { key: "customer", label: this.trans.get("__JSON__.Customer"), sortable: true, },
        { key: "restaurant_name", label: this.trans.get("__JSON__.Restaurant"), sortable: true, },
        { key: "payment_id", label: this.trans.get("__JSON__.Payment Id"), sortable: true, },
        { key: "cashback_pay_id", label: this.trans.get("__JSON__.Cashback Pay Id"), sortable: true, },
        { key: "wallet_pay_id", label: this.trans.get("__JSON__.Wallet Pay Id"), sortable: true, },
        { key: "total_amount", label: this.trans.get("__JSON__.Total Amount"), sortable: true, },
        { key: "default_discount", label: this.trans.get("__JSON__.Default Cashback"), sortable: true, },
        { key: "promocode_discount", label: this.trans.get("__JSON__.Promocode Discount"), sortable: true, },
        { key: "wallet_price", label: this.trans.get("__JSON__.Wallet Price"), sortable: true, },
        { key: "online_paid_amount", label: this.trans.get("__JSON__.Online Paid Amount"), sortable: true, },
        { key: "cashback_price", label: this.trans.get("__JSON__.Cashback Price"), sortable: true, },
        { key: "payment_status", label: this.trans.get("__JSON__.Payment Status"), sortable: true, },
        { key: "coupon_name", label: this.trans.get("__JSON__.Promocode"), sortable: true, },
        { key: "unique_code", label: this.trans.get("__JSON__.Unique Code"), sortable: true, },
        { key: "created_attime", label: this.trans.get("__JSON__.Created At"), sortable: true, },
      ],
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if (this.$auth.partner_login)
        return this.fields.filter(field => !field.requiresAdmin);

      // If the user IS an admin, return all fields.
      else
        return this.fields;
    }
  },
  mounted() {
    this.getRestaurants();
  },
  methods: {
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date: this.moment(date.endDate.toISOString()).format('YYYY-MM-DD'),
      }
      this.getChowmanPayReports();
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date: this.moment(date.startDate.toISOString()).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(date.endDate.toISOString()).format("YYYY-MM-DD")
      };
    },
    downloadsCSV: function (response) {
      const url = window.URL.createObjectURL(new Blob([response.data],{ type:"application/xlsx" }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll("\"","")); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    getRestaurants() {
      this.restaurant = [];
      reportService.getAllRestaurantName({
        city_id: this.city
      }).then((response) => {
        this.restaurant = response.data.result;
        this.searchData();
        if(this.restaurant.length > 0)
          this.restaurant_id = this.restaurant[0].restaurant_id;
      });
    },
    getUser() {
      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;
    },
    getChowmanPayReports(page) {
      this.loading = true;
      if (this.$auth.partner_login) {
        reportService
          .getChowmanPayReport({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            payment_status: this.payment_status,
            page: page ? page : 1,
          })
          .then((response) => {
            this.loading = false;
            this.paginations = response.data.result[0];
            if((page || 1) == 1){
              if(Object.keys(response.data.result[1] || []).length > 0){
                this.total_data =  response.data.result[1];
              }else{
                this.total_data =  {};
              }
            }
            if(Object.keys(this.total_data).length > 0){
              this.reports = [...response.data.result[0].data, this.total_data];
            }else{
              this.reports = [...response.data.result[0].data];
            }
          });
      }
      else {
        reportService
          .getChowmanPayReport({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",

            city: this.city,
            payment_status: this.payment_status,
            restaurants_id: this.restaurants_id,
            page: page ? page : 1,
          })
          .then((response) => {
            this.loading = false;
            this.paginations = response.data.result[0];
            if((page || 1) == 1){
              if(Object.keys(response.data.result[1] || []).length > 0){
                this.total_data =  response.data.result[1];
              }else{
                this.total_data =  {};
              }
            }
            if(Object.keys(this.total_data).length > 0){
              this.reports = [...response.data.result[0].data, this.total_data];
            }else{
              this.reports = [...response.data.result[0].data];
            }
          });
      }
    },

    // for export
    saleExportCsv() {
      this.exporting = true;
      
      if (this.$auth.partner_login) {
        reportService
          .exportChowmanPayReport({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "desc" : "asc",
            payment_status: this.payment_status,
            // page: page ? page : 1,
          })
          .then((response) => {
            this.exporting = false;
            this.downloadsCSV(response);
          });
      }
      else {
        reportService
          .exportChowmanPayReport({
            ...this.filters,
            sortBy: this.sortBy,
            orderBy: this.sortDesc ? "asc" : "desc",
            payment_status: this.payment_status,
            restaurants_id: this.restaurants_id,
            // page: page ? page : 1,
          })
          .then((response) => {
            this.exporting = false;
            this.downloadsCSV(response);
          });
      }
    },
    searchData() {
      this.getChowmanPayReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getChowmanPayReports();
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },


};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
            <div class="page-title-left avatar-main-icon">
              <div class="avatar-xs mr-3">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                  <i class="bx bx-purchase-tag"></i>
                </span>
              </div>
              <div class="report-tag" v-if="!$auth.partner_login">
                <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Chowman Pay Report') }}</h4>
                <p>
                  <router-link :to="{ name: 'reports-list' }">{{ trans.get('__JSON__.Reports') }}</router-link> > {{
                      trans.get('__JSON__.Chowman Pay Report')
                  }}
                </p>
              </div>
              <div class="report-tag" v-else>
                <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Chowman Pay Report') }}</h4>
                <p>
                  <router-link :to="{ name: 'vendor.reports.list' }">{{ trans.get('__JSON__.Reports') }}</router-link> >
                  {{ trans.get('__JSON__.Chowman Pay Report') }}
                </p>
              </div>
            </div>
            <div class="page-title-right">
              <button @click="saleExportCsv" v-if="$auth.hasSubAdminPermission(config.permissions.reports_export)" class="btn btn-primary btn-rounded" :disabled="exporting">{{
                  trans.get(!exporting ? '__JSON__.Export To CSV' : '__JSON__.Exporting')
              }}</button>
            </div>
          </div>
        </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-between">

                <div v-if="restaurant.length > 1" class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.trans('__JSON__.Vendor', { vendor: $auth.setting.restaurant_name }) }} :</label>
                      <select class="custom-select" v-model="restaurants_id" @change="searchData()">
                        <option value selected>{{ trans.trans('__JSON__.All Vendor', {
                            vendor:
                              $auth.setting.restaurant_name
                          })
                        }}</option>
                        <option v-for="res in restaurant" :value="res.restaurant_id" :key="res.restaurant_id">
                          {{ res.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Status') }} :</label>
                      <select class="custom-select" v-model="payment_status" @change="searchData()">
                        <option value="all">{{ trans.get('__JSON__.All') }}</option>
                        <option value="Pending" selected>{{ trans.get('__JSON__.Pending') }}</option>
                        <option value="Failed">{{ trans.get('__JSON__.Failed') }}</option>
                        <option value="Completed">{{ trans.get('__JSON__.Completed') }}</option>
                        <option value="refunded">{{ trans.get('__JSON__.Refunded') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="sales-report">
                  <div class="form-group">
                    <div class="date-range-list">
                      <label>{{ trans.get('__JSON__.Date') }} :</label>
                      <date-range-picker ref="picker" :locale-data="{
                        firstDay: 1,
                        format: 'dd-mm-yyyy',
                      }" :showDropdowns="true" :showWeekNumbers="true" opens="left" v-model="dateRange"
                        :ranges="date_ranges()" @update="updateValues">
                        <template v-slot:input="picker" style="min-width: 350px">
                          {{ moment(picker.startDate.toISOString()).format('MMMM DD, YYYY') | date }} -
                          {{ moment(picker.endDate.toISOString()).format('MMMM DD, YYYY') | date }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row table-responsive">
                <div class="col-12 table-main-list table table-centered table-nowrap sales-report-table">
                  <b-table :items="reports" :fields="computedFields" :sort-by.sync="sortBy" :no-local-sorting="true"
                    @sort-changed="sortingChanged" :busy="loading" show-empty>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ trans.get('__JSON__.Loading...') }}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(table_id)="data">
                      <div>{{ data.index + 1 }}</div>
                    </template>

                    <template v-slot:cell(total_amount)="data">
                      <span v-html="currency"></span>
                      {{ data.value || '0.00' }}
                    </template>

                    <template v-slot:cell(default_discount)="data">
                      <span v-html="currency"></span>
                      {{ data.value || '0.00' }}
                    </template>

                    <template v-slot:cell(promocode_discount)="data">
                      <span v-html="currency"></span>
                      {{ data.value || '0.00' }}
                    </template>



                    <template v-slot:cell(wallet_price)="data">
                      <span v-html="currency"></span>
                      {{ data.value || '0.00' }}
                    </template>

                    <template v-slot:cell(online_paid_amount)="data">
                      <span v-html="currency"></span>
                      {{ data.value || '0.00' }}
                    </template>

                    <template v-slot:cell(cashback_pay_id)="data">
                      {{ data.value || ' - ' }}
                    </template>

                    <template v-slot:cell(wallet_pay_id)="data">
                      {{ data.value || ' - ' }}
                    </template>

                    <template v-slot:cell(coupon_name)="data">
                      {{ data.value || ' - ' }}
                    </template>

                    <template v-slot:cell(created_attime)="data">
                      {{ data.value || ' - ' }}
                    </template>



                    <template v-slot:cell(cashback_price)="data">
                      <span v-html="currency"></span>
                      {{ data.value || '0.00' }}
                    </template>

                    <template v-slot:cell(payment_status)="data">
                      <div v-if="data.item.payment_status === 'Pending'" class="text-warning">
                        {{ trans.get('__JSON__.Pending') }}
                      </div>
                      <div v-else-if="data.item.payment_status === 'Failed'" class="text-danger">
                        {{ trans.get('__JSON__.Failed') }}
                      </div>
                      <div v-else-if="data.item.payment_status === 'Completed' && data.item.refunded == '1'" class="text-secondary">
                        {{ trans.get('__JSON__.Refunded') }}
                      </div>
                      <div v-else-if="data.item.payment_status === 'Completed'" class="text-success">
                        {{ trans.get('__JSON__.Completed') }}
                      </div>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      {{ data.created_at }}
                      {{ moment(data.created_at).format('MMM DD[,] YYYY hh:mm A') }}
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Chowman Pay Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination :data="paginations" :limit="limit" @pagination-change-page="getChowmanPayReports">
                        </pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
/* .sales-report-table .table thead th:nth-child(1),
.sales-report-table .table thead th:nth-child(2),
.sales-report-table .table thead th:nth-child(3),
.sales-report-table .table thead th:nth-child(9),
.sales-report-table .table thead th:nth-child(13),
.sales-report-table .table thead th:nth-child(15),
.sales-report-table .table thead th:nth-child(16) {
  pointer-events: none;
} */
</style>