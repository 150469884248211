
<script>
import DateRangePicker from "vue2-daterange-picker";
import OrderList from "./order_tabs/order-list";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { orderService } from '../../services';
import { date_ranges, debounce, order_statuses } from '../../Helper/helper';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel;
let cancelCount;

export default {
  components: { DateRangePicker, OrderList,pagination },
  props:['filterChange'],
  data() {
    let startDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().startOf('day').toDate();
    let endDate = this.$route.query.date && this.moment(this.$route.query.date).startOf('day').toDate() || this.moment().endOf('day').toDate();
    return {
      dateRange: { startDate, endDate },
      statuses: order_statuses,
      orders: null,
      ordersCount: null,
      loading: false,
      live_orders:0,
      limit: 1,
      tabIndex: 0,
      page: 1,
      statusesArray:[],
      delay_order_btn:true,
      not_response_btn:true,
      cancel_order:true,
      filters: {
        status: order_statuses.placed,
        search_type:'order',
        search: '',
        call_center: false,
        start_date: this.moment().format('YYYY-MM-DD'),
        end_date: this.moment().format('YYYY-MM-DD')
      },
      driverColumns: [
        { key: "driver", label: this.trans.get("__JSON__.Driver Name",{driver: this.$auth.getDriverName()}), sortable: false,formatter: value => (value.length > 0 ?`${value[0].firstname+' '+value[0].lastname}`:'-')}
      ],
      scheduleColumns: [
        { key: "future_delivery_date", label: this.trans.get("__JSON__.Schedule Time"), sortable: false }
      ],
      cancelledColumns: [
        { key: "cancelled_date", label: this.trans.get("__JSON__.Cancel Time"), sortable: false },
      ],
      readyToDeliveryColumns: [
        { key: "assign", label: this.trans.get("__JSON__.Assign"), sortable: false}
      ],
      debounce,
      searchChanged:debounce(()=>{
          this.getOrders()
          this.getOrdersCount();
      },500),
      getCount:debounce(()=>{
          this.getOrdersCount();
      },500),      
      date_ranges: date_ranges
    };
  },
  watch:{
    // "$auth.orders": function(){
    //   // this.getData();
    //   this.ordersCount.placed_orders = parseInt(this.ordersCount.placed_orders);
    // },
    "$route.query": function(){
      this.dateRange =this.changeSettingRange();
      this.filters = {
        ...this.filters,
        status: this.$route.query.status?this.$route.query.status:order_statuses.placed,
        search_type:this.$route.query.search_type?this.$route.query.search_type:'order',
        start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }
      
      this.getOrders();
      this.getOrdersCount();
    }
  },
  mounted() {
     this.checkMailTemplate();
    window.order_count_refresh = false;
    const { placed, inkitchen, readytoserve, ontheway, delivered, cancelled, scheduled, paymentfailure } = order_statuses;
    this.statusesArray = [placed, inkitchen, readytoserve, ontheway, delivered, scheduled,cancelled, paymentfailure];
    this.dateRange =this.changeSettingRange();
    this.filters = {
      ...this.filters,
      status: this.$route.query.status?this.$route.query.status:order_statuses.placed,
      search_type: this.$route.query.search_type?this.$route.query.search_type:'order',
      start_date: this.$route.query.date || this.moment(this.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: this.$route.query.date || this.moment(this.dateRange.endDate).format('YYYY-MM-DD')
    }
    this.getOrders();
    this.getOrdersCount();
    this.sockets.subscribe('order_placed_'+this.$auth.getVendorId(),this.newOrderArrange)
    this.sockets.subscribe('order_confirmed_'+this.$auth.getVendorId(),this.newOrderArrange)
    this.sockets.subscribe('order_placed_partner_'+this.$auth.getVendorId(),this.newOrderArrange)
    this.sockets.subscribe('order_confirmed_parter_'+this.$auth.getVendorId(),this.newOrderArrange)
  },
  computed:{
    getSelected:function(){
      const { start_date, end_date } = this.filters;
      const date_ranges = this.date_ranges();
      return Object.keys(date_ranges).reduce((accu, day) => {
        if(this.moment(date_ranges[day][0]).format('YYYY-MM-DD') == start_date && this.moment(date_ranges[day][1]).format('YYYY-MM-DD') == end_date){
          return day;
        }
        return accu;
      },'');
    }
  },
  methods: {
    checkMailTemplate(){
    orderService.checkMailtemplateActive()
        .then(response => {
          
            if(response.data.code == 200){
                if(response.data.result.delay_order == 0){
                  this.delay_order_btn = false;
                }
                if(response.data.result.not_responding_order == 0){
                  this.not_response_btn = false;
                }
                if(response.data.result.cancel_order == 0){
                  this.cancel_order = false;
                }
              
            }
        })
        },
    updateValues(date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
      // this.getNewOrders()
      window.order_count_refresh = true;
      this.getData()
    },
    getData(){
      if (cancel !== undefined) {
        cancel();
      }
      this.getOrders();
      if(window.order_count_refresh){
        this.getOrdersCount();
        window.order_count_refresh = false;
      }
    },
    getNewOrders(){
      orderService
        .getNewOrders()
        .then((response) => {
          if(typeof response.data.result == 'object'){
            if(response.data.result.length !== this.$auth.orders.length){
              this.$auth.updateOrders(response.data.result)
            }
          }
        })
        .catch ((error) =>{
          // console.log(error);
        });
    },
    dateFormat(classes, date) {
      this.filters = {
        ...this.filters,
        start_date :  this.moment(date.startDate.toISOString()).format('YYYY-MM-DD'),
        end_date :  this.moment(date.endDate.toISOString()).format('YYYY-MM-DD') 
      }
    },
    newOrderArrange(data){
      if(this.filters.search == ''){
        if(this.page === 1 && 
          (
            (data.order.order_status == 'Placed' && this.statusesArray[this.tabIndex] == this.statuses.placed) ||
            (data.order.order_status == 'InKitchen' && this.statusesArray[this.tabIndex] == this.statuses.inkitchen)
          )
        ){
          this.orders.data = [data.order, ...this.orders.data];
        }else if(this.page === 1 && 
          (
            (data.order.order_status != 'Placed' && this.statusesArray[this.tabIndex] == this.statuses.placed) ||
            (data.order.order_status != 'InKitchen' && this.statusesArray[this.tabIndex] == this.statuses.inkitchen )
          )
        ){
          this.orders.data = this.orders.data.filter(order => order.id !== data.order.id);
        }
        this.getCount();
      }
    },
    getOrders(page = 1) {
      this.page = page;
      this.checkMailTemplate();
      this.loading = true;
      orderService
        .getOrders({
          ...this.filters,
          page,
        },{
          cancelToken : new CancelToken(c => {
            cancel = c;
          })
        })
        .then((response) => {
          this.orders = response.data.result;
          setTimeout(() => {
            this.loading = false;
          }, 500);

          this.updateCount(response.data.result.total);

          this.$emit('filterChange',this.filters);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    updateCount(total){
      const { placed, inkitchen, readytoserve, ontheway, delivered, cancelled, scheduled, paymentfailure } = order_statuses;
      switch(this.filters.status){
        case placed:
          this.ordersCount.placed_orders = total;
          break;
        case inkitchen: 
          this.ordersCount.kitchen_orders = total;
          break;
        case readytoserve:
          this.ordersCount.ready_orders = total;
          break;
        case ontheway:
          this.ordersCount.ontheway_orders = total;
          break;
        case delivered:
          this.ordersCount.delivered_orders = total;
          break;
        case cancelled:
          this.ordersCount.cancelled_orders = total;
          break;
        case scheduled:
          this.ordersCount.scheduled_orders = total;
          break;
        case paymentfailure:
          this.ordersCount.failed_orders = total;
          break;
      }

    },
    onTabChanged(tabIndex){
      
      this.tabIndex = tabIndex;
      this.filters.status = this.statusesArray[tabIndex]
      this.checkMailTemplate();
      this.getData();
     
      // this.getNewOrders();
    },
    getOrdersCount(){
      // this.loading = true;
      if (cancelCount !== undefined) {
        cancelCount();
      }
      this.checkMailTemplate();
      orderService
        .getOrdersCount({
          ...this.filters
        },{
          cancelToken : new CancelToken(c => {
            cancelCount = c;
          })
        })
        .then((response) => {
          // this.loading = false;
          this.ordersCount = response.data.result.orders_count;
          this.live_orders = response.data.result.live_orders_count;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    changeSettingRange(){
      var now = this.moment();
      var then = this.moment();
      this.checkMailTemplate();
      if(this.$auth && this.$auth.setting){
        var range = this.$auth.setting.order_dashboard_default_time;
        var date_ranges = this.date_ranges();
        var keys = Object.keys(date_ranges)
        switch(range){
          case "0":
            return {startDate: date_ranges[keys[0]][0],endDate:date_ranges[keys[0]][1]};
          case "1":
            return {startDate: date_ranges[keys[1]][0],endDate:date_ranges[keys[1]][1]};
          case "6":
            return {startDate: date_ranges[keys[2]][0],endDate:date_ranges[keys[2]][1]};
          case "29":
            return {startDate: date_ranges[keys[3]][0],endDate:date_ranges[keys[3]][1]};
          case "365":
            return {startDate: date_ranges[keys[4]][0],endDate:date_ranges[keys[4]][1]};
          case "101":
            return {startDate: date_ranges[keys[5]][0],endDate:date_ranges[keys[5]][1]};
          default:
            return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
        }
      }
      return { startDate: now.startOf('day').toDate(), endDate: then.endOf('day').toDate() }
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="justify-content-between row m-order-list">
                <div class="">
                  <div class="form-group mt-2 pt-4 ml-4">
                    <h2 class="text-success">{{live_orders}}</h2>
                    <h5 class="text-primary">LIVE Orders</h5>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="form-group mt-2 pt-4">
                    <b-form-checkbox v-model="filters.call_center" name="check-button" switch @change="searchChanged">
                      Only Call Center
                    </b-form-checkbox>
                  </div>
                  <div class="">
                    <div class="form-group ml-2">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Date')}} {{(getSelected!=''?('('+getSelected+')'):'')}} :</label>
                        <date-range-picker
                          ref="picker"
                          :locale-data="{
                            firstDay: 1,
                            format: 'dd-mm-yyyy',
                          }"
                          :showDropdowns="true"
                          :showWeekNumbers="true"
                          opens="left"
                          v-model="dateRange"
                          class="w-100"
                          :ranges="date_ranges()"
                          @update="updateValues"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{ moment(picker.startDate.toISOString()).format('YYYY-MM-DD') | date }} -
                            {{ moment(picker.endDate.toISOString()).format('YYYY-MM-DD') | date }}
                          </template>
                        </date-range-picker>
                      </div>
                    </div>
                  </div>
                  <div class="ml-2">
                    <div class="search-box mr-2 mb-0 d-inline-block form-group">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Filter')}} :</label>
                        <div class="position-relative">
                          <select
                            @change="searchChanged"
                            class="custom-select"
                            v-model="filters.search_type"
                            id="admin_commision_type"
                            name="admin_commision_t ype"
                          >
                            <option value="">{{ trans.get('__JSON__.Select Filter') }}</option>
                            <option value="order">Orders</option>
                            <option value="user_name">User Name</option>
                            <option value="mobile_no">Mobile Number</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ml-2">
                    <div class="search-box mr-2 mb-0 d-inline-block form-group">
                      <div class="date-range-list">
                        <label>{{trans.get('__JSON__.Search')}} :</label>
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control"
                            v-model="filters.search"
                            @keyup="searchChanged"
                            :placeholder="trans.get('__JSON__.Search...')"
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-tabs
                justified
                nav-class="nav-tabs-custom mt-0 order-tabs"
                content-class="p-3 text-muted"
                lazy
                @activate-tab="onTabChanged"
              >
                <b-tab :button-id="statuses.placed" >
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-plus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.New Requests") 
                    }}</span>
                    <span v-if="ordersCount && ordersCount.placed_orders && ordersCount.placed_orders !== '0'" class="badge badge-success font-size-12">
                      {{ ordersCount && ordersCount.placed_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders" :loading="loading" @refreshData="getData" :cancel_order="cancel_order"  />
                </b-tab>
                <b-tab :button-id="statuses.inkitchen">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Confirmed")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.kitchen_orders && ordersCount.kitchen_orders !== '0'" class="badge badge-primary font-size-12">
                      {{ ordersCount && ordersCount.kitchen_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders" :loading="loading" @refreshData="getData" :showDeliveryTime="true" :delay_order_btn="delay_order_btn" :not_response_btn="not_response_btn" :cancel_order="cancel_order"  />
                </b-tab>
                <b-tab :button-id="statuses.readytoserve">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-bus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Ready to Delivery")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.ready_orders && ordersCount.ready_orders !== '0'" class="badge badge-info font-size-12">
                      {{ ordersCount && ordersCount.ready_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders" :loading="loading" @refreshData="getData" :extra-columns="readyToDeliveryColumns" :delay_order_btn="delay_order_btn" :not_response_btn="not_response_btn" :cancel_order="cancel_order"  />
                </b-tab>
                <b-tab :button-id="statuses.ontheway">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-bus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.On The Way")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.ontheway_orders && ordersCount.ontheway_orders !== '0'" class="badge badge-warning font-size-12">
                      {{ ordersCount && ordersCount.ontheway_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders" :loading="loading" :extra-columns="driverColumns" @refreshData="getData" :delay_order_btn="delay_order_btn" :not_response_btn="not_response_btn"  :cancel_order="cancel_order"  />
                </b-tab>
                <b-tab :button-id="statuses.delivered">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-bus"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Delivered")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.delivered_orders && ordersCount.delivered_orders !== '0'" class="badge badge-info font-size-12">
                      {{ ordersCount && ordersCount.delivered_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders" :loading="loading" :extra-columns="driverColumns" @refreshData="getData" :delay_order_btn="delay_order_btn" :not_response_btn="not_response_btn"  :cancel_order="cancel_order" />
                </b-tab>
                <b-tab :button-id="statuses.scheduled" :active="$route.query.status == 'Scheduled'">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-calendar"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Scheduled")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.scheduled_orders && ordersCount.scheduled_orders !== '0'" class="badge badge-info font-size-12">
                      {{ ordersCount && ordersCount.scheduled_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders" :loading="loading" :extra-columns="scheduleColumns" @refreshData="getData" :delay_order_btn="delay_order_btn" :not_response_btn="not_response_btn" :cancel_order="cancel_order"   />
                </b-tab>
                <b-tab :button-id="statuses.cancelled">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-times"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Cancelled")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.cancelled_orders && ordersCount.cancelled_orders !== '0'" class="badge badge-danger font-size-12">
                      {{ ordersCount && ordersCount.cancelled_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders"  :loading="loading" :extra-columns="cancelledColumns" @refreshData="getData" :delay_order_btn="delay_order_btn" :not_response_btn="not_response_btn"  :cancel_order="cancel_order" />
                </b-tab>
                <b-tab :button-id="statuses.paymentfailure" v-if="!$auth.partner_login">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-credit-card"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      trans.get("__JSON__.Payment Failed")
                    }}</span>
                    <span v-if="ordersCount && ordersCount.failed_orders && ordersCount.failed_orders !== '0'" class="badge badge-danger font-size-12">
                      {{ ordersCount && ordersCount.failed_orders || 0 }}
                    </span>
                  </template>
                  <OrderList :orders="orders"  :loading="loading" @refreshData="getData"  />
                </b-tab>
              </b-tabs>
            </div>
            <div class="col-12">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0" v-if="orders">
                  <pagination
                      :data="orders"
                      :limit="limit"
                      @pagination-change-page="getOrders"
                  ></pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>