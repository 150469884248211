<script>
import NavBar from "../../components/menu-nav-bar";
import config from "../../config";
import DatePicker from "vue2-datepicker";
import { menuService,vendorService } from "../../services";
import { error_message,success_message } from "../../Helper/helper";
import Swal from "sweetalert2";
import { required,email,maxLength,minlength,numeric,helpers,requiredIf} from "vuelidate/lib/validators";
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,2}|\d+)$/)
export default {
  components: { NavBar, DatePicker },
  data() {
    return {
      config: config,
      restaurant: [],
      submitted: false,
      typeform: {
        name: "",
        restaurant_id: this.$route.params.restaurant_id,
        phone_number: "",
        feedback: "",
        ambience: "",
        time_taken_to_serve: "",
        food_taste: "",
        staff_courteousness: "",
        food_quality: "",
        manager_or_captain_visit_your_table: "",
        birthday:"",
        anniversary: "",
        bill_number: ""
      },
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      phone_number: {
        required,
        numeric,
      }
    },
  },
  mounted() {
    console.log(this.config.foodicon +''+document.getElementById('application_logo').value);
    document.getElementById('feedback_lg').src =  this.config.foodicon +''+document.getElementById('application_logo').value;

    this.getRestaurantById();
  },
   methods: {
    getRestaurantById() {
      vendorService
        .getRestaurantDineInById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
            restaurant_on_off,
            pet_pooja_restaurant_id
          } = response.data.result;
          this.restaurant = {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
            pet_pooja_restaurant_id
          };
        });
    },
    onCancel(){
        this.$router.push({ name: 'menu-list', params: {  pet_pooja_restaurant_id : this.restaurant.pet_pooja_restaurant_id } });
    },
    feedbackInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        this.submitted = true;
        const fd = new FormData();
        fd.append("restaurant_id", this.typeform.restaurant_id)
        fd.append("name", this.typeform.name);
        fd.append("phone_number", this.typeform.phone_number);
        fd.append("feedback", this.typeform.feedback);
        fd.append("ambience", this.typeform.ambience);
        fd.append("time_taken_to_serve", this.typeform.time_taken_to_serve);
        fd.append("food_taste", this.typeform.food_taste);
        fd.append("staff_courteousness", this.typeform.staff_courteousness);
        fd.append("food_quality", this.typeform.food_quality);
        fd.append("manager_or_captain_visit_your_table", this.typeform.manager_or_captain_visit_your_table);
        fd.append("birthday", this.typeform.birthday);
        fd.append("anniversary", this.typeform.anniversary);
        fd.append("bill_number", this.typeform.bill_number);
        menuService.addFeedbackApi(fd).then((response) => {
          this.submitted = false;
          this.typeform = {
                restaurant_id: this.$route.params.restaurant_id,
                name: "",
                phone_number: "",
                feedback: "",
                ambience: "",
                time_taken_to_serve: "",
                food_taste: "",
                staff_courteousness: "",
                food_taste: "",
                manager_or_captain_visit_your_table: "",
                birthday: "",
                anniversary: "",
                bill_number: ""
          }
          this.$v.typeform.$reset();
          if (response.data.code === 200) {
            Swal.fire({
              title: response.data.message,
              icon: "success",
              confirmButtonText: "Submit another feedback"
             }).then((result) => {
                 this.$router.go()
             });
            // success_message(response.data.message);
           
            // this.$router.push({ name: 'menu-list', params: {  pet_pooja_restaurant_id : this.restaurant.pet_pooja_restaurant_id } });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>
<template>
  <div layout="vertical">
    <div id="layout-wrapper">
      <!-- <NavBar /> -->
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
      <div class="main-content w-100 ml-0 m-main-content account-pages pt-2">
        <div class="m-container-fluid-menu feedback-form">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 col-lg-6 col-xl-5 login-main">
                <div class="card overflow-hidden">
                  <div class="card-body pt-0">
                    
                    <div class="row flex-nowrap align-items-center">
                      <div class="col-md-6">
                        <h4 class="card-title pt-4">Feedback</h4>
                      </div>
                      <div class="col-md-6 food-icon text-right">
                        <img src="//dashboard.chowman.net/public/media/feedback.png" id="feedback_lg" class="pt-3">
                      </div>
                    </div>

                    <form action="#" @submit.prevent="feedbackInIt" class="pt-4">
                       <div class="form-group">
                          <input
                            id="restaurant_id"
                            name="restaurant_id"
                            type="hidden"
                            v-model="typeform.restaurant_id"
                            class="form-control"
                          />
                        </div>

                       <div class="form-group">
                        <label>Bill Number</label>
                        <input
                          v-model="typeform.bill_number"
                          type="text"
                          class="form-control"
                          placeholder="Enter bill number"
                          name="bill_number"
                        />
                      </div>

                      <div class="form-group">
                        <label>Name</label>
                        <input
                          v-model="$v.typeform.name.$model"
                          type="text"
                          class="form-control"
                          placeholder="Enter name"
                          name="name"
                          :class="{ 'is-invalid': $v.typeform.name.$error }"
                        />
                        <div v-if="$v.typeform.name.$error" class="invalid-feedback cuisine-img">
                          <span
                            v-if="!$v.typeform.name.required"
                          >Enter name</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label>Phone Number</label>
                        <input
                          v-model="$v.typeform.phone_number.$model"
                          type="tel"
                          minlength="10"
                          maxlength="12"
                          class="form-control"
                          placeholder="Enter phone number"
                          name="phone_number"
                          :class="{ 'is-invalid': $v.typeform.phone_number.$error }"
                        />
                        <div v-if="$v.typeform.phone_number.$error" class="invalid-feedback cuisine-img">
                          <span v-if="!$v.typeform.phone_number.required">{{ trans.get('__JSON__.Please enter contact number') }}</span>
                          <span v-if="!$v.typeform.phone_number.numeric">{{ trans.get('__JSON__.This value should be a valid number') }}</span>
                        </div>
                      </div>

                      <div class="wd100">
                        <div class="table-wrapper">
                          <div class="table-inner">
                            <table class="pricing-table">
                              <tbody>
                                <tr>
                                  <th></th>
                                  <th class="pl-2 w-70 text-center">Best</th>
                                  <th class="pl-3 w-70 text-center">Good</th>
                                  <th class="pl-3 w-70 text-center">Average</th>
                                </tr>
                                <tr class="highlight">
                                  <td>
                                    <b>Ambience</b>
                                  </td>
                                  <td class="fed-smile icon-thread best-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios"
                                      id="optionsRadios1"
                                      value="1"
                                      v-model="typeform.ambience"
                                    />
                                    <label class="radio m-0" for="optionsRadios1">
                                      <i class="bx bx-happy-alt"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread good-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios"
                                      id="optionsRadios1"
                                      value="2"
                                      v-model="typeform.ambience"
                                    />
                                    <label class="radio m-0" for="optionsRadios1">
                                      <i class="bx bx-smile"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread average-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios"
                                      id="optionsRadios1"
                                      value="3"
                                      v-model="typeform.ambience"
                                    />
                                    <label class="radio m-0" for="optionsRadios1">
                                      <i class="bx bx-confused"></i>
                                    </label>
                                  </td>
                                </tr>
                                <tr class="highlight">
                                  <td>
                                    <b>Time Taken To Serve</b>
                                  </td>
                                  <td class="fed-smile icon-thread best-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios1"
                                      id="optionsRadios4"
                                      value="1"
                                      v-model="typeform.time_taken_to_serve"
                                    />
                                    <label class="radio m-0" for="optionsRadios4">
                                      <i class="bx bx-happy-alt"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread good-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios1"
                                      id="optionsRadios4"
                                      value="2"
                                      v-model="typeform.time_taken_to_serve"
                                    />
                                    <label class="radio m-0" for="optionsRadios4">
                                      <i class="bx bx-smile"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread average-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios1"
                                      id="optionsRadios4"
                                      value="3"
                                      v-model="typeform.time_taken_to_serve"
                                    />
                                    <label class="radio m-0" for="optionsRadios4">                                      
                                      <i class="bx bx-confused"></i>
                                    </label>
                                  </td>
                                </tr>
                                <tr class="highlight">
                                  <td>
                                    <b>Food Taste</b>
                                  </td>
                                  <td class="fed-smile icon-thread best-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios2"
                                      id="optionsRadios7"
                                      value="1"
                                      v-model="typeform.food_taste"
                                    />
                                    <label class="radio m-0" for="optionsRadios7">
                                    <i class="bx bx-happy-alt"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread good-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios2"
                                      id="optionsRadios7"
                                      value="2"
                                      v-model="typeform.food_taste"
                                    />
                                    <label class="radio m-0" for="optionsRadios7">
                                      <i class="bx bx-smile"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread average-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios2"
                                      id="optionsRadios7"
                                      value="3"
                                      v-model="typeform.food_taste"
                                    />
                                    <label class="radio m-0" for="optionsRadios7">
                                      <i class="bx bx-confused"></i>
                                    </label>
                                  </td>
                                </tr>
                                <tr class="highlight">
                                  <td>
                                    <b>Staff Courteousness</b>
                                  </td>
                                  <td class="fed-smile icon-thread best-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios3"
                                      id="optionsRadios10"
                                      value="1"
                                      v-model="typeform.staff_courteousness"
                                    />
                                    <label class="radio m-0" for="optionsRadios10">
                                      <i class="bx bx-happy-alt"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread good-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios3"
                                      id="optionsRadios10"
                                      value="2"
                                      v-model="typeform.staff_courteousness"
                                    />
                                    <label class="radio m-0" for="optionsRadios10">
                                      <i class="bx bx-smile"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread average-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios3"
                                      id="optionsRadios10"
                                      value="3"
                                      v-model="typeform.staff_courteousness"
                                    />
                                    <label class="radio m-0" for="optionsRadios10">
                                      <i class="bx bx-confused"></i>
                                    </label>
                                  </td>
                                </tr>
                                <tr class="highlight">
                                  <td>
                                    <b>Food Quality</b>
                                  </td>
                                  <td class="fed-smile icon-thread best-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios4"
                                      id="optionsRadios13"
                                      value="1"
                                      v-model="typeform.food_quality"
                                    />
                                    <label class="radio m-0" for="optionsRadios13">
                                      <i class="bx bx-happy-alt"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread good-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios4"
                                      id="optionsRadios13"
                                      value="2"
                                      v-model="typeform.food_quality"
                                    />
                                    <label class="radio m-0" for="optionsRadios13">
                                      <i class="bx bx-smile"></i>
                                    </label>
                                  </td>
                                  <td class="fed-smile icon-thread average-icon text-center">
                                    <input
                                      type="radio"
                                      name="optionsRadios4"
                                      id="optionsRadios13"
                                      value="3"
                                      v-model="typeform.food_quality"
                                    />
                                    <label class="radio m-0" for="optionsRadios13">
                                      <i class="bx bx-confused"></i>
                                    </label>
                                  </td>
                                </tr>
                                <tr class="highlight">
                                  <td class="pt-2">
                                    <b>Did the manager or captain visit your table?</b>
                                  </td>
                                  <td class="pl-2">
                                    <b-form-radio-group>
                                      <b-form-radio class="mb-1" value="1" v-model="typeform.manager_or_captain_visit_your_table">Yes</b-form-radio>
                                    </b-form-radio-group>
                                  </td>
                                  <td>
                                    <b-form-radio-group>
                                      <b-form-radio class="mb-1" value="0" v-model="typeform.manager_or_captain_visit_your_table">No</b-form-radio>
                                    </b-form-radio-group>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>


                      <div class="form-group mt-2">
                        <label>Your Experience</label>
                        <div>
                          <textarea
                            v-model="typeform.feedback"
                            class="form-control"
                            placeholder="Write Your Experience....."
                            name="feedback"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>

                      <div class="form-group mt-3">
                        <b-form-group id="input-group-1" label="BirthDay" label-for="input-1">
                          <date-picker v-model="typeform.birthday" value-type="format"></date-picker>
                        </b-form-group>
                      </div>

                      <div class="form-group">
                        <b-form-group id="input-group-2" label="Anniversary"  label-for="input-2">
                          <date-picker v-model="typeform.anniversary" value-type="format"></date-picker>
                        </b-form-group>
                      </div>

                      <div class="form-group mb-0">
                        <div class="feedbacks">
                          <button class="btn btn-primary" :disabled="this.submitted" type="button" @click="feedbackInIt">Submit</button>
                          <button class="btn btn-secondary m-l-5 ml-1" type="button" @click.prevent="onCancel">Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- end card-body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .w-70{
      width: 70px !important;
  }
  .food-icon img{
    height: 80px;
  }
  label.radio .bx{
    font-size: 30px;
  }
  tr.highlight{
    height: 60px;
  }
</style>