<script>
import Layout from "../../layouts/main";
import config from "../../config";
import PageHeader from "../../components/page-header";;
import pagination from "laravel-vue-pagination";
import Multiselect from 'vue-multiselect'
import { error_message,success_message,} from "../../Helper/helper";
import { customizationService } from "../../services";

import { required} from "vuelidate/lib/validators";

/**
 * Add-product component
 */
export default {
    components: {pagination,Layout, PageHeader, Multiselect},
    data() {
        return {
            config: config,
            customizations: [],
            menu_customizations: [],
            cust_type_search: [],
            templates: [],
            addModal: false,
            editModal: false,
            templateModal: false,
            selectModal: false,
            sortDesc: true,
            paginations: {},
            limit: 1,
            search: "",
            isLoading: false,
            status: 1,
            page: 1,
            error: "",
            customize_id: "",
            restaurant_id: "",
            menu_item_name: "",
            typeform: {
                is_veg_non: "2",
                type_name: "",
                type_name_thai: "",
                is_min_selection: "0",
                is_selection: "0",
                menu_item_id: this.$route.params.menu_item_id
            },
            custType: {
                is_veg_non: "",
                type_name: "",
                type_name_thai: "",
                is_min_selection: "",
                is_selection: "",
                customize_type_id: ""
            },
            template:{
                template_name: "", 
            },
            selectTem:{
                cust_type_search: ""
            },
            typeForm:{
               customizations: ""
            }
        };
    },
    validations: {
        typeform: {
            type_name: {
                required,
            },
        },
        custType: {
            type_name: {
                required,
            },
        },
        template:{
            template_name: {
                required,
            }
        },
        selectTem:{
            cust_type_search: {
                required,
            }
        }
    },
    mounted() {
        this.getItemCustmization();
    },
    methods: {
        getItemCustmization() {
            customizationService.getItemCustmization({menu_item_id : this.$route.params.menu_item_id})
                .then((response) => {
                    this.customizations = response.data.result.data;
                    this.restaurant_id = response.data.result.res_id;
                    this.menu_item_name = response.data.result.item_name;
                    this.customizations.forEach((item,index) => {
                        if(item.menu_customization.length == 0){
                            this.addSlot(index);
                        }
                    })
            });
        },
        customizationAdd(e){
            this.$v.typeform.$touch();
            if (this.$v.typeform.$invalid) {
                return;
            }
            else{
                customizationService.addCustmization(this.typeform)
                    .then(response => {
                    if (response.data.code === 200) {
                        this.addModal = false;
                        this.getItemCustmization();
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                    this.typeform = {
                        is_veg_non: "2",
                        type_name: "",
                        type_name_thai: "",
                        is_min_selection: "0",
                        is_selection: "0",
                        menu_item_id: this.$route.params.menu_item_id
                    }
                    this.$v.$reset();
                });
            }
        },
        getCustmizationType(id){
            customizationService.getCustmizationType({customize_type_id : id})
            .then((response) => {
                const{customize_type_id,type_name, type_name_thai,is_selection, is_min_selection,is_veg_non} = response.data.result;
                this.custType = {customize_type_id,type_name, type_name_thai,is_selection, is_min_selection,is_veg_non};
                this.editModal = true;
            });
        },
        customizationEdit(e){
            this.$v.custType.$touch();
            if (this.$v.custType.$invalid) {
                return;
            }
            else{
                customizationService.customizationEdit(this.custType)
                    .then(response => {
                    if (response.data.code === 200) {
                        this.editModal = false;
                        success_message(response.data.message);
                        this.getItemCustmization();
                    } else {
                        error_message(response.data.message);
                    }
                });
            }
        },
        deleteCustmizationType(id){
            if(confirm("Do you really want to delete?")){
                customizationService.deleteCustmizationType({customize_type_id:id})
                .then(response => {
                if (response.data.code === 200) {
                    this.getItemCustmization();
                    success_message(response.data.message);
                } else {
                    error_message(response.data.message);
                }
                });
            }
        },
        templateSave(id){
            this.templateModal = true;
            this.customize_id = id;
        },
        saveAstemplate(){
            this.$v.template.$touch();
            if (this.$v.template.$invalid) {
                return;
            }
            else{
                customizationService.saveTemplate({template_name : this.template, customize_type_id:this.customize_id})
                    .then(response => {
                    if (response.data.code === 200) {
                        this.templateModal = false;
                        success_message(response.data.message);
                        this.getItemCustmization();
                    } else {
                        error_message(response.data.message);
                    }
                    this.template = {
                        template_name:''
                    };
                    this.$v.$reset();
                });
            }
        },
        removeSlot(id,index,mindex){
            if(id){
                customizationService.deleteitemCustmization({menu_item_selection_id:id})
                    .then(response => {
                    if (response.data.code === 200) {
                        success_message(response.data.message);
                        this.getItemCustmization();
                    } else {
                        error_message(response.data.message);
                    }
                });
            }else{
                
                this.customizations[index].menu_customization.splice(mindex,1);  
            }
            
        },
        addSlot: function (index) {
            this.customizations[index].menu_customization.push({ 
               selection_name: '',
               selection_name_thai:'',
               selection_price:'',is_default:'',
               status:'',
               id: '',
               customize_type_id: this.customizations[index].customize_type_id 
            });
        },
        checkItem(event, index,mindex){
            if(event.target.checked){
                this.customizations[index].menu_customization.forEach((element,i) => {
                    this.customizations[index].menu_customization[i].is_default = 0;
                });
                this.customizations[index].menu_customization[mindex].is_default = 1;
            }else{
                this.customizations[index].menu_customization[mindex].is_default = 0;
            }
        },
        selectAsModal(){
            this.selectModal = true;
        },
        selectFromtemplate(){
            this.$v.$touch();
            if (this.$v.selectTem.$invalid) {
                return;
            } else {
                const fd = new FormData();
                fd.append("menu_item_id", this.$route.params.menu_item_id);
                this.selectTem.cust_type_search
                .map((item) => item.customize_type_id)
                .map((item, index) => {
                    fd.append("cust_type_search[" + index + "]", item);
                });
                customizationService.addTemplate(fd)
                .then(response => {
                    if (response.data.code === 200) {
                        this.selectModal = false;
                        this.getItemCustmization();
                        success_message(response.data.message);
                    } else {
                        error_message(response.data.message);
                    }
                    this.selectTem.cust_type_search = [];
                    this.$v.$reset();
                });
            }
        },
        asyncFind(searchtext) {
            this.isLoading = true;
            customizationService.getTemplate({searchtext: searchtext,menu_item_id : this.$route.params.menu_item_id})
                .then(response => {
                this.templates = response.data.result; 
                this.isLoading = false;
            });
        },
        clearAll() {
            this.cust_type_search = [];
        },
        saveCustomisation(id){
            var customization = this.customizations.find(c => c.customize_type_id == id)
            if(customization){
                console.log(customization);
                var menu_customization = customization
                .menu_customization
                .reduce((a, r) => r.selection_name == ''?a:a.concat({
                    customize_type_id:  r.customize_type_id,
                    is_default: r.is_default,
                    is_min_selection:  r.is_min_selection,
                    is_selection: r.is_selection,
                    menu_item_selection_id: r.menu_item_selection_id,
                    parent_item_id: r.parent_item_id,
                    selection_name: r.selection_name,
                    selection_name_thai: r.selection_name_thai,
                    selection_price: r.selection_price,
                    packaging_charge: r.packaging_charge,
                    status: r.status,
                }),[]);  
                if(menu_customization.length > 0){
                    customizationService.addMenuSelection({menu_customization:menu_customization})
                        .then(response => {
                        if (response.data.code === 200) {
                            success_message(response.data.message);
                            this.getItemCustmization();
                        } else {
                            error_message(response.data.message);
                        }
                    });
                }else{
                    error_message('Please Enter Deatils');
                }
            }
        },
        floating(e,index,mindex){   
            const floating = /^[0-9.]\d*(\.\d{1,2})?$/;
            this.customizations[index].menu_customization.forEach((element,i) => {
                let char = String.fromCharCode(e.keyCode);
                if (floating.test(char)) return true;
                else e.preventDefault();
            });
        },
    }
};
</script>

<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page" >
                    <div class="page-title-left avatar-main-icon">
                        <div class="avatar-xs mr-3">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i class="bx bx-store"></i>
                            </span>
                        </div>
                        <div class="report-tag" v-if="!$auth.partner_login">
                            <h4 class="mb-0 font-size-18">{{this.menu_item_name}}</h4>
                            <!-- <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > <router-link :to="{name: 'vendor-add-customization',params: { menu_item_id: $route.params.menu_item_id}}">{{ trans.trans('__JSON__.Customization') }}</router-link> > {{this.menu_item_name}}</p> -->
                            <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > {{ trans.get('__JSON__.Customization') }}</p>
                        </div>
                        <div v-else>
                            <h4 class="mb-0 font-size-18">{{this.menu_item_name}}</h4>
                            <!-- <p><router-link :to="{ name: 'vendor-list'}">{{ trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name }) }}</router-link> > <router-link :to="{name: 'vendor-catalog',params: { restaurant_id: base64(this.restaurant_id)}}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > <router-link :to="{name: 'vendor-add-customization',params: { menu_item_id: $route.params.menu_item_id}}">{{ trans.trans('__JSON__.Customization') }}</router-link> > {{this.menu_item_name}}</p> -->
                            <p><router-link :to="{name: 'vendor.vendor.catalog'}">{{ trans.trans('__JSON__.Catalog') }}</router-link> > {{ trans.get('__JSON__.Customization') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="drag-container">
            <div class="row drag-list menu-left-main">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body menu-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Customization') }}({{customizations.length}})</h4>
                                        <div class="page-title-right">
                                            <button type="button" class="btn btn-primary btn-rounded mr-2" @click="selectAsModal()">
                                            {{ trans.get('__JSON__.Select From Template') }}
                                            </button>

                                            <button type="button" class="btn btn-primary btn-rounded mr-2" @click="addModal = true">
                                            <i class="mdi mdi-plus mr-1"></i>
                                            {{ trans.get('__JSON__.Add Customisation Type') }}
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end row -->
                                <div class="row">
                                    <div class="col-md-12" v-if="customizations.length > 0">
                                        <div class="card">
                                            <div class="card-body">
                                                <div role="tablist">
                                                    <b-card no-body class="mb-1 shadow-none" v-for="(customization,cindex) in customizations" :key="customization.customize_type_id">
                                                        <a
                                                            v-b-toggle="'accordion-' + customization.customize_type_id"
                                                            class="text-dark "
                                                            href="javascript: void(0);"
                                                            >
                                                            <b-card-header header-tag="header" role="tab">
                                                                <div class="col-12">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <h6 class="m-0 pt-1">
                                                                            {{customization.type_name}}
                                                                        </h6>
                                                                        <div class="page-title-right">
                                                                            <button type="button" class="btn btn-success btn-rounded mr-2" @click="templateSave(customization.customize_type_id)">
                                                                            {{ trans.get('__JSON__.Save As Template') }}
                                                                            </button>
                                                                            <button type="button" class="btn btn-primary btn-rounded mr-2" @click="getCustmizationType(customization.customize_type_id)">
                                                                            {{ trans.get('__JSON__.Edit') }}
                                                                            </button>
                                                                            <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteCustmizationType(customization.customize_type_id)">
                                                                            {{ trans.get('__JSON__.Delete') }}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </b-card-header>
                                                        </a>
                                                        <b-collapse class="ctmz-accordion" :id="'accordion-' + customization.customize_type_id" visible accordion="my-accordion" role="tabpanel">
                                                            <b-card-body v-if="customization.menu_customization.length > 0">
                                                                <form @submit.prevent="saveCustomisation">
                                                                    <div class="table-responsive">
                                                                        <table class="table mb-0 addcust-tbl">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th> {{trans.get('__JSON__.Item Name')}} </th>
                                                                                    <th v-if="$auth.langauges[1].name != 'No Secondary Language'"> {{trans.get('__JSON__.Item Name Other Langauges')}} </th>
                                                                                    <th> {{trans.get('__JSON__.Price')}} (<span v-html="$auth.setting && $auth.setting.currency"></span>)</th>
                                                                                    <th> {{trans.get('__JSON__.Packaging Charge')}} (<span v-html="$auth.setting && $auth.setting.currency"></span>)</th>
                                                                                    <th> {{trans.get('__JSON__.Default')}} </th>
                                                                                    <th> {{trans.get('__JSON__.Active')}} </th>
                                                                                    <th style="width: 30%"></th>
                                                                                </tr>
                                                                            </thead>
                                                                            
                                                                            <tbody v-for="(menucustomization,index) in customization.menu_customization" :key="menucustomization.menu_item_selection_id">
                                                                                <tr>
                                                                                    <td><input type="text" class="form-control" v-model="customizations[cindex].menu_customization[index].selection_name"></td>
                                                                                    <td v-if="$auth.langauges[1].name != 'No Secondary Language'"><input type="text" class="form-control" v-model="customizations[cindex].menu_customization[index].selection_name_thai"></td>
                                                                                    <td><input type="text" class="form-control" v-model="customizations[cindex].menu_customization[index].selection_price" v-on:keypress="floating($event,cindex,index)"></td>
                                                                                    <td><input type="text" class="form-control" v-model="customizations[cindex].menu_customization[index].packaging_charge" v-on:keypress="floating($event,cindex,index)"></td>
                                                                                    <td><input type="checkbox" class="custom-checkbox" @change="checkItem($event,cindex,index)" :checked="parseInt(customizations[cindex].menu_customization[index].is_default)" id="is_default" true-value="1" false-value="0"/></td>
                                                                                    <td class="vendor-status vendr-switch"><label class="switch"><input type="checkbox" id="togBtn" name="status" v-model="customizations[cindex].menu_customization[index].status" class="switch-on" true-value="1" false-value="0"/>
                                                                                        <div class="slider round">
                                                                                            <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                                                            <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                                                        </div>
                                                                                    </label>
                                                                                    </td>
                                                                                    <td class="modfy-btns" >
                                                                                        <button class="btn btn-primary btn-sm btn-rounded" @click.prevent="addSlot(cindex)">+</button>
                                                                                        <button class="btn btn-danger btn-sm btn-rounded" @click.prevent="removeSlot(menucustomization.menu_item_selection_id,cindex,index)" v-if="index!='0' || index!=''">-</button>
                                                                                        <router-link
                                                                                                :to="{name: 'vendor-add-sub-customization',params: { menu_item_selection_id: base64(menucustomization.menu_item_selection_id)}}"
                                                                                                class="btn btn-success btn-sm btn-rounded"
                                                                                            v-if="customizations[cindex].menu_customization[index].selection_name.length > 1">{{ trans.get('__JSON__.Add Sub') }}</router-link>
                                                                                    </td> 
                                                                                    <!-- <td class="modfy-btns" v-else>
                                                                                        <button class="btn btn-primary btn-sm btn-rounded" @click.prevent="addSlot(cindex)">+</button>
                                                                                        <button class="btn btn-danger btn-sm btn-rounded" @click.prevent="removeSlot(menucustomization.menu_item_selection_id,cindex,index)" v-if="index!='0' || index!=''">-</button>
                                                                                        <router-link
                                                                                                :to="{name: 'vendor.vendor.add.sub.customization',params: { menu_item_selection_id: base64(menucustomization.menu_item_selection_id)}}"
                                                                                                class="btn btn-success btn-sm btn-rounded"
                                                                                            >{{ trans.get('__JSON__.Add Sub') }}</router-link>
                                                                                    </td>  -->
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </form>
                                                                 <button class="btn btn-primary cust" @click=saveCustomisation(customization.customize_type_id)>{{ trans.get('__JSON__.Save') }}</button>
                                                            </b-card-body> 
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div v-else>
                                        <div class="col-12">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="m-0 pt-1">
                                                    No Customisations Available
                                                </h6>
                                            </div>
                                        </div>
                                    </div> -->

                                    <!-- Customisation Type Modal Start -->
                                    <b-modal
                                        id="modal-scoped"
                                        v-model="addModal"
                                        :title="trans.get('__JSON__.Customisation Type')"
                                        centered
                                        >
                                        <b-form @submit.prevent="customizationAdd">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-2"
                                                        :label="trans.get('__JSON__.Type')"
                                                        label-for="Status"
                                                    >
                                                        <div class="row">
                                                            <div class="col-sm-4">
                                                                <b-form-radio
                                                                v-model="typeform.is_veg_non"
                                                                name="is_veg_non"
                                                                value="2"
                                                                checked
                                                                >{{ trans.get('__JSON__.None') }}</b-form-radio>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <b-form-radio
                                                                v-model="typeform.is_veg_non"
                                                                name="is_veg_non"
                                                                value="0"
                                                                checked
                                                                >{{ trans.get('__JSON__.Veg') }}</b-form-radio>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <b-form-radio
                                                                v-model="typeform.is_veg_non"
                                                                name="is_veg_non"
                                                                value="1"
                                                                >{{ trans.get('__JSON__.Non Veg') }}</b-form-radio>
                                                            </div>
                                                        </div>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Customisation Type')"
                                                        label-for="type_name"
                                                    >
                                                        <b-form-input id="type_name" name="type_name" v-model="$v.typeform.type_name.$model" type="text"
                                                        :placeholder="trans.get('__JSON__.Enter Customisation Type')"
                                                        :class="{ 'is-invalid': $v.typeform.type_name.$error }"
                                                        ></b-form-input>
                                                        <div v-if="$v.typeform.type_name.$error" class="invalid-feedback">
                                                        <span
                                                            v-if="!$v.typeform.type_name.required"
                                                        >{{ trans.get('__JSON__.Please enter customisation type') }}</span>
                                                        </div>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-12" v-if="$auth.langauges[1].name != 'No Secondary Language'">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Customisation Type Other Language')"
                                                        label-for="type_name_thai"
                                                    >
                                                        <b-form-input id="type_name_thai" name="type_name_thai" v-model="typeform.type_name_thai" type="text"
                                                        :placeholder="trans.get('__JSON__.Enter Customisation Type Other Language')"
                                                        
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </div>  

                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Necessary Item Selection')"
                                                        label-for="is_min_selection"
                                                    >
                                                        <select
                                                            class="custom-select"
                                                            v-model="typeform.is_min_selection"
                                                            id="is_min_selection"
                                                            name="is_min_selection"
                                                            >
                                                            <option v-for="(x,i) in (Array(21))" :key="i" :value="i">
                                                                {{i}}
                                                            </option>
                                                        </select>
                                                    </b-form-group>
                                                </div> 

                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Max Item Selection')"
                                                        label-for="is_selection"
                                                    >
                                                        <select
                                                            class="custom-select"
                                                            v-model="typeform.is_selection"
                                                            id="is_selection"
                                                            name="is_selection"
                                                            >
                                                            <option v-for="(x,i) in (Array(21))" :key="i" :value="i">
                                                                {{i}}
                                                            </option>
                                                        </select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <input type="hidden" name="menu_item_id" v-model="typeform.menu_item_id" value="typeform.menu_item_id">
                                        </b-form>
                                        <template slot="modal-footer">
                                        <div class="w-100">
                                            <b-button
                                            variant="primary"
                                            class="float-right"
                                            @click="customizationAdd"
                                            >{{ trans.get('__JSON__.Submit') }}</b-button>
                                            <b-button class="float-left" @click="addModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
                                        </div>
                                        </template>
                                    </b-modal>
                                    <!-- Add Customisation Type Modal End -->

                                    <!-- Edit Customisation Modal Start -->
                                    <b-modal
                                        id="modal-scoped"
                                        v-model="editModal"
                                        :title="trans.get('__JSON__.Customisation Type')"
                                        centered
                                        >
                                        <b-form @submit.prevent="customizationEdit">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-2"
                                                        :label="trans.get('__JSON__.Type')"
                                                        label-for="Status"
                                                    >
                                                        <div class="row">
                                                            <div class="col-sm-4">
                                                                <b-form-radio
                                                                v-model="custType.is_veg_non"
                                                                name="is_veg_non"
                                                                value="2"
                                                                checked
                                                                >{{ trans.get('__JSON__.None') }}</b-form-radio>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <b-form-radio
                                                                v-model="custType.is_veg_non"
                                                                name="is_veg_non"
                                                                value="0"
                                                                checked
                                                                >{{ trans.get('__JSON__.Veg') }}</b-form-radio>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <b-form-radio
                                                                v-model="custType.is_veg_non"
                                                                name="is_veg_non"
                                                                value="1"
                                                                >{{ trans.get('__JSON__.Non Veg') }}</b-form-radio>
                                                            </div>
                                                        </div>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Customisation Type')"
                                                        label-for="type_name"
                                                    >
                                                        <b-form-input id="type_name" name="type_name" v-model="$v.custType.type_name.$model" type="text"
                                                        :placeholder="trans.get('__JSON__.Enter Customisation Type')"
                                                        :class="{ 'is-invalid': $v.custType.type_name.$error }"
                                                        ></b-form-input>
                                                        <div v-if="$v.custType.type_name.$error" class="invalid-feedback">
                                                        <span
                                                            v-if="!$v.custType.type_name.required"
                                                        >{{ trans.get('__JSON__.Please enter customisation type') }}</span>
                                                        </div>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Customisation Type Other Language')"
                                                        label-for="type_name_thai"
                                                    >
                                                        <b-form-input id="type_name_thai" name="type_name_thai" v-model="custType.type_name_thai" type="text"
                                                        :placeholder="trans.get('__JSON__.Enter Customisation Type Other Language')"
                                                        
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </div>  

                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Necessary Item Selection')"
                                                        label-for="is_min_selection"
                                                    >
                                                        <select
                                                            class="custom-select"
                                                            v-model="custType.is_min_selection"
                                                            id="is_min_selection"
                                                            name="is_min_selection"
                                                            >
                                                            <option v-for="(x,i) in (Array(21))" :key="i" :value="i">
                                                                {{i}}
                                                            </option>
                                                        </select>
                                                    </b-form-group>
                                                </div> 

                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Max Item Selection')"
                                                        label-for="is_selection"
                                                    >
                                                        <select
                                                            class="custom-select"
                                                            v-model="custType.is_selection"
                                                            id="is_selection"
                                                            name="is_selection"
                                                            >
                                                            <option v-for="(x,i) in (Array(21))" :key="i" :value="i">
                                                                {{i}}
                                                            </option>
                                                        </select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <input type="hidden" name="menu_item_id" v-model="custType.menu_item_id" value="typeform.menu_item_id">
                                        </b-form>
                                        <template slot="modal-footer">
                                        <div class="w-100">
                                            <b-button
                                            variant="primary"
                                            class="float-right"
                                            @click="customizationEdit"
                                            >{{ trans.get('__JSON__.Submit') }}</b-button>
                                            <b-button class="float-left" @click="editModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
                                        </div>
                                        </template>
                                    </b-modal>
                                    <!-- Edit Customisation Modal End -->

                                    <!-- Save As Template Modal Start -->
                                    <b-modal
                                        id="modal-scoped"
                                        v-model="templateModal"
                                        :title="trans.get('__JSON__.Template Name')"
                                        centered
                                        >
                                        <b-form @submit.prevent="saveAstemplate">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-group
                                                        id="input-group-1"
                                                        :label="trans.get('__JSON__.Template Name')"
                                                        label-for="template_name"
                                                    >
                                                        <b-form-input id="template_name" name="template_name" v-model="$v.template.template_name.$model" type="text"
                                                        :placeholder="trans.get('__JSON__.Enter Customisation Type')"
                                                        :class="{ 'is-invalid': $v.template.template_name.$error }"
                                                        ></b-form-input>
                                                        <div v-if="$v.template.template_name.$error" class="invalid-feedback">
                                                        <span
                                                            v-if="!$v.template.template_name.required"
                                                        >{{ trans.get('__JSON__.Please enter template name') }}</span>
                                                        </div>
                                                    </b-form-group>
                                                </div> 
                                            </div>
                                        </b-form>
                                        <template slot="modal-footer">
                                        <div class="w-100">
                                            <b-button
                                            variant="primary"
                                            class="float-right"
                                            @click="saveAstemplate"
                                            >{{ trans.get('__JSON__.Submit') }}</b-button>
                                            <b-button class="float-left" @click="templateModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
                                        </div>
                                        </template>
                                    </b-modal>
                                    <!-- Save As Template Modal End -->

                                    <!-- Select From Template Modal Start -->
                                    <b-modal
                                        id="modal-scoped"
                                        v-model="selectModal"
                                        :title="trans.get('__JSON__.Select Template')"
                                        centered
                                        >
                                        <b-form @submit.prevent="selectFromtemplate">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label class="control-label" for="ajax">{{trans.get('__JSON__.Select Template Name')}}</label>
                                                    <multiselect
                                                        v-model="$v.selectTem.cust_type_search.$model"
                                                        id="ajax"
                                                        label="template_name"
                                                        track-by="menu_item_id"
                                                        :placeholder="trans.get('__JSON__.Search...')"
                                                        open-direction="bottom"
                                                        :options="this.templates"
                                                        :multiple="true"
                                                        :searchable="true"
                                                        :loading="isLoading"
                                                        :internal-search="false"
                                                        :clear-on-select="false"
                                                        :close-on-select="false"
                                                        :limit="5"
                                                        :max-height="600"
                                                        :show-no-results="false"
                                                        :hide-selected="true"
                                                        @search-change="asyncFind"
                                                        :class="{ 'is-invalid': $v.selectTem.cust_type_search.$error }"
                                                        >
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option.template_name }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                    </template>
                                                    <template slot="clear" slot-scope="props">
                                                        <div
                                                            class="multiselect__clear"
                                                            v-if="cust_type_search.length"
                                                            @mousedown.prevent.stop="clearAll(props.search)"
                                                        ></div>
                                                    </template>
                                                    <span slot="noResult">{{trans.get('__JSON__Oops! No elements found. Consider changing the search query')}}</span>
                                                    </multiselect>
                                                    <div v-if=" $v.selectTem.cust_type_search.$error" class="invalid-feedback">
                                                        <span
                                                        v-if="!$v.selectTem.cust_type_search.required"
                                                        >{{ trans.trans('__JSON__.Please select at least one template name') }}.</span>
                                                    </div>
                                                </div> 
                                            </div>
                                        </b-form>
                                        <template slot="modal-footer">
                                        <div class="w-100">
                                            <b-button
                                            variant="primary"
                                            class="float-right"
                                            @click="selectFromtemplate"
                                            >{{ trans.get('__JSON__.Submit') }}</b-button>
                                            <b-button class="float-left" @click="selectModal=false">{{ trans.get('__JSON__.Close') }}</b-button>
                                        </div>
                                        </template>
                                    </b-modal>
                                    <!-- Select From  Template Modal End -->

                                </div>
                                <!-- end row -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  <!-- end row -->
</template>
