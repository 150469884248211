
<script>
import vue2Dropzone from "vue2-dropzone";
import { categoryService } from "../../services";
import { required } from "vuelidate/lib/validators";
import config from "../../config";
import { error_message } from "../../Helper/helper";
import { success_message } from "../../Helper/helper";
import DropzoneSingle from "../../components/widgets/dropzone-single";
import DropzonePopup from "../../components/widgets/dropzone-popup";

export default {
  components: { vueDropzone: vue2Dropzone, DropzoneSingle, DropzonePopup },
  data() {
    return {
      checked: true,
      file1: null,
      tmpCategoryImage: "",
      url: null,
      config: config,
      typeform: {
        category_name: "",
        category_name_thai: "",
        category_desc: "",
        category_desc_thai: "",
        color_code: "#000000",
        text_color_code: "#000000",
        status: 1,
        category_img: null,
      },
    };
  },
  validations: {
    typeform: {
      category_name: {
        required,
      },
      category_desc: {
        required,
      },
      category_img: {
        required,
      },
    },
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.typeform.category_img = file;
    },
    categoryInIt() {
      this.$v.$touch();

      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("category_img", this.typeform.category_img);
        fd.append("category_name", this.typeform.category_name);
        fd.append("category_name_thai", this.typeform.category_name_thai);
        fd.append("category_desc", this.typeform.category_desc);
        fd.append("category_desc_thai", this.typeform.category_desc_thai);
        fd.append("color_code", this.typeform.color_code);
        fd.append("text_color_code", this.typeform.text_color_code);
        fd.append("status", this.typeform.status);
        categoryService.addCategory(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.$router.push({ name: "category-list" });
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <form @submit.prevent="categoryInIt">
    <!-- Category Info Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Category Info') }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" v-if="$auth.setting">
              <label class="required">{{ trans.trans('__JSON__.Category Name Language',{ lang: $auth.langauges[0].name }) }}</label>
              <input
                id="category_name"
                v-model="$v.typeform.category_name.$model"
                type="text"
                class="form-control"
                :placeholder="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[0].name})"
                name="category_name"
                :class="{ 'is-invalid': $v.typeform.category_name.$error }"
              />
              <div v-if="$v.typeform.category_name.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.category_name.required">{{ trans.get('__JSON__.Please Enter category Name Error') }}</span>
              </div>
            </div>
          </div>


          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <div class="form-group">
                <label>{{ trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name }) }}</label>
                <input
                  id="category_name_thai"
                  name="category_name_thai"
                  type="text"
                  v-model="typeform.category_name_thai"
                  :placeholder="trans.trans('__JSON__.Category Name Language',{lang: $auth.langauges[1].name })"
                  class="form-control"
                />
               </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group" v-if="$auth.setting">
                <label class="required">{{ trans.trans('__JSON__.Category Description Language',{lang: $auth.langauges[0].name }) }}</label>
                <input
                  rows="5"
                  id="category_desc"
                  name="category_desc"
                  type="text"
                  v-model="$v.typeform.category_desc.$model"
                  :placeholder="trans.trans('__JSON__.Category Description Language',{lang: $auth.langauges[0].name })"
                  class="form-control"
                  :class="{ 'is-invalid': $v.typeform.category_desc.$error }"
                />
                <div v-if="$v.typeform.category_desc.$error" class="invalid-feedback">
                <span
                  v-if="!$v.typeform.category_desc.required"
                >{{ trans.get('__JSON__.Please Enter Category Description Error') }}.</span>
              </div>
               </div>
          </div>

          <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
          </div>
          <div class="col-sm-6" v-else>
            <div class="form-group">
                <label>{{ trans.trans('__JSON__.Category Description Language',{lang: $auth.langauges[1].name }) }}</label>
                <input
                  rows="5"
                  id="category_desc_thai"
                  name="category_desc_thai"
                  type="text"
                  v-model="typeform.category_desc_thai"
                  :placeholder="trans.trans('__JSON__.Category Description Language',{lang: $auth.langauges[0].name })"
                  class="form-control"
                />
               </div>
          </div>

          <div class="col-md-6 vendor-status">
            <b-form-group
              id="input-group-2"
              :label="trans.get('__JSON__.Status')"
              label-for="Status"
            >
              <div class="row">
                <div class="col-sm-6">
                  <div class="swtich-data">
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        class="switch-on"
                        name="status"
                        v-model="typeform.status"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <!--ADDED HTML -->
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                        <!--END-->
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-6">
                <b-form-group
                  id="input-group-2"
                  :label="trans.get('__JSON__.Menu Color Code')"
                  label-for="Menu Color Code"
                ></b-form-group>
              </div>
              <div class="col-sm-6">
                <b-form-group
                  id="input-group-2"
                  :label="trans.get('__JSON__.Text Color Code')"
                  label-for="Text Color Code"
                ></b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <input
                  type="color"
                  id="colorpickers1"
                  v-model="typeform.color_code"
                  name="color_code"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#000000"
                />
              </div>
              <div class="col-sm-6">
                <input
                  type="color"
                  id="colorpickers1"
                  v-model="typeform.text_color_code"
                  name="text_color_code"
                  pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  value="#000000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Category Info End -->

    <!-- Category Image Start -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title required">{{ trans.get('__JSON__.Category Image') }}</h4>
        <p class="card-title-desc"></p>

        <div class="row">
          <div class="col-sm-3">
            <dropzone-popup
              v-model="$v.typeform.category_img.$model"
              type="file"
              id="category_img"
              name="image"
              acceptedFiles=".jpeg,.jpg,.png"
            />
            <div v-if="$v.typeform.category_img.$error" class="invalid-feedback cuisine-img">
              <span
                v-if="!$v.typeform.category_img.required"
              >{{ trans.get('__JSON__.Please Select Category Image Error') }}.</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary mr-1 mt-3">{{ trans.get('__JSON__.Add Category') }}</button>
            <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Category Image End -->
  </form>
</template>

<style>
</style>