
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { categoryService } from "../../services";
import { error_message,debounce } from "../../Helper/helper";

export default {
  components: { pagination, categoryService },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      config:config,
      sortBy: sortBy || "category_name",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      categories: [],
      categoryData: [],
      paginations: {},
      limit: 1,
      search: "",
      status: status == undefined ? 1 :status,
      loading: true,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'category-list', query: {status,orderBy,sortBy}})
        }
        this.getCategory();
        this.getCategoryDetail();
        this.init = false;
      },500),
      fields: [
        { key: "category_img", label: "#", sortable: false },
        {
          key: "category_name",
          label: this.trans.get("__JSON__.Title"),
          sortable: true,
        },
        {
          key: "category_desc",
          label: this.trans.get("__JSON__.Description"),
          sortable: true,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: true,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getCategory();
    this.getCategoryDetail();
  },
  methods: {
    downloadsCSV: function () {
      let categoryData = "\ufeff" + "Title,Description,Status\n";
      this.categoryData.forEach((el) => {
        var line =
          (el["category_name"] ? el["category_name"] : "").replaceAll(
            ",",
            " "
          ) +
          "," +
          (el["category_desc"] ? el["category_desc"] : "").replaceAll(
            ",",
            " "
          ) +
          "," +
          (el["status"] == 1 ? "Active" : "InActive") +
          "\n";
        categoryData += line;
      });

      var blob = new Blob([categoryData], { type: "csv/plain" });
      var currentDate = new Date();
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Categories" + date + ".csv";
      link.click();
    },
    getCategory(page) {
      this.loading = true;
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      }
      categoryService
        .getCategory(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.categories = response.data.result.data;
        });
    },
    getCategoryDetail() {
      categoryService.exportToCsv({status: this.status}).then((response) => {
        this.categoryData = response.data.result;
      });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCategory();
    },
    deleteById(category_id) {
      if (confirm("Are you sure you want to delete?")) {
        categoryService
          .deleteCategory({ category_id: category_id })
          .then((response) => {
            error_message(response.data.message);
            this.getCategory();
          });
      }
    },
  },
};
</script>

<template>
  <div>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-briefcase"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Categories') }}</h4>
          </div>
          <div class="page-title-right">
            <router-link :to="{name: 'category-add'}" class="btn btn-success btn-rounded mr-2"
              v-if="$auth.hasPermission(config.permissions.category_write)">
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get('__JSON__.Add Category') }}
            </router-link>
            <button
              @click="downloadsCSV"
              v-if="$auth.hasSubAdminPermission(config.permissions.category_export)"
              class="btn btn-primary btn-rounded"
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list category-table table-responsive">
                <b-table
                  :items="categories"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>
                  
                  <template v-slot:cell(category_img)="data">
                    <div v-if="data.item.category_img">
                      <img
                        alt
                        title="product-img"
                        class="avatar-md rounded-circle-banner"
                        :src="config.category+data.item.category_img"
                        @error="$event.target.src=config.basepath+'no-img.png'"
                      />
                    </div>
                    <div v-else>
                      <img
                        class="avatar-md rounded-circle-banner"
                        :src="config.basepath+'no-img.png'"
                        alt
                      />
                    </div>
                  </template>

                  <template v-slot:cell(category_name)="data">
                    <h5 class="font-size-14">
                      <div
                        v-if="data.item.category_name.length > 25"
                      >{{data.item.category_name.substr(0, 25)+ '...'}}</div>
                      <div v-else>{{data.item.category_name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(category_desc)="data">
                    <div v-if="data.item.category_desc">
                      <div
                        v-if="data.item.category_desc.length > 40"
                      >{{data.item.category_desc.substr(0, 40)+ '...'}}</div>
                      <div v-else>{{data.item.category_desc}}</div>
                    </div>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div class>
                      <div
                        v-if="data.item.status == '1'"
                        class="badge badge-pill badge-soft-success font-size-12"
                      >{{ trans.get('__JSON__.Active') }}</div>
                      <div
                        v-else
                        class="badge badge-pill badge-soft-danger font-size-12"
                      >{{ trans.get('__JSON__.Inactive') }}</div>
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        v-if="$auth.hasPermission(config.permissions.category_write)"
                        :to="{name: 'category-edit',params: { category_id: base64(data.item.category_id) }}"
                        class="btn btn-success btn-sm btn-rounded cursor-pointer"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                      <button
                        v-if="$auth.hasPermission(config.permissions.category_write)"
                        type="submit"
                        class="btn btn-danger btn-sm btn-rounded ml-1"
                        @click="deleteById(base64(data.item.category_id))"
                      >{{ trans.get('__JSON__.Delete') }}</button>
                    </div>
                  </template>
                  
                  <template #empty>
                        <p class="text-center">{{ trans.get('__JSON__.No Categories') }}</p>
                  </template>

                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getCategory"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.category-table .table thead th:nth-child(1),.category-table .table thead th:nth-child(5){
    pointer-events: none;
}
</style>